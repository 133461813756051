import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { ASSET_CLASSES, Crypto } from 'types/assets';
import AssetIcon from 'common/components/Icon/AssetIcon';
import CryptoEuroInput from 'common/components/CryptoEuroInput/CryptoEuroInput';
import { crypto } from 'common/utils/formatting';
import { ObjectSchema, Shape } from 'yup';
import {
  AvailableAmount,
  Button,
  ButtonMax,
  Headline,
  InputLabel,
  InputWrapper,
  LabelWrapper,
  Wrapper,
} from './styles';

type Props = {
  assetCode: string;
  amount: string;
  updateAmount: (amount: string) => void;
  onProceed: () => undefined;
  validationSchema: ObjectSchema<Shape<object | undefined, { amount: string }>, object>;
  maxAmount?: number;
  asset?: Crypto;
};

const StakingAmountForm: FunctionComponent<PropsWithChildren<Props>> = ({
  assetCode,
  amount,
  updateAmount,
  maxAmount,
  onProceed,
  asset,
  validationSchema,
  children,
}) => {
  const { t } = useTranslation();

  const submitForm = (values: { amount: string }) => {
    if (values?.amount) {
      onProceed();
    }
  };

  return (
    <Formik initialValues={{ amount }} onSubmit={submitForm} validationSchema={validationSchema}>
      {({ values, errors, handleSubmit, setFieldValue }) => (
        <Wrapper>
          <Headline>
            <AssetIcon code={assetCode} class={ASSET_CLASSES.CRYPTO} securityClass={undefined} />
            {asset?.displayName}
          </Headline>

          <InputWrapper>
            <CryptoEuroInput
              isCrypto
              type="string"
              fieldValue={values.amount}
              onChange={(value: string) => setFieldValue('amount', value).then(() => updateAmount(value))}
              currencyLabel={assetCode.toUpperCase()}
              currencyColor={asset?.color}
              cryptoName={assetCode.toLowerCase()}
              error={errors.amount}
              placeholder="0"
              labelComponent={
                <LabelWrapper>
                  <InputLabel>{t('staking.amount')}</InputLabel>
                  <AvailableAmount>
                    {t('staking.amountAvailable', {
                      amount: crypto(maxAmount, asset?.cryptoDetails?.decimalPlaces),
                    })}
                  </AvailableAmount>
                </LabelWrapper>
              }
              endAdornment={
                maxAmount && (
                  <ButtonMax
                    title={t('common.max')}
                    onClick={() => {
                      void setFieldValue(
                        'amount',
                        crypto(maxAmount.toString(), asset?.cryptoDetails?.decimalPlaces),
                      ).then(() =>
                        updateAmount(crypto(maxAmount.toString(), asset?.cryptoDetails?.decimalPlaces)),
                      );
                    }}
                  />
                )
              }
            />
          </InputWrapper>

          {children}

          <Button
            inverted
            size="large"
            isFullWidth
            title={t('staking.continue')}
            onClick={handleSubmit}
            data-test-id="staking.continue"
          />
        </Wrapper>
      )}
    </Formik>
  );
};

StakingAmountForm.defaultProps = {
  maxAmount: 0,
  asset: undefined,
};

export default StakingAmountForm;
