/* eslint-disable @typescript-eslint/no-unsafe-return */

import { RootEpic } from 'types/common';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { isOfType } from 'safetypings';
import { getAccessToken } from 'store/selectors/auth';
import { STOCK_RAFFLE_FETCH_TRANACTION_COUNT_REQUEST } from 'store/actions/stockRaffle';
import { MAX_STOCK_RAFFLE_TRANSACTIONS, STOCK_RAFFLE_TRANSATION_TYPES } from 'common/const/stockRaffle';
import { State } from 'store/types/store';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import {
  stockRaffleFetchTransactionCountError,
  stockRaffleFetchTransactionCountSuccess,
} from '../actions/stockRaffle';
import { Order } from 'types/currency';
import { isDateBetween } from 'common/utils/date';
import { getBackendStockRaffleEndDate, getBackendStockRaffleStartDate } from 'store/selectors/configFeature';
import { forkJoin } from 'rxjs';

export const fetchStockRaffleTransationCount: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(STOCK_RAFFLE_FETCH_TRANACTION_COUNT_REQUEST)),
    switchMap(() =>
      state$.pipe(
        map((state: State) => ({
          accessToken: getAccessToken(state) || '',
          reference: null,
          raffleStartDate: getBackendStockRaffleStartDate(state),
          raffleEndDate: getBackendStockRaffleEndDate(state),
        })),
        filter(({ accessToken }) => !!accessToken),
        take(1),
        switchMap(({ accessToken, reference, raffleStartDate, raffleEndDate }) => {
          const stocksQueryString = queryArrayString({
            reference,
            size: MAX_STOCK_RAFFLE_TRANSACTIONS,
            assetCategory: 'Stock',
            subtypes: STOCK_RAFFLE_TRANSATION_TYPES,
          });

          const etfsQueryString = queryArrayString({
            reference,
            size: MAX_STOCK_RAFFLE_TRANSACTIONS,
            assetCategory: 'Etf',
            subtypes: STOCK_RAFFLE_TRANSATION_TYPES,
          });

          const stocksCount = ajax({
            url: api.getBaseUrlForTrading(accessToken).concat(`/orders/history/euro${stocksQueryString}`),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });

          const etfsCount = ajax({
            url: api.getBaseUrlForTrading(accessToken).concat(`/orders/history/euro${etfsQueryString}`),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });

          return forkJoin({
            stocks: stocksCount,
            etfs: etfsCount,
          }).pipe(
            switchMap(({ stocks, etfs }: { stocks: { response: Order[] }; etfs: { response: Order[] } }) => {
              if (!stocks.response.length && !etfs.response.length)
                return [stockRaffleFetchTransactionCountSuccess(0)];

              const allTransactions = [...stocks.response, ...etfs.response];

              const validTransactionsCount = allTransactions.reduce(
                (all: number, current: Order): number =>
                  isDateBetween(new Date(current.placedAtUtc), raffleStartDate, raffleEndDate)
                    ? all + 1
                    : all,
                0,
              );

              return [stockRaffleFetchTransactionCountSuccess(validTransactionsCount)];
            }),
          );
        }),
        catchError(() => [stockRaffleFetchTransactionCountError()]),
      ),
    ),
  );
