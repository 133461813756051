import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetDeadEndField } from 'store/actions/forms';
import { setIsStillInSecuritiesKyc } from 'store/actions/localSettings';
import { paths } from 'common/urls';
import usePrevLocation from 'common/hooks/usePrevLocation';

const useWatchStocksKycVisiting = () => {
  const dispatch = useDispatch();
  const { currentLocation, prevLocation } = usePrevLocation();

  useEffect(() => {
    if (prevLocation === paths.STOCKS_KYC && currentLocation !== paths.STOCKS_KYC) {
      dispatch(setIsStillInSecuritiesKyc(false));
      dispatch(resetDeadEndField());
    }
  }, [currentLocation, prevLocation]);
};

export default useWatchStocksKycVisiting;
