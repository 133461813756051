import type { AxiosPromise } from 'axios';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import I18n from 'i18next';
import { v4 as uuid } from 'uuid';
import * as platform from 'platform';
import fetch from 'cross-fetch';
import * as Sentry from '@sentry/react';
import { AccountType, accountTypes, Credentials, timePeriodParam } from 'common/const';
import { getClientId } from 'common/utils/auth';
import { browserLanguage } from 'common/utils/language';
import parseTimestampFromGuid, { getUTMParams } from './utils/parse';
import { getActiveSessionId } from './utils/session';
import { makeError } from './apiErrors';
import {
  SubmitDeleteAccountBody,
  SubmitDeleteRealAccountBody,
  SubmitLimitOrderBody,
  SubmitStopOrderBody,
} from './api-types';
import { Fiat, TechIndicatorItem, TimePeriod } from 'types/currency';
import { ApiError } from 'types/error';
import { Account, CryptoWithdrawOrder } from 'types/money';
import { errorResponseInterceptor, responseInterceptor } from 'common/utils/sentryUtilities';
import { TradingRuleCreateLimitModel, TradingRuleCreateStopModel } from 'types/tradingRules';

const appStartTime = Date.now();
axios.defaults.withCredentials = true;

// we cannot call open API w/axios
// https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
const callOpenApi = (url, headers = undefined) =>
  fetch(url, {
    headers,
  }).then((response) => response.json());

let commonHeaders = {};
export const getCommonHeaders = () => {
  commonHeaders = {
    'X-SessionId': getActiveSessionId(),
    'X-App-Start-Time': appStartTime,
    'X-App-Build': window.env.VERSION,
    'X-Device-ID': getClientId(),
    'X-Device-Model': `${platform.manufacturer} ${platform.product}`,
    'X-OS': platform.os ? `${platform.os.family} ${platform.os.version} ${platform.os.architecture}` : null,
    'X-OS-Name': platform.os.family,
    'X-App': !window.env.BETA ? 'BISON_web' : 'BISON_web_beta',
    'X-Browser-Name': platform.name,
    'X-Browser-Version': platform.version,
  };
  return commonHeaders;
};

const buildOrdersUrlsParams = ({ subtype, crypto, size, reference }) => {
  const params = new URLSearchParams();
  subtype?.forEach((item) => params.append('subtypes', item));
  crypto?.forEach((item) => params.append('entities', item));
  if (size) {
    params.append('size', size);
  }
  if (reference) {
    params.append('reference', reference);
  }
  return params;
};

// in case we don't have config for some reasons
// prevent from crashing
window.env = window.env || {};
const createApi = (axiosConfig: { baseURL: string; headers?: any }) => {
  const axiosInstance = axios.create({
    ...axiosConfig,
    withCredentials: true,
    headers: {
      ...commonHeaders,
      ...axiosConfig.headers,
    },
  });

  axiosInstance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      ...config.headers,
      ...getCommonHeaders(),
    },
  }));

  axiosInstance.interceptors.response.use(responseInterceptor, errorResponseInterceptor);

  return axiosInstance;
};

const axiosUsersvcAnonymous = createApi({
  baseURL: window.env.API_USERSVC_ANONYMOUS,
});
const axiosUsersvcRegistered = createApi({
  baseURL: window.env.API_USERSVC_REGISTERED,
});
const axiosTradingAnonymous = createApi({
  baseURL: window.env.API_TRADING_ANONYMOUS,
});
const axiosTradingPaper = createApi({
  baseURL: window.env.API_TRADING_PAPER,
});
const axiosTradingReal = createApi({
  baseURL: window.env.API_TRADING_REAL,
});
const axiosImproveUX = createApi({
  baseURL: window.env.API_TRACKING,
});

const baseUrlForStaticAssets = window.env.API_REMOTE_ASSETS;

/**
 * We added `&version=2.14.1` so the browser cache can invalidate so the crypto charts can update.
 *
 * @since 2.14.1
 * @todo Remove `&version=2.14.1` after 6.9.2024.
 */
const axiosPriceHistorySmallChart = createApi({
  baseURL: window.env.API_PRICE_HISTORY_SMALL_CHART,
});

const getPriceHistorySmallChartAxios = () => axiosPriceHistorySmallChart;

const axiosStatusApi = (headers = undefined) => callOpenApi(`${window.env.API_STATUS}`, headers);
const axiosTechIndicators = (pair: string, timePeriod: TimePeriod, indicator: TechIndicatorItem) =>
  callOpenApi(
    `${window.env.API_TECH_INDICATORS}/${indicator.type}/bison/${pair}?volEur=100&periodLength=${timePeriodParam[timePeriod]}&adjustedBuckets=false&period=${indicator.period}&trim=true`,
  );

const axiosNewsFeed = createApi({ baseURL: window.env.API_NEWSFEED, timeout: 20 * 1000 });
const getCryptoNewsAxios = () => axiosNewsFeed;

const getUserSvcAxios = (accessToken: string) =>
  jwtDecode(accessToken).aty === accountTypes.anonymous ? axiosUsersvcAnonymous : axiosUsersvcRegistered;

const getTradingAxios = (accessToken: string) => {
  switch (jwtDecode(accessToken).aty) {
    case accountTypes.anonymous:
      return axiosTradingAnonymous;

    case accountTypes.paper:
      return axiosTradingPaper;

    default: // fall-through
    case accountTypes.real:
      return axiosTradingReal;
  }
};

// used to get base URLs for external consumers
export const getBaseUrlForUserServices = (accessToken: string) => {
  return getUserSvcAxios(accessToken).defaults.baseURL;
};

export const getBaseUrlForTrading = (accessToken: string) => getTradingAxios(accessToken).defaults.baseURL;
export const getBaseUrlForStaticAssets = (): string => (baseUrlForStaticAssets as string) ?? '';
export const getBaseUrlForNews = () => getCryptoNewsAxios().defaults.baseURL;
export const getBaseUrlForPriceHistorySmallChart = () =>
  getPriceHistorySmallChartAxios().defaults.baseURL ?? '';

// ******************************** Api calls **********************************

export type InitResponse = { response: any } | { error: ApiError };
export type InitRequest = {
  userId?: string;

  // Note: the following is unused on the web client
  fcmToken?: string;
  attribution?: {
    adId?: string;
    network?: string;
    campaign?: string;
    adGroup?: string;
    creative?: string;
    clickLabel?: string;
    trackerToken?: string;
    trackerName?: string;
  };
};

const initAuthRequest = async (axiosApi: any): Promise<{ nonce: string; wwwAuthenticate: string }> => {
  const clientId = getClientId();
  const initResponse = await axiosApi.get('/auth/clientAuth', {
    headers: { Nonce: uuid() },
    params: { clientId },
    validateStatus: (status) => status === 401,
  });

  const wwwAuthenticate = initResponse.headers && initResponse.headers['www-authenticate'];
  const dbrequestid = initResponse.headers && initResponse.headers.dbrequestid;

  if (!wwwAuthenticate || !dbrequestid) {
    throw new Error('Something bad happened while initializing handshake');
  }

  const nonce = parseTimestampFromGuid(dbrequestid);
  return { nonce, wwwAuthenticate };
};

const retryAxiosRequest = async (
  request: () => AxiosPromise<any>,
  retryOnErrorCode,
  retryCount = 3,
): AxiosPromise<any> => {
  try {
    return await request();
  } catch (error) {
    if (retryCount <= 1 || (retryOnErrorCode && retryOnErrorCode !== error?.response?.data?.code))
      throw error;
    return retryAxiosRequest(request, retryOnErrorCode, retryCount - 1);
  }
};

export const init = async (request: InitRequest): Promise<InitResponse> => {
  try {
    const axiosRequest = async () => {
      const axiosApi = axiosUsersvcRegistered;
      const url = `/init`;

      const { nonce, wwwAuthenticate } = await initAuthRequest(axiosApi);
      const clientId = getClientId();
      const body = {
        ...request,
        clientId,
        attributionUtm: getUTMParams(),
      };
      return axiosApi.post(url, body, {
        headers: {
          Nonce: nonce,
          Authorization: `Bearer ${wwwAuthenticate}`,
        },
      });
    };

    const response = await retryAxiosRequest(axiosRequest, 'clientauth_err_level3');

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('login.errorGeneric')) };
  }
};

export type ValidateTokenSuccess = {
  userId: number;
  email: string;
  name: string;
  roles: Array<string>;
  address: string;
  deviceId: string;
};
type ValidateTokenResponse = { response: ValidateTokenSuccess } | { error: ApiError };
export const validateToken = (_: any, accessToken: string): Promise<ValidateTokenResponse> =>
  getUserSvcAxios(accessToken)
    .get('/user', { headers: { Authorization: `Bearer ${accessToken}` } })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('error.accessToken')) }));

// ************ BUY & SELL ************
// export type Refresh
export type InitOrderResponse = any;
export const initOrder = (
  { isBuyOrder, entity, currency }: { isBuyOrder: boolean; entity: string; currency: string },
  accessToken: string,
): Promise<InitOrderResponse> => {
  const buyOrSell = isBuyOrder ? 'buy' : 'sell';
  const entityShortName = entity.toLowerCase();
  return getTradingAxios(accessToken)
    .get(`/order/init?type=${buyOrSell}&currency=${currency}&entity=${entityShortName}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('home.currency.genericErrors.initOrderError')) }));
};

export type PriceQuoteResponse = any;
export const priceQuote = (
  {
    isBuyOrder,
    entity,
    currency,
    amount,
    amountType,
  }: {
    isBuyOrder: boolean;
    entity: string;
    currency: string;
    amount: number;
    amountType: 'volume' | 'value';
  },
  accessToken: string,
): Promise<PriceQuoteResponse> => {
  const buyOrSell = isBuyOrder ? 'buy' : 'sell';
  const entityShortName = entity.toLowerCase();
  return getTradingAxios(accessToken)
    .get(
      `/order/quote?type=${buyOrSell}&currency=${currency}&entity=${entityShortName}&amountType=${amountType}&amount=${amount}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('home.currency.genericErrors.refreshPriceError')) }));
};

export const redeemCode = async (
  { code }: { code: string },
  accessToken: string,
): Promise<{ response: any } | { error: ApiError }> => {
  const body = JSON.stringify({ coupon: code });
  try {
    const response = await getUserSvcAxios(accessToken).post(`/coupon`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('redeemCode.error.generic')) };
  }
};

export const validateReferralCode = async (code) => {
  try {
    const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

    const response = await axiosUsersvcRegistered.get('/auth/isValidReferralCode', {
      params: {
        code,
      },
      headers: {
        Nonce: nonce,
        Authorization: `Bearer ${wwwAuthenticate}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('fieldErrors.invalidReferralCode')) };
  }
};

export type ReserveOrder = any;
export const reserveOrder = (
  {
    isBuyOrder,
    currency,
    entity,
    amount,
    amountType,
  }: {
    isBuyOrder: boolean;
    currency: string;
    entity: string;
    amount: number;
    amountType: 'volume' | 'value';
  },
  accessToken: string,
): Promise<ReserveOrder> => {
  const buyOrSell = isBuyOrder ? 'buy' : 'sell';
  const entityShortName = entity.toLowerCase();
  return getTradingAxios(accessToken)
    .get(
      `/order/reserve?type=${buyOrSell}&currency=${currency}&entity=${entityShortName}&amountType=${amountType}&amount=${amount}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('home.currency.genericErrors.reserveOrderError')) }));
};

export type SubmitOrder = any;
export const submitOrder = (
  {
    reserveOrderData,
  }: {
    reserveOrderData: {
      reservationToken: string;
      confirmationFlowId: string;
      orderType: string;
      currency: string;
      entity: string;
      price: number;
      ratio: number;
      volume: number;
      value: number;
    };
  },
  accessToken: string,
): Promise<SubmitOrder> => {
  const body = JSON.stringify(reserveOrderData);
  return getTradingAxios(accessToken)
    .post('/order/submit', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('home.currency.orderFail')) }));
};

export type WithdrawInfo = any;
export const withdrawInfo = (_: any, accessToken: string): Promise<WithdrawInfo> =>
  getUserSvcAxios(accessToken)
    .get('/user/withdrawalBankAccounts', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error) }));

export type DepositInfo = any;
export const depositInfo = (_: any, accessToken: string): Promise<DepositInfo> =>
  getUserSvcAxios(accessToken)
    .get('/user/depositInfo', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error) }));

export type CryptoDepositInfo = any;
export const cryptoDepositInfo = (crypto: Crypto, accessToken: string): Promise<CryptoDepositInfo> =>
  getUserSvcAxios(accessToken)
    .get(`/cryptoDeposit/info/${crypto.toLowerCase()}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error) }));

export type RegenerateAddress = any;
export const regenerateAddress = (crypto: Crypto, accessToken: string): Promise<RegenerateAddress> =>
  getUserSvcAxios(accessToken)
    .post(
      `/cryptoDeposit/addressRegenerate/${crypto.toLowerCase()}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error) }));

export type WithdrawOrder = any;
export const withdrawOrder = (
  {
    currency,
    volume,
    account,
  }: {
    currency: Fiat;
    volume: number;
    account: Account;
  },
  accessToken: string,
): Promise<WithdrawOrder> => {
  const body = JSON.stringify({ currency, volume, ReferenceAccount: account });
  return getTradingAxios(accessToken)
    .post('/money/submitWithdraw', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('home.currency.orderFail')) }));
};

export type InitCryptoWithdrawOrder = any;
export const initCryptoWithdrawOrder = (
  {
    currency,
    volume,
    walletAddress,
    walletAddressTag,
    password,
    deviceData,
  }: {
    currency: Crypto;
    volume: number;
    walletAddress: string;
    walletAddressTag: string | null;
    password: string;
    deviceData?: string;
  },
  accessToken: string,
): Promise<WithdrawOrder> => {
  const body = JSON.stringify({
    order: {
      currency,
      volume,
      walletAddress,
      walletAddressTag,
    },
    password,
    ...(deviceData ? { deviceData } : {}),
  });
  return getTradingAxios(accessToken)
    .post('/crypto/initWithdraw', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('home.currency.orderFail')) }));
};

export type SubmitCryptoWithdrawOrder = any;
export const submitCryptoWithdrawOrder = (
  {
    order,
    code2fa,
    deviceData,
  }: {
    order: CryptoWithdrawOrder;
    code2fa: string;
    deviceData?: string;
  },
  accessToken: string,
): Promise<WithdrawOrder> => {
  const body = JSON.stringify({
    order,
    code2fa,
    ...(deviceData ? { deviceData } : {}),
  });
  return getTradingAxios(accessToken)
    .post('/crypto/submitWithdraw', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('home.currency.orderFail')) }));
};

// ************ AUTH ************

export type LoginSuccess = { accessToken: string; clientKey?: string };
export type LoginResponse = { response: LoginSuccess } | { error: ApiError };
export type LoginRequest = {
  credentials: Credentials;
  accountType: AccountType;
  clientToken: string;
  deviceData?: string;
  codeLoginParams?: {
    token: string;
    code: string;
  };
};

// Logins user
export const login = async (request: LoginRequest): Promise<LoginResponse> => {
  try {
    const axiosApi =
      request.accountType === accountTypes.anonymous ? axiosUsersvcAnonymous : axiosUsersvcRegistered;
    const { nonce, wwwAuthenticate } = await initAuthRequest(axiosApi);

    const body = {
      username: request.credentials.username,
      accountType: request.accountType,
      secretType: request.credentials.type,
      secret: request.credentials.secret,
      rememberMe: request.credentials.rememberMe,
      clientToken: request.clientToken,
      language: browserLanguage(),
      clientId: getClientId(),
      codeLoginSupported: true,
      ...(request.deviceData ? { deviceData: request.deviceData } : {}),
      ...(request.twofa ? { codeLoginParams: request.twofa } : {}),
    };

    Sentry.addBreadcrumb({
      message: 'Login request payload',
      category: 'auth',
      level: 'debug',
      data: Object.entries(body).reduce(
        (acc, [key, val]) => ({
          ...acc,
          [`${key}HasValue`]: `${!!val}`,
        }),
        {},
      ),
    });

    const response = await axiosApi.post('/auth/login', body, {
      headers: {
        Nonce: nonce,
        Authorization: `Bearer ${wwwAuthenticate}`,
      },
    });

    const { token, ...rest } = response.data;
    return { response: { accessToken: token, ...rest } };
  } catch (error) {
    if (error?.response?.status === 401) {
      return { error: makeError(error, I18n.t('login.errorUnauthorized')) };
    } else {
      return { error: makeError(error, I18n.t('login.errorGeneric')) };
    }
  }
};

export type AnonymousLoginSuccess = { username: string; token: string; clientKey?: string };
type AnonymousLoginResponse = { response: AnonymousLoginSuccess } | { error: ApiError };

// Anonymous login
export const anonymousLogin = async (): Promise<AnonymousLoginResponse> => {
  try {
    const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcAnonymous); // THIS IS A WORKAROUND we use axiosUsersvcRegistered here, bcs gruffalo is not answering clientAuth via anonymous

    const body = {
      clientId: getClientId(),
      language: browserLanguage(),
    };
    const response = await axiosUsersvcAnonymous.post('/auth/signup/', body, {
      headers: {
        Nonce: nonce,
        Authorization: `Bearer ${wwwAuthenticate}`,
      },
    });
    // transforms 'token' into 'accessToken'
    const { token, ...rest } = response.data;
    return { response: { accessToken: token, ...rest } };
  } catch (error) {
    return { error: makeError(error, I18n.t('login.errorGeneric')) };
  }
};

// ************ REGISTER ************

export type RegisterSuccess = { clientKey: string };
export type RegisterResponse = { response: RegisterSuccess } | { error: ApiError };
type RegisterRequest = { username: string; password: string; newsletter: boolean; referralCode: string };

// Registers user
export const register = async ({
  username,
  password,
  newsletter,
  referralCode,
}: RegisterRequest): Promise<RegisterResponse> => {
  try {
    const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

    const body = {
      username,
      secret: password,
      newsletter,
      clientId: getClientId(),
      referralCode,
      language: browserLanguage(),
      ...(window.env.ENVIRONMENT !== 'production' ? { webhost: window.location.origin } : {}),
    };
    const response = await axiosUsersvcRegistered.post('/auth/signup/', body, {
      headers: {
        Nonce: nonce,
        Authorization: `Bearer ${wwwAuthenticate}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('registration.errorGeneric')) };
  }
};

// ************ CHANGE PASSWORD ************
export type ChangePasswordSuccess = boolean;
export type ChangePasswordResponse = { response: ChangePasswordSuccess } | { error: ApiError };

// Checks for username existance
export const changePassword = async (
  {
    username,
    oldPassword,
    newPassword,
  }: {
    username: string;
    oldPassword: string;
    newPassword: string;
  },
  accessToken: string,
): Promise<ChangePasswordResponse> => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const body = {
      username,
      oldPassword,
      newPassword,
    };
    const response = await axiosApi.post('/user/pwdchange/', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.changePass.genericError')) };
  }
};

// ************ RESET PASSWORD ************
export type ResetPasswordSuccess = Record<string, unknown>;
export type ResetPasswordResponse = { response: ResetPasswordSuccess } | { error: ApiError };

// Checks for username existance
export const resetPassword = async ({ username }: { username: string }): Promise<ResetPasswordResponse> => {
  try {
    const clientId = getClientId();
    const url = '/auth/pwdreset/';
    const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

    const body = {
      username,
      clientId,
      language: browserLanguage(),
      bisonWebHost: window.location.origin,
    };

    const response = await axiosUsersvcRegistered.post(url, body, {
      headers: {
        Nonce: nonce,
        Authorization: `Bearer ${wwwAuthenticate}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('forgotPassword.errors.generic')) };
  }
};

export type UserDetailsSuccess = {
  result: string;
  username: string;
  userFirstName: string;
  mobileNumberMasked: string;
  isSecurityCodeRequired: boolean;
};
export type GetUserDetailsResponse = { response: UserDetailsSuccess } | { error: ApiError };
// Get user details from pwd reset token
export const getUserDetails = async (token: string): Promise<GetUserDetailsResponse> => {
  const clientId = getClientId();
  const url = `/auth/pwdreset/token`;
  const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

  const response = await axiosUsersvcRegistered.post(
    url,
    { token },
    {
      headers: {
        Nonce: nonce,
        Authorization: `Bearer ${wwwAuthenticate}`,
      },
    },
  );

  return response.data as Promise<GetUserDetailsResponse>;
};

export type PasswordResetSecurityCode = {
  isSuccessful: boolean;
  confirmationId: string;
};
export type PasswordResetSecurityCodeResponse = { response: PasswordResetSecurityCode } | { error: ApiError };
export const sendPasswordResetSecurityCode = async (body: {
  token: string;
  deviceData?: string;
}): Promise<PasswordResetSecurityCodeResponse> => {
  const clientId = getClientId();
  const url = `/auth/pwdreset/code`;
  const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

  const response = await axiosUsersvcRegistered.post(url, body, {
    headers: {
      Nonce: nonce,
      Authorization: `Bearer ${wwwAuthenticate}`,
    },
  });

  return response.data as Promise<PasswordResetSecurityCodeResponse>;
};

export type ConfirmPasswordReset = {
  isSuccessful: boolean;
  confirmationId: string;
};

export type ConfirmPasswordResetResponse = { response: ConfirmPasswordReset } | { error: ApiError };

export const confirmPasswordReset = async (body: {
  token: string;
  newPassword: string;
  newPasswordConfirm: string;
  confirmationId?: string;
  code?: string;
  deviceData?: string;
}): Promise<ConfirmPasswordResetResponse> => {
  const clientId = getClientId();
  const url = `/auth/pwdreset/confirm`;
  const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

  const response = await axiosUsersvcRegistered.post(url, body, {
    headers: {
      Nonce: nonce,
      Authorization: `Bearer ${wwwAuthenticate}`,
    },
  });

  return response.data as Promise<ConfirmPasswordResetResponse>;
};

export type EmailConfirm = {
  isSuccessful: boolean;
  username: string;
};

export type EmailConfirmResponse = { response: EmailConfirm } | { error: ApiError };

export const emailConfirmRequest = async (body: { token: string }): Promise<EmailConfirmResponse> => {
  const url = '/auth/email-confirm';
  const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

  const response = await axiosUsersvcRegistered.post(url, body, {
    headers: {
      Nonce: nonce,
      Authorization: `Bearer ${wwwAuthenticate}`,
    },
  });

  return response.data as Promise<EmailConfirmResponse>;
};

export type EmailChangeConfirm = {
  isSuccessful: boolean;
  username: string;
};

export type EmailChangeConfirmResponse = { response: EmailChangeConfirm } | { error: ApiError };

export const emailChangeConfirmRequest = async (body: {
  token: string;
  currentEmail: string;
}): Promise<EmailChangeConfirmResponse> => {
  const url = '/auth/email-change';
  const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);

  const response = await axiosUsersvcRegistered.post(url, body, {
    headers: {
      Nonce: nonce,
      Authorization: `Bearer ${wwwAuthenticate}`,
    },
  });

  return response.data as Promise<EmailChangeConfirmResponse>;
};

/* ********* Username change ********* */

export type ChangeUsernameInitSuccess = { newUsername: string; submitToken: string };
export type ChangeUsernameInitResponse = { response: ChangeUsernameInitSuccess } | { error: ApiError };

// Sends out an confirmation code
export const changeUsernameInit = async (
  {
    newUsername,
  }: {
    newUsername: string;
  },
  accessToken: string,
): Promise<ChangeUsernameInitResponse> => {
  const body = JSON.stringify({ username: newUsername });
  try {
    const response = await getUserSvcAxios(accessToken).post('/user/usernameChange', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.personalInfo.errors.changeUsernameInitGenericError')) };
  }
};

export type ChangeUsernameSuccess = boolean;
export type ChangeUsernameResponse = { response: ChangeUsernameSuccess } | { error: ApiError };

// Checks for username existance
export const changeUsername = async (
  { data, code2fa }: { data: any; code2fa: string },
  accessToken: string,
): Promise<ChangeUsernameResponse> => {
  const body = JSON.stringify({ data, code2fa });
  try {
    const response = await getUserSvcAxios(accessToken).patch('/user/usernameChange', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.personalInfo.errors.changeUsernameGenericError')) };
  }
};

// ************ CHANGE ADDRESS ************
export type ChangeAddressInitSuccess = { newAddress: string; submitToken: string };
export type ChangeAddressInitResponse = { response: ChangeAddressInitSuccess } | { error: ApiError };

// Sends out an confirmation code
export const changeAddressInit = async (
  { newAddress, deviceData }: { newAddress: string; deviceData: string },
  accessToken: string,
): Promise<ChangeAddressInitResponse> => {
  const body = JSON.stringify({
    ...newAddress,
    ...(deviceData ? { deviceData } : {}),
  });
  try {
    const response = await getUserSvcAxios(accessToken).post('/user/addressChange', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.personalInfo.errors.changeAddressInitGenericError')) };
  }
};

export type ChangeAddressSuccess = boolean;
export type ChangeAddressResponse = { response: ChangeAddressSuccess } | { error: ApiError };

// Confirm change address
export const changeAddress = async (
  { data, code2fa, deviceData }: { data: any; code2fa: string; deviceData: string },
  accessToken: string,
): Promise<ChangeAddressResponse> => {
  const body = JSON.stringify({
    data,
    code2fa,
    ...(deviceData ? { deviceData } : {}),
  });
  try {
    const response = await getUserSvcAxios(accessToken).patch('/user/addressChange', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data.address };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.personalInfo.errors.changeAddressGenericError')) };
  }
};

// ************ CHANGE MOBILE NUMBER ************
export type MobileNumberAssignInitSuccess = { mobileNumber: string; submitToken: string };
export type MobileNumberAssignInitResponse =
  | { response: MobileNumberAssignInitSuccess }
  | { error: ApiError };

// Sends out an confirmation code
export const mobileNumberAssignInit = async (
  { mobileNumber, deviceData }: { mobileNumber: string; deviceData?: string },
  accessToken: string,
): Promise<MobileNumberAssignInitResponse> => {
  const body = JSON.stringify({
    mobileNumber,
    ...(deviceData ? { deviceData } : {}),
  });
  try {
    const response = await getUserSvcAxios(accessToken).post('/user/mobileNumberAssign', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.mobileNumberChange.errors.assignInitGenericError')) };
  }
};

export type MobileNumberAssignSuccess = boolean;
export type MobileNumberAssignResponse = { response: MobileNumberAssignSuccess } | { error: ApiError };

// Confirm change mobile
export const mobileNumberAssign = async (
  { data, code2fa, deviceData }: { data: any; code2fa: string; deviceData?: string },
  accessToken: string,
): Promise<MobileNumberAssignResponse> => {
  const body = JSON.stringify({
    data,
    code2fa,
    ...(deviceData ? { deviceData } : {}),
  });
  try {
    const response = await getUserSvcAxios(accessToken).patch('/user/mobileNumberAssign', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.mobileNumberChange.errors.assignGenericError')) };
  }
};

export type MobileNumberDeleteInitSuccess = { mobileNumber: string; submitToken: string };
export type MobileNumberDeleteInitResponse =
  | { response: MobileNumberDeleteInitSuccess }
  | { error: ApiError };

// Sends out an confirmation code
export const mobileNumberDeleteInit = async (
  { mobileNumber, deviceData }: { mobileNumber: string; deviceData?: string },
  accessToken: string,
): Promise<MobileNumberDeleteInitResponse> => {
  const body = JSON.stringify({
    mobileNumber,
    ...(deviceData ? { deviceData } : {}),
  });
  try {
    const response = await getUserSvcAxios(accessToken).post('/user/mobileNumberDelete', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.mobileNumberChange.errors.deleteInitGenericError')) };
  }
};

export type MobileNumberDeleteSuccess = boolean;
export type MobileNumberDeleteResponse = { response: MobileNumberDeleteSuccess } | { error: ApiError };

// Confirm change mobile
export const mobileNumberDelete = async (
  { data, code2fa, deviceData }: { data: any; code2fa: string; deviceData?: string },
  accessToken: string,
): Promise<MobileNumberDeleteResponse> => {
  const body = JSON.stringify({
    data,
    code2fa,
    ...(deviceData ? { deviceData } : {}),
  });
  try {
    const response = await getUserSvcAxios(accessToken).patch('/user/mobileNumberDelete', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data }; // TODO response.data.iban
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.mobileNumberChange.errors.deleteGenericError')) };
  }
};

export type KycFormSubmitSuccess = {
  transactionToken: string;
};
export type KycFormSubmitResponse = { response: KycFormSubmitSuccess } | { error: ApiError };

export const kycFormSubmit = async (formData: any, accessToken: string): Promise<KycFormSubmitResponse> => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const response = await axiosApi.post('/user/kycformsubmit', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: { transactionToken: response.data.reference } };
  } catch (error) {
    return { error: makeError(error) };
  }
};

export type KycFormExitSuccess = any;
export type KycFormExitResponse = { response: KycFormSubmitSuccess } | { error: ApiError };

export const kycFormExit = async (formData: any, accessToken: string): Promise<KycFormSubmitResponse> => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const response = await axiosApi.post('/user/kycformexit', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error) };
  }
};

export type KycPendingSuccess = boolean;
export type KycPendingResponse = { response: KycPendingSuccess } | { error: ApiError };

export const kycPending = async (_: any, accessToken: string): Promise<KycPendingResponse> => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);

    const response = await axiosApi.post(
      '/user/kycpending',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error) };
  }
};

export type KycDataSuccess = any;
export type KycDataResponse = { response: KycDataSuccess } | { error: ApiError };

export const kycData = async (_: any, accessToken: string): Promise<KycDataResponse> => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const response = await axiosApi.get('/user/kycData', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error) };
  }
};

export type ResendConfirmationMailSuccess = any;
export type ResendConfirmationMailResponse =
  | { response: ResendConfirmationMailSuccess }
  | { error: ApiError };

export const resendConfirmationMail = async ({
  username,
  secret,
  deviceData,
}: {
  username: string;
  secret: string;
  deviceData?: string;
}): Promise<ResendConfirmationMailResponse> => {
  try {
    const url = '/auth/confirmResend/';
    const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);
    const body = {
      username,
      secret,
      ...(deviceData ? { deviceData } : {}),
      language: browserLanguage(),
      ...(window.env.ENVIRONMENT !== 'production' ? { webhost: window.location.origin } : {}),
    };

    const response = await axiosUsersvcRegistered.post(url, body, {
      headers: {
        Nonce: nonce,
        Authorization: `Bearer ${wwwAuthenticate}`,
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: error?.response?.data };
  }
};

export const checkBisonStatus = (isLoggedInAndStocksUser = false) => {
  // Append stocks user header if condition is true, we can leave it empty if false.
  const headers = isLoggedInAndStocksUser
    ? { 'x-stocks-user': isLoggedInAndStocksUser.toString() }
    : undefined;

  return axiosStatusApi(headers)
    .then((response) => ({ response }))
    .catch((error) => ({ error: makeError(error, 'Status check API error') }));
};

export const sendInfoReport = async ({ year }: { year: number }, accessToken: string): Promise<any> => {
  try {
    const response = await getTradingAxios(accessToken).get(`/orders/sendInfoReport`, {
      params: { year },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.reports.infoReport.error.generic')) };
  }
};

export const orderYears = (): Promise<any> => {
  try {
    const data = {
      years: [2018, 2019],
    };
    return { response: data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.infoReport.error.generic')) };
  }
};

export const changeIbanInit = async (
  { iban, deviceData }: { iban: string; deviceData?: string },
  accessToken: string,
): Promise<any> => {
  const body = JSON.stringify({
    iban,
    ...(deviceData ? { deviceData } : {}),
  });
  try {
    const response = await getUserSvcAxios(accessToken).post('/user/ibanChange', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (e) {
    return { error: makeError(e, I18n.t('settings.personalInfo.errors.changeIbanGenericError')) };
  }
};

export const changeIban = async (
  {
    data,
    code2fa,
    deviceData,
  }: {
    data: { change: { iban: string }; submitToken: string; needsConfirmation: boolean };
    code2fa: string;
    deviceData?: string;
  },
  accessToken: string,
): Promise<any> => {
  const body = {
    data,
    code2fa,
    ...(deviceData ? { deviceData } : {}),
  };
  try {
    const response = await getUserSvcAxios(accessToken).patch('/user/ibanChange', JSON.stringify(body), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return { response: response.data };
  } catch (error) {
    return {
      error: makeError(error, I18n.t('settings.personalInfo.errors.changeIbanInitGenericError')),
    };
  }
};

export type GetTaxResidencySuccess = any;
export type GetTaxResidencyResponse = { response: GetTaxResidencySuccess } | { error: ApiError };

// Sends out an confirmation code
export const getTaxResidency = async (_: any, accessToken: string): Promise<GetTaxResidencySuccess> => {
  try {
    const response = await getUserSvcAxios(accessToken).get('/taxResidency', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return {
      error: makeError(error, I18n.t('settings.personalInfo.errors.changeTaxIdGenericError')),
    };
  }
};

export type PutTaxResidencySuccess = any;
export type PutTaxResidencyResponse = { response: PutTaxResidencySuccess } | { error: ApiError };

// Sends out an confirmation code
export const putTaxResidency = async (
  { id, taxId, country }: { id: string; taxId: string; country: string },
  accessToken: string,
): Promise<PutTaxResidencyResponse> => {
  const body = JSON.stringify({ id, taxId, country });
  try {
    const response = await getUserSvcAxios(accessToken).put(`taxResidency/${id}`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return {
      error: makeError(error, I18n.t('settings.personalInfo.errors.changeTaxIdGenericError')),
    };
  }
};

export type CreateTaxResidencySuccess = any;
export type CreateTaxResidencyResponse = { response: CreateTaxResidencySuccess } | { error: ApiError };

// Sends out an confirmation code
export const createTaxResidency = async (
  { taxId, country }: { taxId: string; country: string },
  accessToken: string,
): Promise<any> => {
  const body = JSON.stringify({ taxId, country }); // edit here

  try {
    const response = await getUserSvcAxios(accessToken).post(`taxResidency/`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return {
      error: makeError(error, I18n.t('settings.personalInfo.errors.changeTaxIdGenericError')),
    };
  }
};

export type DeleteTaxResidencySuccess = any;
export type DeleteTaxResidencyResponse = { response: DeleteTaxResidencySuccess } | { error: ApiError };

// Sends out an confirmation code
export const deleteTaxResidency = async (
  { id }: { id: string },
  accessToken: string,
): Promise<DeleteTaxResidencyResponse> => {
  try {
    const response = await getUserSvcAxios(accessToken).delete(`taxResidency/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return {
      error: makeError(error, I18n.t('settings.personalInfo.errors.changeTaxIdGenericError')),
    };
  }
};

export const fetchPriceAlerts = async (accessToken: string): Promise<any> => {
  const response = await getUserSvcAxios(accessToken).get('/priceAlerts', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  return { response: response.data };
};

export const putPriceAlert = async (alert: any, accessToken: string): Promise<any> => {
  const response = await getUserSvcAxios(accessToken).put('/priceAlerts', alert, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  return { response: response.data };
};

export const deletePriceAlert = async (id: any, accessToken: string): Promise<any> => {
  const response = await getUserSvcAxios(accessToken).delete(`/priceAlerts/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  return { response: response.data };
};

export const fetchSavingsPlans = async (accessToken: string): Promise<any> => {
  const response = await getTradingAxios(accessToken).get('/savingPlan/get', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  return { response: response.data };
};

export const postSavingsPlansStatus = async (
  savingsPlan: { id: number; status: string },
  accessToken: string,
): Promise<any> => {
  const response = await getTradingAxios(accessToken).post(
    '/savingPlan/' + savingsPlan.status,
    { id: savingsPlan.id },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return { response: response.data };
};

export const fetchLimitOrder = async (accessToken: string): Promise<any> => {
  const response = await getTradingAxios(accessToken).get('/limit/get', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  return { response: response.data };
};

export const fetchStopOrders = async (accessToken: string): Promise<any> => {
  const response = await getTradingAxios(accessToken).get(`/stop/get`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  return { response: response.data };
};

export const deleteLimitOrder = async (accessToken: string, uniqueId: string): Promise<any> => {
  const response = await getTradingAxios(accessToken).post(
    '/limit/cancel',
    { uniqueId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return { response: response.data };
};

export const deleteStopOrder = async (accessToken: string, uniqueId: string): Promise<any> => {
  const response = await getTradingAxios(accessToken).post(
    '/stop/cancel',
    { uniqueId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return { response: response.data };
};

export const submitLimitOrder = async (accessToken: string, limitOrderBody: TradingRuleCreateLimitModel) => {
  const response = await getTradingAxios(accessToken).post(
    '/limit/submit',
    { ...limitOrderBody },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return { response: response.data };
};

export const submitStopOrder = async (accessToken: string, stopOrderBody: TradingRuleCreateStopModel) => {
  const response = await getTradingAxios(accessToken).post(
    '/stop/submit',
    { ...stopOrderBody },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return { response: response.data };
};

export const createSavingsPlan = async (accessToken: string, savingsPlan: any) => {
  const response = await getTradingAxios(accessToken).post(
    '/savingPlan/submit',
    { ...savingsPlan },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return { response: response.data };
};

export const deleteSavingsPlan = async (accessToken: string, id: string): Promise<any> => {
  const response = await getTradingAxios(accessToken).post(
    '/savingPlan/disable',
    { id },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
  return { response: response.data };
};

export const fetchBankStatementAvailableMonths = async (_: any, accessToken: string) => {
  try {
    const response = await getUserSvcAxios(accessToken).get(
      '/user/document/bankAccountStatementTimePeriods',
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const formattedData = response.data.timePeriods.map((item) => `${item.month}-${item.year}`);
    return { response: formattedData };
  } catch (error) {
    return { error: makeError(error, I18n.t('error.genericNetwork')) };
  }
};

export type SendBankStatementSuccess = any;
export type SendBankStatementResponse = { response: SendBankStatementSuccess } | { error: ApiError };

export const sendBankStatementRequest = async (
  { bankStatementMonth }: { bankStatementMonth: string },
  accessToken: string,
): Promise<SendBankStatementResponse> => {
  try {
    const [month, year] = bankStatementMonth.split('-');
    const response = await getUserSvcAxios(accessToken).get('/user/document/SendBankAccountStatement', {
      params: { month, year },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('error.genericNetwork')) };
  }
};

export const deleteAccount = async (
  accessToken: string,
  submitDeleteAccountBody: SubmitDeleteAccountBody,
) => {
  try {
    const url = '/user/terminate';

    const response = await axiosUsersvcRegistered.post(
      url,
      { ...submitDeleteAccountBody },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.terminateAccount.genericError')) };
  }
};

const deleteRealAccountUrl = '/user/deleteAccount';

export const checkDeleteRealAccount = async (accessToken: string) => {
  const response = await axiosUsersvcRegistered.post(
    deleteRealAccountUrl,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    },
  );

  return { response: response.data };
};

export const deleteRealAccount = async (
  accessToken: string,
  submitDeleteRealAccountBody: SubmitDeleteRealAccountBody,
) => {
  try {
    const response = await axiosUsersvcRegistered.post(
      deleteRealAccountUrl,
      { ...submitDeleteRealAccountBody },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('settings.terminateAccount.genericError')) };
  }
};

export type TransactionHistorySuccess = any;
export type TransactionHistoryResponse = { response: TransactionHistorySuccess } | { error: ApiError };

export const sendTransactionHistory = async (
  { year }: { year: number },
  accessToken: string,
): Promise<TransactionHistoryResponse> => {
  try {
    const response = await getTradingAxios(accessToken).get('/orders/sendTransactionHistory', {
      ...(year ? { params: { year: year } } : {}),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, I18n.t('error.genericNetwork')) };
  }
};

export const fetchTechIndicatorData = async ({
  pair,
  timePeriod,
  indicator,
}: {
  pair: string;
  timePeriod: TimePeriod;
  indicator: TechIndicatorItem;
}) => {
  try {
    const response = await axiosTechIndicators(pair, timePeriod, indicator);
    return { response };
  } catch (e) {
    return { error: makeError(e) };
  }
};

export const submitFeatureRequest = async (request: any, accessToken: string) => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const body = { request };
    const response = await axiosApi.post('/user/featurerequest', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return {
      error: makeError(error, I18n.t('settings.submitFeature.notification.error.title')),
    };
  }
};

// ************ Event tracking ************
export const flushTrackedEvents = async ({ payload, accessToken }) => {
  try {
    const response = await axiosImproveUX.post('', payload, {
      headers: {
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error) };
  }
};

export const reportEventToBackend = async ({ payload, accessToken }) => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const response = await axiosApi.post('/businessEvent/publish', payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: makeError(error) };
  }
};

export const fetchTradeVolumeData = (_, accessToken) =>
  getTradingAxios(accessToken)
    .get('/entityDetail/tradingInfo', {
      // params: { currency: fiatCurrency },
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error, I18n.t('error.portfolio')) }));

export const fetchTermsAndConditions = (_, accessToken) =>
  getUserSvcAxios(accessToken)
    .get('/termsAndConditions', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error) }));

export const acceptTermsAndConditions = (_, accessToken) =>
  getUserSvcAxios(accessToken)
    .post('/termsAndConditions/accept', null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: makeError(error) }));
// Autocomplete

export const fetchAutocompleteSuggestions = async (accessToken, field, query) => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const response = await axiosApi.get(`/autocomplete/${field}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: query,
    });
    return { data: response.data?.items };
  } catch (error) {
    console.log(error);
    return { error: makeError(error) };
  }
};

export const validateAddress = async (accessToken, address): any => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const response = await axiosApi.get(`/autocomplete/validate`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: address,
    });
    return { ...response.data };
  } catch (error) {
    return { error: makeError(error) };
  }
};

export const sendPrecontractEmailRequest = async (data, accessToken) => {
  try {
    const axiosApi = getUserSvcAxios(accessToken);
    const response = await axiosApi.post(
      '/user/kyc/precontract',
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return { response };
  } catch (error) {
    return { error: makeError(error) };
  }
};

// Device monitoring

// Accept or decline device monitoring consent to retrieve deviceConsentId
export const acceptOrRejectDeviceMonitoringConsent = async (
  username: string,
  consentState: 'accept' | 'decline',
  consentId: string | null,
): Promise<any> => {
  const { nonce, wwwAuthenticate } = await initAuthRequest(axiosUsersvcRegistered);
  const body = {
    username,
    consentType: 'device_monitoring',
    ...(consentId && { reference: consentId }),
  };
  const response = await axiosUsersvcRegistered.post(`/consents/${consentState ?? 'accept'}`, body, {
    headers: {
      Nonce: nonce,
      Authorization: `Bearer ${wwwAuthenticate}`,
    },
  });

  return { response: response.data };
};

// Send device data on kyc
export const sendDeviceData = async (
  username: string,
  deviceData: string,
  accessToken: string,
): Promise<any> => {
  try {
    const body = {
      username,
      consentType: 'device_monitoring',
      deviceData,
    };
    const response = await getUserSvcAxios(accessToken).post('/consents/confirm', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return { response: response.data };
  } catch (error) {
    return { error: makeError(error, 'Sending of device monitoring device data failed') };
  }
};

// Get consent query
export const fetchDeviceMonitoringConsentModel = async (
  country: string | undefined,
  accessToken: string,
): Promise<any> => {
  const params = {
    country,
  };
  const response = await getUserSvcAxios(accessToken).get('/consents/device_monitoring', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    params: country ? params : undefined,
  });

  return { response: response.data };
};
