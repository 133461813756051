import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AccountType } from 'common/const';
import { State } from 'store/types/store';
import { getIsKycDone } from 'store/selectors/auth';
import * as authActions from 'store/actions/auth';
import DisabledFeatures from './view';

const mapStateToProps = (state: State) => ({
  isKycDone: getIsKycDone(state),
  changingAccount: state.auth.changingAccount,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeAccountType: (newAccountType: AccountType) =>
    dispatch(authActions.changeAccount.request(newAccountType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisabledFeatures);
