import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import { dimensions } from 'common/styles';
import { Theme } from 'types/theme';
import devices from '../../styles/devices';

const defaultCheckboxMargins = `${dimensions.mediumPadding}px 0 ${dimensions.mediumPadding}px 0`;

export const Container = styled.div.attrs<{ $dark: boolean }>(({ $dark }: { $dark: boolean }) => ({
  className: `${$dark ? 'body-text' : 'secondary-color-text'} small-text`,
}))``;
export const CheckboxLine = styled.div<{ $disableMargins: boolean; $textAlign?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  align-items: start;
  ${(props) => !props.$disableMargins && `margin: ${defaultCheckboxMargins}`};
  ${({ $textAlign }) => $textAlign && `text-align: ${$textAlign}`};
`;

export const Error = styled(ErrorMessage).attrs({ className: 'large-text', component: 'div' })`
  text-align: right;
  margin-top: 3px;
  @media ${devices.tablet} {
    text-align: left;
  }
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.critical};
  min-height: 20px;
  width: 100%;
`;
