import React, { ReactNode, useState } from 'react';
import { DEFAULT_MODAL_TYPE } from 'common/const/modals';
import ModalContext from './context';
import { ModalProps, ModalType } from './types';
import ModalManager from './ModalManager';
import useModalQueueReset from './useModalQueueReset';

/**
 * ModalProvider creates a Modal Manager at the root of the dom tree
 */
const ModalProvider = ({ children }: { children: ReactNode }) => {
  // modal queue
  const [queue, setQueue] = useState<ModalProps[]>([]);
  const [isQueueBlocked, setIsQueueBlocked] = useState(false);

  const queueForModalManager = isQueueBlocked ? queue.filter((modal) => modal.unblockable) : queue;

  const blockModalQueue = () => setIsQueueBlocked(true);

  const unblockModalQueue = () => setIsQueueBlocked(false);

  const addModal = (modalData: ModalProps) => {
    // Must have onClose implemented in order to queue to work.
    const hasOnCloseCallback = 'onClose' in modalData.component.props;

    if (!hasOnCloseCallback) return;

    setQueue((oldQueue) => [modalData, ...oldQueue]);
  };

  const addModals = (modalData: ModalProps[]) => {
    // Filter out those without onClose implemented.
    const data = modalData.filter((modal) => 'onClose' in modal.component.props);

    setQueue((oldQueue) => [...data, ...oldQueue]);
  };

  const removeModal = () => {
    setQueue((oldQueue) => {
      const newQueue = [...oldQueue];
      newQueue.pop();
      return newQueue;
    });
  };

  const removeModalsByType = (type: ModalType) => {
    setQueue((oldQueue) => [
      ...oldQueue.filter((modal: ModalProps) => {
        const modalType = modal.type ?? DEFAULT_MODAL_TYPE;

        return modalType !== type;
      }),
    ]);
  };

  // Queue will be reset when the logout happens.
  useModalQueueReset(removeModalsByType);

  return (
    <ModalContext.Provider value={{ addModal, queue: [], addModals, blockModalQueue, unblockModalQueue }}>
      <ModalManager queue={queueForModalManager} removeModal={removeModal}></ModalManager>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
