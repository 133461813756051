import pricesSlice from '.';

export const {
  // Crypto
  subscribeToCryptoPrices,
  subscribeToCryptoPricesError,
  subscribeToCryptoPricesSuccess,
  unsubscribeFromCryptoPrices,

  // Securities
  subscribeToSecurityPrices,
  subscribeToSecurityPricesError,
  subscribeToSecurityPricesSuccess,
  unsubscribeFromSecurityPrices,
} = pricesSlice.actions;
