export const screenSizeHeight = {
  small: 568,
};

export const devicesHeight = {
  small: `(min-height: ${screenSizeHeight.small}px)`,
  maxSmall: `(max-height: ${screenSizeHeight.small}px)`,
};

export const screenSize = {
  mobileS: 320,
  maxMobileS: 374,
  mobileM: 375,
  mobileL: 430,
  mobileXL: 480,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const devices = {
  mobileS: `(min-width: ${screenSize.mobileS}px)`,
  mobileM: `(min-width: ${screenSize.mobileM}px)`,
  mobileL: `(min-width: ${screenSize.mobileL}px)`,
  mobileXL: `(min-width: ${screenSize.mobileXL}px)`,
  tablet: `(min-width: ${screenSize.tablet + 1}px)`,
  laptop: `(min-width: ${screenSize.laptop}px)`,
  laptopL: `(min-width: ${screenSize.laptopL}px)`,
  desktop: `(min-width: ${screenSize.desktop}px)`,
  desktopL: `(min-width: ${screenSize.desktop}px)`,
  maxMobileS: `(max-width: ${screenSize.maxMobileS}px)`,
  maxMobileM: `(max-width: ${screenSize.mobileM}px)`,
  maxMobileL: `(max-width: ${screenSize.mobileL}px)`,
  maxMobileXL: `(max-width: ${screenSize.mobileXL}px)`,
  maxTablet: `(max-width: ${screenSize.tablet}px)`,
  maxLaptop: `(max-width: ${screenSize.laptop}px)`,
  maxLaptopL: `(max-width: ${screenSize.laptopL}px)`,
  maxDesktop: `(max-width: ${screenSize.desktop}px)`,
  maxDesktopL: `(max-width: ${screenSize.desktop}px)`,
};

export default devices;
