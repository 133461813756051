/* eslint-disable @typescript-eslint/no-unsafe-return */
import type { Action } from '@reduxjs/toolkit';
import { from, of, timer, zip, forkJoin } from 'rxjs';
import { catchError, filter, map, mergeMap, pluck, switchMap, takeUntil, take, tap } from 'rxjs/operators';
import {
  getAccessToken,
  getIsB2bUser,
  getIsUserInPaperTrading,
  getKycStatus,
  getSecuritiesKycStatus,
} from 'store/selectors/auth';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { handleAjaxError } from 'store/epics/auth';
import { RootEpic } from 'types/common';
import { isOfType } from 'safetypings';
import { SCROLL_PAGE_SIZE } from 'common/const';
import {
  deleteMessageRequest,
  deleteMessageSuccess,
  documentsFailure,
  documentsRequest,
  documentsSuccess,
  editAllMessagesRequest,
  editAllMessagesSuccess,
  fetchTotalUnreadCountFailure,
  fetchTotalUnreadCountRequest,
  fetchTotalUnreadCountSuccess,
  inboxFailure,
  inboxRequest,
  inboxSuccess,
  invalidateInbox,
  readAllDocumentsError,
  readAllDocumentsRequest,
  readAllDocumentsSuccess,
  readMessageError,
  readMessageRequest,
  readMessageSuccess,
  readMessageWithDocumentRequest,
  readMessageWithDocumentSuccess,
  triggerFetchMessagesByCategory,
} from 'store/slices/inbox/actions';
import {
  selectAllMessages,
  selectAllMessagesWithDocuments,
  selectIsOnInboxPage,
} from 'store/slices/inbox/selectors';
import I18n from 'i18next';
import { Message, MessageWithDocument } from 'types/inbox';
import { combineEpics } from 'redux-observable';
import { INBOX_CURSOR_DIRECTIONS, InboxCursorType, TTL } from 'common/const/inbox';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { LOGIN_USER_SUCCESS, LOGOUT_USER } from 'store/actions/auth';
import { canUseSecurities } from 'common/utils/securities';
import { getFeatureStocksEnabled } from 'store/selectors/environment';
import { getBackendIsEligibleCandidateForStocks, getStocksSwitch } from 'store/selectors/settings';
import { getKycFormData } from 'store/selectors/forms';
import { getInboxFilters } from 'store/slices/filters/selectors';
import { setIsImportantMessagesModalWasDisplayed } from 'common/utils/importantMessagesSessionStorage';

export const composeInboxPayloadByCursor = (
  cursorDirection: InboxCursorType = INBOX_CURSOR_DIRECTIONS.DEFAULT,
  loadedMessages: Message[],
): Record<string, any> => {
  const payload: Record<string, any> = {};
  payload.language = I18n.t('l10n.lang');
  payload.cursorDirection = cursorDirection;
  if (loadedMessages.length !== 0) {
    const referenceItem = cursorDirection === INBOX_CURSOR_DIRECTIONS.DEFAULT ? loadedMessages.length - 1 : 0;
    payload.reference = loadedMessages[referenceItem].id;
  }

  if (cursorDirection === INBOX_CURSOR_DIRECTIONS.DEFAULT) {
    payload.size = SCROLL_PAGE_SIZE;
  }

  return payload;
};

export const composePostboxPayloadByCursor = (
  cursorDirection: InboxCursorType = INBOX_CURSOR_DIRECTIONS.DEFAULT,
  loadedMessagesLength: number,
): Record<string, any> => {
  const payload: Record<string, any> = {};

  if (cursorDirection === INBOX_CURSOR_DIRECTIONS.DEFAULT) {
    payload.skip = loadedMessagesLength;
    payload.limit = SCROLL_PAGE_SIZE;
  }

  return payload;
};

export const fetchInbox: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(inboxRequest.match),
    map(({ payload: { cursorDirection } }) => {
      const loadedMessages = selectAllMessages(state$.value);
      return {
        accessToken: getAccessToken(state$.value),
        composedPayload: composeInboxPayloadByCursor(cursorDirection, loadedMessages),
      };
    }),
    filter(({ accessToken }) => accessToken),
    switchMap(({ accessToken, composedPayload }) =>
      ajax({
        url: api
          .getBaseUrlForUserServices(accessToken)
          .concat('/inbox')
          .concat(queryArrayString(composedPayload)),
        method: 'GET',
        withCredentials: true, // include cookies
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        pluck('response'),
        map((response: { items: Message[]; unreadOverall: number }) => {
          return inboxSuccess({ messages: response.items, inboxCount: response.unreadOverall });
        }),
      ),
    ),
    catchError(
      handleAjaxError(
        action$,
        (error) => inboxFailure({ errMsg: I18n.t('error.inbox') }),
        {},
        { message: I18n.t('error.inbox') },
      ),
    ),
  );

export const fetchMessagesWithDocuments: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(documentsRequest.match),
    map(({ payload: { cursorDirection } }) => {
      const messages = selectAllMessagesWithDocuments(state$.value);
      const composedPayload = composePostboxPayloadByCursor(cursorDirection, messages.length);
      return {
        accessToken: getAccessToken(state$.value),
        composedPayload,
      };
    }),
    filter(({ accessToken }) => accessToken),
    switchMap(({ accessToken, composedPayload }) =>
      ajax({
        url: api
          .getBaseUrlForUserServices(accessToken)
          .concat('/stocks/postbox-documents')
          .concat(queryArrayString(composedPayload)),
        method: 'GET',
        withCredentials: true, // include cookies
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        pluck('response'),
        map((response: { result: MessageWithDocument[]; total: number }) => {
          return documentsSuccess({ messages: response.result, totalPostbox: response.total });
        }),
      ),
    ),
    catchError(
      handleAjaxError(
        action$,
        (error) => documentsFailure({ errMsg: I18n.t('error.inbox') }),
        {},
        { message: I18n.t('error.inbox') },
      ),
    ),
  );

export const fetchMessagesByCategory: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(triggerFetchMessagesByCategory.match),
    map(({ payload: { cursorDirection } }) => {
      const { selectedCategory } = getInboxFilters(state$.value);

      return selectedCategory === 'notifications'
        ? inboxRequest({ cursorDirection })
        : documentsRequest({ cursorDirection });
    }),
  );
};

export const readMessage: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(readMessageRequest.match),
    map(({ payload: { id, notifyUser } }) => {
      return { id, notifyUser, accessToken: getAccessToken(state$.value) };
    }),
    filter(({ accessToken }) => accessToken),
    switchMap(({ accessToken, notifyUser, id }) =>
      ajax({
        url: api
          .getBaseUrlForUserServices(accessToken)
          .concat(`/inbox/${id}`)
          .concat(
            queryArrayString({
              isRead: true,
            }),
          ),
        withCredentials: true, // include cookies
        method: 'PUT',
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        mergeMap(() => {
          const emmitedActions: Action[] = [readMessageSuccess({ id, notifyUser })];
          if (notifyUser)
            emmitedActions.push(
              notificationBannerActions.notifySuccess({
                message: I18n.t('inbox.successfullyMarkedAsRead'),
              }),
            );
          return of(...emmitedActions);
        }),
      ),
    ),
    catchError(handleAjaxError(action$, () => readMessageError({ errMsg: I18n.t('error.inbox') }))),
  );

export const deleteMessage: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(deleteMessageRequest.match),
    map(({ payload: { id, notifyUser } }) => {
      const accessToken = getAccessToken(state$.value);
      return { accessToken, id, notifyUser };
    }),
    filter(({ accessToken }) => accessToken),
    switchMap(({ accessToken, id, notifyUser }) =>
      ajax({
        url: api
          .getBaseUrlForUserServices(accessToken)
          .concat(`/inbox/${id}`)
          .concat(
            queryArrayString({
              isDeleted: true,
            }),
          ),
        withCredentials: true, // include cookies
        method: 'PUT',
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        mergeMap(() => {
          const emmitedActions: Action[] = [deleteMessageSuccess({ id, notifyUser })];
          if (notifyUser)
            emmitedActions.push(
              notificationBannerActions.notifyInfo({
                message: I18n.t('inbox.successfullyDeleted'),
              }),
            );
          return of(...emmitedActions);
        }),
      ),
    ),
    catchError(
      handleAjaxError(
        action$,
        (error) => {
          return readMessageError({ errMsg: I18n.t('inbox.errorDeletingMany') });
        },
        {},
        { message: I18n.t('inbox.errorDeletingMany') },
      ),
    ),
  );

export const editAllMessages: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(editAllMessagesRequest.match),
    map(({ payload }) => {
      const accessToken = getAccessToken(state$.value);
      return { accessToken, payload };
    }),
    filter(({ accessToken }) => accessToken),
    switchMap(({ accessToken, payload }) => {
      return ajax({
        url: api.getBaseUrlForUserServices(accessToken).concat(`/inbox`).concat(queryArrayString(payload)),
        withCredentials: true, // include cookies
        method: 'PUT',
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        pluck('response'),
        mergeMap(() => {
          const emmitedActions: Action[] = [editAllMessagesSuccess(payload)];
          if (payload.isRead) {
            emmitedActions.push(
              notificationBannerActions.notifySuccess({
                message: I18n.t('inbox.successfullyMarkedAsRead'),
              }),
            );
          } else if (payload.isDeleted) {
            emmitedActions.push(
              notificationBannerActions.notifyInfo({
                message: I18n.t('inbox.successfullyDeletedMany'),
              }),
            );
          }

          return of(...emmitedActions);
        }),
      );
    }),
    catchError(
      handleAjaxError(action$, (error) => {
        return readMessageError({ errMsg: I18n.t('inbox.errorDeletingMany') });
      }),
    ),
  );

export const readMessageWithDocument: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(readMessageWithDocumentRequest.match),
    map(({ payload: { id, notifyUser = false, refreshLink = false, readWithoutLooking = false } }) => {
      return { id, notifyUser, accessToken: getAccessToken(state$.value), refreshLink, readWithoutLooking };
    }),
    filter(({ accessToken }) => accessToken),
    mergeMap(({ accessToken, notifyUser, id, refreshLink, readWithoutLooking }) =>
      ajax({
        url: api.getBaseUrlForUserServices(accessToken).concat(`/stocks/postbox-documents/${id}`),
        withCredentials: true, // include cookies
        method: 'GET',
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        pluck('response'),
        mergeMap(({ document }: { document: MessageWithDocument }) => {
          const emmitedActions: Action[] = [
            readMessageWithDocumentSuccess({ document, refreshLink, readWithoutLooking }),
          ];
          if (notifyUser)
            emmitedActions.push(
              notificationBannerActions.notifySuccess({
                message: I18n.t('inbox.successfullyMarkedAsRead'),
              }),
            );
          return of(...emmitedActions);
        }),
      ),
    ),

    catchError(handleAjaxError(action$, () => readMessageError({ errMsg: I18n.t('error.inbox') }))),
  );

export const readAllDocuments: RootEpic = (action$, state$, { ajax, api }) => {
  return action$.pipe(
    filter(readAllDocumentsRequest.match),
    mergeMap(() => {
      const documents = selectAllMessagesWithDocuments(state$.value);
      const accessToken = getAccessToken(state$.value);

      const requestUrls = documents
        .filter(({ isRead }) => !isRead)
        .map(({ id }) =>
          api.getBaseUrlForUserServices(accessToken).concat(`/stocks/postbox-documents/${id}`),
        );

      return zip(
        ...requestUrls.map((url) =>
          ajax({
            url: url,
            withCredentials: true, // include cookies
            method: 'GET',
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }),
        ),
      ).pipe(
        map((responses) => responses.map(({ response }) => response.document)),
        filter((documentsUpdates) => documentsUpdates.every(({ isRead }) => isRead)),
        mergeMap(() =>
          of(
            ...[
              readAllDocumentsSuccess(),
              notificationBannerActions.notifySuccess({
                message: I18n.t('inbox.successfullyMarkedAsRead'),
              }),
            ],
          ),
        ),
      );
    }),
    catchError(
      handleAjaxError(
        action$,
        (error) => readAllDocumentsError(),
        {},
        { message: I18n.t('inbox.errorDeletingMany') },
      ),
    ),
  );
};

export const fetchTotalUnread: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(fetchTotalUnreadCountRequest.match),
    map(() => {
      const isFeatureStocksEnabled = getFeatureStocksEnabled(state$.value);
      const isStocksEnabledByUser = getStocksSwitch(state$.value);
      const cryptoKycStatus = getKycStatus(state$.value);
      const stocksKycStatus = getSecuritiesKycStatus(state$.value);
      const isB2bUser = getIsB2bUser(state$.value);
      const isDemo = getIsUserInPaperTrading(state$.value);
      const kycData = getKycFormData(state$.value);
      const backendIsEligibleCandidateForStocks = getBackendIsEligibleCandidateForStocks(state$.value);

      const { stocksEnabled } = canUseSecurities(
        isFeatureStocksEnabled,
        isStocksEnabledByUser,
        cryptoKycStatus,
        stocksKycStatus,
        isB2bUser,
        isDemo,
        kycData,
        backendIsEligibleCandidateForStocks,
      );

      return {
        accessToken: getAccessToken(state$.value),
        shouldFetchPostbox: stocksEnabled && !isDemo,
      };
    }),
    filter(({ accessToken }) => accessToken),
    switchMap(({ accessToken, shouldFetchPostbox }) => {
      const requestUrls = [api.getBaseUrlForUserServices(accessToken).concat(`/inbox/unread-count`)];
      if (shouldFetchPostbox) {
        requestUrls.push(
          api.getBaseUrlForUserServices(accessToken).concat(`/stocks/postbox-documents-count`),
        );
      }

      return forkJoin(
        requestUrls.map((countUrl) =>
          ajax({
            url: countUrl,
            withCredentials: true, // include cookies
            method: 'GET',
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            catchError(
              handleAjaxError(action$, (error) => {
                return fetchTotalUnreadCountFailure({ errMsg: I18n.t('error.inbox') });
              }),
            ),
          ),
        ),
      ).pipe(
        map(
          ([
            inbox = { response: { unreadCount: 0, isNotificationRequired: false } },
            postbox = { response: { total: 0 } },
          ]) => {
            const {
              response: { unreadCount, isNotificationRequired },
            } = inbox;

            return fetchTotalUnreadCountSuccess({
              numOfUnread: {
                postboxCount: postbox?.response?.total ?? 0,
                inboxCount: unreadCount,
                isNotificationRequired,
              },
            });
          },
        ),
      );
    }),
    catchError(
      handleAjaxError(action$, (error) => {
        return fetchTotalUnreadCountFailure({ errMsg: I18n.t('error.inbox') });
      }),
    ),
  );

const bgReadCountSync: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType([LOGIN_USER_SUCCESS])),
    switchMap(() => {
      return timer(0, TTL.INBOX).pipe(
        takeUntil(action$.pipe(filter(isOfType([LOGOUT_USER])))),
        mergeMap(() => {
          const emmitedActions: Action[] = [fetchTotalUnreadCountRequest()];
          const isOnInboxPage = selectIsOnInboxPage(state$.value);

          if (isOnInboxPage)
            emmitedActions.push(
              triggerFetchMessagesByCategory({ cursorDirection: INBOX_CURSOR_DIRECTIONS.REFRESH }),
            );

          return from(emmitedActions);
        }),
      );
    }),
    catchError(
      handleAjaxError(action$, (error) => fetchTotalUnreadCountFailure({ errMsg: I18n.t('error.inbox') })),
    ),
  );

const watchLogout: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType([LOGOUT_USER])),
    // reset important messages session storage flag when user logs out
    tap(() => setIsImportantMessagesModalWasDisplayed(false)),
    map(() => invalidateInbox()),
  );
};

export default combineEpics(
  fetchInbox,
  fetchMessagesWithDocuments,
  fetchMessagesByCategory,
  readMessage,
  readMessageWithDocument,
  deleteMessage,
  editAllMessages,
  fetchTotalUnread,
  bgReadCountSync,
  readAllDocuments,
  watchLogout,
);
