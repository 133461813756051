import { paths } from 'common/urls';
import { call, select } from 'redux-saga/effects';
import * as trackingActions from 'store/actions/tracking';
import getCurrentPath from 'store/selectors/navigation';
import * as biEvents from '../biEventDefinitions';
import { getIsKycFormEmpty, getKycFormMeta } from 'store/selectors/forms';

export function* logKycEvents(
  action: ReturnType<typeof trackingActions.trackAppEvent>,
): Generator<any, any, any> {
  const { currentStepName } = yield select(getKycFormMeta);
  const screenId = paths.KYC;
  const fromScreenId = yield select(getCurrentPath);
  const screenIdWithKycStepId = `${screenId}.${currentStepName}`;
  switch (action.eventName) {
    case 'kycNextPressed': {
      const { success } = action.payload;
      const validationErrors = action.payload.errors;
      yield call(biEvents.trackKycPressButtonNextKycStep, screenIdWithKycStepId, success, validationErrors);
      break;
    }
    case 'kycBackPressed': {
      yield call(biEvents.trackKycPressButtonPreviousKycStep, screenIdWithKycStepId);
      break;
    }
    case 'kycOpenScreen': {
      const isKycFormEmpty = yield select(getIsKycFormEmpty);
      yield call(biEvents.trackKycOpenScreen, screenId, fromScreenId, !isKycFormEmpty);
      break;
    }
    default:
      break;
  }
}
