import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { privatePaths } from 'common/urls';
import { ModalType } from './types';

const useModalQueueReset = (removeByType: (type: ModalType) => void) => {
  const location = useLocation();

  const [wasPreviousPagePrivate, setWasPreviousPagePrivate] = useState<boolean>(false);

  useEffect(() => {
    const isPrivatePage = Object.values(privatePaths).some((path: string) =>
      matchPath({ path }, location.pathname),
    );

    if (wasPreviousPagePrivate && !isPrivatePage) {
      removeByType('private');
    }

    setWasPreviousPagePrivate(isPrivatePage);
  }, [location]);
};

export default useModalQueueReset;
