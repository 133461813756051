import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'store/selectors/auth';
import { TermsAndConditionsData } from 'types/termsAndConditions';

const SPECIAL_CASE_TERMS_AND_CONDITIONS_UPDATE = 'PASSIVE_STAKING_AUG2023_TERMS';

export const useCheckSpecificTermsAndConditionsCase = (data: TermsAndConditionsData) => {
  const [isSpecificTermsAndConditions, setSpecificTermsAndConditions] = useState(false);
  const { address } = useSelector(getUser);

  useEffect(() => {
    if (address?.country !== 'AT' && data?.latestTerms?.name === SPECIAL_CASE_TERMS_AND_CONDITIONS_UPDATE) {
      setSpecificTermsAndConditions(true);
    }
  }, [data?.latestTerms?.name, address?.country]);

  return {
    requiresSecondCheckBox: isSpecificTermsAndConditions,
  };
};
