/*
  session storage used here to show important message modal only ONCE per session
  without this flag modal displayed every time user navigates to one of IMPORTANT_MESSAGES_MODAL_ALLOWED_PAGES pages
  until the messages are marked as read
*/

const IS_IMPORTANT_MESSAGES_MODAL_WAS_DISPLAYED_SESSION_STORAGE_KEY = 'isImportantMessagesModalWasDisplayed';

export const getIsImportantMessagesModalWasDisplayed = (): boolean =>
  !!JSON.parse(sessionStorage.getItem(IS_IMPORTANT_MESSAGES_MODAL_WAS_DISPLAYED_SESSION_STORAGE_KEY));

export const setIsImportantMessagesModalWasDisplayed = (value: boolean) =>
  sessionStorage.setItem(
    IS_IMPORTANT_MESSAGES_MODAL_WAS_DISPLAYED_SESSION_STORAGE_KEY,
    JSON.stringify(value),
  );
