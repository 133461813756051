import React, { BaseSyntheticEvent, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { paths } from 'common/urls';
import { setStakingBlockedModalStatus } from 'store/slices/staking/actions';
import { StakingRoleBlockedReason } from 'types/staking';
import { generatePath, Location } from 'react-router-dom';
import { showFeatureTermsAndConditions } from 'store/actions/termsAndConditions';
import { CONSENT_TYPES } from 'common/const/consent';
import { getIsFeatureSpecificTncNotAccepted } from 'store/selectors/termsAndConditions';
import { NavItem, NavItems } from '../styles';

type Props = {
  location: Location;
  selectedCrypto: string;
  stakingRoleBlockedReason: StakingRoleBlockedReason;
  isDemo: boolean;
};

const MainTabs: FC<Props> = ({ selectedCrypto, stakingRoleBlockedReason }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isStakingFeatureSpecificTandCNotExcepted = useSelector((state) =>
    getIsFeatureSpecificTncNotAccepted(state, CONSENT_TYPES.ACTIVE_STAKING),
  );

  const checkStakingRole = (e: BaseSyntheticEvent) => {
    if (stakingRoleBlockedReason) {
      e?.preventDefault();
      dispatch(setStakingBlockedModalStatus({ isOpened: true, type: stakingRoleBlockedReason }));
    } else if (isStakingFeatureSpecificTandCNotExcepted) {
      e?.preventDefault();
      dispatch(showFeatureTermsAndConditions(true, CONSENT_TYPES.ACTIVE_STAKING));
    }
  };

  return (
    <NavItems>
      <NavItem to={generatePath(paths.PORTFOLIO_CRYPTO, { currency: selectedCrypto.toString() })} end>
        {t('mainScreenTitles.portfolio')}
      </NavItem>
      <NavItem to={paths.MARKET_OVERVIEW}>{t('mainScreenTitles.market')}</NavItem>
      <NavItem onClick={checkStakingRole} to={paths.STAKING}>
        {t('mainScreenTitles.staking')}
      </NavItem>
      <NavItem to={paths.ORDERS}>{t('mainScreenTitles.orders')}</NavItem>
    </NavItems>
  );
};

export default MainTabs;
