/* eslint-disable @typescript-eslint/no-unsafe-return */

import { OrderData, OrderPreview, QuoteData } from 'types/securityOrder';
import { State } from 'store/types/store';

export const getOrderData = (state: State): OrderData => state.stockEtfOrder.orderData.data;

export const getIsOrderDataFetching = (state: State): boolean => state.stockEtfOrder.orderData.isFetching;

export const getReservedOrderData = (state: State): QuoteData => state.stockEtfOrder.reservedQuote.data;

export const getIsReservedOrderLoading = (state: State): boolean =>
  state.stockEtfOrder.reservedQuote.isQuoteFetching;

export const getIsReservedOrderFetchingSuccess = (state: State): boolean =>
  state.stockEtfOrder.reservedQuote.isQuoteFetchingSuccess;

export const getOrderStatus = (state: State) => state.stockEtfOrder.orderStatus;

export const getQuoteAvailibleForInSeconds = (state: State): number | undefined =>
  state.stockEtfOrder.reservedQuote.quoteValidForInSeconds;
