import I18n from 'i18next';
import initIi18n from '../../locales/i18n';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
initIi18n();

export const tiSMA = 'sma';
export const tiRSI = 'rsi';

export const techIndicatorTypes = [tiSMA, tiRSI];
export const techIndicatorPeriods = [10, 20, 50, 100];

export const sma10 = 'sma10';
export const sma20 = 'sma20';
export const sma50 = 'sma50';
export const sma100 = 'sma100';

export const rsi10 = 'rsi10';
export const rsi20 = 'rsi20';
export const rsi50 = 'rsi50';
export const rsi100 = 'rsi100';

export const techIndicators = [
  {
    value: sma10,
    label: 'SMA (10)',
    text: I18n.t('home.currency.indicators.sma.option', { period: 10 }),
    color: '#f26fe4',
    type: tiSMA,
    period: 10,
  },
  {
    value: sma20,
    label: 'SMA (20)',
    text: I18n.t('home.currency.indicators.sma.option', { period: 20 }),
    color: '#72BF00',
    type: tiSMA,
    period: 20,
  },
  {
    value: sma50,
    label: 'SMA (50)',
    text: I18n.t('home.currency.indicators.sma.option', { period: 50 }),
    color: '#F2A900',
    type: tiSMA,
    period: 50,
  },
  {
    value: sma100,
    label: 'SMA (100)',
    text: I18n.t('home.currency.indicators.sma.option', { period: 100 }),
    color: '#F0E68C',
    type: tiSMA,
    period: 100,
  },
  {
    value: rsi10,
    label: 'RSI (10)',
    text: I18n.t('home.currency.indicators.rsi.option', { period: 10 }),
    color: '#f26fe4',
    type: tiRSI,
    period: 10,
  },
  {
    value: rsi20,
    label: 'RSI (20)',
    text: I18n.t('home.currency.indicators.rsi.option', { period: 20 }),
    color: '#F2A900',
    type: tiRSI,
    period: 20,
  },
  {
    value: rsi50,
    label: 'RSI (50)',
    text: I18n.t('home.currency.indicators.rsi.option', { period: 50 }),
    color: '#72BF00',
    type: tiRSI,
    period: 50,
  },
  {
    value: rsi100,
    label: 'RSI (100)',
    text: I18n.t('home.currency.indicators.rsi.option', { period: 100 }),
    color: '#FFC0CB',
    type: tiRSI,
    period: 100,
  },
];

export const techIndicatorValues = techIndicators.map((item) => item.value);
