import { createTheme } from '@mui/material';
import { switchClasses } from '@mui/material/Switch';
import { Theme } from 'types/theme';
import { dimensions } from 'common/styles';

const THUMB_SIZE_IN_PX = 24;

export const useToggleSwitchTheme = (theme: Theme) =>
  createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: dimensions.smallPadding,
          },
          switchBase: {
            padding: 1,
            [`&.${switchClasses.checked}`]: {
              transform: 'translateX(16px)',
              [`& + .${switchClasses.track}`]: {
                backgroundColor: theme.color.toggleSwitch.enabled,
                opacity: 1,
                border: 'none',
              },
              [`& .${switchClasses.thumb}`]: {
                backgroundColor: theme.color.toggleSwitch.switchBase,
              },
            },
          },
          thumb: {
            width: THUMB_SIZE_IN_PX,
            height: THUMB_SIZE_IN_PX,
            backgroundColor: theme.color.toggleSwitch.switchBaseDisabled,
          },
          track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.color.toggleSwitch.border}`,
            backgroundColor: theme.color.toggleSwitch.disabled,
            opacity: 1,
          },
        },
      },
    },
  });
