import styled from 'styled-components';
import { AUDIT_PROOF_MODAL_TYPES, MODAL_TYPE_TO_IMG_MAP } from 'common/const/auditProof';
import { $Keys } from 'utility-types';
import { Theme } from 'types/theme';

export const Img = styled.img.attrs(
  ({ modalType }: { modalType: $Keys<typeof AUDIT_PROOF_MODAL_TYPES> }): Record<string, string> => ({
    src: MODAL_TYPE_TO_IMG_MAP[modalType],
  }),
)`
  display: block;
  text-align: center;
  height: auto;
  margin: 0 auto;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const Title = styled.h2`
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size[2].fontSize} !important;
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.heading.size[2].lineHeight} !important;
  padding: 0 0 24px;
  text-align: center;
`;

export const ExitIntentTitle = styled.div.attrs({ className: 'xlarge-text' })`
  padding: 10px 15px 24px;
  text-align: center;
`;

export const Text = styled.div.attrs({ className: 'small-text' })`
  text-align: center;
`;

export const WarningText = styled(Text)`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.critical};
  padding-top: 28px;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  max-width: 344px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & > * {
    margin: 0;
  }
`;
