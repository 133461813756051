import React, { Fragment, ReactNode } from 'react';
import Maintenance from 'scenes/Welcome/Maintenance';
import useMaintenance from './useMaintenance';

/**
 * MaintenanceProvider adds maintenance page to the app.
 */
const MaintenanceProvider = ({ children }: { children: ReactNode }) => {
  const isDisplayed = useMaintenance();

  return isDisplayed ? <Maintenance /> : <Fragment>{children}</Fragment>;
};

export default MaintenanceProvider;
