// SECURITIES

/**
 * This is how long a specific security's price change will not be re-fetched.
 * Instead the stored/cached price change will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const SECURITY_PRICE_CHANGE_DATA_TTL = 1000 * 60 * 5;

/**
 * This is how often the re-fetching of securities is re-calculated.
 * If the calculation states that a specific security is ready to be re-fetched its new price change data will be retrieved from the server.
 *
 * The value is specified in milliseconds.
 */
export const SECURITY_PRICE_CHANGE_FETCH_INTERVAL_IN_MS = 1000 * 30;

/**
 * An endpoint from where price changes for securities are retrieved.
 */
export const SECURITY_PRICE_CHANGES_ENDPOINT = '/prices/changes/stocks';

// CRYPTO

/**
 * This is how long a specific crypto's price change will not be re-fetched.
 * Instead the stored/cached price change will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_PRICE_CHANGE_DATA_TTL = 1000 * 30;

/**
 * This is how often the re-fetching of cryptos is re-calculated.
 * If the calculation states that a specific crypto is ready to be re-fetched its new price change data will be retrieved from the server.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_PRICE_CHANGE_FETCH_INTERVAL_IN_MS = 1000 * 30;

/**
 * An endpoint from where price changes for cryptos are retrieved.
 */
export const CRYPTO_PRICE_CHANGES_ENDPOINT = '/prices/changes/crypto';
