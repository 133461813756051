import styled from 'styled-components';
import _Icon from 'common/components/Icon';
import DownloadButton from '../DownloadButton';

export const Attachment = styled(DownloadButton)`
  margin-top: 12px;
  max-width: 100%;

  & button {
    width: 100%;
    text-decoration: none;
    color: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ModalIcon = styled(_Icon)`
  margin-right: 12px;
`;

export const AttachmentName = styled.span`
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
