import { seonSdkDomain } from 'common/urls';

interface SeonSdkConfigProps {
  audio_fingerprint?: boolean;
  canvas_fingerprint?: boolean;
  webgl_fingerprint?: boolean;
  host: string;
  onError: (message: string) => void;
  onSuccess: (message: string) => void;
  session_id: string;
  dnsTimeout?: number;
  cname_server?: boolean;
  similarity_hash?: string | undefined;
  verbose?: boolean;
}

declare global {
  interface Window {
    seon?: {
      config: (options: SeonSdkConfigProps) => void;
      getBase64Session: (cb?: (data: string) => void) => Promise<string>;
      opts: SeonSdkConfigProps;
      version: string;
    };
  }
}

const SEON_SESSION_REMOVED = 'session_removed';

export async function getDeviceData(): Promise<{ deviceData?: string; error?: string }> {
  try {
    if (!window.seon?.opts?.session_id || window.seon?.opts?.session_id === SEON_SESSION_REMOVED)
      throw new Error('Missing deviceConsentId');
    const res = await window.seon?.getBase64Session();
    return { deviceData: res };
  } catch (error: any) {
    return { error };
  }
}

export function setSeonSdkConfig(deviceConsentId: string, onSuccess?: () => void, onError?: () => void) {
  window.seon?.config({
    host: seonSdkDomain,
    session_id: deviceConsentId || SEON_SESSION_REMOVED, // SEON_SESSION_REMOVED value represents an undefined value for us, because there is no option to remove session_id once it is set
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
    onSuccess: (message) => {
      if (onSuccess) onSuccess();
    },
    onError: (message) => {
      if (onError) onError();
    },
  });
}
