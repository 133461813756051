import { AnyAction, combineReducers } from 'redux';

import { RESET_ACCOUNT_DELETED, ACCOUNT_DELETED } from 'store/actions/misc';
import { ActiveHours, MiscAccount } from 'types/misc';

function activeHours(
  state: ActiveHours = {
    timezone: 'Europe/Berlin',
    trading: { from: '00:00', to: '23:59' },
    kyc: { from: '08:00', to: '23:59' },
  },
  action: AnyAction,
) {
  switch (action.type) {
    default:
      return state;
  }
}

function account(
  state: MiscAccount = {
    isDeleted: false,
  },
  action: AnyAction,
) {
  switch (action.type) {
    case ACCOUNT_DELETED:
      return {
        ...state,
        isDeleted: true,
      };
    case RESET_ACCOUNT_DELETED:
      return {
        ...state,
        isDeleted: false,
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  activeHours,
  account,
});

export default rootReducer;
