import React, { ReactElement } from 'react';
import { useStocksTradingBanner } from 'common/hooks/useStocksTradingHoursBanner';
import { useSelector } from 'react-redux';
import { getSecurityMarketStatus } from 'store/selectors/status';
import StockTradingHours from './StockTradingHours';

const PageBanners = (): ReactElement | null => {
  const stockMarketStatus = useSelector(getSecurityMarketStatus);
  const shouldShowTradingHoursBanner = useStocksTradingBanner();

  return <>{shouldShowTradingHoursBanner && <StockTradingHours stockMarketStatus={stockMarketStatus} />}</>;
};

export default PageBanners;
