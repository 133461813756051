/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable spaced-comment */

import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import { isOfType, isPresent } from 'safetypings';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { RootEpic } from 'types/common';
import { Observable, interval } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { backoff } from 'common/utils/rxjs-operators';
import { getSelectedTimePeriod } from '../selectors/currency';
import { InnerTimePeriodToStockEtfRequestParam, timePeriodToQueryParamCrypto } from 'common/const';
import { getAccessToken } from 'store/selectors/auth';
import { handleAjaxError } from './auth';
import { State } from 'store/types/store';
import {
  CRYPTO_PRICE_HISTORY_FETCH_INTERVAL_IN_MS,
  SECURITY_PRICE_HISTORY_ENDPOINT,
  SECURITY_PRICE_HISTORY_FETCH_INTERVAL_IN_MS,
  SECURITY_PRICE_HISTORY_HEALTH_CHECK_ENDPOINT,
  SECURITY_PRICE_HISTORY_HEALTH_CHECK_INTERVAL_IN_MS,
} from 'common/const/priceHistory';
import { combineEpics, ofType } from 'redux-observable';
import { ASSET_CLASSES } from 'types/assets';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  subscribeToCryptoPriceHistory,
  subscribeToCryptoPriceHistoryError,
  subscribeToCryptoPriceHistorySuccess,
  subscribeToSecurityChartHealthCheck,
  subscribeToSecurityChartHealthCheckError,
  subscribeToSecurityChartHealthCheckSuccess,
  subscribeToSecurityPriceHistory,
  subscribeToSecurityPriceHistoryError,
  subscribeToSecurityPriceHistorySuccess,
  unsubscribeFromCryptoPriceHistory,
  unsubscribeFromSecurityChartHealthCheck,
  unsubscribeFromSecurityPriceHistory,
} from 'store/slices/priceHistory/actions';
import { getEligiblePriceHistoryCodeByAssetClassAndTimePeriod } from 'store/slices/priceHistory/selectors';
import { PriceHistoryCryptoData, PriceHistorySecurityData } from 'store/types/priceHistory';

// Re-start subscribe to security price history every time a time period is changed.
const watchSubscribeToSecurityPriceHistory = (action$: Observable<PayloadAction<{ code: string }>>) =>
  action$.pipe(
    filter(isOfType([subscribeToSecurityPriceHistory.type])),
    filter((action: PayloadAction<{ code: string }>) => !!action.payload.code),
    switchMap((action: PayloadAction<{ code: string }>) =>
      action$.pipe(
        ofType('SELECT_TIME_PERIOD'),
        switchMap(() => [
          unsubscribeFromSecurityPriceHistory(),
          subscribeToSecurityPriceHistory({ code: action.payload.code }),
        ]),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromSecurityPriceHistory.type)))),
      ),
    ),
  );

// Re-start subscribe to crypto price history every time a time period is changed.
const watchSubscribeToCryptoPriceHistory = (action$: Observable<PayloadAction<{ code: string }>>) =>
  action$.pipe(
    filter(isOfType([subscribeToCryptoPriceHistory.type])),
    filter((action: PayloadAction<{ code: string }>) => !!action.payload.code),
    switchMap((action: PayloadAction<{ code: string }>) =>
      action$.pipe(
        ofType('SELECT_TIME_PERIOD'),
        switchMap(() => [
          unsubscribeFromCryptoPriceHistory(),
          subscribeToCryptoPriceHistory({ code: action.payload.code }),
        ]),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromCryptoPriceHistory.type)))),
      ),
    ),
  );

const watchSubscribeToSecurityPriceHistoryToPriceCheck: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType(subscribeToSecurityPriceHistory.type)),
    map(() => subscribeToSecurityChartHealthCheck()),
  );

const watchUnsubscribeFromSecurityPriceHistory: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType(unsubscribeFromSecurityPriceHistory.type)),
    map(() => unsubscribeFromSecurityChartHealthCheck()),
  );

const fetchSecurityPriceHistory: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(subscribeToSecurityPriceHistory.type)),
    filter((action: PayloadAction<{ code: string }>) => !!action?.payload?.code),
    switchMap((action: PayloadAction<{ code: string }>) =>
      interval(SECURITY_PRICE_HISTORY_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              accessToken: getAccessToken(state),
              timePeriod: getSelectedTimePeriod(state),
              isEligible: getEligiblePriceHistoryCodeByAssetClassAndTimePeriod(
                state,
                action.payload.code,
                ASSET_CLASSES.SECURITY,
                getSelectedTimePeriod(state),
              ),
            })),
            filter(
              ({ accessToken, timePeriod, isEligible }) =>
                isPresent(accessToken) && !!timePeriod && isEligible,
            ),
            take(1),
            mergeMap(({ accessToken, timePeriod }) => {
              const query = { period: InnerTimePeriodToStockEtfRequestParam[timePeriod] };
              return ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat(`${SECURITY_PRICE_HISTORY_ENDPOINT}/${action.payload.code}`)
                  .concat(queryArrayString(query)),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response'),
                map((response: PriceHistorySecurityData) => {
                  return subscribeToSecurityPriceHistorySuccess({
                    priceHistory: response,
                    timePeriod,
                    assetCode: action.payload.code,
                  });
                }),
              );
            }),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(handleAjaxError(action$, subscribeToSecurityPriceHistoryError)),
          ),
        ),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromSecurityPriceHistory.type)))),
      ),
    ),
  );

const fetchCryptoPriceHistory: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(subscribeToCryptoPriceHistory.type)),
    filter((action: PayloadAction<{ code: string }>) => !!action?.payload?.code),
    switchMap((action: PayloadAction<{ code: string }>) =>
      interval(CRYPTO_PRICE_HISTORY_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              timePeriod: getSelectedTimePeriod(state),
              isEligible: getEligiblePriceHistoryCodeByAssetClassAndTimePeriod(
                state,
                action.payload.code,
                ASSET_CLASSES.CRYPTO,
                getSelectedTimePeriod(state),
              ),
            })),
            filter(({ timePeriod, isEligible }) => !!timePeriod && isEligible),
            take(1),
            mergeMap(({ timePeriod }) => {
              const query = {
                periods: timePeriodToQueryParamCrypto[timePeriod],
                trim: true,
                version: '2.14.1',
              };
              return fromFetch(
                `${window.env.API_PRICEHISTORY}/history/cache/${action.payload.code}eur`.concat(
                  queryArrayString(query),
                ),
              ).pipe(
                mergeMap((response: any) => response.json()),
                map(([response]: [PriceHistoryCryptoData]) => {
                  return subscribeToCryptoPriceHistorySuccess({
                    priceHistory: response,
                    timePeriod,
                    assetCode: action.payload.code,
                  });
                }),
              );
            }),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(handleAjaxError(action$, subscribeToCryptoPriceHistoryError)),
          ),
        ),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromCryptoPriceHistory.type)))),
      ),
    ),
  );

const fetchSecurityChartStatusWithInterval: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(subscribeToSecurityChartHealthCheck.type)),
    switchMap(() =>
      state$.pipe(
        take(1),
        switchMap((state) => {
          const accessToken = getAccessToken(state);
          return interval(SECURITY_PRICE_HISTORY_HEALTH_CHECK_INTERVAL_IN_MS).pipe(
            startWith(0),
            switchMap(() =>
              ajax({
                url: api
                  .getBaseUrlForUserServices(accessToken)
                  .concat(SECURITY_PRICE_HISTORY_HEALTH_CHECK_ENDPOINT),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response'),
                mergeMap((response: { isHealthy: boolean }) => [
                  subscribeToSecurityChartHealthCheckSuccess({ ...response }),
                ]),
              ),
            ),
            takeUntil(action$.pipe(filter(isOfType(unsubscribeFromSecurityChartHealthCheck.type)))),
          );
        }),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, subscribeToSecurityChartHealthCheckError)),
      ),
    ),
  );

export default combineEpics(
  watchSubscribeToSecurityPriceHistory,
  watchSubscribeToSecurityPriceHistoryToPriceCheck,
  watchUnsubscribeFromSecurityPriceHistory,
  watchSubscribeToCryptoPriceHistory,
  fetchSecurityPriceHistory,
  fetchCryptoPriceHistory,
  fetchSecurityChartStatusWithInterval,
);
