import { useDispatch, useSelector } from 'react-redux';
import { getLoggedIn } from 'store/selectors/auth';
import { generatePath, matchPath, matchRoutes } from 'react-router-dom';
import { $Values } from 'utility-types';
import { useIsPageAllowed, useUserGrantedRoles } from 'common/hooks/useUserGrantedRoles';
import { featureStocksPaths, paths } from 'common/urls';
import {
  BLOCK_TYPES,
  HARD_BLOCK_TYPE_TO_REDIRECT,
  ROUTE_MANAGEMENT_ROLES,
  TNC_ALLOWED_TYPES,
} from 'common/const/user';
import DisabledFeaturePage from 'scenes/DisabledFeaturePage';
import { showSoftBlockPopUp, cryptoTermsAndConditionsClickedAway } from 'store/actions/termsAndConditions';

export const useAllowedComponent = ({
  component,
  requiredRoles,
  selectedCrypto,
  path,
}: {
  component: React.ReactElement;
  requiredRoles: Array<$Values<typeof ROUTE_MANAGEMENT_ROLES>>;
  selectedCrypto: string;
  path: string;
}) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(getLoggedIn);
  const grantedRoles = useUserGrantedRoles();
  const isPageAllowed = useIsPageAllowed({ requiredRoles, grantedRoles });
  if (!loggedIn) {
    return { redirect: paths.LOGIN };
  }

  const foundBlockedRoles = grantedRoles.filter((grantedRole) =>
    Object.values(BLOCK_TYPES).includes(grantedRole),
  );

  if (foundBlockedRoles.length !== 0) {
    const redirectTo = HARD_BLOCK_TYPE_TO_REDIRECT[foundBlockedRoles[0]];
    if (
      !matchRoutes([{ path: paths.PERSONAL_INFO }, { path: paths.SETTINGS }], path) &&
      !matchPath({ path: redirectTo }, path)
    ) {
      return {
        redirect: generatePath(redirectTo),
      };
    }
    return { AllowedComponent: component };
  }

  const isTncFeatureBlocked =
    Object.values(TNC_ALLOWED_TYPES).findIndex(
      (item) => requiredRoles.includes(item) && !grantedRoles.includes(item),
    ) !== -1;
  if (isTncFeatureBlocked) {
    dispatch(showSoftBlockPopUp());
    dispatch(cryptoTermsAndConditionsClickedAway(false));
  }

  // check if granted roles have DEMO_USER and required roles have CRYPTO_KYC_PASSED
  if (requiredRoles.includes(ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE)) {
    if (grantedRoles.includes(ROUTE_MANAGEMENT_ROLES.DEMO_USER_WITHOUT_KYC)) return { redirect: paths.KYC };
    if (!grantedRoles.includes(ROUTE_MANAGEMENT_ROLES.REAL_ACCOUNT))
      return { AllowedComponent: DisabledFeaturePage };
  }

  if (
    grantedRoles.includes(ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE) &&
    requiredRoles.includes(ROUTE_MANAGEMENT_ROLES.DEMO_USER_WITHOUT_KYC)
  )
    return {
      redirect: generatePath(paths.PORTFOLIO_CRYPTO, {
        currency: selectedCrypto,
      }),
    };

  if (
    !grantedRoles.includes(ROUTE_MANAGEMENT_ROLES.STOCKS_TRADING_AVAILABLE) &&
    featureStocksPaths.includes(path)
  )
    return { redirect: generatePath(paths.PORTFOLIO) };

  if (!isPageAllowed)
    return {
      redirect: generatePath(paths.PORTFOLIO),
    };
  return { AllowedComponent: component };
};
