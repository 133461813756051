import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import entryImage from 'assets/img/KCCentryImage.svg';
import ModalTemplate from 'common/components/Modal/index';
import { CustomLink, KCCImage, RaffleActions } from './styles';
import {
  ContainerCenter,
  MaybeLaterButton,
  RaffleButton,
  RaffleHeader,
  RaffleSubtitle,
  RaffleTCList,
  RaffleTCListItem,
  RaffleTCTitle,
} from '../styles';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  onRejectOneWeek: () => void;
  onFullReject: () => void;
  isMaybeLaterButtonHidden: boolean;
};

const KCCRaffleEntryModal = ({
  onClose,
  onConfirm,
  onRejectOneWeek,
  onFullReject,
  isMaybeLaterButtonHidden,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen
      actions={
        <RaffleActions>
          <RaffleButton title={t('kccRaffle.entryModal.actions.confirm')} size="large" onClick={onConfirm} />
          {!isMaybeLaterButtonHidden && (
            <RaffleButton
              title={t('kccRaffle.entryModal.actions.maybeLater')}
              inverted
              size="large"
              onClick={onRejectOneWeek}
            />
          )}
          <MaybeLaterButton
            title={t('kccRaffle.entryModal.actions.dismiss')}
            inverted
            size="large"
            onClick={onFullReject}
          />
        </RaffleActions>
      }
      onCancel={onClose}
    >
      <ContainerCenter>
        <KCCImage src={entryImage} />
        <RaffleHeader>{t('kccRaffle.entryModal.title')}</RaffleHeader>
        <RaffleSubtitle>
          <Trans i18nKey={'kccRaffle.entryModal.subtitle'} />
        </RaffleSubtitle>
        <RaffleTCTitle>{t('kccRaffle.entryModal.terms.title')}</RaffleTCTitle>
      </ContainerCenter>
      <RaffleTCList $withPadding>
        <RaffleTCListItem>
          <Trans
            i18nKey="kccRaffle.entryModal.terms.item1"
            components={{
              1: <CustomLink href={t('kccRaffle.entryModal.terms.link')} target="_blank" />,
            }}
          />
        </RaffleTCListItem>

        <RaffleTCListItem>{t('kccRaffle.entryModal.terms.item2')}</RaffleTCListItem>
      </RaffleTCList>
    </ModalTemplate>
  );
};

KCCRaffleEntryModal.defaultProps = {
  headerText: undefined,
};

export default KCCRaffleEntryModal;
