import { createSlice } from '@reduxjs/toolkit';
import { cryptoShortInitialState } from './initialState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CryptoShortState } from 'store/types/experiments';
import { Asset } from 'types/assets';
import { PriceChangeData } from 'store/types/priceChanges';

const winnersLosersSlice = createSlice({
  name: 'cryptoShort',
  initialState: cryptoShortInitialState,
  reducers: {
    loadCryptoShortData(state: CryptoShortState, action: PayloadAction<{ code: string }>) {
      state.isLoading = true;
    },
    loadCryptoShortDataSuccess(
      state: CryptoShortState,
      action: PayloadAction<{ priceChange: PriceChangeData | undefined; assetInfo: Asset }>,
    ) {
      state.isLoading = false;
    },
    loadCryptoShortDataError(state: CryptoShortState) {
      state.isLoading = false;
    },
    stopLoadingPriceChanges(state: CryptoShortState) {},
  },
});

export default winnersLosersSlice;
