/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
export const appendItem = (array: any[], item: any) => {
  const newArray = array.slice();
  newArray.push({
    ...item,
    updating: false,
  });
  return newArray;
};

interface ItemWithId {
  id: string | number | undefined;
}

export const removeItem = (array: ItemWithId[], item: ItemWithId) => {
  return array.filter((a) => a.id !== item.id);
};

export function limitBufferSize(buffer: Array<any>, limit: number) {
  if (buffer.length > limit) {
    const nDiff = buffer.length - limit;

    // Chops off first `nDiff` elements
    buffer.splice(0, nDiff);
  }
  return buffer;
}
