import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import getFromLocalStorage from 'locales/languageDetector';
import en from './en';
import de from './de';

const lngDetector = new LngDetector();
lngDetector.addDetector(getFromLocalStorage);

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

export default () => {
  const I18n = i18n
    .use(lngDetector)
    .use(initReactI18next)
    .init({
      resources,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        wait: true,
      },
      detection: {
        order: ['getFromLocalStorage'],
      },
      fallbackLng: 'en',
    });
  return I18n;
};
