import { browserLanguage } from 'common/utils/language';

export type CountryData = {
  value: string;
  label: string;
};

export const countryListEn: CountryData[] = [
  { value: 'DE', label: 'Germany' },
  { value: 'AT', label: 'Austria' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'PL', label: 'Poland' },
  { value: 'ES', label: 'Spain' },
  { value: 'IT', label: 'Italy' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: '\u00c5land Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "C\u00f4te d'Ivoire" },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Cura\u00e7ao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: "Korea, Democratic People's Republic of" },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: "Lao People's Democratic Republic" },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'R\u00e9union' },
  { value: 'XK', label: 'Republic of Kosovo' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barth\u00e9lemy' },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  {
    value: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  { value: 'SS', label: 'South Sudan' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States of America' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const countryListDe: CountryData[] = [
  { value: 'DE', label: 'Deutschland' },
  { value: 'AT', label: 'Österreich' },
  { value: 'CH', label: 'Schweiz' },
  { value: 'PL', label: 'Polen' },
  { value: 'ES', label: 'Spanien' },
  { value: 'IT', label: 'Italien' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'EG', label: 'Ägypten' },
  { value: 'AL', label: 'Albanien' },
  { value: 'DZ', label: 'Algerien' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AG', label: 'Antigua und Barbuda' },
  { value: 'GQ', label: 'Äquatorialguinea' },
  { value: 'AR', label: 'Argentinien' },
  { value: 'AM', label: 'Armenien' },
  { value: 'AZ', label: 'Aserbaidschan' },
  { value: 'ET', label: 'Äthiopien' },
  { value: 'AU', label: 'Australien' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesch' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BE', label: 'Belgien' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivien' },
  { value: 'BA', label: 'Bosnien und Herzegowina' },
  { value: 'BW', label: 'Botsuana' },
  { value: 'BR', label: 'Brasilien' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgarien' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'DK', label: 'Dänemark' },
  { value: 'CD', label: 'Demokratische Republik Kongo' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominikanische Republik' },
  { value: 'DJ', label: 'Dschibuti' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'CI', label: 'Côte d’Ivoire' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estland' },
  { value: 'FJ', label: 'Fidschi' },
  { value: 'FI', label: 'Finnland' },
  { value: 'FR', label: 'Frankreich' },
  { value: 'GA', label: 'Gabun' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgien' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GR', label: 'Griechenland' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HN', label: 'Honduras' },
  { value: 'IN', label: 'Indien' },
  { value: 'ID', label: 'Indonesien' },
  { value: 'IQ', label: 'Irak' },
  { value: 'IR', label: 'Iran' },
  { value: 'IE', label: 'Irland' },
  { value: 'IS', label: 'Island' },
  { value: 'IL', label: 'Israel' },
  { value: 'JM', label: 'Jamaika' },
  { value: 'JP', label: 'Japan' },
  { value: 'YE', label: 'Jemen' },
  { value: 'JO', label: 'Jordanien' },
  { value: 'KH', label: 'Kambodscha' },
  { value: 'CM', label: 'Kamerun' },
  { value: 'CA', label: 'Kanada' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KZ', label: 'Kasachstan' },
  { value: 'QA', label: 'Katar' },
  { value: 'KE', label: 'Kenia' },
  { value: 'KG', label: 'Kirgisistan' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'CO', label: 'Kolumbien' },
  { value: 'KM', label: 'Komoren' },
  { value: 'CG', label: 'Kongo' },
  { value: 'HR', label: 'Kroatien' },
  { value: 'CU', label: 'Kuba' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'LA', label: 'Laos' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LV', label: 'Lettland' },
  { value: 'LB', label: 'Libanon' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libyen' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Litauen' },
  { value: 'LU', label: 'Luxemburg' },
  { value: 'MG', label: 'Madagaskar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Malediven' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MA', label: 'Marokko' },
  { value: 'MH', label: 'Marshallinseln' },
  { value: 'MR', label: 'Mauretanien' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MK', label: 'Mazedonien' },
  { value: 'MX', label: 'Mexiko' },
  { value: 'FM', label: 'Mikronesien' },
  { value: 'MD', label: 'Moldawien' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolei' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MZ', label: 'Mosambik' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NZ', label: 'Neuseeland' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NL', label: 'Niederlande' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'KP', label: 'Nordkorea' },
  { value: 'NO', label: 'Norwegen' },
  { value: 'OM', label: 'Oman' },
  { value: 'TL', label: 'Osttimor' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua-Neuguinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippinen' },
  { value: 'PT', label: 'Portugal' },
  { value: 'XK', label: 'Republik Kosovo' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'RO', label: 'Rumänien' },
  { value: 'RU', label: 'Russland' },
  { value: 'SB', label: 'Salomonen' },
  { value: 'ZM', label: 'Sambia' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'São Tomé und Príncipe' },
  { value: 'SA', label: 'Saudi-Arabien' },
  { value: 'SE', label: 'Schweden' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbien' },
  { value: 'SC', label: 'Seychellen' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'ZW', label: 'Simbabwe' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SK', label: 'Slowakei' },
  { value: 'SI', label: 'Slowenien' },
  { value: 'SO', label: 'Somalia' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'KN', label: 'St. Kitts und Nevis' },
  { value: 'LC', label: 'St. Lucia' },
  { value: 'VC', label: 'St. Vincent und die Grenadinen' },
  { value: 'ZA', label: 'Südafrika' },
  { value: 'SD', label: 'Sudan' },
  { value: 'KR', label: 'Südkorea' },
  { value: 'SS', label: 'Südsudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SZ', label: 'Swasiland' },
  { value: 'SY', label: 'Syrien' },
  { value: 'TJ', label: 'Tadschikistan' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TZ', label: 'Tansania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TG', label: 'Togo' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad und Tobago' },
  { value: 'TD', label: 'Tschad' },
  { value: 'CZ', label: 'Tschechien' },
  { value: 'TN', label: 'Tunesien' },
  { value: 'TR', label: 'Türkei' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'HU', label: 'Ungarn' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Usbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'AE', label: 'Vereinigte Arabische Emirate' },
  { value: 'US', label: 'Vereinigte Staaten von Amerika' },
  { value: 'GB', label: 'Vereinigtes Königreich' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'BY', label: 'Weißrussland' },
  { value: 'CF', label: 'Zentralafrikanische Republik' },
  { value: 'CY', label: 'Zypern' },
];
const emptyTaxEn = { value: '', label: 'Please select Tax Residency' };
const emptyTaxDe = { value: '', label: 'Steuerliche Ansässigkeit auswählen' };
const emptyEn = { value: '', label: '' };
const emptyDe = { value: '', label: '' };
const countryListDeWithEmpty = [...countryListDe];
const countryListEnWithEmpty = [...countryListEn];
const countryListTaxDeWithEmpty = [...countryListDe];
const countryListTaxEnWithEmpty = [...countryListEn];
countryListEnWithEmpty.unshift(emptyEn);
countryListDeWithEmpty.unshift(emptyDe);
countryListTaxEnWithEmpty.unshift(emptyTaxEn);
countryListTaxDeWithEmpty.unshift(emptyTaxDe);
export const nationalitiesEn: CountryData[] = [
  { value: 'AF', label: 'Afghan' },
  { value: 'AX', label: 'Åland Island' },
  { value: 'AL', label: 'Albanian' },
  { value: 'DZ', label: 'Algerian' },
  { value: 'US', label: 'American' },
  { value: 'AS', label: 'American Samoan' },
  { value: 'AD', label: 'Andorran' },
  { value: 'AO', label: 'Angolan' },
  { value: 'AI', label: 'Anguillan' },
  { value: 'AQ', label: 'Antarctic' },
  { value: 'AG', label: 'Antiguan or Barbudan' },
  { value: 'AR', label: 'Argentine' },
  { value: 'AM', label: 'Armenian' },
  { value: 'AW', label: 'Aruban' },
  { value: 'AU', label: 'Australian' },
  { value: 'AT', label: 'Austrian' },
  { value: 'AZ', label: 'Azerbaijani, Azeri' },
  { value: 'BS', label: 'Bahamian' },
  { value: 'BH', label: 'Bahraini' },
  { value: 'BD', label: 'Bangladeshi' },
  { value: 'BB', label: 'Barbadian' },
  { value: 'BL', label: 'Barthélemois' },
  { value: 'LS', label: 'Basotho' },
  { value: 'BY', label: 'Belarusian' },
  { value: 'BE', label: 'Belgian' },
  { value: 'BZ', label: 'Belizean' },
  { value: 'BJ', label: 'Beninese, Beninois' },
  { value: 'BM', label: 'Bermudian, Bermudan' },
  { value: 'BT', label: 'Bhutanese' },
  { value: 'IO', label: 'BIOT' },
  { value: 'GW', label: 'Bissau-Guinean' },
  { value: 'BO', label: 'Bolivian' },
  { value: 'BQ', label: 'Bonaire' },
  { value: 'BA', label: 'Bosnian or Herzegovinian' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazilian' },
  { value: 'VG', label: 'British Virgin Island' },
  { value: 'GB', label: 'British, UK' },
  { value: 'BN', label: 'Bruneian' },
  { value: 'BG', label: 'Bulgarian' },
  { value: 'BF', label: 'Burkinabé' },
  { value: 'MM', label: 'Burmese' },
  { value: 'BI', label: 'Burundian' },
  { value: 'CV', label: 'Cabo Verdean' },
  { value: 'KH', label: 'Cambodian' },
  { value: 'CM', label: 'Cameroonian' },
  { value: 'CA', label: 'Canadian' },
  { value: 'KY', label: 'Caymanian' },
  { value: 'CF', label: 'Central African' },
  { value: 'TD', label: 'Chadian' },
  { value: 'GG', label: 'Channel Island' },
  { value: 'JE', label: 'Channel Island' },
  { value: 'CL', label: 'Chilean' },
  { value: 'CN', label: 'Chinese' },
  { value: 'TW', label: 'Chinese, Taiwanese' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos Island' },
  { value: 'CO', label: 'Colombian' },
  { value: 'KM', label: 'Comoran, Comorian' },
  { value: 'CG', label: 'Congolese' },
  { value: 'CD', label: 'Congolese' },
  { value: 'CK', label: 'Cook Island' },
  { value: 'CR', label: 'Costa Rican' },
  { value: 'HR', label: 'Croatian' },
  { value: 'CU', label: 'Cuban' },
  { value: 'CW', label: 'Curaçaoan' },
  { value: 'CY', label: 'Cypriot' },
  { value: 'CZ', label: 'Czech' },
  { value: 'DK', label: 'Danish' },
  { value: 'DJ', label: 'Djiboutian' },
  { value: 'DM', label: 'Dominican' },
  { value: 'DO', label: 'Dominican' },
  { value: 'NL', label: 'Dutch, Netherlandic' },
  { value: 'EC', label: 'Ecuadorian' },
  { value: 'EG', label: 'Egyptian' },
  { value: 'AE', label: 'Emirati, Emirian, Emiri' },
  { value: 'GQ', label: 'Equatorial Guinean, Equatoguinean' },
  { value: 'ER', label: 'Eritrean' },
  { value: 'EE', label: 'Estonian' },
  { value: 'ET', label: 'Ethiopian' },
  { value: 'FK', label: 'Falkland Island' },
  { value: 'FO', label: 'Faroese' },
  { value: 'FJ', label: 'Fijian' },
  { value: 'FI', label: 'Finnish' },
  { value: 'FR', label: 'French' },
  { value: 'GF', label: 'French Guianese' },
  { value: 'PF', label: 'French Polynesian' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabonese' },
  { value: 'GM', label: 'Gambian' },
  { value: 'GE', label: 'Georgian' },
  { value: 'DE', label: 'German' },
  { value: 'GH', label: 'Ghanaian' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greek, Hellenic' },
  { value: 'GL', label: 'Greenlandic' },
  { value: 'GD', label: 'Grenadian' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guamanian, Guambat' },
  { value: 'GT', label: 'Guatemalan' },
  { value: 'GN', label: 'Guinean' },
  { value: 'GY', label: 'Guyanese' },
  { value: 'HT', label: 'Haitian' },
  { value: 'HM', label: 'Heard Island or McDonald Islands' },
  { value: 'HN', label: 'Honduran' },
  { value: 'HK', label: 'Hong Kong, Hong Kongese' },
  { value: 'HU', label: 'Hungarian, Magyar' },
  { value: 'KI', label: 'I-Kiribati' },
  { value: 'IS', label: 'Icelandic' },
  { value: 'IN', label: 'Indian' },
  { value: 'ID', label: 'Indonesian' },
  { value: 'IR', label: 'Iranian, Persian' },
  { value: 'IQ', label: 'Iraqi' },
  { value: 'IE', label: 'Irish' },
  { value: 'IL', label: 'Israeli' },
  { value: 'IT', label: 'Italian' },
  { value: 'CI', label: 'Ivorian' },
  { value: 'JM', label: 'Jamaican' },
  { value: 'JP', label: 'Japanese' },
  { value: 'JO', label: 'Jordanian' },
  { value: 'KZ', label: 'Kazakhstani, Kazakh' },
  { value: 'KE', label: 'Kenyan' },
  { value: 'KN', label: 'Kittitian or Nevisian' },
  { value: 'XK', label: 'Kosovar' },
  { value: 'KW', label: 'Kuwaiti' },
  { value: 'KG', label: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz' },
  { value: 'LA', label: 'Lao, Laotian' },
  { value: 'LV', label: 'Latvian' },
  { value: 'LB', label: 'Lebanese' },
  { value: 'LR', label: 'Liberian' },
  { value: 'LY', label: 'Libyan' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuanian' },
  { value: 'LU', label: 'Luxembourg, Luxembourgish' },
  { value: 'MO', label: 'Macanese, Chinese' },
  { value: 'MK', label: 'Macedonian' },
  { value: 'YT', label: 'Mahoran' },
  { value: 'MG', label: 'Malagasy' },
  { value: 'MW', label: 'Malawian' },
  { value: 'MY', label: 'Malaysian' },
  { value: 'MV', label: 'Maldivian' },
  { value: 'ML', label: 'Malian, Malinese' },
  { value: 'MT', label: 'Maltese' },
  { value: 'IM', label: 'Manx' },
  { value: 'MH', label: 'Marshallese' },
  { value: 'MQ', label: 'Martiniquais, Martinican' },
  { value: 'MR', label: 'Mauritanian' },
  { value: 'MU', label: 'Mauritian' },
  { value: 'MX', label: 'Mexican' },
  { value: 'FM', label: 'Micronesian' },
  { value: 'MD', label: 'Moldovan' },
  { value: 'MC', label: 'Monégasque, Monacan' },
  { value: 'MN', label: 'Mongolian' },
  { value: 'ME', label: 'Montenegrin' },
  { value: 'MS', label: 'Montserratian' },
  { value: 'MA', label: 'Moroccan' },
  { value: 'BW', label: 'Motswana, Botswanan' },
  { value: 'MZ', label: 'Mozambican' },
  { value: 'NA', label: 'Namibian' },
  { value: 'NR', label: 'Nauruan' },
  { value: 'NP', label: 'Nepali, Nepalese' },
  { value: 'NC', label: 'New Caledonian' },
  { value: 'NZ', label: 'New Zealand, NZ' },
  { value: 'VU', label: 'Ni-Vanuatu, Vanuatuan' },
  { value: 'NI', label: 'Nicaraguan' },
  { value: 'NG', label: 'Nigerian' },
  { value: 'NE', label: 'Nigerien' },
  { value: 'NU', label: 'Niuean' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'KP', label: 'North Korean' },
  { value: 'MP', label: 'Northern Marianan' },
  { value: 'NO', label: 'Norwegian' },
  { value: 'OM', label: 'Omani' },
  { value: 'PK', label: 'Pakistani' },
  { value: 'PW', label: 'Palauan' },
  { value: 'PS', label: 'Palestinian' },
  { value: 'PA', label: 'Panamanian' },
  { value: 'PG', label: 'Papua New Guinean, Papuan' },
  { value: 'PY', label: 'Paraguayan' },
  { value: 'PE', label: 'Peruvian' },
  { value: 'PH', label: 'Philippine, Filipino' },
  { value: 'PN', label: 'Pitcairn Island' },
  { value: 'PL', label: 'Polish' },
  { value: 'PT', label: 'Portuguese' },
  { value: 'PR', label: 'Puerto Rican' },
  { value: 'QA', label: 'Qatari' },
  { value: 'RE', label: 'Réunionese, Réunionnais' },
  { value: 'RO', label: 'Romanian' },
  { value: 'RU', label: 'Russian' },
  { value: 'RW', label: 'Rwandan' },
  { value: 'EH', label: 'Sahrawi, Sahrawian, Sahraouian' },
  { value: 'SH', label: 'Saint Helenian' },
  { value: 'LC', label: 'Saint Lucian' },
  { value: 'VC', label: 'Saint Vincentian, Vincentian' },
  { value: 'MF', label: 'Saint-Martinoise' },
  { value: 'PM', label: 'Saint-Pierrais or Miquelonnais' },
  { value: 'SV', label: 'Salvadoran' },
  { value: 'SM', label: 'Sammarinese' },
  { value: 'WS', label: 'Samoan' },
  { value: 'ST', label: 'São Toméan' },
  { value: 'SA', label: 'Saudi, Saudi Arabian' },
  { value: 'SN', label: 'Senegalese' },
  { value: 'RS', label: 'Serbian' },
  { value: 'SC', label: 'Seychellois' },
  { value: 'SL', label: 'Sierra Leonean' },
  { value: 'SG', label: 'Singaporean' },
  { value: 'SX', label: 'Sint Maarten' },
  { value: 'SK', label: 'Slovak' },
  { value: 'SI', label: 'Slovenian, Slovene' },
  { value: 'SB', label: 'Solomon Island' },
  { value: 'SO', label: 'Somali, Somalian' },
  { value: 'ZA', label: 'South African' },
  { value: 'GS', label: 'South Georgia or South Sandwich Islands' },
  { value: 'KR', label: 'South Korean' },
  { value: 'SS', label: 'South Sudanese' },
  { value: 'ES', label: 'Spanish' },
  { value: 'LK', label: 'Sri Lankan' },
  { value: 'SD', label: 'Sudanese' },
  { value: 'SR', label: 'Surinamese' },
  { value: 'SJ', label: 'Svalbard' },
  { value: 'SZ', label: 'Swazi' },
  { value: 'SE', label: 'Swedish' },
  { value: 'CH', label: 'Swiss' },
  { value: 'SY', label: 'Syrian' },
  { value: 'TJ', label: 'Tajikistani' },
  { value: 'TZ', label: 'Tanzanian' },
  { value: 'TH', label: 'Thai' },
  { value: 'TL', label: 'Timorese' },
  { value: 'TG', label: 'Togolese' },
  { value: 'TK', label: 'Tokelauan' },
  { value: 'TO', label: 'Tongan' },
  { value: 'TT', label: 'Trinidadian or Tobagonian' },
  { value: 'TN', label: 'Tunisian' },
  { value: 'TR', label: 'Turkish' },
  { value: 'TM', label: 'Turkmen' },
  { value: 'TC', label: 'Turks and Caicos Island' },
  { value: 'TV', label: 'Tuvaluan' },
  { value: 'VI', label: 'U.S. Virgin Island' },
  { value: 'UG', label: 'Ugandan' },
  { value: 'UA', label: 'Ukrainian' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguayan' },
  { value: 'UZ', label: 'Uzbekistani, Uzbek' },
  { value: 'VA', label: 'Vatican' },
  { value: 'VE', label: 'Venezuelan' },
  { value: 'VN', label: 'Vietnamese' },
  { value: 'WF', label: 'Wallis and Futuna, Wallisian or Futunan' },
  { value: 'YE', label: 'Yemeni' },
  { value: 'ZM', label: 'Zambian' },
  { value: 'ZW', label: 'Zimbabwean' },
];

export const nationalitiesDe: CountryData[] = [
  { value: 'AF', label: 'afghanisch' },
  { value: 'EG', label: 'ägyptisch' },
  { value: 'AL', label: 'albanisch' },
  { value: 'DZ', label: 'algerisch' },
  { value: 'US', label: 'amerikanisch' },
  { value: 'AD', label: 'andorranisch' },
  { value: 'AO', label: 'angolanisch' },
  { value: 'AG', label: 'antiguanisch' },
  { value: 'GQ', label: 'äquatorialguineisch' },
  { value: 'AR', label: 'argentinisch' },
  { value: 'AM', label: 'armenisch' },
  { value: 'AZ', label: 'aserbaidschanisch' },
  { value: 'ET', label: 'äthiopisch' },
  { value: 'AU', label: 'australisch' },
  { value: 'BS', label: 'bahamaisch' },
  { value: 'BH', label: 'bahrainisch' },
  { value: 'BD', label: 'bangladeschisch' },
  { value: 'BB', label: 'barbadisch' },
  { value: 'BE', label: 'belgisch' },
  { value: 'BZ', label: 'belizisch' },
  { value: 'BJ', label: 'beninisch' },
  { value: 'BT', label: 'bhutanisch' },
  { value: 'BO', label: 'bolivianisch' },
  { value: 'BA', label: 'bosnisch herzegowinisch' },
  { value: 'BW', label: 'botsuanisch' },
  { value: 'BR', label: 'brasilianisch' },
  { value: 'GB', label: 'britisch' },
  { value: 'BN', label: 'bruneiisch' },
  { value: 'BG', label: 'bulgarisch' },
  { value: 'BF', label: 'burkinisch' },
  { value: 'BI', label: 'burundisch' },
  { value: 'CL', label: 'chilenisch' },
  { value: 'CN', label: 'chinesisch' },
  { value: 'CR', label: 'costa-ricanisch' },
  { value: 'DK', label: 'dänisch' },
  { value: 'DE', label: 'deutsch' },
  { value: 'DM', label: 'dominicanisch' },
  { value: 'DO', label: 'dominikanisch' },
  { value: 'DJ', label: 'dschibutisch' },
  { value: 'EC', label: 'ecuadorianisch' },
  { value: 'ER', label: 'eritreisch' },
  { value: 'EE', label: 'estnisch' },
  { value: 'FJ', label: 'fidschianisch' },
  { value: 'FI', label: 'finnisch' },
  { value: 'FR', label: 'französisch' },
  { value: 'GA', label: 'gabunisch' },
  { value: 'GM', label: 'gambisch' },
  { value: 'GE', label: 'georgisch' },
  { value: 'GH', label: 'ghanaisch' },
  { value: 'GD', label: 'grenadisch' },
  { value: 'GR', label: 'griechisch' },
  { value: 'GT', label: 'guatemaltekisch' },
  { value: 'GW', label: 'guinea-bissauisch' },
  { value: 'GN', label: 'guineisch' },
  { value: 'GY', label: 'guyanisch' },
  { value: 'HT', label: 'haitianisch' },
  { value: 'HN', label: 'honduranisch' },
  { value: 'IN', label: 'indisch' },
  { value: 'ID', label: 'indonesisch' },
  { value: 'IQ', label: 'irakisch' },
  { value: 'IR', label: 'iranisch' },
  { value: 'IE', label: 'irisch' },
  { value: 'IS', label: 'isländisch' },
  { value: 'IL', label: 'israelisch' },
  { value: 'IT', label: 'italienisch' },
  { value: 'CI', label: 'ivorisch' },
  { value: 'JM', label: 'jamaikanisch' },
  { value: 'JP', label: 'japanisch' },
  { value: 'YE', label: 'jemenitisch' },
  { value: 'JO', label: 'jordanisch' },
  { value: 'KH', label: 'kambodschanisch' },
  { value: 'CM', label: 'kamerunisch' },
  { value: 'CA', label: 'kanadisch' },
  { value: 'CV', label: 'kapverdisch' },
  { value: 'KZ', label: 'kasachisch' },
  { value: 'QA', label: 'katarisch' },
  { value: 'KE', label: 'kenianisch' },
  { value: 'KG', label: 'kirgisisch' },
  { value: 'KI', label: 'kiribatisch' },
  { value: 'CO', label: 'kolumbianisch' },
  { value: 'KM', label: 'komorisch' },
  { value: 'CG', label: 'kongolesisch (Kongo-Brazzaville)' },
  { value: 'CD', label: 'kongolesisch (Kongo-Kinshasa)' },
  { value: 'XK', label: 'kosovarisch' },
  { value: 'HR', label: 'kroatisch' },
  { value: 'CU', label: 'kubanisch' },
  { value: 'KW', label: 'kuwaitisch' },
  { value: 'LA', label: 'laotisch' },
  { value: 'LS', label: 'lesothisch' },
  { value: 'LV', label: 'lettisch' },
  { value: 'LB', label: 'libanesisch' },
  { value: 'LR', label: 'liberianisch' },
  { value: 'LY', label: 'libysch' },
  { value: 'LI', label: 'liechtensteinisch' },
  { value: 'LT', label: 'litauisch' },
  { value: 'LC', label: 'lucianisch' },
  { value: 'LU', label: 'luxemburgisch' },
  { value: 'MG', label: 'madagassisch' },
  { value: 'MW', label: 'malawisch' },
  { value: 'MY', label: 'malaysisch' },
  { value: 'MV', label: 'maledivisch' },
  { value: 'ML', label: 'malisch' },
  { value: 'MT', label: 'maltesisch' },
  { value: 'MA', label: 'marokkanisch' },
  { value: 'MH', label: 'marshallisch' },
  { value: 'MR', label: 'mauretanisch' },
  { value: 'MU', label: 'mauritisch' },
  { value: 'MK', label: 'mazedonisch' },
  { value: 'MX', label: 'mexikanisch' },
  { value: 'FM', label: 'mikronesisch' },
  { value: 'MD', label: 'moldauisch' },
  { value: 'MC', label: 'monegassisch' },
  { value: 'MN', label: 'mongolisch' },
  { value: 'ME', label: 'montenegrinisch' },
  { value: 'MZ', label: 'mosambikanisch' },
  { value: 'MM', label: 'myanmarisch' },
  { value: 'NA', label: 'namibisch' },
  { value: 'NR', label: 'nauruisch' },
  { value: 'NP', label: 'nepalesisch' },
  { value: 'NZ', label: 'neuseeländisch' },
  { value: 'NI', label: 'nicaraguanisch' },
  { value: 'NL', label: 'niederländisch' },
  { value: 'NG', label: 'nigerianisch' },
  { value: 'NE', label: 'nigrisch' },
  { value: 'KP', label: 'nordkoreanisch' },
  { value: 'NO', label: 'norwegisch' },
  { value: 'OM', label: 'omanisch' },
  { value: 'AT', label: 'österreichisch' },
  { value: 'TL', label: 'osttimor' },
  { value: 'PK', label: 'pakistanisch' },
  { value: 'PW', label: 'palauisch' },
  { value: 'PA', label: 'panamaisch' },
  { value: 'PG', label: 'papua-neuguineisch' },
  { value: 'PY', label: 'paraguayisch' },
  { value: 'PE', label: 'peruanisch' },
  { value: 'PH', label: 'philippinisch' },
  { value: 'PL', label: 'polnisch' },
  { value: 'PT', label: 'portugiesisch' },
  { value: 'RW', label: 'ruandisch' },
  { value: 'RO', label: 'rumänisch' },
  { value: 'RU', label: 'russisch' },
  { value: 'SB', label: 'salomonisch' },
  { value: 'SV', label: 'salvadorianisch' },
  { value: 'ZM', label: 'sambisch' },
  { value: 'WS', label: 'samoanisch' },
  { value: 'SM', label: 'san-marinesisch' },
  { value: 'ST', label: 'são-toméisch' },
  { value: 'SA', label: 'saudi-arabisch' },
  { value: 'SE', label: 'schwedisch' },
  { value: 'CH', label: 'schweizerisch' },
  { value: 'SN', label: 'senegalesisch' },
  { value: 'RS', label: 'serbisch' },
  { value: 'SC', label: 'seychellisch' },
  { value: 'SL', label: 'sierra-leonisch' },
  { value: 'ZW', label: 'simbabwisch' },
  { value: 'SG', label: 'singapurisch' },
  { value: 'SK', label: 'slowakisch' },
  { value: 'SI', label: 'slowenisch' },
  { value: 'SO', label: 'somalisch' },
  { value: 'ES', label: 'spanisch' },
  { value: 'LK', label: 'sri-lankisch' },
  { value: 'KN', label: 'st. kitts und nevis' },
  { value: 'ZA', label: 'südafrikanisch' },
  { value: 'SD', label: 'sudanesisch' },
  { value: 'KR', label: 'südkoreanisch' },
  { value: 'SS', label: 'südsudanesisch' },
  { value: 'SR', label: 'surinamisch' },
  { value: 'SZ', label: 'swasiländisch' },
  { value: 'SY', label: 'syrisch' },
  { value: 'TJ', label: 'tadschikisch' },
  { value: 'TZ', label: 'tansanisch' },
  { value: 'TH', label: 'thailändisch' },
  { value: 'TG', label: 'togoisch' },
  { value: 'TO', label: 'tongaisch' },
  { value: 'TT', label: 'trinidad und tobago' },
  { value: 'TD', label: 'tschadisch' },
  { value: 'CZ', label: 'tschechisch' },
  { value: 'TN', label: 'tunesisch' },
  { value: 'TR', label: 'türkisch' },
  { value: 'TM', label: 'turkmenisch' },
  { value: 'TV', label: 'tuvaluisch' },
  { value: 'UG', label: 'ugandisch' },
  { value: 'UA', label: 'ukrainisch' },
  { value: 'HU', label: 'ungarisch' },
  { value: 'UY', label: 'uruguayisch' },
  { value: 'UZ', label: 'usbekisch' },
  { value: 'VU', label: 'vanuatuisch' },
  { value: 'VE', label: 'venezolanisch' },
  { value: 'AE', label: 'vereinigte arabische emirate' },
  { value: 'VN', label: 'vietnamesisch' },
  { value: 'VC', label: 'vincentisch' },
  { value: 'BY', label: 'weißrussisch' },
  { value: 'CF', label: 'zentralafrikanisch' },
  { value: 'CY', label: 'zyprisch' },
];

const deviceLanguage = browserLanguage();

export const getNationalities = (): CountryData[] => {
  switch (deviceLanguage) {
    case 'de':
      return nationalitiesDe;
    default:
      return nationalitiesEn;
  }
};
export const getCountryList = (language: string) => {
  switch (language) {
    case 'de':
      return countryListDe;
      break;
    default:
      return countryListEn;
  }
};

export const getCountryListWithEmpty = (): CountryData[] => {
  switch (deviceLanguage) {
    case 'de':
      return countryListDeWithEmpty;
    default:
      return countryListEnWithEmpty;
  }
};

export const getTaxCountryListWithEmpty = (): CountryData[] => {
  switch (deviceLanguage) {
    case 'de':
      return countryListTaxDeWithEmpty;
    default:
      return countryListTaxEnWithEmpty;
  }
};

export const nationalities = getNationalities();
export const countryList = getCountryList(deviceLanguage);
export const countryListWithEmpty = getCountryListWithEmpty();

export const countryTaxListWithEmpty = getTaxCountryListWithEmpty();

export const ID_OR_PASS_BILL = 'idOrPassBill';
export const ID_BILL_OR_PASS_BILL = 'idBillOrPassBill';
export const ID_BILL = 'idBill';
export const PASS_BILL = 'passBill';
export const NEWSLETTER = 'newsletter';
export const NEWSLETTERBIRTHCOUNTRY = 'newsletterbirthcountry';

export type VerificationCategory =
  | typeof ID_OR_PASS_BILL
  | typeof ID_BILL_OR_PASS_BILL
  | typeof ID_BILL
  | typeof PASS_BILL
  | typeof NEWSLETTER
  | typeof NEWSLETTERBIRTHCOUNTRY;

export const verificationCategories = [
  ID_OR_PASS_BILL,
  ID_BILL_OR_PASS_BILL,
  ID_BILL,
  PASS_BILL,
  NEWSLETTER,
  NEWSLETTERBIRTHCOUNTRY,
] as VerificationCategory[];

export const supportedCountries = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IS',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'IE',
];
export const notSupportedBirthCountries = ['US'];

export const forbiddenToEditToCountry = ['IT'];

export const supportedStocksCountries = ['DE'];

export type VerificationDocument = {
  country: string;
  passport: boolean;
  identityCard: boolean;
};

export const acceptedVerificationDocuments: { [key: string]: VerificationDocument } = {
  DZ: {
    country: 'Algerien / Algeria',
    passport: true,
    identityCard: false,
  },
  AD: {
    country: 'Andorra / Andorra',
    passport: true,
    identityCard: false,
  },
  AO: {
    country: 'Angola / Angola',
    passport: true,
    identityCard: false,
  },
  AR: {
    country: 'Argentinien / Argentina',
    passport: true,
    identityCard: false,
  },
  AZ: {
    country: 'Aserbaidschan / Azerbaijan',
    passport: true,
    identityCard: false,
  },
  AU: {
    country: 'Australien / Australia',
    passport: true,
    identityCard: false,
  },
  BD: {
    country: 'Bangladesch / Bangladesh',
    passport: true,
    identityCard: false,
  },
  BE: {
    country: 'Belgien / Belgium',
    passport: true,
    identityCard: true,
  },
  BA: {
    country: 'Bosnien Herzegowina / Bosnia Herzegovina',
    passport: true,
    identityCard: false,
  },
  BG: {
    country: 'Bulgarien / Bulgaria',
    passport: true,
    identityCard: false,
  },
  BF: {
    country: 'Burkina Faso / Burkina Faso',
    passport: true,
    identityCard: false,
  },
  BR: {
    country: 'Brasilien / Brazil',
    passport: true,
    identityCard: false,
  },
  CL: {
    country: 'Chile / Chile',
    passport: true,
    identityCard: false,
  },
  CN: {
    country: 'China, Hong Kong / China, Hong Kong',
    passport: true,
    identityCard: false,
  },
  DK: {
    country: 'Dänemark / Denmark',
    passport: true,
    identityCard: false,
  },
  DE: {
    country: 'Deutschland / Germany',
    passport: true,
    identityCard: true,
  },
  CI: {
    country: 'Elfenbeinküste / Ivory Coast',
    passport: true,
    identityCard: false,
  },
  EE: {
    country: 'Estland / Estonia',
    passport: true,
    identityCard: false,
  },
  FI: {
    country: 'Finnland / Finland',
    passport: true,
    identityCard: true,
  },
  FR: {
    country: 'Frankreich / France',
    passport: true,
    identityCard: true,
  },
  GE: {
    country: 'Georgien / Georgia',
    passport: false,
    identityCard: true,
  },
  GR: {
    country: 'Griechenland / Greece',
    passport: true,
    identityCard: false,
  },
  IE: {
    country: 'Irland / Ireland',
    passport: true,
    identityCard: true,
  },
  IS: {
    country: 'Island / Iceland',
    passport: true,
    identityCard: false,
  },
  IT: {
    country: 'Italien / Italy',
    passport: true,
    identityCard: true,
  },
  JP: {
    country: 'Japan / Japan',
    passport: true,
    identityCard: false,
  },
  CM: {
    country: 'Kamerun / Cameroon',
    passport: true,
    identityCard: false,
  },
  CA: {
    country: 'Kanada / Canada',
    passport: true,
    identityCard: false,
  },
  KZ: {
    country: 'Kasachstan / Kazakhstan',
    passport: true,
    identityCard: false,
  },
  KE: {
    country: 'Kenia / Kenya',
    passport: true,
    identityCard: false,
  },
  CO: {
    country: 'Kolumbien / Colombia',
    passport: true,
    identityCard: false,
  },
  XK: {
    country: 'Kosovo / Kosovo',
    passport: true,
    identityCard: false,
  },
  HR: {
    country: 'Kroatien / Croatia',
    passport: true,
    identityCard: true,
  },
  KW: {
    country: 'Kuwait / Kuwait',
    passport: true,
    identityCard: false,
  },
  LV: {
    country: 'Lettland / Latvia',
    passport: false,
    identityCard: true,
  },
  LI: {
    country: 'Liechtenstein / Liechtenstein',
    passport: false,
    identityCard: true,
  },
  LT: {
    country: 'Litauen / Lithuania',
    passport: true,
    identityCard: true,
  },
  LU: {
    country: 'Luxemburg / Luxembourg',
    passport: true,
    identityCard: true,
  },
  MW: {
    country: 'Malawi / Malawi',
    passport: true,
    identityCard: false,
  },
  MY: {
    country: 'Malaysia / Malaysia',
    passport: true,
    identityCard: false,
  },
  ML: {
    country: 'Mali / Mali',
    passport: true,
    identityCard: false,
  },
  MT: {
    country: 'Malta / Malta',
    passport: true,
    identityCard: true,
  },
  MA: {
    country: 'Marokko / Morocco',
    passport: true,
    identityCard: false,
  },
  MX: {
    country: 'Mexiko / Mexico',
    passport: true,
    identityCard: false,
  },
  NA: {
    country: 'Namibia / Namibia',
    passport: true,
    identityCard: false,
  },
  NZ: {
    country: 'Neuseeland / New Zealand',
    passport: true,
    identityCard: false,
  },
  NL: {
    country: 'Niederlande / Netherlands',
    passport: true,
    identityCard: true,
  },
  MK: {
    country: 'Nordmazedonien / North Macedonia',
    passport: true,
    identityCard: false,
  },
  NO: {
    country: 'Norwegen / Norway',
    passport: true,
    identityCard: false,
  },
  AT: {
    country: 'Österreich / Austria',
    passport: true,
    identityCard: true,
  },
  PE: {
    country: 'Peru / Peru',
    passport: true,
    identityCard: false,
  },
  PH: {
    country: 'Philippinen / Philippines',
    passport: true,
    identityCard: false,
  },
  PL: {
    country: 'Polen / Poland',
    passport: true,
    identityCard: true,
  },
  PT: {
    country: 'Portugal / Portugal',
    passport: true,
    identityCard: false,
  },
  RO: {
    country: 'Rumänien / Romania',
    passport: true,
    identityCard: false,
  },
  SE: {
    country: 'Schweden / Sweden',
    passport: true,
    identityCard: true,
  },
  CH: {
    country: 'Schweiz / Switzerland',
    passport: true,
    identityCard: true,
  },
  RS: {
    country: 'Serbien / Serbia',
    passport: true,
    identityCard: false,
  },
  SG: {
    country: 'Singapur / Singapore',
    passport: true,
    identityCard: false,
  },
  SK: {
    country: 'Slowakei / Slovakia',
    passport: true,
    identityCard: true,
  },
  SI: {
    country: 'Slowenien / Slovenia',
    passport: true,
    identityCard: true,
  },
  ES: {
    country: 'Spanien / Spain',
    passport: true,
    identityCard: true,
  },
  ZA: {
    country: 'Südafrika / South Africa',
    passport: true,
    identityCard: false,
  },
  KR: {
    country: 'Südkorea / Republic of Korea',
    passport: true,
    identityCard: false,
  },
  TW: {
    country: 'Taiwan / Taiwan (Republik China)',
    passport: true,
    identityCard: false,
  },
  TH: {
    country: 'Thailand / Thailand',
    passport: true,
    identityCard: false,
  },
  CZ: {
    country: 'Tschechien / Czech Republic',
    passport: true,
    identityCard: true,
  },
  TR: {
    country: 'Türkei / Turkey',
    passport: true,
    identityCard: false,
  },
  HU: {
    country: 'Ungarn / Hungary',
    passport: true,
    identityCard: true,
  },
  GB: {
    country: 'Vereinigtes Königreich / United Kingdom',
    passport: true,
    identityCard: false,
  },
  CY: {
    country: 'Zypern / Cyprus',
    passport: true,
    identityCard: false,
  },
};

export const supportedNationalities = Object.keys(acceptedVerificationDocuments);

export const infoReportsSupportedCountryCodes = ['DE', 'AT', 'CH'];

export function findNationalityCategory(nationality: string): VerificationCategory {
  if (supportedNationalities.indexOf(nationality) < 0) {
    return NEWSLETTER;
  }
  if (nationality === 'DE') {
    return ID_OR_PASS_BILL;
  }
  if (
    acceptedVerificationDocuments[nationality].passport &&
    acceptedVerificationDocuments[nationality].identityCard
  ) {
    return ID_BILL_OR_PASS_BILL;
  }
  if (acceptedVerificationDocuments[nationality].passport) {
    return PASS_BILL;
  }
  if (acceptedVerificationDocuments[nationality].identityCard) {
    return ID_BILL;
  }
  return NEWSLETTER;
}

type Country = (typeof supportedCountries)[number];

export const isSupportedCountry = (country: string): country is Country => {
  return supportedCountries.find((c) => country === c) !== undefined;
};

export const resolveCountry = (country?: string): Country => {
  const resolvedCountry = country?.toUpperCase() ?? '';
  return isSupportedCountry(resolvedCountry) ? resolvedCountry : 'DE';
};

export const filterCountries = (billCountry: string) =>
  countryList.filter((country) => {
    if (forbiddenToEditToCountry.includes(country.value)) return billCountry === country.value;

    return true;
  });
