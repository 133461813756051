import { createSlice } from '@reduxjs/toolkit';
import { SAVINGS_PLANS_SLICE_NAME } from 'common/const/slices';
import { savingsPlansInitialState } from './initialState';
import {
  SavingsPlanCreateAction,
  SavingsPlanDeleteAction,
  SavingsPlanPauseAction,
  SavingsPlanSubscribeSuccessAction,
  SavingsPlanUnpauseAction,
} from './action-types';
import { savingsPlansAdapter } from './adapter';
import { SavingsPlanEntity } from 'types/savingsPlans';
import { handlePauseSavingPlanAction, handleUnpauseSavingPlanAction } from './utils';
import { CHANGE_ACCOUNT } from 'store/actions/auth';

const savingsPlansSlice = createSlice({
  name: SAVINGS_PLANS_SLICE_NAME,
  initialState: savingsPlansInitialState,
  reducers: {
    // GET ALL
    subscribeToSavingsPlans(state) {},
    subscribeToSavingsPlansSuccess(state, action: SavingsPlanSubscribeSuccessAction) {
      if (!action.payload.length) return;

      const items = action.payload.map(
        (savingsPlan): SavingsPlanEntity => ({ ...savingsPlan, updating: false }),
      );

      savingsPlansAdapter.setAll(state, items);
    },
    subscribeToSavingsPlansError(state) {},
    unsubscribeFromSavingsPlans(state) {},

    // PAUSE
    pauseSavingPlan(state, action: SavingsPlanPauseAction) {
      state.isPauseInProgress = true;

      handlePauseSavingPlanAction(state, action, savingsPlansAdapter, true);
    },
    pauseSavingPlanSuccess(state, action: SavingsPlanPauseAction) {
      state.isPauseInProgress = false;

      handlePauseSavingPlanAction(state, action, savingsPlansAdapter, false, true);
    },
    pauseSavingPlanError(state, action: SavingsPlanPauseAction) {
      state.isPauseInProgress = false;

      handlePauseSavingPlanAction(state, action, savingsPlansAdapter, false);
    },

    // UNPAUSE
    unpauseSavingPlan(state, action: SavingsPlanUnpauseAction) {
      state.isUnpauseInProgress = true;

      handleUnpauseSavingPlanAction(state, action, savingsPlansAdapter, true);
    },
    unpauseSavingPlanSuccess(state, action: SavingsPlanUnpauseAction) {
      state.isUnpauseInProgress = false;

      handleUnpauseSavingPlanAction(state, action, savingsPlansAdapter, false, true);
    },
    unpauseSavingPlanError(state, action: SavingsPlanUnpauseAction) {
      state.isUnpauseInProgress = false;

      handleUnpauseSavingPlanAction(state, action, savingsPlansAdapter, false);
    },

    // DELETE
    deleteSavingPlan(state, action: SavingsPlanDeleteAction) {
      state.isDeleteInProgress = true;
    },
    deleteSavingPlanSuccess(state, action: SavingsPlanDeleteAction) {
      state.isDeleteInProgress = false;

      const id = action.payload.entityId;

      if (!id) return;

      savingsPlansAdapter.removeOne(state, id);
    },
    deleteSavingPlanError(state, action: SavingsPlanDeleteAction) {
      state.isDeleteInProgress = false;
    },

    // CREATE
    createSavingPlan(state, action: SavingsPlanCreateAction) {
      state.isCreateInProgress = true;
    },
    createSavingPlanSuccess(state) {
      state.isCreateInProgress = false;
    },
    createSavingPlanError(state) {
      state.isCreateInProgress = false;
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      (action) => action.type === CHANGE_ACCOUNT.REQUEST,
      () => {
        return savingsPlansInitialState;
      },
    ),
});

export default savingsPlansSlice;
