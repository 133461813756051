import React, { useTranslation, Trans } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import CheckboxInput from 'common/components/CheckboxInput';
import { TNC_POPUP_TYPES } from 'common/const/termsAndConditions';
import Button from 'common/components/Button';
import TncExitIntent from 'common/components/Modal/TermsAndConditions/ExitIntent';
import { MODAL_DIMENSIONS } from 'common/const/modals';
import { ModalProps } from 'types/modals';
import {
  Body,
  BodyText,
  ButtonWrapper,
  CheckboxText,
  DocumentAttachment,
  DocumentsWrapper,
  Title,
} from 'common/components/Modal/TermsAndConditions/FeatureSpecificTncConsentModal/styles';
import { Formik } from 'formik';
import { useStakingFeatureSpecificTncConsentModal } from 'common/hooks/useStakingFeatureSpecificTncConsentModal';
import { CheckBoxWrapper, Spacer } from './styles';

const StakingFeatureSpecificTncConsentModal = ({ onClose }: ModalProps) => {
  const { t } = useTranslation();

  const {
    activePopUp,
    setActivePopUp,
    validationSchema,
    isConfirmGeneralTnCNeeded,
    isConfirmDirectiveNeeded,
    definedDocuments,
    declineTerms,
    agreeTerms,
    formikRef,
    textVersion,
    extendedTextVersionForExitPopup,
  } = useStakingFeatureSpecificTncConsentModal({
    onClose,
  });

  return (
    <>
      <TncExitIntent
        dimensions={MODAL_DIMENSIONS.SMALL}
        isOpened={activePopUp === TNC_POPUP_TYPES.EXIT_INTENT}
        onClose={declineTerms}
        onBackToPopUp={() => setActivePopUp(TNC_POPUP_TYPES.TNC)}
        customText={{
          title: t('stakingFeatureTermsAndConditions.exitPopup.title'),
          text: t(`stakingFeatureTermsAndConditions.exitPopup.body.${extendedTextVersionForExitPopup}`),
          primaryButton: t('stakingFeatureTermsAndConditions.exitPopup.primaryAction'),
          secondaryButton: t('stakingFeatureTermsAndConditions.exitPopup.secondaryAction'),
        }}
      />

      <Formik
        innerRef={formikRef}
        initialValues={{ general: false, specialConditions: false, directive: false }}
        onSubmit={agreeTerms}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, handleSubmit }) => (
          <ModalTemplate
            defaultOpen={activePopUp === TNC_POPUP_TYPES.TNC}
            onCancel={() => setActivePopUp(TNC_POPUP_TYPES.EXIT_INTENT)}
            header={<Title>{t('stakingFeatureTermsAndConditions.title')}</Title>}
            actions={
              <ButtonWrapper>
                <Button
                  title={t('stakingFeatureTermsAndConditions.agree')}
                  onClick={handleSubmit}
                  size="modal"
                />
              </ButtonWrapper>
            }
          >
            <Body>
              <BodyText>{t(`stakingFeatureTermsAndConditions.bodyText.${textVersion}`)}</BodyText>
              <CheckBoxWrapper>
                {isConfirmGeneralTnCNeeded && (
                  <CheckboxInput name="general" hideError>
                    <CheckboxText error={errors.general}>
                      {t('stakingFeatureTermsAndConditions.checkboxText.a')}
                    </CheckboxText>
                  </CheckboxInput>
                )}
                <CheckboxInput name="specialConditions" hideError>
                  <CheckboxText error={errors.specialConditions}>
                    {t('stakingFeatureTermsAndConditions.checkboxText.b')}
                  </CheckboxText>
                </CheckboxInput>
                {isConfirmDirectiveNeeded && (
                  <CheckboxInput name="directive" hideError>
                    <CheckboxText error={errors.directive}>
                      {t('stakingFeatureTermsAndConditions.checkboxText.c')}
                    </CheckboxText>
                  </CheckboxInput>
                )}
              </CheckBoxWrapper>

              {isConfirmDirectiveNeeded && (
                <BodyText>
                  <Trans i18nKey={'stakingFeatureTermsAndConditions.rewards'} components={{ 1: <br /> }} />
                </BodyText>
              )}

              <DocumentsWrapper>
                {definedDocuments.map((document) => (
                  <DocumentAttachment
                    key={document.title}
                    fileName={t(document.title)}
                    onItemClick={() => window.open(document.link, '_blank')}
                  />
                ))}
              </DocumentsWrapper>
              {!isConfirmDirectiveNeeded && <Spacer />}
            </Body>
          </ModalTemplate>
        )}
      </Formik>
    </>
  );
};

export default StakingFeatureSpecificTncConsentModal;
