/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { State } from 'store/types/store';

export const getMaxBatchSeconds = (state: State) => state.trackingConfig.MAX_BATCH_SECONDS;

export const getBatchSize = (state: State) => state.trackingConfig.BATCH_SIZE;

export const getTrackingConfigurationJson = (state: State) => state.trackingConfig.TRACKING_CONFIGURATION;
