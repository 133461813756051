import { all, call, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import I18n from 'i18next';

// import config from 'config';
import * as api from 'common/api';
import { paths } from 'common/urls';
// import * as kyc from 'services/kyc';
import { withSkew } from 'common/utils/math';
import { accountTypes, kycStatuses } from 'common/const';
import { authFetchEntity } from 'store/sagas/auth';
import * as formActions from 'store/actions/forms';
import * as authActions from 'store/actions/auth';
import * as notificationBannerActions from 'store/actions/notificationBanner';

import { getIsKycRenewRequired, getKycRenewStatus, getKycStatus, getUser } from 'store/selectors/auth';
import { getFormData } from 'store/selectors/forms';

import type { Saga } from 'redux-saga';
import { acceptCryptoTermsAndConditions } from '../actions/termsAndConditions';
import { errorCodes } from 'common/apiErrors';

export const fetchKycData = authFetchEntity.bind(null, formActions.kycData, api.kycData, false);

function* submitKycForm(includeBillInfo: boolean) {
  // grab form data from the store
  const formData = yield select(getFormData, includeBillInfo);
  const { error } = yield call(
    authFetchEntity,
    formActions.kycFormSubmit,
    api.kycFormSubmit,
    false,
    formData,
  );

  if (error) {
    const message =
      error.code === errorCodes.TAXRESIDENCY_ERR_TAXIDINVALID
        ? I18n.t('kyc.forms.errors.invalidTaxId')
        : I18n.t('kyc.forms.errors.title');

    yield put(
      notificationBannerActions.notifyError({
        message,
        subTitle: error.message,
      }),
    );
  } else {
    yield put(acceptCryptoTermsAndConditions.request());
  }
}

function* closeKyc(): Saga<void> {
  // const componentId = yield select(getComponentId);
  // Navigation.pop(componentId);
}

function* sendKycExitData(): Saga<void> {
  // grab form data from the store
  const formData = yield select(getFormData, false);
  const user = yield select(getUser);
  const newSubscriber = !user.newsletter && formData.newsletter;
  const { error } = yield call(authFetchEntity, formActions.kycFormExit, api.kycFormExit, false, formData);

  if (error) {
    yield put(
      notificationBannerActions.notifyError({
        message: `${I18n.t('kyc.newsletter.errors.problemSubscribing')} ${error.message}`,
      }),
    );
  } else {
    yield call(closeKyc);
    if (newSubscriber) {
      yield put(
        notificationBannerActions.notifySuccess({
          message: `${I18n.t('kyc.newsletter.successTitle')} ${I18n.t('kyc.newsletter.success')}`,
        }),
      );
    }
    // Save the KYC state one step back (before the newsletter form)
    yield put(formActions.prevKycStep());
  }
}

// Calls token validation (/user update) to retrieve the current kyc status
function* refreshKycStatus(): Saga<void> {
  yield put(authActions.fetchUserKycStatusRequest());
  yield take([authActions.FETCH_USER_KYC_STATUS_SUCCESS, authActions.FETCH_USER_KYC_STATUS_FAILURE]);
}

function* startIdentification(action: any): Saga<void> {
  const status = yield select(getKycStatus);

  // Always resubmit if not already confirmed or pending
  if (status !== kycStatuses.Confirmed && status !== kycStatuses.Pending) {
    yield fork(submitKycForm, action.includeBillInfo); // stores transactionToken in store

    // Wait
    const { error } = yield race({
      success: take(formActions.KYC_FORM_SUBMIT.SUCCESS),
      error: take(formActions.KYC_FORM_SUBMIT.FAILURE),
    });
    if (error) {
      return; // Cancel process
    }
    // Update user data immediately after BankIdCreated
    yield fork(refreshKycStatus);
    if (action.navigate) action.navigate(paths.KYC_IDENT_OPTIONS);
  }
}

function* watchStartIdentification(): Saga<void> {
  while (true) {
    const payload = yield take(formActions.START_IDENTIFICATION);
    yield call(startIdentification, payload); // blocking
    yield put(formActions.finishIdentification());
  }
}

function* watchKycFormExit(): Saga<void> {
  yield takeLatest(formActions.USER_KYC_EXIT, sendKycExitData);
}

function* watchCloseKyc(): Saga<void> {
  while (true) {
    yield take(formActions.CLOSE_KYC);
    yield fork(closeKyc);
  }
}

function* switchToRealMoney() {
  yield put(authActions.changeAccount.request(accountTypes.real));
  yield take(authActions.INVALIDATE_ACCOUNT_DATA);
  // TODO: Handle that
  // yield put(push(paths.PORTFOLIO));
  yield put(
    notificationBannerActions.notifySuccess({
      message: `${I18n.t('kyc.success.successBanner.title')} ${I18n.t('kyc.success.successBanner.subTitle')}`,
    }),
  );
}

function* checkKycStatus(): Saga<void> {
  const checkTimeInterval = (status: string) => {
    switch (status) {
      case kycStatuses.Pending:
        return 15;
      case kycStatuses.BankIdCreated:
        return 20;
      case kycStatuses.Invalid:
        return 60;
      default:
        // NotStarted, Submitted
        return 120;
    }
  };

  while (true) {
    const prevStatus = yield select(getKycStatus);
    const dt = checkTimeInterval(prevStatus);
    const { refresh } = yield race({
      delay: delay(withSkew(dt * 1000)),
      refresh: take(formActions.REFRESH_KYC_STATUS),
    });
    yield call(refreshKycStatus);
    const status = yield select(getKycStatus);

    if (refresh) {
      yield put(
        notificationBannerActions.notifySuccess({
          message: `${I18n.t('kyc.success.refreshed')}`,
        }),
      );
    }

    if (status === kycStatuses.Confirmed) {
      yield put(formActions.fetchKycData());
      yield call(switchToRealMoney);
      return;
    }
  }
}

function* watchKycStatus(): Saga<void> {
  const status = yield select(getKycStatus);
  const kycRenewStatus = yield select(getKycRenewStatus);
  const isKycRenewRequired = yield select(getIsKycRenewRequired);

  if (status === kycStatuses.Confirmed && (!isKycRenewRequired || kycRenewStatus === kycStatuses.Confirmed))
    return;

  yield fork(checkKycStatus);
}

function* watchKycData(): Saga<void> {
  // Initial load
  yield call(fetchKycData);

  while (true) {
    yield take(formActions.FETCH_KYC_DATA);
    yield call(fetchKycData);
  }
}

export default function* root(): Saga<void> {
  yield all([
    fork(watchStartIdentification),
    fork(watchKycFormExit),
    fork(watchCloseKyc),
    fork(watchKycStatus),
    fork(watchKycData),
  ]);
}
