import { State } from 'store/types/store';
import { getMarketOverviewSearchAssetCodes } from '../filters/selectors';
import { getAllAssetsForCurrentAssetTag } from '../assets/selectors';
import { ASSET_CLASSES, Asset, MarketOverviewAsset } from 'types/assets';
import { createSelector } from 'reselect';
import { calculateStockPriceChange } from 'common/utils/assets';
import { MARKET_OVERVIEW_SLICE_NAME } from 'common/const/slices';
import { getMarketOverviewPrices } from '../prices/selectors';
import { PriceEntity } from 'store/types/prices';
import { getMarketOverviewPriceChanges } from '../priceChanges/selectors';
import { PriceChangeEntity } from 'store/types/priceChanges';
import { getMarketOverviewPriceHistorySmallCharts } from 'store/slices/priceHistorySmallChart/selectors';
import { PriceHistorySmallChartEntity } from 'store/types/priceHistory';

const selectSlice = (state: State) => state[MARKET_OVERVIEW_SLICE_NAME];

export const getMarketOverviewReference = (state: State) => selectSlice(state).reference;

export const getMarketOverviewIsFullyLoaded = (state: State) => selectSlice(state).isFullyLoaded;

export const getMarketOverviewIsInitialLoading = (state: State) => {
  const slice = selectSlice(state);

  return slice.isLoading && !slice.reference;
};

export const getMarketOverviewIsNextPageLoading = (state: State) => {
  const slice = selectSlice(state);

  // If there is reference set then the next page is loading.
  return slice.isLoading && !!slice.reference;
};

// MARKET OVERVIEW ASSETS

/**
 * Will get all asset objects for stored searched asset codes.
 * If there is no search text all items for selected asset tag will be returned.
 * Items should be retrieved sorted the same as they were in the request's response.
 *
 * @summary Asset infos for Market Overview page.
 *
 * @private
 * @param {State} state
 * @returns {Asset[]}
 */
const getMarketOverviewAssetsBySearchCodes = createSelector(
  [getMarketOverviewSearchAssetCodes, getAllAssetsForCurrentAssetTag],
  (codes: string[], allAssets: Asset[]) =>
    allAssets
      .filter((asset: Asset) => codes.includes(asset.code.toLowerCase()))
      .sort(
        (a: Asset, b: Asset) => codes.indexOf(a.code.toLowerCase()) - codes.indexOf(b.code.toLowerCase()),
      ),
);

/**
 * Returns all assets displayed on the Market Overview page with prices and price changes.
 *
 * @summary Assets for Market Overview page.
 *
 * @public
 * @param {State} state
 * @returns {MarketOverviewAsset[] | undefined}
 */
export const getMarketOverviewAssets = createSelector(
  [
    getMarketOverviewAssetsBySearchCodes,
    getMarketOverviewPrices,
    getMarketOverviewPriceChanges,
    getMarketOverviewPriceHistorySmallCharts,
  ],
  (
    allAssets,
    prices: PriceEntity[],
    priceChanges: PriceChangeEntity[],
    smallCharts: PriceHistorySmallChartEntity[],
  ): MarketOverviewAsset[] | undefined => {
    return allAssets?.map((asset: Asset) => {
      const priceChangeCollection: {
        priceChange?: number;
        priceChangePercentage?: number;
      } = {};

      const priceChangeData = priceChanges.find(
        (priceChange) => priceChange.assetCode.toLowerCase() === asset.code.toLowerCase(),
      );

      if (priceChangeData) {
        priceChangeCollection.priceChangePercentage = calculateStockPriceChange(priceChangeData.relative);
        priceChangeCollection.priceChange = priceChangeData.absolute;
      }

      const priceData = prices.find((price) => price.baseEntity.toLowerCase() === asset.code.toLowerCase());

      const price = priceData?.midPrice;

      const smallChartData =
        asset.class === ASSET_CLASSES.CRYPTO
          ? smallCharts.find(
              (chartEntity) => chartEntity.assetCode.toLowerCase() === asset.code.toLowerCase(),
            )
          : undefined;

      return {
        name: asset.displayName,
        code: asset.code,
        assetClass: asset.class,
        assetSecurityClass: asset.securityClass,
        chartData: smallChartData?.items,
        price,
        ...priceChangeCollection,
      } as MarketOverviewAsset;
    });
  },
);
