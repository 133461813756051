import { createAction } from '@reduxjs/toolkit';
import { OrderSubtype } from 'common/const';
import { Order } from 'types/currency';

export const loadInitialActivityItems = createAction('loadInitialActivityItems');

export const loadNextActivityItemsPage = createAction('loadNextActivityItemsPage');

export const loadActivityItemsSuccess = createAction<{ orders: Order[] }>('loadActivityItemsSuccess');
export const loadActivityItemsError = createAction('loadActivityItemsError');

export const setCryptoActivityFilter = createAction<{ crypto?: string }>('setCryptoActivityFilter');
export const setSubtypeActivityFilter = createAction<{ subtype?: OrderSubtype[] }>(
  'setSubtypeActivityFilter',
);
