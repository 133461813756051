import { useEffect } from 'react';
import { useModalProvider } from 'providers/Modal/useModalProvider';
import { setModalQueueInstance } from 'common/utils/modalQueue';

const useEpicModalQueue = () => {
  const modalProvider = useModalProvider();

  useEffect(() => {
    setModalQueueInstance(modalProvider);
  }, [modalProvider]);
};

export default useEpicModalQueue;
