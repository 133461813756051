import newsSlice from '.';

export const {
  fetchNewsFeedRequest,
  subscribeToNewsFeed,
  fetchNewsFeedFailure,
  fetchNewsFeedSuccess,
  unsubscribeFromNewsFeed,
  loadNewsFeedIfNeeded,
  invalidateNewsFeed,
  changeNewsLang,
} = newsSlice.actions;
