import React from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { useCryptoLabelColor } from 'common/hooks/useCryptoLabelColor';
import { useInputStyles } from 'common/styles/inputStyles';
import { useThemeContext } from 'common/utils/theme/ThemeContext';
import EnhanceWithTheme from 'common/utils/theme/EnhanceWithTheme';
import {
  CurrencyLabelBox,
  ErrorContainer,
  ErrorWrapper,
  InputContainer,
  Label,
  WarningContainer,
  Wrapper,
} from './styles';
import { InputProps } from './types';
import { useCryptoEuroInputActions, useCursorPositionTracking } from './hooks';

const CryptoEuroInput = ({
  fieldValue,
  type,
  placeholder,
  currencyLabel,
  currencyColor,
  labelComponent,
  onChange,
  id,
  error,
  warning,
  onBlur,
  setIsActive = () => null,
  isCrypto,
  cryptoName,
  onFocus = () => null,
  autofocus,
  currencyPrecision,
  endAdornment,
  formatOnBlur = false,
  hideErrorMessage = false,
  highlightError = false,
  testId,
}: InputProps): JSX.Element => {
  const theme = useThemeContext();
  const muiInputThemeStyles = useInputStyles(theme);
  const { checkCursorPosition, field } = useCursorPositionTracking();
  const { handleFocus, handleChange, handleBlur, formatInputValue } = useCryptoEuroInputActions({
    isCrypto,
    cryptoName,
    currencyPrecision,
    checkCursorPosition,
    onFocus,
    onChange,
    onBlur,
    setIsActive,
    formatOnBlur,
    fieldValue,
    testId,
  });
  const currencyColorThemed = useCryptoLabelColor(currencyColor);

  const isErrorOccurred = error !== undefined;
  const isWarningOccurred = warning !== undefined;

  return (
    <MuiThemeProvider theme={muiInputThemeStyles}>
      <Wrapper>
        <Label $hasErrorOccurred={isErrorOccurred} $hasWarningOccurred={isWarningOccurred}>
          {labelComponent}
        </Label>
        <ErrorWrapper
          $highlightError={highlightError}
          $hasErrorOccurred={isErrorOccurred}
          $hasWarningOccurred={isWarningOccurred}
        >
          <InputContainer>
            <TextField
              autoFocus={autofocus}
              inputRef={field}
              id={id}
              type={type}
              placeholder={placeholder}
              fullWidth
              onChange={handleChange}
              value={formatInputValue(fieldValue)}
              error={isErrorOccurred || isWarningOccurred}
              onFocus={handleFocus}
              onBlur={handleBlur}
              margin="none"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyLabelBox
                      $color={currencyColorThemed}
                      $highlightError={isErrorOccurred && highlightError}
                    >
                      {currencyLabel}
                    </CurrencyLabelBox>
                  </InputAdornment>
                ),
                endAdornment,
              }}
            />
          </InputContainer>
          {isErrorOccurred && !hideErrorMessage && <ErrorContainer>{error}</ErrorContainer>}
          {isWarningOccurred && <WarningContainer>{warning}</WarningContainer>}
        </ErrorWrapper>
      </Wrapper>
    </MuiThemeProvider>
  );
};

export default EnhanceWithTheme(CryptoEuroInput);
