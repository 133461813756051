import React, { ReactElement } from 'react';
import { fileSizeFormatters } from 'common/utils/formatting';
import { Attachment, AttachmentName, ModalIcon } from './styles';

interface Props {
  fileName: string;
  size?: number;
  onClick?: () => void;
  iconName?: string;
}

const InboxAttachment = ({ fileName, size, onClick, iconName, ...rest }: Props): ReactElement => (
  <Attachment onClick={() => onClick && onClick()} {...rest}>
    <ModalIcon customColor="red" name={iconName} sizeValue="tiniest" />
    <AttachmentName title={fileName}>{fileName}</AttachmentName>
    {size && <span>&nbsp;{`(${fileSizeFormatters.format(size)})`}</span>}
  </Attachment>
);

InboxAttachment.defaultProps = {
  size: null,
  onClick: () => undefined,
  iconName: 'icon-document-icon',
};

export default InboxAttachment;
