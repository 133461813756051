import { State } from 'store/types/store';
import { WinnerLoserType } from 'types/winnersLosers';

export const getWinnersAndLosersTypeFromGroup = (state: State): WinnerLoserType | undefined => {
  const group = state.experimentsRemoteData.SHOW_STOCKS_WINNERS_LOSERS_MODAL;

  switch (group) {
    case 'A':
      return 'winner';
    case 'B':
      return 'loser';
    default:
      return undefined;
  }
};

export const getEtpShortsOnCryptoDetailScreen = (state: State) =>
  state.experimentsRemoteData.ETP_SHORTS_ON_CRYPTO_DETAIL_SCREEN;

export const getBlockPitDisplayedElement = (state: State) =>
  state.experimentsRemoteData.BLOCKPIT_REFERAL_LINK === 'A' ? 'banner' : 'section';
