import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { AccountType, accountTypes } from 'common/const';
import { paths } from 'common/urls';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import ModalTemplate from 'common/components/Modal/index';
import { MODAL_DIMENSIONS } from 'common/const/modals';
import { ModalProps } from 'types/modals';
import {
  BulletPoint,
  BulletPointText,
  ContentWrapper,
  EmptyProfileImg,
  HeadlineText,
  ImgContainer,
  ModalWrapper,
  SubText,
  TextContainer,
  MarkdownWrapper,
  HeaderText,
  Separator,
} from '../DefaultFeaturesModalStyles/styles';

type Props = {
  open: boolean;
  onKycDoneSuccess?: null | (() => void);
  isKycDone: boolean;
  changeAccountType: (accountType: AccountType) => void;
  title?: string;
  description?: null | string;
} & ModalProps;

const DisabledFeatures = ({
  onClose,
  open,
  isKycDone,
  changeAccountType,
  onKycDoneSuccess,
  title,
  description,
}: Props) => {
  const { t } = useTranslation();
  const bulletPoints = ['1', '2', '3'];
  const changeAccount = () => changeAccountType(accountTypes.real);
  const defaultTitle = isKycDone ? t('demoPopupForRealUser.headline') : t('demoPopup.headline');
  const definedTitle = title ?? defaultTitle;

  return (
    <ModalTemplate
      onCancel={onClose}
      defaultOpen={open}
      header={<HeaderText>{defaultTitle}</HeaderText>}
      modalDimensions={MODAL_DIMENSIONS.WIDE}
      invertedHeader
      disableActionsBlock
      noRoundedBorders
    >
      <ContentWrapper>
        <ImgContainer>
          <EmptyProfileImg />
        </ImgContainer>
        <TextContainer>
          <HeadlineText>{t(definedTitle)}</HeadlineText>
          {!!description && (
            <MarkdownWrapper>
              <ReactMarkdown>{t(description)}</ReactMarkdown>
            </MarkdownWrapper>
          )}
          {!description && !isKycDone && (
            <>
              <SubText>{t('demoPopup.subText')}</SubText>
              {bulletPoints.map((point) => (
                <BulletPoint key={point}>
                  <Icon name="icon-checkIcon" tone="positive" sizeValue="tiniest" />
                  <BulletPointText>{t(`demoPopup.${point}`)}</BulletPointText>
                </BulletPoint>
              ))}
              <SubText>{t('demoPopup.finalText')}</SubText>
            </>
          )}
          {!description && isKycDone && (
            <>
              <SubText>{t('demoPopupForRealUser.subText')}</SubText>
              <Separator />
            </>
          )}
          <ModalWrapper.Actions>
            {(isKycDone && (
              <Button
                title={t('prompts.realMoneyToggleBtn')}
                isFullWidth
                size="modal"
                onClick={() => {
                  changeAccount();
                  if (onKycDoneSuccess) {
                    onKycDoneSuccess();
                  }
                  onClose();
                }}
              />
            )) || (
              <Button
                size="modal"
                title={t('prompts.doKycBtn')}
                to={paths.KYC}
                isFullWidth
                onClick={onClose}
              />
            )}
          </ModalWrapper.Actions>
        </TextContainer>
      </ContentWrapper>
    </ModalTemplate>
  );
};

DisabledFeatures.defaultProps = {
  title: undefined,
  description: null,
  onKycDoneSuccess: null,
};

export default DisabledFeatures;
