import { pipe, range, timer, zip, of, throwError, MonoTypeOperatorFunction } from 'rxjs';
import { retryWhen, map, mergeMap } from 'rxjs/operators';

// https://angular.io/guide/practical-observable-usage#exponential-backoff
export function backoff<T>(
  maxTries: number,
  ms: number,
  shouldRetry: (error: any) => boolean,
): MonoTypeOperatorFunction<T> {
  return pipe(
    retryWhen((attempts) =>
      zip(range(1, maxTries), attempts).pipe(
        mergeMap(([idx, error]) =>
          idx < maxTries && shouldRetry(error)
            ? of(idx).pipe(
                map((i) => i * i),
                mergeMap((i) => timer(i * ms)),
              )
            : throwError(error),
        ),
      ),
    ),
  );
}
