import React, { useContext } from 'react';
import { BrazeContext } from './context';

export const useBrazeProvider = () => {
  const context = useContext(BrazeContext);
  if (!context) {
    throw new Error('`useBrazeProvider` must be used within a BrazeProvider');
  }

  return context;
};
