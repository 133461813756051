import React, { PropsWithChildren, useState } from 'react';
import TopBarContext from 'providers/TopBar/context';

const TopBarProvider = ({ children }: PropsWithChildren) => {
  const [customOnBack, setCustomOnBack] = useState<() => void>(() => null);

  const setOnBack = (back: () => void) => {
    setCustomOnBack(() => back);
  };

  return (
    <TopBarContext.Provider
      value={{
        customOnBack,
        setCustomOnBack: setOnBack,
      }}
    >
      {children}
    </TopBarContext.Provider>
  );
};

export default TopBarProvider;
