import { Path, useNavigate, NavigateOptions } from 'react-router-dom';

/**
 * Custom hook for handling modal navigation with additional options.
 *
 * Note: This hook includes a workaround to handle navigation with additional options and can be used to mitigate
 * transition bugs. Once the underlying issue causing the transition bug is resolved, you can safely remove or replace
 * this hook with direct usage of the React Router `useNavigate` hook.
 *
 * @example
 * // Usage in a functional component:
 * // const handleModalNavigate = useModalNavigate();
 * // handleCustomNavigate({ to: '/dashboard', event: {}, navigateOptions: { state: { key: 'value' } } });
 *
 * @returns {Function} A function to handle modal navigation with additional options.
 */
export const useModalNavigate = (): ((
  to: string | Partial<Path>,
  event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  navigateOptions?: NavigateOptions,
) => void) => {
  const navigate = useNavigate();

  /**
   * Handles navigation with additional options.
   *
   * @param {string} to - The target URL to navigate to.
   * @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} event - The event data triggering the navigation (e.g., 'click').
   * @param {NavigateOptions} navigateOptions - Additional options to pass to the `navigate` function.
   */
  const modalNavigation = (
    to: string | Partial<Path>,
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    navigateOptions?: NavigateOptions,
  ) => {
    event?.stopPropagation();
    event?.preventDefault();
    setTimeout(() => {
      navigate(to, navigateOptions ?? {});
    }, 0);
  };

  return modalNavigation;
};
