import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAX_EXEMPTION_SLICE_NAME } from 'common/const/slices';
import {
  EditTaxExemptionData,
  SubmitTaxExemptionRequestData,
  TaxExemptionData,
  VerifyTaxExemptionRequestData,
} from 'types/taxExemption';
import { ApiError } from 'types/error';
import { initialState } from './initialState';

const taxExemptionSlice = createSlice({
  name: TAX_EXEMPTION_SLICE_NAME,
  initialState,
  reducers: {
    // FETCH TAX EXEMPTIONS
    fetchTaxExemptionsRequest(state) {
      state.fetchedTaxExemption.isFetching = true;
    },
    fetchTaxExemptionsSuccess(state, action: PayloadAction<TaxExemptionData | null>) {
      state.fetchedTaxExemption.isFetching = false;

      if (action.payload) state.fetchedTaxExemption.data = action.payload;
    },
    fetchTaxExemptionsFailure(state) {
      state.fetchedTaxExemption.isFetching = false;
    },

    // SUBMIT TAX EXEMPTION
    submitTaxExemptionRequest(state, action: PayloadAction<SubmitTaxExemptionRequestData>) {
      state.editTaxExemptionData.isFetching = true;
      state.editTaxExemptionData.data = {};
    },
    submitTaxExemptionSuccess(state, action: PayloadAction<EditTaxExemptionData>) {
      state.editTaxExemptionData.isFetching = false;
      state.editTaxExemptionData.data = action.payload;
    },
    submitTaxExemptionFailure(state, action: PayloadAction<ApiError>) {
      state.editTaxExemptionData.isFetching = false;
    },

    // VERIFY TAX EXEMPTION
    verifyTaxExemptionRequest(state, action: PayloadAction<VerifyTaxExemptionRequestData>) {},
    verifyTaxExemptionSuccess(state, action: PayloadAction<TaxExemptionData>) {},
    verifyTaxExemptionFailure(state, action: PayloadAction<ApiError>) {},
  },
});

export default taxExemptionSlice;
