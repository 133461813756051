import { createSlice } from '@reduxjs/toolkit';
import { winnersLosersInitialState } from './initialState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { WinnerLoserItem, WinnerLoserType } from 'types/winnersLosers';
import { Asset } from 'types/assets';

const winnersLosersSlice = createSlice({
  name: 'winnersLosers',
  initialState: winnersLosersInitialState,
  reducers: {
    loadWinnerLosersData(state, action: PayloadAction<WinnerLoserType>) {
      state.type = action.payload;
      state.isLoading = true;
    },
    loadWinnerLosersDataSuccess(state, action: PayloadAction<WinnerLoserItem[]>) {
      state.assets = action.payload;
      state.isLoading = false;
    },
    loadWinnerLosersDataError(state) {
      state.isLoading = false;
    },
  },
});

export default winnersLosersSlice;
