import { createSlice } from '@reduxjs/toolkit';
import { priceChangesInitialState } from './initialState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PRICE_CHANGES_SLICE_NAME } from 'common/const/slices';
import { priceChangesAdapter } from './adapter';
import { ASSET_CLASSES } from 'types/assets';
import { TimePeriod } from 'types/currency';
import { PriceChangeData, PriceChangeEntity } from 'store/types/priceChanges';
import { priceChangeDataItemsToPriceEntityItems } from 'common/utils/priceChanges';

const pricesSlice = createSlice({
  name: PRICE_CHANGES_SLICE_NAME,
  initialState: priceChangesInitialState,
  reducers: {
    // UTILITY ACTIONS
    /**
     * This actions are used for subscribing/unsubscribing to/from a correct asset type price changes request.
     * It gets the latest asset tag (from marketOverview) and then based on that maps the correct action.
     * This action is most useful on marketOverview page
     *
     */
    subscribeToPriceChangesForSelectedAssetTag(state, action: PayloadAction<{ codes: string[] }>) {},
    unsubscribeFromPriceChangesForSelectedAssetTag() {},

    // CRYPTO PRICES
    subscribeToCryptoPriceChanges(state, action: PayloadAction<{ codes: string[] }>) {
      state.areCryptoPriceChangesFetching = true;
    },
    subscribeToCryptoPriceChangesSuccess(
      state,
      action: PayloadAction<{ prices: PriceChangeData[]; timePeriod: TimePeriod }>,
    ) {
      const entities: PriceChangeEntity[] = priceChangeDataItemsToPriceEntityItems(
        action.payload.prices,
        ASSET_CLASSES.CRYPTO,
        action.payload.timePeriod,
      );

      priceChangesAdapter.upsertMany(state, entities);
    },
    subscribeToCryptoPriceChangesError(state) {},
    unsubscribeFromCryptoPriceChanges(state) {
      state.areCryptoPriceChangesFetching = false;
    },

    // SECURITY PRICES
    subscribeToSecurityPriceChanges(state, action: PayloadAction<{ codes: string[] }>) {
      state.areSecuritiesPriceChangesFetching = true;
    },
    subscribeToSecurityPriceChangesSuccess(
      state,
      action: PayloadAction<{ prices: PriceChangeData[]; timePeriod: TimePeriod }>,
    ) {
      const entities: PriceChangeEntity[] = priceChangeDataItemsToPriceEntityItems(
        action.payload.prices,
        ASSET_CLASSES.SECURITY,
        action.payload.timePeriod,
      );

      priceChangesAdapter.upsertMany(state, entities);
    },
    subscribeToSecurityPriceChangesError(state) {},
    unsubscribeFromSecurityPriceChanges(state) {
      state.areSecuritiesPriceChangesFetching = false;
    },
  },
});

export default pricesSlice;
