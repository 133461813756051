import savingsPlansSlice from '.';

export const {
  // GET ALL
  subscribeToSavingsPlans,
  subscribeToSavingsPlansError,
  subscribeToSavingsPlansSuccess,
  unsubscribeFromSavingsPlans,
  // PAUSE
  pauseSavingPlan,
  pauseSavingPlanError,
  pauseSavingPlanSuccess,
  // UNPAUSE
  unpauseSavingPlan,
  unpauseSavingPlanError,
  unpauseSavingPlanSuccess,
  // DELETE
  deleteSavingPlan,
  deleteSavingPlanError,
  deleteSavingPlanSuccess,
  // CREATE
  createSavingPlan,
  createSavingPlanError,
  createSavingPlanSuccess,
} = savingsPlansSlice.actions;
