/* eslint-disable @typescript-eslint/no-unsafe-return */
import { TermsAndConditionsData } from 'types/termsAndConditions';
import {
  ACCEPT_CRYPTO_TERMS_AND_CONDITIONS,
  ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
  ACCEPT_STOCKS_TERMS_AND_CONDITIONS,
  CLOSE_SOFT_BLOCK_POP_UP,
  CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY,
  FETCH_CRYPTO_TERMS_AND_CONDITIONS,
  FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  FETCH_STOCKS_TERMS_AND_CONDITIONS,
  SHOW_FEATURE_TERMS_AND_CONDITIONS,
  SHOW_SOFT_BLOCK_POP_UP,
  STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY,
} from 'store/actions/termsAndConditions';
import { AnyAction, combineReducers } from 'redux';
import { TermsAndConditions, TermsAndConditionsState } from 'store/types/termsAndConditions';
import { ConsentState, FeatureSpecificConsentsState } from 'store/types/featureSpecificConsent';
import { CONSENT_TYPES } from 'common/const/consent';

const termsAndConditionsData: TermsAndConditionsData = {
  hasAcceptedLatestTerms: false,
  latestTerms: {
    version: '1',
    effectiveAt: null,
    termsUrl: null,
    risksUrl: null,
  },
  acceptedTerms: {
    version: '1',
    effectiveAt: null,
    termsUrl: null,
    risksUrl: null,
  },
  blockedFeatures: null,
  warningStage: false,
};

const termsAndConditionsSlice: TermsAndConditions = {
  data: termsAndConditionsData,
  failedAttempts: 0,
  isDataInitiallyLoaded: false,
  isTermsAndConditionsClickedAway: false,
  isSoftBlockPopUpShown: false,
};

export const featureSpecificConsentState: ConsentState = {
  isPopupShown: false,
  isDataInitiallyLoaded: false,
  failedAttempts: 0,
  consent: {
    consentModel: 'Unspecified',
    consentState: null,
    confirmedAt: '',
    consentType: '',
  },
};

export const featureSpecificConsentsState: FeatureSpecificConsentsState = Object.values(CONSENT_TYPES).reduce(
  (state, consentName) => {
    state[consentName] = featureSpecificConsentState;
    return state;
  },
  {},
);

const initialState: TermsAndConditionsState = {
  crypto: termsAndConditionsSlice,
  stocks: termsAndConditionsSlice,
  featureSpecificConsents: featureSpecificConsentsState,
};

/* export default function termsAndConditions(
  state: TermsAndConditionsState = initialState,
  action: AnyAction,
): TermsAndConditionsState {
  switch (action.type) {
    case FETCH_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS:
      return { ...state, crypto: { ...state.crypto, data: action?.payload as TermsAndConditionsData } };
    case ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS:
      return {
        ...state,
        crypto: {
          ...state.crypto,
          data: {
            ...action?.payload,
            blockedFeatures: action?.payload?.blockedFeatures ?? null,
            hasAcceptedLatestTerms: true,
          },
        },
      };
    case FETCH_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS:
      return { ...state, crypto: { ...state.crypto, isDataInitiallyLoaded: true } };
    case TERMS_AND_CONDITIONS_CLICKED_AWAY:
      return {
        ...state,
        crypto: { ...state.crypto, isTermsAndConditionsClickedAway: action.payload as boolean },
      };
    case ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS:
      return state;
    case ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.FAILURE:
      return { ...state, crypto: { ...state.crypto, failedAttempts: ++state.crypto.failedAttempts } };
    case SHOW_SOFT_BLOCK_POP_UP:
      return { ...state, crypto: { ...state.crypto, isSoftBlockPopUpShown: true } };
    case CLOSE_SOFT_BLOCK_POP_UP:
      return { ...state, crypto: { ...state.crypto, isSoftBlockPopUpShown: false } };
    case ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.FAILURE:
    default:
      return state;
  }
} */

const crypto = (state = termsAndConditionsSlice, action: AnyAction) => {
  switch (action.type) {
    case ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS:
      return {
        ...state,
        data: {
          ...action?.payload,
          blockedFeatures: action?.payload?.blockedFeatures ?? null,
          hasAcceptedLatestTerms: true,
        },
      };
    case FETCH_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS:
      return { ...state, isDataInitiallyLoaded: true, data: action?.payload };
    case CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY:
      return {
        ...state,
        isTermsAndConditionsClickedAway: action.payload as boolean,
      };
    case ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.FAILURE:
      return { ...state, failedAttempts: state.failedAttempts + 1 };
    case SHOW_SOFT_BLOCK_POP_UP:
      return { ...state, isSoftBlockPopUpShown: true };
    case CLOSE_SOFT_BLOCK_POP_UP:
      return { ...state, isSoftBlockPopUpShown: false };
    default:
      return state;
  }
};
const stocks = (state = termsAndConditionsSlice, action: AnyAction) => {
  switch (action.type) {
    case ACCEPT_STOCKS_TERMS_AND_CONDITIONS.SUCCESS:
      return {
        ...state,
        data: {
          ...action?.payload,
          blockedFeatures: action?.payload?.blockedFeatures ?? null,
          hasAcceptedLatestTerms: true,
        },
      };
    case STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY:
      return {
        ...state,
        isTermsAndConditionsClickedAway: action.payload as boolean,
      };
    case FETCH_STOCKS_TERMS_AND_CONDITIONS.SUCCESS:
      return { ...state, isDataInitiallyLoaded: true, data: action?.payload };
    case ACCEPT_STOCKS_TERMS_AND_CONDITIONS.FAILURE:
      return { ...state, failedAttempts: state.failedAttempts + 1 };
    default:
      return state;
  }
};

const featureSpecificConsents = (
  state = featureSpecificConsentsState,
  action: AnyAction,
): FeatureSpecificConsentsState => {
  switch (action.type) {
    case FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        [action.payload.consentName]: {
          ...(state[action.payload.consentName] || featureSpecificConsentState),
          consent: action.payload.consent,
          isDataInitiallyLoaded: true,
        },
      };
    case SHOW_FEATURE_TERMS_AND_CONDITIONS:
      return {
        ...state,
        [action.payload.consentName]: {
          ...(state[action.payload.consentName] || featureSpecificConsentState),
          isPopupShown: action.payload.newValue as boolean,
        },
      };
    case ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        [action.payload.consentName]: {
          ...(state[action.payload.consentName] || featureSpecificConsentState),
          failedAttempts: state[action.payload.consentName].failedAttempts + 1,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  crypto,
  stocks,
  featureSpecificConsents,
});
