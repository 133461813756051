export const cryptoColors = Object.freeze({
  btc: '#F7931A',
  bch: '#0AC18E',
  ada: '#0033ad',
  link: '#2A5ADA',
  eth: '#000000',
  ltc: '#345D9D',
  dot: '#E6007A',
  xrp: '#23282D',
  sol: '#DA21FE',
  uni: '#FF007A',
  eur: '#1D25E9',
  shib: '#F00500',
  doge: '#BA9F33',
  pol: '#8247E5',
  xlm: '#000000',
  mana: '#FF2D55',
  sand: '#0084FF',
  aave: '#B6509E',
  algo: '#000000',
  avax: '#E84142',
  axs: '#0055D5',
  ape: '#073183',
  bat: '#FF5000',
  chz: '#FF1256',
  atom: '#2E3148',
  crv: '#000000',
  dai: '#F5AC37',
  enj: '#7866D5',
  etc: '#3AB83A',
  near: '#000000',
  qnt: '#000000',
  xtz: '#2C7DF7',
  grt: '#6747ED',
  usdc: '#2775CA',
} as const);

export const FETCH_MARKET_OVERVIEW_PAGE_SIZE = 20;

export const SPECIAL_LIQUID_CRYPTO_CURRENCIES: Record<string, string> = {
  btc: 'btc',
  eth: 'eth',
} as const;

export const SPECIAL_CRYPTO_CURRENCIES_DEVIATIONS = {
  MIN: 5,
  MEDIUM: 6,
  MAX: 12,
} as const;

export const CRYPTO_CURRENCIES_DEVIATIONS = {
  MIN: 5,
  MEDIUM: 12,
  MAX: 19,
} as const;
