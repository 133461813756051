import { ApiError } from 'common/apiErrors';
import { DeviceMonitoringConsentState } from 'types/deviceMonitoring';
import { ConsentModelType } from 'types/consent';
import { action } from './_common';

export const DEVICE_MONITORING_SCRIPT_LOADED = 'DEVICE_MONITORING_SCRIPT_LOADED';
export const deviceMonitoringScriptLoaded = () => action(DEVICE_MONITORING_SCRIPT_LOADED);

export const DEVICE_MONITORING_PREINIT_CHECK = 'DEVICE_MONITORING_PREINIT_CHECK';
export const deviceMonitoringPreinitCheck = (username: string) =>
  action(DEVICE_MONITORING_PREINIT_CHECK, { username });

export const DEVICE_MONITORING_INIT = 'DEVICE_MONITORING_INIT';
export const deviceMonitoringInit = (username: string, consentModel: ConsentModelType) =>
  action(DEVICE_MONITORING_INIT, { username, consentModel });

export const DEVICE_MONITORING_CONSENT = 'DEVICE_MONITORING_CONSENT';
export const deviceMonitoringConsent = (consentState: DeviceMonitoringConsentState) =>
  action(DEVICE_MONITORING_CONSENT, { consentState });

export const DEVICE_CONSENT_ID = 'DEVICE_CONSENT_ID';
export const deviceConsentIdSuccess = (values: { username: string; deviceConsentId: string }) =>
  action(DEVICE_CONSENT_ID, values);

export const DEVICE_CONSENT_ID_ERROR = 'DEVICE_CONSENT_ID_ERROR';
export const deviceConsentIdError = (error: any) => action(DEVICE_CONSENT_ID_ERROR, { error });

export const DEVICE_DATA_REQUEST = 'DEVICE_DATA_REQUEST';
export const deviceDataRequest = (deviceConsentId: string) =>
  action(DEVICE_DATA_REQUEST, { deviceConsentId });

export const DEVICE_DATA_SUCCESS = 'DEVICE_DATA_SUCCESS';
export const deviceDataSuccess = (deviceData: string) => action(DEVICE_DATA_SUCCESS, { deviceData });

export const DEVICE_DATA_ERROR = 'DEVICE_DATA_ERROR';
export const deviceDataError = () => action(DEVICE_DATA_ERROR);

export const DEVICE_DATA_END = 'DEVICE_DATA_END';
export const deviceDataEnd = () => action(DEVICE_DATA_END);

export const SUBMIT_LOGIN_USER_WITH_DEVICE_DATA = 'SUBMIT_LOGIN_USER_WITH_DEVICE_DATA';
export const submitLoginUserWithDeviceData = (values: { email: string; password: string }, actions: any) =>
  action(SUBMIT_LOGIN_USER_WITH_DEVICE_DATA, { values, actions });

// Repeat requests actions for when device data is missing
export const REPEAT_AUTH_REQUEST_WITH_DEVICE_DATA = 'REPEAT_AUTH_REQUEST_WITH_DEVICE_DATA';
export const repeatAuthRequest = (username: string, password: string) =>
  action(REPEAT_AUTH_REQUEST_WITH_DEVICE_DATA, { username, password });

export const KYC_DEVICE_MONITORING_INIT = 'KYC_DEVICE_MONITORING_INIT';
export const initKycDeviceMonitoring = (
  values: { username: string; country: string },
  actions: { continue: () => void },
) => action(KYC_DEVICE_MONITORING_INIT, { values, actions });

export const DEVICE_MONITORING_CONSENT_MODEL_REQUEST = 'DEVICE_MONITORING_CONSENT_MODEL_REQUEST';
export const deviceMonitoringConsentModelRequest = (country: string) =>
  action(DEVICE_MONITORING_CONSENT_MODEL_REQUEST, { country });

export const DEVICE_MONITORING_CONSENT_MODEL_SUCCESS = 'DEVICE_MONITORING_CONSENT_MODEL_SUCCESS';
export const deviceMonitoringConsentModelSuccess = (res: any) =>
  action(DEVICE_MONITORING_CONSENT_MODEL_SUCCESS, { res });

export const DEVICE_MONITORING_CONSENT_MODEL_ERROR = 'DEVICE_MONITORING_CONSENT_MODEL_ERROR';
export const deviceMonitoringConsentModelError = (error: any) =>
  action(DEVICE_MONITORING_CONSENT_MODEL_ERROR, { error });

export const DEVICE_MONITORING_CONSENT_STATE_REQUEST = 'DEVICE_MONITORING_CONSENT_STATE_REQUEST';
export const deviceMonitoringConsentStateRequest = () => action(DEVICE_MONITORING_CONSENT_STATE_REQUEST);

export const DEVICE_MONITORING_CONSENT_STATE_SUCCESS = 'DEVICE_MONITORING_CONSENT_STATE_SUCCESS';
export const deviceMonitoringConsentStateSuccess = (res: any) =>
  action(DEVICE_MONITORING_CONSENT_STATE_SUCCESS, { res });

export const DEVICE_MONITORING_CONSENT_STATE_ERROR = 'DEVICE_MONITORING_CONSENT_STATE_ERROR';
export const deviceMonitoringConsentStateError = (error: any) =>
  action(DEVICE_MONITORING_CONSENT_STATE_ERROR, { error });

export const DEVICE_MONITORING_CONSENT_DECLINE_REQUEST = 'DEVICE_MONITORING_CONSENT_DECLINE_REQUEST';

export const deviceMonitoringConsentDeclineRequest = (device: string, consentId: string) =>
  action(DEVICE_MONITORING_CONSENT_DECLINE_REQUEST, { device, consentId });

export const DEVICE_MONITORING_CONSENT_DECLINE_SUCCESS = 'DEVICE_MONITORING_CONSENT_DECLINE_SUCCESS';

export const deviceMonitoringConsentDeclineSuccess = (consentId: string | null) =>
  action(DEVICE_MONITORING_CONSENT_DECLINE_SUCCESS, { consentId });

export const DEVICE_MONITORING_CONSENT_DECLINE_ERROR = 'DEVICE_MONITORING_CONSENT_DECLINE_ERROR';

export const deviceMonitoringConsentDeclineError = (error: any) =>
  action(DEVICE_MONITORING_CONSENT_DECLINE_ERROR, { error });

export const DEVICE_MONITORING_CONSENT_PROMPT = 'DEVICE_MONITORING_CONSENT_PROMPT';
export const deviceMonitoringConsentPrompt = (consentModel: ConsentModelType) =>
  action(DEVICE_MONITORING_CONSENT_PROMPT, { consentModel });

export const DEVICE_MONITORING_CONSENT_CONTINUE = 'DEVICE_MONITORING_CONSENT_CONTINUE';
export const deviceMonitoringConsentContinue = (shouldContinue: boolean, skip?: boolean) =>
  action(DEVICE_MONITORING_CONSENT_CONTINUE, { shouldContinue, skip });

export const ERROR_CHECK_DEVICE_DATA = 'ERROR_CHECK_DEVICE_DATA';
export const errorCheckDeviceData = (error: ApiError) => action(ERROR_CHECK_DEVICE_DATA, { error });
