/* eslint-disable no-nested-ternary, @typescript-eslint/no-unsafe-return */
import { ofType, ActionsObservable, StateObservable, combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Action } from 'redux';
import { emailChangeConfirmRequest, emailConfirmRequest } from 'common/api';
import {
  SEND_CONFIRM_EMAIL_CHANGE_REQUEST,
  SEND_CONFIRM_EMAIL_REQUEST,
  sendEmailChangeConfirmFailure,
  sendEmailChangeConfirmSuccess,
  sendEmailConfirmFailure,
  sendEmailConfirmSuccess,
} from 'store/actions/emailConfirm';
import { handleAjaxError } from 'store/epics/auth';
import { State } from 'store/types/store';

const sendConfirmEmailRequest = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(SEND_CONFIRM_EMAIL_REQUEST),
    switchMap(({ token }) =>
      from(emailConfirmRequest({ token })).pipe(
        map((res) => {
          if (res.isSuccessful) return sendEmailConfirmSuccess(res);
          return sendEmailConfirmFailure(res);
        }),
      ),
    ),
    catchError(
      handleAjaxError(action$, (error) => sendEmailConfirmFailure({ error: error?.response?.data })),
    ),
  );

const sendConfirmEmailChangeRequest = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(SEND_CONFIRM_EMAIL_CHANGE_REQUEST),
    switchMap(({ token, currentEmail }) =>
      from(emailChangeConfirmRequest({ token, currentEmail })).pipe(
        map((res) => {
          if (res.isSuccessful) return sendEmailChangeConfirmSuccess(res);
          return sendEmailChangeConfirmFailure(res);
        }),
      ),
    ),
    catchError(
      handleAjaxError(action$, (error) => sendEmailChangeConfirmFailure({ error: error?.response?.data })),
    ),
  );

export default combineEpics(sendConfirmEmailRequest, sendConfirmEmailChangeRequest);
