/* eslint-disable @typescript-eslint/no-unsafe-return */
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { remotePersistEpic as createRemotePersistEpic } from 'redux-remote-persist';
import { filter, map, take, mergeMap, switchMap } from 'rxjs/operators';
import { remotePersistSelectors, remoteRehydrateSelectors } from 'store/rootReducer';
import { handleAjaxError } from 'store/epics/auth';
import { getCommonHeaders, getBaseUrlForUserServices } from 'common/api';
import { getAccessToken as accessTokenSelector } from 'store/selectors/auth';
import { ajax as rxjsAjax, AjaxResponse } from 'rxjs/ajax';
import { of, Observable } from 'rxjs';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { getItem } from 'services/storage';
import preAuthAjaxRequest from 'common/utils/rxjs-ajax';
import { DEVICE_ID } from 'services/auth';
import { v4 as uuid } from 'uuid';
import { State } from 'store/types/store';

type RemoteStorageAjax = (
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
  dependencies: { ajax: typeof rxjsAjax },
) => Observable<AjaxResponse>;

const remoteStorageFetchAjax =
  ({
    getAccessToken,
    getBaseUrl,
    getClientId,
    getHeaders,
    generateNonce,
  }: {
    getAccessToken: (state: State) => string | undefined | null;
    getBaseUrl: () => string;
    getClientId: () => string;
    getHeaders: () => any;
    generateNonce: () => string;
  }): RemoteStorageAjax =>
  (action$, state$, { ajax }) =>
    state$.pipe(
      map(getAccessToken),
      take(1),
      mergeMap((accessToken) => {
        const authHeaders$ =
          accessToken != null
            ? of({
                url: `${getBaseUrl(accessToken)}/settings`,
                authHeaders: { Authorization: `Bearer ${accessToken}` },
              })
            : preAuthAjaxRequest({
                ajax,
                baseUrl: getBaseUrl(),
                clientId: getClientId(),
                nonce: generateNonce(),
                commonHeaders: getHeaders(),
                timeout: 2000,
              }).pipe(
                map((authHeaders) => ({
                  url: `${getBaseUrl()}/settings/device/${getClientId()}`,
                  authHeaders,
                })),
              );
        return authHeaders$.pipe(
          mergeMap(({ url, authHeaders }) =>
            ajax({
              url,
              headers: { ...getHeaders(), ...authHeaders },
              timeout: 3000,
            }),
          ),
        );
      }),
    );

const remoteStorageUpdateAjax =
  ({
    getAccessToken,
    getBaseUrl,
  }: {
    getAccessToken: (state: any) => string | undefined | null;
    getBaseUrl: () => string;
  }) =>
  (payload: any): RemoteStorageAjax =>
  (action$, state$, { ajax }) =>
    of(state$.value).pipe(
      map(getAccessToken),
      filter((accessToken) => accessToken != null),
      switchMap((accessToken) =>
        ajax({
          url: getBaseUrl(accessToken).concat('/settings'),
          method: 'PUT',
          headers: {
            ...getCommonHeaders(),
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: payload,
        }),
      ),
    );

const remotePersistEpic = createRemotePersistEpic({
  getPersistState: (state) => state.persist,
  remoteStorageFetchAjax: remoteStorageFetchAjax({
    getAccessToken: accessTokenSelector,
    getBaseUrl: getBaseUrlForUserServices,
    getClientId: getItem(DEVICE_ID),
    getHeaders: getCommonHeaders,
    generateNonce: uuid(),
  }),
  remoteStorageUpdateAjax: remoteStorageUpdateAjax({
    getAccessToken: accessTokenSelector,
    getBaseUrl: getBaseUrlForUserServices,
  }),
  handleAjaxError,
  localStorageKey: 'bisonapp',
  persistDebounceTime: 5000,
  persistSelectors: remotePersistSelectors,
  rehydrateSelectors: remoteRehydrateSelectors,
  storage,
});

export default remotePersistEpic;
