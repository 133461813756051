import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';
import { paths } from 'common/urls';
import { linkToHelpCenter } from 'common/utils/mailAndHelpCenter';
import { getStakingBlockedModalStatus } from 'store/slices/staking/selectors';
import { setStakingBlockedModalStatus } from 'store/slices/staking/actions';
import { ButtonWrapper, GeometryBox, Link, Text } from './styles';

type Props = {
  onClose: () => void;
};

const StakingBlockedModal = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpened, type } = useSelector(getStakingBlockedModalStatus);
  const supportLink = linkToHelpCenter();

  const onModalClose = () => {
    dispatch(setStakingBlockedModalStatus({ isOpened: false, type: undefined }));
    if (onClose) onClose();
  };

  if (type === 'Demo') {
    return (
      <ModalTemplate
        defaultOpen={isOpened}
        header={<h2>{t('staking.demoOnlyModal.title')}</h2>}
        onCancel={onModalClose}
        actions={
          <ButtonWrapper>
            <Button
              title={t('staking.demoOnlyModal.buttons.stayInDemo')}
              size="large"
              inverted
              onClick={onModalClose}
            />
            <Button
              title={t('staking.demoOnlyModal.buttons.verify')}
              size="large"
              to={paths.KYC}
              onClick={onModalClose}
            />
          </ButtonWrapper>
        }
      >
        <GeometryBox>
          <Text>{t('staking.demoOnlyModal.text')}</Text>
          <Text>
            <Trans
              i18nKey="staking.demoOnlyModal.support"
              values={{ supportEmail: t('common.support') }}
              components={{
                1: (
                  <Link href={supportLink} target="_blank">
                    {t('common.support')}
                  </Link>
                ),
              }}
            />
          </Text>
        </GeometryBox>
      </ModalTemplate>
    );
  }
  return (
    <ModalTemplate
      defaultOpen={isOpened}
      header={<h2>{t('staking.stakingBlockedModal.title')}</h2>}
      onCancel={onModalClose}
      actions={
        <ButtonWrapper>
          <Button title={t('common.close')} size="large" inverted onClick={onModalClose} />
        </ButtonWrapper>
      }
    >
      <GeometryBox>
        <Text>{t('staking.stakingBlockedModal.text')}</Text>
      </GeometryBox>
    </ModalTemplate>
  );
};

export default StakingBlockedModal;
