import React from 'react';
import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';
import { useTranslation } from 'react-i18next';
import { Img, Title, Text, ImgWrapper, ButtonWrapper } from './styles';

const IntroModal = ({ open, onConfirm }: { open: boolean; onConfirm: () => void }) => {
  const { t } = useTranslation();
  return (
    <ModalTemplate
      persist
      defaultOpen={open}
      actions={
        <ButtonWrapper>
          <Button title={t('kyc.afterKycPhoneSubmission.buttonText')} onClick={onConfirm} size="modal" />
        </ButtonWrapper>
      }
    >
      <ImgWrapper>
        <Img />
      </ImgWrapper>

      <Title>{t('kyc.afterKycPhoneSubmission.title')}</Title>
      <Text>{t('kyc.afterKycPhoneSubmission.text')}</Text>
    </ModalTemplate>
  );
};

export default IntroModal;
