import { LogEventPayload, TrackingConfiguration } from 'types/tracking';

export const DEFAULT_TRACKING_CONFIGURATION: TrackingConfiguration = {
  eventTags: 'app default',
  rules: [{ pattern: '.*', enabled: true }],
  rulesBE: [
    { pattern: '^Kyc_Open_Screen_common.KycProcess_[^_]+$', enabled: true },
    { pattern: '.*', enabled: false },
  ],
};

export const parseTrackingConfiguration = (trackingConfigurationJson: string): TrackingConfiguration => {
  let trackingConfiguration = DEFAULT_TRACKING_CONFIGURATION;
  if (trackingConfigurationJson === '' || !trackingConfigurationJson) {
    return trackingConfiguration;
  }

  try {
    trackingConfiguration = JSON.parse(trackingConfigurationJson);
  } catch {
    console.error('failed to parse tracking config json');
  }
  return trackingConfiguration;
};

function constructEventName(eventPayload: Partial<LogEventPayload>): string {
  const { group, action, object, objectId, screen } = eventPayload;
  return `${group}_${action}_${object}_${objectId}_${screen}`;
}

function eventMatchesRule(eventPayload: Partial<LogEventPayload>, rulePattern: string): boolean {
  try {
    const eventName = constructEventName(eventPayload);
    return new RegExp(rulePattern).test(eventName);
  } catch {
    return false;
  }
}

export const isEventEnabled = (
  eventPayload: Partial<LogEventPayload>,
  trackingConfiguration: TrackingConfiguration,
  useBackendRules = false,
): boolean => {
  const ruleSet = useBackendRules ? trackingConfiguration.rulesBE : trackingConfiguration.rules;
  /* eslint-disable-next-line no-restricted-syntax */
  for (const rule of ruleSet) {
    if (eventMatchesRule(eventPayload, rule.pattern)) {
      return rule.enabled;
    }
  }
  return true;
};

export const isEventTriggersFlushing = (
  eventPayload: Partial<LogEventPayload>,
  trackingConfiguration: TrackingConfiguration,
): boolean => {
  const ruleSet = trackingConfiguration?.flushRules;
  if (!ruleSet) return false;
  /* eslint-disable-next-line no-restricted-syntax */
  for (const rule of ruleSet) {
    if (eventMatchesRule(eventPayload, rule.pattern)) {
      return rule.enabled;
    }
  }
  return false;
};
