import { initialState } from './initialState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { instrumentsAdapter } from 'store/slices/portfolio/adapter';
import { PortfolioSuccessActionType } from 'types/portfolio';
import { PORTFOLIO_SLICE_NAME } from 'common/const/slices';

const portfolioSlice = createSlice({
  name: PORTFOLIO_SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchPortfolioRequest(state) {
      state.loading = true;
    },
    fetchPortfolioSuccess(state, action: PortfolioSuccessActionType) {
      state.lastUpdated = action.payload.receivedAt;
      state.firstLoadComplete = true;
      state.loading = false;
      state.errMsg = '';
      state.currency = action.payload.response.currency;
      state.cash = action.payload.response.cash;
      state.invested = action.payload.response.invested;
      instrumentsAdapter.setAll(state.instruments, action.payload.response.instruments);
    },
    fetchPortfolioFailure(state, action: PayloadAction<{ errMsg: string }>) {
      state.loading = false;
      state.errMsg = action.payload.errMsg;
    },
    invalidatePortfolio() {},
  },
});

export default portfolioSlice;
