import { browserLanguage } from 'common/utils/language';

export default {
  name: 'getFromLocalStorage',

  lookup() {
    return browserLanguage();
  },

  cacheUserLanguage(lng) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage

    // store it
    localStorage.setItem('i18nextLng', lng);
  },
};
