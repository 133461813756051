import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import imageOne from 'assets/img/stakingIntro-1.svg';
import imageTwo from 'assets/img/stakingIntro-2.svg';
import imageThree from 'assets/img/stakingIntro-3.svg';
import ModalTemplate from 'common/components/Modal/index';
import {
  ButtonWrapper,
  ContainerCenter,
  EmphTitle,
  ProgressIndicatorWrapper,
  Text,
  Title,
  ActionWrapper,
  ImageWrapper,
} from 'common/components/Modal/Staking/StakingIntroductionModal/styles';
import { ReactSVG } from 'react-svg';

import Button from 'common/components/Button';
import { ProgressIndicatorWithClickableDotsAndNoDotCover } from 'common/components/ProgressIndicator';

type Props = {
  onClose: () => void;
};

const stepsImages = [imageOne, imageTwo, imageThree];

const StakingIntroductionModal = ({ onClose }: Props): ReactElement => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const goToNextStep = () => setStep((prevStep) => prevStep + 1);

  const isFinalStep = step === 2;

  return (
    <ModalTemplate
      defaultOpen
      actions={
        <ActionWrapper>
          <ProgressIndicatorWrapper>
            <ProgressIndicatorWithClickableDotsAndNoDotCover max={3} step={step + 1} onDotClick={setStep} />
          </ProgressIndicatorWrapper>
          <ButtonWrapper isFinalStep={isFinalStep}>
            {!isFinalStep && (
              <Button
                title={t('staking.stakingFeatureIntroduction.actions.skip')}
                onClick={onClose}
                size="large"
                inverted
              />
            )}
            <Button
              title={
                isFinalStep ? t('staking.stakingFeatureIntroduction.actions.start') : t('common.continue')
              }
              onClick={isFinalStep ? onClose : goToNextStep}
              size="large"
            />
          </ButtonWrapper>
        </ActionWrapper>
      }
      onCancel={onClose}
    >
      <ContainerCenter>
        <ImageWrapper>
          <ReactSVG src={stepsImages[step]} />
        </ImageWrapper>

        <Title>
          <Trans
            i18nKey={`staking.stakingFeatureIntroduction.${step}.title`}
            components={{ 1: <EmphTitle /> }}
          />
        </Title>
        <Text>
          <Trans i18nKey={`staking.stakingFeatureIntroduction.${step}.body`} />
        </Text>
      </ContainerCenter>
    </ModalTemplate>
  );
};

export default StakingIntroductionModal;
