import styled from 'styled-components';
import { Theme } from 'types/theme';

export const BetaSettingsContainer = styled.div`
  width: 100%;
  margin: 100px 0 0 0;
`;

export const BetaSettingsHeader = styled.h2`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.caution};
  text-align: center;
`;

export const BetaSettingsElementsWrapper = styled.div`
  max-width: 400px;
`;
