import priceHistorySlice from '.';

export const {
  // Crypto
  subscribeToCryptoPriceHistory,
  subscribeToCryptoPriceHistoryError,
  subscribeToCryptoPriceHistorySuccess,
  unsubscribeFromCryptoPriceHistory,

  // Security
  subscribeToSecurityPriceHistory,
  subscribeToSecurityPriceHistoryError,
  subscribeToSecurityPriceHistorySuccess,
  unsubscribeFromSecurityPriceHistory,

  // Security chart health
  subscribeToSecurityChartHealthCheck,
  subscribeToSecurityChartHealthCheckError,
  subscribeToSecurityChartHealthCheckSuccess,
  unsubscribeFromSecurityChartHealthCheck,
} = priceHistorySlice.actions;
