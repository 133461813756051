import styled from 'styled-components';
import { Theme } from 'types/theme';
import { devices } from 'common/styles';

export const InputLabel = styled.div.attrs({ className: 'small-text strong-weight' })`
  text-align: center;
  padding-bottom: 12px;

  ${({ theme, isErrorOccurred }: { theme: Theme; isErrorOccurred: boolean }) =>
    isErrorOccurred && `color: ${theme.color.foreground.critical}`}
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  // background: ${({ theme }: { theme: Theme }) => theme.color.background.critical};
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  @media${devices.maxMobileM} {
    gap: 5px;
  }
`;

export const Input = styled.input`
  width: 45px;
  height: 45px;
  outline: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['2'].fontSize};
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['2'].lineHeight};
  font-weight: 700;
  color: ${({
    isErrorOccurred,
    isFilled,
    theme,
  }: {
    isErrorOccurred: boolean;
    isFilled: boolean;
    theme: Theme;
  }): string => {
    if (!isFilled || isErrorOccurred) return theme.color.foreground.primary;

    return theme.color.foreground.primaryInverted;
  }};

  @media${devices.maxMobileM} {
    width: 40px;
    height: 40px;
  }

  &:before {
    content: '2sdfs';
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;

    @media${devices.maxMobileM} {
      width: 40px;
      height: 40px;
    }
  }

  ${({
    isErrorOccurred,
    isFilled,
    theme,
  }: {
    isErrorOccurred: boolean;
    isFilled: boolean;
    theme: Theme;
  }): string => {
    if (isErrorOccurred)
      return `
  border: 1px solid ${theme.color.border.critical};
  background: #FFEDEE !important;
`;

    if (isFilled)
      return `background: ${theme.color.border.inputFocused} !important;
  border: 1px solid ${theme.color.border.inputFocused};`;

    return `background: ${theme.color.background.input} !important;
  border: 1px solid ${theme.color.border.input};`;
  }}
`;

export const ErrorContainer = styled.div.attrs({ className: 'small-text' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.background.critical};
  padding-top: 8px;
  text-align: center;
`;
