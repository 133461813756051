import { action } from './_common';

export const ADD_USER_TO_USERS_WHO_SAW_PHISHING_BANNER = 'ADD_USER_TO_USERS_WHO_SAW_THE_BANNER';
export const ADD_USER_TO_USERS_WHO_SAW_STAKING_INTRO_BANNER =
  'ADD_USER_TO_USERS_WHO_SAW_STAKING_INTRO_BANNER';

export const ADD_USER_TO_USERS_WHO_SAW_STAKING_ROLLOUT_MODAL =
  'ADD_USER_TO_USERS_WHO_SAW_STAKING_ROLLOUT_MODAL';
export const SHOW_PHASED_ROLLOUT_MODAL_AGAIN = 'SHOW_PHASED_ROLLOUT_MODAL_AGAIN';
export const SET_SHOW_PASSWORD = 'SET_SHOW_PASSWORD';
export const SET_AFTER_LOGIN_FLAG = 'SET_AFTER_LOGIN_FLAG';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_IS_STILL_IN_SECURITIES_KYC = 'SET_IS_STILL_IN_SECURITIES_KYC';
export const CHANGE_IS_SECOND_ATTEMPT_MODAL_OPENED = 'CHANGE_IS_SECOND_ATTEMPT_MODAL_OPENED';

export const SET_IS_SOLARIS_ERROR_OCCURRED = 'SET_IS_SOLARIS_ERROR_OCCURRED';

export const SET_WAS_AUDIT_PROOF_MODAL_CLOSED = 'SET_WAS_AUDIT_PROOF_MODAL_CLOSED';

export const setShowPassword = (payload) => action(SET_SHOW_PASSWORD, { payload });
export const setAfterLoginFlag = (payload) => action(SET_AFTER_LOGIN_FLAG, { payload });
export const addUserToUsersWhoSawPhishingBanner = (value) => ({
  type: ADD_USER_TO_USERS_WHO_SAW_PHISHING_BANNER,
  payload: {
    userHash: value,
  },
});
export const addUserToUsersWhoSawStakingIntroBanner = (value) => ({
  type: ADD_USER_TO_USERS_WHO_SAW_STAKING_INTRO_BANNER,
  payload: {
    userHash: value,
  },
});
export const addUserToUsersWhoSawStakingRolloutModal = (value: number) => ({
  type: ADD_USER_TO_USERS_WHO_SAW_STAKING_ROLLOUT_MODAL,
  payload: {
    userHash: value,
  },
});

export const setShowPhasedRolloutModalAgain = (value: boolean) => ({
  type: SHOW_PHASED_ROLLOUT_MODAL_AGAIN,
  payload: {
    showPhasedRolloutModalAgain: value,
  },
});

export const setPageTitle = (payload) => action(SET_PAGE_TITLE, { payload });

export const changeIsSecondAttemptModalOpened = (isSecondAttemptModalOpened) => ({
  type: CHANGE_IS_SECOND_ATTEMPT_MODAL_OPENED,
  payload: { isSecondAttemptModalOpened },
});

export const setIsStillInSecuritiesKyc = (isStillInStocksKyc) => ({
  type: SET_IS_STILL_IN_SECURITIES_KYC,
  payload: {
    isStillInStocksKyc,
  },
});

export const setWasAuditProofModalClosed = () => ({ type: SET_WAS_AUDIT_PROOF_MODAL_CLOSED });

export const setIsSolarisDownErrorOccurred = (isSolarisDownErrorOccurred: boolean) => ({
  type: SET_IS_SOLARIS_ERROR_OCCURRED,
  payload: { isSolarisDownErrorOccurred },
});
