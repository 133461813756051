import { PriceAlertEntity, PriceAlertListItem } from 'types/alerts';
import { fiat } from './formatting';
import { Crypto } from 'types/assets';

export const getPriceAlertListItem = (
  priceAlert: PriceAlertEntity,
  cryptos: Crypto[],
): PriceAlertListItem => {
  const priceFormatting = priceAlert.price ? fiat(priceAlert.price) : '-';

  const asset = cryptos.find((item) => item.code.toLowerCase() === priceAlert.entity.toLowerCase());

  return {
    ...priceAlert,
    priceFormatting,
    assetName: asset?.displayName ?? '',
  };
};
