/* eslint-disable import/prefer-default-export, @typescript-eslint/no-unsafe-return */
import { createSelector } from 'reselect';
import { State } from 'store/types/store';

export const getGlobalNotifications = (state: State) => state.notification.filter((item) => !item.local);

export const getNotifications = (state: State, props: any = {}) => {
  return state.notification
    .filter((item) => {
      return item.containerId !== undefined ? props.containerId === item.containerId : true;
    })
    .filter((item) => (props.type ? item.type === props.type : !item.type));
};
export const makeGetlNotification = () => createSelector(getNotifications, (notifications) => notifications);

export const getNotificationsByType = () =>
  createSelector(getNotifications, (notifications) => notifications);

export const getNotificationMap = createSelector(getNotifications, (notifications) =>
  notifications.reduce((acc, value) => {
    acc[value.toastType] = acc[value.toastType] ? acc[value.toastType] : [];
    acc[value.toastType].push(value);
    return acc;
  }, {}),
);
