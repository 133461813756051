import { createSlice } from '@reduxjs/toolkit';
import { pricesInitialState } from './initialState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PRICES_SLICE_NAME } from 'common/const/slices';
import { PriceData, PriceEntity } from 'store/types/prices';
import { pricesAdapter } from './adapter';
import { ASSET_CLASSES } from 'types/assets';
import { priceDataItemsToPriceEntityItems } from 'common/utils/prices';

const pricesSlice = createSlice({
  name: PRICES_SLICE_NAME,
  initialState: pricesInitialState,
  reducers: {
    // CRYPTO PRICES
    subscribeToCryptoPrices(state, action: PayloadAction<{ codes: string[] }>) {
      state.areCryptoPricesFetching = true;
    },
    subscribeToCryptoPricesSuccess(state, action: PayloadAction<{ prices: PriceData[] }>) {
      const entities: PriceEntity[] = priceDataItemsToPriceEntityItems(
        action.payload.prices,
        ASSET_CLASSES.CRYPTO,
      );

      pricesAdapter.upsertMany(state, entities);
    },
    subscribeToCryptoPricesError(state) {},
    unsubscribeFromCryptoPrices(state) {
      state.areCryptoPricesFetching = false;
    },

    // SECURITY PRICES
    subscribeToSecurityPrices(state, action: PayloadAction<{ codes: string[] }>) {
      state.areSecuritiesPricesFetching = true;
    },
    subscribeToSecurityPricesSuccess(state, action: PayloadAction<{ prices: PriceData[] }>) {
      const entities: PriceEntity[] = priceDataItemsToPriceEntityItems(
        action.payload.prices,
        ASSET_CLASSES.SECURITY,
      );

      pricesAdapter.upsertMany(state, entities);
    },
    subscribeToSecurityPricesError(state) {},
    unsubscribeFromSecurityPrices(state) {
      state.areSecuritiesPricesFetching = false;
    },
  },
});

export default pricesSlice;
