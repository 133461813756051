import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';
import { trackAppEvent } from 'store/actions/tracking';
import { trackDisplayShowNotification, trackDisplayCloseNotification } from '../biEventDefinitions';

export function* logNotificationEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const screenId: string = yield select(getCurrentPath);

  switch (action.eventName) {
    case 'showNotification': {
      yield call(
        trackDisplayShowNotification,
        action.payload.type,
        screenId,
        action.payload.contentId,
        action.payload.message,
      );
      break;
    }
    case 'closeNotification': {
      yield call(
        trackDisplayCloseNotification,
        action.payload.type,
        screenId,
        action.payload.contentId,
        action.payload.message,
        action.payload.closeType,
      );
      break;
    }
    default:
      break;
  }
}
