/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  loadWinnerLosersData,
  loadWinnerLosersDataError,
  loadWinnerLosersDataSuccess,
} from 'store/slices/experiments/winnersLosers/actions';
import { RootEpic } from 'types/common';
import { combineLatest, interval, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { WinnerLoserItem, WinnerLoserType } from 'types/winnersLosers';
import { getAccessToken } from 'store/selectors/auth';
import { isOfType, isPresent } from 'safetypings';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { ASSET_CLASSES, ASSET_TAGS, Asset } from 'types/assets';
import { backoff } from 'common/utils/rxjs-operators';
import { handleAjaxError } from './auth';
import { combineEpics } from 'redux-observable';
import { LOGIN_USER_SUCCESS } from 'store/actions/auth';
import {
  getEtpShortsOnCryptoDetailScreen,
  getWinnersAndLosersTypeFromGroup,
} from 'store/slices/experiments/remoteData/selectors';
import {
  CRYPTO_SHORT_ISINS,
  CRYPTO_SHORT_PRICE_CHANGE_PERIOD,
  CRYPTO_SHORT_USE_HARDCODED_NAMES,
  WINNERS_LOSERS_ASSETS_COUNT,
  WINNERS_LOSERS_PRICE_CHANGE_PERIOD,
  WINNERS_LOSERS_TAG,
  WINNERS_LOSERS_TIME_PERIOD,
} from 'common/const/experiments';
import { State } from 'store/types/store';
import { getIsWinnerLoserModalVisited } from 'store/selectors/settings';
import { isEqualIgnoreCase } from 'common/utils/common';
import {
  loadCryptoShortData,
  loadCryptoShortDataError,
  loadCryptoShortDataSuccess,
  stopLoadingPriceChanges,
} from 'store/slices/experiments/cryptoShort/actions';
import { getAsset } from 'store/slices/assets/selectors';
import { fetchSingleAssetSuccess } from 'store/slices/assets/actions';
import { Observable } from 'redux';
import { getEligiblePriceChangeCodesByAssetClass } from 'store/slices/priceChanges/selectors';
import { PriceChangeData } from 'store/types/priceChanges';
import { subscribeToSecurityPriceChangesSuccess } from 'store/slices/priceChanges/actions';
import { InnerTimePeriodToStockEtfRequestParam } from 'common/const';
import { SECURITY_PRICE_CHANGE_FETCH_INTERVAL_IN_MS } from 'common/const/priceChanges';
import { calculateStockPriceChange } from 'common/utils/assets';

const watchLoginSuccess = (action$, state$) =>
  action$.pipe(
    filter(isOfType(LOGIN_USER_SUCCESS)),
    switchMap(() =>
      state$.pipe(
        take(1),
        map((state: State) => ({
          type: getWinnersAndLosersTypeFromGroup(state),
          modalShown: getIsWinnerLoserModalVisited(state),
        })),
        filter(
          ({ type, modalShown }: { type: WinnerLoserType | undefined; modalShown: boolean }) =>
            !!type && !modalShown,
        ),
        switchMap(({ type }: { type: WinnerLoserType }) => of(loadWinnerLosersData(type))),
      ),
    ),
  );

const fetchAssetsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(loadWinnerLosersData.match),
    map((payload) => ({
      search: '',
      pageSize: WINNERS_LOSERS_ASSETS_COUNT,
      tag: WINNERS_LOSERS_TAG,
      OrderMechanism: payload.payload,
      timePeriod: WINNERS_LOSERS_TIME_PERIOD,
    })),
    switchMap((params) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat('/assets').concat(queryArrayString(params)),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            switchMap(({ assets }: { assets: Asset[] }) =>
              ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat(`/prices/changes/stocks`)
                  .concat(
                    queryArrayString({
                      Period: WINNERS_LOSERS_PRICE_CHANGE_PERIOD,
                      isins: assets.map((asset: Asset) => asset.code),
                    }),
                  ),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response', 'changes'),
                map((priceChanges: PriceChangeData[]) => {
                  return loadWinnerLosersDataSuccess(
                    assets.map((asset: Asset): WinnerLoserItem => {
                      const change = priceChanges.find((priceChange: PriceChangeData) =>
                        isEqualIgnoreCase(priceChange.isin, asset.code),
                      );

                      return {
                        displayName: asset.displayName,
                        code: asset.code,
                        changeInPercentage: calculateStockPriceChange(change?.relative ?? 0),
                      };
                    }),
                  );
                }),
              ),
            ),
          ),
        ),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(2, 1000, (error: any) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, (error) => loadWinnerLosersDataError())),
      ),
    ),
  );

const loadCryptoShortDataForAsset = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(loadCryptoShortData.match),
    map((payload: any) => CRYPTO_SHORT_ISINS[payload?.payload?.code as 'eth' | 'btc']),
    switchMap((code: string) =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);

          if (!accessToken) return undefined;

          const asset = CRYPTO_SHORT_USE_HARDCODED_NAMES
            ? undefined
            : getAsset(state, ASSET_CLASSES.SECURITY, code);

          return { accessToken, asset };
        }),
        filter(isPresent),
        take(1),
        switchMap(({ accessToken, asset }: { accessToken: string; asset: Asset | undefined }) => {
          const priceChangeInterval$ = interval(SECURITY_PRICE_CHANGE_FETCH_INTERVAL_IN_MS).pipe(
            startWith(0),
            takeUntil(action$.pipe(filter(stopLoadingPriceChanges.match))),
            map(() => {
              if (!code) return false;

              const etpShortsOnCryptoDetailScreen = getEtpShortsOnCryptoDetailScreen(state$.value);

              // If the group is not the one who allows performance numbers then we do not need to do an API call.
              if (etpShortsOnCryptoDetailScreen !== 'B') return false;

              const isEligiable = getEligiblePriceChangeCodesByAssetClass(
                state$.value,
                [code],
                ASSET_CLASSES.SECURITY,
                CRYPTO_SHORT_PRICE_CHANGE_PERIOD,
              );

              // If the code is returned that means that we can fetch its price change.
              return isEligiable.length === 1;
            }),
            mergeMap((lastFetchDateValid) => {
              const query = {
                Period: InnerTimePeriodToStockEtfRequestParam[CRYPTO_SHORT_PRICE_CHANGE_PERIOD],
                isins: [code],
              };
              return lastFetchDateValid
                ? ajax({
                    url: api
                      .getBaseUrlForTrading(accessToken)
                      .concat(`/prices/changes/securities`)
                      .concat(queryArrayString(query)),
                    withCredentials: true, // include cookies
                    headers: {
                      ...api.getCommonHeaders(),
                      Authorization: `Bearer ${accessToken}`,
                      'Content-Type': 'application/json',
                    },
                  }).pipe(pluck('response', 'changes'))
                : of(undefined);
            }),
          );

          const assetInfo$ = !asset
            ? (ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat(`/assets`)
                  .concat(queryArrayString({ search: code, tag: ASSET_TAGS.ETF })),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response', 'assets'),
                map((assets: Asset[]) => assets[0]),
              ) as Observable<Asset>)
            : of(asset);

          // Skip the assetInfo request if names can be hardcoded.
          if (CRYPTO_SHORT_USE_HARDCODED_NAMES)
            return priceChangeInterval$.pipe(
              map((priceChange: PriceChangeData | undefined) =>
                loadCryptoShortDataSuccess({ assetInfo: undefined, priceChange }),
              ),
              takeUntil(action$.pipe(filter(stopLoadingPriceChanges.match))),
            );

          return combineLatest(priceChangeInterval$, assetInfo$).pipe(
            map(([priceChange, remoteAsset]: [PriceChangeData | undefined, Asset]) =>
              loadCryptoShortDataSuccess({ assetInfo: remoteAsset, priceChange }),
            ),
            takeUntil(action$.pipe(filter(stopLoadingPriceChanges.match))),
          );
        }),
        backoff(2, 1000, (error: any) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, (error) => loadCryptoShortDataError())),
      ),
    ),
  );

const watchLoadCryptoShortDataSuccessForPriceChange = (action$, state$) =>
  action$.pipe(
    filter(loadCryptoShortDataSuccess.match),
    pluck('payload', 'priceChange'),
    filter((val) => !!val),
    map((priceChange: PriceChangeData[]) =>
      subscribeToSecurityPriceChangesSuccess({
        prices: priceChange,
        timePeriod: CRYPTO_SHORT_PRICE_CHANGE_PERIOD,
      }),
    ),
  );
const watchLoadCryptoShortDataSuccessForAsset = (action$, state$) =>
  action$.pipe(
    filter(loadCryptoShortDataSuccess.match),
    pluck('payload', 'assetInfo'),
    filter((val) => !!val),
    map((asset: Asset) => fetchSingleAssetSuccess(asset)),
  );

export default combineEpics(
  watchLoginSuccess,
  fetchAssetsEpic,
  loadCryptoShortDataForAsset,
  watchLoadCryptoShortDataSuccessForPriceChange,
  watchLoadCryptoShortDataSuccessForAsset,
);
