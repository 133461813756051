/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable spaced-comment */

import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { RootEpic } from 'types/common';
import { interval } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { ASSET_CLASSES, ASSET_TAGS } from 'types/assets';
import { backoff } from 'common/utils/rxjs-operators';
import { getAccessToken } from 'store/selectors/auth';
import { handleAjaxError } from './auth';
import { State } from 'store/types/store';
import { getMarketOverviewAssetTag } from 'store/slices/filters/selectors';
import { setMarketOverviewCategory } from 'store/slices/filters/actions';
import {
  subscribeToCryptoPrices,
  subscribeToCryptoPricesError,
  subscribeToCryptoPricesSuccess,
  subscribeToSecurityPrices,
  subscribeToSecurityPricesError,
  subscribeToSecurityPricesSuccess,
  unsubscribeFromCryptoPrices,
  unsubscribeFromSecurityPrices,
} from 'store/slices/prices/actions';
import { getEligiblePriceCodes } from 'store/slices/prices/selectors';
import {
  CRYPTO_PRICE_FETCH_INTERVAL_IN_MS,
  CRYPTO_PRICES_ENDPOINT,
  SECURITY_PRICE_FETCH_INTERVAL_IN_MS,
  SECURITY_PRICES_ENDPOINT,
} from 'common/const/prices';
import { PriceData } from 'store/types/prices';

const fetchSecurityPrices: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(subscribeToSecurityPrices.type)),
    switchMap((action: any) =>
      interval(SECURITY_PRICE_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              accessToken: getAccessToken(state),
              assetTag: getMarketOverviewAssetTag(state),
              codes: getEligiblePriceCodes(state, action?.payload?.codes ?? [], ASSET_CLASSES.SECURITY),
            })),
            filter(
              ({ assetTag, accessToken, codes }) =>
                assetTag !== ASSET_TAGS.CRYPTO && isPresent(accessToken) && !!codes.length,
            ),
            take(1),
            mergeMap(({ accessToken, codes }) =>
              ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat(SECURITY_PRICES_ENDPOINT)
                  .concat(queryArrayString({ isins: codes })),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response'),
                map((response: PriceData[]) => subscribeToSecurityPricesSuccess({ prices: response })),
              ),
            ),

            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(handleAjaxError(action$, subscribeToSecurityPricesError)),
          ),
        ),
        takeUntil(
          action$.pipe(
            filter(isOfType([unsubscribeFromSecurityPrices.type, setMarketOverviewCategory.type])),
          ),
        ),
      ),
    ),
  );

const fetchCryptoPrices: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(subscribeToCryptoPrices.type)),
    switchMap((action: any) =>
      interval(CRYPTO_PRICE_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              accessToken: getAccessToken(state),
              codes: getEligiblePriceCodes(state, action?.payload?.codes ?? [], ASSET_CLASSES.CRYPTO),
            })),
            filter(({ accessToken, codes }) => isPresent(accessToken) && !!codes.length),
            take(1),
            mergeMap(({ accessToken, codes }) =>
              ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat(CRYPTO_PRICES_ENDPOINT)
                  .concat(queryArrayString({ assetCodes: codes })),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response'),
                map((response: PriceData[]) => subscribeToCryptoPricesSuccess({ prices: response })),
              ),
            ),

            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(handleAjaxError(action$, subscribeToCryptoPricesError)),
          ),
        ),
        takeUntil(
          action$.pipe(
            filter(isOfType([unsubscribeFromSecurityPrices.type, setMarketOverviewCategory.type])),
          ),
        ),
      ),
    ),
  );

export default combineEpics(fetchSecurityPrices, fetchCryptoPrices);
