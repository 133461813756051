import { createSlice } from '@reduxjs/toolkit';
import { TRADING_RULES_SLICE_NAME } from 'common/const/slices';
import { tradingRulesInitialState } from './initialState';
import {
  TradingRuleDeleteAction,
  TradingRulesCreateAction,
  TradingRulesSubscribeSuccessAction,
} from './action-types';
import { tradingRulesAdapter } from './adapter';
import { LimitOrderData, StopOrderData, TradingRuleEntity } from 'types/tradingRules';
import { CHANGE_ACCOUNT } from 'store/actions/auth';

const tradingRulesSlice = createSlice({
  name: TRADING_RULES_SLICE_NAME,
  initialState: tradingRulesInitialState,
  reducers: {
    // GET ALL
    subscribeToTradingRules(state) {
      state.isFetchInProgress = true;
    },
    subscribeToTradingRulesSuccess(state, action: TradingRulesSubscribeSuccessAction) {
      // We want to track only first call. After that the loading is done.
      state.isFetchInProgress = false;

      if (!action.payload.limit.length && !action.payload.stop.length) return;

      const items = [
        ...action.payload.limit.map(
          (limit: LimitOrderData): TradingRuleEntity => ({
            ...limit,
            tradingRuleCategory: 'limit',
            volume: limit.entityVolume,
          }),
        ),
        ...action.payload.stop.map(
          (stop: StopOrderData): TradingRuleEntity => ({ ...stop, tradingRuleCategory: 'stop' }),
        ),
      ];

      tradingRulesAdapter.setAll(state, items);
    },
    subscribeToTradingRulesError(state) {
      // We want to track only first call. After that the loading is done.
      state.isFetchInProgress = false;
    },
    unsubscribeFromTradingRules(state) {
      state.isFetchInProgress = false;
    },

    // DELETE
    deleteTradingRule(state, action: TradingRuleDeleteAction) {
      state.isDeleteInProgress = true;
    },
    deleteTradingRuleSuccess(state, action: TradingRuleDeleteAction) {
      state.isDeleteInProgress = false;

      if (!action.payload.id) return;

      tradingRulesAdapter.removeOne(state, action.payload.id);
    },
    deleteTradingRuleError(state, action: TradingRuleDeleteAction) {
      state.isDeleteInProgress = false;
    },

    // CREATE
    createTradingRule(state, action: TradingRulesCreateAction) {
      state.isCreateInProgress = true;
    },
    createTradingRuleSuccess(state) {
      state.isCreateInProgress = false;
    },
    createTradingRuleError(state) {
      state.isCreateInProgress = false;
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      (action) => action.type === CHANGE_ACCOUNT.REQUEST,
      () => {
        return tradingRulesInitialState;
      },
    ),
});

export default tradingRulesSlice;
