import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { setPageTitle } from 'store/actions/localSettings';
import { State } from 'store/types/store';
import withRouter from 'common/hoc/WithRouter';
import { getAvailableCrypto } from 'store/slices/portfolio/selectors';
import AssetStakingWrapper from 'common/components/AssetStakingWrapper/view';

const mapStateToProps = (state: State) => ({
  availableCryptos: getAvailableCrypto(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setPageTitle: bindActionCreators(setPageTitle, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetStakingWrapper));
