import { ModalContextType } from 'providers/Modal/types';

let modalQueueInstance: ModalContextType | undefined;

export const setModalQueueInstance = (modalQueue: ModalContextType) => {
  modalQueueInstance = modalQueue;
};

export const getModalQueueInstance = () => {
  if (!modalQueueInstance) {
    console.error('Modal queue instance has not been set');
    return undefined;
  }

  return modalQueueInstance;
};
