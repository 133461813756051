import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ModalTemplate from 'common/components/Modal/index';
import {
  AUDIT_PROOF_MODAL_TYPES,
  AuditProofModalType,
  TYPE_TO_TRANSLATION_KEY,
} from 'common/const/auditProof';
import Button from 'common/components/Button';

import { Img, ImageWrapper, ActionsWrapper, Text, Title, WarningText } from './styles';

const AuditProofLoginModal = ({
  open,
  daysLeft,
  handleRejectAudit,
  handleAcceptAudit,
  isAmlOverdue,
  type,
}: {
  open: boolean;
  daysLeft: number;
  handleRejectAudit: () => void;
  handleAcceptAudit: () => void;
  isAmlOverdue: boolean;
  type: AuditProofModalType;
}): JSX.Element => {
  const { t } = useTranslation();

  const title = t(`auditProof.${TYPE_TO_TRANSLATION_KEY[type]}.title`);
  const text = t(`auditProof.${TYPE_TO_TRANSLATION_KEY[type]}.text`);
  const buttonText = t(`auditProof.${TYPE_TO_TRANSLATION_KEY[type]}.button`);

  const isActionsAvailable = type !== AUDIT_PROOF_MODAL_TYPES.MANUAL_PROGRESS;

  return (
    <ModalTemplate
      defaultOpen={open}
      persist={type !== AUDIT_PROOF_MODAL_TYPES.QUESTION}
      onCancel={handleRejectAudit}
      actions={
        isActionsAvailable ? (
          <ActionsWrapper>
            <Button size="large" onClick={handleAcceptAudit} title={buttonText} />
            {!isAmlOverdue && (
              <Button
                size="large"
                onClick={handleRejectAudit}
                inverted
                title={t('auditProof.onLoginModal.hardFlowExtraButton')}
              />
            )}
          </ActionsWrapper>
        ) : (
          <></>
        )
      }
    >
      <ImageWrapper>
        <Img modalType={type} />
      </ImageWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      {!isAmlOverdue && <WarningText>{t('auditProof.onLoginModal.daysLeft', { daysLeft })}</WarningText>}
    </ModalTemplate>
  );
};

export default memo(AuditProofLoginModal);
