import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import DisabledFeatures from 'common/components/Modal/DisabledFeatures';
import type { AccountType } from 'common/const';
import { accountTypes, kycStatuses } from 'common/const';
import * as authActions from 'store/actions/auth';
import { State } from 'store/types/store';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/styles';
import PaperTradingBanner from './view';

type Props = {
  switchingRealMoneyMode: boolean;
  kycStatus: string;
  accountType: AccountType;
  changeAccountType: (newAccountType: AccountType, eventLabel: string) => void;
  mobileMenuOpen: boolean;
};

const PaperTradingBannerContainer = (props: Props) => {
  const { changeAccountType, kycStatus, accountType } = props;
  const [disabledFeature, setDisabled] = React.useState(false);
  const isMobile = useMedia([devices.maxTablet, devices.tablet], [true, false], false);
  const { mobileMenuOpen } = props;
  const isBannerDisabled = mobileMenuOpen && isMobile;

  const handleToggleRealMoneyTrading = (eventLabel: string) => {
    if (kycStatus === kycStatuses.Pending) {
      setDisabled(true);
      return;
    }

    if (kycStatus !== kycStatuses.Confirmed) {
      setDisabled(true);
      return;
    }
    const newAccountType = accountType === accountTypes.real ? accountTypes.paper : accountTypes.real;
    changeAccountType(newAccountType, eventLabel);
  };

  return (
    <>
      {!isBannerDisabled && (
        <PaperTradingBanner {...props} handleAccountToggle={handleToggleRealMoneyTrading} />
      )}

      <DisabledFeatures open={disabledFeature} onClose={() => setDisabled(false)} />
    </>
  );
};

const mapStateToProps = (state: State) => ({
  accountType: state.auth.user.accountType,
  switchingRealMoneyMode: state.auth.changingAccount,
  kycStatus: state.auth.user.kycStatus,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  changeAccountType: (newAccountType: AccountType, eventLabel: string) =>
    dispatch(authActions.changeAccount.request(newAccountType, eventLabel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaperTradingBannerContainer);
