import { paths } from 'common/urls';
import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';
import { trackAppEvent } from 'store/actions/tracking';
import {
  trackPortfolioOpenScreen,
  trackPortfolioModifySelectionPortfolioChartType,
  trackPortfolioInteractChartPortfolioChart,
} from '../biEventDefinitions';

export function* logPortfolioEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const portfolioScreenId = paths.PORTFOLIO;
  const lastScreenId: string = yield select(getCurrentPath);
  switch (action.eventName) {
    case 'portfolioScreenOpen':
      if (lastScreenId.includes(portfolioScreenId)) {
        yield call(
          trackPortfolioOpenScreen,
          portfolioScreenId,
          lastScreenId,
          action.payload.portfolioChartType,
        );
      }
      break;

    case 'modifyPortfolioChartTypePress':
      yield call(
        trackPortfolioModifySelectionPortfolioChartType,
        portfolioScreenId,
        action.payload.portfolioChartType,
      );
      break;

    case 'portfolioChartInteractionTime':
      yield call(
        trackPortfolioInteractChartPortfolioChart,
        portfolioScreenId,
        action.payload.portfolioChartType,
        action.payload.interactionTimeMsec,
      );
      break;

    default:
      break;
  }
}
