/* eslint-disable no-case-declarations, @typescript-eslint/no-unsafe-return */
import * as ActionTypes from 'store/actions/forms';
import { INVALIDATE_TOP_UP_DATA, SET_TOP_UP_AMOUNT, SET_TOP_UP_PAYMENT_METHOD } from 'store/actions/forms';
import {
  CONFIRM_RESET_PASSWORD_FAILURE,
  CONFIRM_RESET_PASSWORD_REQUEST,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_SUCCESS,
  SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE,
  SEND_RESET_PASSWORD_SECURITY_CODE_REQUEST,
  SEND_RESET_PASSWORD_SECURITY_CODE_SUCCESS,
  SKIP_RESET_PASSWORD_SECURITY_CODE,
} from 'store/actions/resetPassword';
import {
  findNationalityCategory,
  ID_OR_PASS_BILL,
  NEWSLETTER,
  NEWSLETTERBIRTHCOUNTRY,
  supportedCountries,
} from 'common/const';
import * as formatting from '../../common/utils/formatting';
import { notSupportedBirthCountries } from '../../common/const';
import { AnyAction } from 'redux';
import { KycFormData, TaxResidency } from 'types/forms';
import { errorCodes } from 'common/apiErrors';
import { passwordResetSteps } from 'types/resetPassword';
import { FormsState } from 'store/types/forms';

export const initialKycFormData: KycFormData = {
  agb: false,
  datenschutz: false,
  nichtInUSA: false,
  ownAccount: false,
  newsletter: false,
  cryptoRisk: false,
  precontractualMail: false,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  country: 'DE',
  nationality: 'DE',
  taxId: '', // Optional
  taxIdCountry: '', // Optional
  taxResidency: '',
  germanBankAcc: false,
  // If using passport + utility bill
  billType: '',
  billDate: '',
  billStreet: '',
  billStreetNumber: '',
  billPostNumber: '', // PLZ
  birthCountry: '',
  birthCity: '',
  billCity: '',
  billCountry: 'DE',

  employmentStatus: '',
  professionalField: '',
  incomeSource: '',
  netIncome: 0,
  netWealth: '',
  investmentTarget: '',
  cryptoDeposit: false,
  cryptoDepositSource: '',

  // Fixed setting for this client
  kycMethod: undefined,
  birthProvince: '',
  maritalStatus: 'None',
  reference: '',
  salutation: 'None',
  sandboxFirstName: '',
  state: '',
  workCountry: '',
  workProvince: '',
};

export const initialRenewKycFormData = {
  billCity: '',
  billCountry: 'DE',
  billDate: '',
  billPostNumber: '',
  billStreet: '',
  billStreetNumber: '',
  billType: '',
};

export const initialState: FormsState = {
  sendingFormData: false,
  validKycInfo: false,
  kycMeta: {
    currentStep: 1,
    nSteps: undefined,
    verificationCategory: ID_OR_PASS_BILL,
    transactionToken: '',
    updatedForm: true,
    isKycStarted: false, // used to decide if we need to log the event KYC_STARTED
    verificationDocument: undefined,
  },
  kycFormData: initialKycFormData,
  stocksKycMeta: {
    currentStep: 0,
    currentStepName: '',
    pendingFormSubmit: false,
    isFailed: false,
  },
  stocksKycFormData: {
    hasGermanTaxID: true,
    hasTradedSecurities: null,
    hasTradedCommonIndicesOrComparableEtfs: null,
    hasTradedNonCommonIndicesOrLeveragedEtfs: null,
    taxId: '',
    taxIdCountry: 'DE',
    salutation: '',
    maritalStatus: '',
    employmentStatus: '',
    professionalField: '',
    sutorBankTnC: false,
    sutorBankPrivacyPolicy: false,
    basicKnowledge: false,
    noChargebacks: false,
    euwaxContracts: false,
    eIdUsed: false,
  },
  renewKycFormData: initialRenewKycFormData,
  acceptedTermsAndConditionsForCountryInKycFormSubmit: undefined,
  stocksKnowledgeData: {
    Answer1: undefined,
    Answer2: undefined,
    Answer3: undefined,
    Answer4: undefined,
    Answer5: undefined,
    Answer6: undefined,
    Answer7: undefined,
    Answer8: undefined,
    Answer9: undefined,
  },
  sendingKycPending: false,
  isInitialTaxFetched: false,
  taxResidency: [],
  passwordReset: {
    email: '',
    status: undefined, // 'success'|'error'|undefined
    userDetails: {},
    step: passwordResetSteps.INIT_PASSWORD_RESET,
    loading: false,
  },
  topUpData: {
    amount: '',
    paymentMethod: {
      paymentMethodId: '',
      cardBrand: '',
      cardNumberLast4: '',
    },
  },
};

const setEmptyIfNull = (value: string) => {
  if (value === null) {
    return '';
  }
  return value;
};

function groupKycData(stateObj: FormsState, data: any) {
  if (data.employmentStatus) {
    return {
      ...stateObj.kycFormData,
      ...data,
      netIncome: data.netIncome ? formatting.numberFromDecimalString(`${data.netIncome}`) : data.netIncome,
    };
  }

  return data;
}

// Updates kyc meta depending on kyc form data
function updateMeta(data: any) {
  if (data.nationality) {
    const category = supportedCountries.includes(data.country)
      ? findNationalityCategory(data.nationality)
      : NEWSLETTER;
    return {
      verificationCategory: category,
    };
  }
  if (data.birthCountry) {
    if (notSupportedBirthCountries.includes(data.birthCountry)) {
      return {
        verificationCategory: NEWSLETTERBIRTHCOUNTRY,
      };
    } else {
      return {
        verificationCategory: ID_OR_PASS_BILL,
      };
    }
  }
  return {};
}

function taxResidencyCompare(tr1: TaxResidency, tr2: TaxResidency): number {
  if (tr1.id < tr2.id) {
    return -1;
  }
  if (tr1.id > tr2.id) {
    return 1;
  }
  return 0;
}

function root(state = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.SAVE_STOCKS_RISK_KNOWLEDGE_DATA:
      return {
        ...state,
        stocksKnowledgeData: {
          ...state.stocksKnowledgeData,
          [action.payload.question]: action.payload.answer,
        },
      };
    case ActionTypes.CHANGE_SECURITIES_KNOWLEDGE.SUCCESS:
      return {
        ...state,
        stocksKnowledgeData: {
          Answer1: undefined,
          Answer2: undefined,
          Answer3: undefined,
          Answer4: undefined,
          Answer5: undefined,
          Answer6: undefined,
          Answer7: undefined,
          Answer8: undefined,
          Answer9: undefined,
        },
      };
    case ActionTypes.SAVE_SECURITIES_KYC_DATA:
      return {
        ...state,
        stocksKycFormData: {
          ...state.stocksKycFormData,
          ...action.payload,
        },
      };
    case ActionTypes.RESET_DEAD_END_FIELD:
      const { stocksKycFormData } = initialState;

      return {
        ...state,
        stocksKycFormData: {
          ...state.stocksKycFormData,
          taxIdCountry: stocksKycFormData.taxIdCountry,
          residence: stocksKycFormData.residence,
          citizenship: stocksKycFormData.citizenship,
          mobileNumber: stocksKycFormData.mobileNumber,
          showPhoneDeadEnd: stocksKycFormData.showPhoneDeadEnd,
          hasGermanTaxID: stocksKycFormData.hasGermanTaxID,
        },
      };
    case ActionTypes.SECURITIES_KYC_FORM_SUBMIT.REQUEST:
      return {
        ...state,
        stocksKycFormData: {
          ...state.stocksKycFormData,
          ...action.data,
        },
        stocksKycMeta: {
          ...state.stocksKycMeta,
        },
      };
    case ActionTypes.SECURITIES_KYC_FORM_SUBMIT.SUCCESS:
      return {
        ...state,
        stocksKycMeta: {
          ...state.stocksKycMeta,
          pendingFormSubmit: false,
          isFailed: false,
        },
      };
    case ActionTypes.SECURITIES_KYC_FORM_SUBMIT.FAILURE:
      return {
        ...state,
        stocksKycMeta: {
          ...state.stocksKycMeta,
          pendingFormSubmit: false,
          isFailed: true,
        },
      };
    case ActionTypes.INVALIDATE_SECURITIES_KYC_FAILED:
      return {
        ...state,
        stocksKycMeta: {
          ...state.stocksKycMeta,
          isFailed: false,
        },
      };

    case ActionTypes.UPDATE_KYC_DATA:
      return {
        ...state,
        kycMeta: {
          ...state.kycMeta,
          updatedForm: true,
          ...updateMeta(action.data),
        },
        kycFormData: {
          ...state.kycFormData,
          ...groupKycData(state, action.data),
        },
      };
    case ActionTypes.NEXT_KYC_STEP:
      return {
        ...state,
        kycMeta: {
          ...state.kycMeta,
          currentStep:
            state.kycMeta.currentStep <= action.nSteps - 1
              ? state.kycMeta.currentStep + 1
              : state.kycMeta.currentStep,
        },
      };
    case ActionTypes.PREV_KYC_STEP:
      return {
        ...state,
        kycMeta: {
          ...state.kycMeta,
          currentStep:
            state.kycMeta.currentStep >= 1 ? state.kycMeta.currentStep - 1 : state.kycMeta.currentStep,
        },
      };
    case ActionTypes.KYC_NUMBER_OF_STEPS:
      return {
        ...state,
        kycMeta: {
          ...state.kycMeta,
          nSteps: action.nSteps,
        },
      };
    case ActionTypes.KYC_FORM_SUBMIT.REQUEST:
      return {
        ...state,
        sendingFormData: true,
        acceptedTermsAndConditionsForCountryInKycFormSubmit:
          action.formData.acceptedTermsAndConditionsForCountry,
      };
    case ActionTypes.KYC_FORM_SUBMIT.SUCCESS:
      return {
        ...state,
        sendingFormData: false,
        validKycInfo: true,
        kycMeta: {
          ...state.kycMeta,
          updatedForm: false,
          transactionToken: action.response.transactionToken,
        },
      };
    case ActionTypes.KYC_FORM_SUBMIT.FAILURE:
      return {
        ...state,
        sendingFormData: false,
        validKycInfo: false,
      };
    case ActionTypes.KYC_DATA.SUCCESS:
      return {
        ...state,
        kycFormData: {
          ...state.kycFormData,
          ...action.response,
        },
        validKycInfo: action.response.reference ? true : state.validKycInfo,
        kycMeta: {
          ...state.kycMeta,
          transactionToken: action.response.reference,
        },
      };
    case ActionTypes.PUT_NEW_ADDRESS_DATA:
      return {
        ...state,
        kycFormData: {
          ...state.kycFormData,
          country: action.address.country,
          billStreet: action.address.line1,
          billStreetNumber: action.address.line2,
          billPostNumber: action.address.postalCode,
          billCity: action.address.city,
        },
      };

    case ActionTypes.GET_TAX_RESIDENCY.REQUEST:
      return {
        ...state,
        refreshingTaxResidency: true,
      };
    case ActionTypes.GET_TAX_RESIDENCY.SUCCESS:
      return {
        ...state,
        refreshingTaxResidency: false,
        isInitialTaxFetched: true,
        taxResidency: action.response.sort(taxResidencyCompare),
      };
    case ActionTypes.GET_TAX_RESIDENCY.FAILURE:
      return {
        ...state,
        refreshingTaxResidency: false,
      };
    case ActionTypes.SET_KYC_STARTED:
      return {
        ...state,
        kycMeta: {
          ...state.kycMeta,
          isKycStarted: true,
        },
      };
    case ActionTypes.KYC_VIEWED_STEP:
      return {
        ...state,
        kycMeta: {
          ...state.kycMeta,
          currentStepName: action.stepName,
        },
      };
    case ActionTypes.RESET_CHECKBOXES_KYC:
      return {
        ...state,
        kycMeta: {
          ...initialState.kycMeta,
        },
        kycFormData: {
          ...state.kycFormData,
          agb: false,
          datenschutz: false,
          nichtInUSA: false,
          ownAccount: false,
          cryptoRisk: false,
        },
      };
    case ActionTypes.SET_VERIFICATION_DOCUMENT:
      return {
        ...state,
        kycMeta: {
          ...state.kycMeta,
          verificationDocument: action.document,
        },
      };
    case ActionTypes.INVALIDATE_TRANSACTION_TOKEN:
      return {
        ...state,
        validKycInfo: false,
      };

    case ActionTypes.SECURITY_ADDITIONAL_INFO.SUCCESS:
      return {
        ...state,
        stocksKycFormData: {
          ...state.stocksKycFormData,
          ...action.response,
        },
      };

    case ActionTypes.STORE_RENEW_KYC_FORM_DATA:
      return {
        ...state,
        renewKycFormData: {
          ...state.renewKycFormData,
          ...action.payload,
        },
      };
    case ActionTypes.SUBMIT_RESET_PASSWORD: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          email: action.values.email,
        },
      };
    }
    case ActionTypes.SUBMIT_RESET_PASSWORD_STATUS: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          email: action.values.email === undefined ? state.passwordReset.email : action.values.email,
          status: action.values.status,
        },
      };
    }
    case FETCH_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          userDetails: action.userDetails,
          step: passwordResetSteps.SECURITY_CODE,
          loading: false,
        },
      };
    }
    case SKIP_RESET_PASSWORD_SECURITY_CODE:
    case SEND_RESET_PASSWORD_SECURITY_CODE_SUCCESS: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          userDetails: action.userDetails ?? state.passwordReset.userDetails,
          step: passwordResetSteps.NEW_PASSWORD_FORM,
          loading: false,
        },
      };
    }
    case FETCH_USER_DETAILS_FAILURE:
    case SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE:
    case CONFIRM_RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          email: '',
          status: undefined, // 'success'|'error'|undefined
          userDetails: action?.error?.code ? state.passwordReset.userDetails : {},
          step:
            action?.error?.code === errorCodes.GENERAL_DEVICEDATA_REQUIRED
              ? state.passwordReset.step
              : passwordResetSteps.PASSWORD_RESET_ERROR,
          loading: false,
        },
      };
    }
    case CONFIRM_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          status: undefined, // 'success'|'error'|undefined
          userDetails: {},
          step: passwordResetSteps.PASSWORD_RESET_SUCCESS,
          loading: false,
        },
      };
    }
    case SEND_RESET_PASSWORD_SECURITY_CODE_REQUEST:
    case CONFIRM_RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          loading: true,
        },
      };
    }
    case SET_TOP_UP_AMOUNT:
      return {
        ...state,
        topUpData: {
          ...state.topUpData,
          amount: action.payload,
        },
      };
    case SET_TOP_UP_PAYMENT_METHOD:
      return {
        ...state,
        topUpData: {
          ...state.topUpData,
          paymentMethod: action.payload,
        },
      };
    case INVALIDATE_TOP_UP_DATA:
      return {
        ...state,
        topUpData: {
          ...initialState.topUpData,
        },
      };
    default:
      return state;
  }
}

export default root;
