import React, { ReactElement } from 'react';
import { Field, FieldProps } from 'formik';

import Checkbox from 'common/components/Checkbox';

import { useTracking } from 'common/tracking/hooks';
import { Container, CheckboxLine, Error } from './styles';

type CheckboxInputProps = {
  name: string;
  children: React.ReactElement | React.ReactElement[];
  disableMargins?: boolean;
  testId?: string;
  dark?: boolean;
  hideError?: boolean;
  onClick?: (newValue: boolean) => void;
};

type CheckboxInputWithoutFormikProps = {
  value: boolean;
  onClick: () => void;
  children: React.ReactElement;
  disableMargins?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  dark?: boolean;
  error?: boolean;
};

const CheckboxInput = ({
  name,
  children,
  disableMargins = false,
  testId = '',
  dark,
  onClick,
  hideError,
}: CheckboxInputProps): ReactElement => {
  const trackAppEvent = useTracking();
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<boolean>) => (
        <Container dark={dark}>
          <CheckboxLine
            $disableMargins={disableMargins}
            data-test-id={testId}
            onClick={() => {
              if (testId) {
                trackAppEvent('toggleComponentPress', {
                  componentId: testId,
                  componentType: 'Checkbox',
                  newValue: !field.value,
                });
              }
              void form.setFieldTouched(name, true);
              void form.setFieldValue(name, !field.value);

              if (onClick) onClick(!field.value);
            }}
          >
            <Checkbox value={field.value} />
            {children}
          </CheckboxLine>
          {!hideError && <Error name={name} />}
        </Container>
      )}
    </Field>
  );
};

CheckboxInput.defaultProps = {
  disableMargins: false,
  testId: '',
  dark: false,
  hideError: false,
  onClick: () => null,
};

export const CheckboxInputWithoutFormik = ({
  value,
  onClick,
  children,
  disableMargins = false,
  textAlign = 'center',
  dark = false,
  error = undefined,
}: CheckboxInputWithoutFormikProps): ReactElement => (
  <Container $dark={dark}>
    <CheckboxLine $disableMargins={disableMargins} onClick={onClick} $textAlign={textAlign}>
      <Checkbox value={value} hasErrors={error} />
      {children}
    </CheckboxLine>
  </Container>
);

CheckboxInputWithoutFormik.defaultProps = {
  disableMargins: false,
  textAlign: 'center',
  dark: false,
  error: undefined,
};

export default CheckboxInput;
