import taxExemptionSlice from '.';

export const {
  fetchTaxExemptionsRequest,
  fetchTaxExemptionsSuccess,
  fetchTaxExemptionsFailure,
  submitTaxExemptionRequest,
  submitTaxExemptionSuccess,
  submitTaxExemptionFailure,
  verifyTaxExemptionRequest,
  verifyTaxExemptionSuccess,
  verifyTaxExemptionFailure,
} = taxExemptionSlice.actions;
