import styled from 'styled-components';
import { Theme } from 'types/theme';
import { devices } from 'common/styles';

export const Headline = styled.h2`
  width: 95%; // to prevent text with icon close overlay
`;

export const PhoneText = styled.div.attrs({ className: 'small-text' })`
  padding: 32px 0 0;
`;

export const InputWrapper = styled.div.attrs({ className: '' })`
  padding: 40px 16px 0;

  @media${devices.maxMobileM} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SupportText = styled.div.attrs({ className: 'small-text secondary-color-text' })`
  padding-top: 32px;
  padding-bottom: 50px;
`;

export const RepeatSendingText = styled.div.attrs({ className: 'small-text' })`
  text-align: center;
`;

export const SupportLink = styled.a`
  text-decoration: none;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
`;

export const SendAgainText = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
  text-decoration: underline;
  cursor: pointer;
`;
