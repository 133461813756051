// SECURITIES

/**
 * This is how long a specific security's price history will not be re-fetched.
 * Instead the stored/cached price history will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const SECURITY_PRICE_HISTORY_DATA_TTL = 1000 * 60 * 3;

/**
 * This is how often the re-fetching of securities is re-calculated.
 * If the calculation states that a specific security is ready to be re-fetched its new price history data will be retrieved from the server.
 *
 * The value is specified in milliseconds.
 */
export const SECURITY_PRICE_HISTORY_FETCH_INTERVAL_IN_MS = 1000 * 30;

/**
 * This is how often the re-fetching of security chart health check is done.
 *
 * The value is specified in milliseconds.
 */
export const SECURITY_PRICE_HISTORY_HEALTH_CHECK_INTERVAL_IN_MS = 1000 * 60;

/**
 * An endpoint from where security price history health check is retrieved.
 */
export const SECURITY_PRICE_HISTORY_HEALTH_CHECK_ENDPOINT = '/stocks/prices/health';

/**
 * An endpoint from where price history for securities are retrieved.
 */
export const SECURITY_PRICE_HISTORY_ENDPOINT = '/prices/stockChart';

// CRYPTO

/**
 * This is how long a specific crypto's price history will not be re-fetched.
 * Instead the stored/cached price history will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_PRICE_HISTORY_DATA_TTL = 1000 * 60 * 3;

/**
 * This is how often the re-fetching of cryptos is re-calculated.
 * If the calculation states that a specific crypto is ready to be re-fetched its new price history data will be retrieved from the server.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_PRICE_HISTORY_FETCH_INTERVAL_IN_MS = 1000 * 30;
/**
 * Means long a specific crypto's small price history will not be re-fetched.
 * Instead the stored/cached price change will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_SMALL_CHART_DATA_TTL = 1000 * 60 * 3;
/**
 * This is how often the re-fetching of cryptos is re-calculated.
 * If the calculation states that a specific crypto is ready to be re-fetched its new small chart data will be retrieved from the server.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_SMALL_CHART_FETCH_INTERVAL_IN_MS = 1000 * 30;
