import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { trackAppEvent } from 'store/actions/tracking';
import { AppTrackingEvent } from 'store/sagas/tracking/handlers/types';

export function useTracking(): typeof trackAppEvent {
  const dispatch = useDispatch();

  return useCallback(
    (eventName: AppTrackingEvent, payload: any) => dispatch(trackAppEvent(eventName, payload)),
    [dispatch],
  );
}
