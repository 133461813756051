import type { AxiosError } from 'axios';
import I18n from 'i18next';

export const stockTradingErrors = Object.freeze({
  GENERAL_ERR_ACTIONNOTALLOWED: 'general_err_actionnotallowed',
  STOCKORDER_ERR_USERCANTTRADESTOCK: 'stockorder_err_usercanttradestock',
  STOCKS_GENERAL_ERR: 'stocks_general_err',
  STOCKORDERQUOTE_ERR_NOTENOUGHASSET: 'stockorderquote_err_notenoughasset',
  ORDERRESERVESUBMIT_ERR_TOKENINVALID: 'orderreservesubmit_err_tokeninvalid',
});

export const errorCodes = Object.freeze({
  // auth (common)
  AUTH_ERR_TOKENEXPIRED: 'auth_err_tokenexpired', // Authentication token expired
  AUTH_ERR_TOKENINVALID: 'auth_err_tokeninvalid', // Authentication token not valid
  GENERAL_ERR_TOOMANYTRIES: 'general_err_toomanytries',
  // login
  LOGIN_ERR_LOGINFAILED: 'login_err_loginfailed', // No such user or login data incorrect
  LOGIN_ERR_NOTCONFIRM: 'login_err_notconfirm', // User has not confirmed email
  LOGIN_ERR_WRONGPARAMS: 'login_err_wrongparams', // Login function called with wrong parameters
  LOGIN_ERR_NOPERMISSION: 'login_err_nopermission', // User does not have permission to login
  LOGIN_ERR_NOKYCROLE: 'login_err_nokycrole', // Forbidden to login to real account without kyc role
  LOGIN_ERR_ANONYMOUSNOTSUPPORTED: 'login_err_anonymousnotsupported', // Anonymous login not supported
  LOGIN_ERR_ANONYMOUSCONDITIONFAIL: 'login_err_anonymousconditionfail' as const, // Anonymous users may only login to anonymous account and vice-versa
  LOGIN_ERR_NOSUCHACCOUNT: 'login_err_nosuchaccount', // User does not have the account of the requested type
  LOGIN_ERR_TOOMANYATTEMPTS: 'login_err_toomanyattempts',
  LOGIN_ERR_DEVICE_2FA: 'login_err_notconfirm2fa',
  LOGIN_ERR_2FAWRONGCODE: 'login_err_2faloginfailed',
  LOGIN_ERR_DEVICE_2FA_SMSCHALLANGEFAIL: 'smschallenge_err_2fainitfailed',
  LOGIN_2FAPROCESS_ERR_INVALIDTOKEN: 'login2faprocess_err_invalidtoken',
  GENERAL_DEVICEDATA_REQUIRED: 'general_err_devicedatarequired',
  // signup
  SIGNUP_ERR_USEREXISTS: 'signup_err_userexists', // User already exists
  SIGNUP_ERR_PWDINVALID: 'signup_err_pwdinvalid', // Password does not match minimal complexity requirements
  SIGNUP_ERR_CANNOTSENDEMAIL: 'signup_err_cannotsendemail', // We couldn't send an email to this address
  SIGNUP_ERR_WRONGPARAMS: 'signup_err_wrongparams', // Signup function called with wrong parameters
  // 2fa change processes
  CODE2FA_ERR_INCORRECT: 'code2fa_err_incorrect',
  CHANGEPROCESS_ERR_WRONG2FACODE: 'changeprocess_err_wrong2facode',
  TAXRESIDENCY_ERR_TAXIDINVALID: 'taxresidency_err_taxidinvalid',
  TAXRESIDENCY_ERR_COUNTRYALREADYEXISTS: 'taxresidency_err_countryalreadyexists',
  // change password
  PWDCHANGE_ERR_WRONGBODY: 'pwdchange_err_wrongbody', // PwdChange function called with wrong body parameters
  PWDCHANGE_ERR_USERMISMATCH: 'pwdchange_err_usermismatch', // Mismatch between token authenticated user and the provided username
  PWDCHANGE_ERR_OLDPWDWRONG: 'pwdchange_err_oldpwdwrong', // The provided old/current password is not correct
  PWDCHANGE_ERR_NEWPWDINVALID: 'pwdchange_err_newpwdinvalid', // New password does not match minimal complexity requirements
  // reset pass
  PWDRESET_ERR_NOUSER: 'pwdreset_err_nouser', // User not found
  PWDRESET_ERR_NOTCONFIRM: 'pwdreset_err_notconfirm', // User has not confirmed email
  // Crypto withdraw
  CRYPTOWITHDRAWINIT_ERR_PASSWORDWRONG: 'cryptowithdrawinit_err_passwordwrong',
  CRYPTOWITHDRAWSUBMIT_ERR_INVALIDTOKEN: 'cryptowithdrawsubmit_err_invalidtoken',
  CRYPTOWITHDRAWINIT_ERR_WALLETADDRESSINVALID: 'cryptowithdrawinit_err_walletaddressinvalid',
  // crypto deposit
  CRYPTODEPOSIT_ERR_NOTSUPPORTEDCRYPTO: 'cryptodeposit_err_notsupportedcrypto',
  // Withdraw
  WITHDRAWSUBMIT_ERR_BANKACCOUNTTOKEN: 'withdrawsubmit_err_bankaccounttoken',
  // KYC
  KYCSTATUSTRANSITION_ERR_NOTALLOWED: 'kycstatustransition_err_notallowed',
  KYCFORMSUBMIT_ERR_COUNTRYNOTSUPPORTED: 'kycformsubmit_err_countrynotsupported',
  KYCFORMSUBMIT_ERR_INITERROR: 'kycformsubmit_err_initerror',
  // coupon (redeem code)
  CLAIMCOUPON_ERR_EXPIRED: 'claimcoupon_err_expired',
  CLAIMCOUPON_ERR_NOTYETSTARTED: 'claimcoupon_err_notyetstarted',
  CLAIMCOUPON_ERR_NOTVALID: 'claimcoupon_err_notvalid',
  CLAIMCOUPON_ERR_RECLAIMEDEVENT: 'claimcoupon_err_reclaimedevent',
  CLAIMCOUPON_ERR_KYCBEFORECODEACTIVE: 'claimcoupon_err_kycbeforecodeactive',
  CLAIMCOUPON_ERR_CLAIMCOUNTLIMITREACHED: 'claimcoupon_err_claimcountlimitreached',
  CLAIMCOUPON_ERR_NOTTARGETUSER: 'claimcoupon_err_nottargetuser',
  CLAIMCOUPON_ERR_REGISTERBEFORECODEACTIVE: 'claimcoupon_err_registerbeforecodeactive',
  CLAIMCOUPON_ERR_KYCMORETHAN48HAGO: 'claimcoupon_err_kycmorethan48hago',
  CLAIMCOUPON_ERR_KYCALREADYDONE: 'claimcoupon_err_kycalreadydone',
  // alerts
  SAME_ALERT_ALREADY_EXISTS: 'same_alert_already_exists',
  // change processes
  CHANGEPROCESS_ERR_CONFIRMATIONNOTREQUIRED: 'changeprocess_err_confirmationnotrequired',
  SOLARIS_DOWN_ERR: 'general_err_serviceunavailable',
} as const);

export type StocksTradingErrorCode = (typeof stockTradingErrors)[keyof typeof stockTradingErrors];

export type ApiErrorCode = (typeof errorCodes)[keyof typeof errorCodes] | StocksTradingErrorCode;

// Api Error Messages
export const errorMessages: Record<string, string> = {
  // auth (common)
  auth_err_tokenexpired: I18n.t('login.errorGeneric'),
  auth_err_tokeninvalid: I18n.t('login.errorGeneric'),
  general_err_toomanytries: I18n.t('error.tooManyAttempts'),
  // login
  login_err_code_2fa: 'login.error2faInvalidCode',
  login_err_loginfailed: I18n.t('login.errorIncorrectCredentials'),
  login_err_notconfirm: I18n.t('login.errorUnconfirmedEmail'),
  login_err_wrongparams: I18n.t('login.errorGeneric'),
  login_err_nopermission: I18n.t('login.errorGeneric'),
  login_err_nokycrole: I18n.t('login.errorNoKyc'),
  login_err_anonymousnotsupported: 'login.errorGeneric',
  login_err_anonymousconditionfail: 'login.errorGeneric',
  login_err_nosuchaccount: I18n.t('login.errorGeneric'),
  login2faprocess_err_invalidtoken: I18n.t('pairing.twofaInvalid'),
  // signup
  signup_err_userexists: I18n.t('fieldErrors.emailTaken'),
  signup_err_pwdinvalid: I18n.t('fieldErrors.passwordInvalid'),
  signup_err_cannotsendemail: I18n.t('registration.errorCannotSendEmail'),
  signup_err_wrongparams: I18n.t('registration.errorGeneric'),
  // 2fa change processes
  code2fa_err_incorrect: I18n.t('settings.personalInfo.errors.incorrectCode2fa'),
  changeprocess_err_wrong2facode: I18n.t('settings.personalInfo.errors.incorrectCode2fa'),
  taxresidency_err_taxidinvalid: I18n.t('settings.personalInfo.errors.invalidTaxId'),
  taxresidency_err_countryalreadyexists: I18n.t('settings.personalInfo.errors.taxIdCountryExists'),
  // change password
  pwdchange_err_wrongbody: I18n.t('settings.changePass.generic'), // PwdChange function called with wrong body parameters
  pwdchange_err_usermismatch: I18n.t('settings.changePass.userMismatch'), // Mismatch between token authenticated user and the provided username
  pwdchange_err_oldpwdwrong: I18n.t('settings.changePass.oldPwdWrong'), // The provided old/current password is not correct
  pwdchange_err_newpwdinvalid: I18n.t('settings.changePass.newPwdInvalid'), // New password does not match minimal complexity requirements
  // reset pass
  pwdreset_err_nouser: I18n.t('forgotPassword.errors.noUser'),
  pwdreset_err_notconfirm: I18n.t('forgotPassword.errors.notConfirmed'),
  // crypto withdraw
  cryptowithdrawinit_err_passwordwrong: I18n.t('cryptoWithdraw.errors.wrongPassword'),
  cryptowithdrawsubmit_err_invalidtoken: I18n.t('cryptoWithdraw.errors.invalidTokenOrTooLong'),
  cryptowithdrawinit_err_walletaddressinvalid: I18n.t('cryptoWithdraw.errors.walletAddressInvalid'),
  // crypto deposit
  cryptodeposit_err_notsupportedcrypto: I18n.t('cryptoDeposit.messages.notSupportedError'), // We don't actually display anything, we just "handle" it
  // withdraw
  withdrawsubmit_err_bankaccounttoken: I18n.t('withdraw.errors.invalidTokenOrTooLong'),
  // kyc
  kycstatustransition_err_notallowed: I18n.t('kyc.forms.errors.alreadyConfirmed'),
  kycformsubmit_err_countrynotsupported: I18n.t('kyc.forms.errors.unsupportedCountry'),
  kycformsubmit_err_initerror: I18n.t('kyc.forms.errors.initError'),
  // coupon (redeem code)
  claimcoupon_err_expired: I18n.t('redeemCode.error.expired'),
  claimcoupon_err_notyetstarted: I18n.t('redeemCode.error.notYetStarted'),
  claimcoupon_err_notvalid: I18n.t('redeemCode.error.invalid'),
  claimcoupon_err_reclaimedevent: I18n.t('redeemCode.error.reclaimedEvent'),
  claimcoupon_err_kycbeforecodeactive: I18n.t('redeemCode.error.kycbeforecodeactive'),
  claimcoupon_err_claimcountlimitreached: I18n.t('redeemCode.error.claimcountlimitreached'),
  claimcoupon_err_nottargetuser: I18n.t('redeemCode.error.nottargetuser'),
  claimcoupon_err_registerbeforecodeactive: I18n.t('redeemCode.error.registerbeforecodeactive'),
  claimcoupon_err_kycmorethan48hago: I18n.t('redeemCode.error.kycmorethan48hago'),
  claimcoupon_err_kycalreadydone: I18n.t('redeemCode.error.kycalreadydone'),
  general_err_update_required: I18n.t('termsAndConditions.updateBanner.subTitle'),
  // alerts
  same_alert_already_exists: I18n.t('alerts.banners.alreadyExists'),
  // change processes
  changeprocess_err_confirmationnotrequired: I18n.t('settings.personalInfo.errors.changeIbanNotRequired'),
};

export interface StocksTradingError {
  status?: number;
  response: {
    data: {
      code: StocksTradingErrorCode;
    };
  };
}

export interface ApiError {
  status?: number;
  code?: ApiErrorCode;
  message: string;
  detail?: string | Record<string, string>;
}

export interface ApiErrorAxios {
  code?: ApiErrorCode;
  message?: string;
  detail?: string;
}

/**
 * Constructs an error object.
 * @returns {Object} The created error (status: 'http status', code: 'error code', message: 'description').
 */
export const makeError = (error: AxiosError<ApiErrorAxios>, defaultMessage?: string): ApiError => {
  const { status, data } = error.response ?? {}; // server error response
  const code = data?.code;
  const serverMessage = data?.message;
  const detail = data?.detail as any;
  const message: string =
    (code && errorMessages[code]) || defaultMessage || serverMessage || I18n.t('error.genericNetwork');
  return { status, code, message, detail };
};

export const codesSilentFail = Object.freeze({
  [errorCodes.LOGIN_ERR_DEVICE_2FA]: errorCodes.LOGIN_ERR_DEVICE_2FA,
  [errorCodes.LOGIN_ERR_NOTCONFIRM]: errorCodes.LOGIN_ERR_NOTCONFIRM,
  [errorCodes.GENERAL_DEVICEDATA_REQUIRED]: errorCodes.GENERAL_DEVICEDATA_REQUIRED,
  [errorCodes.GENERAL_ERR_TOOMANYTRIES]: errorCodes.GENERAL_ERR_TOOMANYTRIES,
});
