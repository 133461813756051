import styled from 'styled-components';
import { dimensions } from 'common/styles';
import { Theme } from 'types/theme';

export const SwitchContainer = styled.div.attrs((props) => ({
  className: props.disabled ? 'disabled' : '',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${dimensions.switchWidth}px;
  height: ${dimensions.switchHeight}px;
  border-radius: ${dimensions.btnBorderRadius}px;
  border: 1px solid
    ${({ active, theme }: { theme: Theme; active: boolean }) =>
      active ? theme.color.background.switcher : 'currentColor'};
  background-color: ${({ active, theme }: { theme: Theme; active: boolean }) =>
    active ? theme.color.background.switcher : 'transparent'};
  padding: 0 ${dimensions.smallPadding}px;
  cursor: pointer;
`;

export const SwitchText = styled.span.attrs({ className: 'small-text' })`
  line-height: 100%;
  color: ${({ active, theme }: { theme: Theme; active: boolean }) =>
    active ? theme.color.foreground.primaryInverted : 'inherit'};
`;
