import { AnyAction } from 'redux';
import { StockRaffleState } from 'store/types/stockRaffle';
import {
  STOCK_RAFFLE_FETCH_TRANACTION_COUNT_ERROR,
  STOCK_RAFFLE_FETCH_TRANACTION_COUNT_REQUEST,
  STOCK_RAFFLE_FETCH_TRANACTION_COUNT_SUCCESS,
} from 'store/actions/stockRaffle';

const initialState: StockRaffleState = {
  transactionCount: 0,
  isFetching: false,
};

function stockRaffleReducer(state: StockRaffleState = initialState, action: AnyAction): StockRaffleState {
  switch (action.type) {
    case STOCK_RAFFLE_FETCH_TRANACTION_COUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case STOCK_RAFFLE_FETCH_TRANACTION_COUNT_SUCCESS:
      return {
        ...state,
        transactionCount: action?.payload?.transactionCount ?? initialState.transactionCount,
        isFetching: false,
      };
    case STOCK_RAFFLE_FETCH_TRANACTION_COUNT_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default: {
      return state;
    }
  }
}

export default stockRaffleReducer;
