import { getUsersWhoSawPhishingBanner } from 'store/selectors/localSettings';
import { useSelector } from 'react-redux';
import { getIsKycDone, getUser } from 'store/selectors/auth';
import { stringHashCode } from 'common/utils/hash';
import { useMemo } from 'react';

export const usePhishingModalDisplay = () => {
  const usersWhoSawPhishingBanner = useSelector(getUsersWhoSawPhishingBanner);
  const userName = useSelector(getUser).username;
  const userHash = stringHashCode(userName ?? '');
  const didUserSawBanner = usersWhoSawPhishingBanner.includes(userHash);
  const isKycDone = useSelector(getIsKycDone);

  // didUserSawBanner is not used as dependency to prevent Phishing modal triggering after username change
  return useMemo(() => !didUserSawBanner && isKycDone, [isKycDone]);
};
