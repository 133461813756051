/* eslint-disable no-nested-ternary, @typescript-eslint/no-unsafe-return */
import { ofType, ActionsObservable, StateObservable, combineEpics } from 'redux-observable';
import { concat, of, from } from 'rxjs';
import { handleAjaxError } from 'store/epics/auth';
import { map, catchError, switchMap, filter, take, takeUntil } from 'rxjs/operators';
import { Action } from 'redux';
import { isOfType } from 'safetypings';
import I18n from 'i18next';
import { errorCodes } from 'common/apiErrors';
import {
  CONFIRM_RESET_PASSWORD_FAILURE,
  CONFIRM_RESET_PASSWORD_REQUEST,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_FAILURE,
  PASSWORD_RESET_FLOW_FINISH,
  SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE,
  SEND_RESET_PASSWORD_SECURITY_CODE_REQUEST,
  SEND_RESET_PASSWORD_SECURITY_CODE_SUCCESS,
  SKIP_RESET_PASSWORD_SECURITY_CODE,
  confirmResetPasswordFailure,
  confirmResetPasswordSuccess,
  fetchUserDetailsFailure,
  fetchUserDetailsSuccess,
  passwordResetFlowFinish,
  sendResetPasswordSecurityCodeFailure,
  sendResetPasswordSecurityCodeRequest,
  sendResetPasswordSecurityCodeSuccess,
  skipPasswordResetSecurityCode,
} from 'store/actions/resetPassword';
import { confirmPasswordReset, getUserDetails, sendPasswordResetSecurityCode } from 'common/api';
import {
  DEVICE_DATA_END,
  DEVICE_DATA_SUCCESS,
  DEVICE_MONITORING_CONSENT_CONTINUE,
  deviceMonitoringPreinitCheck,
} from 'store/actions/deviceMonitoring';
import { State } from 'store/types/store';
import { errorCheckDeviceData } from 'store/actions/deviceMonitoring';
import { notifyError } from 'store/actions/notificationBanner';

const maskedUserDetails = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(FETCH_USER_DETAILS),
    switchMap(({ token }) =>
      from(getUserDetails(token)).pipe(
        map((userDetails) => {
          if (userDetails.isSuccessful) {
            if (userDetails.isSecurityCodeRequired) return fetchUserDetailsSuccess(userDetails);
            return skipPasswordResetSecurityCode(token, userDetails);
          }
          return fetchUserDetailsFailure();
        }),
        catchError(handleAjaxError(action$, fetchUserDetailsFailure)),
      ),
    ),
  );

const sendResetPasswordSecurityCode = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(SEND_RESET_PASSWORD_SECURITY_CODE_REQUEST),
    switchMap(({ token, email }) =>
      concat(
        of(deviceMonitoringPreinitCheck(email)),
        action$.pipe(
          filter(isOfType([DEVICE_DATA_SUCCESS, DEVICE_DATA_END])),
          take(1),
          switchMap(({ deviceData }: { deviceData: string }) =>
            from(sendPasswordResetSecurityCode({ token, ...(deviceData ? { deviceData } : {}) })).pipe(
              map((res) => {
                if (res.isSuccessful) return sendResetPasswordSecurityCodeSuccess(res, token, deviceData);
                return sendResetPasswordSecurityCodeFailure({ token, email });
              }),
            ),
          ),
          catchError(
            handleAjaxError(action$, (error) =>
              sendResetPasswordSecurityCodeFailure({ error: error?.response?.data, token, email }),
            ),
          ),
        ),
      ),
    ),
  );

const retrykResetPasswordSecurityCode = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE),
    switchMap(({ error, token, email }) =>
      concat(
        of(errorCheckDeviceData(error)),
        action$.pipe(
          ofType(DEVICE_MONITORING_CONSENT_CONTINUE),
          switchMap(({ shouldContinue, skip }: { shouldContinue: boolean; skip: boolean }) => {
            if (!shouldContinue || skip) return [];
            return of(sendResetPasswordSecurityCodeRequest({ token, email }));
          }),
        ),
      ),
    ),
  );

const confirmPasswordResetEpic = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    filter(isOfType([SEND_RESET_PASSWORD_SECURITY_CODE_SUCCESS, SKIP_RESET_PASSWORD_SECURITY_CODE])),
    switchMap(
      ({
        token,
        confirmationId,
        deviceData,
      }: {
        token: string;
        confirmationId?: string;
        deviceData?: string;
      }) =>
        action$.pipe(
          ofType(CONFIRM_RESET_PASSWORD_REQUEST),
          switchMap(
            ({ form }: { form: { newPassword: string; newPasswordConfirm: string; code?: string } }) =>
              from(
                confirmPasswordReset({
                  token,
                  ...(confirmationId ? { confirmationId, deviceData } : {}),
                  ...form,
                }),
              ).pipe(
                map((res) => {
                  if (res.isSuccessful) return confirmResetPasswordSuccess();
                  return confirmResetPasswordFailure();
                }),
                catchError(handleAjaxError(action$, confirmResetPasswordFailure)),
              ),
          ),
        ),
    ),
  );

const passwordResetErrorNotification = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    filter(
      isOfType([
        FETCH_USER_DETAILS_FAILURE,
        SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE,
        CONFIRM_RESET_PASSWORD_FAILURE,
      ]),
    ),
    takeUntil(action$.ofType(PASSWORD_RESET_FLOW_FINISH)),
    switchMap(({ error }: { error: any }) => {
      if (error?.code !== errorCodes.GENERAL_DEVICEDATA_REQUIRED) {
        return concat(
          of(passwordResetFlowFinish()),
          of(notifyError({ message: I18n.t('forgotPassword.errors.generic') })),
        );
      }
      return [];
    }),
  );

export default combineEpics(
  maskedUserDetails,
  sendResetPasswordSecurityCode,
  confirmPasswordResetEpic,
  retrykResetPasswordSecurityCode,
  passwordResetErrorNotification,
);
