/* eslint-disable no-useless-return */
import { put, take } from 'redux-saga/effects';
import {
  deviceMonitoringConsentPrompt,
  DEVICE_MONITORING_CONSENT_CONTINUE,
} from 'store/actions/deviceMonitoring';
import { ApiError, errorCodes } from 'common/apiErrors';
import { ConsentModelType } from 'types/consent';

export function* checkDeviceConsentNeeded(error: ApiError) {
  if (error && error.code === errorCodes.GENERAL_DEVICEDATA_REQUIRED) {
    // if device monitoring consent is missing ask user for consent

    yield put(
      deviceMonitoringConsentPrompt(
        typeof error.detail === 'object' ? (error.detail.consentModel as ConsentModelType) : 'Explicit',
      ),
    );
    // wait for device monitoring consent to be gathered from the user
    const { shouldContinue } = yield take(DEVICE_MONITORING_CONSENT_CONTINUE);
    if (!shouldContinue) {
      // Do not continue the flow and break out of the loop
      return { shouldContinue };
    }
    // throw error to retry initial request after device consent modal
    throw new Error('DeviceData missing. Consent gethered.');
  }
  return { skip: true };
}
