import { InboxCategory, InboxUnreadCount, MessageWithDocument } from 'types/inbox';
import { messagesAdapter, messagesWithDocumentsAdapter } from './adapters';

export const getEntitiesInitialState = () => ({
  messages: {
    items: messagesAdapter.getInitialState(),
    isLoading: false,
    lastUpdated: null as number | null,
    areAllLoaded: false,
  },
  messagesWithDocuments: {
    items: messagesWithDocumentsAdapter.getInitialState(),
    isLoading: false,
    lastUpdated: null as number | null,
    fullyLoaded: false,
    totalCount: null as number | null,
  },
});
export type InboxState = ReturnType<typeof getEntitiesInitialState> & {
  numOfUnread: InboxUnreadCount;
  countLastUpdated: number | null;
  errMsg?: string;
  readMessageWithDocumentsLoading: boolean;
  singleMessageWithDocument: MessageWithDocument | null;
  isOnInboxPage: boolean;
};
export const initialState: InboxState = {
  ...getEntitiesInitialState(),
  numOfUnread: { postboxCount: 0, inboxCount: 0, isNotificationRequired: false },
  singleMessageWithDocument: null,
  readMessageWithDocumentsLoading: false,
  countLastUpdated: null,
  errMsg: undefined,
  isOnInboxPage: false,
};
