import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSelectedCryptoCurrency } from 'store/selectors/currency';
import * as formActions from 'store/actions/forms';
import { State } from 'store/types/store';

import AppWrapper from './view';
import withRouter from 'common/hoc/WithRouter';
import { WithRouterType } from 'types/withRouter';

const mapStateToProps = (state: State, ownProps: WithRouterType) => ({
  autoLogin: state.auth.autoLogin,
  loggedIn: state.auth.loggedIn,
  authInitialized: state.auth.initialized,
  authInitError: state.auth.authInitError,
  // get the selected crypto from the url or fallback to last selected
  selectedCrypto: getSelectedCryptoCurrency(state, ownProps.params),
});

const mapDispatchToProps = (dispatch: any) => ({
  openVerification: bindActionCreators(formActions.openVerification, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWrapper));
