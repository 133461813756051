/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { TrackingConfigState } from 'store/types/trackingConfig';

// in-app default values (which will be merged with values from remote storage)
const defaultValues: TrackingConfigState = {
  BATCH_SIZE: 1000,
  MAX_BATCH_SECONDS: 60,
  TRACKING_CONFIGURATION: '',
};

// reducer for read-only state → just passes through state
function root(state: TrackingConfigState = defaultValues, action: any) {
  return state;
}

export default root;
