import React, { memo } from 'react';
import { Dot, DotCover, DotsWrapper } from './styles';

interface Props {
  max: number;
  step: number;
}

const ProgressIndicator = ({ max, step }: Props) => {
  const dots = [];

  for (let index = 0; index < max; index += 1) {
    dots.push(<Dot active={index < step} key={`dot-${index}`} />);
  }

  return (
    <DotsWrapper>
      {dots}
      <DotCover span={step - 1} />
    </DotsWrapper>
  );
};

export const ProgressIndicatorWithClickableDotsAndNoDotCover = ({
  max,
  step,
  onDotClick,
}: Props & { onDotClick: (index) => void }) => {
  const dots = [];

  for (let index = 0; index < max; index += 1) {
    dots.push(<Dot active={index < step} key={`dot-${index}`} onClick={() => onDotClick(index)} />);
  }

  return <DotsWrapper>{dots}</DotsWrapper>;
};

export default memo(ProgressIndicator);
