import styled from 'styled-components';
import { Theme } from 'types/theme';
import { Modal } from 'common/lib/semantic-ui';
import _Icon from 'common/components/Icon';
import { dimensions } from 'common/styles';
import emptyProfile from 'assets/img/empty_profile.svg';
import devices from 'common/styles/devices';

export const Icon = styled(_Icon)`
  cursor: pointer;
`;

export const EmptyProfileImg = styled.img.attrs({
  src: emptyProfile,
})`
  width: 272px;

  @media ${devices.maxTablet} {
    width: 100%;
    height: auto;
  }
`;

export const ModalWrapper = styled(Modal)`
  max-width: ${dimensions.widePopup}px;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.primary} !important;

  .actions {
    background: none !important;
    border: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0 !important;
  }

  @media ${devices.maxTablet} {
    &.ui.modal {
      margin: 0 !important;
      position: static;
      width: 100%;
      min-height: 100%;
    }
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  @media ${devices.tablet} {
    margin-bottom: 35px;
  }
`;

export const HeaderText = styled.div.attrs({ className: 'xlarge-text' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
  font-weight: 400;
`;

export const HeadlineText = styled.h2.attrs({ className: 'strong-weight' })``;

export const SubText = styled.div.attrs({ className: 'standard-text' })`
  margin: 24px 0px 16px 0px;
`;

export const Separator = styled.div`
  padding: 50px 0;
`;

export const BulletPoint = styled.div.attrs({ className: 'standard-text' })`
  margin-top: 8px;
  display: flex;

  .icon-checkIcon {
    padding: 6px 0;
  }
`;

export const BulletPointText = styled.div`
  margin-left: 14px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-direction: column-reverse;
  @media ${devices.tablet} {
    flex-direction: row;
    padding: 50px 28px 0px 28px;
  }
  @media ${devices.laptop} {
    padding: 50px 28px 0px 28px;
  }
  @media ${devices.maxMobileS} {
    padding: ${dimensions.smallIndent}px;
  }
`;
export const MarkdownWrapper = styled.div`
  margin: 24px 0px 16px 0px;
  & > p {
    margin-top: 0;
  }
`;
