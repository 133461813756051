import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { newsAdapter } from './adapter';
import { State } from 'store/types/store';
import { NewsState } from 'store/types/news';

export const newsAdapterSelectors = newsAdapter.getSelectors();

export const getNewsState = (state: State): NewsState => state.news;

export const getIsFetchingNewsFeed = (state: State): boolean => state.news.isFetching;

export const getLangOfNews = createSelector(getNewsState, (news: NewsState) => news.lang);

export const getNewsFeed = (state: State) => newsAdapterSelectors.selectAll(state.news);

export const getNewsFeedIsLoading = createSelector(
  getNewsState,
  (news: NewsState) => news.isFetching && newsAdapterSelectors.selectTotal(news) === 0,
);
