import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { generatePath, Navigate, Params, useLocation, useParams } from 'react-router-dom';
import { getSelectedCryptoCurrency } from 'store/selectors/currency';
import { setAfterLoginFlag, setPageTitle } from 'store/actions/localSettings';
import { kycStatuses } from 'common/const';
import { paths, stripTrailingSlash } from 'common/urls';
import { getUser } from 'store/selectors/auth';
import { useAllowedComponent } from 'common/hooks/useAllowedComponent';
import { State } from 'store/types/store';
import usePrivatePageInitialPopups from 'common/hooks/usePrivatePageInitialPopups';
import { usePublicPageInitialPopups } from 'common/hooks/usePublicPageInitialPopups';
import { User } from 'types/auth';
import { $Values } from 'utility-types';
import { ROUTE_MANAGEMENT_ROLES } from 'common/const/user';
import DefaultLayout from '../DefaultLayout';
import ClosedFolderRedirect from './ClosedFolderRedirect/ClosedFolderRedirect';

type PrivatePageProps = {
  component: React.ReactElement;
  loggedIn: boolean;
  user: User;
  layout?: React.ReactElement;
  title?: string;
  selectedCrypto: string;
  showPriceAlertButton?: boolean;
  requiredRoles: $Values<typeof ROUTE_MANAGEMENT_ROLES>[];
  pageHeadlinei18nKey: string;
  loggingIn: boolean;
  pageHeadlineType?: 'h1' | 'h2' | 'h3';
  customHeadlineComponent?: React.ReactElement;
};

const mapStateToProps = (state: State, ownProps: Readonly<Params<string>>) => ({
  loggedIn: state.auth.loggedIn,
  user: getUser(state),
  selectedCrypto: getSelectedCryptoCurrency(state, ownProps),
});

export const PrivatePage = connect(mapStateToProps)(({
  component,
  loggedIn,
  title,
  user,
  layout: RenderLayout,
  selectedCrypto,
  loggingIn,
  pageHeadlinei18nKey,
  pageHeadlineType,
  customHeadlineComponent,
  requiredRoles = [],
  showPriceAlertButton,
  ...rest
}: PrivatePageProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  usePrivatePageInitialPopups();

  const params = useParams();
  // for the pages that have the same parent title
  // so we need to update it manually

  const isKYCDone = user?.kycStatus === kycStatuses.Confirmed;

  React.useEffect(() => {
    document.title = title;
    dispatch(setPageTitle(title));
  }, [title]);

  React.useEffect(() => {
    if (pathname === paths.PORTFOLIO) {
      // After the login page, the "isFirstRendering" flag does not work
      // And if the page that redirects to after a successful login has navigation actions in navigation.js
      // You need to add an additional flag to run actions on the first page load
      dispatch(setAfterLoginFlag(true));
    }
  }, [pathname]);

  const { redirect, AllowedComponent } = useAllowedComponent({
    component,
    requiredRoles,
    selectedCrypto,
    path: pathname,
  });

  if (pathname.endsWith('/') && pathname !== '/') return <ClosedFolderRedirect pathname={pathname} />;

  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  if ('currency' in params) {
    const existingPath = generatePath(pathname, params);
    if (existingPath !== stripTrailingSlash(pathname)) return <Navigate to={existingPath} replace />;
  }

  return (
    <RenderLayout
      title={title}
      {...{ ...rest, isKYCDone, selectedCrypto, showPriceAlertButton }}
      loggedIn={loggedIn}
      user={user}
      privatePage
      pageHeadlinei18nKey={pageHeadlinei18nKey}
      pageHeadlineType={pageHeadlineType}
      customHeadlineComponent={customHeadlineComponent}
    >
      <AllowedComponent user={user} />
    </RenderLayout>
  );
});

PrivatePage.defaultProps = {
  title: '',
  layout: DefaultLayout,
};

type PublicPageProps = {
  component: React.ReactElement;
  title?: string;
  loggedIn: boolean;
  layout?: RenderLayout;
  allowBetaAccess: boolean;
};

export const PublicPage = ({
  component: RenderComponent,
  loggedIn,
  layout: RenderLayout,
  title,
  ...rest
}: PublicPageProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  usePublicPageInitialPopups();

  React.useEffect(() => {
    document.title = title;
    dispatch(setPageTitle(title));
  }, [title]);

  if (pathname.endsWith('/') && pathname !== '/') return <ClosedFolderRedirect pathname={pathname} />;

  return (
    <RenderLayout title={title} {...{ ...rest }}>
      {loggedIn ? <Navigate to={paths.FIRST_PAGE_AFTER_LOGIN} replace /> : <RenderComponent />}
    </RenderLayout>
  );
};

PublicPage.defaultProps = {
  title: '',
  layout: DefaultLayout,
};
