import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './_common';

export const WITHDRAW_ORDER = createRequestTypes('WITHDRAW_ORDER');
export const withdrawOrder = {
  request: ({ currency, volume, account }: { currency: Fiat; volume: number; account: Account }) =>
    action(WITHDRAW_ORDER[REQUEST], { currency, volume, account }),
  success: (_: any, response: any) =>
    action(WITHDRAW_ORDER[SUCCESS], { orderDone: response.orderDone, accountState: response.accountState }),
  failure: (_: any, error: any) => action(WITHDRAW_ORDER[FAILURE], { error }),
};

export const WITHDRAW_INFO = createRequestTypes('WITHDRAW_INFO');
export const withdrawInfo = {
  request: () => action(WITHDRAW_INFO[REQUEST]),
  success: (_: any, response: any) => action(WITHDRAW_INFO[SUCCESS], { accounts: response.accounts }),
  failure: (_: any, error: any) => action(WITHDRAW_INFO[FAILURE], { error }),
};

export const DEPOSIT_INFO = createRequestTypes('DEPOSIT_INFO');
export const depositInfo = {
  request: () => action(DEPOSIT_INFO[REQUEST]),
  success: (_: any, response: any) => action(DEPOSIT_INFO[SUCCESS], { depositAccount: response }),
  failure: (_: any, error: any) => action(DEPOSIT_INFO[FAILURE], { error }),
};

export const CHECK_WITHDRAW_ORDER = 'CHECK_WITHDRAW_ORDER';
export const checkWithdrawOrder = () => action(CHECK_WITHDRAW_ORDER);

export const SUBMIT_WITHDRAW_ORDER = 'SUBMIT_WITHDRAW_ORDER';
export const submitWithdrawOrder = (volume: number) => action(SUBMIT_WITHDRAW_ORDER, { volume });

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const clearErrors = () => action(CLEAR_ERRORS);

export const UPDATE_WITHDRAW_INFO = 'UPDATE_WITHDRAW_INFO';
export const updateWithdrawInfo = () => action(UPDATE_WITHDRAW_INFO);
export const UPDATE_DEPOSIT_INFO = 'UPDATE_DEPOSIT_INFO';
export const updateDepositInfo = () => action(UPDATE_DEPOSIT_INFO);

// Crypto withdrawals
export const INIT_CRPYO_WITHDRAW_ORDER = createRequestTypes('INIT_CRPYO_WITHDRAW_ORDER');
export const initCryptoWithdrawOrder = {
  request: ({
    currency,
    volume,
    walletAddress,
    walletAddressTag,
    password,
  }: {
    currency: string;
    volume: number;
    walletAddress: string;
    walletAddressTag: string | null;
    password: string;
  }) =>
    action(INIT_CRPYO_WITHDRAW_ORDER[REQUEST], {
      currency,
      volume,
      walletAddress,
      walletAddressTag,
      password,
    }),
  success: (_: any, response: any) =>
    action(INIT_CRPYO_WITHDRAW_ORDER[SUCCESS], {
      cryptoWithdrawOrder: response,
    }),
  failure: (_: any, error: any) => action(INIT_CRPYO_WITHDRAW_ORDER[FAILURE], { error }),
};

export const SUBMIT_CRPYO_WITHDRAW_ORDER = createRequestTypes('SUBMIT_CRPYO_WITHDRAW_ORDER');
export const submitCryptoWithdrawOrder = {
  request: ({ order, code2fa }: { order: CryptoWithdrawOrder; code2fa: string }) =>
    action(SUBMIT_CRPYO_WITHDRAW_ORDER[REQUEST], { order, code2fa }),
  success: (_: any, response: any) =>
    action(SUBMIT_CRPYO_WITHDRAW_ORDER[SUCCESS], {
      orderDone: response.orderDone,
      accountState: response.accountState,
    }),
  failure: (_: any, error: any) => action(SUBMIT_CRPYO_WITHDRAW_ORDER[FAILURE], { error }),
};

export const START_CRYPTO_WITHDRAW_SESSION = 'START_CRYPTO_WITHDRAW_SESSION';
export const startCryptoWithdrawSession = () => action(START_CRYPTO_WITHDRAW_SESSION);

export const END_CRYPTO_WITHDRAW_SESSION = 'END_CRYPTO_WITHDRAW_SESSION';
export const endCryptoWithdrawSession = () => action(END_CRYPTO_WITHDRAW_SESSION);

export const AFTER_CRYPTO_ENTRY = 'AFTER_CRYPTO_ENTRY';
export const afterCryptoEntry = () => action(AFTER_CRYPTO_ENTRY);

export const INIT_CRYPTO_WITHDRAW = 'INIT_CRYPTO_WITHDRAW';
export const initCryptoWithdraw = (
  currency: string,
  volume: number,
  walletAddress: string,
  walletAddressTag: string | null,
  password: string,
  onInitSuccess: () => void,
) =>
  action(INIT_CRYPTO_WITHDRAW, {
    currency,
    volume,
    walletAddress,
    walletAddressTag,
    password,
    onInitSuccess,
  });

export const RESEND_CRYPTO_WITHDRAW_CODE = 'RESEND_CRYPTO_WITHDRAW_CODE';
export const resendCryptoWithdrawCode = () => action(RESEND_CRYPTO_WITHDRAW_CODE);

export const SUBMIT_CRYPTO_WITHDRAW = 'SUBMIT_CRYPTO_WITHDRAW';
export const submitCryptoWithdraw = (code2fa: string, actionsWithdraw, navigate?: () => void) =>
  action(SUBMIT_CRYPTO_WITHDRAW, { code2fa, actionsWithdraw, navigate });

export const RESEND_CRYPTO_WITHDRAW_CONFIRMATION_EMAIL = 'RESEND_CRYPTO_WITHDRAW_CONFIRMATION_EMAIL';
export const resendCryptoWithdrawConfirmationEmail = () => action(RESEND_CRYPTO_WITHDRAW_CONFIRMATION_EMAIL);

export const CLEANUP_CRYPTO_WITHDRAW_PROCCESS_DATA = 'CLEANUP_CRYPTO_WITHDRAW_PROCCESS_DATA';
export const cleanupCryptoWithdrawProcessData = () => action(CLEANUP_CRYPTO_WITHDRAW_PROCCESS_DATA);

//
// Info report-related actions
//

export const SEND_INFO_REPORT = createRequestTypes('SEND_INFO_REPORT');
export const sendInfoReport = {
  request: ({ year }: { year: int }) => action(SEND_INFO_REPORT[REQUEST], { year }),
  success: ({ year }: { year: int }) => action(SEND_INFO_REPORT[SUCCESS], { year }),
  failure: (_: any, error: any) => action(SEND_INFO_REPORT[FAILURE], { error }),
};

export const USER_SEND_INFO_REPORT = 'USER_SEND_INFO_REPORT';
export const userSendInfoReport = (year: string) => action(USER_SEND_INFO_REPORT, { year });

// Crypto deposit

export const CRYPTO_DEPOSIT_INFO = createRequestTypes('CRYPTO_DEPOSIT_INFO');
export const cryptoDepositInfo = {
  request: () => action(CRYPTO_DEPOSIT_INFO[REQUEST]),
  success: (crypto: string, response: any) => action(CRYPTO_DEPOSIT_INFO[SUCCESS], { crypto, response }),
  failure: (_: any, error: any) => action(CRYPTO_DEPOSIT_INFO[FAILURE], { error }),
};

export const REGENERATE_ADDRESS = createRequestTypes('REGENERATE_ADDRESS');
export const regenerateAddress = {
  request: () => action(REGENERATE_ADDRESS[REQUEST]),
  success: (crypto: string, response: any) => action(REGENERATE_ADDRESS[SUCCESS], { crypto, response }),
  failure: (_: any, error: any) => action(REGENERATE_ADDRESS[FAILURE], { error }),
};

// UI actions
export const START_CRYPTO_ADDRESS_SYNC = 'START_CRYPTO_ADDRESS_SYNC';
export const startCryptoDepositAddressSync = (crypto: string) =>
  action(START_CRYPTO_ADDRESS_SYNC, { crypto });

export const STOP_CRYPTO_ADDRESS_SYNC = 'STOP_CRYPTO_ADDRESS_SYNC';
export const stopCryptoDepositAddressSync = () => action(STOP_CRYPTO_ADDRESS_SYNC);

export const GENERATE_ADDRESS = 'GENERATE_ADDRESS';
export const generateAddress = (crypto: string) => action(GENERATE_ADDRESS, { crypto });

export const FETCH_BANK_STATEMENT_AVAILABLE_MONTHS = 'FETCH_BANK_STATEMENT_AVAILABLE_MONTHS';
export const fetchBankStatementAvailableMonths = () => action(FETCH_BANK_STATEMENT_AVAILABLE_MONTHS);

export const SET_BANK_STATEMENT_AVAILABLE_MONTHS = 'SET_BANK_STATEMENT_AVAILABLE_MONTHS';
export const setBankStatementAvailableMonths = (availableMonths: Array<string>) => ({
  type: SET_BANK_STATEMENT_AVAILABLE_MONTHS,
  payload: { availableMonths },
});

export const SEND_BANK_STATEMENT_REQUEST = 'SEND_BANK_STATEMENT_REQUEST';
export const sendBankStatementRequest = (values, actions) =>
  action(SEND_BANK_STATEMENT_REQUEST, { values, actions });

export const SEND_TRANSACTION_HISTORY_REQUEST = createRequestTypes('SEND_TRANSACTION_HISTORY_REQUEST');
export const sendTransactionHistory = {
  request: ({ year }: { year: int }) => action(SEND_TRANSACTION_HISTORY_REQUEST[REQUEST], { year }),
  success: ({ year }: { year: int }) => action(SEND_TRANSACTION_HISTORY_REQUEST[SUCCESS], { year }),
  failure: (_: any, error: any) => action(SEND_TRANSACTION_HISTORY_REQUEST[FAILURE], { error }),
};

export const SEND_TRANSACTION_HISTORY = 'SEND_TRANSACTION_HISTORY';
export const userSendTransactionHistoryReport = (values, actions) =>
  action(SEND_TRANSACTION_HISTORY, { values, actions });
