import { State } from 'store/types/store';
import { priceChangesAdapter } from './adapter';
import { AssetClass } from 'types/assets';
import { createSelector } from 'reselect';
import { getMarketOverviewSearchAssetCodes } from '../filters/selectors';
import { PriceChangeEntity } from 'store/types/priceChanges';
import { getSelectedTimePeriod } from 'store/selectors/currency';
import { TimePeriod } from 'types/currency';
import { isPriceChangeEntityEligibleForFetching } from 'common/utils/priceChanges';
import { isEqualIgnoreCase } from 'common/utils/common';

const priceChangesState = (state: State) => state.priceChanges;

const priceChangesSelectors = priceChangesAdapter.getSelectors(priceChangesState);

// Both private for now. Make it public if needed.
const areCryptoPriceChangesFetching = (state: State) =>
  priceChangesState(state).areCryptoPriceChangesFetching;
const areSecuritiesPriceChangesFetching = (state: State) =>
  priceChangesState(state).areSecuritiesPriceChangesFetching;

/**
 * Returns price change data of a single passed-in asset.
 * If the asset is not there then it returns `undefined`
 *
 * @summary Price change data of a single stored asset.
 *
 * @public
 * @param {State} state
 * @param {string} id Code/ID of an asset you want price change data for. Id is composed from asset code and timePeriod.
 * @returns {PriceChangeEntity | undefined}
 */
export const getPriceChangeById = priceChangesSelectors.selectById;

/**
 * Returns all price changes that are stored in this state adapter for a selected time period.
 *
 * @summary Price change data of all stored assets for a selected time period.
 *
 * @public
 * @param {State} state
 * @returns {PriceChangeEntity[]}
 */
export const getAllPriceChangesForSelectedTimePeriod = createSelector(
  [priceChangesSelectors.selectAll, getSelectedTimePeriod],
  (priceChanges: PriceChangeEntity[], timePeriod: TimePeriod) =>
    priceChanges.filter((priceChange) => priceChange.timePeriod === timePeriod),
);

/**
 * Returns price change data of a single passed-in asset.
 * If the asset is not there then it returns `undefined`
 *
 * @summary Price data of a single stored asset.
 *
 * @public
 * @param {State} state
 * @param {string} id Code/ID of an asset you want price change data for.
 * @returns {PriceChangeEntity | undefined}
 */
export const getPriceChangesForSelectedTimePeriodByCode = createSelector(
  [getAllPriceChangesForSelectedTimePeriod, (state: State, props: string) => props],
  (priceChanges: PriceChangeEntity[], assetCode: string) =>
    priceChanges.find((priceChange) => isEqualIgnoreCase(priceChange.assetCode, assetCode)),
);

/**
 * Returns all price changes for a selected time period,
 * that are stored in this state adapter filtered by asset class.
 *
 * @summary Price change data of all stored assets for a selected time period,
 * filtered by passed in asset class.
 *
 * @public
 * @param {State} state
 * @param {AssetClass} assetClass
 * @returns {PriceChangeEntity[]}
 */
export const getAllPriceChangesForSelectedTimePeriodByAssetClass = createSelector(
  [getAllPriceChangesForSelectedTimePeriod, (state: State, props: AssetClass) => props],
  (priceChanges: PriceChangeEntity[], assetClass: AssetClass) =>
    priceChanges.filter((priceChange) => priceChange.assetClass === assetClass),
);

/**
 * Returns all memo price changes data for searched assets on Market Overview page.
 * In addition those price changes are filtered by a selected time period.
 * If you need all prices please refer to: `getAllPrices` or `getAllPricesByAssetClass`
 *
 * @summary Price changes for Market Overview page.
 *
 * @public
 * @param {State} state
 * @returns {PriceChangeEntity[]}
 */
export const getMarketOverviewPriceChanges = createSelector(
  [getAllPriceChangesForSelectedTimePeriod, getMarketOverviewSearchAssetCodes],
  (priceChanges: PriceChangeEntity[], codes: string[]) =>
    priceChanges.filter((priceChange: PriceChangeEntity) =>
      codes.includes(priceChange.assetCode.toLowerCase()),
    ),
);

/**
 * Returns all passed in filter codes that can be included in the price change fetching.
 * If some asset's price change was already fetched recently that asset will be left out.
 *
 * @summary Filtered codes for price changes fetching.
 *
 * @public
 * @param {State} state
 * @param {string[]} codes Asset codes that are ready to be fetched.
 * @returns {string[]}
 */
export const getEligiblePriceChangeCodesByAssetClass = (
  state: State,
  codes: string[],
  assetClass: AssetClass,
  timePeriod: TimePeriod,
) => {
  const allPriceChanges = getAllPriceChangesForSelectedTimePeriodByAssetClass(state, assetClass);

  if (!allPriceChanges.length) return codes;

  return codes.filter((code: string) => {
    const priceChangeEntity = allPriceChanges.find(
      (entity) => isEqualIgnoreCase(entity.assetCode, code) && entity.timePeriod === timePeriod,
    );

    // If there is no entity yet stored than we need to fetch it.
    if (!priceChangeEntity) return true;

    return isPriceChangeEntityEligibleForFetching(priceChangeEntity.lastFetched, assetClass);
  });
};

/**
 * Returns a boolean value if some price changes fetching is in progress at this moment.
 *
 * @summary Price changes fetching in progress.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const isAnyPriceChangeFetchingInProgress =
  areCryptoPriceChangesFetching || areSecuritiesPriceChangesFetching;
