import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserHash } from 'common/hooks/useUserHash';
import { addUserToUsersWhoSawStakingIntroBanner } from 'store/actions/localSettings';
import { useIsStakingRoleGranted } from 'common/hooks/useIsStakingRoleGranted';
import { getUsersWhoSawStakingIntroBanner } from 'store/selectors/localSettings';

export const useStakingIntroModal = () => {
  const dispatch = useDispatch();
  const userHash = useUserHash();
  const isStakingRoleGranted = useIsStakingRoleGranted();
  const usersWhoSawStakingIntroBanner = useSelector(getUsersWhoSawStakingIntroBanner);
  const didUserSawBanner = usersWhoSawStakingIntroBanner.includes(userHash);

  const [isStakingIntroShown, setIsStakingIntroShown] = useState(false);

  useEffect(() => {
    if (!userHash || didUserSawBanner || !isStakingRoleGranted) return;
    setIsStakingIntroShown(true);
  }, [didUserSawBanner, isStakingRoleGranted, userHash]);

  const onCloseStakingIntro = () => {
    if (!isStakingRoleGranted || didUserSawBanner) return;
    dispatch(addUserToUsersWhoSawStakingIntroBanner(userHash));
  };

  return { isStakingIntroShown, onCloseStakingIntro };
};
