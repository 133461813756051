import {
  Asset,
  MarketOverviewOrderBy,
  AssetReference,
  CostTransparencyFromApi,
  CostTransparencyPayload,
  AssetDetail,
  AssetTag,
  AssetClassInfo,
} from 'types/assets';
import { ApiError } from 'types/error';
import { TimePeriodToPortfolioChartTimeValue } from 'common/const';
import { NavigateFunction } from 'react-router-dom';

// ACTION DEFINITIONS

// STOCK&ETF PRICE CHANGES
export const FETCH_STOCKS_ETF_PRICE_CHANGES_SUCCESS = 'FETCH_STOCKS_ETF_PRICE_CHANGES_SUCCESS';
export const FETCH_STOCKS_ETF_PRICE_CHANGES_FAILURE = 'FETCH_STOCKS_ETF_PRICE_CHANGES_FAILURE';
// ASSET DETAILS
export const FETCH_ASSET_DETAIL_FAILURE = 'FETCH_ASSET_DETAIL_FAILURE';
// COST TRANSPARENCY
export const COST_TRANSPARENCY_GET = 'COST_TRANSPARENCY_GET';
export const COST_TRANSPARENCY_GET_SUCCESS = 'COST_TRANSPARENCY_GET_SUCCESS';
export const COST_TRANSPARENCY_GET_FAILURE = 'COST_TRANSPARENCY_GET_FAILURE';
// ACTIONS

// FETCH SINGLE ASSET
export type FetchSingleAssetPayload = {
  code: string;
} & AssetClassInfo;

// MARKET OVERVIEW ASSETS
export type AssetsRequest = {
  search?: string;
  reference?: AssetReference;
  pageSize: number;
  orderMechanism?: MarketOverviewOrderBy;
  timePeriod?: TimePeriodToPortfolioChartTimeValue;
  tag: AssetTag;
};
export type AssetsSuccess = {
  assets: Array<Asset>;
  reference?: AssetReference;
  pageSize: number;
} & AssetClassInfo;

// ASSET DETAILS
export type AssetDetailRequestPayload = {
  code: string;
  language: any;
} & AssetClassInfo;

export type AssetDetailSuccessPayload = {
  detail: AssetDetail;
  code: string;
} & AssetClassInfo;

// COST TRANSPARENCY
export const fetchCostTransparencyData = (payload: CostTransparencyPayload) =>
  ({
    type: COST_TRANSPARENCY_GET,
    payload,
  } as const);
export const fetchCostTransparencyDataSuccess = ({
  costTransparency,
  price,
}: {
  costTransparency: CostTransparencyFromApi;
  price: number;
}) =>
  ({
    type: COST_TRANSPARENCY_GET_SUCCESS,
    payload: {
      costTransparency,
      price,
    },
  } as const);
export const fetchCostTransparencyDataFailure = (error: ApiError) =>
  ({
    type: COST_TRANSPARENCY_GET_FAILURE,
    error,
  } as const);
