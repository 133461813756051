import styled from 'styled-components';
import { devices } from 'common/styles';
import LayoutRegularWrapper from '../LayoutContainer/LayoutRegularWrapper/LayoutRegularWrapper';

const BannerLayoutWrapper = styled(LayoutRegularWrapper)`
  padding-top: 12px;
  background: transparent;
  @media ${devices.tablet} {
    padding-top: 24px;
  }
`;

export default BannerLayoutWrapper;
