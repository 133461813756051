import React from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';
import { sessionVerifyAndReset } from 'common/utils/session';
import initGruffalo from 'common/utils/init';
import initSentry from 'common/utils/sentryUtilities';
import CookieConsentValues from './common/components/Modal/CookiePopup/helpers';
import setupStore from 'store/configureStore';
import initIi18n from 'locales/i18n';
import rootSaga from 'store/rootSaga';
import rootEpic from 'store/rootEpic';
import 'semantic-ui-css/components/dimmer.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/transition.min.css';
import 'semantic-ui-css/components/loader.min.css';
import 'semantic-ui-css/components/modal.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/checkbox.min.css';
import 'semantic-ui-css/components/sidebar.min.css';
import 'semantic-ui-css/components/popup.min.css';
import 'semantic-ui-css/components/accordion.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import App from 'scenes/App';

window.env.BETA = !!window.env.BETA && window.env.ENVIRONMENT === 'production';
const { persistor, runSaga, store, runEpic, rehydration } = setupStore();
(async function init() {
  // check session validity before anything else happens
  sessionVerifyAndReset();
  const cookieConsent = Cookies.get('cookiesConsent');
  if (cookieConsent === CookieConsentValues.ACCEPT_ALL) {
    const gtmInitEvent = new CustomEvent('init-gtm');
    window.dispatchEvent(gtmInitEvent);
  }

  initSentry();
  initGruffalo(undefined);
  await initIi18n();
  persistor.persist();
  await rehydration();
  runEpic(rootEpic);
  runSaga(rootSaga);

  const container = document.getElementById('root');

  const root = createRoot(container);

  root.render(<App isCookieConsent={!!cookieConsent} persistor={persistor} store={store} />);
})();
