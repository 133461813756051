import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { isOfType } from 'safetypings';
import { State } from 'store/types/store';
import { getSelectedTimePeriod } from 'store/selectors/currency';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import {
  subscribeToPriceHistorySmallChart,
  subscribeToPriceHistorySmallChartError,
  subscribeToPriceHistorySmallChartSuccess,
  unsubscribeFromPriceHistorySmallChart,
} from 'store/slices/priceHistorySmallChart/actions';
import { AnyAction } from 'redux';
import { from, interval, Observable } from 'rxjs';
import { timePeriodToQueryParamCrypto } from 'common/const';
import { PriceHistorySmallChartPoint } from 'store/types/priceHistory';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import { getEligibleSmallChartCodes } from 'store/slices/priceHistorySmallChart/selectors';
import { CRYPTO_SMALL_CHART_FETCH_INTERVAL_IN_MS } from 'common/const/priceHistory';

const fetchPriceHistoryForSmallChart = (
  action$: Observable<AnyAction>,
  state$: Observable<State>,
  { ajax, api }: any,
) =>
  action$.pipe(
    filter(isOfType(subscribeToPriceHistorySmallChart.type)),
    filter((action) => !!action?.payload?.codes?.length),
    switchMap((action: any) =>
      interval(CRYPTO_SMALL_CHART_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              timePeriod: getSelectedTimePeriod(state),
              assetCodes: getEligibleSmallChartCodes(
                state,
                action.payload.codes,
                getSelectedTimePeriod(state),
              ),
            })),
            filter(({ timePeriod, assetCodes }) => !!assetCodes?.length && !!timePeriod),
            take(1),
            mergeMap(({ timePeriod, assetCodes }) => {
              return from(assetCodes).pipe(
                mergeMap((assetCode: string) =>
                  ajax({
                    url: api
                      .getBaseUrlForPriceHistorySmallChart()
                      .concat(`/${assetCode}eur`)
                      .concat(
                        queryArrayString({
                          periods: timePeriodToQueryParamCrypto[timePeriod],
                        }),
                      ),
                  }).pipe(
                    pluck('response'),
                    map((response: PriceHistorySmallChartPoint[]) => {
                      return subscribeToPriceHistorySmallChartSuccess({
                        smallChart: response,
                        timePeriod,
                        assetCode,
                      });
                    }),
                  ),
                ),
                backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
                catchError(handleAjaxError(action$, subscribeToPriceHistorySmallChartError)),
              );
            }),
          ),
        ),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromPriceHistorySmallChart.type)))),
      ),
    ),
  );

export default combineEpics(fetchPriceHistoryForSmallChart);
