/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Observable, of, throwError } from 'rxjs';
import { ajax as Ajax, AjaxError, AjaxTimeoutError } from 'rxjs/ajax';
import { mergeMap, catchError } from 'rxjs/operators';
import { flatten, partition } from './functional';
import parseTimestampFromGuid from './parse';

type JSONValue = string | number | boolean | null | { [key: string]: JSONValue };
type JSONObject = { [key: string]: JSONValue };

const normalizePayload = (payload: JSONObject, prefix?: string): (string | number | boolean)[][] => {
  const normalized = Object.entries(payload)
    .filter(([k, v]) => k != null && v != null)
    .map(([k, v]) => {
      const prefixedKey = (prefix?.concat('.') ?? '') + k;
      return typeof v === 'object' && v != null ? normalizePayload(v, prefixedKey) : [prefixedKey, v];
    });

  // [key, value] pairs
  return partition<string | number | boolean>(2, flatten(normalized)) ?? [];
};

const preAuthAjaxRequest = ({
  ajax,
  baseUrl,
  clientId,
  nonce,
  timeout,
  commonHeaders = {},
}: {
  ajax: typeof Ajax;
  baseUrl: string;
  clientId: string;
  nonce: string;
  timeout?: number;
  commonHeaders?: any;
}): Observable<{ Nonce: string; Authorization: string }> | Observable<never> =>
  ajax({
    url: `${baseUrl}/auth/clientAuth?clientId=${clientId}`,
    headers: { ...commonHeaders, nonce },
    // if passed as `timeout` and it was undefined, Android would freak out and throw exception
    ...(timeout != null ? { timeout } : {}),
  }).pipe(
    // since 401 is expected, just pass the err along
    catchError((err: AjaxError) => of(err)),
    mergeMap((response) => {
      const headers = ['Www-Authenticate', 'DbRequestId'].reduce((m, k) => ({
        ...m,
        [k]: response.xhr.getResponseHeader(k),
      }));
      // check if all values are present
      return response.status === 401 && Object.values(headers).every(Boolean)
        ? of({
            Nonce: parseTimestampFromGuid(headers.DbRequestId),
            Authorization: `Bearer ${headers['Www-Authenticate']}`,
          })
        : throwError(new AjaxError('clientAuth responded unexpectedly', response.xhr, response.request));
    }),
  );

export const isAjaxErrorTimeout = (error: AjaxError): boolean => error instanceof AjaxTimeoutError;

export const queryString = (payload: JSONObject): string =>
  Object.keys(payload).length === 0
    ? ''
    : '?' +
      normalizePayload(payload)
        .map((e) => e.map(encodeURIComponent))
        .map(([k, v]) => `${k}=${v}`)
        .join('&');

export const queryArrayString = (payload: Record<string, any>): string => {
  return Object.keys(payload).length === 0
    ? ''
    : '?' +
        Object.entries(payload)
          .filter(
            ([_, value]) =>
              (Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value != null),
          )
          .map(([key, value]) =>
            Array.isArray(value)
              ? value.map((arrayValue) => `${key}=${encodeURIComponent(arrayValue)}`).join('&')
              : typeof value === 'object'
              ? normalizePayload({ [key]: value })
                  .map((e) => e.map(encodeURIComponent))
                  .map(([k, v]) => `${k}=${v}`)
                  .join('&')
              : `${key}=${encodeURIComponent(value)}`,
          )
          .join('&');
};

export default preAuthAjaxRequest;
