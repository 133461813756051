/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createSlice } from '@reduxjs/toolkit';
import { SCROLL_PAGE_SIZE } from 'common/const';
import {
  clearSingleMessageWithDocument,
  deleteMessageSuccess,
  documentsFailure,
  documentsRequest,
  documentsSuccess,
  editAllMessagesSuccess,
  fetchTotalUnreadCountSuccess,
  inboxFailure,
  inboxRequest,
  inboxSuccess,
  invalidateInbox,
  leaveInboxPage,
  readAllDocumentsSuccess,
  readMessageSuccess,
  readMessageWithDocumentError,
  readMessageWithDocumentRequest,
  readMessageWithDocumentSuccess,
  visitInboxPage,
} from './actions';
import { messagesAdapter, messagesWithDocumentsAdapter } from 'store/slices/inbox/adapters';
import { messagesSelectors, messagesWithDocumentsSelectors } from 'store/slices/inbox/selectors';
import { initialState } from 'store/slices/inbox/initialState';
import { INBOX_SLICE_NAME } from 'common/const/slices';

const slice = createSlice({
  name: INBOX_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inboxRequest, (state) => {
        state.messages.isLoading = true;
      })
      .addCase(inboxSuccess, (state, { payload }) => {
        messagesAdapter.upsertMany(state.messages.items, payload.messages);
        state.messages.lastUpdated = Date.now();
        state.numOfUnread.inboxCount = payload.inboxCount;

        state.messages.isLoading = false;

        if (payload.messages.length < SCROLL_PAGE_SIZE) {
          state.messages.areAllLoaded = true;
        }
      })
      .addCase(inboxFailure, (state, { payload }) => {
        state.messages.isLoading = true;
        state.errMsg = payload.errMsg;
      })
      .addCase(documentsRequest, (state) => {
        state.messagesWithDocuments.isLoading = true;
      })
      .addCase(documentsSuccess, (state, { payload }) => {
        messagesWithDocumentsAdapter.addMany(state.messagesWithDocuments.items, payload.messages);
        state.messagesWithDocuments.lastUpdated = Date.now();
        state.messagesWithDocuments.totalCount = payload.totalPostbox;
        state.messagesWithDocuments.isLoading = false;

        if (
          payload.totalPostbox ===
          messagesWithDocumentsSelectors.selectTotal(state.messagesWithDocuments.items)
        )
          state.messagesWithDocuments.fullyLoaded = true;
      })
      .addCase(documentsFailure, (state) => {
        state.messagesWithDocuments.isLoading = false;
      })
      .addCase(readMessageSuccess, (state, { payload: { id } }) => {
        messagesAdapter.updateOne(state.messages.items, {
          id,
          changes: { isRead: true, isNotificationRequired: false },
        });

        const { inboxCount } = state.numOfUnread;
        state.numOfUnread.inboxCount = inboxCount > 0 ? inboxCount - 1 : 0;
        state.numOfUnread.isNotificationRequired =
          messagesSelectors
            .selectAll(state.messages.items)
            .filter(({ isNotificationRequired }) => isNotificationRequired).length > 0;
      })
      .addCase(deleteMessageSuccess, (state, { payload: { id } }) => {
        const selectedMessage = messagesSelectors.selectById(state.messages.items, id);

        if (selectedMessage && !selectedMessage.isRead) {
          const { inboxCount } = state.numOfUnread;
          state.numOfUnread.inboxCount = inboxCount > 0 ? inboxCount - 1 : 0;
        }

        messagesAdapter.removeOne(state.messages.items, id);
      })
      .addCase(editAllMessagesSuccess, (state, { payload: { isRead, isDeleted } }) => {
        const oldMessages = messagesSelectors.selectAll(state.messages.items);
        if (isRead) {
          messagesAdapter.updateMany(
            state.messages.items,
            oldMessages.map(({ id }) => ({ id, changes: { isRead: true } })),
          );

          state.numOfUnread.inboxCount = 0;
          return;
        }

        if (isDeleted) {
          const messagesToRemove = oldMessages
            .filter(({ attachments, isNotificationRequired }) => !isNotificationRequired && !attachments)
            .map(({ id }) => id);

          messagesAdapter.removeMany(state.messages.items, messagesToRemove);
          const unreadMessages = messagesSelectors
            .selectAll(state.messages.items)
            .map(({ isRead: isMessageRead }) => !isMessageRead);

          state.numOfUnread.inboxCount = unreadMessages.length;
          return;
        }
      })
      .addCase(fetchTotalUnreadCountSuccess, (state, { payload: { numOfUnread } }) => {
        state.numOfUnread = numOfUnread;
        state.countLastUpdated = Date.now();
      })
      .addCase(readMessageWithDocumentRequest, (state, { payload: { refreshLink, readWithoutLooking } }) => {
        if (refreshLink || readWithoutLooking) return;
        state.readMessageWithDocumentsLoading = true;
      })
      .addCase(
        readMessageWithDocumentSuccess,
        (state, { payload: { document, refreshLink, readWithoutLooking } }) => {
          state.readMessageWithDocumentsLoading = false;
          const oldPostboxCount = state.numOfUnread.postboxCount;
          messagesWithDocumentsAdapter.updateOne(state.messagesWithDocuments.items, {
            id: document.id,
            changes: { isRead: true },
          });
          state.numOfUnread.postboxCount = oldPostboxCount > 0 ? oldPostboxCount - 1 : 0;

          if (readWithoutLooking) return;

          if (!refreshLink) {
            state.singleMessageWithDocument = document;
            return;
          }

          if (state.singleMessageWithDocument) {
            state.singleMessageWithDocument.oneTimeDownloadLink = document.oneTimeDownloadLink;
          } else {
            state.singleMessageWithDocument = document;
          }
        },
      )
      .addCase(readMessageWithDocumentError, (state) => {
        state.readMessageWithDocumentsLoading = false;
      })
      .addCase(readAllDocumentsSuccess, (state) => {
        const messagesWithDocumentsToUpdate = messagesWithDocumentsSelectors
          .selectAll(state.messagesWithDocuments.items)
          .filter(({ isRead }) => !isRead)
          .map(({ id }) => id);

        messagesWithDocumentsAdapter.updateMany(
          state.messagesWithDocuments.items,
          messagesWithDocumentsToUpdate.map((id) => ({ id, changes: { isRead: true } })),
        );

        state.numOfUnread.postboxCount =
          state.numOfUnread.postboxCount - messagesWithDocumentsToUpdate.length;
      })
      .addCase(clearSingleMessageWithDocument, (state) => {
        state.singleMessageWithDocument = null;
      })
      .addCase(visitInboxPage, (state) => {
        state.isOnInboxPage = true;
      })
      .addCase(leaveInboxPage, (state) => {
        state.isOnInboxPage = false;
      })
      .addCase(invalidateInbox, () => initialState);
  },
});

export default slice.reducer;
