import { fork, all, takeEvery } from 'redux-saga/effects';

import { TRACK_APP_EVENT } from 'store/actions/tracking';
import { logTradingRulesEvents } from './tradingRules';
import { logWelcomeEvents } from './welcome';
import eventsFromActionsWatcher from './app-wide';
import { logBeforeUnload, logOnLoad } from './window';
import { logPortfolioEvents } from './portfolio';
import { logKycEvents } from './kyc';
import { logMobileNotificationEvents } from './mobileNotification';
import { logSavingPlansEvents } from './savingPlans';
import { logNewsFeedEvents } from './newsfeed';
import { logSettingsEvents } from './settings';
import { logComponentEvents } from './components';
import { logNotificationEvents } from './notifications';
import { logBannerEvents } from './banner';

function* trackAppEventsWatcher(): Generator<any, any, any> {
  yield takeEvery(TRACK_APP_EVENT, logOnLoad);
  yield takeEvery(TRACK_APP_EVENT, logBeforeUnload);
  yield takeEvery(TRACK_APP_EVENT, logWelcomeEvents);
  yield takeEvery(TRACK_APP_EVENT, logPortfolioEvents);
  yield takeEvery(TRACK_APP_EVENT, logKycEvents);
  yield takeEvery(TRACK_APP_EVENT, logMobileNotificationEvents);
  yield takeEvery(TRACK_APP_EVENT, logTradingRulesEvents);
  yield takeEvery(TRACK_APP_EVENT, logSavingPlansEvents);
  yield takeEvery(TRACK_APP_EVENT, logNewsFeedEvents);
  yield takeEvery(TRACK_APP_EVENT, logSettingsEvents);
  yield takeEvery(TRACK_APP_EVENT, logComponentEvents);
  yield takeEvery(TRACK_APP_EVENT, logNotificationEvents);
  yield takeEvery(TRACK_APP_EVENT, logBannerEvents);
}

export default function* root(): Generator<any, any, any> {
  try {
    yield all([fork(trackAppEventsWatcher), fork(eventsFromActionsWatcher)]);
  } catch (error) {
    console.error(error);
  }
}
