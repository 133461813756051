import styled from 'styled-components';
import { ResponsiveContainer as ResContainer } from 'recharts';
import { devices, dimensions } from 'common/styles';

export const MarketChartsWrapper = styled.div`
  position: relative;

  @media ${devices.maxMobileL} {
    margin-left: -${dimensions.smallIndent}px;
    margin-right: -${dimensions.smallIndent}px;
  }

  .recharts-xAxis .recharts-cartesian-axis-tick,
  .recharts-yAxis .recharts-cartesian-axis-tick {
    &:first-child line,
    &:last-child line {
      stroke: none;
    }
  }

  .recharts-yAxis .recharts-cartesian-axis-tick {
    font-size: 10px;
  }
`;

export const ResponsiveContainer = styled(ResContainer)`
  .recharts-tooltip-wrapper:focus-visible {
    outline: none;
  }
`;

export const LoadingWrapper = styled.div<{ $loading?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: ${({ $loading }: { $loading: boolean }) => ($loading ? 2 : 0)};
`;
