import * as api from 'common/api';

type InitParams = {
  fcmToken?: string;
  attribution?: any;
};

const initGruffalo = async (userId?: string, initParams: InitParams = {}) => {
  const params = {
    ...initParams,
    ...(userId != null ? { userId } : {}), // conditionally add if defined
  };

  await api.init(params);
};

export default initGruffalo;
