import styled from 'styled-components';
import { dimensions } from 'common/styles';
import bisonLogo from 'assets/img/bison_sad.png';

export const BisonLogo = styled.img.attrs({
  src: bisonLogo,
})`
  display: block;
  text-align: center;
  width: 150px;
  height: auto;
  margin: 0 auto ${dimensions.mediumPadding}px auto;
`;

export const Header = styled.div`
  margin-top: ${dimensions.mediumPadding}px;
`;

export const Wrapper = styled.div`
  width: 80%;
  max-width: ${dimensions.maxCenteredContentWidth}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 80%;
  padding-top: 20%;
  text-align: center;
`;
