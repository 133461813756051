import styled from 'styled-components';
import { Theme } from 'types/theme';

export const Header = styled.div.attrs({ className: 'h3' })``;

export const AssetCode = styled.text`
  text-transform: uppercase;
  color: ${({ $color }: { $color?: string }) => $color};
`;

export const Body = styled.div`
  padding-top: 16px;
  padding-bottom: 75px;
`;

export const Paragraph = styled.p`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary} !important;
  font-size: 14px !important;
  line-height: 19px !important;
`;
