import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchSingleAsset } from 'store/slices/assets/actions';
import { getAsset } from 'store/slices/assets/selectors';
import { State } from 'store/types/store';
import { AssetClass, AssetReturnType, AssetSecurityClass, Crypto, Security } from 'types/assets';

export const useAsset = <T extends AssetClass>(
  code: string,
  assetClass: T,
  securityClass: AssetSecurityClass | undefined,
): AssetReturnType<T> => {
  const asset = useSelector((state: State) => getAsset(state, assetClass, code));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSingleAsset({ code, class: assetClass, securityClass: securityClass }));
  }, []);

  return asset;
};
