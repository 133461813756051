import React, { CSSProperties, useCallback } from 'react';
import { Loader } from 'common/lib/semantic-ui';
import { useTracking } from 'common/tracking/hooks';
import Wrapper from './Wrapper';
import { ButtonWrapperProps } from './utils';
import { Link, TagLink, TextContainer } from './styles';

interface Props extends ButtonWrapperProps {
  'data-test-id'?: string;
  href?: string;
  inProgress?: boolean;
  linkStyle?: CSSProperties;
  title: string;
  to?: string;
  target?: string;
}

const Button = ({
  title,
  to,
  href,
  onClick,
  onMouseDown,
  inverted = false,
  isErrorType = false,
  disabled = false,
  disabledTheme = false,
  inProgress = false,
  children = null,
  link = false,
  linkStyle = {},
  target,
  style,
  className,
  type = 'button',
  isFullWidth = false,
  size = 'standard',
  weight = 'medium',
  fontSize = 'standard',
  customColor,
  ...rest
}: Props): React.ReactElement => {
  const trackAppEvent = useTracking();

  const onButtonClick = useCallback(
    (event: Event) => {
      const testId = rest['data-test-id'];
      if (testId) {
        trackAppEvent('buttonComponentPress', {
          buttonText: title,
          componentId: testId,
          componentType: 'Button',
        });
      }
      if (onClick) onClick(event);
    },
    [rest['data-test-id'], title, onClick],
  );

  const linkElement =
    href === undefined ? (
      <TextContainer inverted={inverted} isErrorType={isErrorType} data-test-id={rest['data-test-id']}>
        {inProgress ? <Loader active inline size="tiny" /> : title}
      </TextContainer>
    ) : (
      <TagLink href={href} target={target}>
        <TextContainer inverted={inverted} isErrorType={isErrorType} data-test-id={rest['data-test-id']}>
          {inProgress ? <Loader active inline size="tiny" /> : title}
        </TextContainer>
      </TagLink>
    );

  return (
    <Wrapper
      link={link}
      type={type}
      inverted={inverted}
      isErrorType={isErrorType}
      onClick={disabled || inProgress ? undefined : onButtonClick}
      onMouseDown={disabled || inProgress ? undefined : onMouseDown}
      disabled={disabled || disabledTheme}
      style={style}
      isFullWidth={isFullWidth}
      size={size}
      weight={weight}
      fontSize={fontSize}
      className={className}
      customColor={customColor}
    >
      {to === undefined ? (
        linkElement
      ) : (
        <Link to={to} target={target} style={linkStyle}>
          <TextContainer inverted={inverted} isErrorType={isErrorType} data-test-id={rest['data-test-id']}>
            {inProgress ? <Loader active inline size="tiny" /> : title}
          </TextContainer>
        </Link>
      )}
      {children}
    </Wrapper>
  );
};

Button.defaultProps = {
  to: undefined,
  inProgress: false,
  target: undefined,
  href: undefined,
  linkStyle: {},
  'data-test-id': undefined,
  customColor: undefined,
};

export default Button;
