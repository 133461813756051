import { ExperimentsRemoteDataState } from 'store/types/experiments';

const initialState: ExperimentsRemoteDataState = {
  SHOW_STOCKS_WINNERS_LOSERS_MODAL: '',
  ETP_SHORTS_ON_CRYPTO_DETAIL_SCREEN: '',
  BLOCKPIT_REFERAL_LINK: '',
};

// reducer for read-only state → just passes through state
function experimentsRemoteDataReducer(
  state: ExperimentsRemoteDataState = initialState,
): ExperimentsRemoteDataState {
  return state;
}

export default experimentsRemoteDataReducer;
