import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { RootState } from 'types/common';
import { useSelector } from 'react-redux';
import { getTheme } from 'store/selectors/settings';
import { themeByName } from 'common/utils/theme';
import GlobalStyles from 'common/styles/global';
import { useInputStyles } from 'common/styles/inputStyles';

const EnhanceWithTheme = (RenderComponent: React.ComponentType<any>): React.ReactNode => {
  const EnhancedComp = (props: any): React.ReactChild => {
    const themeName = useSelector((state: RootState) => getTheme(state));
    const theme = themeByName[themeName || 'light'];
    const muiInputThemeStyles = useInputStyles(theme);

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <MuiThemeProvider theme={muiInputThemeStyles}>
          <RenderComponent {...props} />
        </MuiThemeProvider>
      </ThemeProvider>
    );
  };
  return EnhancedComp;
};

export default EnhanceWithTheme;
