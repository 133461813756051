/* eslint-disable @typescript-eslint/no-unsafe-return */

import I18n from 'i18next';
import { createSelector } from 'reselect';
import { StatusState } from 'store/types/status';
import { State } from 'store/types/store';

export const getSubmoduleStatusState = ({ status }: { status: StatusState }) => status.submoduleStatus;
export const getStatus = ({ status }: { status: StatusState }) => status.status;
export const getStatusMessage = ({ status }: { status: StatusState }) => {
  // retrieve the language from the translation file, as the browser may be using an unsupported language
  const lang = I18n.t('l10n.lang');
  const statusValue = status.status;
  const currentLang = (lang || window.lang) as 'de' | 'en'; // fix for some cases when local storage gets wiped out
  return status.message ? status.message[currentLang] || I18n.t(`statusFallback.${statusValue}`) : undefined;
};

export const getModuleStatus = createSelector(
  ({ status }: { status: StatusState }) => status,
  (state) => {
    return state.submoduleStatus.reduce((acc, status) => {
      acc[status.webId] = status;
      return acc;
    }, {});
  },
);

export const createPageStatusSelector = () =>
  createSelector(
    getSubmoduleStatusState,
    getModuleStatus,
    (_, path) => path,
    (state, statusMap, path) => {
      const id = state
        .filter((status) => status.webId && path.indexOf(status.webId) === 0)
        .map(({ webId }) => webId)[0];
      return statusMap[id] || {};
    },
  );

export const statusInit = ({ status }: { status: StatusState }) => status.statusInitialized;

export const getSecurityMarketStatus = (state: State) => state.status.stockMarketStatus;
export const getStockMarketStatusIsUpdating = (state: State) => state.status.checkingStockMarketStatus;

export const getStockMarketTradingHours = (state: State) => {
  return {
    start: state.status.stockMarketStatus.tradingHoursStartAt,
    close: state.status.stockMarketStatus.tradingHoursCloseAt,
  };
};

export const getWhyIsStockMarketClosed = (state: State) => {
  if (state.status.stockMarketStatus.isHoliday) {
    return 'holiday';
  } else if (state.status.stockMarketStatus.isWeekend) {
    return 'weekend';
  } else {
    return 'outsideWorkingHours';
  }
};
