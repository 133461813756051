import { createSlice } from '@reduxjs/toolkit';
import { PRICE_ALERTS_SLICE_NAME } from 'common/const/slices';
import { priceAlertsInitialState } from './initialState';
import { priceAlertsAdapter } from './adapter';
import {
  PriceAlertCreateAction,
  PriceAlertCreateErrorAction,
  PriceAlertCreateSuccessAction,
  PriceAlertDeleteAction,
  PriceAlertDeleteErrorAction,
  PriceAlertDeleteSuccessAction,
  PriceAlertEditAction,
  PriceAlertEditErrorAction,
  PriceAlertEditSuccessAction,
  PriceAlertsSuccessAction,
} from './action-types';
import { PRICE_ALERTS_FETCH_DELAY_IN_MS } from 'common/const/priceAlerts';

const priceAlertsSlice = createSlice({
  name: PRICE_ALERTS_SLICE_NAME,
  initialState: priceAlertsInitialState,
  reducers: {
    // Fetching
    subscribeToPriceAlerts(state) {},
    subscribeToPriceAlertsSuccess(state, action: PriceAlertsSuccessAction) {
      state.lastFetched = new Date().getTime();
      priceAlertsAdapter.setAll(state, action.payload.priceAlerts);
    },
    subscribeToPriceAlertsError() {},
    unsubscribeFromPriceAlerts(state) {},

    // Fetch one time
    fetchPriceAlerts(state) {
      // Before we can set fetch in progress we must calculate if the fetching will even happen
      const lastFetched = state.lastFetched;
      const now = new Date().getTime();

      if (!lastFetched || lastFetched + PRICE_ALERTS_FETCH_DELAY_IN_MS < now)
        state.isPriceAlertFetchInProgress = true;
    },
    fetchPriceAlertsSuccess(state, action: PriceAlertsSuccessAction) {
      state.isPriceAlertFetchInProgress = false;
      state.lastFetched = new Date().getTime();
      priceAlertsAdapter.setAll(state, action.payload.priceAlerts);
    },
    fetchPriceAlertsError(state) {
      state.isPriceAlertFetchInProgress = false;
    },

    // Update price alert
    updatePriceAlert(state, action: PriceAlertEditAction) {
      const id = action.payload.model.id;

      if (!id) return;

      const entity = state.entities[id];

      if (!entity) return;

      // Set model to be updating
      const model = { ...entity, updating: true };

      priceAlertsAdapter.updateOne(state, { id, changes: model });
    },
    updatePriceAlertSuccess(state, action: PriceAlertEditSuccessAction) {
      const id = action.payload.id;

      if (!id) return;

      // Set model to not be updating
      const model = { ...action.payload, updating: false };

      priceAlertsAdapter.updateOne(state, { id, changes: model });
    },
    updatePriceAlertError(state, action: PriceAlertEditErrorAction) {
      const id = action.payload.id;

      if (!id) return;

      const entity = state.entities[id];

      if (!entity) return;

      // Set model to not be updating
      const model = { ...entity, updating: false };

      priceAlertsAdapter.updateOne(state, { id, changes: model });
    },

    // Create price alert
    createPriceAlert(state, action: PriceAlertCreateAction) {
      state.isPriceAlertCreateInProgress = true;
    },
    createPriceAlertSuccess(state, action: PriceAlertCreateSuccessAction) {
      const id = action.payload.id;

      if (!id) return;

      // Set model to not be updating
      const model = { ...action.payload, updating: false };

      state.isPriceAlertCreateInProgress = false;

      priceAlertsAdapter.upsertOne(state, model);
    },
    createPriceAlertError(state, action: PriceAlertCreateErrorAction) {
      state.isPriceAlertCreateInProgress = false;
    },

    // Delete price alert
    deletePriceAlert(state, action: PriceAlertDeleteAction) {
      state.isPriceAlertDeleteInProgress = true;
    },
    deletePriceAlertSuccess(state, action: PriceAlertDeleteSuccessAction) {
      const id = action.payload;

      if (!id) return;

      state.isPriceAlertDeleteInProgress = false;

      priceAlertsAdapter.removeOne(state, id);
    },
    deletePriceAlertError(state, action: PriceAlertDeleteErrorAction) {
      state.isPriceAlertDeleteInProgress = false;
    },
  },
});

export default priceAlertsSlice;
