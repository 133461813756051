import devicesSlice from '.';

export const {
  fetchDevicesRequest,
  fetchDevicesSuccess,
  fetchDevicesFailure,
  unpairDeviceRequest,
  unpairDeviceSuccess,
  unpairDeviceFailure,
} = devicesSlice.actions;
