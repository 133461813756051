import { initialState } from './initialState';
import { TimePeriod } from 'types/currency';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortfolioChartItem } from 'types/portfolio';

const portfolioChartSlice = createSlice({
  name: 'portfolioChart',
  initialState: initialState,
  reducers: {
    fetchPortfolioChartRequest(state, action: PayloadAction<{ timePeriod: TimePeriod }>) {
      const timePeriod = action.payload.timePeriod;
      if (!state[timePeriod]) {
        state[timePeriod] = {
          isFetching: false,
          items: [],
          lastUpdatedAt: null,
        };
      }
      state[timePeriod].isFetching = true;
    },
    fetchPortfolioChartSuccess(
      state,
      action: PayloadAction<{ timePeriod: TimePeriod; response: PortfolioChartItem[] }>,
    ) {
      const { timePeriod, response } = action.payload;

      if (!state[timePeriod]) return;

      state[timePeriod].isFetching = false;
      state[timePeriod].items = response;
      state[timePeriod].lastUpdatedAt = new Date().toString();
    },
    fetchPortfolioChartFailure(state, action: PayloadAction<{ timePeriod: TimePeriod }>) {
      const timePeriod = action.payload.timePeriod;
      state[timePeriod].isFetching = false;
    },
    fetchPortfolioChartWithSelectedTimePeriod() {},
  },
});

export default portfolioChartSlice;
