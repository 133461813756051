/* eslint-disable no-nested-ternary, @typescript-eslint/no-unsafe-return */
import { Action } from 'redux';
import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { from, concat, of, zip, race } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  acceptOrRejectDeviceMonitoringConsent,
  fetchDeviceMonitoringConsentModel,
  LoginRequest,
  sendDeviceData,
} from 'common/api';
import {
  DEVICE_MONITORING_CONSENT,
  DEVICE_MONITORING_INIT,
  deviceConsentIdSuccess,
  deviceDataRequest,
  DEVICE_DATA_REQUEST,
  DEVICE_MONITORING_PREINIT_CHECK,
  deviceMonitoringInit,
  DEVICE_DATA_SUCCESS,
  deviceDataSuccess,
  deviceDataEnd,
  repeatAuthRequest,
  REPEAT_AUTH_REQUEST_WITH_DEVICE_DATA,
  deviceMonitoringPreinitCheck,
  SUBMIT_LOGIN_USER_WITH_DEVICE_DATA,
  DEVICE_DATA_END,
  KYC_DEVICE_MONITORING_INIT,
  DEVICE_MONITORING_CONSENT_MODEL_REQUEST,
  deviceMonitoringConsentModelRequest,
  DEVICE_MONITORING_CONSENT_MODEL_SUCCESS,
  deviceMonitoringConsentModelError,
  deviceMonitoringConsentModelSuccess,
  DEVICE_MONITORING_CONSENT_MODEL_ERROR,
  deviceConsentIdError,
  DEVICE_CONSENT_ID_ERROR,
  DEVICE_MONITORING_CONSENT_PROMPT,
  deviceMonitoringConsentContinue,
  deviceMonitoringConsentPrompt,
  DEVICE_MONITORING_CONSENT_CONTINUE,
  ERROR_CHECK_DEVICE_DATA,
  DEVICE_MONITORING_CONSENT_STATE_REQUEST,
  deviceMonitoringConsentStateSuccess,
  deviceMonitoringConsentStateError,
  DEVICE_MONITORING_CONSENT_DECLINE_REQUEST,
  deviceMonitoringConsentDeclineSuccess,
  deviceMonitoringConsentDeclineError,
} from 'store/actions/deviceMonitoring';
import * as auth from 'store/actions/auth';
import { State } from 'store/types/store';
import { getDeviceData, setSeonSdkConfig } from 'common/utils/seonSdk';
import { getDeviceConsents, getIsDeviceMonitoringScriptLoaded } from 'store/selectors/deviceMonitoring';
import { deviceConsentPairHash } from 'common/utils/deviceConsentPairHash';
import { ApiError, errorCodes } from 'common/apiErrors';
import { KYC_FORM_SUBMIT, submitLoginUser } from 'store/actions/forms';
import { isOfType, isPresent } from 'safetypings';
import { getAccessToken, getUser } from 'store/selectors/auth';
import { DeviceMonitoringConsentState } from 'types/deviceMonitoring';
import { handleAjaxError } from './auth';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import I18n from 'i18next';
import { ConsentModelType } from 'types/consent';

const checkDeviceConsent = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(DEVICE_MONITORING_PREINIT_CHECK),
    switchMap(({ username }) =>
      state$.pipe(
        map(getIsDeviceMonitoringScriptLoaded),
        distinctUntilChanged(),
        filter((isDeviceMonitoringScriptLoaded) => isDeviceMonitoringScriptLoaded),
        switchMap(() =>
          state$.pipe(
            map(getDeviceConsents),
            take(1),
            map((deviceConsents) => {
              const hashKey = deviceConsentPairHash(username);
              const deviceConsentId = hashKey ? deviceConsents[hashKey] : null;
              if (deviceConsentId) {
                // Set deviceData which can be retrieved from the store upon login
                return deviceDataRequest(deviceConsentId);
              }
              return deviceDataEnd();
            }),
          ),
        ),
      ),
    ),
  );

const deviceMonitoring = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(DEVICE_MONITORING_INIT),
    switchMap(({ username, consentModel }: { username: string; consentModel: ConsentModelType }) => {
      return action$.pipe(
        // DEVICE_MONITORING_CONSENT is triggered from DeviceMonitoring modal consent with a click on button
        ofType(DEVICE_MONITORING_CONSENT),
        take(1),
        switchMap(({ consentState }: { consentState: DeviceMonitoringConsentState }) => {
          const consentStateDeclined = consentState?.toLowerCase() === 'declined';

          if (consentModel.toLowerCase() === 'explicit' && consentStateDeclined) {
            return of(deviceDataEnd());
          }
          return from(
            acceptOrRejectDeviceMonitoringConsent(username, consentStateDeclined ? 'decline' : 'accept'),
          ).pipe(
            switchMap(({ response }: { response: { reference: string } }) =>
              of(
                deviceConsentIdSuccess({ username, deviceConsentId: response?.reference }),
                deviceDataRequest(response?.reference),
              ),
            ),
            catchError(handleAjaxError(action$, deviceConsentIdError)),
          );
        }),
      );
    }),
  );

const generateDeviceData = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(DEVICE_DATA_REQUEST),
    switchMap(({ deviceConsentId }) =>
      zip(of(setSeonSdkConfig(deviceConsentId)), from(getDeviceData())).pipe(
        map((x) => deviceDataSuccess(x[1].deviceData)),
      ),
    ),
  );

const checkDeviceConsentNeeded = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(ERROR_CHECK_DEVICE_DATA),
    mergeMap(({ error }: { error: ApiError }) => {
      if (error && error.code === errorCodes.GENERAL_DEVICEDATA_REQUIRED) {
        // if device monitoring consent is missing ask user for consent
        return of(
          deviceMonitoringConsentPrompt(
            typeof error.detail === 'object' ? (error.detail.consentModel as ConsentModelType) : 'Explicit',
          ),
        );
      }
      return of(deviceMonitoringConsentContinue(true, true));
    }),
  );

const deviceMonitoringPrompt = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(DEVICE_MONITORING_CONSENT_PROMPT),
    switchMap(({ consentModel }: { consentModel: ConsentModelType }) =>
      state$.pipe(
        map(getUser),
        take(1),
        switchMap(({ username }) =>
          concat(
            of(deviceMonitoringInit(username, consentModel)),
            action$.pipe(
              filter(isOfType([DEVICE_DATA_SUCCESS, DEVICE_CONSENT_ID_ERROR, DEVICE_DATA_END])),
              take(1),
              mergeMap(({ type }) => of(deviceMonitoringConsentContinue(type !== DEVICE_DATA_END))),
            ),
          ),
        ),
      ),
    ),
  );

const deviceConsentNeeded = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(auth.AUTH.FAILURE),
    filter(
      ({ error: { code } }: { request: LoginRequest; error: ApiError }) =>
        code === errorCodes.GENERAL_DEVICEDATA_REQUIRED,
    ),
    switchMap(({ error: { detail, code }, request }: { request: LoginRequest; error: ApiError }) => {
      if (request?.credentials?.username) {
        return concat(
          of(deviceMonitoringInit(request.credentials.username, detail?.consentModel)),
          of(repeatAuthRequest(request?.credentials?.username, request?.credentials?.secret)),
        );
      }
      return of(deviceDataEnd());
    }),
  );

const preUserLoginSubmit = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(SUBMIT_LOGIN_USER_WITH_DEVICE_DATA),
    switchMap(({ values, actions }) =>
      concat(
        of(deviceMonitoringPreinitCheck(values.email)),
        action$.pipe(
          filter(isOfType([DEVICE_DATA_SUCCESS, DEVICE_DATA_END])),
          take(1),
          switchMap((x) => of(submitLoginUser(values, actions))),
        ),
      ),
    ),
  );

const repeatAuthRequestsWithDeviceData = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(REPEAT_AUTH_REQUEST_WITH_DEVICE_DATA),
    switchMap(({ username, password }) =>
      action$.pipe(
        filter(isOfType([DEVICE_DATA_SUCCESS, DEVICE_CONSENT_ID_ERROR])),
        take(1),
        // Repeat login request with deviceData this time
        switchMap(({ deviceData }: { deviceData: string }) =>
          of(submitLoginUser({ email: username, password })),
        ),
      ),
    ),
  );

// KYC DEVICE CONSENT

const kycDeviceMonitoringConsentCheck = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(KYC_DEVICE_MONITORING_INIT),
    switchMap(({ values, actions }) =>
      concat(
        of(deviceMonitoringConsentModelRequest(values.country)),
        action$.pipe(
          filter(isOfType([DEVICE_MONITORING_CONSENT_MODEL_SUCCESS, DEVICE_MONITORING_CONSENT_MODEL_ERROR])),
          switchMap(({ res, error }) => {
            if (res?.consentState || res?.consentModel?.toLowerCase() === 'unspecified' || error) {
              actions.continue(); // redirect to idNow even if the consent model fetching fails
              return of(deviceDataEnd());
            }

            return action$.pipe(
              // DEVICE_MONITORING_CONSENT is triggered from DeviceMonitoring modal consent with a click on button
              ofType(DEVICE_MONITORING_CONSENT),
              switchMap(({ consentState }: { consentState: DeviceMonitoringConsentState }) => {
                const consentStateDeclined = consentState?.toLowerCase() === 'declined';

                if (res?.consentModel?.toLowerCase() === 'explicit' && consentStateDeclined) {
                  return of(deviceDataEnd());
                }

                actions.continue(); // Submit KYC form data
                return action$.pipe(
                  filter(isOfType([KYC_FORM_SUBMIT.SUCCESS, KYC_FORM_SUBMIT.FAILURE])),
                  take(1),
                  switchMap((x) =>
                    from(
                      acceptOrRejectDeviceMonitoringConsent(
                        values.username,
                        consentStateDeclined ? 'decline' : 'accept',
                      ),
                    ).pipe(
                      switchMap(({ response: { reference } }: { response: { reference: string } }) =>
                        of(
                          deviceConsentIdSuccess({ username: values.username, deviceConsentId: reference }),
                          deviceDataRequest(reference),
                        ),
                      ),
                      catchError(handleAjaxError(action$, deviceConsentIdError)),
                    ),
                  ),
                );
              }),
            );
          }),
        ),
      ),
    ),
  );

const kycSendDeviceDataAfterConsentGiven = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(KYC_DEVICE_MONITORING_INIT),
    switchMap(({ values, actions }) =>
      race(
        action$.pipe(
          ofType(DEVICE_DATA_SUCCESS),
          take(1),
          switchMap(({ deviceData }: { deviceData: string }) =>
            state$.pipe(
              map((state) => [state, getAccessToken(state)]),
              filter(([state, accessToken]) => isPresent(accessToken)),
              take(1),
              map(([state, accessToken]) => [accessToken, getUser(state)]),
              distinctUntilChanged((prev, curr) => prev[1] === curr[1]),
              take(1),
              switchMap(([accessToken, { username }]) =>
                from(sendDeviceData(username, deviceData, accessToken)).pipe(
                  switchMap(() => {
                    return of(deviceDataEnd());
                  }),
                ),
              ),
            ),
          ),
        ),
        action$.pipe(
          ofType(DEVICE_CONSENT_ID_ERROR),
          map(() => deviceDataEnd()),
        ),
      ),
    ),
  );

const deviceMonitoringConsentModel = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(DEVICE_MONITORING_CONSENT_MODEL_REQUEST),
    switchMap(({ country }: { country: string }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(fetchDeviceMonitoringConsentModel(country, accessToken)).pipe(
            switchMap(({ error, response }) => of(deviceMonitoringConsentModelSuccess(response))),
            catchError(handleAjaxError(action$, deviceMonitoringConsentModelError)),
          ),
        ),
      ),
    ),
  );

const deviceMonitoringConsentState = (
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  action$.pipe(
    ofType(DEVICE_MONITORING_CONSENT_STATE_REQUEST),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(fetchDeviceMonitoringConsentModel(undefined, accessToken)).pipe(
            switchMap(({ error, response }) => of(deviceMonitoringConsentStateSuccess(response))),
            catchError(handleAjaxError(action$, deviceMonitoringConsentStateError)),
          ),
        ),
      ),
    ),
  );

const revokeConsent = (action$: any, state$: StateObservable<State>): ActionsObservable<Action> =>
  action$.pipe(
    ofType(DEVICE_MONITORING_CONSENT_DECLINE_REQUEST),
    switchMap(({ device, consentId }) =>
      state$.pipe(
        map(getUser),
        take(1),
        switchMap(({ username }) =>
          from(acceptOrRejectDeviceMonitoringConsent(username, 'decline', consentId)).pipe(
            switchMap(({ error, response }) =>
              of(
                deviceMonitoringConsentDeclineSuccess(consentId),
                notificationBannerActions.notifySuccess({
                  message: I18n.t('devices.revokeDeviceConsentResultNotification.success', { device }),
                }),
              ),
            ),
            catchError(
              handleAjaxError(
                action$,
                deviceMonitoringConsentDeclineError,
                {},
                { message: I18n.t('devices.revokeDeviceConsentResultNotification.error', { device }) },
              ),
            ),
          ),
        ),
      ),
    ),
  );

export default combineEpics(
  preUserLoginSubmit,
  deviceConsentNeeded,
  checkDeviceConsentNeeded,
  deviceMonitoringPrompt,
  checkDeviceConsent,
  generateDeviceData,
  deviceMonitoring,
  repeatAuthRequestsWithDeviceData,
  deviceMonitoringConsentModel,
  kycDeviceMonitoringConsentCheck,
  kycSendDeviceDataAfterConsentGiven,
  deviceMonitoringConsentState,
  revokeConsent,
);
