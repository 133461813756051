import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OrderBodyProps } from 'types/tradingRules';
import { setPageTitle } from 'store/actions/localSettings';
import LayoutNarrow from 'common/components/LayoutContainer/LayourNarrow/LayoutNarrow';
import IconButton from 'common/components/IconButton/IconButton';
import Icon from 'common/components/Icon';
import useTradingTitle from 'common/hooks/useTradingTitle';
import { devices } from 'common/styles';
import {
  BodyWrapper,
  FormOuterWrapper,
  TitleText,
} from 'common/components/TradingRulesCommonComponents/TradingRulesCreationStyles';
import { useAsset } from 'common/hooks/useAsset';
import { WithRouterType } from 'types/withRouter';
import { OrderType } from 'common/const';
import { ASSET_CLASSES } from 'types/assets';

const OrderBodyView = ({
  selectedCrypto,
  invalidatePortfolio,
  specificKey,
  children,
  params,
  navigate,
}: OrderBodyProps & WithRouterType): ReactElement => {
  const { type }: { type: OrderType } = params;
  const dispatch = useDispatch();
  const cryptoInfo = useAsset(selectedCrypto, ASSET_CLASSES.CRYPTO, undefined);

  const title = useTradingTitle({ type, specificKey, assetName: cryptoInfo?.displayName });

  useEffect(() => {
    invalidatePortfolio();
  }, [invalidatePortfolio]);

  useEffect(() => {
    setTimeout(() => dispatch(setPageTitle(title)), 0);
  }, []);

  return (
    <BodyWrapper>
      <LayoutNarrow
        isTopMarginRemoved={false}
        mainContainerCustomStyles={`@media ${devices.tablet} {padding: 30px;}`}
        headerElement={
          <TitleText>
            <IconButton onClick={() => navigate(-1)}>
              <Icon name="icon-arrow" sizeValue="tiny" />
            </IconButton>
            <div>{title}</div>
          </TitleText>
        }
      >
        <FormOuterWrapper>{children}</FormOuterWrapper>
      </LayoutNarrow>
    </BodyWrapper>
  );
};

export default OrderBodyView;
