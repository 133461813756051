/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-template-expressions */
import { ActionsObservable, combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import {
  filter,
  switchMap,
  map,
  pluck,
  catchError,
  take,
  mergeMap,
  tap,
  ignoreElements,
} from 'rxjs/operators';
import I18n from 'i18next';
import { backoff } from 'common/utils/rxjs-operators';
import { verificationDocuments } from 'common/const';
import { getDeviceData } from 'common/utils/seonSdk';
import { RootAction, RootEpic } from 'types/common';
import { isOfType, isPresent } from 'safetypings';
import { State } from 'store/types/store';
import { getAccessToken } from 'store/selectors/auth';
import { getRenewKycFormData } from 'store/selectors/forms';
import {
  startKycRenewProcessFailure,
  startKycRenewProcessSuccess,
  fetchUserKycStatusRenewInfoFailure,
  fetchUserKycStatusRenewInfoSuccess,
  START_KYC_RENEW_PROCESS,
  startKycRenewProcess,
} from 'store/actions/renewKycDocs';
import { DEVICE_MONITORING_CONSENT_CONTINUE, errorCheckDeviceData } from 'store/actions/deviceMonitoring';
import { handleAjaxError } from './auth';

export const renewKYCEpic: Epic<any> = (
  action$: ActionsObservable<any>,
  state$: StateObservable<State>,
  { ajax, api }: any,
) =>
  action$.pipe(
    ofType(START_KYC_RENEW_PROCESS),
    switchMap(({ documentType }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map((s) => ({ accessToken: getAccessToken(s), renewKycFormData: getRenewKycFormData(s) })),
            filter(isPresent),
            take(1),
            switchMap(({ accessToken, renewKycFormData }) => {
              return ajax({
                url: api.getBaseUrlForUserServices(accessToken).concat('/user/kyc/renew'),
                method: 'POST',
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  'Content-Type': 'application/json;charset=utf-8',
                  Authorization: `Bearer ${accessToken}`,
                },
                body:
                  documentType === verificationDocuments.PASSPORT
                    ? {
                        documentType,
                        ...renewKycFormData,
                        ...(deviceData ? { deviceData } : {}),
                      }
                    : {
                        documentType,
                        ...(deviceData ? { deviceData } : {}),
                      },
              }).pipe(
                pluck('response'),
                map(({ reference }) => {
                  return startKycRenewProcessSuccess(reference);
                }),
                catchError(({ response: { errors } }) => {
                  if (errors && errors.length) {
                    return merge(
                      action$.pipe(
                        ofType(DEVICE_MONITORING_CONSENT_CONTINUE),
                        mergeMap(({ shouldContinue, skip }) => {
                          if (shouldContinue && !skip) {
                            return of(startKycRenewProcess(documentType));
                          }
                          throw new Error(errors);
                        }),
                      ),
                      of(errorCheckDeviceData(errors[0])),
                    );
                  }
                  throw new Error(errors);
                }),
              );
            }),
            // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
            catchError(
              handleAjaxError(action$, startKycRenewProcessFailure, undefined, {
                message: I18n.t('error.generic'),
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const fetchIsKycRenewRequired: Epic<any> = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<State>,
  { ajax, api }: any,
) =>
  action$.pipe(
    filter(isOfType(['FETCH_USER_KYC_STATUS_SUCCESS', 'FETCH_USER_KYC_STATUS_RENEW_INFO_REQUEST'])),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken).concat('/user/kyc/renew/info'),
            withCredentials: true, // include cookies
            headers: { ...api.getCommonHeaders(), Authorization: `Bearer ${accessToken}` },
            timeout: 20000,
          }).pipe(
            pluck('response'),
            map((response) => fetchUserKycStatusRenewInfoSuccess(response.isKycRenewRequired)),
          ),
        ),
        // retries the whole `switchMap` observable to get new `x-bison-request-id header for subsequent ajax request
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchUserKycStatusRenewInfoFailure)),
      ),
    ),
  );

export default combineEpics(renewKYCEpic, fetchIsKycRenewRequired);
