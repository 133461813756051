import { TNC_TIMEOUT_IN_DAYS } from 'common/const/termsAndConditions';
import { addDays, isDateBetween } from './date';

export function isTnCOnHold(tncLastSeenTimestamp: string | null) {
  if (!tncLastSeenTimestamp) return false;

  const date = new Date();
  const tncLastSeenDate = new Date(tncLastSeenTimestamp);
  const timeoutInMiliseconds = TNC_TIMEOUT_IN_DAYS * 24 * 60 * 60 * 1000;
  if (tncLastSeenTimestamp && tncLastSeenDate && !isNaN(tncLastSeenDate.getTime())) {
    return date.getTime() - timeoutInMiliseconds <= tncLastSeenDate.getTime();
  }
  return false;
}

/**
 * This function checks if a tnc modal should still be hidden based on the last closed timestamp provided.
 *
 * @param {string | null} tncLastSeenTimestamp - Timestamp of the last clicked-away date - The last time the user closed the modal.
 * @returns {boolean} Returns true if the modal should still be hidden, otherwise false.
 */
export const isTnCModalStillHidden = (tncLastSeenTimestamp: string | null): boolean => {
  if (!tncLastSeenTimestamp) return false;

  const now = new Date();
  const modalHiddenDate = new Date(tncLastSeenTimestamp);
  const modalNextDisplayDate = addDays(modalHiddenDate, TNC_TIMEOUT_IN_DAYS);

  return isDateBetween(now, modalHiddenDate, modalNextDisplayDate);
};
