export function setItem(key: string, item: any) {
  try {
    localStorage.setItem(key, item);
    return { response: true };
  } catch (error) {
    return { error: { message: error?.message ?? 'Something bad happened while storing item' } };
  }
}

export function getItem(key: string) {
  try {
    const item = localStorage.getItem(key);
    return { response: { item } };
  } catch (error) {
    return {
      error: {
        code: error?.code ?? '',
        message: error?.message ?? 'Something bad happened while retrieving item',
      },
    };
  }
}

export function removeItem(key: string) {
  try {
    localStorage.removeItem(key);
    return { response: true };
  } catch (error) {
    return { error: { message: error?.message ?? 'Something bad happened while removing item' } };
  }
}

export function clearAll() {
  try {
    localStorage.clear();
    return { response: true };
  } catch (error) {
    return { error: { message: error?.message ?? 'Something bad happened while clearing local storage' } };
  }
}
