import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';

import { trackAppEvent } from 'store/actions/tracking';
import {
  trackMobileNotificationPressButtonSwitchToApp,
  trackMobileNotificationPressButtonContinueOnWeb,
} from '../biEventDefinitions';

export function* logMobileNotificationEvents(
  action: ReturnType<typeof trackAppEvent>,
): Generator<any, any, any> {
  const screenId: string = yield select(getCurrentPath);
  switch (action.eventName) {
    case 'mobileNotificationPressSwitchToApp': {
      yield call(trackMobileNotificationPressButtonSwitchToApp, screenId);
      break;
    }
    case 'mobileNotificationPressContinueOnWeb': {
      yield call(trackMobileNotificationPressButtonContinueOnWeb, screenId);
      break;
    }
    default:
      break;
  }
}
