// Raffle transation count

export const STOCK_RAFFLE_FETCH_TRANACTION_COUNT_REQUEST = 'STOCK_RAFFLE_FETCH_TRANACTION_COUNT_REQUEST';
export const STOCK_RAFFLE_FETCH_TRANACTION_COUNT_SUCCESS = 'STOCK_RAFFLE_FETCH_TRANACTION_COUNT_SUCCESS';
export const STOCK_RAFFLE_FETCH_TRANACTION_COUNT_ERROR = 'STOCK_RAFFLE_FETCH_TRANACTION_COUNT_ERROR';

export const stockRaffleFetchTransactionCount = () => ({
  type: STOCK_RAFFLE_FETCH_TRANACTION_COUNT_REQUEST,
});

export const stockRaffleFetchTransactionCountSuccess = (transactionCount: number) => ({
  type: STOCK_RAFFLE_FETCH_TRANACTION_COUNT_SUCCESS,
  payload: { transactionCount },
});

export const stockRaffleFetchTransactionCountError = () => ({
  type: STOCK_RAFFLE_FETCH_TRANACTION_COUNT_ERROR,
});
