import { connect } from 'react-redux';
import { getSelectedCryptoCurrency } from 'store/selectors/currency';
import { State } from 'store/types/store';
import { selectNumOFUnreadMessages } from 'store/slices/inbox/selectors';
import withRouter from 'common/hoc/WithRouter';
import { WithRouterType } from 'types/withRouter';
import TopBar from './view';

const mapStateToProps = (state: State, ownProps: WithRouterType) => ({
  selectedCrypto: getSelectedCryptoCurrency(state, ownProps.params), // get the selected crypto from the url or fallback to last selected
  unReadMessages: selectNumOFUnreadMessages(state),
  nSteps: state.forms.kycMeta.nSteps,
  currentStep: state.forms.kycMeta.currentStep,
});

export default withRouter(connect(mapStateToProps)(TopBar));
