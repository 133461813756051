/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { DEFAULT_TRACKING_CONFIGURATION } from 'common/tracking/rules';
import * as ActionTypes from '../actions/tracking';
import { limitBufferSize } from './_common';
import { TrackingState } from 'store/types/tracking';

// Upper limit of events in buffer
// 10K events translates to roughly 2MB of phone memory (payload size (in bytes) approx = 3KB + 200B * nEvents)
const MAX_EVENTS_IN_BUFFER = 10 * 1000;

const initialState: TrackingState = {
  sequentialId: 0,
  eventsBuffer: [],
  trackingConfiguration: DEFAULT_TRACKING_CONFIGURATION,
  flushRetryMode: {
    active: false,
    attempt: 0,
  },
};

export default function tracking(state: TrackingState = initialState, action: any): any {
  switch (action.type) {
    case ActionTypes.FLUSH_EVENTS.REQUEST:
      return {
        ...state,
      };
    case ActionTypes.FLUSH_EVENTS.SUCCESS: {
      const flushedEvents = action.payload.events;
      const lastFlushedId = flushedEvents.length > 0 ? flushedEvents[flushedEvents.length - 1].id : -1;
      return {
        ...state,
        eventsBuffer: state.eventsBuffer.filter((event) => event.id > lastFlushedId),
        flushRetryMode: {
          active: false,
          attempt: 0,
        },
      };
    }
    case ActionTypes.FLUSH_EVENTS.FAILURE:
      return {
        ...state,
        flushRetryMode: {
          active: true,
          attempt: state.flushRetryMode.attempt + 1,
        },
      };
    case ActionTypes.QUEUE_EVENT:
      return {
        ...state,
        eventsBuffer: limitBufferSize(
          [...state.eventsBuffer, { ...action.payload, id: state.sequentialId }],
          MAX_EVENTS_IN_BUFFER,
        ),
        sequentialId: state.sequentialId + 1,
      };
    case ActionTypes.SET_TRACKING_CONFIGURATION:
      return {
        ...state,
        trackingConfiguration: {
          ...state.trackingConfiguration,
          ...action.trackingConfiguration,
        },
      };
    default:
      return state;
  }
}
