/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable spaced-comment */

import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { RootEpic } from 'types/common';
import { interval } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { backoff } from 'common/utils/rxjs-operators';
import { getSelectedTimePeriod } from '../selectors/currency';
import { InnerTimePeriodToStockEtfRequestParam } from 'common/const';
import { getAccessToken } from 'store/selectors/auth';
import { handleAjaxError } from './auth';
import { State } from 'store/types/store';
import {
  subscribeToCryptoPriceChanges,
  subscribeToCryptoPriceChangesError,
  subscribeToCryptoPriceChangesSuccess,
  subscribeToPriceChangesForSelectedAssetTag,
  subscribeToSecurityPriceChanges,
  subscribeToSecurityPriceChangesError,
  subscribeToSecurityPriceChangesSuccess,
  unsubscribeFromCryptoPriceChanges,
  unsubscribeFromPriceChangesForSelectedAssetTag,
  unsubscribeFromSecurityPriceChanges,
} from 'store/slices/priceChanges/actions';
import { getEligiblePriceChangeCodesByAssetClass } from 'store/slices/priceChanges/selectors';
import {
  CRYPTO_PRICE_CHANGE_FETCH_INTERVAL_IN_MS,
  CRYPTO_PRICE_CHANGES_ENDPOINT,
  SECURITY_PRICE_CHANGE_FETCH_INTERVAL_IN_MS,
  SECURITY_PRICE_CHANGES_ENDPOINT,
} from 'common/const/priceChanges';
import { PriceChangeData } from 'store/types/priceChanges';
import { combineEpics } from 'redux-observable';
import { ASSET_CLASSES, ASSET_TAGS } from 'types/assets';
import { getMarketOverviewAssetTag } from 'store/slices/filters/selectors';

const mapGeneralSubscribeActionToCorrectByAssetTag: RootEpic = (action$: any, state$) =>
  action$.pipe(
    filter(isOfType(subscribeToPriceChangesForSelectedAssetTag.type)),
    switchMap((action: any) =>
      state$.pipe(
        map((state: State) => ({ assetTag: getMarketOverviewAssetTag(state) })),
        take(1),
        switchMap(({ assetTag }) => [
          assetTag === ASSET_TAGS.CRYPTO
            ? subscribeToCryptoPriceChanges({ codes: action.payload.codes })
            : subscribeToSecurityPriceChanges({ codes: action.payload.codes }),
        ]),
      ),
    ),
  );

const mapGeneralUnsubscribeActionToCorrectByAssetTag: RootEpic = (action$: any, state$) =>
  action$.pipe(
    filter(isOfType(unsubscribeFromPriceChangesForSelectedAssetTag.type)),
    switchMap(() =>
      state$.pipe(
        map((state: State) => ({ assetTag: getMarketOverviewAssetTag(state) })),
        take(1),
        switchMap(({ assetTag }) => [
          assetTag === ASSET_TAGS.CRYPTO
            ? unsubscribeFromCryptoPriceChanges()
            : unsubscribeFromSecurityPriceChanges(),
        ]),
      ),
    ),
  );

// If there is subscribe made to securities price changes we can unsubscribe crypto ones
const unsubscribeCryptoOnSecuritiesInit: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType(subscribeToSecurityPriceChanges.type)),
    map(() => unsubscribeFromCryptoPriceChanges()),
  );

// If there is subscribe made to crypto price changes we can unsubscribe securities ones
const unsubscribeSecuritiesOnCryptoInit: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType(subscribeToCryptoPriceChanges.type)),
    map(() => unsubscribeFromSecurityPriceChanges()),
  );

const fetchSecurityPriceChanges: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(subscribeToSecurityPriceChanges.type)),
    filter((action) => !!action?.payload?.codes?.length),
    switchMap((action: any) =>
      interval(SECURITY_PRICE_CHANGE_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              accessToken: getAccessToken(state),
              timePeriod: getSelectedTimePeriod(state),
              isins: getEligiblePriceChangeCodesByAssetClass(
                state,
                action.payload.codes,
                ASSET_CLASSES.SECURITY,
                getSelectedTimePeriod(state),
              ),
            })),
            filter(
              ({ accessToken, timePeriod, isins }) =>
                isPresent(accessToken) && !!timePeriod && !!isins?.length,
            ),
            take(1),
            mergeMap(({ isins, accessToken, timePeriod }) => {
              const query = { Period: InnerTimePeriodToStockEtfRequestParam[timePeriod], isins };
              return ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat(SECURITY_PRICE_CHANGES_ENDPOINT)
                  .concat(queryArrayString(query)),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response', 'changes'),
                map((response: PriceChangeData[]) => {
                  return subscribeToSecurityPriceChangesSuccess({ prices: response, timePeriod });
                }),
              );
            }),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(handleAjaxError(action$, subscribeToSecurityPriceChangesError)),
          ),
        ),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromSecurityPriceChanges.type)))),
      ),
    ),
  );

const fetchCryptoPriceChanges: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(subscribeToCryptoPriceChanges.type)),
    filter((action) => !!action?.payload?.codes?.length),
    switchMap((action: any) =>
      interval(CRYPTO_PRICE_CHANGE_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              accessToken: getAccessToken(state),
              timePeriod: getSelectedTimePeriod(state),
              assetCodes: getEligiblePriceChangeCodesByAssetClass(
                state,
                action.payload.codes,
                ASSET_CLASSES.CRYPTO,
                getSelectedTimePeriod(state),
              ),
            })),
            filter(
              ({ accessToken, timePeriod, assetCodes }) =>
                isPresent(accessToken) && !!timePeriod && !!assetCodes?.length,
            ),
            take(1),
            mergeMap(({ assetCodes, accessToken, timePeriod }) => {
              const query = { Period: InnerTimePeriodToStockEtfRequestParam[timePeriod], assetCodes };
              return ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat(CRYPTO_PRICE_CHANGES_ENDPOINT)
                  .concat(queryArrayString(query)),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response', 'changes'),
                map((response: PriceChangeData[]) => {
                  return subscribeToCryptoPriceChangesSuccess({ prices: response, timePeriod });
                }),
              );
            }),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(handleAjaxError(action$, subscribeToCryptoPriceChangesError)),
          ),
        ),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromCryptoPriceChanges.type)))),
      ),
    ),
  );

export default combineEpics(
  fetchSecurityPriceChanges,
  fetchCryptoPriceChanges,
  mapGeneralSubscribeActionToCorrectByAssetTag,
  mapGeneralUnsubscribeActionToCorrectByAssetTag,
  unsubscribeCryptoOnSecuritiesInit,
  unsubscribeSecuritiesOnCryptoInit,
);
