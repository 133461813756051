import { dateFormatters, timeFormatters } from 'common/utils/formatting';
import { SecurityMarketStatus } from 'types/status';
import { TranslateFunction } from 'types/translate';

const getIcon = (isWeekend: boolean, isHoliday: boolean) => {
  if (isWeekend) return 'icon-calendar';
  if (isHoliday) return 'icon-earth';

  return 'icon-moon';
};

const getText = (
  isWeekend: boolean,
  isHoliday: boolean,
  stockMarketStatus: SecurityMarketStatus,
  t: TranslateFunction,
): string => {
  if (isWeekend) return t('stockMarketHours.weekend.banner');
  if (isHoliday)
    return t('stockMarketHours.holiday.banner', {
      date: dateFormatters.formatMediumLocalDate(new Date()),
    });

  return t('stockMarketHours.night.banner', {
    start: timeFormatters.formatShortTime(stockMarketStatus.tradingHoursStartAt),
    close: timeFormatters.formatShortTime(stockMarketStatus.tradingHoursCloseAt),
  });
};

const getBannerDetails = (
  isWeekend: boolean,
  isHoliday: boolean,
  stockMarketStatus: SecurityMarketStatus,
  t: TranslateFunction,
) => ({
  icon: getIcon(isWeekend, isHoliday),
  text: getText(isWeekend, isHoliday, stockMarketStatus, t),
});

export default getBannerDetails;
