import { v4 as uuid } from 'uuid';
import * as storage from 'services/storage';

export const DELAY_SESSION_CHECK = 1000;
export const SID_TIMESTAMP = 'lastActiveSession';

const SESSION_TTL = 15 * 60 * 1000;
export const SID = 'SID';

export const newSessionId = (timestamp: string, guid: string): string => {
  const newSID = `SID_${timestamp}_${guid}`;
  // session can be 36 chars max
  return newSID.substr(0, 36);
};

export const getActiveSessionId = (): string | null | undefined => {
  const { response } = storage.getItem(SID);
  return response?.item;
};

export const sessionIsNotValid = (now: number, lastActiveTime: number): boolean => {
  return now - lastActiveTime > SESSION_TTL || !lastActiveTime;
};

export const getSessionData = (): number[] => {
  const now = Date.now();
  const { response } = storage.getItem(SID_TIMESTAMP);
  const lastActiveTime = response && Number(response.item);
  return [now, lastActiveTime];
};

export const getNewSessionTimeStamp = (): number => Date.now();
export const getNewSessionId = (): string => uuid().replace(/-/g, '') as string;

const setNewSessionData = (): void => {
  const timestamp = getNewSessionTimeStamp();
  const guid = getNewSessionId();
  const newSID = newSessionId(timestamp, guid);
  storage.setItem(SID_TIMESTAMP, timestamp);
  storage.setItem(SID, newSID);
};

// we call this at the first app load
export const sessionVerifyAndReset = (): void => {
  const lastActiveSession = getSessionData();
  if (sessionIsNotValid(...lastActiveSession)) {
    setNewSessionData();
  }
};

export default newSessionId;
