import { kycStatuses } from 'common/const';
import { useSelector } from 'react-redux';
import { getKycStatus, getMobileNumber } from 'store/selectors/auth';
import { getIsEIdKycMethod } from 'store/selectors/forms';
import { getHasClosedEidPopup } from 'store/selectors/settings';

/**
 * Hook that calculates the display state of the add phone number flow modal.
 */
function useAddPhoneNumberFlowDisplay(): boolean {
  const cryptoKycStatus = useSelector(getKycStatus);
  const mobilePhone = useSelector(getMobileNumber);
  const isEIdKycMethod = useSelector(getIsEIdKycMethod);
  const hasClosedEidPopup = useSelector(getHasClosedEidPopup);

  if (cryptoKycStatus !== kycStatuses.Confirmed || mobilePhone || !isEIdKycMethod || hasClosedEidPopup)
    return false;

  return true;
}

export default useAddPhoneNumberFlowDisplay;
