// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BisonLogo, Wrapper, Header } from './styles';

const ErrorScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <BisonLogo />
        <Header>{t('error.genericApp')}</Header>
      </Wrapper>
    </>
  );
};

export default ErrorScreen;
