import styled from 'styled-components';

export const HeaderText = styled.h3`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 28px 24px;

  & > *:first-child {
    margin-right: 16px;
    width: inherit;
  }
`;

export const LoaderWrapper = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  backdrop-filter: blur(10px);

  ${({ $active }: { $active: boolean }) => !$active && ' display: none;'};
`;
