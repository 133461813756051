import React, { ReactNode, useEffect } from 'react';
import * as braze from '@braze/web-sdk';
import { BrazeCustomEvents, LogCustomEventFunction } from './types';
import { BrazeContext } from './context';

type WindowTyped = {
  env: {
    BRAZE_API_KEY: string;
    BRAZE_ENDPOINT: string;
  };
};

const BrazeProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    const windowTyped = window as unknown as WindowTyped;

    const apiKey = windowTyped.env.BRAZE_API_KEY;
    const endpoint = windowTyped.env.BRAZE_ENDPOINT;

    if (!apiKey || !endpoint) {
      console.warn('BRAZE NOT INITIALIZED!');
      return undefined;
    }

    braze.initialize(apiKey, {
      baseUrl: endpoint,
      allowUserSuppliedJavascript: true,
    });

    braze.automaticallyShowInAppMessages();

    braze.openSession();

    return () => {
      braze.destroy();
    };
  }, []);

  const onLogCustomEvent: LogCustomEventFunction = <T extends keyof BrazeCustomEvents>(
    type: T,
    payload: BrazeCustomEvents[T],
  ) => {
    if (!type) return;

    braze.logCustomEvent(type, payload);
  };

  const getBrazeUser = () => braze.getUser();

  const setBrazeUser = (userId: string) => {
    if (!userId) return;

    const currentUserId = braze.getUser()?.getUserId();

    if (userId !== currentUserId) {
      braze.changeUser(userId);
    }
  };

  return (
    <BrazeContext.Provider value={{ logCustomEvent: onLogCustomEvent, getBrazeUser, setBrazeUser }}>
      {children}
    </BrazeContext.Provider>
  );
};

export default BrazeProvider;
