/* eslint-disable no-case-declarations */
import * as ActionTypes from 'store/actions/status';
import { statusValues } from 'common/const';
import { UTC_DEFAULT_TRADING_HOURS } from 'common/const/time';
import { AnyAction } from 'redux';
import { StatusState } from 'store/types/status';
import { addHours, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const initialState: StatusState = {
  status: statusValues.ok,
  checkingStatus: false,
  submoduleStatus: [],
  message: {
    en: '',
    de: '',
  },
  stockMarketStatus: {
    isHoliday: false,
    isMarketOpen: false,
    isWeekend: false,
    lastUpdated: '',
    tradingHoursCloseAt: '',
    tradingHoursStartAt: '',
  },
  checkingStockMarketStatus: false,
  statusInitialized: false,
};

export default function status(state: StatusState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.CHECK_BISON_STATUS.REQUEST:
      return {
        ...state,
        checkingStatus: true,
      };
    case ActionTypes.CHECK_BISON_STATUS.SUCCESS:
      return {
        ...state,
        status: action.response.status ? action.response.status : initialState.status,
        message: action.response.message ? action.response.message : initialState.message,
        submoduleStatus: action.response.submoduleStatus || [],
        checkingStatus: false,
      };
    case ActionTypes.CHECK_BISON_STATUS.FAILURE:
      return {
        ...initialState,
      };
    case ActionTypes.SYNC_STOCK_MARKET_STATUS:
      return {
        ...state,
        checkingStockMarketStatus: true,
      };
    case ActionTypes.SYNC_STOCK_MARKET_STATUS_FAILURE:
      return {
        ...state,
        stockMarketStatus: {
          ...state.stockMarketStatus,
          lastUpdated: new Date().toUTCString(),
        },
        checkingStockMarketStatus: false,
      };
    case ActionTypes.UPDATE_STOCK_MARKET_STATUS:
      const { tradingHoursCloseAt, tradingHoursStartAt } = action.response;
      const parsedResponse = { ...action.response };

      if (!tradingHoursCloseAt || !tradingHoursStartAt) {
        parsedResponse.tradingHoursStartAt = zonedTimeToUtc(
          addHours(startOfDay(new Date()), UTC_DEFAULT_TRADING_HOURS.START),
          'UTC',
        ).toUTCString();
        parsedResponse.tradingHoursCloseAt = zonedTimeToUtc(
          addHours(startOfDay(new Date()), UTC_DEFAULT_TRADING_HOURS.END),
          'UTC',
        ).toUTCString();
      }

      return {
        ...state,
        stockMarketStatus: parsedResponse,
        checkingStockMarketStatus: false,
      };
    default:
      return state;
  }
}
