import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import { Theme } from 'types/theme';

const AccountMenuContainer = styled.div.attrs<{ open: boolean }>((props) => ({
  className: props.open ? 'open' : '',
}))`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${dimensions.mediumPadding}px 0;

  @media ${devices.maxTablet} {
    flex-direction: column;

    &.open {
      padding: ${dimensions.largePadding + dimensions.mediumPadding}px 0 ${dimensions.mediumPadding}px 0;
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  top: ${dimensions.mediumPadding}px;
  right: ${dimensions.smallPadding}px;

  @media ${devices.maxTablet} {
    top: 16px;
    right: ${dimensions.smallPadding}px;
    display: none;

    ${AccountMenuContainer}.open & {
      display: block;
    }
  }
`;

export const ToggleTitle = styled.span.attrs<{ disabled?: boolean }>(({ disabled }) => ({
  className: disabled ? 'large-text disabled' : 'large-text',
}))`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary} !important;
  opacity: ${({ disabled, theme }: { disabled: boolean; theme: Theme }) =>
    disabled ? theme.opacity.hover : theme.opacity.none};
  margin-right: ${dimensions.smallPadding}px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  & div div {
    height: unset;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleSwitchWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -10px;
`;
