/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import { RootEpic } from 'types/common';
import { catchError, filter, map, pluck, switchMap, take } from 'rxjs/operators';
import { getAccessToken } from 'store/selectors/auth';
import { handleAjaxError } from 'store/epics/auth';
import I18n from 'i18next';
import {
  fetchPendingOrdersFailure,
  fetchPendingOrdersRequest,
  fetchPendingOrdersSuccess,
  invalidateStakingState,
  stakeUnstakeFlowRequest,
  stakeUnstakeFlowRequestFailure,
  stakeUnstakeFlowRequestSuccess,
  stakingChartError,
  stakingChartRequest,
  stakingChartSuccess,
  stakingInfoFailure,
  stakingInfoRequest,
  stakingInfoSuccess,
  stakingNextRewardFailure,
  stakingNextRewardRequest,
  stakingNextRewardSuccess,
  stakingRewardsHistoryFailure,
  stakingRewardsHistoryRequest,
  stakingRewardsHistorySuccess,
  stakingRewardsNextPageRequest,
  updateLastPointOfChart,
} from 'store/slices/staking/actions';
import { isOfType, isPresent } from 'safetypings';
import { combineEpics } from 'redux-observable';
import { fetchPortfolioSuccess, invalidatePortfolio } from 'store/slices/portfolio/actions';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { PendingOrder, StakingChartPoint, StakingNextRewardResponse, StakingReward } from 'types/staking';
import { State } from 'store/types/store';
import { SCROLL_PAGE_SIZE, timePeriodToPortfolioChartTime } from 'common/const';
import {
  getStakingNextRewardInfoForAllCryptos,
  getStakingRewardsFullyLoaded,
  getStakingRewardsReference,
  selectIsStakingInfoLastFetchedTime,
} from 'store/slices/staking/selectors';
import { getSelectedTimePeriod } from 'store/selectors/currency';
import { getLastPointOfChartFromTotalSum, shouldLoadStakingInfo } from 'common/utils/staking';
import { getAvailableCrypto } from 'store/slices/portfolio/selectors';
import { LOGOUT_USER } from 'store/actions/auth';

export const fetchStakingInfo: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(stakingInfoRequest.match),
    switchMap(() =>
      state$.pipe(
        map((state) => ({
          accessToken: getAccessToken(state),
          shouldLoadData: shouldLoadStakingInfo(selectIsStakingInfoLastFetchedTime(state)),
        })),
        filter(({ accessToken, shouldLoadData }) => Boolean(accessToken && shouldLoadData)),
        take(1),
        switchMap(({ accessToken }) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat(`/staking/info`),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(pluck('response'), map(stakingInfoSuccess)),
        ),
        catchError(
          handleAjaxError(
            action$,
            (error) => {
              console.error(error);
              return stakingInfoFailure({ errMsg: I18n.t('error.genericNetwork') });
            },
            {},
            { message: I18n.t('error.genericNetwork') },
          ),
        ),
      ),
    ),
  );

export const stakeUnstakeFlowEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(stakeUnstakeFlowRequest.match),
    switchMap(({ payload: { type, assetCode, volume } }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat(`/staking/${type.toLowerCase()}`),
            method: 'POST',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: {
              volume,
              assetCode,
              // assetCode: capitalizeFirstLetter(assetCode),
            },
          }).pipe(switchMap(() => [stakeUnstakeFlowRequestSuccess(), invalidatePortfolio()])),
        ),
        catchError(
          handleAjaxError(
            action$,
            (error) => {
              console.error(error);
              return stakeUnstakeFlowRequestFailure({ errMsg: I18n.t('error.genericNetwork') });
            },
            {},
            { message: I18n.t('error.genericNetwork') },
          ),
        ),
      ),
    ),
  );

export const fetchStakingRewardsHistory: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(stakingRewardsHistoryRequest.match),
    switchMap(({ payload: filters }) =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);
          if (!accessToken) return undefined;

          return {
            accessToken,
            params: {
              size: filters.size ?? SCROLL_PAGE_SIZE,
            },
          };
        }),
        filter(isPresent),
        take(1),
        switchMap(({ accessToken, params }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat(`/staking/reward-history/`)
              .concat(queryArrayString(params)),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response: { result: StakingReward[]; hasMore: boolean }) =>
              stakingRewardsHistorySuccess({ orders: response.result, hasMore: response.hasMore }),
            ),
          ),
        ),
        catchError(
          handleAjaxError(
            action$,
            (error) => {
              console.error(error);
              return stakingRewardsHistoryFailure({ errMsg: I18n.t('error.genericNetwork') });
            },
            {},
            { message: I18n.t('error.genericNetwork') },
          ),
        ),
      ),
    ),
  );

const stakingRewardsLoadNextPageEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(stakingRewardsNextPageRequest.match),
    switchMap(({ payload: filters }) =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);

          if (!accessToken) return undefined;

          const isFullyLoaded = getStakingRewardsFullyLoaded(state);

          if (isFullyLoaded) return undefined;

          const reference = getStakingRewardsReference(state);

          return {
            accessToken,
            params: {
              ...filters,
              size: filters.size ?? SCROLL_PAGE_SIZE,
              reference,
            },
          };
        }),
        filter(isPresent),
        take(1),
        switchMap(({ accessToken, params }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat(`/staking/reward-history/`)
              .concat(queryArrayString(params)),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response: { result: StakingReward[]; hasMore: boolean }) =>
              stakingRewardsHistorySuccess({ orders: response.result, hasMore: response.hasMore }),
            ),
          ),
        ),
        catchError(
          handleAjaxError(
            action$,
            (error) => {
              console.log(error);
              return stakingRewardsHistoryFailure({ errMsg: I18n.t('error.genericNetwork') });
            },
            {},
            { message: I18n.t('error.genericNetwork') },
          ),
        ),
      ),
    ),
  );

export const fetchStakingChart: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(stakingChartRequest.match),
    switchMap(() =>
      state$.pipe(
        map((state) => ({
          accessToken: getAccessToken(state),
          params: { timePeriod: timePeriodToPortfolioChartTime[getSelectedTimePeriod(state)] },
        })),
        filter(isPresent),
        take(1),
        switchMap(({ accessToken, params }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat(`/staking/chart`)
              .concat(queryArrayString(params)),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response', 'stakingChart'),
            map((stakingChart: StakingChartPoint[]) => {
              const instruments = getAvailableCrypto(state$.value);
              stakingChart.pop();
              stakingChart.push(getLastPointOfChartFromTotalSum(instruments));

              return stakingChartSuccess(stakingChart);
            }),
          ),
        ),
        catchError(
          handleAjaxError(
            action$,
            (error) => {
              console.log(error);
              return stakingChartError({ errMsg: I18n.t('error.genericNetwork') });
            },
            {},
            { message: I18n.t('error.genericNetwork') },
          ),
        ),
      ),
    ),
  );

export const listenToInstrumentsUpdate: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchPortfolioSuccess.match),
    pluck('payload', 'response', 'instruments'),
    map(() => {
      const instruments = getAvailableCrypto(state$.value);

      return updateLastPointOfChart(getLastPointOfChartFromTotalSum(instruments));
    }),
  );

export const fetchNextRewardData: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(stakingNextRewardRequest.match),
    switchMap(({ payload: { assetCode } }) =>
      state$.pipe(
        map((state) => ({
          accessToken: getAccessToken(state),
          shouldLoadData: shouldLoadStakingInfo(
            getStakingNextRewardInfoForAllCryptos(state)[assetCode]?.lastFetchedAt,
          ),
        })),
        filter(({ accessToken, shouldLoadData }) => Boolean(accessToken && shouldLoadData)),
        take(1),
        switchMap(({ accessToken }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat(`/staking/next-reward`)
              .concat(queryArrayString({ assetCode })),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((payload: StakingNextRewardResponse) => stakingNextRewardSuccess({ assetCode, ...payload })),
            catchError(
              handleAjaxError(
                action$,
                (error) => stakingNextRewardFailure({ assetCode }),
                {},
                { message: I18n.t('error.genericNetwork') },
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const fetchPendingUnstakesEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(fetchPendingOrdersRequest.match),
    switchMap(() =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);
          if (!accessToken) return undefined;
          return accessToken;
        }),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat(`/staking/pending-orders`),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response: { pendingOrders: PendingOrder[] }) =>
              fetchPendingOrdersSuccess({ items: response.pendingOrders }),
            ),
          ),
        ),
        catchError(
          handleAjaxError(
            action$,
            (error) => fetchPendingOrdersFailure({ errMsg: I18n.t('error.genericNetwork') }),
            {},
            { message: I18n.t('error.genericNetwork') },
          ),
        ),
      ),
    ),
  );

const watchLogout: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType([LOGOUT_USER])),
    map(() => invalidateStakingState()),
  );

export default combineEpics(
  fetchStakingInfo,
  stakeUnstakeFlowEpic,
  fetchStakingRewardsHistory,
  stakingRewardsLoadNextPageEpic,
  fetchStakingChart,
  fetchNextRewardData,
  fetchPendingUnstakesEpic,
  listenToInstrumentsUpdate,
  watchLogout,
);
