import { Dispatch, SetStateAction, useState } from 'react';

export type UseTwoFaType = {
  twoFaState: [boolean, Dispatch<SetStateAction<boolean>>];
  twoFaError: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
  twoFaValue: [string, Dispatch<SetStateAction<string>>];
};

export const useTwoFa = (): UseTwoFaType => {
  const twoFaState = useState(false);
  const twoFaError = useState<string | undefined>(undefined);
  const twoFaValue = useState('');

  return {
    twoFaState,
    twoFaError,
    twoFaValue,
  };
};
