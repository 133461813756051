import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { subscribeToCryptoPrices, unsubscribeFromCryptoPrices } from 'store/slices/prices/actions';

const useTradingManagerCreationPriceSubscription = (assetCodes: string | string[]) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!assetCodes) return;

    dispatch(unsubscribeFromCryptoPrices());
    dispatch(subscribeToCryptoPrices({ codes: Array.isArray(assetCodes) ? [...assetCodes] : [assetCodes] }));

    return () => {
      dispatch(unsubscribeFromCryptoPrices());
    };
  }, [assetCodes]);
};

export default useTradingManagerCreationPriceSubscription;
