import styled from 'styled-components';
import { Theme } from 'types/theme';

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 12px;
  background-color: ${({ color }: { color?: string }) => color ?? '#000000'};
`;

// used for headerSubTitle prop
export const DefaultSubTitle = styled.div.attrs({ className: 'small-text strong-weight' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.inactive};
`;

// golden text in headerSubTitle
export const Status = styled.div.attrs({ className: 'small-text strong-weight' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.pending};
  display: inline;
`;

// default elements for children rows

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
`;

export const DefaultTitle = styled.div.attrs({ className: 'small-text' })``;

export const DefaultValue = styled(DefaultTitle).attrs({ className: 'strong-weight' })``;

export const LargeTitleAndValue = styled.div.attrs({ className: 'xlarge-text strong-weight' })``;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: 2px 0 15px;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.progressIndicator};
  opacity: 0.4;
`;

export const SpacerBox = styled.div`
  ${({ sizeInPx }: { sizeInPx: number }) => `padding-bottom: ${sizeInPx}px;`}
`;

export const GreyChip = styled.div.attrs({ className: 'xxsmall-text strong-weight' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryLight}bf;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.action}bf;
  padding: 4px;
  border-radius: 3px;
`;

// Action area
export const InfoWrap = styled.div`
  width: 100%;
`;
export const Info = styled.div.attrs({ className: 'small-text' })`
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.action};
  padding: 16px;
  border-radius: 4px;
`;

export const LegalText = styled.div.attrs({ className: 'small-text' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.logo};
`;

export const SupportLink = styled.a.attrs({ className: 'small-text' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
  text-decoration: none !important;
`;
