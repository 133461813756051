// @flow
import TagManager from 'react-gtm-module-nonce';
import I18n from 'i18next';

// Docs of event definitions
// https://docs.google.com/spreadsheets/d/1dk-CjOltB3n_Fy5E3Jvik7FJrDP4j0L4E7kN12cfd6c/edit#gid=1106597857

type Mode = 'RealMoneyTrading' | 'PaperTrading';
type Action = 'Buy' | 'Sell';

function logEvent(name: string, params?: any) {
  TagManager.dataLayer({
    dataLayer: {
      category: 'Bison Web',
      event: name,
      ...params,
      lang: I18n.t('l10n.lang'),
    },
  });
}

export function startIdentification() {
  logEvent('Start_Identification');
}

export function tutorialSkip() {
  logEvent('Tutorial_Skip');
}

export function tutorialDone() {
  logEvent('Tutorial_Done');
}

export function kycStarted(pathname: string) {
  logEvent('click_event', { event_category: 'Verification', event_action: 'start', event_label: pathname });
}

export function kycViewedStep(step: number, stepName: string) {
  logEvent('new_screen', { step, stepName });
}

export function kycCompleted() {
  logEvent('Kyc_Completed');
}

// Event 4
export function startRegistration() {
  logEvent('Start_Registration');
}

// Event 5
export function createAccount() {
  logEvent('Create_Account');
}

// Event 6
export function firstLogin(userId: any) {
  logEvent('First_Login', { user_id: userId });
}

// Event 7
export function login() {
  logEvent('Login');
}

export const limitOrderSelectCrypto = (pathname) => {
  logEvent('click_event', {
    event_category: 'Limit Order',
    event_action: 'Step 1',
    event_label: pathname,
  });
};

export const limitOrderSend = ({ action, currency }: { action: Action; currency: Crypto }) => {
  logEvent('click_event', {
    event_category: 'Limit Order',
    event_action: 'Step 2',
    event_label: `${action}:${currency}`,
  });
};

export const limitOrderReview = ({
  action,
  currency,
  priceLimit,
  amount,
  euro,
}: {
  action: Action;
  currency: Crypto;
  priceLimit: number;
  amount: number;
  euro: number;
}) => {
  logEvent('click_event', {
    event_category: 'Limit Order',
    event_action: 'Step 3',
    event_label: `${action}|${currency}|${priceLimit}|${amount}|${euro}`,
  });
};

export const limitOrderConfirm = ({
  action,
  currency,
  priceLimit,
  amount,
  euro,
}: {
  action: Action;
  currency: Crypto;
  priceLimit: number;
  amount: number;
  euro: number;
}) => {
  logEvent('click_event', {
    event_category: 'Limit Order',
    event_action: 'Confirm',
    event_label: `${action}|${currency}|${priceLimit}|${amount}|${euro}`,
  });
};

export const limitOrderDeletePreview = () => {
  logEvent('click_event', {
    event_category: 'Limit Order',
    event_action: 'Delete step 1',
    event_label: ``,
  });
};

export const limitOrderDeleteConfirm = () => {
  logEvent('click_event', {
    event_category: 'Limit Order',
    event_action: 'Delete step 2',
    event_label: ``,
  });
};

// Events 8-24 154-156, 159-161, 163
// Covers all screen opens and logs also the screen from which the user came and the current mode the user is in
export function screenOpen({
  screenName,
  mode,
  verified,
  title,
  params,
  userId,
  ...rest
}: {
  screenName: string;
  mode: Mode;
  verified: string;
  title: string;
  params: any;
  userId: any;
}) {
  logEvent(`new_screen`, {
    screen_path: screenName,
    modus: mode,
    verified,
    screen_title: title,
    ...params,
    user_id: userId,
    ...rest,
  });
}

// Events 26-49
export function priceChart(currency: Crypto, mode: Mode, action: Action) {
  logEvent('PriceChart', { currency, mode, action });
}

// Events 58-141
export function buySell({
  entity,
  mode,
  orderType,
  price,
  userId,
}: {
  entity: Crypto;
  mode: Mode;
  orderType: Action;
  price: number;
  userId: any;
}) {
  logEvent('basket', {
    mode,
    product: entity,
    product_variant: orderType,
    product_price: price,
    user_id: userId,
  });
}

export function buySellSuccess({
  entity,
  mode,
  orderType,
  value,
  confirmationFlowId,
  price,
  firstTransaction,
  userId,
}: {
  entity: Crypto;
  mode: Mode;
  value: number;
  orderType: Action;
  confirmationFlowId: string;
  price: number;
  firstTransaction: string;
  userId: any;
}) {
  const spent = `${price}`;
  logEvent('success', {
    product: entity,
    mode,
    product_variant: orderType,
    transactionId: confirmationFlowId,
    price: spent,
    first_transaction: firstTransaction,
    user_id: userId,
    value,
  });
}

export function logOpenVerification(pathname: string, userId: any) {
  logEvent('click_event', {
    event_category: 'verification',
    event_action: 'open id now online-ident',
    event_label: pathname,
    user_id: userId,
  });
}

export function buySellFailure(currency: Crypto, mode: Mode, action: Action) {
  logEvent('BuySellFailure', { currency, mode, action });
}

// Events 158, 162
export function changeMode(newAccountType: string, eventCategory: string, currentPath: string, userId: any) {
  logEvent('click_event', {
    event_category: eventCategory,
    event_action: 'open',
    event_label: currentPath,
    user_id: userId,
  });
}

// Events 166, 167
export function moneyWithdraw(button: 'OkMoveOn' | 'PayOutNow') {
  logEvent('MoneyWithdraw', { button });
}

// Events 168, 169
export function cryptoWithdraw(button: 'OkMoveOn' | 'SendPayoutRequest') {
  logEvent('CryptoWithdraw', { button });
}

export function buttonClick(eventCategory: string, currentPath: string, eventAction: string, mode: Mode) {
  logEvent('click_event', {
    event_category: eventCategory,
    event_action: eventAction,
    event_label: currentPath,
    mode,
  });
}
