/* eslint-disable import/no-cycle */
/* eslint-disable prefer-destructuring */

//
// This file contains tracking handlers that attach to existing ACTIONS.
//
import { take, takeEvery, call, fork, all, select, race } from 'redux-saga/effects';

import { matchPathToUrl } from 'common/urls';
import { formatScreenProps } from 'common/utils/formatting';
import { auth } from 'store/actions/auth';
import {
  FINISH_IDENTIFICATION,
  kycViewedStep,
  KYC_VIEWED_STEP,
  START_IDENTIFICATION,
} from 'store/actions/forms';
import getCurrentPath from 'store/selectors/navigation';

import * as biEvents from '../biEventDefinitions';
import { getKycFormData, getKycFormMeta } from 'store/selectors/forms';
import { LOCATION_CHANGE } from 'store/actions/navigation';

// ********************************* HELPERS *********************************

// ********************************* WATCHERS **********************************

function* logScreenOpen(action: { componentName: string }, fromScreenName: string): Generator<any, any, any> {
  const toScreen = matchPathToUrl(action.componentName);
  const fromScreen = matchPathToUrl(fromScreenName);
  const toScreenProps = formatScreenProps(toScreen.match, 'objectId');
  const fromScreenProps = formatScreenProps(fromScreen.match, 'screen');
  if (JSON.stringify(toScreen) !== JSON.stringify(fromScreen)) {
    yield call(
      biEvents.trackDisplayOpenScreen,
      toScreen.path,
      fromScreen.path,
      toScreenProps,
      fromScreenProps,
    );
  }
}

function* logLoginAttempt(requestAction: ReturnType<typeof auth.request>): Generator<any, any, any> {
  const screenId = yield select(getCurrentPath);
  const { success, failure } = yield race({
    success: take('AUTH_SUCCESS'),
    failure: take('AUTH_FAILURE'),
  });
  if (success) {
    const accountType = success.response.accountType;
    const loginMethod = requestAction.request.credentials.type;
    const askedFor2FA = !!requestAction.request.twofa;
    yield call(biEvents.trackLoginSuccessEventLogin, screenId, accountType, loginMethod, askedFor2FA);
  } else if (failure) {
    const accountType = requestAction.request.accountType as string;
    const loginMethod = requestAction.request.credentials.type;
    const exceptionCode = failure?.error?.code || 'not present';
    const askedFor2FA = failure?.error?.code === 'login_err_notconfirm2fa';
    yield call(
      biEvents.trackLoginErrorEventLogin,
      screenId,
      accountType,
      loginMethod,
      exceptionCode,
      askedFor2FA,
    );
  }
}

function* logKycViewedStep(action: ReturnType<typeof kycViewedStep>): Generator<any, any, any> {
  const { step, stepName } = action;
  const screenId = yield select(getCurrentPath);
  const screenIdWithKycStepId = `${screenId}.${stepName}`;
  const formData = yield select(getKycFormData);
  const { birthCountry, country, nationality } = formData;
  const formMetaData = yield select(getKycFormMeta);
  const verificationCategory = formMetaData.verificationCategory;
  yield call(
    biEvents.trackKycOpenStep,
    stepName,
    screenIdWithKycStepId,
    birthCountry,
    country,
    nationality,
    verificationCategory,
  );
}

function* logStartIdentification(): Generator<any, any, any> {
  const screenId = yield select(getCurrentPath);
  const { currentStepName } = yield select(getKycFormMeta);
  const screenIdWithKycStepId = `${screenId}.${currentStepName}`;
  yield call(biEvents.trackKycOpenSdkIdNow, screenIdWithKycStepId);
}

function* logFinishIdentification(): Generator<any, any, any> {
  const screenId = yield select(getCurrentPath);
  const { currentStepName } = yield select(getKycFormMeta);
  const screenIdWithKycStepId = `${screenId}.${currentStepName}`;
  yield call(biEvents.trackKycCloseSdkIdNow, screenIdWithKycStepId);
}

function* watchSimpleAnalyticsActions(): Generator<any, any, any> {
  yield takeEvery('AUTH_REQUEST', logLoginAttempt);
  yield takeEvery(KYC_VIEWED_STEP, logKycViewedStep);
  yield takeEvery(START_IDENTIFICATION, logStartIdentification);
  yield takeEvery(FINISH_IDENTIFICATION, logFinishIdentification);
}

function* watchScreenChange(): Generator<any, any, any> {
  while (true) {
    const fromScreenName = yield select(getCurrentPath);
    const { location } = yield take(LOCATION_CHANGE);
    yield call(logScreenOpen, { componentName: location.pathname }, fromScreenName);
  }
}

export default function* eventsFromActionsWatcher(): Generator<any, any, any> {
  yield all([fork(watchScreenChange), fork(watchSimpleAnalyticsActions)]);
}
