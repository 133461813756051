import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Asset, AssetTag, MarketOverviewOrderBy } from 'types/assets';
import { InboxCategory } from 'types/inbox';
import { FILTERS_SLICE_NAME } from 'common/const/slices';
import { invalidateInbox } from '../inbox/actions';
import { loadMarketOverviewAssets, loadMarketOverviewAssetsSuccess } from '../common/actions';

const filtersSlice = createSlice({
  name: FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    setMarketOverviewCategory(state, action: PayloadAction<AssetTag>) {
      state.marketOverview.assetTag = action.payload;
      state.marketOverview.searchAssetCodes = [];
      state.marketOverview.visibleAssetCodes = [];
    },
    setMarketOverviewSearchText(state, action: PayloadAction<string>) {
      state.marketOverview.searchText = action.payload;
      state.marketOverview.searchAssetCodes = [];
    },
    setMarketOverviewOrderBy(state, action: PayloadAction<MarketOverviewOrderBy>) {
      state.marketOverview.order = action.payload;
      state.marketOverview.searchAssetCodes = [];
    },
    setMarketOverviewVisibleItems(state, action: PayloadAction<{ items: string[] }>) {
      state.marketOverview.visibleAssetCodes = action.payload.items;
    },
    onMarketOverviewUnmount(state) {
      state.marketOverview.searchText = '';
      state.marketOverview.searchAssetCodes = [];
      state.marketOverview.visibleAssetCodes = [];
    },
    selectInboxCategory(state, action: PayloadAction<InboxCategory>) {
      state.inbox.selectedCategory = action.payload;
    },
    resetMarketOverviewFilter(state) {
      state.marketOverview = initialState.marketOverview;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loadMarketOverviewAssets, (state, { payload }) => {
        // reset searchAssetCodes upon forceReload
        if (payload?.forceReload) {
          state.marketOverview.searchAssetCodes = [];
        }
      })
      .addCase(loadMarketOverviewAssetsSuccess, (state, { payload }) => {
        state.marketOverview.searchAssetCodes = [
          ...state.marketOverview.searchAssetCodes,
          ...payload.assets.map((asset: Asset) => asset.code.toLowerCase()),
        ];
      })
      .addCase(invalidateInbox, (state) => {
        state.inbox = initialState.inbox;
      });
  },
});

export default filtersSlice;
