import { Theme } from 'types/theme';
import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import Button from 'common/components/Button';

export const BodyWrapper = styled.div.attrs({ className: 'background-primary' })``;

export const FormOuterWrapper = styled.div`
  margin: 0 auto;

  @media ${devices.tablet} {
    flex-wrap: nowrap;
  }
  @media ${devices.laptopL} {
    max-width: ${dimensions.maxContentWidth}px;
  }
`;

export const MainContentWrapper = styled.div.attrs({ className: 'background-secondary' })`
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div``;

export const Head = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PriceAlarmHead = styled(Head)`
  align-items: baseline;
`;

export const Text = styled.div.attrs({ className: 'xxsmall-text regular-weight' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryLight};
  font-style: normal;
  letter-spacing: -0.02em;
`;

export const TitleText = styled.h3`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  padding-bottom: 26px;

  & > *:first-child {
    margin-right: 16px;
    width: inherit;
  }
`;

export const DeleteIconWrapper = styled.div`
  padding-right: 24px;
  padding-bottom: 26px;
`;

export const CourseText = styled.div.attrs({ className: 'standard-text' })``;

export const FittedCourseText = styled(CourseText)`
  white-space: normal;
`;

export const CourseAmountText = styled.span.attrs({ className: 'xlarge-text strong-weight' })<{
  $isPriceMaxDeviated?: boolean;
  theme: Theme;
}>`
  ${({ $isPriceMaxDeviated = false, theme }: { $isPriceMaxDeviated?: boolean; theme: Theme }) =>
    $isPriceMaxDeviated && `color: ${theme.color.foreground.caution}`}
`;

export const Separator = styled.div`
  border-bottom: ${({ theme }: { theme: Theme }) =>
    `${theme.border.width.standard} solid ${theme.color.border.standard}`};
  margin: 20px 0;
`;

export const SummaryWrapper = styled.div`
  padding-top: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  gap: 24px;

  & div {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  flex: 0 0 45%;
`;

export const DeleteButtonWrapper = styled.div`
  & > div {
    margin: 0;
  }

  padding-top: 15px;

  @media ${devices.tablet} {
    padding-top: 20px;
  }
`;

export const Form = styled.form`
  & > div {
    padding-bottom: 24px;
  }
`;

export const TypeSwitchWrapper = styled.div`
  padding: 24px 0;
  @media ${devices.maxMobileS} {
    padding: ${dimensions.smallIndent}px 0;
  }
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 35px;
`;

export const DisplayName = styled.div.attrs({
  className: 'strong-weight xlarge-text primary-color-text',
})``;

export const AdvancedSettingsButton = styled(Button)`
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize} !important;
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize} !important;
`;
