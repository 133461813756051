interface DimensionsReturnType {
  width: string | null;
  height: string | null;
}
export const getWindowDimensions = (): DimensionsReturnType => {
  if (document) {
    const body = document.getElementsByTagName('body');
    if (body && body[0]) {
      const dimensions = body[0].getBoundingClientRect();
      return { width: dimensions.width.toString(), height: dimensions.height.toString() };
    }
  }
  return { width: null, height: null };
};
