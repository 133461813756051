import { State } from 'store/types/store';
import { createSelector } from '@reduxjs/toolkit';
import { getSelectedTimePeriod } from 'store/selectors/currency';
import { PORTFOLIO_CHART_SLICE_NAME } from 'common/const/slices';

export const getPortfolioChart = (state: State) => state[PORTFOLIO_CHART_SLICE_NAME];

export const getPortfolioChartBySelectedTimePeriod = createSelector(
  [getPortfolioChart, getSelectedTimePeriod],
  (portfolioChart, timePeriod) => portfolioChart?.[timePeriod],
);
