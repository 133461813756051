import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { errorMessages } from 'common/apiErrors';
import _ from 'lodash';

export default () => {
  if (!window?.env?.SENTRY_DSN) return;

  // remove for testing sentry locally
  if (window.location.hostname === 'localhost') return;

  Sentry.init({
    dsn: window.env.SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // In production we are sending 2% of errors cause of possible undesirable load for our production app
    // https://docs.sentry.io/platforms/javascript/guides/bun/configuration/sampling/
    tracesSampleRate: window.env.ENVIRONMENT === 'production' ? 0.02 : 1.0,
    environment: window.env.ENVIRONMENT,
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb?.category === 'xhr' && breadcrumb?.data?.status_code === 0 ? null : breadcrumb;
    },
  });
};

export function responseInterceptor(response: AxiosResponse<any>) {
  return response;
}

export function errorResponseInterceptor(error: any) {
  try {
    // Don't log cancel request and Check if there is an actual response (otherwise the request has been cancelled)
    if (error?.code !== 'ECONNABORTED' && 'response' in error) {
      const status = `${_.get(error, 'response.status', '')}`;
      const code = `${_.get(error, 'code', '')}`;
      const unknownCode = !errorMessages[code];

      if (status !== '0' || !code || unknownCode) {
        // Create message log to sentry if this is an unexpected error
        const requestInfo = {
          method: `${error.config.method}`,
        };
        const requestHeaders = {
          ...error.response.headers,
          authorization: undefined,
          nonce: undefined,
        };
        const responseInfo = {
          code: !unknownCode ? code : 'no code',
          status,
          data: error.response.data,
        };

        Sentry.withScope((scope) => {
          scope.setExtras({
            request: JSON.stringify(requestInfo),
            requestHeaders: JSON.stringify(requestHeaders),
            response: JSON.stringify(responseInfo),
          });
          scope.setTag('status', status);
          Sentry.captureMessage(`Unexpected API error: ${error.response.request.responseURL}`);
        });
      }
    }
  } catch (err) {
    console.log('Error preparing API log data for Sentry', err);
  }

  return Promise.reject(error);
}
