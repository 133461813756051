/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AnyAction } from 'redux';
import * as ActionTypes from 'store/actions/notificationBanner';
import { NotificationState } from 'store/types/notification';

const initialState: NotificationState = [];
let initId = 0;
const getnewId = () => {
  initId += 1;
  return initId;
};

function root(state: NotificationState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.NOTIFY_ERROR:
      return [{ id: getnewId(), banner: 'error', displayedOnce: false, ...action.props }, ...state];
    case ActionTypes.NOTIFY_WARN:
      return [{ id: getnewId(), banner: 'warn', displayedOnce: false, ...action.props }, ...state];
    case ActionTypes.NOTIFY_INFO:
      return [{ id: getnewId(), banner: 'info', displayedOnce: false, ...action.props }, ...state];
    case ActionTypes.NOTIFY_SUCCESS:
      return [{ id: getnewId(), banner: 'success', displayedOnce: false, ...action.props }, ...state];
    case ActionTypes.CLEAR_NOTIFICATION:
      // clear all notifications if id is not given
      if (action.id === undefined) {
        return [];
      }
      return state.reduce((acc, value) => {
        const valueToDelete = action.id.filter((item) => item.id === value.id)[0];
        if (!valueToDelete) {
          acc.push(value);
        }
        return acc;
      }, []);
    case ActionTypes.DISPLAY_NOTIFICATION:
      // notification can be displayed only ones
      return state.reduce((acc, value) => {
        if (value.id === action.payload.id) {
          acc.push({ ...value, displayedOnce: true });
          return acc;
        }
        acc.push(value);
        return acc;
      }, []);
    default:
      return state;
  }
}

export default root;
