// @flow
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators } from 'redux';
import { makeGetlNotification, getNotificationMap } from 'store/selectors/notification';
import { clearNotification } from 'store/actions/notificationBanner';

import { State } from 'store/types/store';
import { WithRouterType } from 'types/withRouter';
import { Dispatch } from 'react';
import NotificationBanner from './view';

const mapStateToProps = (state: State, props: WithRouterType) => {
  const getAllNotifications = makeGetlNotification();
  const notifications = getAllNotifications(state, props);
  return {
    notifications,
    lastNotification: notifications[0],
    notificationsMap: getNotificationMap(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  clearNotification: bindActionCreators(clearNotification, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBanner);
