import { cryptoAdapter, securityAdapter } from './adapters';

export const getEntitiesInitialState = () => ({
  crypto: cryptoAdapter.getInitialState(),
  securities: securityAdapter.getInitialState(),
});

export type AssetEntitiesState = ReturnType<typeof getEntitiesInitialState> & {
  areAssetsLoading: boolean;
  isMasterDataLoading: boolean;
};

export const initialState: AssetEntitiesState = {
  areAssetsLoading: false,
  isMasterDataLoading: false,
  ...getEntitiesInitialState(),
};
