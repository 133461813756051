/* eslint-disable no-case-declarations, @typescript-eslint/no-unsafe-return */
import { ORDER_STATUS_TYPES, Order, OrderData, OrderPreview, ReservedQuote } from 'types/securityOrder';
import { AnyAction, combineReducers } from 'redux';
import * as actionTypes from 'store/actions/stockEtfOrder';
import { ORDER_STATUS } from 'common/const/stockOrder';

const initialData = { isFetching: false, data: { orderPreview: {}, auxInfo: {}, marketInfo: {} } };

const orderData = (state: Order = initialData, action: AnyAction): Order => {
  switch (action.type) {
    case actionTypes.INIT_SECURITY_ORDER_REQUEST:
      return { isFetching: true, data: {} };
    case actionTypes.INIT_SECURITY_ORDER_SUCCESS:
      return { isFetching: false, data: action.payload };
    case actionTypes.STOCK_ETF_PRICE_QUOTE_REQUEST:
      return { isFetching: true, data: state.data };
    case actionTypes.STOCK_ETF_PRICE_QUOTE_SUCCESS:
      return { isFetching: false, data: { ...state.data, ...action.payload } };
    case actionTypes.RESET_ORDER:
      return initialData;
    default:
      return state;
  }
};

const initialReservedQuote = {
  isQuoteFetching: false,
  isQuoteFetchingSuccess: false,
  data: {},
  quoteValidForInSeconds: undefined,
};

const reservedQuote = (state: ReservedQuote = initialReservedQuote, action: AnyAction): ReservedQuote => {
  switch (action.type) {
    case actionTypes.RESERVE_SECURITY_PRICE_QUOTE_REQUEST:
      return {
        ...state,
        isQuoteFetching: true,
        isQuoteFetchingSuccess: false,
        data: { ...state.data } as OrderPreview,
      };
    case actionTypes.RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS:
      return {
        ...state,
        isQuoteFetching: false,
        isQuoteFetchingSuccess: true,
        data: action.payload as OrderPreview,
      };
    case actionTypes.RESERVE_STOCK_ETF_PRICE_QUOTE_ERROR:
      return {
        ...state,
        isQuoteFetching: false,
        isQuoteFetchingSuccess: false,
        data: { ...state.data } as OrderPreview,
      };
    case actionTypes.RESET_ORDER:
    case actionTypes.CLEAR_RESERVED_ORDER:
      return initialReservedQuote;
    case actionTypes.UPDATE_QUOTE_VALID_FOR:
      return {
        ...state,
        quoteValidForInSeconds: action.payload.time,
      };
    default:
      return state;
  }
};

const orderStatus = (
  state: ORDER_STATUS_TYPES = ORDER_STATUS.FORM,
  action: AnyAction,
): ORDER_STATUS_TYPES => {
  switch (action.type) {
    case actionTypes.RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS:
      return ORDER_STATUS.CONFIRM_PRICE;
    case actionTypes.ACCEPT_SECURITY_PRICE_QUOTE_SUCCESS:
      return ORDER_STATUS.BUY_SELL_SUCCESS;
    case actionTypes.ACCEPT_SECURITY_PRICE_QUOTE_ERROR:
      return ORDER_STATUS.BUY_SELL_ERROR;
    case actionTypes.ACCEPT_SECURITY_PRICE_QUOTE_REQUEST:
      return ORDER_STATUS.BUY_SELL_PENDING;
    case actionTypes.MANUAL_ORDER_STATUS_CHANGE:
      return action.payload.status as ORDER_STATUS_TYPES;
    case actionTypes.RESERVE_STOCK_ETF_PRICE_QUOTE_ERROR:
      return ORDER_STATUS.QUOTE_ERROR;
    case actionTypes.RESET_ORDER:
      return ORDER_STATUS.FORM;
    default:
      return state;
  }
};

export default combineReducers({ orderData, reservedQuote, orderStatus });
