import { BrazeContextType } from 'providers/Braze/types';

/**
 * Used for providing the Braze instance to the epic middleware.
 */
let brazeInstance: BrazeContextType | undefined = undefined;

export const setBrazeInstance = (braze: BrazeContextType) => {
  brazeInstance = braze;
};

export const getBrazeInstance = () => {
  if (!brazeInstance) {
    console.error('Braze instance has not been set');
    return;
  }

  return brazeInstance;
};

/**
 * Determines whether a login failed event should be logged to Braze based on the status code.
 *
 * This function returns `true` if the provided `statusCode` is greater than or equal to 500,
 * indicating a server error. It returns `false` for all other status codes.
 *
 * @param {number} statusCode - The HTTP status code to evaluate.
 * @returns {boolean} - Returns `true` if the status code is 500 or greater, otherwise `false`.
 */
export const shouldLogLoginFailedEventToBraze = (statusCode: number): boolean =>
  !!statusCode && (statusCode >= 500 || statusCode === 404);
