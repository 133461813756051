import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
  padding-left: 12px;
`;

export const ButtonWrapper = styled.div`
  max-width: 343px;
  width: 100%;

  & > div {
    margin: 0;
    min-width: unset;
  }
`;

export const Spacer = styled.div`
  padding-bottom: 100px;
`;
