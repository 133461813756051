import * as React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationBanner from 'common/components/NotificationBanner';
import EnhanceWithTheme from 'common/utils/theme/EnhanceWithTheme';
import { BisonLogo, Wrapper, Header } from './styles';

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <>
      <NotificationBanner />
      <Wrapper>
        <BisonLogo />
        <Header>{t('maintenance.text')}</Header>
      </Wrapper>
    </>
  );
};
// TODO: Remove EnhanceWithTheme when theme provider is merged.
export default EnhanceWithTheme(Maintenance);
