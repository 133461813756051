import React, { FunctionComponent, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ASSET_CLASSES } from 'types/assets';
import Button from 'common/components/Button';
import CryptoInfoWithIconModal from 'common/components/Modal/CryptoInfoWithIconModal';
import StakingData from 'common/components/StakingData';
import {
  BodyWrapper,
  ButtonWrapper,
  Link,
  NoteText,
  StakeTypes,
  SummaryRow,
  SummaryText,
} from 'common/components/Modal/Staking/StakingConfirmModal/styles';
import { ParsedStakingOverviewItem } from 'types/staking';
import { ModalProps } from 'types/modals';
import { useSelector } from 'react-redux';
import { getAvailableCrypto } from 'store/slices/portfolio/selectors';
import { useAsset } from 'common/hooks/useAsset';
import { getCryptoAssets } from 'store/slices/assets/selectors';
import { extractStakingOverviewItem } from 'scenes/StakingOverview/helpers';
import { selectStakingInfoAllCryptos } from 'store/slices/staking/selectors';
import { requiredDocumentsForStakingConsent } from 'common/const/consent';

type Props = {
  onConfirm: () => void;
  amount: string;
  assetCode: string;
} & ModalProps;

const StakingConfirmModal: FunctionComponent<Props> = ({ onConfirm, amount, assetCode, onClose }) => {
  const { t } = useTranslation();
  const asset = useAsset(assetCode, ASSET_CLASSES.CRYPTO, undefined);
  const cryptoInstruments = useSelector(getAvailableCrypto);
  const crypto = cryptoInstruments.find((item) => item.entity.toLowerCase() === assetCode);
  const cryptoAssets = useSelector(getCryptoAssets);
  const stakingInfo = useSelector(selectStakingInfoAllCryptos);

  const stakingData: ParsedStakingOverviewItem | undefined =
    crypto && extractStakingOverviewItem(crypto, cryptoAssets, stakingInfo[crypto.entity.toLowerCase()]);

  return (
    <CryptoInfoWithIconModal
      hideShortnameInTitle
      open
      headerSubTitle={<StakeTypes>{t('staking.confirmPopup.stakeType')}</StakeTypes>}
      displayName={asset?.displayName ?? ''}
      currency={assetCode}
      actions={
        <ButtonWrapper>
          <Button
            data-test-id="staking.confirmPopup.confirm"
            title={t('staking.confirmPopup.confirm')}
            size="large"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          />
        </ButtonWrapper>
      }
      onClose={onClose}
    >
      <BodyWrapper>
        <StakingData hideButtonInfo stakingInfo={stakingData} cryptoInfo={asset} />
        <SummaryRow>
          <SummaryText>{t('staking.confirmPopup.stakeType')}</SummaryText>
          <SummaryText>{`${amount} ${asset?.code.toUpperCase()}`}</SummaryText>
        </SummaryRow>
        <NoteText>
          <Trans
            i18nKey="staking.confirmPopup.note"
            values={{ assetCode: asset?.code.toUpperCase() }}
            components={{
              1: <Link href={requiredDocumentsForStakingConsent.specialConditions.link} target="_blank" />,
            }}
          />
        </NoteText>
      </BodyWrapper>
    </CryptoInfoWithIconModal>
  );
};

export default memo(StakingConfirmModal);
