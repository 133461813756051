/* eslint-disable @typescript-eslint/no-unsafe-return */
import { combineEpics } from 'redux-observable';
import { fromEvent, merge, timer } from 'rxjs';
import {
  switchMap,
  tap,
  ignoreElements,
  throttleTime,
  mapTo,
  pluck,
  filter,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import {
  getNewSessionTimeStamp,
  getNewSessionId,
  newSessionId,
  sessionIsNotValid,
  getSessionData,
  SID_TIMESTAMP,
  SID,
  DELAY_SESSION_CHECK,
} from 'common/utils/session';
import * as actions from 'store/actions/misc';
import * as loginActions from 'store/actions/auth';
import * as storage from 'services/storage';
import { getUser } from 'store/selectors/auth';
import { ofType } from 'redux-observable';
import * as authActions from '../actions/auth';

const ACTIVE_USER = 'activeUser';

const element$ = document.getElementById('root');
const touchMoveEvent$ = fromEvent(element$, 'touchmove');
const mouseMoveEvent$ = fromEvent(element$, 'mousemove');
const windowEvent$ = fromEvent(window, 'storage');

const generateNewSession = (action$) =>
  action$.pipe(
    ofType(actions.SET_NEW_SESSION_ID),
    map(() => {
      const timestamp = getNewSessionTimeStamp();
      const guid = getNewSessionId();
      return [timestamp, guid];
    }),
    tap((args) => {
      const newSID = newSessionId(...args);
      storage.setItem(SID_TIMESTAMP, args[0]);
      storage.setItem(SID, newSID);
    }),
    mapTo(actions.watchSessionTimeout()),
  );

const onLogout = (action$) =>
  action$.pipe(
    ofType(loginActions.LOGOUT_USER),
    tap(() => {
      storage.removeItem(ACTIVE_USER);
    }),
    mapTo(actions.setNewSessionId()),
  );

const onLogin = (action$, state$) =>
  state$.pipe(
    pluck('auth', 'user', 'hasTokensInKeychain'),
    distinctUntilChanged(),
    filter(Boolean),
    tap(() => {
      const activeUser = getUser(state$.value);
      storage.setItem(ACTIVE_USER, activeUser.username);
    }),
    ignoreElements(),
  );

const watchLogout = () =>
  windowEvent$.pipe(
    filter(({ key, newValue }) => key === ACTIVE_USER && !newValue),
    mapTo(loginActions.logoutUser()),
  );

const watchReload = () =>
  windowEvent$.pipe(
    filter(({ key, newValue }) => key === ACTIVE_USER && newValue),
    tap(() => window.location.reload()),
    ignoreElements(),
  );

const updateSessionTimestamp = (action$) =>
  action$.pipe(
    ofType(actions.WATCH_SESSION_TIMEOUT),
    switchMap(() =>
      merge(mouseMoveEvent$, touchMoveEvent$).pipe(
        throttleTime(2000),
        tap(() => {
          const timestamp = getNewSessionTimeStamp();
          storage.setItem(SID_TIMESTAMP, timestamp);
        }),
        ignoreElements(),
      ),
    ),
  );
// watch session validity after initial auth
const startWatchingSessionValidity = (action$) =>
  action$.pipe(ofType(loginActions.AUTH_INIT_DONE), mapTo(actions.watchSessionValidity()));

// if session is valid  after the app INIT
// here we just need to watch validity
const onLoadSessionIsValid = (action$) =>
  action$.pipe(
    ofType(loginActions.AUTH_INIT_DONE),
    map(() => getSessionData()),
    filter((args) => !sessionIsNotValid(...args)),
    mapTo(actions.watchSessionTimeout()),
  );

const checkSessionExpiration = (action$) =>
  action$.pipe(
    ofType(actions.WATCH_SESSION_VALIDITY),
    switchMap(() =>
      timer(0, DELAY_SESSION_CHECK).pipe(
        map(() => {
          const now = getNewSessionTimeStamp();
          const { response } = storage.getItem(SID_TIMESTAMP);
          const lastActiveTime = response && Number(response.item);
          return [now, lastActiveTime];
        }),
        filter((args) => sessionIsNotValid(...args)),
        map(() => authActions.logoutUser()),
      ),
    ),
  );

export default combineEpics(
  checkSessionExpiration,
  onLoadSessionIsValid,
  startWatchingSessionValidity,
  updateSessionTimestamp,
  watchReload,
  generateNewSession,
  onLogin,
  onLogout,
  watchLogout,
);
