/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AnyAction } from 'redux';
import { RootAction, RootState } from 'types/common';
import * as AuthActionTypes from 'store/actions/auth';
import * as FormsActionTypes from 'store/actions/forms';
import * as RenewKycDocsActionTypes from 'store/actions/renewKycDocs';
import * as resetPasswordActions from 'store/actions/resetPassword';
import * as emailConfirmActions from 'store/actions/emailConfirm';
import { REQUEST, SUCCESS } from 'store/actions/_common';

export const actionTransformer = (action: RootAction): AnyAction => {
  switch (action.type) {
    // remove sensitive info from actions
    case 'persist/REHYDRATE':
    case AuthActionTypes.WAIT_CONFIRMATION:
    case AuthActionTypes.CHANGE_ACCOUNT.FAILURE:
    case AuthActionTypes.AUTH.SUCCESS:
    case AuthActionTypes.TOKEN_REFRESH.SUCCESS:
    case AuthActionTypes.TOKEN_VALIDATION.SUCCESS:
    case FormsActionTypes.CHANGE_USERNAME_CONFIRM.SUCCESS:
    case FormsActionTypes.MOBILE_NUMBER_ASSIGN.SUCCESS:
    case FormsActionTypes.MOBILE_NUMBER_DELETE.SUCCESS:
    case FormsActionTypes.SUBMIT_LOGIN_USER:
    case FormsActionTypes.SUBMIT_RESET_PASSWORD:
    case FormsActionTypes.SUBMIT_REGISTER_USER:
    case FormsActionTypes.SUBMIT_CHANGE_PASSWORD:
    case FormsActionTypes.START_CHANGE_USERNAME:
    case FormsActionTypes.START_CHANGE_ADDRESS:
    case FormsActionTypes.START_DELETE_MOBILE:
    case FormsActionTypes.CONFIRM_DELETE_MOBILE:
    case FormsActionTypes.START_ASSIGN_MOBILE:
    case FormsActionTypes.CONFIRM_ASSIGN_MOBILE:
    case FormsActionTypes.CONFIRM_USERNAME_CHANGE:
    case FormsActionTypes.TAX_ID_CHANGE:
    case FormsActionTypes.CONFIRM_ADDRESS_CHANGE:
    case FormsActionTypes.PUT_NEW_ADDRESS_DATA:
    case FormsActionTypes.IBAN_CHANGE_START:
    case FormsActionTypes.IBAN_CHANGE_FINISH:
    case FormsActionTypes.GET_TAX_RESIDENCY[SUCCESS]:
    case FormsActionTypes.CREATE_TAX_RESIDENCY[SUCCESS]:
    case FormsActionTypes.PUT_TAX_RESIDENCY[SUCCESS]:
    case FormsActionTypes.DELETE_TAX_RESIDENCY[REQUEST]:
    case FormsActionTypes.DELETE_TAX_RESIDENCY[SUCCESS]:
    case FormsActionTypes.UPDATE_KYC_DATA:
    case FormsActionTypes.SET_VERIFICATION_DOCUMENT:
    case FormsActionTypes.CHANGE_USERNAME_CONFIRM[REQUEST]:
    case FormsActionTypes.CHANGE_USERNAME_CONFIRM[SUCCESS]:
    case FormsActionTypes.KYC_FORM_SUBMIT[REQUEST]:
    case FormsActionTypes.KYC_FORM_SUBMIT[SUCCESS]:
    case FormsActionTypes.KYC_FORM_EXIT[REQUEST]:
    case FormsActionTypes.KYC_FORM_EXIT[SUCCESS]:
    case FormsActionTypes.KYC_DATA[REQUEST]:
    case FormsActionTypes.KYC_DATA[SUCCESS]:
    case FormsActionTypes.SAVE_SECURITIES_KYC_DATA:
    case FormsActionTypes.SECURITIES_KYC_FORM_SUBMIT[REQUEST]:
    case FormsActionTypes.SECURITIES_KYC_FORM_SUBMIT[SUCCESS]:
    case FormsActionTypes.START_CHANGE_IBAN[REQUEST]:
    case FormsActionTypes.START_CHANGE_IBAN[SUCCESS]:
    case FormsActionTypes.TAX_ID_CHANGE[REQUEST]:
    case FormsActionTypes.TAX_ID_CHANGE[SUCCESS]:
    case FormsActionTypes.CONFIRM_CHANGE_IBAN[REQUEST]:
    case FormsActionTypes.CONFIRM_CHANGE_IBAN[SUCCESS]:
    case FormsActionTypes.SUBMIT_2FA_CODE[SUCCESS]:
    case FormsActionTypes.SUBMIT_2FA_CODE[REQUEST]:
    case FormsActionTypes.MOBILE_NUMBER_ASSIGN[REQUEST]:
    case FormsActionTypes.MOBILE_NUMBER_ASSIGN[SUCCESS]:
    case FormsActionTypes.CONFIRM_DEVICE_CODE[REQUEST]:
    case FormsActionTypes.CONFIRM_DEVICE_CODE[SUCCESS]:
    case FormsActionTypes.SUBMIT_2FA_CODE_START:
    case FormsActionTypes.SEND_2FA_CODE:
    case FormsActionTypes.PAIR_DEVICE_START:
    case FormsActionTypes.STORE_RENEW_KYC_FORM_DATA:
    case RenewKycDocsActionTypes.START_KYC_RENEW_PROCESS_SUCCESS:
    case resetPasswordActions.FETCH_USER_DETAILS_SUCCESS:
    case emailConfirmActions.SEND_CONFIRM_EMAIL_SUCCESS:
    case emailConfirmActions.SEND_CONFIRM_EMAIL_FAILURE:
    case emailConfirmActions.SEND_CONFIRM_EMAIL_CHANGE_SUCCESS:
    case emailConfirmActions.SEND_CONFIRM_EMAIL_CHANGE_FAILURE:
      return {
        type: action.type,
      };

    default:
      return action;
  }
};

const REDACTED = '';

// Prepares the redux state tree to be reported to sentry for debugging
// we throw out sensitive info, and larger data sets (inbox, order history, price charts)
export const stateTransformer = (state: RootState) => ({
  ...state,
  ...{
    auth: {
      ...state.auth,
      tokens: REDACTED,
      twofa: REDACTED,
      user: {
        ...state.auth.user,
        id: REDACTED,
        username: REDACTED,
        firstName: REDACTED,
        lastName: REDACTED,
        name: REDACTED,
        mobileNumber: REDACTED,
        address: REDACTED,
      },
    },
    forms: {
      ...state.forms,
      kycMeta: REDACTED,
      kycFormData: REDACTED,
      stocksKycMeta: REDACTED,
      stocksKycFormData: REDACTED,
      renewKycFormData: REDACTED,
      taxResidency: REDACTED,
      passwordReset: REDACTED,
    },
    currency: {
      ...state.currency,
      portfolio: REDACTED,
      priceByPair: REDACTED,
      tradingVolume: REDACTED,
    },
    persist: REDACTED,
    deviceMonitoring: REDACTED,
    misc: REDACTED,
    money: {
      ...state.money,
      cryptoAddresses: REDACTED,
      transactionHistory: REDACTED,
      bankStatement: REDACTED,
    },
    orders: {
      ...state.orders,
      reservation: REDACTED,
      quote: REDACTED,
    },
    inbox: {
      ...state.inbox,
      messages: REDACTED,
      messagesWithDocuments: REDACTED,
    },
    devices: {
      ...state.devices,
      list: REDACTED,
    },
    stockEtfPriceHistory: {
      ...state.stocksEtfPriceHistory,
      chart: {
        ...state.stocksEtfPriceHistory.chart,
        data: REDACTED,
      },
      ticker: {
        ...state.stocksEtfPriceHistory.ticker,
        data: REDACTED,
      },
      stockPriceChanges: {
        ...state.stocksEtfPriceHistory.stockPriceChanges,
        data: REDACTED,
      },
    },
    activity: {
      ...state.activity,
      entities: REDACTED,
    },
  },
});
