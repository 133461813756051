import styled from 'styled-components';
import { dimensions } from 'common/styles';

export const ModalFooter = styled.div`
  width: 100%;
`;

export const ExitIntentHeader = styled.div.attrs({
  className: 'large-text strong-weight primary-color-text',
})``;

export const ExitIntentText = styled.div.attrs({
  className: 'small-text primary-color-text',
})`
  padding-top: ${dimensions.btnMargin}px;
`;
