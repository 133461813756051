import React, { ReactElement } from 'react';
import StopOrderCreationView from 'scenes/StopOrderCreation/StopOrderCreationView';
import { StopOrderCreationContextProvider } from 'scenes/StopOrderCreation/StopOrderCreationContext';

const StopOrderCreation = (): ReactElement => {
  return (
    <StopOrderCreationContextProvider>
      <StopOrderCreationView />
    </StopOrderCreationContextProvider>
  );
};

export default StopOrderCreation;
