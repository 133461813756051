import { ASSET_TAGS } from 'types/assets';

export const MARKET_OVERVIEW_SEARCH_PARAMS_CATEGORY_NAME = 'category';

export const MARKET_OVERVIEW_SEARCH_PARAMETER_COMMODITIES = 'commodities';
export const MARKET_OVERVIEW_SEARCH_PARAMETER_SHORTS = 'shorts';
export const MARKET_OVERVIEW_SEARCH_PARAMETER_STOCKS = 'stocks';
export const MARKET_OVERVIEW_SEARCH_PARAMETER_ETFS = 'etfs';
export const MARKET_OVERVIEW_SEARCH_PARAMETER_CRYPTO = 'crypto';

export const MARKET_OVERVIEW_ALLOWED_SEARCH_PARAMS_CATEGORIES = {
  [MARKET_OVERVIEW_SEARCH_PARAMETER_COMMODITIES]: ASSET_TAGS.COMMODITY,
  [MARKET_OVERVIEW_SEARCH_PARAMETER_SHORTS]: ASSET_TAGS.LONG_SHORT,
  [MARKET_OVERVIEW_SEARCH_PARAMETER_STOCKS]: ASSET_TAGS.STOCK,
  [MARKET_OVERVIEW_SEARCH_PARAMETER_ETFS]: ASSET_TAGS.ETF,
  [MARKET_OVERVIEW_SEARCH_PARAMETER_CRYPTO]: ASSET_TAGS.CRYPTO,
};
