import styled from 'styled-components';
import phoneImg from 'assets/img/person-with-phone.png';
import { Theme } from 'types/theme';
import { getHeaderFontStyles } from 'common/styles/global';

export const Title = styled.div.attrs({ className: 'strong-weight' })`
  padding-bottom: 24px;
  ${({ theme }: { theme: Theme }) => getHeaderFontStyles('2', theme)};
  text-align: center;
`;

export const Text = styled.div.attrs({ className: 'small-text' })``;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 12px 44px 27px;
`;
export const Img = styled.img.attrs({ src: phoneImg })`
  width: 217px;
  height: auto;
`;

export const ButtonWrapper = styled.div`
  & > div {
    margin: 0;
  }
`;
