/**
 * This is how often the re-fetching of all price alerts happens.
 *
 * The value is specified in milliseconds.
 */
export const PRICE_ALERTS_FETCH_INTERVAL_IN_MS = 1000 * 10;

/**
 * The delay between each single price alert fetches.
 *
 * The value is specified in milliseconds.
 */
export const PRICE_ALERTS_FETCH_DELAY_IN_MS = 1000 * 60 * 10; // 10min
