import { dimensions } from 'common/styles';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import styled from 'styled-components';

export const ContentWrapper = styled.div``;

export const HeadlineText = styled.h3.attrs({ className: 'strong-weight' })`
  margin-bottom: ${dimensions.modalPadding}px;
  margin-right: 20px;
`;
export const ExitHeadlineText = styled.h4.attrs({ className: 'strong-weight' })`
  margin-right: 20px;
`;

export const TextContainer = styled(ReactMarkdown).attrs({
  className: 'small-text primary-color-text',
})`
  position: relative;
  p {
    font-size: inherit;
    line-height: inherit;
  }
`;

export const ExitTextContainer = styled(TextContainer)`
  margin-top: ${dimensions.smallPadding}px;
  p {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${dimensions.btnMargin}px 0;
  > div {
    margin: 0;
  }
`;
