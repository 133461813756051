import { useSelector } from 'react-redux';
import { getKycFormData } from 'store/selectors/forms';
import {
  getIsB2bUser,
  getIsUserInPaperTrading,
  getKycStatus,
  getSecuritiesKycStatus,
} from 'store/selectors/auth';
import { getFeatureStocksEnabled } from 'store/selectors/environment';
import { getBackendIsEligibleCandidateForStocks, getStocksSwitch } from 'store/selectors/settings';
import { canUseSecurities } from 'common/utils/securities';

type UseSecuritiesType = {
  stocksEnabled: boolean;
  isUserSwitchStocksAvailable: boolean;
  canTradeStocks: boolean;
  isEligibleForStocks: boolean;
};

export const useSecurities = (): UseSecuritiesType => {
  const isFeatureStocksEnabled = useSelector(getFeatureStocksEnabled);
  const isStocksEnabledByUser = useSelector(getStocksSwitch);
  const cryptoKycStatus = useSelector(getKycStatus);
  const stocksKycStatus = useSelector(getSecuritiesKycStatus);
  const isB2bUser = useSelector(getIsB2bUser);
  const isDemo = useSelector(getIsUserInPaperTrading);
  const kycData = useSelector(getKycFormData);
  const backendIsEligibleCandidateForStocks = useSelector(getBackendIsEligibleCandidateForStocks);

  const canUserUseStocks = canUseSecurities(
    isFeatureStocksEnabled,
    isStocksEnabledByUser,
    cryptoKycStatus,
    stocksKycStatus,
    isB2bUser,
    isDemo,
    kycData,
    backendIsEligibleCandidateForStocks,
  );

  return canUserUseStocks;
};
