import { useSelector } from 'react-redux';
import { getKCCRaffleEndDate, getKCCRaffleStartDate } from 'store/selectors/configFeature';
import { matchPath, useLocation, useMatch } from 'react-router-dom';
import {
  getRejectedKansasCityChiefsRaffleTermsForWeeks,
  getDoNotAskForKansasCityChiefsRaffleTerms,
} from 'store/selectors/settings';
import { paths } from 'common/urls';
import { getItem } from 'services/storage';
import { isDateBetween } from 'common/utils/date';
import { isDateInWeeks } from 'common/utils/kccRaffle';
import { KCC_RAFFLE_BANNER_HIDDEN_FOR_WEEKS } from 'common/const/kccRaffle';
import { useSecurities } from 'common/hooks/useSecurities';

type UseStocksType = {
  isDisplayed: boolean;
};

export const useKCCRaffleDisplay = (): UseStocksType => {
  const { pathname } = useLocation();

  const doNotAskForKansasCityChiefsRaffleTerms = useSelector(getDoNotAskForKansasCityChiefsRaffleTerms);
  const rejectedKansasCityChiefsRaffleTermsForWeeks = useSelector(
    getRejectedKansasCityChiefsRaffleTermsForWeeks,
  );

  const { isEligibleForStocks } = useSecurities();

  const { response } = getItem(KCC_RAFFLE_BANNER_HIDDEN_FOR_WEEKS);
  const bannerHiddenForWeeks = response?.item ? JSON.parse(response.item) : null;
  const isBannerHiddenForCurrentWeek = isDateInWeeks(bannerHiddenForWeeks);
  const isRaffleRejectedForThisWeek = isDateInWeeks(rejectedKansasCityChiefsRaffleTermsForWeeks);

  // Should be displayed only on Portfolio page
  const validPaths = isBannerHiddenForCurrentWeek
    ? [paths.PERSONAL_INFO]
    : [paths.PORTFOLIO_CRYPTO, paths.PORTFOLIO];
  const isPathValid = validPaths.some((pagePath) => matchPath({ path: pagePath }, pathname));

  const kccRaffleStartDate = useSelector(getKCCRaffleStartDate);
  const kccRaffleEndDate = useSelector(getKCCRaffleEndDate);

  // Is stocks raffle even active. If not we should not display anything.
  const isRaffleActive = isDateBetween(new Date(), kccRaffleStartDate, kccRaffleEndDate);

  return {
    isDisplayed:
      isEligibleForStocks &&
      isPathValid &&
      isRaffleActive &&
      !doNotAskForKansasCityChiefsRaffleTerms &&
      !isRaffleRejectedForThisWeek,
  };
};
