import { IMPORTANT_MESSAGES_MODAL_ALLOWED_PAGES } from 'common/const/inbox';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { selectIsAnyMessagesImportant } from 'store/slices/inbox/selectors';
import { getIsImportantMessagesModalWasDisplayed } from 'common/utils/importantMessagesSessionStorage';

const isPageAllowed = (path: string): boolean =>
  !IMPORTANT_MESSAGES_MODAL_ALLOWED_PAGES.every(
    (allowedPath: string) => !matchPath({ path: allowedPath, caseSensitive: false }, path),
  );

/**
 * Hook that calculates the display state of the important messages modal.
 */
function useImportantMessagesModal(): boolean {
  const { pathname } = useLocation();

  const isAnyMessageImportant = useSelector(selectIsAnyMessagesImportant);
  const isImportantMessagesModalWasDisplayed = getIsImportantMessagesModalWasDisplayed();

  const isOnAllowedPage = isPageAllowed(pathname);

  return isAnyMessageImportant && isOnAllowedPage && !isImportantMessagesModalWasDisplayed;
}

export default useImportantMessagesModal;
