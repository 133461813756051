import { useSelector } from 'react-redux';
import { getIsUserInPaperTrading, getKycStatus } from 'store/selectors/auth';
import { kycStatuses } from 'common/const';

export const useIsDemoOnlyUser = () => {
  const cryptoKycStatus = useSelector(getKycStatus);
  const cryptoKycStatusConfirmed = cryptoKycStatus === kycStatuses.Confirmed;
  const isDemo = useSelector(getIsUserInPaperTrading);
  return !cryptoKycStatusConfirmed && isDemo;
};
