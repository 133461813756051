import portfolioSlice from '.';
import { createControlActions, createControlTypes } from 'store/actions/_common';

export const {
  fetchPortfolioChartRequest,
  fetchPortfolioChartSuccess,
  fetchPortfolioChartFailure,
  fetchPortfolioChartWithSelectedTimePeriod,
  addLastDotForChartFromNewTotalPortfolioValue,
} = portfolioSlice.actions;

export const SYNC_PORTFOLIO_CHART = createControlTypes('syncPortfolioChart');
export const syncPortfolioChart = createControlActions(SYNC_PORTFOLIO_CHART);
