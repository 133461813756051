import { TaxExemptionState } from 'store/types/taxExemption';

export const initialState: TaxExemptionState = {
  fetchedTaxExemption: {
    isFetching: false,
    data: {
      status: null,
      validFrom: '',
      validTill: '',
      amount: 0,
    },
  },
  editTaxExemptionData: {
    isFetching: false,
    data: {},
  },
};
