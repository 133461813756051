import { getIsStakingAllowed } from 'store/selectors/auth';
import { featureActiveStakingPaths } from 'common/urls';
import { matchPath, useLocation } from 'react-router-dom';
import {
  getIsFeatureSpecificTncInitiallyLoaded,
  getIsFeatureSpecificTncNotAccepted,
} from 'store/selectors/termsAndConditions';
import { CONSENT_TYPES } from 'common/const/consent';
import { showFeatureTermsAndConditions } from 'store/actions/termsAndConditions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const useCheckIfUserEnteredStakingWithoutConsentAndShowTncOrRedirectOnReject = () => {
  const dispatch = useDispatch();
  const isStakingAllowed = useSelector(getIsStakingAllowed);
  const location = useLocation();
  const isActiveStakingInitiallyLoaded = useSelector((state: any) =>
    getIsFeatureSpecificTncInitiallyLoaded(state, CONSENT_TYPES.ACTIVE_STAKING),
  );
  const isPassiveStakingInitiallyLoaded = useSelector((state: any) =>
    getIsFeatureSpecificTncInitiallyLoaded(state, CONSENT_TYPES.PASSIVE_STAKING),
  );

  const isOnStakingRelatedPage = featureActiveStakingPaths.some(
    (pattern) => !!matchPath({ path: pattern }, location.pathname),
  );

  const isStakingFeatureSpecificTncNotAccepted = useSelector((state: any) =>
    getIsFeatureSpecificTncNotAccepted(state, CONSENT_TYPES.ACTIVE_STAKING),
  );

  useEffect(() => {
    if (
      !isStakingAllowed ||
      !isOnStakingRelatedPage ||
      !isStakingFeatureSpecificTncNotAccepted ||
      !isActiveStakingInitiallyLoaded ||
      !isPassiveStakingInitiallyLoaded
    )
      return;
    dispatch(showFeatureTermsAndConditions(true, CONSENT_TYPES.ACTIVE_STAKING));
  }, [
    isStakingFeatureSpecificTncNotAccepted,
    isActiveStakingInitiallyLoaded,
    isPassiveStakingInitiallyLoaded,
    isOnStakingRelatedPage,
    isStakingAllowed,
  ]);
};

export default useCheckIfUserEnteredStakingWithoutConsentAndShowTncOrRedirectOnReject;
