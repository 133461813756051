import styled from 'styled-components';
import { Theme } from 'types/theme';
import { ContainerCenter } from 'common/components/Modal/KCCRaffle/styles';
import { Link } from 'scenes/Welcome/commonStyles';

export const RaffleActions = styled(ContainerCenter)`
  & > *:first-child {
    margin: 0;
  }

  width: 100%;
`;

export const KCCImage = styled.img`
  width: 140px;
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
`;
