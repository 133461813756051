import { useTranslation } from 'react-i18next';
import React from 'react';
import ResultModal, {
  DEFAULT_RESULT_VALUES,
  DerivedModalProps,
} from 'common/components/TwoFaFlow/ResultModal/index';

const SuccessResultModal = ({ open, onClose }: DerivedModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ResultModal
      open={open}
      title={t(DEFAULT_RESULT_VALUES.SUCCESS.title)}
      imgSrc={DEFAULT_RESULT_VALUES.SUCCESS.src}
      onClose={onClose}
    />
  );
};

export default SuccessResultModal;
