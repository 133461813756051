import portfolioSlice from '.';
import { INVALIDATE_ACCOUNT_DATA } from 'store/actions/auth';
import { AnyAction } from 'redux';
import { initialState } from './initialState';

// Resets state on account change
const accountInvalidatesReducer = (reducer) => (state, action: AnyAction) => {
  if (action.type === INVALIDATE_ACCOUNT_DATA) state = initialState; // eslint-disable-line no-param-reassign
  return reducer(state, action);
};

export default accountInvalidatesReducer(portfolioSlice.reducer);
