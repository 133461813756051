import { createEntityAdapter } from '@reduxjs/toolkit';
import { Message, MessageWithDocument } from 'types/inbox';
import { getTime } from 'date-fns';

export const messagesAdapter = createEntityAdapter<Message>({
  // Messages with isNotificationRequired set to true will be
  // shown first, then ordered by date from new to old
  sortComparer: (a, b) => {
    const notificationRequiredDifference =
      Number(b.isNotificationRequired) - Number(a.isNotificationRequired);
    if (notificationRequiredDifference !== 0) {
      return notificationRequiredDifference;
    }

    return getTime(new Date(a.createdAt)) - getTime(new Date(b.createdAt)) > 0 ? -1 : 1;
  },
});

export const messagesWithDocumentsAdapter = createEntityAdapter<MessageWithDocument>({
  sortComparer: (a, b) => (getTime(new Date(a.createdAt)) - getTime(new Date(b.createdAt)) > 0 ? -1 : 1),
});
