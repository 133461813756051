import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { filter, switchMap, take, map, catchError, pluck, skipWhile } from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs';
import { backoff } from 'common/utils/rxjs-operators';
import { State } from 'store/types/store';
import { Action } from 'redux';
import { canUseSecurities } from 'common/utils/securities';
import { getKycFormData } from 'store/selectors/forms';
import { getFeatureStocksEnabled } from 'store/selectors/environment';
import { getBackendIsEligibleCandidateForStocks, getStocksSwitch } from 'store/selectors/settings';
import {
  getAccessToken,
  getIsB2bUser,
  getIsUserInPaperTrading,
  getKycStatus,
  getSecuritiesKycStatus,
} from '../selectors/auth';
import {
  syncStockMarketStatusFailure,
  SYNC_STOCK_MARKET_STATUS,
  updateStockMarketStatus,
} from '../actions/status';

const stockMarketStatusEpic = (
  action$: any,
  state$: StateObservable<State>,
  { ajax, api }: any,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(SYNC_STOCK_MARKET_STATUS)),
    switchMap(() =>
      state$.pipe(
        map((state) => {
          const accessToken = getAccessToken(state);

          // If there is no accessToken we can have an early return since this request cannot be made.
          if (!accessToken) return null;

          const isFeatureStocksEnabled = getFeatureStocksEnabled(state);
          const isStocksEnabledByUser = getStocksSwitch(state);
          const cryptoKycStatus = getKycStatus(state);
          const stocksKycStatus = getSecuritiesKycStatus(state);
          const isB2bUser = getIsB2bUser(state);
          const isDemo = getIsUserInPaperTrading(state);
          const kycData = getKycFormData(state);
          const backendIsEligibleCandidateForStocks = getBackendIsEligibleCandidateForStocks(state);

          const { canTradeStocks } = canUseSecurities(
            isFeatureStocksEnabled,
            isStocksEnabledByUser,
            cryptoKycStatus,
            stocksKycStatus,
            isB2bUser,
            isDemo,
            kycData,
            backendIsEligibleCandidateForStocks,
          );

          // If stocks are enabled then this request can be made so we can just return accessToken so it can be used later.
          if (canTradeStocks) return accessToken;

          // Return null so isPresent filter can then skip it.
          return null;
        }),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat('/stocks/trading-hours'),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
            map((response) => ({ ...response, lastUpdated: new Date().toUTCString() })), // tack on code
            map(updateStockMarketStatus),
          ),
        ),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(5, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(() => of(syncStockMarketStatusFailure())),
      ),
    ),
  );

export default combineEpics(stockMarketStatusEpic);
