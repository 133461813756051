import { ReactNode } from "react";

export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS' as const;
export const NOTIFY_ERROR = 'NOTIFY_ERROR' as const;
export const NOTIFY_WARN = 'NOTIFY_WARN' as const;
export const NOTIFY_INFO = 'NOTIFY_INFO' as const;
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION' as const;
export const DISPLAY_NOTIFICATION = 'DISPLAY_NOTIFICATION' as const;

export type NotificationProps = {
  message: ReactNode | string;
  id?: string;
  onClick?: () => void;
};

export function notifySuccess(props: NotificationProps) {
  return { type: NOTIFY_SUCCESS, props };
}
export function notifyError(props: NotificationProps) {
  return { type: NOTIFY_ERROR, props };
}
export function notifyWarn(props: NotificationProps) {
  return { type: NOTIFY_WARN, props };
}
export function notifyInfo(props: NotificationProps) {
  return { type: NOTIFY_INFO, props };
}

export function displayNotification(id: number) {
  return { type: DISPLAY_NOTIFICATION, payload: { id } };
}

export function clearNotification(id: any) {
  if (!id) {
    return { type: CLEAR_NOTIFICATION, id };
  }
  const ids = id instanceof Array ? id : [id];
  return { type: CLEAR_NOTIFICATION, id: ids };
}
