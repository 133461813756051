import { privatePaths } from 'common/urls';
import { matchPath } from 'react-router-dom';

export const checkIfValidPrivateUrl = (url: string) => {
  let isPrivate = false;

  Object.values(privatePaths).forEach((privatePath) => {
    if (
      matchPath(
        {
          path: privatePath,
          caseSensitive: false,
        },
        url,
      )
    ) {
      isPrivate = true;
    }
  });

  return isPrivate;
};
