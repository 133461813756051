export const CARD_BRAND = {
  VISA: 'visa',
  MASTERCARD: 'mastercard',
} as const;

export const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'fail',
} as const;

export const ALTERNATIVE_PAYMENT_METHODS = {
  GOOGLE_PAY: 'googlePay',
  APPLE_PAY: 'applePay',
} as const;

export const PAYMENT_METHOD = {
  CREDIT_CARD: 'creditCard',
  ...ALTERNATIVE_PAYMENT_METHODS,
} as const;

export const MODAL_STEP = {
  SELECT: 'select',
  ADD: 'add',
} as const;

export const CREDIT_CARD_FORM_FIELDS = {
  CARD_NUMBER: 'cardNumber',
  CARD_EXPIRY: 'cardExpiry',
  CARD_CVC: 'cardCvc',
} as const;

export const STRIPE_ACCOUNT_COUNTRY = 'DE';

export const NUMBER_OF_SAVED_CREDIT_CARDS_TO_SHOW = 3;

export const MIN_MONTH_KYC_CONFIRMED = 3;

export const TOTAL_SHOWS_LAUNCH_POPUP = 3;

export const supportedCardBrands = Object.values(CARD_BRAND);

export const amountSelectOptions = (
  formatter: (label: string) => string,
  maxAmount: number,
): { label: string; value: number; testId?: string }[] => [
  {
    label: formatter('100'),
    value: 100,
    testId: 'deposit.instantTopupFlow.recommendedAmount.100',
  },
  {
    label: formatter('500'),
    value: 500,
    testId: 'deposit.instantTopupFlow.recommendedAmount.500',
  },
  {
    label: formatter('2000'),
    value: 2000,
    testId: 'deposit.instantTopupFlow.recommendedAmount.2000',
  },
  {
    label: 'Max',
    value: maxAmount,
    testId: 'deposit.instantTopupFlow.recommendedAmount.max',
  },
];
