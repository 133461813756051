import styled from 'styled-components';
import { Theme } from 'types/theme';
import { Divider as _Divider } from 'common/components/StakingData/styles';

export const StakeTypes = styled.div.attrs({ className: 'small-text strong-weight' })`
  text-transform: uppercase;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.inactive};
`;

export const BodyWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Divider = styled(_Divider)`
  margin: 2px 0 16px;
`;

export const SummaryRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SummaryText = styled.div.attrs({ className: 'xlarge-text strong-weight' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const NoteText = styled.div.attrs({ className: 'xxsmall-text' })`
  text-align: right;
  max-width: 332px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.logo};
  margin-top: 24px;
`;

export const Link = styled.a.attrs({ className: 'small-text' })`
  text-decoration: none !important;
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};

  &:hover {
    text-decoration: underline !important;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > div {
    margin: 0;
    min-width: 212px;
  }
`;
