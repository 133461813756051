import type { AnonymousLoginSuccess, LoginRequest, LoginSuccess } from 'common/api';
import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './_common';
import { ApiError } from 'types/error';

export const AUTH = createRequestTypes('AUTH');
export const LOCAL_AUTH = createRequestTypes('LOCAL_AUTH');
export const TOKEN_VALIDATION = createRequestTypes('TOKEN_VALIDATION');
export const TOKEN_REFRESH = createRequestTypes('TOKEN_REFRESH');
export const KEYCHAIN_SAVE = createRequestTypes('KEYCHAIN_SAVE');
export const KEYCHAIN_CLEAR = createRequestTypes('KEYCHAIN_CLEAR');
export const CONFIRMATION_MAIL = createRequestTypes('CONFIRMATION_MAIL');
export const CHANGE_ACCOUNT = createRequestTypes('CHANGE_ACCOUNT');

export const WAIT_CONFIRMATION = 'WAIT_CONFIRMATION';
export const RESEND_CONFIRMATION_MAIL = 'RESEND_CONFIRMATION_MAIL';
export const START_RESEND_CONFIRMATION_MAIL = 'START_RESEND_CONFIRMATION_MAIL';
export const CANCEL_WAIT_CONFIRMATION = 'CANCEL_WAIT_CONFIRMATION';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const FETCH_USER_KYC_STATUS_REQUEST = 'FETCH_USER_KYC_STATUS_REQUEST';
export const FETCH_USER_KYC_STATUS_SUCCESS = 'FETCH_USER_KYC_STATUS_SUCCESS';
export const FETCH_USER_KYC_STATUS_FAILURE = 'FETCH_USER_KYC_STATUS_FAILURE';
export const TRY_ANONYMOUS = 'TRY_ANONYMOUS';
export const LOCALLY_LOGIN_USER = 'LOCALLY_LOGIN_USER';
export const AUTH_INIT_DONE = 'AUTH_INIT_DONE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const INVALIDATE_TOKENS = 'INVALIDATE_TOKENS';
export const STORE_AUTH_TOKENS = 'STORE_AUTH_TOKENS';
export const CLEAR_AUTH_TOKENS = 'CLEAR_AUTH_TOKENS';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const INVALIDATE_ACCOUNT_DATA = 'INVALIDATE_ACCOUNT_DATA';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const AUTO_LOGIN_FAILED = 'AUTO_LOGIN_FAILED';
export const RELOAD_USER = 'RELOAD_USER';
export const ALLOW_BETA_ACCESS = 'ALLOW_BETA_ACCESS';
export const START_REGISTRATION = 'START_REGISTRATION';

export const SUBMIT_DELETE_ACCOUNT = 'SUBMIT_DELETE_ACCOUNT';
export const submitDeleteAccount = (payload, onSuccess, onFail) =>
  action(SUBMIT_DELETE_ACCOUNT, {
    payload,
    onSuccess,
    onFail,
  });

export const SUBMIT_DELETE_ACCOUNT_SUCCESS = 'SUBMIT_DELETE_ACCOUNT_SUCCESS';
export const submitDeleteAccountSuccess = (payload) => action(SUBMIT_DELETE_ACCOUNT_SUCCESS, { payload });

export const SUBMIT_DELETE_ACCOUNT_FAILURE = 'SUBMIT_DELETE_ACCOUNT_FAILURE';
export const submitDeleteAccountFailure = (error) => action(SUBMIT_DELETE_ACCOUNT_FAILURE, { error });

export const CHECK_DELETE_REAL_ACCOUNT_SUCCESS = 'CHECK_DELETE_REAL_ACCOUNT_SUCCESS';
export const checkDeleteRealAccountSuccess = (payload) =>
  action(CHECK_DELETE_REAL_ACCOUNT_SUCCESS, { payload });

export const CHECK_DELETE_REAL_ACCOUNT_FAILURE = 'CHECK_DELETE_REAL_ACCOUNT_FAILURE';
export const checkDeleteRealAccountFailure = (props) => action(CHECK_DELETE_REAL_ACCOUNT_FAILURE, { props });

export const CHECK_DELETE_REAL_ACCOUNT = 'CHECK_DELETE_REAL_ACCOUNT';
export const checkDeleteRealAccount = (onIsDeletable, onIsNotDeletable) =>
  action(CHECK_DELETE_REAL_ACCOUNT, {
    onIsDeletable,
    onIsNotDeletable,
  });

export const SUBMIT_DELETE_REAL_ACCOUNT = 'SUBMIT_DELETE_REAL_ACCOUNT';
export const submitDeleteRealAccount = (payload, onSuccess, onFail) =>
  action(SUBMIT_DELETE_REAL_ACCOUNT, {
    payload,
    onSuccess,
    onFail,
  });

export const SUBMIT_DELETE_REAL_ACCOUNT_SUCCESS = 'SUBMIT_DELETE_REAL_ACCOUNT_SUCCESS';
export const submitDeleteRealAccountSuccess = (payload) =>
  action(SUBMIT_DELETE_REAL_ACCOUNT_SUCCESS, { payload });

export const SUBMIT_DELETE_REAL_ACCOUNT_FAILURE = 'SUBMIT_DELETE_REAL_ACCOUNT_FAILURE';
export const submitDeleteRealAccountFailure = (error) =>
  action(SUBMIT_DELETE_REAL_ACCOUNT_FAILURE, { error });

export const anonymousLogin = {
  request: (): AuthRequestAction => action('AUTH_REQUEST') as AuthRequestAction,
  success: (response: AnonymousLoginSuccess): AuthSuccessAction =>
    action('AUTH_SUCCESS', { request: {}, response }),
  failure: (error: ApiError): AuthFailureAction => action('AUTH_FAILURE', { request: {}, error }),
};

export const auth = {
  request: (request: LoginRequest): AuthRequestAction => action('AUTH_REQUEST', { request }),
  success: (request: LoginRequest, response: LoginSuccess): AuthSuccessAction =>
    action('AUTH_SUCCESS', { request, response }),
  failure: (request: LoginRequest, error: ApiError): AuthFailureAction =>
    action('AUTH_FAILURE', { request, error }),
};

type LocalAuthRsp = { clientKey: string };

export const localAuth = {
  request: (username: string) => action(LOCAL_AUTH[REQUEST], { username }),
  success: (username: string, response: LocalAuthRsp) => action(LOCAL_AUTH[SUCCESS], { username, response }),
  failure: (username: string, error: ApiError) => action(LOCAL_AUTH[FAILURE], { username, error }),
};

type ValidationResp = { userId: number; email: string; name: string; address?: string; deviceId: string };

export const tokenValidation = {
  request: (username: string) => action(TOKEN_VALIDATION[REQUEST], { username }),
  success: (username: string, response: ValidationResp) =>
    action(TOKEN_VALIDATION[SUCCESS], { username, response }),
  failure: (username: string, error: ApiError) => action(TOKEN_VALIDATION[FAILURE], { username, error }),
};

type RefreshRsp = { accessToken: string };

export const tokenRefresh = {
  request: (request: LoginRequest) => action(TOKEN_REFRESH[REQUEST], { request }),
  success: (request: LoginRequest, response: RefreshRsp) =>
    action(TOKEN_REFRESH[SUCCESS], { request, response }),
  failure: (request: LoginRequest, error: ApiError, usernameChanged?: boolean) =>
    action(TOKEN_REFRESH[FAILURE], {
      request,
      error,
      usernameChanged,
    }),
};

export const changeAccount = {
  request: (newAccountType: AccountType, target) =>
    action(CHANGE_ACCOUNT.REQUEST, { newAccountType, target }),
  failure: (username: string, newAccountType: sring) =>
    action(CHANGE_ACCOUNT[FAILURE], { username, newAccountType }),
};

type KeychainSaveParams = { username: string; clientKey: any };

export const keychainSave = {
  request: ({ username, clientKey }: KeychainSaveParams) =>
    action(KEYCHAIN_SAVE[REQUEST], { username, clientKey }),
  success: ({ username, clientKey }: KeychainSaveParams, response: any) =>
    action(KEYCHAIN_SAVE[SUCCESS], { username, clientKey, response }),
  failure: ({ username, clientKey }: KeychainSaveParams, error: ApiError) =>
    action(KEYCHAIN_SAVE[FAILURE], { username, clientKey, error }),
};

export const keychainClear = {
  request: (username: string) => action(KEYCHAIN_CLEAR[REQUEST], { username }),
  success: (username: string, response: any) => action(KEYCHAIN_CLEAR[SUCCESS], { username, response }),
  failure: (username: string, error: ApiError) => action(KEYCHAIN_CLEAR[FAILURE], { username, error }),
};

export const confirmationMail = {
  request: () => action(CONFIRMATION_MAIL[REQUEST]),
  success: (_: any, response: any) => action(CONFIRMATION_MAIL[SUCCESS], { response }),
  failure: (_: any, error: ApiError) => action(CONFIRMATION_MAIL[FAILURE], { error }),
};

export const waitConfirmation = (
  username: string,
  secret: string,
  secretType: string,
  displayWaitingScreen: boolean,
) => action(WAIT_CONFIRMATION, { username, secret, secretType, displayWaitingScreen });
export const startRegistration = () => action(START_REGISTRATION);
export const resendConfirmationMail = (sendSecurityCode = false) => {
  return action(RESEND_CONFIRMATION_MAIL, { sendSecurityCode });
};
export const startResendConfirmationMail = ({
  sendSecurityCode,
  secret,
}: {
  sendSecurityCode: boolean;
  secret: string;
}) => action(START_RESEND_CONFIRMATION_MAIL, { sendSecurityCode, secret });
export const cancelWaitConfirmation = () => action(CANCEL_WAIT_CONFIRMATION);
export const loginWith2Fa = ({
  username,
  secret,
  rememberMe,
  accountType,
  twofa,
  actions,
}: {
  username: string;
  password: string;
  rememberMe: boolean;
  accountType: AccountType;
  twofa: any;
}) => {
  return action(LOGIN_USER, {
    username,
    password: secret,
    accountType,
    rememberMe,
    additional: twofa,
    actions,
  });
};
export const loginUser = (
  username: string,
  password: string,
  rememberMe: boolean,
  accountType?: AccountType,
) => action(LOGIN_USER, { username, password, accountType, rememberMe });
export const loginUserSuccess = () => action(LOGIN_USER_SUCCESS);
export const tryAnonymous = () => action(TRY_ANONYMOUS);
export const locallyLoginUser = () => action(LOCALLY_LOGIN_USER);
export const logoutUser = () => action(LOGOUT_USER);
export const logoutUserSuccess = () => action(LOGOUT_USER_SUCCESS);
export const invalidateTokens = (payload?: { usernameChanged?: boolean }) =>
  action(INVALIDATE_TOKENS, { usernameChanged: payload?.usernameChanged });
export const storeAuthTokens = (resolve: (result?: Promise<empty>) => void = () => {}) =>
  action(STORE_AUTH_TOKENS, { resolve });
export const clearAuthTokens = () => action(CLEAR_AUTH_TOKENS);
export const resetErrorMessage = () => action(RESET_ERROR_MESSAGE);
export const invalidateAccountData = () => action(INVALIDATE_ACCOUNT_DATA);
export const authInitError = () => action(AUTH_INIT_ERROR);
export const authInitDone = () => action(AUTH_INIT_DONE);
export const autoLoggingFailed = () => action(AUTO_LOGIN_FAILED);
export const reloadUser = () => action(RELOAD_USER);
export const allowBetaAccess = (allow = true) => action(ALLOW_BETA_ACCESS, { allow });
export const autoLogin = () => action(AUTO_LOGIN);

export const fetchUserKycStatusRequest = (payload) => action(FETCH_USER_KYC_STATUS_REQUEST, { payload });
export const fetchUserKycStatusSuccess = (payload) => action(FETCH_USER_KYC_STATUS_SUCCESS, { payload });
export const fetchUserKycStatusFailure = (payload) => action(FETCH_USER_KYC_STATUS_FAILURE, { payload });
