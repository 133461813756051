import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable';
import { Action } from 'redux';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isPresent } from 'safetypings';
import { from, interval, Observable, of } from 'rxjs';
import I18n from 'i18next';
import * as api from 'common/api';
import { AppReduxEpicMiddleware, State } from 'store/types/store';
import { PriceAlertData } from 'types/alerts';
import { paths } from 'common/urls';
import { handleAjaxError } from './auth';
import * as notificationBannerActions from '../actions/notificationBanner';
import { getAccessToken } from '../selectors/auth';
import {
  PriceAlertCreateAction,
  PriceAlertCreateAction$,
  PriceAlertCreateErrorAction,
  PriceAlertCreateSuccessAction,
  PriceAlertDeleteAction,
  PriceAlertDeleteAction$,
  PriceAlertDeleteErrorAction,
  PriceAlertDeleteErrorAction$,
  PriceAlertDeleteSuccessAction,
  PriceAlertEditAction,
  PriceAlertEditAction$,
  PriceAlertEditErrorAction,
  PriceAlertEditErrorAction$,
  PriceAlertEditSuccessAction,
} from 'store/slices/priceAlerts/action-types';
import {
  createPriceAlert,
  createPriceAlertError,
  createPriceAlertSuccess,
  deletePriceAlert,
  deletePriceAlertError,
  deletePriceAlertSuccess,
  fetchPriceAlerts,
  fetchPriceAlertsError,
  fetchPriceAlertsSuccess,
  subscribeToPriceAlerts,
  subscribeToPriceAlertsError,
  subscribeToPriceAlertsSuccess,
  unsubscribeFromPriceAlerts,
  updatePriceAlert,
  updatePriceAlertError,
  updatePriceAlertSuccess,
} from 'store/slices/priceAlerts/actions';
import { PRICE_ALERTS_FETCH_INTERVAL_IN_MS } from 'common/const/priceAlerts';
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit';
import { generatePath } from 'react-router-dom';
import { AxiosError } from 'axios';
import { shouldPriceAlertFetch } from 'store/slices/priceAlerts/selectors';

// GET ALL

const fetchPriceAlertsIntervalEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: Observable<PayloadAction>,
  state$: StateObservable<State>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(subscribeToPriceAlerts.type),
    switchMap(() =>
      interval(PRICE_ALERTS_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          getPriceAlertFetchObservable(
            action$,
            state$,
            subscribeToPriceAlertsSuccess,
            subscribeToPriceAlertsError,
          ),
        ),
        takeUntil(action$.pipe(ofType(unsubscribeFromPriceAlerts.type))),
      ),
    ),
  );

const fetchPriceAlertsEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: Observable<PayloadAction>,
  state$: StateObservable<State>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(fetchPriceAlerts.type),
    switchMap(() =>
      getPriceAlertFetchObservable(action$, state$, fetchPriceAlertsSuccess, fetchPriceAlertsError, true),
    ),
  );

// UPDATE

const editPriceAlert = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertEditAction$,
  state$: StateObservable<State>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(updatePriceAlert.type),
    filter(({ payload }: PriceAlertEditAction) => !!payload.model.id),
    exhaustMap(({ payload }: PriceAlertEditAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.putPriceAlert(payload.model, accessToken)).pipe(
            pluck('response'),
            map(updatePriceAlertSuccess),
            catchError((error: AxiosError | any) =>
              of(
                updatePriceAlertError({
                  id: payload.model.id ?? 0,
                  errorCode: error?.response?.data?.code ?? '',
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

const editPriceAlertSuccess = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertEditAction$,
  state$: StateObservable<State>,
  { navigate }: AppReduxEpicMiddleware,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(updatePriceAlert.type),
    filter(({ payload }: PriceAlertEditAction) => !!payload.model.id),
    switchMap(({ payload }: PriceAlertEditAction) =>
      action$.pipe(
        ofType(updatePriceAlertSuccess.type),
        filter((action: PriceAlertEditSuccessAction) => !!action.payload.id),
        switchMap((action: PriceAlertEditSuccessAction) =>
          of(
            notificationBannerActions.notifySuccess({
              message: I18n.t('alerts.banners.successEdited'),
            }),
          ).pipe(
            tap(() => {
              navigate(
                generatePath(paths.PRICE_ALERTS_NEW, { currency: action.payload.entity.toLowerCase() }),
              );
            }),
          ),
        ),
      ),
    ),
  );

const editPriceAlertError = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertEditErrorAction$,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(updatePriceAlert.type),
    filter(({ payload }: PriceAlertEditAction) => !!payload.model.id),
    switchMap(({ payload }: PriceAlertEditAction) =>
      action$.pipe(
        ofType(updatePriceAlertError.type),
        filter((action: PriceAlertEditErrorAction) => !!action.payload),
        switchMap((action: PriceAlertEditErrorAction) =>
          getAlertNotificationsObservable(
            { payload: { response: { data: { code: action.payload.errorCode } } } },
            false,
            payload.actions!,
          ),
        ),
      ),
    ),
  );

// CREATE

const createPriceAlertEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertCreateAction$,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(createPriceAlert.type),
    filter(({ payload }: PriceAlertCreateAction) => !!payload.model),
    exhaustMap(({ payload }: PriceAlertCreateAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.putPriceAlert(payload.model, accessToken)).pipe(
            pluck('response'),
            map(createPriceAlertSuccess),
            catchError((error: AxiosError | any) =>
              of(
                createPriceAlertError({
                  errorCode: error?.response?.data?.code ?? '',
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

const createPriceAlertSuccessEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertCreateAction$,
  state$: StateObservable<State>,
  { navigate }: AppReduxEpicMiddleware,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(createPriceAlert.type),
    filter(({ payload }: PriceAlertCreateAction) => !!payload.model),
    switchMap(({ payload }: PriceAlertCreateAction) =>
      action$.pipe(
        ofType(createPriceAlertSuccess.type),
        filter((action: PriceAlertCreateSuccessAction) => !!action.payload.id),
        switchMap((action: PriceAlertCreateSuccessAction) =>
          of(
            notificationBannerActions.notifySuccess({
              message: I18n.t('alerts.banners.successCreated'),
            }),
          ).pipe(
            tap(() => {
              navigate(
                generatePath(paths.PRICE_ALERTS_NEW, { currency: action.payload.entity.toLowerCase() }),
              );
            }),
          ),
        ),
      ),
    ),
  );

const createPriceAlertErrorEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertEditErrorAction$,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(createPriceAlert.type),
    filter(({ payload }: PriceAlertCreateAction) => !!payload.model),
    switchMap(({ payload }: PriceAlertCreateAction) =>
      action$.pipe(
        ofType(createPriceAlertError.type),
        filter((action: PriceAlertCreateErrorAction) => !!action.payload),
        switchMap((action: PriceAlertCreateErrorAction) =>
          getAlertNotificationsObservable(
            { payload: { response: { data: { code: action.payload } } } },
            true,
            payload.actions!,
          ),
        ),
      ),
    ),
  );

// DELETE

const deletePriceAlertEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertDeleteAction$,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(deletePriceAlert.type),
    filter(({ payload }: PriceAlertDeleteAction) => !!payload.id),
    switchMap(({ payload }: PriceAlertDeleteAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.deletePriceAlert(payload.id, accessToken)).pipe(
            map(() => deletePriceAlertSuccess(payload.id)),
            catchError((error: AxiosError | any) =>
              of(deletePriceAlertError(error?.response?.data?.code ?? '')),
            ),
          ),
        ),
      ),
    ),
  );

const deletePriceAlertSuccessEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertDeleteAction$,
  state$: StateObservable<State>,
  { navigate }: AppReduxEpicMiddleware,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(deletePriceAlert.type),
    filter(({ payload }: PriceAlertDeleteAction) => !!payload.id),
    switchMap(({ payload }: PriceAlertDeleteAction) =>
      action$.pipe(
        ofType(deletePriceAlertSuccess.type),
        filter((action: PriceAlertDeleteSuccessAction) => !!action.payload),
        switchMap((action: PriceAlertDeleteSuccessAction) =>
          of(
            notificationBannerActions.notifySuccess({
              message: I18n.t('alerts.banners.successDelete'),
            }),
          ).pipe(
            tap(() => {
              navigate(generatePath(paths.PRICE_ALERTS_NEW, { currency: payload.entity.toLowerCase() }));
            }),
          ),
        ),
      ),
    ),
  );

const deletePriceAlertErrorEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: PriceAlertDeleteErrorAction$,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(deletePriceAlertError.type),
    switchMap(() =>
      of(
        notificationBannerActions.notifyError({
          message: I18n.t('alerts.banners.deleteError'),
        }),
      ),
    ),
  );

// UTILS

const getAlertNotificationsObservable = (
  responseAction: { payload: { response: { data: { code: string } } } },
  isNewOrder: boolean,
  actions?: { setSubmitting: (newValue: boolean) => void },
) => {
  const errorKey = isNewOrder ? 'createError' : 'editError';
  return of(
    notificationBannerActions.notifyError({
      message: I18n.t(
        `alerts.banners.${
          responseAction?.payload?.response?.data?.code === 'same_alert_already_exists'
            ? 'alreadyExists'
            : errorKey
        }`,
      ),
    }),
  ).pipe(
    tap(() => {
      actions?.setSubmitting(false);
    }),
  );
};

const getPriceAlertFetchObservable = (
  action$: Observable<PayloadAction>,
  state$: StateObservable<State>,
  successAction: ActionCreatorWithPayload<{ priceAlerts: PriceAlertData[] }, string>,
  errorAction: ActionCreatorWithPayload<any, string>,
  checkFetchDelay = false, // Checks if the data are still valid (time from last fetch).
) =>
  state$.pipe(
    map((state: State) => ({
      accessToken: getAccessToken(state),
      shouldFetch: checkFetchDelay ? shouldPriceAlertFetch(state) : true,
    })),
    filter(({ shouldFetch }) => shouldFetch),
    map(({ accessToken }) => accessToken),
    filter(isPresent),
    take(1),
    switchMap((accessToken) =>
      from(api.fetchPriceAlerts(accessToken)).pipe(
        map(({ response }: { response: PriceAlertData[] }) => ({ priceAlerts: response })),
        map(successAction),
        catchError(
          handleAjaxError(
            action$,
            errorAction,
            {},
            {
              message: I18n.t('alerts.banners.fetchingError'),
            },
          ),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchPriceAlertsIntervalEpic,
  fetchPriceAlertsEpic,
  editPriceAlert,
  editPriceAlertSuccess,
  editPriceAlertError,
  createPriceAlertEpic,
  createPriceAlertSuccessEpic,
  createPriceAlertErrorEpic,
  deletePriceAlertEpic,
  deletePriceAlertSuccessEpic,
  deletePriceAlertErrorEpic,
);
