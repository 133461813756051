/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AnyAction } from 'redux';
import { LOCATION_CHANGE } from 'store/actions/navigation';
import { NavigationState } from 'store/types/navigation';

const initialState: NavigationState = {
  isFirstRendering: true,
  keepScroll: false,
  prevPath: '/',
  location: undefined,
};

function root(state: NavigationState = initialState, action: AnyAction) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        keepScroll: action.location.hash === '#keep-scroll',
        location: { ...state.location, ...action.location },
        prevPath: state.location?.pathname ?? '/',
      };
    default:
      return state;
  }
}

export default root;
