/* eslint-disable @typescript-eslint/no-shadow */

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { checkUrlInPathname } from 'common/const/backArrow';
import { useDesktop } from './useMedia';

export const useDesktopBackArrow = () => {
  const { pathname } = useLocation();
  const isDesktopLayout = useDesktop();

  const { backUrl } = useMemo(() => {
    const url = checkUrlInPathname({ pathname, isDesktopLayout });

    return {
      backUrl: url !== pathname ? url : null,
    };
  }, [pathname, isDesktopLayout]);

  return { backUrl };
};
