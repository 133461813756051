import {
  Asset,
  ASSET_CLASSES,
  ASSET_TAGS,
  AssetClass,
  AssetReturnType,
  AssetDictionaryReturnType,
  Crypto,
  AssetsReturnType,
} from 'types/assets';
import { State } from 'store/types/store';
import { getMarketOverviewAssetTag } from '../filters/selectors';
import { createSelector, EntityId } from '@reduxjs/toolkit';
import { ASSETS_SLICE_NAME } from 'common/const/slices';
import { cryptoAdapter, securityAdapter } from './adapters';
import { arrayToDictionary } from 'common/utils/array';
import { sortAssetsAlphabetically } from 'common/utils/assets';

// SLICE SELECTORS
const selectSlice = (state: State) => state[ASSETS_SLICE_NAME];

export const cryptoSelectors = cryptoAdapter.getSelectors();
export const securitySelectors = securityAdapter.getSelectors();

// ASSETS
/**
 * Retrieves an asset based on the provided asset class and code.
 *
 * @param {State} state The current state of the store.
 * @param {AssetClass} assetClass The class of the asset (e.g., CRYPTO, SECURITY).
 * @param {string} code The unique code of the asset.
 * @returns {Asset | undefined} If asset class is Crypto.
 * @returns {Security | undefined} If asset class is Security.
 * @returns {undefined} If asset class is Currency.
 */

export const getAsset = <T extends AssetClass>(
  state: State,
  assetClass: T,
  code: string,
): AssetReturnType<T> => {
  const selectedSlice = selectSlice(state);

  if (assetClass === ASSET_CLASSES.CURRENCY) return undefined as AssetReturnType<T>;

  if (assetClass === ASSET_CLASSES.CRYPTO)
    return cryptoSelectors.selectById(selectedSlice.entities.crypto, code) as AssetReturnType<T>;

  return securitySelectors.selectById(selectedSlice.entities.securities, code) as AssetReturnType<T>;
};

/**
 * Retrieves the name of the asset based on the provided asset class and code.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @param {string} code - The unique code of the asset.
 * @param {AssetSecurityClass | undefined} securityClass - The security class of the asset, if applicable.
 * @returns {string | undefined} - An object where the keys are asset codes (in lowercase) and the values are asset display names.
 */
export const getAssetDisplayName = createSelector(getAsset, (asset) => asset?.displayName);

/**
 * Retrieves the IDs of assets based on the provided asset class.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @returns {EntityId[]} - An array of asset IDs.
 */
export const getAssetIds = (state: State, assetClass: AssetClass): EntityId[] => {
  const selectedSlice = selectSlice(state);

  if (assetClass === ASSET_CLASSES.CRYPTO) return cryptoSelectors.selectIds(selectedSlice.entities.crypto);

  return securitySelectors.selectIds(selectedSlice.entities.securities);
};

/**
 * Retrieves the entities of assets based on the provided asset class.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @returns {Dictionary<Crypto | Security>} - A dictionary of asset entities (e.g., {btc: {...assetData}}).
 */
export const getAssetsAsEntityDictionary = <T extends AssetClass>(
  state: State,
  assetClass: T,
): AssetDictionaryReturnType<T> => {
  const selectedSlice = selectSlice(state);

  if (assetClass === ASSET_CLASSES.CRYPTO)
    return cryptoSelectors.selectEntities(selectedSlice.entities.crypto) as AssetDictionaryReturnType<T>;

  return securitySelectors.selectEntities(selectedSlice.entities.securities) as AssetDictionaryReturnType<T>;
};

/**
 * Retrieves the assets based on the provided asset class.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @returns {Crypto[] | Security[]} - A dictionary of asset entities (e.g., {btc: {...assetData}}).
 */
export const getAssets = <T extends AssetClass>(state: State, assetClass: T): AssetsReturnType<T> => {
  const selectedSlice = selectSlice(state);

  if (assetClass === ASSET_CLASSES.CRYPTO)
    return cryptoSelectors.selectAll(selectedSlice.entities.crypto) as AssetsReturnType<T>;

  return securitySelectors.selectAll(selectedSlice.entities.securities) as AssetsReturnType<T>;
};

/**
 * Retrieves sorted assets based on the provided asset class and comparator function.
 *
 * @param {State} state - The current state of the store.
 * @param {AssetClass} assetClass - The class of the asset (e.g., CRYPTO, SECURITY).
 * @param {(a: Asset, b: Asset) => number} comparator - The comparator function to sort the assets.
 * @returns {Asset[]} - An array of sorted assets.
 */
export const getSortedAssetEntities = createSelector(
  [
    (state: State, assetClass: AssetClass, comparator: (a: Asset, b: Asset) => number) =>
      getAssets(state, assetClass)?.sort(comparator),
  ],
  <T extends AssetClass>(assets: AssetsReturnType<T>) =>
    arrayToDictionary(assets ?? [], (item: Asset) => item.code.toLowerCase()),
);

export const getAllAssetsForCurrentAssetTag = createSelector(
  [
    getMarketOverviewAssetTag,
    (state: State) => cryptoSelectors.selectAll(state.assets.entities.crypto),
    (state: State) => securitySelectors.selectAll(state.assets.entities.securities),
  ],
  (assetTag, cryptos, securities) => (assetTag === ASSET_TAGS.CRYPTO ? cryptos : securities),
);

// CRYPTO
export const getCryptoPrecision = createSelector(
  [(state: State, code: string) => getAsset(state, ASSET_CLASSES.CRYPTO, code)],
  (asset: Crypto | undefined) => asset?.cryptoDetails?.decimalPlaces,
);
export const getCryptoAssets = (state: State) => {
  return cryptoSelectors.selectEntities(state.assets.entities.crypto);
};

export const getSortedCryptoAssets = (state: State) => {
  return cryptoSelectors.selectAll(state.assets.entities.crypto).sort(sortAssetsAlphabetically);
};

export const getSortedCryptoAssetsAvailableForDeposit = createSelector(
  [(state: State) => getSortedCryptoAssets(state)],
  (cryptos: Crypto[]) => cryptos.filter((crypto) => crypto.cryptoDetails?.isDepositEnabled),
);

export const getCryptoEntities = (state: State) => {
  return cryptoSelectors.selectAll(state.assets.entities.crypto);
};

export const getCryptoMinWithdrawalAmount = createSelector(
  [(state: State, code: string) => getAsset(state, ASSET_CLASSES.CRYPTO, code)],
  (asset: Crypto | undefined) => asset?.cryptoDetails?.minWithdrawalAmount,
);

export const getCryptoPairs = createSelector(
  [(state: State) => getAssets(state, ASSET_CLASSES.CRYPTO)],
  (assets) =>
    arrayToDictionary(
      assets,
      (item) => item.code.toLowerCase(),
      (item) => item.pair,
    ),
);

// SECURITIES
export const getIsMasterDataLoading = (state: State): boolean => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.entities.isMasterDataLoading;
};
