/* eslint-disable @typescript-eslint/no-unsafe-return */
import { MarketOverviewFilterType } from 'types/assets';
import { InboxFilterOptions } from 'types/filters';
import { State } from 'store/types/store';
import { FILTERS_SLICE_NAME } from 'common/const/slices';

const selectSlice = (state: State) => state[FILTERS_SLICE_NAME];
const selectMarketOverviewSlice = (state: State) => selectSlice(state).marketOverview;

// MARKET OVERVIEW
export const getActiveMarketOverviewFilters = (state: State): MarketOverviewFilterType => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.marketOverview;
};

export const getMarketOverviewSearchAssetCodes = (state: State) =>
  selectMarketOverviewSlice(state).searchAssetCodes;

export const getMarketOverviewAssetTag = (state: State) => selectMarketOverviewSlice(state).assetTag;

export const getMarketOverviewSearchText = (state: State) => selectMarketOverviewSlice(state).searchText;

export const getMarketOverviewVisibleAssets = (state: State) =>
  selectMarketOverviewSlice(state).visibleAssetCodes;

export const getMarketOverviewOrderBy = (state: State) => selectMarketOverviewSlice(state).order;

// INBOX
export const getInboxFilters = (state: State): InboxFilterOptions => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.inbox;
};
