import { BLOCKED_FEATURES_VALUES } from 'common/const';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useLocation } from 'react-router-dom';
import { getIsCryptoFeatureDisabledWithoutAcceptingTandC } from 'store/selectors/termsAndConditions';
import { cryptoTermsAndConditionsClickedAway } from 'store/actions/termsAndConditions';
import { paths } from 'common/urls';
import { ASSET_CLASSES } from 'types/assets';

const useOrderTypes = ({ isBuy, selectedCrypto }: { isBuy: boolean; selectedCrypto: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isPrevScreenIsBuySell = location?.state?.isFromBuySellScreen;
  const isFeaureDisabledTnc = useSelector(getIsCryptoFeatureDisabledWithoutAcceptingTandC);
  const isLOTradingDisabled = isFeaureDisabledTnc(BLOCKED_FEATURES_VALUES.LO);
  const isSOTradingDisabled = isFeaureDisabledTnc(BLOCKED_FEATURES_VALUES.SO);
  const triggerTermsAndConditions = () => {
    dispatch(cryptoTermsAndConditionsClickedAway(false));
  };

  const { pathname } = location;

  const isOnLimitOrder =
    pathname ===
    generatePath(paths.LIMIT_ORDER_CREATION, {
      type: isBuy ? 'buy' : 'sell',
      currency: selectedCrypto,
    });

  const isOnStopOrder =
    pathname ===
    generatePath(paths.STOP_ORDER_CREATION, {
      type: isBuy ? 'buy' : 'sell',
      currency: selectedCrypto,
    });

  const onBuySellOrderClick = () => {
    navigate(
      generatePath(paths.TRADE_ASSET, {
        type: isBuy ? 'buy' : 'sell',
        assetCode: selectedCrypto,
        assetClass: ASSET_CLASSES.CRYPTO,
      }),
      { replace: true },
    );
  };

  const handleGoToLimitOrders = (): void => {
    navigate(
      generatePath(paths.LIMIT_ORDER_CREATION, {
        type: isBuy ? 'buy' : 'sell',
        currency: selectedCrypto,
      }),
      {
        state: {
          isFromBuySellScreen: isPrevScreenIsBuySell,
        },
        replace: true,
      },
    );
  };

  const handleGoToStopOrder = (): void => {
    navigate(
      generatePath(paths.STOP_ORDER_CREATION, {
        type: isBuy ? 'buy' : 'sell',
        currency: selectedCrypto,
      }),
      {
        state: {
          isFromBuySellScreen: isPrevScreenIsBuySell,
        },
        replace: true,
      },
    );
  };

  const onClickStopOrder = () => {
    if (isOnStopOrder) return;

    if (isSOTradingDisabled) {
      triggerTermsAndConditions();
      return;
    }

    handleGoToStopOrder();
  };

  const onClickLimitOrder = () => {
    if (isOnLimitOrder) return;

    if (isLOTradingDisabled) {
      triggerTermsAndConditions();
      return;
    }

    handleGoToLimitOrders();
  };

  return [
    {
      name: t(`tradingRules.${isBuy ? 'buy' : 'sell'}`).toUpperCase(),
      onClick: onBuySellOrderClick,
      isDisabled: false,
      isActive: false,
    },
    {
      name: t('tradingRules.stopOrderButton').toUpperCase(),
      onClick: onClickStopOrder,
      isDisabled: false,
      isActive: isOnStopOrder,
    },
    {
      name: t('tradingRules.limitOrderButton').toUpperCase(),
      onClick: onClickLimitOrder,
      isDisabled: false,
      isActive: isOnLimitOrder,
    },
  ];
};

export default useOrderTypes;
