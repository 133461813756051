// @flow
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { accountTypes } from 'common/const';
import { paths } from 'common/urls';
import Button from 'common/components/Button';
import * as authActions from 'store/actions/auth';
import { Content, Buttons } from './styles';

type Props = {
  isKycDone: boolean;
  changeAccountType: typeof authActions.changeAccount;
};

const DisabledFeatures = ({ isKycDone, changeAccountType }: Props) => {
  const { t } = useTranslation();
  const changeAccount = () => {
    changeAccountType(accountTypes.real);
  };

  return (
    <Content>
      <ReactMarkdown>{t('prompts.disabledFeature.paperMode')}</ReactMarkdown>
      <Buttons>
        {(isKycDone && (
          <Button
            title={t('prompts.realMoneyToggleBtn')}
            onClick={() => {
              changeAccount();
            }}
          />
        )) || <Button title={t('prompts.doKycBtn')} to={paths.KYC} />}
      </Buttons>
    </Content>
  );
};

export default DisabledFeatures;
