import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { allowBetaAccess } from 'store/actions/auth';
import ReactMarkdown from 'react-markdown';
import AlertModal from 'common/components/Modal/AlertModal';
import { linkToHelpCenter } from 'common/utils/mailAndHelpCenter';
import { ModalProps } from 'types/modals';

const BetaAccessModal = ({ onClose }: ModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const supportLink = linkToHelpCenter();
  const onCloseClick = () => {
    dispatch(allowBetaAccess());
    onClose();
  };

  return (
    <AlertModal
      closeOnDimmerClick={false}
      open={true}
      onClose={onCloseClick}
      ButtonTitle={t('common.close')}
      Content={
        <ReactMarkdown linkTarget="_blank">{t('betaAccess.forbidden', { supportLink })}</ReactMarkdown>
      }
    />
  );
};

export default React.memo(BetaAccessModal);
