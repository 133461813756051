import React from 'react';
import { Container, OrderType } from 'common/components/OrderTypeSelector/styles';

export type TypeValue = {
  name: string;
  onClick: () => void;
  isDisabled: boolean;
  isActive: boolean;
};

type TypeSwitchProps = {
  typeValues: TypeValue[];
};

const TypeSwitch = ({ typeValues }: TypeSwitchProps): React.ReactElement => (
  <Container>
    {typeValues.map(({ name, isActive, isDisabled, onClick }) => (
      <OrderType
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={isDisabled ? () => null : onClick}
        key={name}
      >
        {name}
      </OrderType>
    ))}
  </Container>
);

export default TypeSwitch;
