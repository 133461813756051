import { themes } from 'common/const';

export const colors = {
  pureBlack: '#000000',
  blackSecondary: '#4D4D4D',
  altBlack: '#252525',
  blackTransparent: 'rgba(0, 0, 0, 0.12)',
  pureWhite: '#FFFFFF',
  whiteOpacity: '#FFFFFFCC',
  main: '#74CDDC',
  darkMain: '#336474',
  blueOpacity: '#D8ECF266',
  orange: '#e09f36',
  white: '#FAFAFA',
  gray: '#F1F2F3',
  dark: '#153243',
  gray1: '#cbcbcb',
  gray2: '#8E8E93',
  gray3: '#F2F2F2',
  gray4: '#CCCCCC',
  gray4Opacity: 'rgba(204,204,204,0.4)',
  gray5: '#ced3d666',
  gray6: '#DADADA',
  gray7: '#757575',
  gray8: '#CED3D6',
  gray9: 'rgba(204, 204, 204, 0.4)',
  gray10: '#5B6F79',
  gray12: '#e0e0e0', // staking button light mode
  gray13: '#FCFCFD',
  green: '#72BF00',
  lightMain: '#d8ecf2',
  transparent: 'transparent',
  grayTransparent: 'rgba(17, 51, 83, 0.6)',
  yellow: 'rgba(255, 206, 43, 0.95)',
  yellowDeep: 'rgba(255, 199, 0, 1)',
  golden: '#FFCE2B',
  yellowTransparent: 'rgba(255, 206, 43, 0.2)',
  yellowLight: '#FFF8DF',
  yellowDeepTransparent: 'rgba(247, 147, 26, 0.12)',
  textBlue: '#B6C6DA',
  textBlueOpacity: '#B6C6DA33',
  mainTransparent: 'rgba(116, 205, 220, 0.12)',
  inactiveCell: 'rgba(255, 255, 255, 0.12)',
  whiteWithOpacity: 'rgba(255, 255, 255, 0.4)',
  iconBgLight: '#E6F3F9',
  iconBgDark: '#153243',
  // charts
  portfolioDarkChartLine: '#B4E6EE',
  chartBg: '#F4FBFC',
  chartGradient: ['rgba(116, 205, 220, 0.64)', 'rgba(116, 205, 220, 0.51)', 'rgba(116, 205, 220, 0.00)'],
  marketChartGradient: ['rgba(115, 205, 221, 0.6)', 'rgba(59, 187, 210, 0.444)', 'rgba(115, 205, 221, 0.06)'],
  positiveGradient: ['rgba(114, 191, 0, 0.4)', 'rgba(114, 191, 0, 0.4)', 'rgba(255, 255, 255, 0)'],
  negativeGradient: ['rgba(229, 59, 71, 0.4)', 'rgba(229, 59, 71, 0.4)', 'rgba(255, 255, 255, 0)'],
  boxShadowGradientDark: `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`,
  boxShadowGradientLight: `0px 5px 5px -3px rgb(116 205 220 / 20%), 0px 8px 10px 1px rgb(116 205 220 / 14%), 0px 3px 14px 2px rgb(116 205 220 / 12%)`, // the rgb color is #74CDDC
  defaultTechIndicator: '#8884d8',
  techIndicators: [
    {
      label: 'SMA (10)',
      color: '#f26fe4',
    },
    {
      label: 'SMA (20)',
      color: '#72BF00',
    },
    {
      label: 'SMA (50)',
      color: '#F2A900',
    },
    {
      label: 'SMA (100)',
      color: '#F0E68C',
    },
    {
      label: 'RSI (10)',

      color: '#f26fe4',
    },
    {
      label: 'RSI (20)',

      color: '#F2A900',
    },
    {
      label: 'RSI (50)',

      color: '#72BF00',
    },
    {
      label: 'RSI (100)',
      color: '#FFC0CB',
    },
    {
      label: 'POS',
      color: '#b5f5b5',
    },
    {
      label: 'NEG',
      color: '#fcd2d8',
    },
  ],
  rsiStopColor: '82ca9d',

  // Dark mode
  darkShade4: '#002B3F',
  darkShade5: '#356876',
  darkBlue06: '#1F3D51',
  darkBlue07: '#071C28',
  darkBlue08: '#1d2c35', // staking button dark mode
  darkBlue: '#A8CED8',

  loadingBackgroundGray: '#F5F5F6',
  highlighted: '#50A2E0',

  newSuccess: '#00C14D',
  newCritical: '#F83A3A',
  newWarning: '#FFC700',
  newInfo: '#3F94FF',

  shadowColors: {
    success: 'rgba(0, 193, 77, 0.12)',
    error: 'rgba(248, 58, 58, 0.12)',
    info: 'rgba(63, 148, 255, 0.12)',
    warning: 'rgba(255, 199, 0, 0.24)',
  },

  //light mode palette
  secondaryBgLight: '#F9FAFB',
  thirdBgLight: '#F1F3F4',
  primaryInvertedBgLight: '#070808',
  disabledFgLight: '#A4A4A5',

  //dark mode palette
  secondaryBgDark: '#03121B',
  tertiaryBgDark: '#002A33',
  tertiaryFgDark: '#394E58',
};

const themeColors = {
  [themes.light]: {
    foreground: {
      primary: colors.pureBlack,
      primaryLight: colors.gray2,
      primaryInverted: colors.pureWhite,
      secondary: colors.gray2,
      tertiary: colors.textBlue,
      critical: colors.newCritical,
      positive: colors.newSuccess,
      caution: colors.yellow,
      cautionIcon: colors.yellow,
      pending: colors.golden,
      focused: colors.main,
      footer: {
        text: colors.gray6,
        header: colors.textBlue,
        logo: colors.disabledFgLight,
      },
      formAccent: colors.lightMain,
      formAccentInverted: colors.dark,
      inactive: colors.textBlue,
      tradingBanner: colors.blackSecondary,
      highlighted: colors.highlighted,
      disabledInput: colors.gray1,
      type: colors.textBlue,
      stakingBtn: colors.pureBlack,
      activityDate: colors.gray8,
      neutralPerfText: colors.disabledFgLight,
      transactionAmount: colors.disabledFgLight,
      disabledText: colors.disabledFgLight,
      disabledSameOnLightAndDark: colors.disabledFgLight,
      inputPlaceholder: colors.blackSecondary,
    },
    background: {
      primary: colors.pureWhite,
      primaryInverted: colors.primaryInvertedBgLight,
      secondary: colors.secondaryBgLight,
      tertiary: colors.thirdBgLight,
      info: colors.gray3,
      caution: colors.yellowTransparent,
      coutionLight: colors.yellowLight,
      emph: colors.lightMain,
      positive: colors.newSuccess,
      input: colors.transparent,
      inputAdornment: colors.lightMain,
      formAccent: colors.dark,
      formAccentInverted: colors.lightMain,
      formAccentInvertedOpacity: colors.blueOpacity,
      footer: colors.secondaryBgDark,
      critical: colors.newCritical,
      criticalLight: '#FFEDEE',
      overlay: colors.grayTransparent,
      tableHeader: colors.gray3,
      depositOption: colors.gray13,
      switcher: colors.pureBlack,
      selected: colors.gray3,
      action: colors.gray,
      light: colors.mainTransparent,
      tradingBanner: colors.gray5,
      chipsBase: colors.gray,
      loading: colors.loadingBackgroundGray,
      progressIndicator: colors.gray4,
      boxShadow: colors.boxShadowGradientDark,
      radioButton: colors.main,
      stockRaffleBanner: colors.darkBlue07,
      stockRaffleCloseBanner: '#2a5562',
      moneyButton: colors.main,
      bankCard: colors.gray,
      bankCardLine: colors.gray8,
      notifications: {
        success: {
          mainColor: colors.newSuccess,
          boxShadow: colors.shadowColors.success,
        },
        error: {
          mainColor: colors.newCritical,
          boxShadow: colors.shadowColors.error,
        },
        info: {
          mainColor: colors.newInfo,
          boxShadow: colors.shadowColors.info,
        },
        warning: {
          mainColor: colors.newWarning,
          boxShadow: colors.shadowColors.warning,
        },
      },
      cookieBanner: colors.gray3,
      stakingBtn: colors.gray12,
      cryptoShortButton: colors.blackTransparent,
      cryptoShortBtcButton: colors.yellowDeepTransparent,
    },
    border: {
      input: colors.gray1,
      standard: colors.gray9,
      inputFocused: colors.main,
      formAccent: colors.altBlack,
      formAccentInverted: colors.altBlack,
      critical: colors.newCritical,
      table: colors.gray6,
    },
    charts: {
      tooltipText: colors.darkMain,
      chartLine: colors.pureWhite,
      chartGradient: colors.chartGradient,
      marketChartLine: colors.main,
      marketChartGradient: colors.marketChartGradient,
      positiveGradient: colors.positiveGradient,
      negativeGradient: colors.negativeGradient,
      defaultTechIndicator: colors.defaultTechIndicator,
      techIndicators: colors.techIndicators,
      rsiStopColor: colors.rsiStopColor,
      background: colors.chartBg,
      emptyCell: colors.textBlue,
      donutData: colors.blackSecondary,
      donutCryptoLabel: colors.pureWhite,
      donutStockLabel: colors.pureBlack,
      lineChartStroke: colors.pureBlack,
    },
    icons: {
      neutral: colors.gray,
      neutralIntense: colors.gray4,
      white: colors.pureWhite,
      svgBg: colors.iconBgLight,
      main: colors.main,
    },
    toggleSwitch: {
      enabled: colors.green,
      disabled: colors.gray,
      switchBase: colors.pureWhite,
      switchBaseDisabled: colors.pureWhite,
      border: colors.gray1,
    },
  },
  [themes.dark]: {
    foreground: {
      primary: colors.lightMain,
      primaryLight: colors.white,
      primaryInverted: colors.pureBlack,
      secondary: colors.darkBlue,
      tertiary: colors.tertiaryFgDark,
      critical: colors.newCritical,
      positive: colors.newSuccess,
      caution: colors.yellow,
      cautionIcon: colors.pureBlack,
      pending: colors.golden,
      focused: colors.main,
      footer: {
        text: colors.gray6,
        header: colors.textBlue,
        logo: colors.disabledFgLight,
      },
      formAccent: colors.dark,
      formAccentInverted: colors.pureWhite,
      inactive: colors.darkBlue,
      tradingBanner: colors.whiteOpacity,
      footerSectionHeader: colors.textBlue,
      highlighted: colors.highlighted,
      disabledInput: colors.gray5,
      type: colors.gray10,
      stakingBtn: colors.lightMain,
      activityDate: colors.gray8,
      neutralPerfText: colors.disabledFgLight,
      transactionAmount: colors.disabledFgLight,
      disabledText: colors.gray10,
      disabledSameOnLightAndDark: colors.disabledFgLight,
      inputPlaceholder: colors.blackSecondary,
    },
    background: {
      primary: colors.darkBlue07,
      primaryInverted: colors.lightMain,
      secondary: colors.secondaryBgDark,
      tertiary: colors.tertiaryBgDark,
      input: colors.secondaryBgDark,
      inputDisabled: colors.secondaryBgDark,
      inputFocused: colors.secondaryBgDark,
      inputAdornment: colors.tertiaryFgDark,
      info: colors.gray3,
      caution: colors.yellow,
      coutionLight: colors.yellow,
      emph: colors.darkBlue,
      positive: colors.newSuccess,
      formAccent: colors.lightMain,
      formAccentInverted: colors.dark,
      formAccentInvertedOpacity: colors.darkBlue07,
      footer: colors.secondaryBgDark,
      overlay: colors.grayTransparent,
      critical: colors.newCritical,
      criticalLight: colors.newCritical + '0D',
      tableHeader: colors.darkBlue06,
      depositOption: colors.darkBlue06,
      switcher: colors.main,
      selected: colors.main,
      action: colors.dark,
      light: colors.grayTransparent,
      tradingBanner: colors.textBlueOpacity,
      chipsBase: colors.dark,
      loading: colors.loadingBackgroundGray,
      progressIndicator: colors.gray5,
      boxShadow: colors.boxShadowGradientLight,
      radioButton: colors.darkShade4,
      stockRaffleBanner: '#6432FA',
      stockRaffleCloseBanner: '#b7e0e9',
      moneyButton: colors.main,
      bankCard: colors.darkBlue06,
      bankCardLine: colors.secondaryBgDark,
      notifications: {
        success: {
          mainColor: colors.newSuccess,
          boxShadow: colors.shadowColors.success,
        },
        error: {
          mainColor: colors.newCritical,
          boxShadow: colors.shadowColors.error,
        },
        info: {
          mainColor: colors.newInfo,
          boxShadow: colors.shadowColors.info,
        },
        warning: {
          mainColor: colors.newWarning,
          boxShadow: colors.shadowColors.warning,
        },
      },
      cookieBanner: colors.darkBlue06,
      stakingBtn: colors.darkBlue08,
      cryptoShortButton: colors.main,
      cryptoShortBtcButton: colors.main,
    },
    border: {
      input: colors.dark,
      standard: colors.darkShade5,
      inputFocused: colors.main,
      formAccent: colors.transparent,
      formAccentInverted: colors.main,
      critical: colors.newCritical,
      table: colors.gray6,
    },
    charts: {
      tooltipText: colors.darkMain,
      chartLine: colors.portfolioDarkChartLine,
      chartGradient: colors.chartGradient,
      marketChartLine: colors.main,
      marketChartGradient: colors.marketChartGradient,
      positiveGradient: colors.positiveGradient,
      negativeGradient: colors.negativeGradient,
      defaultTechIndicator: colors.defaultTechIndicator,
      techIndicators: colors.techIndicators,
      rsiStopColor: colors.rsiStopColor,
      background: colors.darkBlue07,
      emptyCell: colors.inactiveCell,
      donutData: colors.whiteWithOpacity,
      donutCryptoLabel: colors.pureWhite,
      donutStockLabel: colors.pureBlack,
      lineChartStroke: colors.lightMain,
    },
    icons: {
      neutral: colors.gray,
      neutralIntense: colors.gray4,
      white: colors.pureWhite,
      svgBg: colors.iconBgDark,
      main: colors.lightMain,
    },
    toggleSwitch: {
      enabled: colors.main,
      disabled: colors.gray,
      switchBase: colors.darkMain,
      switchBaseDisabled: colors.gray1,
      border: colors.gray1,
    },
  },
};

export default themeColors;
