import { State } from 'store/types/store';

export const getIsDeviceMonitoringConsentOpen = (state: State) =>
  state.deviceMonitoring.isDeviceMonitoringConsentOpen;
export const getDeviceConsents = (state: State) => state.deviceMonitoring.deviceConsents;
export const getIsDeviceMonitoringScriptLoaded = (state: State) =>
  state.deviceMonitoring.isDeviceMonitoringScriptLoaded;
export const getConsentModel = (state: State) => state.deviceMonitoring.consentModel;

export const getConsentState = (state: State) => state.deviceMonitoring.consentState;
