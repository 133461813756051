import styled from 'styled-components';
import { Theme } from 'types/theme';

export const BannerWrapper = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.tradingBanner};
  border-radius: 4px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
`;

export const CircleIcon = styled.div`
  height: 38px;
  width: 38px;
  min-width: 38px;
  min-height: 38px;
  border-radius: 50%;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.tradingBanner};
  font-weight: 700;
`;
