import { all, call, cancel, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import I18n from 'i18next';

import { authFetchEntity } from 'store/sagas/auth';
import * as api from 'common/api';
import { getDeviceData } from 'common/utils/seonSdk';
import { getSelectedFiatCurrency } from 'store/selectors/currency';
import {
  getCode2fa,
  getCryptoDepositInfo,
  getCryptoWithdrawInitPayload,
  getCryptoWithdrawOrder,
  getLastSent,
  getMoneyState,
  getWithdrawalAccount,
} from 'store/selectors/money';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import * as actions from 'store/actions/money';
import { pageURLs, paths } from 'common/urls';
import { authRequest } from './auth';
import { getLastTransactionHistorySent } from '../selectors/money';
import { transactionHistoryYears } from '../../common/const';
import { checkDeviceConsentNeeded } from './deviceMonitoring';
import { errorCodes } from 'common/apiErrors';

export const MIN_SEND_INFO_REPORT_INTERVAL = 10; // Minutes
export const TTL_CRYPTO_DEPOSIT_INFO = 4 * 1000;

export const sendInfoReport = authFetchEntity.bind(null, actions.sendInfoReport, api.sendInfoReport, true);

export const submitWithdrawalsOrder = authFetchEntity.bind(
  null,
  actions.withdrawOrder,
  api.withdrawOrder,
  false,
);
export const withdrawInfo = authFetchEntity.bind(null, actions.withdrawInfo, api.withdrawInfo, false);
export const depositInfo = authFetchEntity.bind(null, actions.depositInfo, api.depositInfo, false);
export const initCryptoWithdrawOrder = authFetchEntity.bind(
  null,
  actions.initCryptoWithdrawOrder,
  api.initCryptoWithdrawOrder,
  false,
);
export const submitCryptoWithdrawOrder = authFetchEntity.bind(
  null,
  actions.submitCryptoWithdrawOrder,
  api.submitCryptoWithdrawOrder,
  false,
);
export const fetchCryptoDepositInfo = authFetchEntity.bind(
  null,
  actions.cryptoDepositInfo,
  api.cryptoDepositInfo,
  false,
);
export const regenerateAddress = authFetchEntity.bind(
  null,
  actions.regenerateAddress,
  api.regenerateAddress,
  false,
);

export const sendTransactionHistory = authFetchEntity.bind(
  null,
  actions.sendTransactionHistory,
  api.sendTransactionHistory,
  false,
);

function* submitUserOrder(action: any): Saga<void> {
  const { volume, onFail, onSuccess } = action.volume;
  const fiat = yield select(getSelectedFiatCurrency);
  const account = yield select(getWithdrawalAccount);
  const { error } = yield call(submitWithdrawalsOrder, {
    currency: fiat,
    volume,
    account,
  });
  if (error) {
    yield put(
      notificationBannerActions.notifyError({
        title: I18n.t('withdraw.errors.generic'),
        message: error.message,
      }),
    );
    onFail();
  } else {
    onSuccess();
  }
}

function* updateWithdrawInfo(): Saga<void> {
  const { fetchingWithdrawInfo } = yield select(getMoneyState);
  if (!fetchingWithdrawInfo) {
    const { error } = yield call(withdrawInfo);
    if (error) {
      yield put(
        notificationBannerActions.notifyError({
          title: I18n.t('settings.withdraw.title'),
          message: error.message,
        }),
      );
    }
  }
}

function* updateDepositInfo(): Saga<void> {
  const { fetchingDepositInfo } = yield select(getMoneyState);
  if (!fetchingDepositInfo) {
    const { error } = yield call(depositInfo);
    if (error) {
      yield put(
        notificationBannerActions.notifyError({
          title: I18n.t('settings.deposit.title'),
          message: error.message,
        }),
      );
    }
  }
}

function* initCryptoWithdrawProcess(): Saga<void> {
  while (true) {
    const { currency, volume, walletAddress, walletAddressTag, password, onInitSuccess } = yield take(
      actions.INIT_CRYPTO_WITHDRAW,
    );

    for (let i = 1; i <= 2; i++) {
      // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing
      try {
        const { deviceData, error: deviceDataError } = yield call(getDeviceData);

        const { error } = yield call(initCryptoWithdrawOrder, {
          currency,
          volume,
          walletAddress,
          walletAddressTag,
          password,
          deviceData,
        });
        if (error) {
          const { skip } = yield call(checkDeviceConsentNeeded, error);
          if (skip) {
            i = 2;
          }

          if (error.detail === 'bch_legacy') {
            const landingUrls = pageURLs();
            yield put(
              notificationBannerActions.notifyError({
                message: I18n.t('cryptoWithdraw.errors.bchLegacy'),
                onClick: () => {
                  window.open(landingUrls.cryptoWithdrawInfo, '_blank');
                },
              }),
            );
          } else if (error?.code === errorCodes.CRYPTOWITHDRAWINIT_ERR_WALLETADDRESSINVALID) {
            yield put(
              notificationBannerActions.notifyError({
                message: I18n.t('cryptoWithdraw.errors.walletAddressInvalid'),
              }),
            );
          } else {
            yield put(
              notificationBannerActions.notifyError({
                title: I18n.t('cryptoWithdraw.errors.generic'),
                message: error.message,
              }),
            );
          }
        } else {
          i = 2;
          yield call(onInitSuccess);
        }
      } catch (err) {
        // console.log('Error', err);
      }
    }
  }
}

function* resendCryptoWithdrawCodeProcess(): Generator<any, any, any> {
  while (true) {
    yield take(actions.RESEND_CRYPTO_WITHDRAW_CODE);

    for (let i = 1; i <= 2; i++) {
      // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing
      try {
        const { currency, volume, walletAddress, password, destinationTag } = yield select(
          getCryptoWithdrawInitPayload,
        );
        const { deviceData, error: deviceDataError } = yield call(getDeviceData);

        const { error } = yield call(initCryptoWithdrawOrder, {
          currency,
          volume,
          walletAddress,
          password,
          walletAddressTag: destinationTag,
          deviceData,
        });
        if (error) {
          const { skip } = yield call(checkDeviceConsentNeeded, error);
          if (skip) {
            i = 2;
          }

          yield put(
            notificationBannerActions.notifyError({
              title: I18n.t('cryptoWithdraw.errors.resendCodeError'),
              message: I18n.t('cryptoWithdraw.errors.resendCodeError'),
            }),
          );
        } else {
          i = 2;
          yield put(
            notificationBannerActions.notifySuccess({
              title: I18n.t('cryptoWithdraw.resendCodeSuccess'),
              message: I18n.t('cryptoWithdraw.resendCodeSuccess'),
            }),
          );
        }
      } catch (err) {
        // console.log('Error', err);
      }
    }
  }
}

function* submitCryptoWithdrawProcess(): Saga<void> {
  while (true) {
    const { code2fa, actionsWithdraw, navigate } = yield take(actions.SUBMIT_CRYPTO_WITHDRAW);
    const order = yield select(getCryptoWithdrawOrder);

    for (let i = 1; i <= 2; i++) {
      // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing
      try {
        const { deviceData, error: deviceDataError } = yield call(getDeviceData);

        const { error } = yield call(submitCryptoWithdrawOrder, { order, code2fa, deviceData });
        if (error) {
          const { skip } = yield call(checkDeviceConsentNeeded, error);
          if (skip) {
            i = 2;
          }

          if (error?.code === errorCodes.CRYPTOWITHDRAWSUBMIT_ERR_INVALIDTOKEN) {
            yield call(actionsWithdraw.wrongTwoFa);
          } else {
            yield call(actionsWithdraw.failed);
            yield put(
              notificationBannerActions.notifyError({
                title: I18n.t('cryptoWithdraw.errors.generic'),
                message: error.message,
              }),
            );
          }
        } else {
          i = 2;
          if (actionsWithdraw?.success) {
            yield call(actionsWithdraw.success);
          }
          if (navigate) navigate(paths.CRYPTO_WITHDRAW_SUCCESS);
        }
        yield call(actionsWithdraw.finished);
      } catch (err) {
        // console.log('Error', err);
      }
    }
  }
}

function* resendCryptoWithdrawConfirmationEmail(): Saga<void> {
  while (true) {
    yield take(actions.RESEND_CRYPTO_WITHDRAW_CONFIRMATION_EMAIL);
    const code2fa = yield select(getCode2fa);
    const order = yield select(getCryptoWithdrawOrder);

    for (let i = 1; i <= 2; i++) {
      // foor loop with try/catch logic is only ment for retrying init request when device consent id is missing
      try {
        const { deviceData, error: deviceDataError } = yield call(getDeviceData);

        const { error } = yield call(submitCryptoWithdrawOrder, { order, code2fa, deviceData });
        if (error) {
          const { skip } = yield call(checkDeviceConsentNeeded, error);
          if (skip) {
            i = 2;
          }

          yield put(
            notificationBannerActions.notifyError({
              title: I18n.t('cryptoWithdraw.errors.generic'),
              message: error.message,
            }),
          );
        } else {
          i = 2;
          yield put(
            notificationBannerActions.notifySuccess({
              title: I18n.t('cryptoWithdraw.resentMail'),
              message: I18n.t('cryptoWithdraw.resentMail'),
            }),
          );
        }
      } catch (err) {
        // console.log('Error', err);
      }
    }
  }
}

// WITHDRAW
function* watchWithdrawRequest(): Saga<void> {
  yield takeLatest(actions.SUBMIT_WITHDRAW_ORDER, submitUserOrder);
}

function* watchUpdateWithdrawInfo(): Saga<void> {
  yield takeLatest(actions.UPDATE_WITHDRAW_INFO, updateWithdrawInfo);
}

// DEPOSIT
function* watchUpdateDepositInfo(): Saga<void> {
  yield takeLatest(actions.UPDATE_DEPOSIT_INFO, updateDepositInfo);
}

// CRYPTO_WITHDRAW
function* watchStartCryptoWithdraw(): Generator<any, any, any> {
  while (true) {
    yield take(actions.START_CRYPTO_WITHDRAW_SESSION);
    yield race({
      _: all([
        call(initCryptoWithdrawProcess),
        call(submitCryptoWithdrawProcess),
        call(resendCryptoWithdrawCodeProcess),
        call(resendCryptoWithdrawConfirmationEmail),
      ]),
      end: take(actions.END_CRYPTO_WITHDRAW_SESSION),
    });
  }
}

function* handleSendInfoReport(action: any): Saga<void> {
  const { year } = action;
  const lastSent = yield select(getLastSent, year);
  const lastSentDate = new Date(lastSent);
  const currDate = new Date();
  const timeDiff = (currDate - lastSentDate) / (1000 * 60); // Difference in minutes

  // Allow sending every 10 minutes
  if (timeDiff >= MIN_SEND_INFO_REPORT_INTERVAL) {
    const { error } = yield call(sendInfoReport, { year });
    if (!error) {
      yield put(
        notificationBannerActions.notifySuccess({
          title: I18n.t('settings.reports.infoReport.title'),
          message: I18n.t('settings.reports.infoReport.success', { year }),
        }),
      );
    }
  } else {
    yield put(
      notificationBannerActions.notifyWarn({
        title: I18n.t('settings.reports.infoReport.title'),
        message: I18n.t('settings.reports.infoReport.successAlreadySent', { year }),
      }),
    );
  }
}

function* bgCryptoAddressSync(crypto: string): Saga<void> {
  while (true) {
    yield call(fetchCryptoDepositInfo, crypto);
    yield delay(TTL_CRYPTO_DEPOSIT_INFO);
  }
}

function* regenerateCryptoAddress(action: any): Saga<void> {
  const { crypto } = action;
  const { canRegenerate } = yield select(getCryptoDepositInfo, crypto);
  if (canRegenerate) {
    const { error } = yield call(regenerateAddress, crypto);
    if (!error) {
      yield put(
        notificationBannerActions.notifySuccess({
          title: I18n.t('cryptoDeposit.title'),
          subTitle: I18n.t('cryptoDeposit.messages.successfullyRegenerated'),
        }),
      );
    } else if (error.code === 'cryptodepositaddressregenerate_err_addressnotused') {
      yield put(
        notificationBannerActions.notifyInfo({
          title: I18n.t('cryptoDeposit.title'),
          subTitle: I18n.t('cryptoDeposit.messages.cannotRegenerateInfo'),
        }),
      );
    } else {
      yield put(
        notificationBannerActions.notifyError({
          title: I18n.t('cryptoDeposit.title'),
          message: I18n.t('cryptoDeposit.messages.genericRegenerateError'),
        }),
      );
    }
  } else {
    yield put(
      notificationBannerActions.notifyInfo({
        title: I18n.t('cryptoDeposit.title'),
        message: I18n.t('cryptoDeposit.messages.cannotRegenerateInfo'),
      }),
    );
  }
}

function* watchRegenerateAddress(): Saga<void> {
  yield takeLatest(actions.GENERATE_ADDRESS, regenerateCryptoAddress);
}

function* handleFetchBankStatementAvailableMonths(): Saga<void> {
  const apiRequest = yield call(authRequest, api.fetchBankStatementAvailableMonths);
  if (!apiRequest.error) {
    yield put(actions.setBankStatementAvailableMonths(apiRequest.response));
  } else {
    yield put(
      notificationBannerActions.notifyError({
        title: I18n.t('settings.reports.bankStatement.title'),
        message: I18n.t('error.genericNetwork'),
      }),
    );
  }
}

function* handleBankStatementRequest({ values }): Saga<void> {
  const apiRequest = yield call(authRequest, api.sendBankStatementRequest, values);
  if (!apiRequest.error) {
    yield put(
      notificationBannerActions.notifySuccess({
        message: I18n.t('settings.reports.bankStatement.web_success'),
      }),
    );
  } else {
    yield put(
      notificationBannerActions.notifyError({
        message: I18n.t('settings.reports.bankStatement.web_error'),
      }),
    );
  }
}

function* handleTransactionHistoryRequest(props: any): Saga<void> {
  const lastSent = yield select(
    getLastTransactionHistorySent,
    props.values.transactionHistoryYear || transactionHistoryYears.all,
  );
  const lastSentDate = new Date(lastSent);
  const currDate = new Date();
  const timeDiff = (currDate - lastSentDate) / (1000 * 60); // Difference in minutes
  if (timeDiff >= MIN_SEND_INFO_REPORT_INTERVAL) {
    const { error } = yield call(sendTransactionHistory, { year: props.values.transactionHistoryYear });
    if (!error) {
      yield put(
        notificationBannerActions.notifySuccess({
          message: props.values.transactionHistoryYear
            ? I18n.t('settings.reports.transactionHistory.success.year', {
                year: `${props.values.transactionHistoryYear}`,
              })
            : I18n.t('settings.reports.transactionHistory.success.allTime'),
        }),
      );
    } else {
      yield put(
        notificationBannerActions.notifyError({
          message: I18n.t('settings.reports.transactionHistory.error'),
        }),
      );
    }
  } else {
    yield put(
      notificationBannerActions.notifyWarn({
        message: props.values.transactionHistoryYear
          ? I18n.t('settings.reports.transactionHistory.successAlreadySent.year', {
              year: `${props.values.transactionHistoryYear}`,
            })
          : I18n.t('settings.reports.transactionHistory.successAlreadySent.allTime'),
      }),
    );
  }
  yield call(props.actions.setSubmitting, false);
}

function* handleCryptoAddressSync(crypto: string): Saga<void> {
  yield fork(bgCryptoAddressSync, crypto);
  yield fork(watchRegenerateAddress);

  // Cancel if we start a new sync or stop
  yield race({
    startNew: take(actions.START_CRYPTO_ADDRESS_SYNC),
    stop: take(actions.STOP_CRYPTO_ADDRESS_SYNC),
  });
  yield cancel();
}

function* watchSendInfoReport(): Saga<void> {
  yield takeLatest(actions.USER_SEND_INFO_REPORT, handleSendInfoReport);
}
function* watchFetchBankStatementAvailableMonths(): Saga<void> {
  yield takeLatest(actions.FETCH_BANK_STATEMENT_AVAILABLE_MONTHS, handleFetchBankStatementAvailableMonths);
}

function* watchBankStatementRequest(): Saga<void> {
  yield takeLatest(actions.SEND_BANK_STATEMENT_REQUEST, handleBankStatementRequest);
}

function* watchSendTransactionHistory(): Saga<void> {
  yield takeLatest(actions.SEND_TRANSACTION_HISTORY, handleTransactionHistoryRequest);
}

function* watchCryptoDeposit(): Saga<void> {
  while (true) {
    const { crypto } = yield take(actions.START_CRYPTO_ADDRESS_SYNC);
    yield fork(handleCryptoAddressSync, crypto);
  }
}

export default function* root(): Saga<void> {
  yield all([
    fork(watchWithdrawRequest),
    fork(watchUpdateDepositInfo),
    fork(watchStartCryptoWithdraw),
    fork(watchSendInfoReport),
    fork(watchCryptoDeposit),
    fork(watchUpdateWithdrawInfo),
    fork(watchFetchBankStatementAvailableMonths),
    fork(watchBankStatementRequest),
    fork(watchSendTransactionHistory),
  ]);
}
