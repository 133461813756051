/* eslint-disable @typescript-eslint/no-unsafe-return */

import { ConfigState } from 'store/types/config';

// in-app default values (which will be merged with values from remote storage)
const defaultValues: ConfigState = {
  SHOULD_PROMPT_FOR_247_TRADING: true,
  STORE_REVIEW_REQUIRED_TRADE_COMPLETIONS: 2,
  STORE_REVIEW_REQUIRED_OPEN_PL_PERC: 5,
  STORE_REVIEW_REQUIRED_CLOSED_PL_PERC: 5,
  STORE_REVIEW_LIMIT_PER_YEAR: 3,
  STORE_REVIEW_SECONDS_DELAY_BEFORE_PRESENTING: 2,
};

// reducer for read-only state → just passes through state
function root(state: ConfigState = defaultValues) {
  return state;
}

export default root;
