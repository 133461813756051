import { RootEpic } from 'types/common';
import { catchError, exhaustMap, filter, map, mergeMap, pluck, switchMap, take } from 'rxjs/operators';
import {
  fetchDevicesFailure,
  fetchDevicesRequest,
  fetchDevicesSuccess,
  unpairDeviceFailure,
  unpairDeviceRequest,
  unpairDeviceSuccess,
} from 'store/slices/devices/actions';
import { getAccessToken } from 'store/selectors/auth';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import I18n from 'i18next';
import { combineEpics } from 'redux-observable';
import { isOfType, isPresent } from 'safetypings';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { LOGIN_USER_SUCCESS } from 'store/actions/auth';
import { of } from 'rxjs';

const watchFetchDevices: RootEpic = (action$, state$, { api, ajax }) =>
  action$.pipe(
    filter(isOfType([fetchDevicesRequest.type, LOGIN_USER_SUCCESS])),
    exhaustMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken).concat('/device/paired'),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(pluck('response'), map(fetchDevicesSuccess)),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(
          handleAjaxError(action$, fetchDevicesFailure, {}, { message: I18n.t('devices.fetchError') }),
        ),
      ),
    ),
  );

const watchUnpairDevice: RootEpic = (action$, state$, { api, ajax }) =>
  action$.pipe(
    filter(unpairDeviceRequest.match),
    exhaustMap(({ payload: { device } }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken).concat('/device/unpair'),
            method: 'POST',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: { deviceHandle: device.deviceHandle },
          }).pipe(
            mergeMap(() =>
              of(
                unpairDeviceSuccess({ device }),
                notificationBannerActions.notifySuccess({
                  message: I18n.t('devices.unpairSuccess'),
                }),
              ),
            ),
          ),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(
          handleAjaxError(
            action$,
            () => unpairDeviceFailure({ device }),
            {},
            { message: I18n.t('devices.unpairError') },
          ),
        ),
      ),
    ),
  );

export default combineEpics(watchFetchDevices, watchUnpairDevice);
