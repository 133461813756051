import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';
import AssetStakingImage from 'common/components/AssetStakingImage';
import {
  Header,
  ImageWrapper,
  Text,
  Title,
  Wrapper,
} from 'common/components/Modal/Staking/StakingFinishModal/styles';
import { ModalProps } from 'types/modals';
import { useAsset } from 'common/hooks/useAsset';
import { ASSET_CLASSES } from 'types/assets';
import { useSelector } from 'react-redux';
import { getStakeUnstakeState } from 'store/slices/staking/selectors';

type Props = {
  onProceed: () => void;
  assetCode: string;
  amount: string;
} & ModalProps;

const StakingFinishModal: FunctionComponent<Props> = ({ onProceed, amount, assetCode, onClose }) => {
  const { t } = useTranslation();
  const { isSuccess: isStakeUnstakeSuccess } = useSelector(getStakeUnstakeState);
  const asset = useAsset(assetCode, ASSET_CLASSES.CRYPTO, undefined);

  const assetColor = asset?.color ?? '';
  const error = !isStakeUnstakeSuccess;

  const title = error
    ? t('staking.finishPopup.titleError')
    : t('staking.finishPopup.titleSuccess', {
        assetCode: assetCode.toUpperCase(),
        amount,
      });

  const text = error
    ? t('staking.finishPopup.textError', {
        assetCode: assetCode.toUpperCase(),
        amount,
      })
    : t('staking.finishPopup.textSuccess', { assetCode: assetCode.toUpperCase(), amount });

  return (
    <ModalTemplate
      defaultOpen
      onCancel={onClose}
      actions={
        <Button
          inverted
          size="modal"
          title={t('staking.finishPopup.proceed')}
          onClick={() => {
            onProceed();
            onClose();
          }}
        />
      }
      persist
      header={
        <Header>
          <ImageWrapper $error={error}>
            <AssetStakingImage color={assetColor} />
          </ImageWrapper>
        </Header>
      }
    >
      <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Wrapper>
    </ModalTemplate>
  );
};

export default memo(StakingFinishModal);
