import { useContext } from 'react';
import TopBarContext from './context';

const useTopBar = () => {
  const context = useContext(TopBarContext);
  if (!context) {
    throw new Error('`useTopBarContext` must be used within a TopBarContext');
  }

  return context;
};

export default useTopBar;
