import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from 'i18next';
import { paths } from 'common/urls';
import { getUser } from 'store/selectors/auth';
import { setTermsAndConditionsClickedAwayTimestamp } from 'store/actions/settings';
import { deleteLastPaymentMethod } from 'store/actions/topUps';
import { resetFeatureTermsAndConditions } from 'store/actions/termsAndConditions';
import { CONSENT_TYPES } from 'common/const/consent';
import { isBetaUser } from 'common/utils/auth';
import Button from '../Button';
import { BetaSettingsContainer, BetaSettingsElementsWrapper, BetaSettingsHeader } from './styles';

const BetaSettings = () => {
  const { username } = useSelector(getUser);
  const dispatch = useDispatch();
  const isBetaSettingsAllowed = isBetaUser(username)

  if (!isBetaSettingsAllowed) return null;

  const currentLang = I18n.language;

  return (
    <BetaSettingsContainer>
      <BetaSettingsHeader>🔥 Beta Settings 🔥</BetaSettingsHeader>
      <BetaSettingsElementsWrapper>
        <Button type="button" to={paths.RENEW_KYC_DOCS} title="Renew KYC flow" />
        <Button
          type="button"
          disabled={currentLang === 'de'}
          onClick={() => {
            void I18n.changeLanguage('de');
          }}
          title="Switch to German language"
        />
        <Button
          type="button"
          disabled={currentLang === 'en'}
          onClick={() => {
            void I18n.changeLanguage('en');
          }}
          title="Switch to English language"
        />
        <Button
          type="button"
          onClick={() => dispatch(setTermsAndConditionsClickedAwayTimestamp(true))}
          title="Reset TNC clicked away timestamp"
        />
        <Button
          type="button"
          onClick={() => dispatch(deleteLastPaymentMethod())}
          title="Delete last payment methods"
        />
        <Button
          type="button"
          onClick={() => dispatch(resetFeatureTermsAndConditions(CONSENT_TYPES.TOP_UPS))}
          title="Reset Topups Feature Specific TNC accepted"
        />
        <Button
          type="button"
          onClick={() => dispatch(resetFeatureTermsAndConditions(CONSENT_TYPES.ACTIVE_STAKING))}
          title="Reset Active Staking Feature Specific TNC accepted"
        />
        <Button
          type="button"
          onClick={() => dispatch(resetFeatureTermsAndConditions(CONSENT_TYPES.PASSIVE_STAKING))}
          title="Reset Passive Staking Feature Specific TNC accepted"
        />
      </BetaSettingsElementsWrapper>
    </BetaSettingsContainer>
  );
};

export default BetaSettings;
