import I18n from 'i18next';

export const TNC_POPUP_TYPES = {
  TNC: 'tnc',
  EXIT_INTENT: 'exitIntent',
} as const;

export const TNC_TIMEOUT_IN_DAYS = 14;

export const getTopUpsTermsAndConditionsEn = () => [
  {
    title: I18n.t('featureTermsAndConditions.documents.1'),
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Special_Services_Prices_v1.0.pdf',
  },
  {
    title: I18n.t('featureTermsAndConditions.documents.2'),
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Sonderbedingungen_v1.11.pdf',
  },
  {
    title: I18n.t('featureTermsAndConditions.documents.3'),
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Special%20Conditions_instanttopups_v1.1.1.pdf',
  },
];

export const getTopUpsTermsAndConditionsDe = () => [
  {
    title: I18n.t('featureTermsAndConditions.documents.1'),
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Sonderleistungen_Preise_v1.0.pdf',
  },
  {
    title: I18n.t('featureTermsAndConditions.documents.2'),
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Sonderbedingungen_v1.11.pdf',
  },
  {
    title: I18n.t('featureTermsAndConditions.documents.3'),
    link: 'https://bisonapp.com/static/documents/BISON_Solaris_Sonderbedingungen_Soforteinzahlung_v1.1.1.pdf',
  },
];

export const getTopUpsTermsAndConditions = () => {
  return I18n.t('l10n.lang') === 'en' ? getTopUpsTermsAndConditionsEn() : getTopUpsTermsAndConditionsDe();
};
