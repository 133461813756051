import { action } from './_common';

export const SET_BETA_MODE = 'SET_BETA_MODE';

export const setBetaMode = () => ({ type: SET_BETA_MODE });

// TYPES
export type SetBetaModeAction = $Call<typeof setBetaMode>;

export type Action = SetBetaModeAction;

export const TOGGLE_STOCKS_ENABLED = 'TOGGLE_STOCKS_ENABLED';
export const toggleStocksEnabled = (payload: boolean) => action(TOGGLE_STOCKS_ENABLED, { payload });

export const TOGGLE_FEATURE_TOP_UPS_ENABLED = 'TOGGLE_FEATURE_TOP_UPS_ENABLED';
export const toggleTopUpsEnabled = (payload: boolean | string) =>
  action(TOGGLE_FEATURE_TOP_UPS_ENABLED, { payload });
