import styled from 'styled-components';
import { Theme } from 'types/theme';
import { devices } from 'common/styles';

export const Wrapper = styled.div`
  align-items: baseline;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.cookieBanner};
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  position: fixed;
  width: 600px;
  z-index: 9999;
  right: 1em;
  bottom: 1em;
  max-width: 100%;
  max-height: calc(100vh - 100px);
  padding: 1em 2em;
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 10px;

  @media${devices.maxTablet} {
    max-width: calc(100% - 2em);
  }
}`;

export const Container = styled.div.attrs({ className: 'standard-text primary-color-text' })`
  word-wrap: break-word;
  padding-top: 10px;
  padding-bottom: 16px;
  overflow-y: auto;
`;

export const FlexButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  & > div {
    flex: 1 1 0px;
    margin: 0;
    height: unset;
    min-height: 40px;
    min-width: unset;
  }
`;

export const Link = styled.a.attrs({ className: 'strong-weight' })`
  color: #73cddd;
`;

export const ExpandedItemsContainer = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  margin: 16px 0;
  width: 100%;
`;

export const ExpandedItem = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
`;

export const IconWrapper = styled.div<{ isChecked: boolean; isWithBorder: boolean; theme: Theme }>`
  height: 16px;
  width: 16px;
  ${({ isChecked, isWithBorder, theme }: { isChecked: boolean; isWithBorder: boolean; theme: Theme }) =>
    `border: ${theme.border.width.large} solid ${
      isChecked && !isWithBorder ? 'transparent' : theme.color.border.input
    }`}
  border-radius: ${({ theme }: { theme: Theme }) => theme.border.radius.standard};
  margin-right: 8px;
  position: relative;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Text = styled.div``;
