import styled from 'styled-components';
import { devices } from 'common/styles';
import documentRenewalImg from 'assets/img/document-renewal.svg';
import videoCallImg from 'assets/img/renew-kyc-ident-video-call.svg';
import kycDocsSuccessImg from 'assets/img/kyc-docs-success.svg';
import kycDocsFailImg from 'assets/img/kyc-docs-fail.svg';
import ModalTemplate from 'common/components/Modal';

export const DocumentRenewalImg = styled.img.attrs({ src: documentRenewalImg })`
  margin: 0 -32px;
  max-width: 256px;
  width: calc(100% + 32px * 2);

  @media ${devices.maxMobileL} {
    margin: 20px -20px 18px;
    width: calc(100% + 20px * 2);
  }
`;

export const VideoCallImg = styled(DocumentRenewalImg).attrs({ src: videoCallImg })`
  margin: 20px -20px -20px;
`;

export const KycDocsSuccessImg = styled(DocumentRenewalImg).attrs({ src: kycDocsSuccessImg })``;
export const KycDocsFailImg = styled(DocumentRenewalImg).attrs({ src: kycDocsFailImg })``;

export const Title = styled.h2`
  margin-bottom: 24px;
  @media ${devices.maxMobileL} {
    margin-bottom: 12px;
  }
`;
export const Text = styled.div.attrs({ className: 'small-text' })`
  margin-bottom: 24px;
`;

export const VideoCallInfoWrapper = styled.div`
  text-align: center;
  @media ${devices.mobileL} {
    padding: 0 46px;
  }
`;

export const ContentCenterWrapper = styled.div`
  position: relative;
  text-align: center;
`;

export const ModalTemplateSpecial = styled(ModalTemplate)`
  max-width: 328px;
`;

export const TitleSpecialSmall = styled.h5`
  font-size: 18px;
`;

export const Actions = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
`;
