import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
  width: 100%;
  cursor: pointer;
`;

export default Button;
