import styled from 'styled-components';

export const Note = styled.div.attrs({ className: 'xsmall-text' })`
  margin-top: 12px;
  margin-bottom: 18px;
`;

export const Header = styled.div.attrs({ className: 'xlarge-text' })`
  padding-right: 20px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  & > * {
    margin: 0;
  }
`;
