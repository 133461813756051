import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEVICES_SLICE_NAME } from 'common/const/slices';
import { Device } from 'types/devices';
import { ApiError } from 'types/error';
import { removeTargetDevice, updateTargetDevice } from 'scenes/PairedDevices/utils';
import { initialState } from './initialState';

const devicesSlice = createSlice({
  name: DEVICES_SLICE_NAME,
  initialState,
  reducers: {
    fetchDevicesRequest: (state, action: PayloadAction<{ refreshing: boolean }>) => {
      state.loading = true;
      state.refreshing = action.payload.refreshing;
    },
    fetchDevicesSuccess: (state, action: PayloadAction<Device[]>) => {
      state.list = action.payload;
      state.loading = false;
      state.refreshing = false;
      state.firstLoadComplete = true;
    },
    fetchDevicesFailure: (state, action: PayloadAction<ApiError>) => {
      state.loading = false;
      state.refreshing = false;
    },
    unpairDeviceRequest: (state, action: PayloadAction<{ device: Device }>) => {
      state.updating = true;
      state.list = updateTargetDevice(state.list, action.payload.device, { updating: true });
    },
    unpairDeviceSuccess: (state, action: PayloadAction<{ device: Device }>) => {
      state.updating = false;
      state.list = removeTargetDevice(state.list, action.payload.device);
    },
    unpairDeviceFailure: (state, action: PayloadAction<{ device: Device }>) => {
      state.updating = false;
      state.list = updateTargetDevice(state.list, action.payload.device, { updating: false });
    },
  },
});

export default devicesSlice;
