import React, { Fragment, ReactNode } from 'react';
import NotificationBanner from 'common/components/NotificationBanner';

/**
 * NotificationProvider adds notifications to the app.
 */
const NotificationProvider = ({ children }: { children: ReactNode }) => {
  return (
    <Fragment>
      <NotificationBanner />
      {children}
    </Fragment>
  );
};

export default NotificationProvider;
