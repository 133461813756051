// @flow
import * as React from 'react';

type FullScreenLayoutProps = {
  children: React.Node;
  location: Location;
};
const FullScreenLayout = ({ children, location, ...rest }: FullScreenLayoutProps) => {
  return <>{React.Children.map(children, (child) => React.cloneElement(child, { ...rest }))}</>;
};

export default FullScreenLayout;
