import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { paths } from '../../urls';

const canonicalHrefsRoutes = [paths.LOGIN, paths.SIGNUP];

type Props = {
  title?: string;
  description?: string;
};

const Seo = ({ title, description }: Props): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const { host, origin } = window.location;
  const canonicalLink = canonicalHrefsRoutes.find((path) => location.pathname.startsWith(path));
  const isProductionHost = host === paths.BISON_TRADE;

  return (
    <Helmet>
      {title && <title>{t(title)}</title>}
      <meta name="description" content={description ? t(description) : t('metaTags.app.description')} />

      {canonicalLink && isProductionHost ? (
        <link rel="canonical" href={`${origin}${canonicalLink}`} />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Helmet>
  );
};

Seo.defaultProps = {
  title: '',
  description: '',
};

export default Seo;
