import { format } from 'date-fns-tz';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import I18next from 'i18next';
import { formatDistance } from 'date-fns';

const localesMap: Record<string, Locale> = {
  en: enLocale,
  de: deLocale,
};

export const addWeeks = (date: Date, weeks: number): Date => {
  const dateCopy = new Date(date);

  dateCopy.setDate(dateCopy.getDate() + 7 * weeks);

  return dateCopy;
};

export const addDays = (date: Date, days: number): Date => {
  const dateCopy = new Date(date);

  dateCopy.setDate(dateCopy.getDate() + days);

  return dateCopy;
};

export const isDateBetween = (date: Date, startDate: Date, endDate: Date): boolean =>
  date >= startDate && date <= endDate;

export const subtractYears = (date: Date, years: number): Date => {
  if (!years) return date;

  date.setFullYear(date.getFullYear() - years);

  return date;
};

export const parseISOUTCStringToUTC = (isoString: string): Date =>
  isoString.endsWith('Z') ? new Date(isoString) : new Date(`${isoString}Z`);

export const formatWithLocale = (date: Date, formatStr: string) => {
  const selectedLocale = localesMap[I18next.t('l10n.lang')];
  return format(date, formatStr, { locale: selectedLocale });
};

export const formatDistanceWithLocale = (date: Date, baseDate: Date) => {
  const selectedLocale = localesMap[I18next.t('l10n.lang')];
  return formatDistance(date, baseDate, { locale: selectedLocale });
};
