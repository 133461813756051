import React from 'react';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { phoneLocales } from 'locales/locales';

type Props = {
  name: string;
  children: JSX.Element;
  initialCountry: string;
};

type Lang = 'en' | 'de';

const PhoneNumberInput = ({ name, initialCountry, ...rest }: Props) => {
  const { t } = useTranslation();

  const lang: Lang = t('l10n.lang');

  const pickerLanguage = phoneLocales[lang] ?? {};
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps<string | null | undefined>) => (
          <PhoneInput
            countryCodeEditable={false}
            localization={pickerLanguage}
            country={initialCountry}
            placeholder="12345..."
            value={field.value}
            onChange={(value) => {
              void form.setFieldValue(name, value);
            }}
            {...rest}
          />
        )}
      </Field>
    </>
  );
};

export default PhoneNumberInput;
