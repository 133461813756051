import i18next from 'i18next';
import * as Yup from 'yup';
import { PriceAlertEntity, NotificationType, PriceAlertType } from 'types/alerts';
import * as formatting from 'common/utils/formatting';
import { LabelTexts } from './types';

export const alertTimeValues: { value: PriceAlertType; label: string; blueBoxLabel: string }[] = [
  {
    value: 'WhenMidPriceCrosses',
    label: 'alerts.typesText.WhenMidPriceCrosses',
    blueBoxLabel: 'alerts.typesText.WhenMidPriceCrosses',
  },
  {
    value: 'WhenSellPriceFallsBelow',
    label: 'alerts.typesText.WhenSellPriceFallsBelow',
    blueBoxLabel: 'alerts.typesText.WhenSellPriceFallsBelow',
  },
  {
    value: 'WhenBuyPriceFallsBelow',
    label: 'alerts.typesText.WhenBuyPriceFallsBelow',
    blueBoxLabel: 'alerts.typesText.WhenBuyPriceFallsBelow',
  },
  {
    value: 'WhenSellPriceRisesAbove',
    label: 'alerts.typesText.WhenSellPriceRisesAbove',
    blueBoxLabel: 'alerts.typesText.WhenSellPriceRisesAbove',
  },
  {
    value: 'WhenBuyPriceRisesAbove',
    label: 'alerts.typesText.WhenBuyPriceRisesAbove',
    blueBoxLabel: 'alerts.typesText.WhenBuyPriceRisesAbove',
  },
];

export const alertTypeValues: { value: NotificationType; label: string }[] = [
  {
    value: 'Email',
    label: 'alerts.sendingType.Email',
  },
  {
    value: 'Push',
    label: 'alerts.sendingType.web_Push',
  },
];

export const suspendTimeValues: { value: string; label: string }[] = [
  {
    value: `${60}`,
    label: 'alerts.gracePeriods.1h',
  },
  {
    value: `${2 * 60}`,
    label: 'alerts.gracePeriods.2h',
  },
  {
    value: `${6 * 60}`,
    label: 'alerts.gracePeriods.6h',
  },
  {
    value: `${12 * 60}`,
    label: 'alerts.gracePeriods.12h',
  },
  {
    value: `${24 * 60}`,
    label: 'alerts.gracePeriods.24h',
  },
  {
    value: `${100000000}`,
    label: 'alerts.gracePeriods.triggerOnce',
  },
];

export const defaultSuspendTimeValue = suspendTimeValues[3].value;

export const suspendTimeTexts: { value: string; firstPart: string; secondPart: string }[] = [
  {
    value: `${60}`,
    firstPart: '',
    secondPart: 'alerts.gracePeriods.1h',
  },
  {
    value: `${2 * 60}`,
    firstPart: '',
    secondPart: 'alerts.gracePeriods.2h',
  },
  {
    value: `${6 * 60}`,
    firstPart: '',
    secondPart: 'alerts.gracePeriods.6h',
  },
  {
    value: `${12 * 60}`,
    firstPart: '',
    secondPart: 'alerts.gracePeriods.12h',
  },
  {
    value: `${24 * 60}`,
    firstPart: '',
    secondPart: 'alerts.gracePeriods.24h',
  },
  {
    value: `${100000000}`,
    firstPart: '',
    secondPart: 'alerts.gracePeriods.triggerOnce',
  },
];

type GetValidationSchemaType = () => Yup.ObjectSchema<
  Yup.Shape<
    object | undefined,
    {
      alertType: number | undefined;
      alertTime: number | undefined;
      alertPrice: number | undefined;
      suspendTime: number | undefined;
    }
  >,
  object
>;

export const priceAlertTypes = {
  NEW: 'new',
  EDIT: 'edit',
};

export const getValidationSchema: GetValidationSchemaType = () =>
  Yup.object().shape({
    alertType: Yup.string().required(),
    alertTime: Yup.string().required(),
    alertPrice: Yup.number().positive(i18next.t('automation.fieldErrors.web_zero')).required(),
    suspendTime: Yup.string(),
  });

export const getNewOrderInitalValues = (): LabelTexts => ({
  alertType: '',
  alertTime: '',
  alertPrice: '',
  suspendTime: '',
});

export const getEditOrderInitialValues = (alert: PriceAlertEntity | undefined): LabelTexts => {
  const alertType = alertTypeValues.find(({ value }) => value === alert?.sendingType);
  const alertTime = alertTimeValues.find(({ value }) => value === alert?.priceAlertType);
  const suspendTime = suspendTimeValues.find(({ value }) => value === alert?.gracePeriodMinutes.toString());
  return {
    alertType: alertType ? alertType.value : alertTypeValues[0].value,
    alertTime: alertTime ? alertTime.value : alertTimeValues[0].value,
    alertPrice: alert?.price ?? '',
    suspendTime: suspendTime ? suspendTime.value : defaultSuspendTimeValue,
  };
};

export const getIsSaveButtonDisabled = (
  alertToEdit: PriceAlertEntity | undefined,
  newValues: LabelTexts,
  isNew: boolean,
  isValid: boolean,
): boolean => {
  if (isNew) {
    const { suspendTime, ...valid } = newValues;
    return Object.values(valid).some((value) => value === '') || !isValid;
  }

  if (!alertToEdit) return true;

  const { price, gracePeriodMinutes, priceAlertType, sendingType } = alertToEdit;
  const { alertTime, alertPrice, suspendTime, alertType } = newValues;

  return (
    price === alertPrice &&
    gracePeriodMinutes.toString() === suspendTime &&
    alertTime === priceAlertType &&
    alertType === sendingType
  );
};

export const calculatePriceAlertSummaryField = (
  value: string,
  fieldName: string,
  currencyPrecision?: number,
) => {
  const placeholder = '   ';
  switch (fieldName) {
    case 'sendingType':
      return value ? i18next.t(`alerts.sendingType.${value}`) : '...';
    case 'entity':
      if (value === '') {
        return placeholder;
      }
      return value.toUpperCase();
    case 'price':
      if (value) {
        return formatting.fiat(value, { customPrecision: currencyPrecision });
      }
      return '.';
    case 'priceAlertType':
      return value
        ? i18next.t(`alerts.typesExplanationText.${value}`, { returnObjects: true })
        : i18next.t('alerts.typesExplanationText.NotSelected', { returnObjects: true });
    case 'gracePeriodMinutes':
      return `${Math.round(Number(value) / 60)}`;
    default:
      return value;
  }
};
