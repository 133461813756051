import * as Yup from 'yup';
import I18n from 'i18next';
import { crypto, numberFromDecimalString } from 'common/utils/formatting';
import { CryptoDetails } from 'types/portfolio';

export const getValidationSchema = (
  assetCode: string,
  minAmount: number,
  maxAmount: number,
  cryptoDetails: CryptoDetails | undefined,
) =>
  Yup.object().shape({
    amount: Yup.string()
      .required(I18n.t('staking.errors.emptyAmount'))
      .test(
        'too-small',
        I18n.t('staking.errors.tooSmall', {
          minAmount: crypto(minAmount, cryptoDetails?.decimalPlaces, { strip_insignificant_zeros: true }),
          code: assetCode.toUpperCase(),
        }),
        (value: any) => numberFromDecimalString(value) >= minAmount,
      )
      .test(
        'too-lagre',
        I18n.t('staking.errors.tooLarge', {
          maxAmount: crypto(maxAmount, cryptoDetails?.decimalPlaces, { strip_insignificant_zeros: true }),
          code: assetCode.toUpperCase(),
        }),
        (value: any) => numberFromDecimalString(value) <= maxAmount,
      ),
  });
