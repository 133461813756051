import { State } from 'store/types/store';
import { createSelector } from '@reduxjs/toolkit';
import { PriceHistorySmallChartEntity } from 'store/types/priceHistory';
import { TimePeriod } from 'types/currency';
import { getMarketOverviewSearchAssetCodes } from 'store/slices/filters/selectors';
import { isSmallChartEntityEligibleForFetching } from 'common/utils/priceHistorySmallChart';
import { isEqualIgnoreCase } from 'common/utils/common';
import { priceHistorySmallChartAdapter } from './adapter';
import { PRICE_HISTORY_SMALL_CHART_SLICE_NAME } from 'common/const/slices';

const priceHistorySmallChartState = (state: State) => state[PRICE_HISTORY_SMALL_CHART_SLICE_NAME];

export const priceHistorySmallChartSelectors =
  priceHistorySmallChartAdapter.getSelectors(priceHistorySmallChartState);

export const getPriceHistorySmallChartFetching = (state: State) =>
  priceHistorySmallChartState(state).isFetching;

export const getPriceHistorySmallChartById = priceHistorySmallChartSelectors.selectById;

export const getAllPriceHistorySmallCharts = priceHistorySmallChartSelectors.selectAll;

/*
 * Time period is selected from the currency state without selector to prevent circular dependency with currency/getCryptosDetails.
 * */
export const getAllPriceHistorySmallChartsForSelectedTimePeriod = createSelector(
  [getAllPriceHistorySmallCharts, (state: State) => state.currency.selectedTimePeriod],
  (priceHistorySmallCharts: PriceHistorySmallChartEntity[], timePeriod: TimePeriod) =>
    priceHistorySmallCharts.filter(
      (priceHistorySmallChart) => priceHistorySmallChart.timePeriod === timePeriod,
    ),
);

export const getMarketOverviewPriceHistorySmallCharts = createSelector(
  [getAllPriceHistorySmallChartsForSelectedTimePeriod, getMarketOverviewSearchAssetCodes],
  (priceHistorySmallCharts: PriceHistorySmallChartEntity[], assetCodes: string[]) =>
    priceHistorySmallCharts.filter((priceHistorySmallChart) =>
      assetCodes.includes(priceHistorySmallChart.assetCode.toLowerCase()),
    ),
);

export const getEligibleSmallChartCodes = (state: State, codes: string[], timePeriod: TimePeriod) => {
  const priceHistorySmallCharts = getAllPriceHistorySmallChartsForSelectedTimePeriod(state);

  return codes.filter((code: string) => {
    const smallChartEntity = priceHistorySmallCharts.find(
      (entity) => isEqualIgnoreCase(entity.assetCode, code) && entity.timePeriod === timePeriod,
    );

    if (!smallChartEntity) return true;

    return isSmallChartEntityEligibleForFetching(smallChartEntity.lastFetched);
  });
};
