import { useSecurities } from 'common/hooks/useSecurities';
import { ROUTE_MANAGEMENT_ROLES } from 'common/const/user';
import { useSelector } from 'react-redux';
import { getUser, getAml, getIsStakingAllowed } from 'store/selectors/auth';
import { BLOCKED_FEATURES_VALUES, accountTypes, kycStatuses } from 'common/const';
import { isAmlOverdue } from 'common/utils/auditProof';
import { $Values } from 'utility-types';
import { getIsCryptoFeatureDisabledWithoutAcceptingTandC } from 'store/selectors/termsAndConditions';
import { getIsSolarisDownErrorOccurred } from 'store/selectors/localSettings';
import { useIsDemoOnlyUser } from 'common/hooks/useIsDemoOnlyUser';

export const useUserGrantedRoles = (): Array<$Values<typeof ROUTE_MANAGEMENT_ROLES>> => {
  const grantedRoles: Array<$Values<typeof ROUTE_MANAGEMENT_ROLES>> = [];
  const user = useSelector(getUser);
  const aml = useSelector(getAml);
  const isSolarisDownErrorOccurred = useSelector(getIsSolarisDownErrorOccurred);
  const { stocksEnabled, canTradeStocks } = useSecurities();
  const isKYCDone = user?.kycStatus === kycStatuses.Confirmed;
  const realMoneyAccountActivated = user?.accountType === accountTypes.real;
  const auditProofHardBlock = isAmlOverdue(aml.confirmationStatus) && !isSolarisDownErrorOccurred;
  const isFeatureDisabledWithoutAcceptingTandC = useSelector(getIsCryptoFeatureDisabledWithoutAcceptingTandC);
  const isDemoOnlyUser = useIsDemoOnlyUser();
  const isStakingAllowed = useSelector(getIsStakingAllowed);

  // TnC blocking phase
  if (!isFeatureDisabledWithoutAcceptingTandC(BLOCKED_FEATURES_VALUES.LO)) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_LO);
  }
  if (!isFeatureDisabledWithoutAcceptingTandC(BLOCKED_FEATURES_VALUES.SO)) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_SO);
  }
  if (!isFeatureDisabledWithoutAcceptingTandC(BLOCKED_FEATURES_VALUES.SP)) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_SP);
  }
  if (!isFeatureDisabledWithoutAcceptingTandC(BLOCKED_FEATURES_VALUES.TRADING)) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING);
  }

  if (auditProofHardBlock) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.AUDIT_PROOF_HARD_BLOCK);
  }

  if (stocksEnabled) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.STOCKS_TRADING_AVAILABLE);
  }

  if (canTradeStocks) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.REAL_STOCKS_FEATURE_AVAILABLE);
  }

  if (isKYCDone) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE);
  }

  if (realMoneyAccountActivated) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.REAL_ACCOUNT);
  }

  if (isDemoOnlyUser) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.DEMO_USER_WITHOUT_KYC);
  }

  if (isStakingAllowed) {
    grantedRoles.push(ROUTE_MANAGEMENT_ROLES.STAKING_AVAILABLE);
  }

  return grantedRoles;
};

export const useIsPageAllowed = ({
  requiredRoles,
  grantedRoles,
}: {
  requiredRoles: Array<$Values<typeof ROUTE_MANAGEMENT_ROLES>>;
  grantedRoles: Array<$Values<typeof ROUTE_MANAGEMENT_ROLES>>;
}): boolean => requiredRoles.every((requiredRole) => grantedRoles.includes(requiredRole));
