export interface UserDetails {
  isSuccessful: boolean;
  username: string | null;
  userDisplayName: string | null;
  mobileNumberMasked: string | null;
  isSecurityCodeRequired: boolean;
}

export const passwordResetSteps = {
  INIT_PASSWORD_RESET: 'INIT_PASSWORD_RESET',
  SECURITY_CODE: 'SECURITY_CODE',
  NEW_PASSWORD_FORM: 'NEW_PASSWORD_FORM',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR: 'PASSWORD_RESET_ERROR',
};

export type PasswordResetStep = keyof typeof passwordResetSteps;
