import { createSelector } from 'reselect';
import { accountTypes, kycStatuses, partners, secretTypes } from 'common/const';
import { Tokens, TwoFA } from 'types/auth';
import { State } from 'store/types/store';
import { User } from 'types/state';
import { parseISOUTCStringToUTC } from 'common/utils/date';
import { isBefore, subMonths } from 'date-fns';
import { getKycCountry } from 'store/selectors/forms';
import { isGermany } from 'common/utils/residency';

export const getLoggedIn = (state: State) => state.auth.loggedIn;
export const getUser = (state: State) => state.auth.user;
export const getUserId = (state: State) => state.auth.user.id;
export const getUserExternalId = (state: State) => state.auth.user.guid;
export const getTokens = (state: State) => state.auth.tokens;
export const getAccessToken = (state: State) => state.auth.tokens.accessToken;
export const getIsKycDone = (state: State) => state.auth.user.kycStatus === kycStatuses.Confirmed;
export const getIsUserInPaperTrading = (state: State) =>
  state.auth.loggedIn && state.auth.user && state.auth.user.accountType === accountTypes.paper;
export const getAccountType = (state: State) => state.auth.user.accountType;
export const getUserCreationDate = (state: State) => state.auth.user.createdAt;
export const getUserRoles = (state: State) => state.auth.user.roles;
export const getUserStocksKnowledgeLevel = (state: State) => state.auth.user.stocksKnowledgeLevel;
export const getIsKycRenewRequired = (state: State) => state.auth.user.isKycRenewRequired;
export const getRenewKycCode = (state: State) => state.auth.renewKycCode;
export const getUserStocksQuestionsLastAnsweredDate = (state: State) =>
  state.auth.user.stocksQuestionsLastAnsweredDate;
export const getIsWaitingConfirmation = (state: State) => state.auth.isWaitingConfirmation;
export const getLoggingIn = (state: State) => state.auth.loggingIn;
export const getIsResendingConfirmationEmail = (state: State) => state.auth.resendingConfirmationEmail;

export const getAuthSecret = createSelector(
  getTokens,
  (tokens: Tokens): { secret: string; secretType: string } => {
    if (tokens.clientToken) {
      return { secret: tokens.clientToken, secretType: secretTypes.clientToken };
    }
    return { secret: tokens.clientKey, secretType: secretTypes.clientKey };
  },
);

export const getInfoReportYears = (state: State) => {
  const { createdAt } = state.auth.user;
  const userJoinedYear = createdAt ? new Date(createdAt).getFullYear() : 2018;
  const currYear = new Date().getFullYear();
  const years = [];
  for (let i = currYear; i >= 2018; i--) {
    years.push(i);
  }
  return years.filter((year) => year >= userJoinedYear).sort();
};

export const getIsB2bUser = createSelector(getUser, (user: User): boolean => {
  return user?.partnerId !== undefined && user?.partnerId?.toLowerCase() !== partners.bison;
});

export const getStocksTradingAccountNumber = (state: State): number =>
  state.auth.user.stocksTradingAccountNumber;

export const getKycStatus = (state: State) => state.auth.user.kycStatus;
export const getKycRenewStatus = (state: State) => state.auth.user.kycRenewStatus;
export const getSecuritiesKycStatus = (state: State) => state.auth.user.stocksUserAccountStatus;
export const getGet2faData = (state: State): TwoFA => state.auth.twofa;
export const getAml = (state: State) =>
  state.auth.user.amlUpdate || {
    status: 'Unspecified',
    confirmationStatus: 'Unspecified',
    followUpDate: '',
    countdown: 0,
  };

export const getIsDemoOnlyUser = createSelector(
  [getIsUserInPaperTrading, getKycStatus],
  (isDemo, cryptoKycStatus): boolean => {
    const cryptoKycStatusConfirmed = cryptoKycStatus === kycStatuses.Confirmed;
    return !cryptoKycStatusConfirmed && isDemo;
  },
);

export const getIsKycConfirmedLongerThanNumberOfMonths = createSelector(
  [getUser, (state: State, numOfMonth: number) => numOfMonth],
  (user: User, numOfMonth: number): boolean => {
    const { confirmedKycStatusAt } = user;

    if (!confirmedKycStatusAt) return false;

    return isBefore(
      new Date(parseISOUTCStringToUTC(confirmedKycStatusAt)),
      subMonths(new Date(), numOfMonth),
    );
  },
);

export const getIsKycCountryEqualTo = createSelector(
  [getUser, (state: State, country: string) => country],
  (user: User, country: string): boolean => {
    const { address } = user;

    if (!address?.country) return false;

    return address.country === country;
  },
);

export const getMobileNumber = (state: State) => state.auth.user.mobileNumber;

export const getIsStakingAllowed = createSelector(
  [getIsUserInPaperTrading, getKycCountry],
  (isDemo, country): boolean => !isDemo && isGermany(country),
);
