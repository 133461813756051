import { State } from 'store/types/store';
import { tradingRulesAdapter } from './adapter';
import { TRADING_RULES_SLICE_NAME } from 'common/const/slices';
import { createSelector } from 'reselect';
import { getAllPrices } from '../prices/selectors';
import { TradingRuleEntity, TradingRuleListItem } from 'types/tradingRules';
import { PriceEntity } from 'store/types/prices';
import { getTradingRuleListItem, getTradingRulesListItems } from 'common/utils/tradingRules';
import { getCryptoEntities } from '../assets/selectors';
import { Crypto } from 'types/assets';

const tradingRulesState = (state: State) => state[TRADING_RULES_SLICE_NAME];

const tradingRulesSelectors = tradingRulesAdapter.getSelectors(tradingRulesState);

/**
 * Returns trading rule by id or undefined if not found.
 *
 * @summary Trading rule by id.
 *
 * @public
 * @param {State} state
 * @param {EntityId} id
 * @returns {TradingRuleEntity | undefined}
 */
export const getTradingRuleById = tradingRulesSelectors.selectById;

/**
 * Returns all trading rules that are stored in this state adapter.
 *
 * @summary All trading rules.
 *
 * @public
 * @param {State} state
 * @returns {TradingRuleEntity[]}
 */
export const getAllTradingRules = tradingRulesSelectors.selectAll;

/**
 * Returns all trading orders list items with current prices.
 *
 * @summary Trading order models.
 *
 * @public
 * @param {State} state
 * @returns {TradingRuleListItem[]}
 */
export const getAllTradingOrdersWithPrices = createSelector(
  [getAllTradingRules, getAllPrices, getCryptoEntities],
  (tradingRules: TradingRuleEntity[], prices: PriceEntity[], cryptos: Crypto[]) =>
    getTradingRulesListItems(tradingRules, prices, cryptos),
);

/**
 * Returns trading rule by id with current price.
 *
 * @summary Trading rule with price.
 *
 * @public
 * @param {State} state
 * @param {string} id
 * @returns {TradingRuleListItem}
 */
export const getTradingOrdersWithPricesById = createSelector(
  [getAllTradingRules, getAllPrices, getCryptoEntities, (state: State, props: string) => props],
  (tradingRules: TradingRuleEntity[], prices: PriceEntity[], cryptos: Crypto[], id: string) => {
    const tradingRule = tradingRules.find((rule) => rule.uniqueId.toLowerCase() === id.toLowerCase());

    if (!tradingRule) return undefined;

    return getTradingRuleListItem(tradingRule, prices, cryptos);
  },
);

/**
 * Returns if trading rule create is in progress.
 *
 * @summary Create trading rule in progress.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const isTradingRuleCreateInProgress = (state: State) => tradingRulesState(state).isCreateInProgress;

/**
 * Returns if trading rules fetch is in progress.
 *
 * @summary Fetch trading rules in progress.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const isTradingRuleFetchInProgress = (state: State) => tradingRulesState(state).isFetchInProgress;

/**
 * Returns if the adapter has any element.
 *
 * @summary Is there any trading rules.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const areThereAnyTradingRules = (state: State) => tradingRulesSelectors.selectTotal(state) !== 0;
