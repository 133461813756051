import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { catchError, ignoreElements } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/react';
import { RootAction, RootEpic, RootState } from 'types/common';
import { AppReduxEpicMiddleware } from 'store/types/store';
import formsEpics from './epics/forms';
import remotePersistEpic from './epics/persist';
import {
  handleBisonPageStatus,
  handleBisonStatusLocation,
  handleLocationsChange,
  handlePageStatusChange,
  handleParentLocationChange,
} from './epics/notifications';
import sessionEpic from './epics/storage';
import analytics from './epics/analytics';
import userEpics from './epics/user';
import termsAndConditionsEpics from './epics/termsAndConditions';
import portfolioEpics from './epics/portfolio';
import portfolioChartEpics from './epics/portfolioChart';
import trackingConfigurationEpic from './epics/trackingConfiguration';
import news from './epics/news';
import assetsEpic from './epics/assets';
import statusEpic from './epics/status';
import stockEtfOrder from './epics/stockEtfOrder';
import renewKycDocs from './epics/renewKycDocs';
import deviceMonitoring from './epics/deviceMonitoring';
import { fetchStockRaffleTransationCount } from './epics/stockRaffle';
import resetPassword from './epics/resetPassword';
import inboxEpic from './epics/inbox';
import topUps from './epics/topUps';
import environmentEpic from './epics/environment';
import filtersEpic from './epics/filters';
import auditProofEpic from './epics/auditProof';
import activityEpic from './epics/activity';
import emailConfirmEpic from './epics/emailConfirm';
import experimentsEpic from './epics/experiments';
import marketOverviewEpic from './epics/marketOverview';
import pricesEpic from './epics/prices';
import priceChangesEpic from './epics/priceChanges';
import priceHistoryEpic from './epics/priceHistory';
import priceAlertsEpic from './epics/priceAlerts';
import savingsPlansEpic from './epics/savingsPlans';
import tradingRulesEpic from './epics/tradingRules';
import priceHistorySmallChartEpic from './epics/priceHistorySmallChart';
import taxExemptionEpics from './epics/taxExemption';
import authEpics, { watchWaitConfirmationEpic } from './epics/auth';
import devicesEpic from './epics/devices';
import lossTaxExemptionsEpic from './epics/lossTaxExemptions';
import kycEpic from './epics/kyc';
import stakingEpic from './epics/staking';
import referralEpic from './epics/referral';
import newsletterEpic from './epics/newsletter';


const combinedEpic = combineEpics(
  environmentEpic,
  remotePersistEpic,
  sessionEpic,
  handlePageStatusChange,
  handleLocationsChange,
  handleBisonPageStatus,
  handleBisonStatusLocation,
  handleParentLocationChange,
  ...Object.values(analytics),
  ...Object.values(formsEpics),
  userEpics,
  trackingConfigurationEpic,
  news,
  assetsEpic,
  stockEtfOrder,
  statusEpic,
  renewKycDocs,
  deviceMonitoring,
  inboxEpic,
  fetchStockRaffleTransationCount,
  resetPassword,
  topUps,
  filtersEpic,
  activityEpic,
  auditProofEpic,
  emailConfirmEpic,
  termsAndConditionsEpics,
  experimentsEpic,
  marketOverviewEpic,
  pricesEpic,
  priceChangesEpic,
  priceHistoryEpic,
  priceHistorySmallChartEpic,
  portfolioEpics,
  portfolioChartEpics,
  stakingEpic,
  priceAlertsEpic,
  savingsPlansEpic,
  tradingRulesEpic,
  referralEpic,
  newsletterEpic,
  // ??????
  watchWaitConfirmationEpic,
  authEpics,
  taxExemptionEpics,
  devicesEpic,
  lossTaxExemptionsEpic,
  kycEpic,
) as RootEpic;

const rootEpic: RootEpic = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
  dependencies: AppReduxEpicMiddleware,
): Observable<RootAction> =>
  combinedEpic(action$, state$, dependencies).pipe(
    catchError((error) => {
      Sentry.captureException(error);

      return ignoreElements();
    }),
  );

export default rootEpic;
