/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/ban-types */

import { call, put } from 'redux-saga/effects';

import { logEvent as _logEvent } from 'store/actions/tracking';

function* logEvent(
  group: string,
  action: string,
  object: string,
  objectId: string,
  screen: string,
  extraProperties: any = {},
): Generator<any, any, any> {
  yield put(
    _logEvent({
      time: new Date().toISOString(),
      group,
      action,
      object,
      objectId,
      screen,
      properties: {
        // Add any default properties here
        ...extraProperties,
      },
    }),
  );
}

//
// DO NOT MANUALLY EDIT THE FUNCTIONS BELOW
//
// They are generated from: https://docs.google.com/spreadsheets/d/1dTBliVHpmsLX_d1m03lXvBBoAeTq635x7lGlD0uLQ2w/edit#gid=1192106238
//
// -------------- App level events --------------

/**
 * User opens the app or brings it from the background
 */
export function* trackAppOpenAppBison(
  screenId: string,
  loadTimeMsec: number,
  timezone: string,
  originalLanguage: string,
  appLanguage: string,
  appOpenVariant: string,
  deepLink: string,
  referrer: string | null = null,
  pathnameProps: { [key: string]: string } = {},
): Generator<any, any, any> {
  const customProperties = {
    loadTimeMsec,
    timezone,
    originalLanguage,
    appLanguage,
    appOpenVariant,
    deepLink,
    referrer,
    ...pathnameProps,
  };
  yield call(logEvent, 'App', 'Open', 'App', 'Bison', screenId, customProperties);
}

/**
 * User exits the app
 */
export function* trackAppCloseAppBison(
  screenId: string,
  sessionTimeMsec: number,
  pathnameProps: { [key: string]: string } = {},
): Generator<any, any, any> {
  const customProperties = {
    sessionTimeMsec,
    ...pathnameProps,
  };
  yield call(logEvent, 'App', 'Close', 'App', 'Bison', screenId, customProperties);
}

// -------------- Geenric display and navigation events --------------

/**
 * User opens a screen
 */
export function* trackDisplayOpenScreen(
  screenId: string,
  fromScreenId: string,
  pathnameProps: { [key: string]: string } = {},
  fromPathnameProps: { [key: string]: string } = {},
): Generator<any, any, any> {
  const customProperties = {
    ...pathnameProps,
    ...fromPathnameProps,
  };
  yield call(logEvent, 'Display', 'Open', 'Screen', screenId, fromScreenId, customProperties);
}

/**
 * User opens a modal
 */
export function* trackDisplayOpenModal(
  modalId: string,
  screenId: string,
  internalId: string,
  modalType: 'modal' | 'overlay' = 'modal',
): Generator<any, any, any> {
  const customProperties = {
    internalId,
    modalType,
  };
  yield call(logEvent, 'Display', 'Open', 'Modal', modalId, screenId, customProperties);
}

/**
 * User closes a modal
 */
export function* trackDisplayCloseModalUnknown(
  screenId: string,
  internalId: string,
  modalType: 'modal' | 'overlay' = 'modal',
): Generator<any, any, any> {
  const customProperties = {
    internalId,
    modalType,
  };
  yield call(logEvent, 'Display', 'Close', 'Modal', 'Unknown', screenId, customProperties);
}

/**
 * Modal is clicked (either the whole modal if there is an action assigned or a specific button on the banner
 *   which specifies the possitive action that is advertised)
 */
export function* trackDisplayClickModal(
  modalId: string,
  screenId: string,
  clickTarget: string,
  modalType: 'modal' | 'overlay' = 'modal',
): Generator<any, any, any> {
  const customProperties = {
    clickTarget,
    modalType,
  };
  yield call(logEvent, 'Display', 'Click', 'Modal', modalId, screenId, customProperties);
}

/**
 * Dropdown notification is shown to the user
 */
export function* trackDisplayShowNotification(
  type: string,
  screenId: string,
  contentId: string,
  message: string,
): Generator<any, any, any> {
  const customProperties = {
    contentId,
    message,
  };
  yield call(logEvent, 'Display', 'Show', 'Notification', type, screenId, customProperties);
}

/**
 * Dropdown notification is closed (by user or any other action)
 */
export function* trackDisplayCloseNotification(
  type: string,
  screenId: string,
  contentId: string | null,
  message: string,
  closeType: 'userIgnored' | 'closeButton' | 'swipe',
): Generator<any, any, any> {
  const customProperties = {
    contentId,
    message,
    closeType,
  };
  yield call(logEvent, 'Display', 'Close', 'Notification', type, screenId, customProperties);
}

/**
 * On screen banner is shown to the user
 */
export function* trackDisplayShowBanner(
  bannerId: string,
  screenId: string,
  message: string | null,
  displayProperties: object,
): Generator<any, any, any> {
  const customProperties = {
    message,
    displayProperties,
  };
  yield call(logEvent, 'Display', 'Show', 'Banner', bannerId, screenId, customProperties);
}

/**
 * On screen banner is closed by the user or otherwise if possible
 */
export function* trackDisplayCloseBanner(
  bannerId: string,
  screenId: string,
  closeType: string,
): Generator<any, any, any> {
  const customProperties = {
    closeType,
  };
  yield call(logEvent, 'Display', 'Close', 'Banner', bannerId, screenId, customProperties);
}

/**
 * On screen banner is clicked (either the whole banner or a specific button on the banner which specifies the
 *   possitive action that is advertised)
 */
export function* trackDisplayClickBanner(
  bannerId: string,
  screenId: string,
  clickTarget: string,
): Generator<any, any, any> {
  const customProperties = {
    clickTarget,
  };
  yield call(logEvent, 'Display', 'Click', 'Banner', bannerId, screenId, customProperties);
}

/**
 * A form or a single control data validation displays an error
 */
export function* trackDisplayShowValidationError(
  validationFormId: string,
  screenId: string,
  componentList: { componentId: string; componentType: string; error: string }[],
): Generator<any, any, any> {
  const customProperties = {
    componentList,
  };
  yield call(logEvent, 'Display', 'Show', 'ValidationError', validationFormId, screenId, customProperties);
}

// -------------- Generic interaction --------------

/**
 * User presses a button
 */
export function* trackInteractPressButton(
  componentId: string,
  screenId: string,
  componentType: string,
  buttonText: string,
): Generator<any, any, any> {
  const customProperties = {
    componentType,
    buttonText,
  };
  yield call(logEvent, 'Interact', 'Press', 'Button', componentId, screenId, customProperties);
}

/**
 * User presses a button linked to a specific enity / db item, e.g. edit/delete taxId, edit/delete price
 *   alert, ...
 */
export function* trackInteractPressEntityButton(
  componentId: string,
  screenId: string,
  componentType: string,
  buttonText: string,
  entityType: string | null = null,
  entityId: string | null = null,
): Generator<any, any, any> {
  const customProperties = {
    componentType,
    buttonText,
    entityType,
    entityId,
  };
  yield call(logEvent, 'Interact', 'Press', 'EntityButton', componentId, screenId, customProperties);
}

/**
 * An event when a user clicks on an item within a list of objects. This could be used for various lists such
 *   as a transaction history, a portfolio view of crypto assets, or a list of staked assets.
 *
 * @param listId A unique identifier for the component displaying the list of objects. This should be unique
 *   within the application. If the event is triggered generically for all list displaying components, use a
 *   specific ID like ""TransactionHistory"", ""TradableAssets"", ""PortfolioItems"", etc.
 *
 * @param listItemId - A meaningful identifier for the item within the list, such as an asset name or
 *   transaction ID.
 * @param listItemSeq - The sequence number of the item within the current list, indicating its position. For
 *   example, 2 if the item is in the second place in the list.
 * @param listItemProperties - An object containing meaningful properties of the clicked item. For instance,
 *   if the item is a portfolio asset, it might include the asset's value.
 * @param listLength - The total number of objects in the list that was clicked. This value should be greater
 *   than or equal to listItemSeq.
 * @param listProperties - An object containing relevant properties of the entire list, such as whether a
 *   filter was applied to a transaction history list.
 */
export function* trackInteractPressListItem(
  listId: string,
  screenId: string,
  listItemId: string,
  listItemSeq: number,
  listItemProperties: object,
  listLength: number,
  listProperties: object,
): Generator<any, any, any> {
  const customProperties = {
    listItemId,
    listItemSeq,
    listItemProperties,
    listLength,
    listProperties,
  };
  yield call(logEvent, 'Interact', 'Press', 'ListItem', listId, screenId, customProperties);
}

/**
 * User switches a checkbox on/off
 */
export function* trackInteractToggleSwitch(
  componentId: string,
  screenId: string,
  componentType: string,
  newValue: boolean,
): Generator<any, any, any> {
  const customProperties = {
    componentType,
    newValue,
  };
  yield call(logEvent, 'Interact', 'Toggle', 'Switch', componentId, screenId, customProperties);
}

/**
 * User finalizes entering the value into a numerical field - focus changes of the component
 */
export function* trackInteractModifyNumeric(
  componentId: string,
  screenId: string,
  componentType: string,
  oldValue: number,
  newValue: number,
): Generator<any, any, any> {
  const customProperties = {
    componentType,
    oldValue,
    newValue,
  };
  yield call(logEvent, 'Interact', 'Modify', 'Numeric', componentId, screenId, customProperties);
}

/**
 * User select a value in dropdown or selection box
 */
export function* trackInteractModifySelection(
  componentId: string,
  screenId: string,
  componentType: string,
  oldValue: string[],
  newValue: string[],
): Generator<any, any, any> {
  const customProperties = {
    componentType,
    oldValue,
    newValue,
  };
  yield call(logEvent, 'Interact', 'Modify', 'Selection', componentId, screenId, customProperties);
}

/**
 * User selects/focuses on a text field
 */
export function* trackInteractSelectText(
  componentId: string,
  screenId: string,
  componentType: string,
  textLength: number,
): Generator<any, any, any> {
  const customProperties = {
    componentType,
    textLength,
  };
  yield call(logEvent, 'Interact', 'Select', 'Text', componentId, screenId, customProperties);
}

/**
 * User finalizes entering the text into a text field - focus changes of the component
 */
export function* trackInteractEnterText(
  componentId: string,
  screenId: string,
  componentType: string,
  textLength: number,
): Generator<any, any, any> {
  const customProperties = {
    componentType,
    textLength,
  };
  yield call(logEvent, 'Interact', 'Enter', 'Text', componentId, screenId, customProperties);
}

// -------------- Ab tests --------------

/**
 * Form or a single control data validation displays an error
 */
export function* trackAbTestEnterVariantAbTest(
  abTestId: string,
  screenId: string,
  variantId: string,
): Generator<any, any, any> {
  const customProperties = {
    variantId,
  };
  yield call(logEvent, 'AbTest', 'EnterVariant', 'AbTest', abTestId, screenId, customProperties);
}

// -------------- MobileNotification screen events --------------

/**
 * User presses [SwitchToApp] on welcome.Welcome (newUser, loginOrSignup or login mode) screen
 */
export function* trackMobileNotificationPressButtonSwitchToApp(screenId: string): Generator<any, any, any> {
  yield call(logEvent, 'MobileNotification', 'Press', 'Button', 'SwitchToApp', screenId, {});
}

/**
 * User presses [ContinueOnWeb] on welcome.Welcome (newUser, loginOrSignup or login mode) screen
 */
export function* trackMobileNotificationPressButtonContinueOnWeb(screenId: string): Generator<any, any, any> {
  yield call(logEvent, 'MobileNotification', 'Press', 'Button', 'ContinueOnWeb', screenId, {});
}

// -------------- Login screen events --------------

/**
 * Login with password screen opens on welcome.SignInForm (newUser or existingUser) screen
 */
export function* trackLoginOpenScreen(
  screenId: string,
  fromScreenId: string,
  loginState: string,
  isBiometricsEnabled: boolean,
): Generator<any, any, any> {
  const customProperties = {
    loginState,
    isBiometricsEnabled,
  };
  yield call(logEvent, 'Login', 'Open', 'Screen', screenId, fromScreenId, customProperties);
}

/**
 * User enters email on welcome.SignInForm (newUser or existingUser) screen
 */
export function* trackLoginEnterTextEmail(screenId: string, isValidEmail: boolean): Generator<any, any, any> {
  const customProperties = {
    isValidEmail,
  };
  yield call(logEvent, 'Login', 'Enter', 'Text', 'Email', screenId, customProperties);
}

/**
 * User enters password on welcome.SignInForm (newUser or existingUser) screen
 */
export function* trackLoginEnterTextPassword(screenId: string): Generator<any, any, any> {
  yield call(logEvent, 'Login', 'Enter', 'Text', 'Password', screenId, {});
}

/**
 * User presses [Log in] on welcome.SignInForm (newUser or existingUser) screen
 */
export function* trackLoginPressButtonLogin(
  screenId: string,
  pressType: string | null = null,
): Generator<any, any, any> {
  const customProperties = {
    pressType,
  };
  yield call(logEvent, 'Login', 'Press', 'Button', 'Login', screenId, customProperties);
}

/**
 * User presses [Forgot password?] on welcome.SignInForm (newUser or existingUser) screen
 */
export function* trackLoginPressButtonForgotPass(screenId: string): Generator<any, any, any> {
  yield call(logEvent, 'Login', 'Press', 'Button', 'ForgotPass', screenId, {});
}

/**
 * Log in successful
 */
export function* trackLoginSuccessEventLogin(
  screenId: string,
  accountType: string,
  loginMethod: string,
  askedFor2FA: boolean,
): Generator<any, any, any> {
  const customProperties = {
    accountType,
    loginMethod,
    askedFor2FA,
  };
  yield call(logEvent, 'Login', 'Success', 'Event', 'Login', screenId, customProperties);
}

/**
 * Log in error
 */
export function* trackLoginErrorEventLogin(
  screenId: string,
  accountType: string,
  loginMethod: string,
  exceptionCode: string,
  askedFor2FA: boolean,
): Generator<any, any, any> {
  const customProperties = {
    accountType,
    loginMethod,
    exceptionCode,
    askedFor2FA,
  };
  yield call(logEvent, 'Login', 'Error', 'Event', 'Login', screenId, customProperties);
}

// -------------- Settings menu events --------------

/**
 * Light/dark mode settings was changed - new value is reported by this event
 */
export function* trackSettingsModifySettingTheme(
  screenId: string,
  theme: 'dark' | 'light',
): Generator<any, any, any> {
  const customProperties = {
    theme,
  };
  yield call(logEvent, 'Settings', 'Modify', 'Setting', 'Theme', screenId, customProperties);
}

// -------------- Kyc process --------------

/**
 * Kyc screen opens - does not trigger on separate steps on common.KycProcess screen
 */
export function* trackKycOpenScreen(
  screenId: string,
  fromScreenId: string,
  existingKycData: boolean,
): Generator<any, any, any> {
  const customProperties = {
    existingKycData,
  };
  yield call(logEvent, 'Kyc', 'Open', 'Screen', screenId, fromScreenId, customProperties);
}

/**
 * Kyc steps/screens on common.KycProcess screen
 */
export function* trackKycOpenStep(
  kycStepName: string,
  screenWithKycStepId: string,
  birthCountry: string,
  country: string,
  nationality: string,
  verificationCategory: string,
): Generator<any, any, any> {
  const customProperties = {
    birthCountry,
    country,
    nationality,
    verificationCategory,
  };
  yield call(logEvent, 'Kyc', 'Open', 'Step', kycStepName, screenWithKycStepId, customProperties);
}

/**
 * [Next] pressed on common.KycProcess screen
 */
export function* trackKycPressButtonNextKycStep(
  screenWithKycStepId: string,
  success: boolean,
  validationErrors: any,
): Generator<any, any, any> {
  const customProperties = {
    success,
    validationErrors,
  };
  yield call(logEvent, 'Kyc', 'Press', 'Button', 'NextKycStep', screenWithKycStepId, customProperties);
}

/**
 * [Back] pressed on common.KycProcess screen
 */
export function* trackKycPressButtonPreviousKycStep(screenWithKycStepId: string): Generator<any, any, any> {
  yield call(logEvent, 'Kyc', 'Press', 'Button', 'PreviousKycStep', screenWithKycStepId, {});
}

/**
 * Kyc control is transfered to IdNow sdk on common.KycProcess screen
 */
export function* trackKycOpenSdkIdNow(screenWithKycStepId: string): Generator<any, any, any> {
  yield call(logEvent, 'Kyc', 'Open', 'Sdk', 'IdNow', screenWithKycStepId, {});
}

/**
 * Kyc control is transfered back to bison from IdNow sdk on common.KycProcess screen
 */
export function* trackKycCloseSdkIdNow(
  screenWithKycStepId: string,
  success: boolean,
  validationErrors: any,
): Generator<any, any, any> {
  const customProperties = {
    success,
    validationErrors,
  };
  yield call(logEvent, 'Kyc', 'Close', 'Sdk', 'IdNow', screenWithKycStepId, customProperties);
}

// -------------- Portfolio screen events --------------

/**
 * Portfolio screen open
 */
export function* trackPortfolioOpenScreen(
  screenId: string,
  fromScreenId: string,
  portfolioChartType: string,
): Generator<any, any, any> {
  const customProperties = {
    portfolioChartType,
  };
  yield call(logEvent, 'Portfolio', 'Open', 'Screen', screenId, fromScreenId, customProperties);
}

/**
 * Switch/toggle between time and pie chart
 */
export function* trackPortfolioModifySelectionPortfolioChartType(
  screenId: string,
  portfolioChartType: string,
): Generator<any, any, any> {
  const customProperties = {
    portfolioChartType,
  };
  yield call(logEvent, 'Portfolio', 'Modify', 'Selection', 'PortfolioChartType', screenId, customProperties);
}

/**
 * Interact start with time or pie chart
 */
export function* trackPortfolioInteractChartPortfolioChart(
  screenId: string,
  portfolioChartType: string,
  interactionTimeMsec: number,
): Generator<any, any, any> {
  const customProperties = {
    portfolioChartType,
    interactionTimeMsec,
  };
  yield call(logEvent, 'Portfolio', 'Interact', 'Chart', 'PortfolioChart', screenId, customProperties);
}

// -------------- News feed --------------

/**
 * News feed screen opens on newsFeed screen
 */
export function* trackNewsFeedOpenScreen(
  screenId: string,
  fromScreenId: string,
  newsLanguage: string,
  loadedNewsIds: string[],
): Generator<any, any, any> {
  const customProperties = {
    newsLanguage,
    loadedNewsIds,
  };
  yield call(logEvent, 'NewsFeed', 'Open', 'Screen', screenId, fromScreenId, customProperties);
}

/**
 * [More news] pressed on buy-sell.CurrencyDetail screen
 */
export function* trackNewsFeedPressButtonOpenNewsFeed(
  screenId: string,
  newsLanguage: string,
  loadedNewsIds: string[],
): Generator<any, any, any> {
  const customProperties = {
    newsLanguage,
    loadedNewsIds,
  };
  yield call(logEvent, 'NewsFeed', 'Press', 'Button', 'OpenNewsFeed', screenId, customProperties);
}

/**
 * News language changed on buy-sell.CurrencyDetail
 * newsFeed screen
 */
export function* trackNewsFeedModifySelectionNewsLanguage(
  screenId: string,
  newsLanguage: string,
  loadedNewsIds: string[],
): Generator<any, any, any> {
  const customProperties = {
    newsLanguage,
    loadedNewsIds,
  };
  yield call(logEvent, 'NewsFeed', 'Modify', 'Selection', 'NewsLanguage', screenId, customProperties);
}

/**
 * User scrolls news list on newsFeed screen
 */
export function* trackNewsFeedScrollListNewsItem(
  screenId: string,
  newsLanguage: string,
  loadedNewsIds: string[],
  visibleNewsIds: string[],
): Generator<any, any, any> {
  const customProperties = {
    newsLanguage,
    loadedNewsIds,
    visibleNewsIds,
  };
  yield call(logEvent, 'NewsFeed', 'Scroll', 'List', 'NewsItem', screenId, customProperties);
}

/**
 * Specific news item clicked on buy-sell.CurrencyDetail
 * newsFeed screen
 */
export function* trackNewsFeedPressListNewsItem(
  screenId: string,
  newsLanguage: string,
  loadedNewsIds: string[],
  pressedNewsId: string,
): Generator<any, any, any> {
  const customProperties = {
    newsLanguage,
    loadedNewsIds,
    pressedNewsId,
  };
  yield call(logEvent, 'NewsFeed', 'Press', 'List', 'NewsItem', screenId, customProperties);
}

// -------------- Trading rule list view --------------

/**
 * User presses [New limit/stop order] on trading-manager.TradingManager screen
 */
export function* trackTradingRulePressButtonNewTradingRule(
  screenId: string,
  totalCount: number,
  loadedCount: number,
  tradingRuleType: 'limit' | 'stop',
): Generator<any, any, any> {
  const customProperties = {
    totalCount,
    loadedCount,
    tradingRuleType,
  };
  yield call(logEvent, 'TradingRule', 'Press', 'Button', 'NewTradingRule', screenId, customProperties);
}

/**
 * User scrolls trading rule list on trading-manager.TradingManager screen
 */
export function* trackTradingRuleScrollListTradingRule(
  screenId: string,
  totalCount: number,
  loadedCount: number,
): Generator<any, any, any> {
  const customProperties = {
    totalCount,
    loadedCount,
  };
  yield call(logEvent, 'TradingRule', 'Scroll', 'List', 'TradingRule', screenId, customProperties);
}

// -------------- Trading rule detail --------------

/**
 * User presses [Delete] trading rule on automation.TradingRuleDetail screen
 */
export function* trackTradingRuleDetailPressButtonDeleteTradingRule(
  screenId: string,
  tradingRuleId: number,
  tradingRuleType: 'limit' | 'stop',
): Generator<any, any, any> {
  const customProperties = {
    tradingRuleId,
    tradingRuleType,
  };
  yield call(
    logEvent,
    'TradingRuleDetail',
    'Press',
    'Button',
    'DeleteTradingRule',
    screenId,
    customProperties,
  );
}

// -------------- Savings plan list view --------------

/**
 * User presses [New savings plan] on trading-manager.TradingManager screen
 */
export function* trackSavingsPlanPressButtonNewSavingsPlan(
  screenId: string,
  totalCount: number,
  loadedCount: number,
): Generator<any, any, any> {
  const customProperties = {
    totalCount,
    loadedCount,
  };
  yield call(logEvent, 'SavingsPlan', 'Press', 'Button', 'NewSavingsPlan', screenId, customProperties);
}

/**
 * User scrolls limit order list on trading-manager.TradingManager screen
 */
export function* trackSavingsPlanScrollListSavingsPlan(
  screenId: string,
  totalCount: number,
  loadedCount: number,
): Generator<any, any, any> {
  const customProperties = {
    totalCount,
    loadedCount,
  };
  yield call(logEvent, 'SavingsPlan', 'Scroll', 'List', 'SavingsPlan', screenId, customProperties);
}

// -------------- Savings plan detail --------------

/**
 * User presses [Delete] savings plan on automation.SavingsPlanDetail screen
 */
export function* trackSavingsPlanDetailPressButtonobjectId(
  screenId: string,
  savingsPlanId: number,
): Generator<any, any, any> {
  const customProperties = {
    savingsPlanId,
  };
  yield call(logEvent, 'SavingsPlanDetail', 'Press', 'Button', 'objectId', screenId, customProperties);
}

// -------------- END OF LIST --------------"
