/* eslint-disable @typescript-eslint/no-var-requires */
import React, { Suspense, useEffect } from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'common/lib/semantic-ui';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { paths, urlTitleCodes } from 'common/urls';
import { useAuthParams } from 'common/utils/auth';
import { useMedia } from 'common/hooks/useMedia';
import { toggleStocksEnabled, toggleTopUpsEnabled } from 'store/actions/environment';
import Seo from 'common/components/SeoComponent/SeoComponent';
import { LoadingWrapper } from 'common/components/Charts/MarketCharts/styles';
import { getPageTitle } from 'store/selectors/localSettings';
import { ROUTE_MANAGEMENT_ROLES } from 'common/const/user';
import AuthInitError from '../GenericError';
import FrontPageLayout from './FrontPageLayout';
import { PrivatePage, PublicPage } from './PageAccess';
import { useTracking } from 'common/tracking/hooks';
import StopOrderCreation from '../StopOrderCreation/StopOrderCreation';
import { useDispatch, useSelector } from 'react-redux';
import { useTopUps } from 'common/hooks/topUps/useTopUps';
import { WithRouterType } from 'types/withRouter';
import AssetStakingWrapper from 'common/components/AssetStakingWrapper';
import { ASSET_CLASSES } from 'types/assets';
import { Redirect } from 'common/components/Redirect';
import useDeviceMonitoring from 'common/hooks/useDeviceMonitoring';
import useWatchStocksKycVisiting from 'common/hooks/useWatchStocksKycVisiting';
import useLocationChange from 'common/hooks/useLocationChange';
import useDeeplink from 'common/hooks/useDeeplink';
import useEpicNavigate from 'common/hooks/useEpicNavigate';
import useEpicBraze from 'common/hooks/useEpicBraze';
import useEpicModalQueue from 'common/hooks/useEpicModalQueue';

// Main tabs
const SignInPage = React.lazy(() => import('../Welcome/SignInPage'));
const RegistrationPage = React.lazy(() => import('../Welcome/RegistrationPage'));
const WaitingForConfirmation = React.lazy(() => import('../Welcome/WaitingForConfirmation'));
const Portfolio = React.lazy(() => import('../Portfolio'));
const MarketOverview = React.lazy(() => import('../MarketOverview'));
const AssetProfileWrapper = React.lazy(() => import('../AssetProfile'));
const Settings = React.lazy(() => import('../Settings'));
const TransactionHistory = React.lazy(() => import('../TransactionHistory'));
const CryptoWithdraw = React.lazy(() => import('../MoneyTransfer/CryptoWithdraw'));
const CryptoDeposit = React.lazy(() => import('../MoneyTransfer/CryptoDeposit'));
const Withdraw = React.lazy(() => import('../MoneyTransfer/Withdraw'));
const Deposit = React.lazy(() => import('scenes/MoneyTransfer/Deposit/index'));
const DepositTransfer = React.lazy(() => import('scenes/MoneyTransfer/Deposit/Transfer'));
const DepositInstant = React.lazy(() => import('scenes/MoneyTransfer/Deposit/Instant/DepositInstantWrapper'));
const PersonalInformation = React.lazy(() => import('../Settings/PersonalInformation'));
const AccountInformation = React.lazy(() => import('../Settings/AccountInformation'));
const KycProcess = React.lazy(() => import('../Kyc/CryptoKYC/KycProcess'));
const RedeemCode = React.lazy(() => import('../RedeemCode'));
const Referral = React.lazy(() => import('../Referral'));
const KycIdentOptions = React.lazy(() => import('../Kyc/CryptoKYC/IdentOptions'));
const OffsetAccounts = React.lazy(() => import('../OffsetAccounts'));
const TaxExemption = React.lazy(() => import('../TaxExemption'));
const StocksKyc = React.lazy(() => import('../Kyc/SecuritiesKyc'));
const RenewKycDocs = React.lazy(() => import('../Kyc/RenewKycDocs'));

const Inbox = React.lazy(() => import('../Inbox'));
const PriceAlerts = React.lazy(() => import('scenes/PriceAlerts'));
const Staking = React.lazy(() => import('../StakingOverview'));
const StakingRewardHistory = React.lazy(() => import('../RewardHistory'));
const AssetTransactionHistory = React.lazy(() => import('../AssetTransactionHistory'));
const Orders = React.lazy(() => import('../Orders'));

const LimitOrderCreation = React.lazy(() => import('../LimitOrderCreation/LimitOrderCreation'));
const PriceAlertsCreation = React.lazy(() => import('../PriceAlertsCreation/PriceAlertsCreation'));
const SavingsPlanCreation = React.lazy(() => import('../SavingsPlanCreation/SavingsPlanCreation'));
const Trading = React.lazy(() => import('../Trading'));
const ForgotPasswordPage = React.lazy(() => import('../Welcome/ForgotPasswordPage'));
const ResetPasswordPage = React.lazy(() => import('../Welcome/ResetPasswordPage'));
const EmailConfirmPage = React.lazy(() => import('../Welcome/EmailConfirm'));
const EmailChangeConfirmPage = React.lazy(() => import('../Welcome/EmailChangeConfirm'));
const MobileNotification = React.lazy(() => import('../MobileNotification/MobileNotification'));
const InfoReportPage = React.lazy(() => import('../../common/components/InfoReport/InfoReportPage'));
const LimitOrderDeleteView = React.lazy(() => import('../LimitOrder/LimitOrderDelete/LimitOrderDeleteView'));
const SavingsPlanDeleteView = React.lazy(
  () => import('../SavingsPlan/SavingsPlanDelete/SavingsPlanDeleteView'),
);
const StopOrderDeleteView = React.lazy(() => import('../LimitOrder/StopOrderDelete/StopOrderDeleteView'));
const RiskSettings = React.lazy(() => import('../RiskSettings/RiskSteps'));
const DeleteRealAccount = React.lazy(() => import('../DeleteAccount/DeleteAccount'));
const TechIndicators = React.lazy(() => import('../TechIndicators/TechIndicators'));
const PairedDevices = React.lazy(() => import('../PairedDevices'));
const HelpSection = React.lazy(() => import('../HelpSection'));
const NewsLetterSection = React.lazy(() => import('../NewsLetterSection'));
const FeatureRequest = React.lazy(() => import('../HelpSection/FeatureRequest/FeatureRequest'));
const Design = React.lazy(() => import('../Design/Design'));
const AuditProof = React.lazy(() => import('../Kyc/AuditProof'));

type Props = {
  loggedIn: boolean;
  t: TFunction;
  authInitialized: boolean;
  authInitError: boolean;
  selectedCrypto: Crypto;
  autoLogin: boolean;
  openVerification: any;
};

const mobile = require('is-mobile');

const isMobile = mobile();

const AppWrapper = React.memo((props: Props & WithRouterType) => {
  const { loggedIn, t, authInitialized, authInitError, selectedCrypto, autoLogin, openVerification, params } =
    props;

  const trackAppEvent = useTracking();
  const isMobileView = useMedia(['(max-width: 768px)', '(min-width: 768px)'], [true, false], false);
  const title = useSelector(getPageTitle);
  const dispatch = useDispatch();
  const { isTopUpsEnabled } = useTopUps();

  // Adds navigate function to epic middleware.
  useEpicNavigate();

  // Adds braze instance to epic middleware.
  useEpicBraze();

  // Adds modal queue to epic middleware
  useEpicModalQueue();

  // Handles deeplinks.
  useDeeplink();

  // Dispatches the new location when it is changed.
  useLocationChange();

  // Resets KYC state when the page is unmounted.
  useWatchStocksKycVisiting();

  // Load SEON script for device monitoring
  useDeviceMonitoring();

  useEffect(() => {
    function trackOnLoad() {
      trackAppEvent('windowOnLoad', {});
    }

    function trackBeforeUnload() {
      trackAppEvent('windowBeforeUnload', {});
    }

    window.addEventListener('load', trackOnLoad);
    window.addEventListener('beforeunload', trackBeforeUnload);
    dispatch(toggleStocksEnabled(window.env.FEATURE_STOCKS_ENABLED));
    dispatch(toggleTopUpsEnabled(window.env.FEATURE_TOP_UPS_ENABLED));
    return () => {
      window.removeEventListener('load', trackOnLoad);
    };
  }, []);

  const queryUsername = useAuthParams('username');
  const SignInComponent = React.memo((args) => {
    return <SignInPage {...{ ...args, username: queryUsername }} />;
  });
  const RegistrationComponent = React.memo((args) => (
    <RegistrationPage {...{ ...args, username: queryUsername }} />
  ));

  if (autoLogin) {
    return <Loader active>{t('login.loggingIn')}</Loader>;
  }
  if (authInitError) {
    return <AuthInitError />;
  }
  if (!authInitialized) {
    return <Loader active>Loading ...</Loader>;
  }

  return (
    <>
      <Seo />
      <Suspense
        fallback={
          <LoadingWrapper $loading={true}>
            <Loader active />
          </LoadingWrapper>
        }
      >
        <Routes>
          {/* //#region PUBLIC ROUTES */}
          <Route
            path={paths.LOGIN}
            element={
              <PublicPage
                title={t(urlTitleCodes[paths.LOGIN])}
                loggedIn={loggedIn}
                component={SignInComponent}
                layout={FrontPageLayout}
              />
            }
          />
          {(!window.env.BETA && (
            <Route
              path={paths.FORGOT_PASSWORD}
              element={
                <PublicPage
                  title={t(urlTitleCodes[paths.FORGOT_PASSWORD])}
                  loggedIn={loggedIn}
                  component={ForgotPasswordPage}
                  layout={FrontPageLayout}
                />
              }
            />
          )) ||
            null}
          <Route
            path={paths.RESET_PASSWORD}
            element={
              <PublicPage
                title={t(urlTitleCodes[paths.RESET_PASSWORD])}
                loggedIn={loggedIn}
                component={ResetPasswordPage}
                layout={FrontPageLayout}
              />
            }
          />
          <Route
            path={paths.SIGNUP_CONFIRM}
            element={
              <PublicPage
                title={t(urlTitleCodes[paths.SIGNUP])}
                loggedIn={loggedIn}
                component={WaitingForConfirmation}
              />
            }
          />
          <Route
            path={paths.SIGNUP}
            element={
              <PublicPage
                title={t(urlTitleCodes[paths.SIGNUP])}
                loggedIn={loggedIn}
                component={RegistrationComponent}
                layout={FrontPageLayout}
              />
            }
          />
          <Route
            path={paths.EMAIL_CONFIRM}
            element={
              <PublicPage
                title={t(urlTitleCodes[paths.EMAIL_CONFIRM])}
                loggedIn={loggedIn}
                component={EmailConfirmPage}
                layout={FrontPageLayout}
              />
            }
          />
          <Route
            path={paths.EMAIL_CHANGE_CONFIRM}
            element={
              <PublicPage
                title={t(urlTitleCodes[paths.EMAIL_CHANGE_CONFIRM])}
                loggedIn={loggedIn}
                component={EmailChangeConfirmPage}
                layout={FrontPageLayout}
              />
            }
          />
          {/* //#endregion  */}

          {/* //#region PRIVATE ROUTES */}
          <Route
            path={paths.HOME}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.PORTFOLIO])}
                loggedIn={loggedIn}
                component={() => <Navigate to={isMobile ? paths.MOBILE_NOTIFICATION : paths.PORTFOLIO} />}
                selectedCrypto={selectedCrypto}
              />
            }
          />

          <Route
            path={paths.PORTFOLIO}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.PORTFOLIO])}
                component={() => (
                  <Navigate
                    to={generatePath(paths.PORTFOLIO_CRYPTO, {
                      currency: selectedCrypto,
                    })}
                  />
                )}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.PORTFOLIO_CRYPTO}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.PORTFOLIO])}
                loggedIn={loggedIn}
                component={Portfolio}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />

          <Route
            path={paths.MOBILE_NOTIFICATION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.PORTFOLIO])}
                loggedIn={loggedIn}
                component={MobileNotification}
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.PRICE_ALERTS_ROOT}
            element={
              <PrivatePage
                component={() => (
                  <Redirect to={paths.PRICE_ALERTS_NEW} additionalParams={{ currency: selectedCrypto }} />
                )}
              />
            }
          />
          <Route
            path={`${paths.PRICE_ALERTS_NEW}/*`}
            element={
              <PrivatePage
                showRegistrationSuccess
                title={t(urlTitleCodes[paths.PRICE_ALERTS_NEW])}
                loggedIn={loggedIn}
                authInitialized={authInitialized}
                component={PriceAlerts}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />

          <Route
            path={paths.PRICE_ALERTS_CREATION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.PRICE_ALERTS_CREATION])}
                component={PriceAlertsCreation}
                loggedIn={loggedIn}
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.SAVINGS_PLAN_CREATION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.SAVINGS_PLAN_CREATION])}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_SP,
                ]}
                component={SavingsPlanCreation}
                loggedIn={loggedIn}
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.SAVINGS_PLAN_DELETE}
            element={
              <PrivatePage
                showRegistrationSuccess
                title={t(urlTitleCodes[paths.SAVINGS_PLAN_DELETE])}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_SP,
                ]}
                loggedIn={loggedIn}
                component={SavingsPlanDeleteView}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />
          {/* market-overview */}
          <Route
            path={paths.MARKET_OVERVIEW}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.MARKET_OVERVIEW])}
                loggedIn={loggedIn}
                component={MarketOverview}
                mainPage
                selectedCrypto={selectedCrypto}
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.MARKET_OVERVIEW_CRYPTO}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.MARKET_OVERVIEW])}
                loggedIn={loggedIn}
                component={AssetProfileWrapper}
                mainPage
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={title}
                pageHeadlineType="h3"
                withoutSideBar
                showPriceAlertButton
              />
            }
          />
          <Route
            path={paths.MARKET_OVERVIEW_CRYPTO_OLD}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.MARKET_OVERVIEW])}
                loggedIn={loggedIn}
                component={() => (
                  <Navigate
                    to={generatePath(paths.MARKET_OVERVIEW_CRYPTO, {
                      currency: selectedCrypto,
                    })}
                  />
                )}
                authInitialized={authInitialized}
                mainPage
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'mainScreenTitles.market'}
                pageHeadlineType="h3"
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.MARKET_OVERVIEW_SECURITY}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.MARKET_OVERVIEW])}
                loggedIn={loggedIn}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.STOCKS_TRADING_AVAILABLE]}
                component={AssetProfileWrapper}
                mainPage
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={title}
                pageHeadlineType="h3"
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.SETTINGS}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.SETTINGS])}
                loggedIn={loggedIn}
                component={() => (isMobileView ? <Settings /> : <Navigate to={paths.PERSONAL_INFO} />)}
                fullWidth
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.TRANSACTION_HISTORY}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.TRANSACTION_HISTORY])}
                loggedIn={loggedIn}
                component={TransactionHistory}
                fullWidth
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.TRADE_CURRENCY}
            element={
              <PrivatePage
                loggedIn={loggedIn}
                title={t(urlTitleCodes[paths.TRADE_CURRENCY])}
                component={() => (
                  <Navigate
                    to={generatePath(paths.TRADE_ASSET, {
                      type: 'buy',
                      assetCode: params?.currency ?? selectedCrypto,
                      assetClass: ASSET_CLASSES.CRYPTO,
                    })}
                  />
                )}
                mainPage
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.TRADE_CURRENCY_CRYPTO}
            element={
              <PrivatePage
                loggedIn={loggedIn}
                title={t(urlTitleCodes[paths.TRADE_CURRENCY])}
                component={() => (
                  <Navigate
                    to={generatePath(paths.TRADE_ASSET, {
                      type: params.type ?? 'buy',
                      assetCode: params.currency ?? '',
                      assetType: 'crypto',
                    })}
                  />
                )}
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.TRADE_ASSET}
            element={
              <PrivatePage
                loggedIn={loggedIn}
                title={t(urlTitleCodes[paths.TRADE_CURRENCY])}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING]}
                component={Trading}
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={`${paths.CRYPTO_WITHDRAW}/*`}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.CRYPTO_WITHDRAW])}
                loggedIn={loggedIn}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE]}
                component={CryptoWithdraw}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.CRYPTO_DEPOSIT}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.CRYPTO_DEPOSIT])}
                loggedIn={loggedIn}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                ]}
                component={CryptoDeposit}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.WITHDRAW}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.WITHDRAW])}
                loggedIn={loggedIn}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE]}
                component={Withdraw}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.INFO_REPORT}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.INFO_REPORT])}
                loggedIn={loggedIn}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE]}
                component={InfoReportPage}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.DEPOSIT}
            element={
              <PrivatePage
                title={t(urlTitleCodes[isTopUpsEnabled ? paths.DEPOSIT : paths.DEPOSIT_TRANSFER])}
                loggedIn={loggedIn}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                ]}
                component={isTopUpsEnabled ? Deposit : DepositTransfer}
                authInitialized={authInitialized}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.DEPOSIT_TRANSFER}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.DEPOSIT_TRANSFER])}
                loggedIn={loggedIn}
                kycOnly
                component={isTopUpsEnabled ? DepositTransfer : <Navigate to={paths.DEPOSIT} />}
                authInitialized={authInitialized}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.DEPOSIT_INSTANT}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.DEPOSIT_INSTANT])}
                loggedIn={loggedIn}
                kycOnly
                component={DepositInstant}
                authInitialized={authInitialized}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.DELETE_ACCOUNT}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.DELETE_ACCOUNT])}
                loggedIn={loggedIn}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE]}
                component={DeleteRealAccount}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.DESIGN}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.DESIGN])}
                exact
                loggedIn={loggedIn}
                component={Design}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.LIMIT_ORDER_CREATION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.LIMIT_ORDER_CREATION])}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_LO,
                ]}
                component={LimitOrderCreation}
                loggedIn={loggedIn}
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.LIMIT_ORDER_DELETE}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.LIMIT_ORDER_DELETE])}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_LO,
                ]}
                component={LimitOrderDeleteView}
                loggedIn={loggedIn}
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.STOP_ORDER_CREATION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.STOP_ORDER_CREATION])}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_SO,
                ]}
                component={StopOrderCreation}
                loggedIn={loggedIn}
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            exact
            path={paths.STOP_ORDER_DELETE}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.STOP_ORDER_DELETE])}
                requiredRoles={[
                  ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING,
                  ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_SO,
                ]}
                component={StopOrderDeleteView}
                loggedIn={loggedIn}
                selectedCrypto={selectedCrypto}
                mainPage
                withoutSideBar
              />
            }
          />
          <Route
            path={paths.PERSONAL_INFO}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.PERSONAL_INFO])}
                loggedIn={loggedIn}
                component={PersonalInformation}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.ACCOUNT_INFO}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.ACCOUNT_INFO])}
                loggedIn={loggedIn}
                component={AccountInformation}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.OFFSET_ACCOUNTS}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.OFFSET_ACCOUNTS])}
                loggedIn={loggedIn}
                component={OffsetAccounts}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.REAL_STOCKS_FEATURE_AVAILABLE]}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.TAX_EXEMPTION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.TAX_EXEMPTION])}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.REAL_STOCKS_FEATURE_AVAILABLE]}
                loggedIn={loggedIn}
                component={TaxExemption}
                pageHeadlinei18nKey={'settings.taxExemption.title'}
              />
            }
          />
          <Route
            path={paths.KYC_IDENT_OPTIONS}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.KYC])}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.DEMO_USER_WITHOUT_KYC]}
                kycPage
                loggedIn={loggedIn}
                component={KycIdentOptions}
                disableBack
                selectedCrypto={selectedCrypto}
                openVerification={openVerification}
              />
            }
          />
          <Route
            path={paths.KYC}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.KYC])}
                kycPage
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.DEMO_USER_WITHOUT_KYC]}
                loggedIn={loggedIn}
                component={KycProcess}
                disableBack
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.KYC_STEP}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.KYC])}
                kycPage
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.DEMO_USER_WITHOUT_KYC]}
                loggedIn={loggedIn}
                component={KycProcess}
                disableBack
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.STOCKS_KYC}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.KYC])}
                kycPage
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE]}
                loggedIn={loggedIn}
                component={StocksKyc}
                disableBack
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.RENEW_KYC_DOCS}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.KYC])}
                kycPage
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE]}
                loggedIn={loggedIn}
                component={RenewKycDocs}
                disableBack
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.RISK_SETTINGS}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.RISK_SETTINGS])}
                kycPage
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.CRYPTO_TRADING_AVAILABLE]}
                loggedIn={loggedIn}
                component={RiskSettings}
                disableBack
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.REDEEM_CODE}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.REDEEM_CODE])}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.TNC_ALLOWED_TRADING]}
                loggedIn={loggedIn}
                component={RedeemCode}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.PAIRED_DEVICES}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.PAIRED_DEVICES])}
                loggedIn={loggedIn}
                component={PairedDevices}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.REFERRAL}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.REFERRAL])}
                loggedIn={loggedIn}
                component={Referral}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.HELP_SECTION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.HELP_SECTION])}
                loggedIn={loggedIn}
                component={HelpSection}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.NEWS_LETTER_SECTION}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.NEWS_LETTER_SECTION])}
                loggedIn={loggedIn}
                component={NewsLetterSection}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.profile'}
              />
            }
          />
          <Route
            path={paths.FEATURE_REQUEST}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.FEATURE_REQUEST])}
                loggedIn={loggedIn}
                component={FeatureRequest}
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={'settings.help.title'}
              />
            }
          />
          <Route
            path={paths.TECH_INDICATORS_CRYPTO}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.TECH_INDICATORS])}
                loggedIn={loggedIn}
                component={TechIndicators}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.INBOX}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.INBOX])}
                loggedIn={loggedIn}
                component={Inbox}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.AUDIT_PROOF}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.KYC])}
                kycPage
                loggedIn={loggedIn}
                component={AuditProof}
                disableBack
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.STAKING}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.STAKING])}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.STAKING_AVAILABLE]}
                loggedIn={loggedIn}
                component={Staking}
                authInitialized={authInitialized}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
                pageHeadlinei18nKey={urlTitleCodes[paths.STAKING]}
              />
            }
          />
          <Route
            path={paths.ASSET_STAKING}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.STAKING])}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.STAKING_AVAILABLE]}
                loggedIn={loggedIn}
                component={AssetStakingWrapper}
                authInitialized={authInitialized}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />

          {[paths.ORDERS, paths.ORDERS_TYPE].map((path) => (
            <Route
              path={path}
              key={path}
              element={
                <PrivatePage
                  title={t(urlTitleCodes[paths.ORDERS])}
                  loggedIn={loggedIn}
                  component={Orders}
                  authInitialized={authInitialized}
                  mainPage
                  withoutSideBar
                  selectedCrypto={selectedCrypto}
                />
              }
            />
          ))}
          <Route
            path={paths.STAKING_REWARD_HISTORY}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.STAKING_REWARD_HISTORY])}
                requiredRoles={[ROUTE_MANAGEMENT_ROLES.STAKING_AVAILABLE]}
                loggedIn={loggedIn}
                component={StakingRewardHistory}
                authInitialized={authInitialized}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />
          <Route
            path={paths.MARKET_OVERVIEW_CRYPTO_TRANSACTION_HISTORY}
            element={
              <PrivatePage
                title={t(urlTitleCodes[paths.MARKET_OVERVIEW_CRYPTO_TRANSACTION_HISTORY])}
                loggedIn={loggedIn}
                component={AssetTransactionHistory}
                authInitialized={authInitialized}
                mainPage
                withoutSideBar
                selectedCrypto={selectedCrypto}
              />
            }
          />

          {/* //#endregion */}

          <Route path="*" element={<Navigate to={paths.LOGIN} replace />} />
        </Routes>
      </Suspense>
    </>
  );
});

export default withTranslation()(AppWrapper);
