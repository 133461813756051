/* eslint-disable @typescript-eslint/no-unsafe-return  */
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { Action } from 'redux';
import { catchError, filter, ignoreElements, map, mapTo, pluck, switchMap, take, tap } from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { concat, from, of } from 'rxjs';

import I18n from 'i18next';
import * as loginActions from 'store/actions/auth';
import * as miscActions from 'store/actions/misc';
import { AjaxError } from 'rxjs/ajax';
import { backoff } from 'common/utils/rxjs-operators';
import { State } from 'store/types/store';
import { getAccessToken } from 'store/selectors/auth';
import {
  CheckDeleteRealAccount,
  FullEpicPayload,
  StandardEpicPayload,
  SubmitDeleteAccountBody,
  SubmitDeleteRealAccountBody,
} from 'common/api-types';
import * as api from 'common/api';
import {
  CHECK_DELETE_REAL_ACCOUNT,
  CHECK_DELETE_REAL_ACCOUNT_FAILURE,
  CHECK_DELETE_REAL_ACCOUNT_SUCCESS,
  checkDeleteRealAccountFailure,
  checkDeleteRealAccountSuccess,
  LOGOUT_USER_SUCCESS,
  SUBMIT_DELETE_ACCOUNT,
  SUBMIT_DELETE_ACCOUNT_FAILURE,
  SUBMIT_DELETE_ACCOUNT_SUCCESS,
  SUBMIT_DELETE_REAL_ACCOUNT,
  SUBMIT_DELETE_REAL_ACCOUNT_FAILURE,
  SUBMIT_DELETE_REAL_ACCOUNT_SUCCESS,
  submitDeleteAccountFailure,
  submitDeleteAccountSuccess,
  submitDeleteRealAccountFailure,
  submitDeleteRealAccountSuccess,
} from 'store/actions/auth';
import { handleAjaxError } from './auth';
import * as notificationBannerActions from '../actions/notificationBanner';

const submitDeleteAccountEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(SUBMIT_DELETE_ACCOUNT)),
    map((props: FullEpicPayload<SubmitDeleteAccountBody>) => ({
      payload: props.payload,
      onSuccess: props.onSuccess,
      onFail: props.onFail,
    })),
    switchMap(({ payload, onSuccess, onFail }: FullEpicPayload<SubmitDeleteAccountBody>) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.deleteAccount(accessToken, payload)).pipe(
            map(submitDeleteAccountSuccess),
            catchError(handleAjaxError(action$, submitDeleteAccountFailure)),
            tap(({ type, error }) => {
              if (type === SUBMIT_DELETE_ACCOUNT_SUCCESS) {
                onSuccess();
              } else if (type === SUBMIT_DELETE_ACCOUNT_FAILURE) {
                onFail();
              }
            }),
          ),
        ),
      ),
    ),
  );

const checkDeleteRealAccountEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(CHECK_DELETE_REAL_ACCOUNT)),
    map((props: StandardEpicPayload<CheckDeleteRealAccount>) => ({
      ...props,
    })),
    switchMap(({ onIsDeletable, onIsNotDeletable }: CheckDeleteRealAccount) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.checkDeleteRealAccount(accessToken)).pipe(
            map(checkDeleteRealAccountSuccess),
            catchError(
              handleAjaxError(action$, (args: any) =>
                checkDeleteRealAccountFailure({
                  ...args,
                  onIsNotDeletable,
                }),
              ),
            ),
            tap(({ type: actionType, error }) => {
              if (actionType === CHECK_DELETE_REAL_ACCOUNT_SUCCESS) {
                onIsDeletable();
              }
            }),
          ),
        ),
      ),
    ),
  );

const submitDeleteRealAccountEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
  state$: StateObservable<State>,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(SUBMIT_DELETE_REAL_ACCOUNT)),
    map((props: FullEpicPayload<SubmitDeleteRealAccountBody>) => ({
      type: props.type,
      payload: props.payload,
      onSuccess: props.onSuccess,
      onFail: props.onFail,
    })),
    switchMap(({ payload, onSuccess, onFail }: FullEpicPayload<SubmitDeleteRealAccountBody>) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.deleteRealAccount(accessToken, payload)).pipe(
            map(submitDeleteRealAccountSuccess),
            catchError(handleAjaxError(action$, submitDeleteRealAccountFailure)),
            tap(({ type: actionType, error }) => {
              if (actionType === SUBMIT_DELETE_REAL_ACCOUNT_SUCCESS) {
                onSuccess();
              } else if (actionType === SUBMIT_DELETE_REAL_ACCOUNT_FAILURE) {
                onFail();
              }
            }),
          ),
        ),
      ),
    ),
  );
const handleAccountDeleteSuccess = (action$: any): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType([SUBMIT_DELETE_ACCOUNT_SUCCESS, SUBMIT_DELETE_REAL_ACCOUNT_SUCCESS])),
    switchMap(() =>
      concat(
        of(loginActions.logoutUser()),
        action$.pipe(filter(isOfType(LOGOUT_USER_SUCCESS)), mapTo(miscActions.accountDeleted())),
      ),
    ),
  );

const submitDeleteAccountResultHandlerEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType([SUBMIT_DELETE_ACCOUNT, SUBMIT_DELETE_REAL_ACCOUNT])),
    switchMap(() =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      action$.pipe(
        filter(isOfType([SUBMIT_DELETE_ACCOUNT_FAILURE, SUBMIT_DELETE_REAL_ACCOUNT_FAILURE])),
        take(1),
        switchMap(() =>
          of(
            notificationBannerActions.notifyError({
              message: I18n.t('settings.terminateAccount.error'),
            }),
          ),
        ),
      ),
    ),
  );

const checkDeleteAccountNonEmptyEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(CHECK_DELETE_REAL_ACCOUNT_FAILURE)),
    filter(({ props }) => props.errorCode === 'userdeleterequest_err_accountnotempty'),
    tap((props: any) => props.props.onIsNotDeletable(props?.props?.response?.data?.detail)),
    ignoreElements(),
  );

const checkDeleteAccountErrorEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: any,
): ActionsObservable<Action> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    filter(isOfType(CHECK_DELETE_REAL_ACCOUNT_FAILURE)),
    filter(({ props }) => props.errorCode !== 'userdeleterequest_err_accountnotempty'),
    map(() =>
      notificationBannerActions.notifyError({
        message: I18n.t('settings.terminateAccount.error'),
      }),
    ),
  );

const loadUserKycStatusEpic = (action$: any, state$: StateObservable<State>, { ajax }) =>
  action$.pipe(
    filter(isOfType(loginActions.FETCH_USER_KYC_STATUS_REQUEST)),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken).concat('/user/kyc/status'),
            withCredentials: true, // include cookies
            headers: { ...api.getCommonHeaders(), Authorization: `Bearer ${accessToken}` },
            timeout: 20000,
          }).pipe(pluck('response'), map(loginActions.fetchUserKycStatusSuccess)),
        ),
        // retries the whole `switchMap` observable to get new `x-bison-request-id header for subsequent ajax request
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, loginActions.fetchUserKycStatusFailure)),
      ),
    ),
  );

export default combineEpics(
  submitDeleteAccountResultHandlerEpic,
  submitDeleteAccountEpic,
  handleAccountDeleteSuccess,
  submitDeleteRealAccountEpic,
  checkDeleteRealAccountEpic,
  checkDeleteAccountNonEmptyEpic,
  checkDeleteAccountErrorEpic,
  loadUserKycStatusEpic,
);
