import styled from 'styled-components';
import { devices } from 'common/styles';
import phishingImg from 'assets/img/phishing.png';
import {
  ImgContainer,
  TextContainer,
  HeadlineText as _HeadlineText,
} from '../DefaultFeaturesModalStyles/styles';

export const PhishingImg = styled.img.attrs({
  src: phishingImg,
})`
  width: 100%;
  height: auto;
`;

export const CustomImgContainer = styled(ImgContainer)`
  flex: 40%;
`;

export const CustomTextContainer = styled(TextContainer)`
  flex: 60%;
  a {
    font-weight: 700;
  }
`;

export const HeadlineText = styled(_HeadlineText)`
  @media ${devices.maxTablet} {
    font-size: 20px;
    line-height: 1;
  }
`;
