/* eslint-disable @typescript-eslint/no-unsafe-return */
import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { RootEpic } from 'types/common';
import { LOGOUT_USER_SUCCESS } from 'store/actions/auth';
import { toggleTopUpsEnabled } from 'store/actions/environment';

const handleTopUpsEnabledStateOnLogout: RootEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOGOUT_USER_SUCCESS),
    map(() => toggleTopUpsEnabled(window.env.FEATURE_TOP_UPS_ENABLED)),
  );

export default combineEpics(handleTopUpsEnabledStateOnLogout);
