import React, { Dispatch, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AnyAction } from 'redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { paths } from 'common/urls';
import { accountTypes, kycStatuses } from 'common/const';
import { useSecurities } from 'common/hooks/useSecurities';
import { User } from 'types/state';
import { getIsKycDone, getIsUserInPaperTrading, getSecuritiesKycStatus, getUser } from 'store/selectors/auth';
import { logoutUser } from 'store/actions/auth';
import DisabledFeatures from 'common/components/Modal/DisabledFeatures';
import { fiat } from 'common/utils/formatting';
import AlertModal from 'common/components/Modal/AlertModal';
import { useToggleSwitchTheme } from 'common/styles/toggleSwitchStyles';
import { useThemeContext } from 'common/utils/theme/ThemeContext';
import * as referralActions from 'store/slices/referral/actions';
import { getIsCryptoTradingDisabledWithoutAcceptingTandC } from 'store/selectors/termsAndConditions';
import { cryptoTermsAndConditionsClickedAway } from 'store/actions/termsAndConditions';
import { setSelectedTechIndicators, changeTheme } from 'store/actions/settings';

import { useLocation, useNavigate } from 'react-router-dom';
import { State } from 'store/types/store';
import { ReferralState } from 'store/types/referral';
import QuickSettings from './QuickSettings';

import {
  Headline,
  InviteFriendsMenuItemWrapper,
  InviteFriendsWrapper,
  KycButton,
  LineSeparator,
  MenuButton,
  MenuItemWrapper,
  MoneyTransferButton,
  MoneyTransferButtonsWrapper,
  NavItem,
  NavItems,
  PersonImage,
  SideListContainer,
  TextContentWrapper,
} from './styles';
import DeleteAccountContent from './DeleteAccount/DeleteAccountContent';
import { DeleteAccountActions } from './DeleteAccount/DeleteAccountActions';
import ToggleStocks from './ToggleStocks';

// TODO: Uncomment when/if raffle comes back.
// import { useStockRaffleToggleDisplay } from 'common/hooks/useStockRaffleToggleDisplay';

export const MenuItem = ({
  text,
  goTo,
  children,
  disabled,
  url,
}: {
  text: string;
  goTo: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  url: string;
}): JSX.Element => {
  const { pathname } = useLocation();
  return (
    <MenuItemWrapper disabled={disabled} onClick={goTo} active={pathname.startsWith(url)}>
      <NavItem active={pathname.startsWith(url)}>{text}</NavItem>
      {children}
    </MenuItemWrapper>
  );
};
MenuItem.defaultProps = {
  children: null,
  disabled: undefined,
};

export const InviteItem = ({
  text,
  goTo,
  disabled,
  url,
  referral,
  invalidateReferral,
}: {
  text: string;
  goTo: () => void;
  disabled?: boolean;
  url: string;
  referral: ReferralState;
  invalidateReferral: () => void;
}): JSX.Element => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    invalidateReferral();
  }, [invalidateReferral]);
  const codeOwnerReward = referral.reward?.referralCodeOwnerReward;
  const codeOwnerRewardAmount = codeOwnerReward
    ? fiat(codeOwnerReward.volume, { symbol: true, customPrecision: 0 })
    : '-';
  const codeOwnerRewardPayoutCurrency = codeOwnerReward?.payoutCurrency.toUpperCase() ?? '-';

  return (
    <InviteFriendsMenuItemWrapper disabled={disabled} onClick={goTo}>
      <InviteFriendsWrapper>
        <TextContentWrapper>
          <NavItem active={pathname.startsWith(url)}>
            <Headline>{text}</Headline>
            <span className="invite-subtext small-text regular-weight primary-color">
              <span>
                {t('referral.inviteText.long', { codeOwnerRewardAmount, codeOwnerRewardPayoutCurrency })}
              </span>
            </span>
          </NavItem>
        </TextContentWrapper>
        <PersonImage />
      </InviteFriendsWrapper>
    </InviteFriendsMenuItemWrapper>
  );
};
InviteItem.defaultProps = {
  children: null,
  disabled: undefined,
};

const KycStatus = ({
  kycStatus,
  goTo,
  url,
  ...rest
}: {
  kycStatus: string;
  goTo: () => void;
  url: string;
}): JSX.Element => {
  const { t } = useTranslation();
  let kycStatusMessage;
  switch (kycStatus) {
    case kycStatuses.Pending:
      kycStatusMessage = t('settings.verificationPending');
      break;
    case kycStatuses.Confirmed:
      kycStatusMessage = t('settings.verificationConfirmed');
      break;
    case kycStatuses.Invalid:
      kycStatusMessage = t('settings.verificationInvalid');
      break;
    default:
      kycStatusMessage = t('settings.verificationStart');
  }

  return (
    <MenuItem text={t('settings.verification')} goTo={goTo} url={url} {...rest}>
      <KycButton
        onClick={goTo}
        disabled={kycStatus === kycStatuses.Pending}
        title={kycStatusMessage}
        link
        inverted
      />
    </MenuItem>
  );
};

export const Menu = ({
  user,
  isKycDone,
  logout,
  clearSelectedTechIndicators,
  closeMenu,
  invalidateReferral,
  ...rest
}: {
  user: User;
  isKycDone?: boolean;
  logout: typeof logoutUser;
  clearSelectedTechIndicators: () => void;
  closeMenu?: () => void;
  invalidateReferral: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // TODO: Uncomment when/if raffle comes back.
  // const { isDisplayed: displayStocksRaffleToggle } = useStockRaffleToggleDisplay();

  const isTradingDisabled = useSelector(getIsCryptoTradingDisabledWithoutAcceptingTandC);
  const { canTradeStocks, isUserSwitchStocksAvailable } = useSecurities();
  const stocksKycStatus = useSelector(getSecuritiesKycStatus);
  const isDemo = useSelector(getIsUserInPaperTrading);

  const [isOpenDisabledFeature, setIsOpenDisabledFeature] = React.useState(false);
  const [isOpenDeleteRequest, setIsOpenDeleteRequest] = React.useState(false);

  const [deleteReasonText, setDeleteReasonText] = React.useState('');

  const handleMenuClick = (url: string, needsRealMoney = false) => {
    if (needsRealMoney) {
      if (user.accountType !== accountTypes.real || user.kycStatus !== kycStatuses.Confirmed) {
        setIsOpenDisabledFeature(true);
        return;
      }
    }
    navigate(url);
    if (closeMenu) closeMenu();
  };

  const handleDeleteAccountSuccess = () => {
    setIsOpenDeleteRequest(false);
  };

  const handleDeleteAccountFail = () => {
    setIsOpenDeleteRequest(false);
  };

  const handleLogout = () => {
    logout();
    clearSelectedTechIndicators();
    dispatch(changeTheme('light'));
  };

  const triggerTermsAndConditions = () => {
    dispatch(cryptoTermsAndConditionsClickedAway(false));
  };

  return (
    <>
      <NavItems>
        <MoneyTransferButtonsWrapper>
          <MoneyTransferButton
            title={t('settings.moneyTransferButtons.deposit')}
            onClick={() =>
              isTradingDisabled ? triggerTermsAndConditions() : handleMenuClick(paths.DEPOSIT, true)
            }
            size="large"
            disabledTheme={isDemo}
          />
          <MoneyTransferButton
            title={t('settings.moneyTransferButtons.withdraw')}
            onClick={() => handleMenuClick(paths.WITHDRAW, true)}
            size="large"
            disabledTheme={isDemo}
          />
        </MoneyTransferButtonsWrapper>
        <MenuItem
          goTo={() => handleMenuClick(paths.PERSONAL_INFO)}
          text={t('settings.personalInfo.title')}
          url={paths.PERSONAL_INFO}
          {...rest}
        />
        <MenuItem
          disabled={!isKycDone}
          goTo={() => handleMenuClick(paths.ACCOUNT_INFO, true)}
          text={t('settings.accountInfo.title')}
          url={paths.ACCOUNT_INFO}
          {...rest}
        />
        <MenuItem
          disabled={!isKycDone}
          goTo={() => handleMenuClick(paths.INFO_REPORT, true)}
          exact
          text={t('settings.reports.title')}
          url={paths.INFO_REPORT}
          {...rest}
        />
        <MenuItem
          disabled={!isKycDone}
          goTo={() =>
            isTradingDisabled ? triggerTermsAndConditions() : handleMenuClick(paths.DEPOSIT, true)
          }
          exact
          text={t('settings.deposit.title')}
          url={paths.DEPOSIT}
          {...rest}
        />
        <MenuItem
          disabled={!isKycDone}
          goTo={() => handleMenuClick(paths.WITHDRAW, true)}
          text={t('settings.withdraw.title')}
          url={paths.WITHDRAW}
          {...rest}
        />
        <MenuItem
          disabled={!isKycDone}
          goTo={() =>
            isTradingDisabled ? triggerTermsAndConditions() : handleMenuClick(paths.CRYPTO_DEPOSIT, true)
          }
          text={t('mainScreenTitles.cryptoDeposit')}
          url={paths.CRYPTO_DEPOSIT}
          {...rest}
        />
        <MenuItem
          disabled={!isKycDone}
          goTo={() => handleMenuClick(paths.CRYPTO_WITHDRAW, true)}
          text={t('mainScreenTitles.cryptoWithdraw')}
          url={paths.CRYPTO_WITHDRAW}
          {...rest}
        />
        {canTradeStocks && stocksKycStatus === kycStatuses.Confirmed && (
          <MenuItem
            disabled={!isKycDone}
            goTo={() => handleMenuClick(paths.TAX_EXEMPTION, true)}
            text={t('settings.taxExemption.title')}
            url={paths.TAX_EXEMPTION}
            {...rest}
          />
        )}
        {canTradeStocks && stocksKycStatus === kycStatuses.Confirmed && (
          <MenuItem
            disabled={!isKycDone}
            goTo={() => handleMenuClick(paths.OFFSET_ACCOUNTS, true)}
            text={t('mainScreenTitles.offsetAccounts')}
            url={paths.OFFSET_ACCOUNTS}
            {...rest}
          />
        )}
        <LineSeparator />
        <MenuItem
          goTo={() => (isTradingDisabled ? triggerTermsAndConditions() : handleMenuClick(paths.REDEEM_CODE))}
          exact
          text={t('mainScreenTitles.redeemCode')}
          url={paths.REDEEM_CODE}
          {...rest}
        />
        <MenuItem
          goTo={() => handleMenuClick(paths.PAIRED_DEVICES)}
          exact
          text={t('devices.title')}
          url={paths.PAIRED_DEVICES}
          {...rest}
        />
        <MenuItemWrapper>
          <QuickSettings disabled={!isKycDone} />
        </MenuItemWrapper>
        {!isKycDone && (
          <KycStatus
            {...user}
            goTo={() => handleMenuClick(paths.KYC)}
            isKycDone={isKycDone}
            url={paths.KYC}
            {...rest}
          />
        )}
        {!isKycDone && (
          <MenuItem
            goTo={() => setIsOpenDeleteRequest(true)}
            text={t('mainScreenTitles.deleteAccount')}
            {...rest}
          />
        )}
        <MenuItem
          goTo={() => handleMenuClick(paths.HELP_SECTION)}
          exact
          text={t('settings.help.title')}
          url={paths.HELP_SECTION}
          {...rest}
        />
        <MenuItem
          goTo={() => handleMenuClick(paths.NEWS_LETTER_SECTION)}
          exact
          text={t('settings.newsletter.title')}
          url={paths.NEWS_LETTER_SECTION}
          {...rest}
        />
        {isKycDone && (
          <MenuItem
            goTo={() => handleMenuClick(paths.DELETE_ACCOUNT)}
            text={t('mainScreenTitles.deleteAccount')}
            url={paths.DELETE_ACCOUNT}
            {...rest}
          />
        )}
        <MenuItem
          goTo={() => handleMenuClick(paths.DESIGN)}
          exact
          text={t('mainScreenTitles.design')}
          url={paths.DESIGN}
          {...rest}
        />
        {isUserSwitchStocksAvailable && (
          <MenuItemWrapper>
            <ToggleStocks />
          </MenuItemWrapper>
        )}

        {/* // TODO: Uncomment when/if raffle comes back.
        {displayStocksRaffleToggle && (
          <MenuItemWrapper>
            <ToggleRaffle />
          </MenuItemWrapper>
        )} */}
        <MenuItemWrapper onClick={handleLogout}>
          <MenuButton className="large-text">{t('settings.logout')}</MenuButton>
        </MenuItemWrapper>
        <InviteItem
          goTo={() => handleMenuClick(paths.REFERRAL)}
          exact
          text={t('mainScreenTitles.referral')}
          url={paths.REFERRAL}
          invalidateReferral={invalidateReferral}
          {...rest}
        />
      </NavItems>
      <DisabledFeatures open={isOpenDisabledFeature} onClose={() => setIsOpenDisabledFeature(false)} />
      <AlertModal
        open={isOpenDeleteRequest}
        onClose={() => {
          setIsOpenDeleteRequest(false);
        }}
        actions={
          <DeleteAccountActions
            onCancelHandle={() => setIsOpenDeleteRequest(false)}
            deleteReasonText={deleteReasonText}
            onSuccessHandle={handleDeleteAccountSuccess}
            onFailHandle={handleDeleteAccountFail}
          />
        }
        Content={
          <DeleteAccountContent
            deleteReasonText={deleteReasonText}
            setDeleteReasonText={setDeleteReasonText}
          />
        }
      />
    </>
  );
};
Menu.defaultProps = {
  isKycDone: undefined,
  closeMenu: undefined,
};

const mapStateToProps = (state: State) => ({
  isKycDone: getIsKycDone(state),
  user: getUser(state),
  referral: state.referral,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  logout: () => dispatch(logoutUser()),
  clearSelectedTechIndicators: () => dispatch(setSelectedTechIndicators('')),
  invalidateReferral: () => dispatch(referralActions.invalidateReferral()),
});

export const MenuContent = connect(mapStateToProps, mapDispatchToProps)(Menu);

MenuContent.defaultProps = {
  closeMenu: () => undefined,
};
type Props = {
  closeMenu?: () => void;
};

export const SideMenu = (props: Props): JSX.Element => {
  const theme = useThemeContext();
  const muiThemeStyles = useToggleSwitchTheme(theme);

  return (
    <MuiThemeProvider theme={muiThemeStyles}>
      <SideListContainer>
        <MenuContent {...props} />
      </SideListContainer>
    </MuiThemeProvider>
  );
};
SideMenu.defaultProps = {
  closeMenu: () => undefined,
};
export default SideMenu;
