import { useModalProvider } from 'providers/Modal/useModalProvider';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsDeviceMonitoringConsentOpen } from 'store/selectors/deviceMonitoring';
import DeviceMonitoringConsentModal from 'common/components/Modal/DeviceMonitoringConsentModal';
import { State } from 'store/types/store';
import BetaAccessModal from 'scenes/Welcome/BetaAccessModal';
import { getIsAccountDeleted } from 'store/selectors/misc';
import DeleteAccountConfirmation from 'common/components/SideMenu/DeleteAccount/DeleteAccountConfirmation';
import { ModalType } from 'providers/Modal/types';

const PUBLIC_PAGE_MODAL_TYPE: ModalType = 'public';

/**
 * Hook that watches state and displays initial modals.
 */
export function usePublicPageInitialPopups(): any {
  const modalProvider = useModalProvider();

  const isAccountDeleted = useSelector(getIsAccountDeleted);
  const isDeviceMonitoringConsentOpen = useSelector(getIsDeviceMonitoringConsentOpen);
  const isBetaAccessModalDisplayed = useSelector((state: State) => state.auth.isAccessAllowed);

  useEffect(() => {
    if (isDeviceMonitoringConsentOpen) {
      modalProvider.addModal({
        component: <DeviceMonitoringConsentModal onClose={() => {}}></DeviceMonitoringConsentModal>,
        type: PUBLIC_PAGE_MODAL_TYPE,
      });
    }
  }, [isDeviceMonitoringConsentOpen]);

  useEffect(() => {
    if (!isBetaAccessModalDisplayed) {
      modalProvider.addModal({
        component: <BetaAccessModal onClose={() => {}}></BetaAccessModal>,
        type: PUBLIC_PAGE_MODAL_TYPE,
      });
    }
  }, [isBetaAccessModalDisplayed]);

  useEffect(() => {
    if (isAccountDeleted) {
      modalProvider.addModal({
        component: <DeleteAccountConfirmation onClose={() => {}}></DeleteAccountConfirmation>,
        type: PUBLIC_PAGE_MODAL_TYPE,
      });
    }
  }, [isAccountDeleted]);
}
