const textStyles = {
  blueBoxNormal: `
        font-size: 14px;
        line-height: 19.07px;
        overflow-wrap: break-word;
  `,
  pageHeadlineText: ` 
        font-size: 30px;
        font-weight: 700;
        line-height: 41px;
        letter-spacing: 0em;
  `,
  modalHeadline: `
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
  `,
  modalText: `
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
  `,
  buySellModalHeadline: `
        font-size: 24px;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0em;
`,
  buySellModalContent: `
        font-size: 17px;
        font-weight: 400;
        line-height: 41px;
        letter-spacing: 0em;
`,
  buySellModalButtons: `
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
`,
};

export default textStyles;
