import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import Button from 'common/components/Button';
import ModalTemplate from 'common/components/Modal';
import { MODAL_DIMENSIONS } from 'common/const/modals';
import { paths } from 'common/urls';
import { deviceMonitoringConsent } from 'store/actions/deviceMonitoring';
import { getConsentModel } from 'store/selectors/deviceMonitoring';
import { DeviceMonitoringConsentState } from 'types/deviceMonitoring';
import { ConsentModelType } from 'types/consent';
import { ModalProps } from 'types/modals';
import {
  ButtonWrapper,
  ContentWrapper,
  ExitHeadlineText,
  ExitTextContainer,
  HeadlineText,
  TextContainer,
} from './styles';

const mapper = {
  [`${paths.LOGIN}`]: 'paragraphLogin',
  [`${paths.SIGNUP}`]: 'paragraphLogin',
  [`${paths.KYC}`]: 'paragraphOnboarding',
  [`${paths.RESET_PASSWORD}`]: 'paragraphResetPassword',
};

function useTextsType(consentModel: ConsentModelType, pathname: string) {
  const [textsType, setTextsType] = useState('paragraphFallBack');
  const keys = Object.keys(mapper);
  useEffect(() => {
    if (consentModel !== 'Explicit') {
      // user has an option to decline consent
      setTextsType('paragraphOptOut');
    } else {
      keys.forEach((key) => {
        const pattern = new RegExp(key);
        if (pattern.test(pathname)) {
          setTextsType(mapper[key]);
        }
      });
    }
  }, [consentModel, pathname]);

  return { textsType };
}

const DeviceMonitoringConsentModal = ({ onClose }: ModalProps) => {
  const { t } = useTranslation();
  const consentModel = useSelector(getConsentModel);
  const dispatch = useDispatch();
  const [exitModalOpen, setExitModalOpen] = useState(false);

  const { pathname } = useLocation();

  const match = useMatch(pathname);

  const { textsType } = useTextsType(consentModel, match?.pattern.path);

  const submitDeviceMonitoringConsent = (consentState: DeviceMonitoringConsentState) => {
    dispatch(deviceMonitoringConsent(consentState));
    onClose();
  };
  const exitConsentPrompt = () => {
    setExitModalOpen(true);
  };
  const closeExitModal = () => {
    submitDeviceMonitoringConsent('Declined');
    setExitModalOpen(false);
    onClose();
  };

  if (exitModalOpen) {
    // if user dismisses the main modal with consent and consentModel is Required for this user, show the exit prompt
    return (
      <ModalTemplate
        key="ExitPromptModal"
        onCancel={closeExitModal}
        defaultOpen={exitModalOpen}
        modalDimensions={MODAL_DIMENSIONS.SMALL}
        actions={
          <ButtonWrapper>
            <Button
              size="large"
              title={t('deviceMonitoringConsent.exitModal.goBack')}
              onClick={() => setExitModalOpen(false)}
              isFullWidth
            />
            <Button
              size="large"
              title={t('deviceMonitoringConsent.exitModal.close')}
              onClick={closeExitModal}
              inverted
              isFullWidth
            />
          </ButtonWrapper>
        }
      >
        <ContentWrapper>
          <ExitHeadlineText>{t('deviceMonitoringConsent.exitModal.title')}</ExitHeadlineText>
          <ExitTextContainer>{t(`deviceMonitoringConsent.exitModal.${textsType}`)}</ExitTextContainer>
        </ContentWrapper>
      </ModalTemplate>
    );
  }

  // Main modal with device monitoring consent
  return (
    <ModalTemplate
      key="DeviceMonitoringConsentModal"
      onCancel={exitConsentPrompt}
      defaultOpen={true}
      actions={
        <Button
          size="large"
          title={t('deviceMonitoringConsent.modal.action')}
          onClick={() => submitDeviceMonitoringConsent('Accepted')}
        />
      }
    >
      <ContentWrapper>
        <HeadlineText>{t('deviceMonitoringConsent.modal.title')}</HeadlineText>
        <TextContainer>{t(`deviceMonitoringConsent.modal.${textsType}`)}</TextContainer>
        <TextContainer linkTarget="_blank">{t('deviceMonitoringConsent.modal.text')}</TextContainer>
      </ContentWrapper>
    </ModalTemplate>
  );
};

export default DeviceMonitoringConsentModal;
