import { useSelector } from 'react-redux';
import {
  getIsCryptoTermsAndConditionsDataInitiallyLoaded,
  getIsCryptoTermsAccepted,
  getIsCryptoTermsAndConditionsClickedAway,
  getIsCryptoTradingDisabledWithoutAcceptingTandC,
} from 'store/selectors/termsAndConditions';
import { getIsKycDone, getIsUserInPaperTrading } from 'store/selectors/auth';
import { getTncPopupClosedAt } from 'store/selectors/settings';
import { isTnCModalStillHidden } from 'common/utils/termsAndConditions';

const useDisplayCryptoTnC = () => {
  const acceptedTnC = useSelector(getIsCryptoTermsAccepted);
  const isKycDone = useSelector(getIsKycDone);
  const isDemo = useSelector(getIsUserInPaperTrading);
  const isTermsAndConditionsClickedAway = useSelector(getIsCryptoTermsAndConditionsClickedAway);
  const isDataInitiallyLoaded = useSelector(getIsCryptoTermsAndConditionsDataInitiallyLoaded);
  const isTradingBlocked = useSelector(getIsCryptoTradingDisabledWithoutAcceptingTandC);
  const tncPopupClosedAt = useSelector(getTncPopupClosedAt);

  if (acceptedTnC) return false;

  if (!isKycDone) return false;

  if (isDemo) return false;

  if (isTermsAndConditionsClickedAway) return false;

  if (!isDataInitiallyLoaded) return false;

  // if the tnc is in the blocked phase, then show the popup
  if (isTradingBlocked) return true;

  if (isTnCModalStillHidden(tncPopupClosedAt)) return false;

  return true;
};

export default useDisplayCryptoTnC;
