import { ConfigFeatureState } from 'store/types/configFeature';

// in-app default values (which will be merged with values from remote storage)
const defaultValues: ConfigFeatureState = {
  IBAN_CHANGE_ENABLED: false,
  CRYPTO_DEPOSIT_ENABLED: false,
  STOCKS_RAFFLE_START_DATE: new Date('2023-06-12T00:00:00.00Z').toISOString(),
  STOCKS_RAFFLE_END_DATE: new Date('2023-07-03T23:59:00.00Z').toISOString(),
  STOCKS_RAFFLE_ANNOUNCEMENT_DATE: new Date('2023-06-12T00:00:00.00Z').toISOString(),
  TOP_UPS_ENABLED: false,
  KANSAS_CITY_CHIEFS_RAFFLE_START_DATE: new Date('2023-10-01T22:00:00Z').toISOString(),
  KANSAS_CITY_CHIEFS_RAFFLE_END_DATE: new Date('2023-10-29T22:00:00Z').toISOString(),
};

// reducer for read-only state → just passes through state
function root(state: ConfigFeatureState = defaultValues) {
  return state;
}

export default root;
