/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export type RequestType = 'REQUEST' | 'SUCCESS' | 'FAILURE';
export type ControlType = 'START' | 'STOP';

export function createRequestTypes<T>(base: string & keyof T) {
  return {
    REQUEST: `${base}_REQUEST` as const,
    SUCCESS: `${base}_SUCCESS` as const,
    FAILURE: `${base}_FAILURE` as const,
  };
}

export function createControlTypes<T>(base: string & keyof T) {
  return {
    START: `${base}_START` as const,
    STOP: `${base}_STOP` as const,
  };
}

type RequestActions<T> = {
  REQUEST: `${string & keyof T}_REQUEST`;
  SUCCESS: `${string & keyof T}_SUCCESS`;
  FAILURE: `${string & keyof T}_FAILURE`;
};

type ControlActions<T> = {
  START: `${string & keyof T}_START`;
  STOP: `${string & keyof T}_STOP`;
};

export function createControlActions<T>(base: ControlActions<T>) {
  return {
    START: { type: base.START },
    STOP: { type: base.STOP },
  };
}

export function createRequestActions<T>(base: RequestActions<T>) {
  return {
    REQUEST: { type: base.REQUEST },
    SUCCESS: { type: base.SUCCESS },
    FAILURE: { type: base.FAILURE },
  };
}

export function action<Type extends string, Payload extends Record<string, unknown>>(
  type: Type,
  payload?: Payload,
): Payload & { type: Type } {
  return { type, ...payload } as Payload & { type: Type };
}
