import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PRICE_HISTORY_SMALL_CHART_SLICE_NAME } from 'common/const/slices';
import { PriceHistorySmallChartData, PriceHistorySmallChartEntity } from 'store/types/priceHistory';
import { TimePeriod } from 'types/currency';
import { priceHistorySmallChartDataToEntity } from 'common/utils/priceHistorySmallChart';
import { priceHistorySmallChartInitialState } from './initialState';
import { priceHistorySmallChartAdapter } from './adapter';

const priceHistorySmallChartSlice = createSlice({
  name: PRICE_HISTORY_SMALL_CHART_SLICE_NAME,
  initialState: priceHistorySmallChartInitialState,
  reducers: {
    subscribeToPriceHistorySmallChart(state, action: PayloadAction<{ codes: string[] }>) {
      state.isFetching = true;
    },
    subscribeToPriceHistorySmallChartSuccess(
      state,
      action: PayloadAction<{
        smallChart: PriceHistorySmallChartData;
        assetCode: string;
        timePeriod: TimePeriod;
      }>,
    ) {
      const entity: PriceHistorySmallChartEntity = priceHistorySmallChartDataToEntity(
        action.payload.smallChart,
        action.payload.assetCode,
        action.payload.timePeriod,
      );

      priceHistorySmallChartAdapter.upsertOne(state, entity);
    },
    subscribeToPriceHistorySmallChartError(state) {},
    unsubscribeFromPriceHistorySmallChart(state) {
      state.isFetching = false;
    },
  },
});

export default priceHistorySmallChartSlice;
