import { colors } from 'common/utils/theme/colors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'store/selectors/settings';

export const useCryptoLabelColor = (cryptoColor?: string) => {
  const theme = useSelector(getTheme);

  const color = useMemo(() => {
    if (!cryptoColor) return undefined;

    if (theme === 'light') {
      return cryptoColor || colors.pureBlack;
    }

    return colors.lightMain;
  }, [cryptoColor, theme]);

  return color;
};
