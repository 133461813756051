/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { State } from 'store/types/store';

export const getEventsInBuffer = (state: State) => state.tracking.eventsBuffer;

export const getTrackingConfiguration = (state: State) => state.tracking.trackingConfiguration;

export const getFlushRetryMode = (state: State) => state.tracking.flushRetryMode;
