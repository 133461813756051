import { useSelector } from 'react-redux';
import {
  getIsStocksTermsAccepted,
  getIsStocksTermsAndConditionsClickedAway,
  getIsStocksTermsAndConditionsDataInitiallyLoaded,
  getIsStocksTradingDisabledWithoutAcceptingTandC,
} from 'store/selectors/termsAndConditions';
import { getIsKycDone, getIsUserInPaperTrading } from 'store/selectors/auth';
import { useSecurities } from './useSecurities';
import { isTnCModalStillHidden } from 'common/utils/termsAndConditions';
// Uncomment when stocks TNCs are live.
/* import { getStocksTncPopupClosedAt } from 'store/selectors/settings'; */

const useDisplayStocksTnC = () => {
  const acceptedTnC = useSelector(getIsStocksTermsAccepted);
  const isKycDone = useSelector(getIsKycDone);
  const isDemo = useSelector(getIsUserInPaperTrading);
  const isTermsAndConditionsClickedAway = useSelector(getIsStocksTermsAndConditionsClickedAway);
  const isDataInitiallyLoaded = useSelector(getIsStocksTermsAndConditionsDataInitiallyLoaded);
  const isTradingBlocked = useSelector(getIsStocksTradingDisabledWithoutAcceptingTandC);
  // Uncomment when stocks TNCs are live.
  /*   const tncPopupClosedAt = useSelector(getStocksTncPopupClosedAt); */

  if (window.env.STOCKS_TNC_ENABLED !== 'true') return false;

  const { stocksEnabled } = useSecurities();

  if (!stocksEnabled) return false;

  if (acceptedTnC) return false;

  if (!isKycDone) return false;

  if (isDemo) return false;

  if (isTermsAndConditionsClickedAway) return false;

  if (!isDataInitiallyLoaded) return false;

  if (isTradingBlocked) return true;

  // Uncomment when stocks TNCs are live.
  // if (isTnCModalStillHidden(tncPopupClosedAt)) return false;

  return true;
};

export default useDisplayStocksTnC;
