import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';
import { trackAppEvent } from 'store/actions/tracking';
import {
  trackLoginOpenScreen,
  trackLoginEnterTextEmail,
  trackLoginEnterTextPassword,
  trackLoginPressButtonLogin,
  trackLoginPressButtonForgotPass,
} from '../biEventDefinitions';

export function* logWelcomeEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const welcomeScreenId = 'welcome';
  const lastScreenId: string = yield select(getCurrentPath);

  switch (action.eventName) {
    case 'loginScreenOpen':
      yield call(trackLoginOpenScreen, lastScreenId, welcomeScreenId, 'login', false);
      break;

    case 'loginUserEndEditingEmail': {
      const { isValidEmail } = action.payload;
      yield call(trackLoginEnterTextEmail, lastScreenId, isValidEmail);
      break;
    }
    case 'loginUserEndEditingPassword': {
      yield call(trackLoginEnterTextPassword, lastScreenId);
      break;
    }
    case 'loginUserPressLogIn':
      yield call(trackLoginPressButtonLogin, lastScreenId, action.payload.pressType || null);
      break;

    case 'loginUserPressForgotPassword':
      yield call(trackLoginPressButtonForgotPass, lastScreenId);
      break;

    default:
      break;
  }
}
