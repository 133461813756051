import styled from 'styled-components';
import { dimensions } from 'common/styles';
import { Link } from 'react-router-dom';
import { Theme } from 'types/theme';

const BaseContainer = styled.div`
  padding-top: 0;
  padding-bottom: ${dimensions.smallPadding}px;
`;

export const ItemContainer = styled(BaseContainer)`
  display: block;
  padding-bottom: 12px;
  width: 100%;
`;

export const EmailContainer = styled(BaseContainer)`
  display: block;
  width: 100%;
  margin-bottom: ${dimensions.mediumPadding}px;
`;

export const ActionLink = styled.div.attrs({ className: 'small-text' })`
  width: 100%;
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.text};
`;

export const TagLink = styled.a.attrs({ target: '_blank', className: 'small-text medium-weight' })`
  width: 100%;
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.text};
`;

export const RouteLink = styled(Link)`
  width: 100%;
  cursor: pointer;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize};
  font-weight: ${({ theme }: { theme: Theme }) => theme.typography.fontWeight.medium};
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.text};
`;

export const DisabledLink = styled.div.attrs({ className: 'medium-weight' })`
  width: 100%;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.text};
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize};
`;
