import {
  AlternativePaymentMethods,
  InitTopUpRequest,
  InitTopUpRequestWithTwoFa,
  InitTopUpResponse,
  PaymentMethodType,
  TopUpLimits,
  TopUpType,
} from 'types/topUps';

// top ups
export const SET_TOP_UPS_FEATURE = 'SET_TOP_UPS_FEATURE';

export const INIT_TOP_UP_REQUEST = 'INIT_TOP_UP_REQUEST';
export const INIT_TOP_UP_REQUEST_SUCCESS = 'INIT_TOP_UP_REQUEST_SUCCESS';
export const INIT_TOP_UP_REQUEST_FAILURE = 'INIT_TOP_UP_REQUEST_FAILURE';

export const CONFIRM_TOP_UP_REQUEST = 'CONFIRM_TOP_UP_REQUEST';
export const CONFIRM_TOP_UP_SUCCESS = 'CONFIRM_TOP_UP_SUCCESS';
export const CONFIRM_TOP_UP_FAILURE = 'CONFIRM_TOP_UP_FAILURE';

export const CANCEL_TOP_UP = 'CANCEL_TOP_UP';
export const CANCEL_TOP_UP_SUCCESS = 'CANCEL_TOP_UP_SUCCESS';
export const CANCEL_TOP_UP_FAILURE = 'CANCEL_TOP_UP_FAILURE';

// payment methods
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';
export const DELETE_LAST_PAYMENT_METHOD = 'DELETE_LAST_PAYMENT_METHOD';

// check and set alternative payment methods from stripe if available (applePay and googlePay)
export const SET_ALTERNATIVE_PAYMENT_METHODS = 'SET_ALTERNATIVE_PAYMENT_METHODS';

export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const REMOVE_CREATED_PAYMENT_METHOD = 'REMOVE_CREATED_PAYMENT_METHOD';

export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
export const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';

// limits
export const FETCH_TOP_UP_LIMITS = 'FETCH_TOP_UP_LIMITS';
export const FETCH_TOP_UP_LIMITS_SUCCESS = 'FETCH_TOP_UP_LIMITS_SUCCESS';
export const FETCH_TOP_UP_LIMITS_FAILURE = 'FETCH_TOP_UP_LIMITS_FAILURE';

export const TOGGLE_TOP_UP_INITIALIZING = 'TOGGLE_TOP_UP_INITIALIZING';

export const TOP_UP_SUCCESS = 'TOP_UP_SUCCESS';
export const TOP_UP_FAILURE = 'TOP_UP_FAILURE';

export const setTopUpsFeature = (payload: { enable: boolean }) => ({
  type: SET_TOP_UPS_FEATURE,
  payload: payload.enable,
});

export const initTopUpRequest = (payload: {
  request: InitTopUpRequest;
  onSuccess: (response: InitTopUpResponse) => void;
  retry?: boolean;
}) => ({
  type: INIT_TOP_UP_REQUEST,
  payload,
});

export const initTopUpRequestSuccess = (payload: InitTopUpResponse) => ({
  type: INIT_TOP_UP_REQUEST_SUCCESS,
  payload,
});

export const initTopUpRequestFailure = () => ({
  type: INIT_TOP_UP_REQUEST_FAILURE,
});

export const confirmTopUpRequest = (payload: {
  request: InitTopUpRequestWithTwoFa;
  onSuccess: (response: TopUpType) => void;
  onFail?: () => void;
}) => ({
  type: CONFIRM_TOP_UP_REQUEST,
  payload,
});

export const confirmTopUpSuccess = (payload: TopUpType) => ({
  type: CONFIRM_TOP_UP_SUCCESS,
  payload,
});

export const cancelTopUp = (payload: { topUpId: string }) => ({
  type: CANCEL_TOP_UP,
  payload,
});

export const cancelTopUpSuccess = () => ({
  type: CANCEL_TOP_UP_SUCCESS,
});

export const cancelTopUpFailure = () => ({
  type: CANCEL_TOP_UP_FAILURE,
});

export const deletePaymentMethod = (payload: { id: string }) => ({
  type: DELETE_PAYMENT_METHOD,
  payload,
});

export const deletePaymentMethodSuccess = () => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
});

export const deletePaymentMethodFailure = () => ({
  type: DELETE_PAYMENT_METHOD_FAILURE,
});

export const deleteLastPaymentMethod = () => ({
  type: DELETE_LAST_PAYMENT_METHOD,
});

export const createPaymentMethod = (payload: PaymentMethodType) => ({
  type: CREATE_PAYMENT_METHOD,
  payload,
});

export const selectPaymentMethod = (payload: PaymentMethodType) => ({
  type: SELECT_PAYMENT_METHOD,
  payload,
});

export const removeCreatedPaymentMethod = () => ({
  type: REMOVE_CREATED_PAYMENT_METHOD,
});

export const fetchPaymentMethods = () => ({
  type: FETCH_PAYMENT_METHODS,
});

export const fetchPaymentMethodsSuccess = (paymentMethods: PaymentMethodType[]) => ({
  type: FETCH_PAYMENT_METHODS_SUCCESS,
  payload: paymentMethods,
});

export const fetchPaymentMethodsFailure = () => ({
  type: FETCH_PAYMENT_METHODS_FAILURE,
});

export const setAlternativePaymentMethods = (
  alternativePaymentMethods: { [key in AlternativePaymentMethods]: boolean } | null,
) => ({
  type: SET_ALTERNATIVE_PAYMENT_METHODS,
  payload: alternativePaymentMethods,
});

export const fetchTopUpLimits = () => ({
  type: FETCH_TOP_UP_LIMITS,
});

export const fetchTopUpLimitsSuccess = (limits: TopUpLimits) => ({
  type: FETCH_TOP_UP_LIMITS_SUCCESS,
  payload: limits,
});

export const fetchTopUpLimitsFailure = () => ({
  type: FETCH_TOP_UP_LIMITS_FAILURE,
});

export const toggleTopUpInitializing = (isTopUpInitialising: boolean) => ({
  type: TOGGLE_TOP_UP_INITIALIZING,
  payload: isTopUpInitialising,
});

export const topUpSuccess = () => ({
  type: TOP_UP_SUCCESS,
});

export const topUpFailure = () => ({
  type: TOP_UP_FAILURE,
});
