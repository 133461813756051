/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-template-expressions */
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { AppReduxEpicMiddleware, State } from 'store/types/store';
import { isPresent } from 'safetypings';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import * as api from '../../common/api';
import { getAccessToken } from 'store/selectors/auth';
import { handleAjaxError } from './auth';
import { Observable, from, interval, of } from 'rxjs';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createSavingPlan,
  createSavingPlanError,
  createSavingPlanSuccess,
  deleteSavingPlan,
  deleteSavingPlanError,
  deleteSavingPlanSuccess,
  pauseSavingPlan,
  pauseSavingPlanError,
  pauseSavingPlanSuccess,
  subscribeToSavingsPlans,
  subscribeToSavingsPlansError,
  subscribeToSavingsPlansSuccess,
  unpauseSavingPlan,
  unpauseSavingPlanError,
  unpauseSavingPlanSuccess,
  unsubscribeFromSavingsPlans,
} from 'store/slices/savingsPlans/actions';
import { SAVINGS_PLANS_FETCH_INTERVAL_IN_MS } from 'common/const/savingsPlans';
import {
  SavingsPlanCreateAction,
  SavingsPlanCreateAction$,
  SavingsPlanDeleteAction,
  SavingsPlanDeleteAction$,
  SavingsPlanPauseAction,
  SavingsPlanPauseAction$,
  SavingsPlanUnpauseAction,
  SavingsPlanUnpauseAction$,
} from 'store/slices/savingsPlans/action-types';
import * as notificationBannerActions from '../actions/notificationBanner';
import I18n from 'i18next';
import { paths } from 'common/urls';

// GET ALL
const fetchSavingPlansIntervalEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: Observable<PayloadAction>,
  state$: StateObservable<State>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(subscribeToSavingsPlans.type),
    switchMap(() =>
      interval(SAVINGS_PLANS_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map(getAccessToken),
            filter(isPresent),
            take(1),
            switchMap((accessToken) =>
              from(api.fetchSavingsPlans(accessToken)).pipe(
                pluck('response'),
                map(subscribeToSavingsPlansSuccess),
                catchError(handleAjaxError(action$, subscribeToSavingsPlansError)),
              ),
            ),
          ),
        ),
        takeUntil(action$.pipe(ofType(unsubscribeFromSavingsPlans.type))),
      ),
    ),
  );

// PAUSE
const pauseSavingPlanEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: SavingsPlanPauseAction$,
  state$: StateObservable<State>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(pauseSavingPlan.type),
    switchMap((action: SavingsPlanPauseAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.postSavingsPlansStatus({ status: 'pause', id: action.payload }, accessToken)).pipe(
            map(() => pauseSavingPlanSuccess(action.payload)),
            catchError(handleAjaxError(action$, () => pauseSavingPlanError(action.payload))),
          ),
        ),
      ),
    ),
  );

// UNPAUSE
const unpauseSavingPlanEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: SavingsPlanUnpauseAction$,
  state$: StateObservable<State>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(unpauseSavingPlan.type),
    switchMap((action: SavingsPlanUnpauseAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          from(api.postSavingsPlansStatus({ status: 'unpause', id: action.payload }, accessToken)).pipe(
            map(() => unpauseSavingPlanSuccess(action.payload)),
            catchError(handleAjaxError(action$, () => unpauseSavingPlanError(action.payload))),
          ),
        ),
      ),
    ),
  );

// DELETE
const deleteSavingPlanEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: SavingsPlanDeleteAction$,
  state$: StateObservable<State>,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(deleteSavingPlan.type),
    mergeMap((action: SavingsPlanDeleteAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          from(api.deleteSavingsPlan(accessToken, action.payload.entityId.toString())).pipe(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            mapTo(deleteSavingPlanSuccess(action.payload)),
            catchError(handleAjaxError(action$, () => deleteSavingPlanError(action.payload), action.payload)),
          ),
        ),
      ),
    ),
  );

const deleteSavingPlanSuccessEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: SavingsPlanDeleteAction$,
  state$: StateObservable<State>,
  { navigate }: AppReduxEpicMiddleware,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(deleteSavingPlanSuccess.type),
    mergeMap((action: SavingsPlanDeleteAction) =>
      of(
        notificationBannerActions.notifySuccess({
          message: I18n.t('savings.disable.success'),
        }),
      ).pipe(
        tap(() => {
          navigate(paths.ORDERS);
        }),
      ),
    ),
  );

const deleteSavingPlanErrorEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: SavingsPlanDeleteAction$,
  state$: StateObservable<State>,
  { navigate }: AppReduxEpicMiddleware,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(deleteSavingPlanError.type),
    mergeMap((action: SavingsPlanDeleteAction) =>
      of(
        notificationBannerActions.notifyError({
          message: I18n.t('savings.disable.error'),
        }),
      ).pipe(
        tap(() => {
          navigate(paths.ORDERS);
        }),
      ),
    ),
  );

// CREATE
const createSavingPlanEpic = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action$: SavingsPlanCreateAction$,
  state$: StateObservable<State>,
  { ajax, api }: any,
) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  action$.pipe(
    ofType(createSavingPlan.type),
    mergeMap((action: SavingsPlanCreateAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          ajax({
            method: 'POST',
            url: api.getBaseUrlForTrading(accessToken).concat('/savingPlan/submit'),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            timeout: 20000,
            body: action.payload.model,
          }).pipe(
            tap(() => {
              if (action.payload.onSuccess) action.payload.onSuccess();
            }),
            map(createSavingPlanSuccess),
          ),
        ),
        catchError(() => {
          if (action.payload.onError) action.payload.onError();

          return of(handleAjaxError(action$, () => createSavingPlanError()));
        }),
      ),
    ),
  );

export default combineEpics(
  fetchSavingPlansIntervalEpic,
  pauseSavingPlanEpic,
  unpauseSavingPlanEpic,
  deleteSavingPlanEpic,
  deleteSavingPlanSuccessEpic,
  deleteSavingPlanErrorEpic,
  createSavingPlanEpic,
);
