import styled from 'styled-components';
import { Theme } from 'types/theme';
import { ListItemBoxDiv } from '../../LayoutContainer/LayourNarrow/ListItemBox/ListItemBox';
import { MainContainer } from '../../LayoutContainer/LayourNarrow/styles';

export const Title = styled.div.attrs({ className: 'xlarge-text strong-weight' })`
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
`;

export const BottomText = styled.div.attrs({ className: 'small-text' })`
  letter-spacing: 0em;
  display: block;
  flex: 0 0 100%;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryLight};
  padding-bottom: 8px;
`;

export const ConfirmWrapper = styled.div`
  & ${MainContainer} {
    box-shadow: none;
  }

  padding-right: 3px;
`;

export const CustomListItemBox = styled(ListItemBoxDiv)`
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const IconWrapper = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${({ color }: { color: string }) => color || '#000000'};
`;
