import { EMPTY, fromEvent, merge, Observable, of } from 'rxjs';
import {
  concatMap,
  debounceTime,
  filter,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { isAjaxErrorTimeout } from 'common/utils/rxjs-ajax';
import {
  AUTH,
  CANCEL_WAIT_CONFIRMATION,
  CONFIRMATION_MAIL,
  invalidateTokens,
  loginUser,
  RESEND_CONFIRMATION_MAIL,
  startResendConfirmationMail,
  TOKEN_REFRESH,
  TOKEN_VALIDATION,
  WAIT_CONFIRMATION,
} from 'store/actions/auth';
import { NotificationProps, notifyError } from 'store/actions/notificationBanner';
import { ApiError } from 'types/error';
import { isOfType } from 'safetypings';
import { PAIR_DEVICE_START } from 'store/actions/forms';
import { errorCodes } from 'common/apiErrors';
import { getLoginRetryDelayTime, getTooManyRetriesMessageCode } from 'common/utils/auth';
import { AppReduxEpicMiddleware, State } from 'store/types/store';
import { shouldLogLoginFailedEventToBraze } from 'common/utils/braze';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
  AjaxErrorResponse,
  AjaxErrorWithCustomResponse,
  AuthErrorResponse,
  AuthSuccessResponse,
} from 'store/types/auth';
import { Credentials } from 'common/const';
import I18n from 'i18next';
import { isAmlOverdue } from 'common/utils/auditProof';

// actions
export const httpError = (error: ApiError) => ({
  type: 'HTTP_ERROR',
  ...error,
});

// epics
export const refreshToken = (
  action$: Observable<AnyAction>,
  _error: Error,
  source: Observable<unknown>,
): Observable<unknown> =>
  // merge is similar to startWith, but be mindful that a
  // startWith is just shorthand for a concat, not a merge,
  // which means it would synchronously emit the action before
  // we have started to listen for a success one
  merge(
    action$.pipe(
      ofType(TOKEN_REFRESH.SUCCESS),
      takeUntil(action$.pipe(ofType(TOKEN_REFRESH.FAILURE))),
      take(1),
      mergeMap(() => source),
    ),
    of(invalidateTokens()),
  );

export const isAuthTokenExpiredError = (error: AjaxErrorWithCustomResponse<AjaxErrorResponse>): boolean =>
  error.response?.data?.code === 'auth_err_tokenexpired' || error.response?.code === 'auth_err_tokeninvalid';

const getResponseHeadersMap = (xhrHeaders: string) =>
  xhrHeaders
    .trim()
    .split(/[\r\n]+/)
    .map((value) => value.split(': '))
    .reduce((acc, [header, value]) => ({ ...acc, [header.toLowerCase()]: value }), {});

export const normalizeAjaxErrorResponse = (
  error: AjaxErrorWithCustomResponse<AjaxErrorResponse>,
): ApiError => ({
  url: error?.request?.url,
  statusCode: error?.status,
  response: error?.response,
  errorCode: error?.response?.data?.code,
  requestHeaders: error?.request?.headers,
  responseHeaders: getResponseHeadersMap(error?.xhr?.getAllResponseHeaders() ?? ''),
  isTimeout: isAjaxErrorTimeout(error),
});

// enables you to pass your own action to dispatch in case of unhandled failure
export const handleAjaxError =
  (
    action$: Observable<AnyAction>,
    errorAction: (error: ApiError, props?: unknown) => AnyAction | typeof httpError,
    payload?: unknown,
    notify?: NotificationProps,
  ) =>
  (error: AjaxError, source: Observable<unknown>) => {
    const errAction = notify
      ? of(errorAction(normalizeAjaxErrorResponse(error), payload), notifyError(notify))
      : of(errorAction(normalizeAjaxErrorResponse(error), payload));

    return isAuthTokenExpiredError(error) ? refreshToken(action$, error, source) : errAction;
  };

export const watchWaitConfirmationEpic = (action$: Observable<PayloadAction>, state$: Observable<State>) =>
  action$.pipe(
    filter(isOfType([WAIT_CONFIRMATION, PAIR_DEVICE_START])),
    filter(({ secret }) => !!secret),
    switchMap(({ secret }: { secret: string }) =>
      action$.pipe(
        filter(isOfType(RESEND_CONFIRMATION_MAIL)),
        switchMap(({ sendSecurityCode }: { sendSecurityCode: boolean }) =>
          state$.pipe(() => of(startResendConfirmationMail({ sendSecurityCode, secret }))),
        ),
      ),
    ),
  );

/**
 * Creates an observable that emits a boolean value indicating whether the document is visible.
 *
 * This observable:
 * - Emits `true` if the document is visible.
 * - Emits `false` if the document is hidden.
 * - Replays the latest visibility status to new subscribers.
 */
const visibilitychange$ = fromEvent(document, 'visibilitychange').pipe(
  // Switch to a new observable that emits the opposite of document.hidden whenever the visibility changes
  map(() => !document.hidden),
  // Start the observable with the current visibility status
  startWith(!document.hidden),
  // Share the latest value with all subscribers and replay the last emitted value to new subscribers
  shareReplay(1),
);

// Epic to handle login with retry logic after registration (until email verification is done)
export const watchWaitEmailConfirmationEpic = (action$: Observable<PayloadAction<AuthErrorResponse>>) =>
  action$.pipe(
    // Filter for AUTH_FAILURE action type and check for specific LOGIN_ERR_NOTCONFIRM error code
    filter(isOfType(AUTH.FAILURE)),
    filter(({ error }: AuthErrorResponse) => error.code === errorCodes.LOGIN_ERR_NOTCONFIRM),
    // Accumulate retry attempts and credentials
    scan(
      (acc, action) => ({
        count: acc.count + 1,
        credentials: action?.request?.credentials ?? {
          username: '',
          secret: '',
          secretType: 'Password',
          displayWaitingScreen: false,
        },
      }),
      {
        count: 0,
        credentials: {} as Credentials,
      },
    ),
    // Handle visibility changes and retry login
    switchMap(({ count, credentials }) =>
      visibilitychange$.pipe(
        filter((v) => v), // Only proceed if the tab is visible
        debounceTime(getLoginRetryDelayTime(count)), // Wait for a specific time before retrying
        take(1),
        concatMap(() => {
          const { username, secret, secretType, displayWaitingScreen } = credentials;
          // Dispatch login action with stored credentials
          return of(loginUser(username, secret, secretType as unknown as boolean, displayWaitingScreen));
        }),
        // Cancel the subscription if the cancel action is dispatched
        takeUntil(action$.pipe(ofType(CANCEL_WAIT_CONFIRMATION))),
      ),
    ),
  );

export const watchLoginFailForBrazeEpic = (
  action$: Observable<PayloadAction<AuthErrorResponse>>,
  state$,
  { braze }: AppReduxEpicMiddleware,
) =>
  action$.pipe(
    // Catch every auth fail action
    filter(isOfType(AUTH.FAILURE)),
    // Check if error object is present and if status code matches braze login failed condition
    filter(
      (payload: AuthErrorResponse) =>
        !!payload?.error?.status && shouldLogLoginFailedEventToBraze(payload.error.status),
    ),
    // Get braze instance
    map((payload) => ({
      brazeInstance: braze(),
      username: payload?.request?.credentials?.username,
    })),
    // Check if braze is available
    filter(({ brazeInstance }) => !!brazeInstance),
    // Fire custom Braze event
    tap(({ brazeInstance, username }) => {
      const userId = brazeInstance?.getBrazeUser()?.getUserId();

      if (userId !== username) {
        brazeInstance?.setBrazeUser(username ?? '');
      }

      brazeInstance?.logCustomEvent('login_fail', { failed_at: new Date().toUTCString() });
    }),
    mergeMap(() => EMPTY),
  );

const watchNewUserForBrazeEpic = (
  action$: Observable<PayloadAction<AuthSuccessResponse>>,
  state$,
  { braze }: AppReduxEpicMiddleware,
) =>
  action$.pipe(
    filter(isOfType(AUTH.SUCCESS)),
    filter((payload: AuthSuccessResponse) => !!payload?.response?.accessToken),
    map((payload: AuthSuccessResponse) => payload?.response?.accessToken),
    map((token) => jwtDecode<JwtPayload>(token!).sub),
    map((userId) => ({ userId, brazeInstance: braze() })),
    filter(({ brazeInstance }) => !!brazeInstance),
    tap(({ brazeInstance, userId }) => {
      brazeInstance?.setBrazeUser(userId!);
    }),
    mergeMap(() => EMPTY),
  );

const watchTooManyRetriesEpic = (action$: Observable<PayloadAction<AuthErrorResponse>>) =>
  action$.pipe(
    filter(isOfType([AUTH.FAILURE, CONFIRMATION_MAIL.FAILURE])),
    filter(({ error }: AuthErrorResponse) => error.code === errorCodes.GENERAL_ERR_TOOMANYTRIES),
    map(({ error }: AuthErrorResponse) => {
      const blockedUntil = error?.metadata?.blockedUntil;

      if (!blockedUntil) return EMPTY;

      const messageCode = getTooManyRetriesMessageCode(blockedUntil);
      return notifyError({ message: I18n.t(messageCode) });
    }),
  );

const watchIsAmlStatusOverdueEpic = (action$, state$, { modalQueue }) =>
  action$.pipe(
    filter(isOfType(TOKEN_VALIDATION.SUCCESS)),
    filter(({ response }) => isAmlOverdue(response.amlUpdate.confirmationStatus)),
    map(() => ({ modalQueueInstanse: modalQueue() })),
    filter(({ modalQueueInstanse }) => !!modalQueueInstanse),
    tap(({ modalQueueInstanse }) => modalQueueInstanse?.blockModalQueue()),
    mergeMap(() => EMPTY),
  );

export default combineEpics(
  watchWaitConfirmationEpic,
  watchWaitEmailConfirmationEpic,
  watchTooManyRetriesEpic,
  watchLoginFailForBrazeEpic,
  watchIsAmlStatusOverdueEpic,
  watchNewUserForBrazeEpic,
);
