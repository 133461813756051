// slices/newsletter/actions.ts

import newsletterSlice from '.';

export const {
  fetchNewsletterFailure,
  fetchNewsletterRequest,
  fetchNewsletterSuccess,
  setNewsletterFailure,
  setNewsletterRequest,
  setNewsletterSuccess,
} = newsletterSlice.actions;