import React, { FunctionComponent } from 'react';
import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';
import { Trans, useTranslation } from 'react-i18next';
import AssetStakingImage from 'common/components/AssetStakingImage';
import {
  BodyWrapper,
  HeaderWrapper,
  Icon,
  ImageWrapper,
  InformationItem,
  ItemTitle,
  Link,
  Text,
  TextWrapper,
  Title,
  TitleGreen,
  LegalText,
  ActionsWrapper,
} from 'common/components/Modal/Staking/StakingIntroModal/styles';
import { ParsedStakingOverviewItem } from 'types/staking';
import { getSupportCenterStakingRewardsUrl } from 'common/urls';
import { ModalProps } from 'types/modals';
import { useAsset } from 'common/hooks/useAsset';
import { ASSET_CLASSES } from 'types/assets';
import { useSelector } from 'react-redux';
import { getAvailableCrypto } from 'store/slices/portfolio/selectors';
import { extractStakingOverviewItem } from 'scenes/StakingOverview/helpers';
import { getCryptoAssets } from 'store/slices/assets/selectors';
import { selectIsStakingInfoLoading, selectStakingInfoAllCryptos } from 'store/slices/staking/selectors';
import { MODAL_DIMENSIONS } from 'common/const/modals';
import { UNSTAKING_PERIOD_DAYS } from 'common/const/staking';

type Props = {
  assetCode: string;
} & ModalProps;

const StakingIntroModal: FunctionComponent<Props> = ({ assetCode, onClose }) => {
  const { t } = useTranslation();
  const cryptoInstruments = useSelector(getAvailableCrypto);
  const cryptoAssets = useSelector(getCryptoAssets);
  const isStakingInfoLoading = useSelector(selectIsStakingInfoLoading);
  const asset = useAsset(assetCode, ASSET_CLASSES.CRYPTO, undefined);
  const stakingInfo = useSelector(selectStakingInfoAllCryptos);

  const symbol = assetCode.toUpperCase();
  const assetColor = asset?.color ?? '';
  const crypto = cryptoInstruments.find((item) => item.entity.toLowerCase() === assetCode.toLowerCase());
  const stakingData: ParsedStakingOverviewItem | undefined =
    crypto && extractStakingOverviewItem(crypto, cryptoAssets, stakingInfo[crypto.entity.toLowerCase()]);

  if (isStakingInfoLoading) return <></>;

  return (
    <ModalTemplate
      defaultOpen
      onCancel={onClose}
      modalDimensions={MODAL_DIMENSIONS.DEFAULT_WITH_REDUCED_VERTICAL_PADDING}
      actions={
        <ActionsWrapper>
          <Button
            inverted
            title={t('staking.infoPopup.confirm')}
            size="modal"
            onClick={onClose}
            data-test-id="staking.infoPopup.confirm"
          />
          <LegalText>{t('staking.infoPopup.legal', { assetCode: symbol })}</LegalText>
        </ActionsWrapper>
      }
      header={
        <HeaderWrapper>
          <ImageWrapper>
            <AssetStakingImage color={assetColor} />
          </ImageWrapper>
          <Title>
            <Trans
              i18nKey="staking.infoPopup.title"
              values={{ assetCode: symbol, apr: `${stakingData?.aprInfo}` }}
              components={{ 1: <TitleGreen /> }}
            />
          </Title>
        </HeaderWrapper>
      }
    >
      <BodyWrapper>
        <InformationItem>
          <Icon name="custom-icon-sand-clock" />
          <TextWrapper>
            <ItemTitle>{t('staking.infoPopup.body.0.title', { assetCode: symbol })}</ItemTitle>
            <Text>{t('staking.infoPopup.body.0.text', { assetCode: symbol })}</Text>
          </TextWrapper>
        </InformationItem>
        <InformationItem>
          <Icon name="icon-arrows-swap" />
          <TextWrapper>
            <ItemTitle>{t('staking.infoPopup.body.1.title', { assetCode: symbol })}</ItemTitle>
            <Text>{t('staking.infoPopup.body.1.text', { assetCode: symbol })}</Text>
          </TextWrapper>
        </InformationItem>
        <InformationItem>
          <Icon name="icon-clock" />
          <TextWrapper>
            <ItemTitle>
              {t('staking.infoPopup.body.2.title', {
                periodStart: UNSTAKING_PERIOD_DAYS.START,
                periodEnd: UNSTAKING_PERIOD_DAYS.END,
              })}
            </ItemTitle>
            <Text>{t('staking.infoPopup.body.2.text', { assetCode: symbol })}</Text>
          </TextWrapper>
        </InformationItem>
        <InformationItem>
          <Icon name="icon-stars" />
          <TextWrapper>
            <ItemTitle>{t('staking.infoPopup.body.3.title')}</ItemTitle>
            <Text>
              <Trans
                i18nKey={'staking.infoPopup.body.3.text'}
                components={{ 1: <Link target="_blank" href={getSupportCenterStakingRewardsUrl()} /> }}
              />
            </Text>
          </TextWrapper>
        </InformationItem>
      </BodyWrapper>
    </ModalTemplate>
  );
};

export default StakingIntroModal;
