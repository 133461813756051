import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/Button';
import { ModalProps } from 'types/modals';
import ModalTemplate from '../index';

type Props = {
  persist?: boolean;
  Header?: string | JSX.Element;
  Content: ReactElement | string;
  ButtonTitle?: string;
  open: boolean;
  actions?: ReactElement;
  modalId?: string;
} & ModalProps;

const AlertModal = ({
  Header,
  Content,
  ButtonTitle,
  open,
  onClose,
  actions,
  persist = false,
  modalId,
  ...rest
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ModalTemplate
      modalId={modalId}
      defaultOpen={open}
      onCancel={onClose}
      header={Header}
      persist={persist}
      actions={actions ?? <Button size="modal" title={ButtonTitle ?? t('common.ok')} onClick={onClose} />}
      {...rest}
    >
      <div className="body-text">{Content}</div>
    </ModalTemplate>
  );
};

AlertModal.defaultProps = {
  Header: '',
  actions: null,
  persist: false,
  ButtonTitle: '',
  modalId: undefined,
};

export default AlertModal;
