import React, { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { paths } from 'common/urls';
import { getSelectedCryptoCurrency } from 'store/selectors/currency';
import { useDispatch, useSelector } from 'react-redux';
import { anyPriceAlertWithEntity } from 'store/slices/priceAlerts/selectors';
import { State } from 'store/types/store';
import { fetchPriceAlerts } from 'store/slices/priceAlerts/actions';
import PriceAlertIcon from './styles';

const PriceAlertIconWithState = () => {
  const selectedCrypto = useSelector(getSelectedCryptoCurrency);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const entity = params?.currency ?? '';
  const isSelectedCryptoAlertsExist = useSelector((state: State) => anyPriceAlertWithEntity(state, entity));

  useEffect(() => {
    dispatch(fetchPriceAlerts());
  }, []);

  return (
    <PriceAlertIcon
      onClick={() =>
        navigate(generatePath(paths.PRICE_ALERTS_NEW, { currency: selectedCrypto.toLowerCase() }))
      }
      name={isSelectedCryptoAlertsExist ? 'custom-icon-bell-filled' : 'icon-outlined-bell'}
      sizeValue={isSelectedCryptoAlertsExist ? 'small' : 'tiniest'}
    />
  );
};

export default PriceAlertIconWithState;
