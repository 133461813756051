import * as R from 'ramda';
import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './_common';
import { PaymentMethodType } from 'types/topUps';

// CRYPTO KYC
export const KYC_FORM_SUBMIT = createRequestTypes('KYC_FORM_SUBMIT');
export const KYC_FORM_EXIT = createRequestTypes('KYC_FORM_EXIT');
export const KYC_DATA = createRequestTypes('KYC_DATA');
export const CHANGE_USERNAME_CONFIRM = createRequestTypes('CHANGE_USERNAME_CONFIRM');
export const MOBILE_NUMBER_DELETE = createRequestTypes('MOBILE_NUMBER_DELETE');
export const MOBILE_NUMBER_ASSIGN = createRequestTypes('MOBILE_NUMBER_ASSIGN');
export const UPDATE_KYC_DATA = 'UPDATE_KYC_DATA';
export const NEXT_KYC_STEP = 'NEXT_KYC_STEP';
export const PREV_KYC_STEP = 'PREV_KYC_STEP';
export const KYC_NUMBER_OF_STEPS = 'KYC_NUMBER_OF_STEPS';
export const KYC_STARTED = 'KYC_STARTED';
export const KYC_VIEWED_STEP = 'KYC_VIEWED_STEP';
export const SET_KYC_STARTED = 'SET_KYC_STARTED';
export const USER_KYC_EXIT = 'USER_KYC_EXIT';
export const RESET_CHECKBOXES_KYC = 'RESET_CHECKBOXES_KYC';
export const FETCH_KYC_DATA = 'FETCH_KYC_DATA';
export const CLOSE_KYC = 'CLOSE_KYC';
export const REFRESH_KYC_STATUS = 'REFRESH_KYC_STATUS';

// SECURITIES KYC
export const SECURITIES_KYC_FORM_SUBMIT = createRequestTypes('SECURITIES_KYC_FORM_SUBMIT');
export const INVALIDATE_SECURITIES_KYC_FAILED = 'INVALIDATE_SECURITIES_KYC_FAILED';
export const SECURITY_ADDITIONAL_INFO = createRequestTypes('SECURITY_ADDITIONAL_INFO');
export const CHANGE_SECURITIES_KYC_STATUS = 'CHANGE_SECURITIES_KYC_STATUS';
export const CHANGE_SECURITIES_KNOWLEDGE = createRequestTypes('CHANGE_SECURITIES_KNOWLEDGE');

export const START_REGISTRATION = 'START_REGISTRATION';
export const SUBMIT_LOGIN_USER = 'SUBMIT_LOGIN_USER';
export const SUBMIT_RESET_PASSWORD = 'SUBMIT_RESET_PASSWORD';
export const SUBMIT_RESET_PASSWORD_STATUS = 'SUBMIT_RESET_PASSWORD_STATUS';
export const SUBMIT_REGISTER_USER = 'SUBMIT_REGISTER_USER';
export const SUBMIT_CHANGE_PASSWORD = 'SUBMIT_CHANGE_PASSWORD';
export const START_CHANGE_USERNAME = 'START_CHANGE_USERNAME';
export const START_CHANGE_ADDRESS = 'START_CHANGE_ADDRESS';
export const START_DELETE_MOBILE = 'START_DELETE_MOBILE';
export const START_ASSIGN_MOBILE = 'START_ASSIGN_MOBILE';
export const CONFIRM_USERNAME_CHANGE = 'CONFIRM_USERNAME_CHANGE';
export const TAX_ID_CHANGE = 'TAX_ID_CHANGE';
export const TAX_ID_CHANGE_CANCEL = 'TAX_ID_CHANGE_CANCEL';
export const CONFIRM_ADDRESS_CHANGE = 'CONFIRM_ADDRESS_CHANGE';
export const PUT_NEW_ADDRESS_DATA = 'PUT_NEW_ADDRESS_DATA';
export const CONFIRM_DELETE_MOBILE = 'CONFIRM_DELETE_MOBILE';
export const CONFIRM_ASSIGN_MOBILE = 'CONFIRM_ASSIGN_MOBILE';
export const USERNAME_CHANGE_CANCEL = 'USERNAME_CHANGE_CANCEL';
export const ADDRESS_CHANGE_CANCEL = 'ADDRESS_CHANGE_CANCEL';
export const MOBILE_CHANGE_CANCEL = 'MOBILE_CHANGE_CANCEL';

export const SET_VERIFICATION_DOCUMENT = 'SET_VERIFICATION_DOCUMENT';

export const START_IDENTIFICATION = 'START_IDENTIFICATION';
export const IDENTIFICATION_FAILED = 'IDENTIFICATION_FAILED';
export const FINISH_IDENTIFICATION = 'FINISH_IDENTIFICATION';

export const INVALIDATE_TAX_RESIDENCY = 'INVALIDATE_TAX_RESIDENCY';
export const INVALIDATE_TRANSACTION_TOKEN = 'INVALIDATE_TRANSACTION_TOKEN';

export const IBAN_CHANGE_START = 'START_CHANGE_IBAN';
export const IBAN_CHANGE_FINISH = 'CONFIRM_CHANGE_IBAN';
export const REDEEM_CODE = 'REDEEM_CODE';
export const OPEN_VERIFICATION_PAGE = 'OPEN_VERIFICATION_PAGE';
export const START_CHANGE_IBAN = createRequestTypes(IBAN_CHANGE_START);
export const CONFIRM_CHANGE_IBAN = createRequestTypes(IBAN_CHANGE_FINISH);
export const CONFIRM_DEVICE_CODE = createRequestTypes('CONFIRM_DEVICE_CODE');
export const PAIR_DEVICE_START = 'PAIR_DEVICE_START';
export const PAIR_DEVICE_STOP = 'PAIR_DEVICE_STOP';
export const SUBMIT_2FA_CODE = createRequestTypes('SUBMIT_2FA_CODE');
export const SUBMIT_2FA_CODE_START = 'SUBMIT_2FA_CODE_START';
export const INVALID_2FA_CODE = 'INVALID_2FA_CODE';
export const SEND_2FA_CODE = createRequestTypes('SEND_2FA_CODE');

export const GET_TAX_RESIDENCY = createRequestTypes('GET_TAX_RESIDENCY');
export const CREATE_TAX_RESIDENCY = createRequestTypes('CREATE_TAX_RESIDENCY');
export const PUT_TAX_RESIDENCY = createRequestTypes('PUT_TAX_RESIDENCY');
export const DELETE_TAX_RESIDENCY = createRequestTypes('DELETE_TAX_RESIDENCY');
export const SUBMIT_FEATURE_REQUEST = 'SUBMIT_FEATURE_REQUEST';

export const RESET_DEAD_END_FIELD = 'RESET_DEAD_END_FIELD';

export const SUBMIT_PRECONTRACTUAL_MAIL = 'SUBMIT_PRECONTRACTUAL_MAIL';

export const SET_TOP_UP_AMOUNT = 'SET_TOP_UP_AMOUNT';
export const SET_TOP_UP_PAYMENT_METHOD = 'SET_TOP_UP_PAYMENT_METHOD';
export const INVALIDATE_TOP_UP_DATA = 'INVALIDATE_TOP_UP_DATA';

export const startLogin = () => action(START_LOGIN);

export const startRegistration = () => action(START_REGISTRATION);

export const submitLoginUser = (values: any, actions: any) => action(SUBMIT_LOGIN_USER, { values, actions });

export const submitResetPassword = (values: any, actions: any) =>
  action(SUBMIT_RESET_PASSWORD, { values, actions });

export const submitResetPasswordStatus = (values: any, actions: any) =>
  action(SUBMIT_RESET_PASSWORD_STATUS, { values, actions });

export const submitRegisterUser = (values: any, actions: any) =>
  action(SUBMIT_REGISTER_USER, { values, actions });

export const submitChangePassword = (values: any, actions: any) =>
  action(SUBMIT_CHANGE_PASSWORD, { values, actions });

export const startChangeUsername = (newUsername: string, actions: any) =>
  action(START_CHANGE_USERNAME, { newUsername, actions });

export const startChangeAddress = (values: any, actions: any, isResendCode?: boolean) =>
  action(START_CHANGE_ADDRESS, { values, actions, isResendCode });

export const startDeleteMobile = (values: any, actions: any, isResendCode?: boolean) =>
  action(START_DELETE_MOBILE, { values, actions, isResendCode });

export const confirmDeleteMobile = (values: any, actions: any) =>
  action(CONFIRM_DELETE_MOBILE, { values, actions });

export const startAssignMobile = (values: any, actions: any) =>
  action(START_ASSIGN_MOBILE, { values, actions });

export const confirmAssignMobile = (values: any, actions: any) =>
  action(CONFIRM_ASSIGN_MOBILE, { values, actions });

export const confirmUsernameChange = (values: any, actions: any) =>
  action(CONFIRM_USERNAME_CHANGE, { values, actions });

export const startChangeTaxId = (values: any, actions: any) => {
  return action(TAX_ID_CHANGE, { values, actions });
};

export const redeemCode = (values: any, actions: any, onSuccess: OnSuccess) =>
  action(REDEEM_CODE, { values, actions, onSuccess });

export const confirmAddressChange = (values: any, actions: any) =>
  action(CONFIRM_ADDRESS_CHANGE, { values, actions });

export const putNewAddressData = (address) => action(PUT_NEW_ADDRESS_DATA, { address });

export const submitFeatureRequest = (values: AnyObject, formikActions: FormikActions<FormikValues>) =>
  action(SUBMIT_FEATURE_REQUEST, { values, actions: formikActions });

export const usernameChangeCancel = () => action(USERNAME_CHANGE_CANCEL);

export const startChangeIban = (iban: any, actions: any) => action(IBAN_CHANGE_START, { iban, actions });

export const confirmChangeIban = (values: any, actions: any) =>
  action(IBAN_CHANGE_FINISH, { values, actions });

export const getTaxResidency = {
  request: () => action(GET_TAX_RESIDENCY[REQUEST]),
  success: (_, response) => action(GET_TAX_RESIDENCY[SUCCESS], { response }),
  failure: (_, error) => action(GET_TAX_RESIDENCY[FAILURE], { error }),
};

export const createTaxResidency = {
  request: (taxResidency) => action(CREATE_TAX_RESIDENCY[REQUEST], { taxResidency }),
  success: (taxResidency, response) => action(CREATE_TAX_RESIDENCY[SUCCESS], { taxResidency, response }),
  failure: (_, error) => action(CREATE_TAX_RESIDENCY[FAILURE], { error }),
};

export const putTaxResidency = {
  request: (taxResidency) => action(PUT_TAX_RESIDENCY[REQUEST], { taxResidency }),
  success: (taxResidency, response) => action(PUT_TAX_RESIDENCY[SUCCESS], { taxResidency, response }),
  failure: (_, error) => action(PUT_TAX_RESIDENCY[FAILURE], { error }),
};

export const deleteTaxResidency = {
  request: (taxResidency) => action(DELETE_TAX_RESIDENCY[REQUEST], { taxResidency }),
  success: (taxResidency, response) => action(DELETE_TAX_RESIDENCY[SUCCESS], { taxResidency, response }),
  failure: (_, error) => action(DELETE_TAX_RESIDENCY[FAILURE], { error }),
};

export const addressChangeCancel = () => action(ADDRESS_CHANGE_CANCEL);

export const mobileChangeCancel = () => action(MOBILE_CHANGE_CANCEL);

export const updateKycData = (data: any) => action(UPDATE_KYC_DATA, { data });

export const nextKycStep = (nSteps: number) => action(NEXT_KYC_STEP, { nSteps });

export const prevKycStep = () => action(PREV_KYC_STEP);

export const kycNumSteps = (nSteps: number) => action(KYC_NUMBER_OF_STEPS, { nSteps });

export const changeSecuritiesKycStatus = (status: StockKycStatus) =>
  action(CHANGE_SECURITIES_KYC_STATUS, { status });

export const setVerificationDocument = (document: VerificationDocument) =>
  action(SET_VERIFICATION_DOCUMENT, { document });

export const startIdentification = (includeBillInfo = false, navigate?: () => void) =>
  action(START_IDENTIFICATION, { includeBillInfo, navigate });
export const identificationFailed = () => action(IDENTIFICATION_FAILED);
export const finishIdentification = () => action(FINISH_IDENTIFICATION);

export const changeUsernameConfirm = {
  request: ({ data, code2fa }: { data: any; code2fa: string }) =>
    action(CHANGE_USERNAME_CONFIRM[REQUEST], { username: R.path(['change', 'username'], data), code2fa }),
  success: ({ data }: { data: any }, response) =>
    action(CHANGE_USERNAME_CONFIRM[SUCCESS], { username: R.path(['change', 'username'], data), response }),
  failure: (_, error) => action(CHANGE_USERNAME_CONFIRM[FAILURE], { error }),
};

export const changeSecuritiesKnowledge = {
  request: (successCallback) =>
    action(CHANGE_SECURITIES_KNOWLEDGE[REQUEST], { payload: { successCallback } }),
  success: (response) => action(CHANGE_SECURITIES_KNOWLEDGE[SUCCESS], { response }),
  failure: (error) => action(CHANGE_SECURITIES_KNOWLEDGE[FAILURE], { error }),
};

export const kycFormSubmit = {
  request: (formData: any) => action(KYC_FORM_SUBMIT[REQUEST], { formData }),
  success: (formData: any, response) => action(KYC_FORM_SUBMIT[SUCCESS], { formData, response }),
  failure: (formData: any, error) => action(KYC_FORM_SUBMIT[FAILURE], { formData, error }),
};

export const kycFormExit = {
  request: (formData: any) => action(KYC_FORM_EXIT[REQUEST], { formData }),
  success: (formData: any, response) => action(KYC_FORM_EXIT[SUCCESS], { formData, response }),
  failure: (formData: any, error) => action(KYC_FORM_EXIT[FAILURE], { formData, error }),
};

export const kycData = {
  request: () => action(KYC_DATA[REQUEST]),
  success: (_, response: any) => action(KYC_DATA[SUCCESS], { response }),
  failure: (_, error) => action(KYC_DATA[FAILURE], { error }),
};

export const SAVE_STOCKS_RISK_KNOWLEDGE_DATA = 'SAVE_STOCKS_RISK_KNOWLEDGE_DATA';
export const saveStocksRiskKnowledgeData = (question, answer) => ({
  type: SAVE_STOCKS_RISK_KNOWLEDGE_DATA,
  payload: { question, answer },
});

export const SAVE_SECURITIES_KYC_DATA = 'SAVE_SECURITIES_KYC_DATA';
export const saveSecuritiesKycData = (data: any) => ({ type: SAVE_SECURITIES_KYC_DATA, payload: data });

export const securitiesKycFormSubmit = {
  request: (data: AnyObject) => action(SECURITIES_KYC_FORM_SUBMIT[REQUEST], { data }),
  success: (response: { stockAccountStatus: StockKycStatus }) =>
    action(SECURITIES_KYC_FORM_SUBMIT[SUCCESS], { response }),
  failure: (error: AnyObject) => action(SECURITIES_KYC_FORM_SUBMIT[FAILURE], { error }),
};

export const startChangeIbanReq = {
  request: (iban: string, actions: any) => action(START_CHANGE_IBAN[REQUEST], { iban, actions }),
  success: (response: any, deviceData?: string) =>
    action(START_CHANGE_IBAN[SUCCESS], { response, deviceData }),
  failure: (error: any) => action(START_CHANGE_IBAN[FAILURE], { error }),
};

export const startChangeTaxIdReq = {
  request: (values: string, actions: any) => action(TAX_ID_CHANGE[REQUEST], { values, actions }),
  success: (response: any) => action(TAX_ID_CHANGE[SUCCESS], { response }),
  failure: (error: any) => action(TAX_ID_CHANGE[FAILURE], { error }),
};

export const confirmChangeIbanReq = {
  request: (values: any, actions: any) => action(CONFIRM_CHANGE_IBAN[REQUEST], { values, actions }),
  success: (response: any) => action(CONFIRM_CHANGE_IBAN[SUCCESS], { response }),
  failure: (error: any) => action(CONFIRM_CHANGE_IBAN[FAILURE], { error }),
};

export const submit2FaCode = {
  request: (args: any): any => action(SUBMIT_2FA_CODE[REQUEST], args),
  success: (response: any) => action(SUBMIT_2FA_CODE[SUCCESS], { response }),
  failure: (error: any) => action(SUBMIT_2FA_CODE[FAILURE], { error }),
};

export const mobileNumberDelete = {
  request: ({ data, code2fa }: { data: any; code2fa: string }) =>
    action(MOBILE_NUMBER_DELETE[REQUEST], { data, code2fa }),
  success: ({ data }: { data: any }, response) => action(MOBILE_NUMBER_DELETE[SUCCESS], { data, response }),
  failure: (_, error) => action(MOBILE_NUMBER_DELETE[FAILURE], { error }),
};

export const mobileNumberAssign = {
  request: ({ data, code2fa }: { data: any; code2fa: string }) =>
    action(MOBILE_NUMBER_ASSIGN[REQUEST], { data, code2fa }),
  success: ({ data }: { data: any }, response) => action(MOBILE_NUMBER_ASSIGN[SUCCESS], { data, response }),
  failure: (_, error) => action(MOBILE_NUMBER_ASSIGN[FAILURE], { error }),
};

export const confirmDeviceCode = {
  request: ({ data, code2fa }: { data: any; code2fa: string }) =>
    action(CONFIRM_DEVICE_CODE[REQUEST], { data, code2fa }),
  success: ({ data }: { data: any }, response) => action(CONFIRM_DEVICE_CODE[SUCCESS], { data, response }),
  failure: (_, error) => action(CONFIRM_DEVICE_CODE[FAILURE], { error }),
};

export const fetchKycData = () => action(FETCH_KYC_DATA);
export const kycStarted = () => action(KYC_STARTED);
export const kycViewedStep = (step: number, stepName: string) => action(KYC_VIEWED_STEP, { step, stepName });
export const setKycStarted = () => action(SET_KYC_STARTED);
export const closeKyc = () => action(CLOSE_KYC);
export const resetCheckboxesKyc = () => action(RESET_CHECKBOXES_KYC);
export const invalidateTransactionToken = () => action(INVALIDATE_TRANSACTION_TOKEN);
export const invalidateTaxResidency = () => action(INVALIDATE_TAX_RESIDENCY);
export const userKycExit = () => action(USER_KYC_EXIT);
export const refreshKycStatus = () => action(REFRESH_KYC_STATUS);
export const openVerification = () => action(OPEN_VERIFICATION_PAGE);
export const submit2FaCodeStart = (values: any, actions: any) =>
  action(SUBMIT_2FA_CODE_START, { values, actions });
export const send2FaCode = (args: any): any => action(SEND_2FA_CODE, args);
export const stop2faProcess = () => action(PAIR_DEVICE_STOP);
export const invalid2FaCode = () => action(INVALID_2FA_CODE);
export const start2faProccess = (args: any): any => action(PAIR_DEVICE_START, args);
export const invalidateSecuritiesKycFailed = () => action(INVALIDATE_SECURITIES_KYC_FAILED);

export const fetchAdditionalSecurityInfo = {
  request: (successCallback) => action(SECURITY_ADDITIONAL_INFO[REQUEST], { payload: { successCallback } }),
  success: (response: AnyObject) => action(SECURITY_ADDITIONAL_INFO[SUCCESS], { response }),
  failure: (error: AnyObject) => action(SECURITY_ADDITIONAL_INFO[FAILURE], { error }),
};

export const STORE_RENEW_KYC_FORM_DATA = 'STORE_RENEW_KYC_FORM_DATA';
export const storeRenewKycFormData = (payload) => action(STORE_RENEW_KYC_FORM_DATA, { payload });

export const resetDeadEndField = () => action(RESET_DEAD_END_FIELD);

export const PRECONTRACTUAL_MAIL = createRequestTypes(SUBMIT_PRECONTRACTUAL_MAIL);
export const submitPrecontractualMailActions = {
  request: () => action(PRECONTRACTUAL_MAIL[REQUEST]),
  success: () => action(PRECONTRACTUAL_MAIL[SUCCESS]),
  failure: () => action(PRECONTRACTUAL_MAIL[FAILURE]),
};

export const setTopUpAmount = (payload: string) => action(SET_TOP_UP_AMOUNT, { payload });
export const setTopUpPaymentMethod = (payload: PaymentMethodType) =>
  action(SET_TOP_UP_PAYMENT_METHOD, { payload });

export const invalidateTopUpData = () => action(INVALIDATE_TOP_UP_DATA);
