import styled from 'styled-components';
import { devices } from 'common/styles';
import stocksKycIntroImg from 'assets/img/stocks-kyc-intro.svg';

export const Actions = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  @media ${devices.maxMobileL} {
    flex-direction: column-reverse;
    gap: 12px;

    & > div {
      margin: 0;
    }
  }
`;
export const Img = styled.img.attrs({ src: stocksKycIntroImg })`
  margin: 20px -32px 24px;
  width: calc(100% + 32px * 2);

  @media ${devices.maxMobileL} {
    margin: 20px -20px 18px;
    width: calc(100% + 20px * 2);
  }
`;
export const Title = styled.h2`
  margin-bottom: 24px;
  @media ${devices.maxMobileL} {
    margin-bottom: 12px;
  }
`;

export const Text = styled.div.attrs({ className: 'small-text' })`
  margin-bottom: 24px;
  p {
    font-size: 14px;
  }
`;
