/* eslint-disable @typescript-eslint/no-unsafe-return */

export const flatten = (x: any): any[] =>
  Array.isArray(x)
    ? x.reduce((arr, item) => {
        return [...arr, ...flatten(item)];
      }, [])
    : [x];

export const isNumeric = (data: string): boolean => {
  const pastedTextToArray = data.split('');
  return pastedTextToArray.every((char: string) => !Number.isNaN(Number(char)));
};

/**
 * Returns an array of arrays of n items each.
 */
export function partition<T>(n: number, items: T[]): Array<Array<T>> | null {
  if (items.length < n) {
    return null;
  }
  return [items.slice(0, n)].concat(partition(n, items.slice(n)) ?? []);
}

/**
 * Applies `pred` to each value in `items`, splitting it each time `pred` returns a new value.
 */
export function partitionBy<T>(pred: (item: T) => boolean, items: T[]): T[][] {
  return items.reduce((acc, input, idx) => {
    if (idx === 0) {
      acc.push([input]);
      return acc;
    }

    const val = pred(input);
    const prevVal = pred(items[idx - 1]);

    if (val === prevVal) {
      acc[acc.length - 1].push(input);
    } else {
      acc.push([input]);
    }
    return acc;
  }, [] as T[][]);
}

/**
 * Returns an array of elements from `items`, separated by return value from `generator`.
 */
export function interposeWith<T>(generator: (first: T, second: T) => T, items: T[]): T[] {
  return items.reduce((acc, input, idx) => {
    if (idx === items.length - 1) {
      acc.push(input);
    } else {
      acc.push(input, generator(input, items[idx + 1]));
    }
    return acc;
  }, [] as T[]);
}
