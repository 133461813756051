/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as ActionTypes from 'store/actions/money';
import { transactionHistoryYears } from 'common/const';
import { MoneyState } from 'store/types/money';
import { cryptoCodes } from 'common/utils/assets';

const initialState: MoneyState = {
  fetchingWithdrawInfo: false,
  submittingFiatWithdrawal: false,
  initializingCryptoWithdrawal: false,
  submittingCryptoWithdrawal: false,
  cryptoWithdrawOrder: undefined,
  cryptoWithdrawInitPayload: undefined,
  code2fa: undefined,
  fetchingDepositInfo: false,
  error: '',
  infoReport: {
    lastSent: {},
    years: [],
  },
  transactionHistory: {
    lastSent: {},
    years: [],
  },
  bankStatement: {
    availableMonths: [],
    lastFetched: 0,
  },
  depositAccount: null,
  sendingInfoReport: false,
  accounts: [],
  fetchingCryptoDepositInfo: false,
  cryptoAddresses: Object.keys(cryptoCodes).reduce((acc, curr) => {
    acc[curr] = {};
    return acc;
  }, {}),
  regeneratingCryptoAddress: false,
};

export default function money(state: MoneyState = initialState, action: any) {
  switch (action.type) {
    // WITHDRAW
    // Resets state on account change
    case ActionTypes.WITHDRAW_ORDER.REQUEST:
      return {
        ...state,
        submittingFiatWithdrawal: true,
      };
    case ActionTypes.WITHDRAW_ORDER.SUCCESS:
      return {
        ...state,
        submittingFiatWithdrawal: false,
      };
    case ActionTypes.WITHDRAW_ORDER.FAILURE:
      return {
        ...state,
        submittingFiatWithdrawal: false,
        error: action.error,
      };
    case ActionTypes.WITHDRAW_INFO.REQUEST:
      return {
        ...state,
        fetchingWithdrawInfo: true,
      };
    case ActionTypes.WITHDRAW_INFO.SUCCESS:
      return {
        ...state,
        fetchingWithdrawInfo: false,
        accounts: action.accounts,
      };
    case ActionTypes.WITHDRAW_INFO.FAILURE:
      return {
        ...state,
        fetchingWithdrawInfo: false,
        error: action.error,
      };

    // DEPOSIT
    case ActionTypes.DEPOSIT_INFO.REQUEST:
      return {
        ...state,
        fetchingDepositInfo: true,
      };
    case ActionTypes.DEPOSIT_INFO.SUCCESS:
      return {
        ...state,
        fetchingDepositInfo: false,
        depositAccount: action.depositAccount,
      };
    case ActionTypes.DEPOSIT_INFO.FAILURE:
      return {
        ...state,
        fetchingDepositInfo: false,
        error: action.error,
      };

    // CRYPTO DEPOSIT
    case ActionTypes.CRYPTO_DEPOSIT_INFO.REQUEST:
      return {
        ...state,
        fetchingCryptoDepositInfo: true,
      };
    case ActionTypes.CRYPTO_DEPOSIT_INFO.SUCCESS:
      return {
        ...state,
        fetchingCryptoDepositInfo: false,
        cryptoAddresses: {
          ...state.cryptoAddresses,
          [action.crypto]: {
            ...action.response,
            initialLoadComplete: true,
          },
        },
      };
    case ActionTypes.CRYPTO_DEPOSIT_INFO.FAILURE:
      return {
        ...state,
        fetchingCryptoDepositInfo: false,
        error: action.error,
      };

    // Regenerate address
    case ActionTypes.REGENERATE_ADDRESS.REQUEST:
      return {
        ...state,
        regeneratingCryptoAddress: true,
      };
    case ActionTypes.REGENERATE_ADDRESS.SUCCESS:
      return {
        ...state,
        regeneratingCryptoAddress: false,
        cryptoAddresses: {
          ...state.cryptoAddresses,
          [action.crypto]: action.response,
        },
      };
    case ActionTypes.REGENERATE_ADDRESS.FAILURE:
      return {
        ...state,
        regeneratingCryptoAddress: false,
        error: action.error,
      };

    case ActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        error: '',
      };
    case ActionTypes.INIT_CRYPTO_WITHDRAW:
      return {
        ...state,
        cryptoWithdrawInitPayload: {
          currency: action.currency,
          volume: action.volume,
          walletAddress: action.walletAddress,
          destinationTag: action.destinationTag,
          password: action.password,
        },
      };
    case ActionTypes.START_CRYPTO_WITHDRAW_SESSION:
      return {
        ...state,
        cryptoWithdrawInitPayload: undefined,
        cryptoWithdrawOrder: undefined,
      };
    case ActionTypes.INIT_CRPYO_WITHDRAW_ORDER.REQUEST:
      return {
        ...state,
        initializingCryptoWithdrawal: true,
      };
    case ActionTypes.INIT_CRPYO_WITHDRAW_ORDER.SUCCESS:
      return {
        ...state,
        initializingCryptoWithdrawal: false,
        cryptoWithdrawOrder: action.cryptoWithdrawOrder,
      };
    case ActionTypes.INIT_CRPYO_WITHDRAW_ORDER.FAILURE:
      return {
        ...state,
        initializingCryptoWithdrawal: false,
        error: action.error,
      };

    case ActionTypes.SUBMIT_CRYPTO_WITHDRAW:
      return {
        ...state,
        code2fa: action.code2fa,
      };

    case ActionTypes.SUBMIT_CRPYO_WITHDRAW_ORDER.REQUEST:
      return {
        ...state,
        submittingCryptoWithdrawal: true,
      };
    case ActionTypes.SUBMIT_CRPYO_WITHDRAW_ORDER.SUCCESS:
      return {
        ...state,
        submittingCryptoWithdrawal: false,
      };
    case ActionTypes.SUBMIT_CRPYO_WITHDRAW_ORDER.FAILURE:
      return {
        ...state,
        submittingCryptoWithdrawal: false,
        code2fa: undefined,
        error: action.error,
      };
    case ActionTypes.CLEANUP_CRYPTO_WITHDRAW_PROCCESS_DATA:
      return {
        ...state,
        cryptoWithdrawOrder: undefined,
        cryptoWithdrawInitPayload: undefined,
        code2fa: undefined,
      };
    // Info report
    case ActionTypes.SEND_INFO_REPORT.REQUEST:
      return {
        ...state,
        sendingInfoReport: true,
      };
    case ActionTypes.SEND_INFO_REPORT.SUCCESS:
      return {
        ...state,
        sendingInfoReport: false,
        infoReport: {
          ...state.infoReport,
          lastSent: {
            ...state.infoReport.lastSent,
            [action.year]: new Date().toUTCString(), // Current timestamp
          },
        },
      };
    case ActionTypes.SEND_INFO_REPORT.FAILURE:
      return {
        ...state,
        sendingInfoReport: false,
      };
    case ActionTypes.SEND_TRANSACTION_HISTORY_REQUEST.REQUEST:
      return {
        ...state,
        sendingTransactionHistoryReport: true,
      };
    case ActionTypes.SEND_TRANSACTION_HISTORY_REQUEST.SUCCESS:
      return {
        ...state,
        sendingTransactionHistoryReport: false,
        transactionHistory: {
          ...state.transactionHistory,
          lastSent: {
            ...state.transactionHistory.lastSent,
            [action.year || transactionHistoryYears.all]: new Date().toUTCString(), // Current timestamp
          },
        },
      };
    case ActionTypes.SEND_TRANSACTION_HISTORY_REQUEST.FAILURE:
      return {
        ...state,
        sendingTransactionHistoryReport: false,
      };

    case ActionTypes.SET_BANK_STATEMENT_AVAILABLE_MONTHS:
      return {
        ...state,
        bankStatement: {
          availableMonths: action.payload.availableMonths,
          lastFetched: new Date().getTime(),
        },
      };
    default:
      return state;
  }
}
