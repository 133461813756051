import { TimePeriod } from 'types/currency';
import { timePeriods } from 'common/const/timePeriods';
import { PortfolioChart, PortfolioChartItem } from 'types/portfolio';

export const initialState = timePeriods.reduce(
  (
    prev: Partial<{
      [timePeriod in TimePeriod]: {
        isFetching: boolean;
        items: PortfolioChartItem[];
        lastUpdatedAt: string | null;
      };
    }>,
    curr,
  ) => {
    if (prev) prev[curr.key] = { isFetching: false, items: [], lastUpdatedAt: null };
    return prev;
  },
  {},
) as PortfolioChart;
