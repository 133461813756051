import priceAlertsSlice from '.';

export const {
  // GET ALL (INTERVAL)
  subscribeToPriceAlerts,
  subscribeToPriceAlertsError,
  subscribeToPriceAlertsSuccess,
  unsubscribeFromPriceAlerts,
  // GET ALL ONCE
  fetchPriceAlerts,
  fetchPriceAlertsError,
  fetchPriceAlertsSuccess,
  // UPDATE
  updatePriceAlert,
  updatePriceAlertError,
  updatePriceAlertSuccess,
  // CREATE
  createPriceAlert,
  createPriceAlertError,
  createPriceAlertSuccess,
  // DELETE
  deletePriceAlert,
  deletePriceAlertError,
  deletePriceAlertSuccess,
} = priceAlertsSlice.actions;
