import styled from 'styled-components';
import { Theme } from 'types/theme';
import { ListItemBoxDiv } from 'common/components/LayoutContainer/LayourNarrow/ListItemBox/ListItemBox';
import { MainContainer } from 'common/components/LayoutContainer/LayourNarrow/styles';
import { devices } from 'common/styles';

export const Title = styled.div.attrs({ className: 'xlarge-text strong-weight' })`
  display: flex;
  justify-content: space-between;
  padding-top: 35px;

  @media ${devices.tablet} {
    padding-top: 60px;
  }
`;

export const MobileTitle = styled(Title)`
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.standard.fontSize};
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.standard.lineHeight};
  font-weight: ${({ theme }: { theme: Theme }) => theme.typography.fontWeight.regular};
`;

export const BottomText = styled.div.attrs({ className: 'small-text' })`
  text-align: center;
  letter-spacing: 0em;
  display: block;
  flex: 0 0 100%;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryLight};
  padding-top: 30px;
  @media ${devices.tablet} {
    text-align: left;
    padding-top: 27px;
  }
  @media ${devices.maxMobileL} {
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.xxsmall.fontSize} !important;
    line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.xxsmall.lineHeight} !important;
    padding-top: 10px;
  }
`;

export const ConfirmWrapper = styled.div`
  & ${MainContainer} {
    box-shadow: none;
  }
`;

export const CustomListItemBox = styled(ListItemBoxDiv)`
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const ConfirmFooterWrapper = styled.div`
  width: 100%;
`;

export const ConfirmationHeader = styled.h3.attrs({ className: 'strong-weight' })`
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
  & > div {
    margin: 0;
  }
  @media ${devices.tablet} {
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;

    & > div:first-child {
      flex: 1;
    }

    & > div:last-child {
      flex: 2;
    }
  }
`;
