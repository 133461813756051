// @flow
import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import { colors } from 'common/utils/theme/colors';

export const ContentColumn = styled.div`
  width: 72%;
`;

export const FullWidthColumn = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${colors.white};
  padding: ${dimensions.mediumPadding}px;

  @media ${devices.maxTablet} {
    padding-top: 0;
  }
`;

export const CenteredColumn = styled.div`
  max-width: ${dimensions.maxCenteredContentWidth}px;
  margin: 0 auto;

  @media ${devices.maxTablet} {
    padding: 0 ${dimensions.mediumPadding}px;
  }
`;
