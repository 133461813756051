import { connect } from 'react-redux';
import { State } from 'store/types/store';
import {
  getWinnersLosersItemByOrderNumber,
  getWinnersLosersType,
} from 'store/slices/experiments/winnersLosers/selectors';
import { AnyAction, bindActionCreators } from 'redux';
import { setMarketOverviewOrderBy } from 'store/slices/filters/actions';
import { selectTimePeriod } from 'store/actions/currency';
import { setWinnerLoserExperimentModalVisited } from 'store/actions/settings';
import { Dispatch } from 'react';
import WinnerLoserModalView from './view';

const mapStateToProps = (state: State) => ({
  first: getWinnersLosersItemByOrderNumber(state, 1),
  second: getWinnersLosersItemByOrderNumber(state, 2),
  third: getWinnersLosersItemByOrderNumber(state, 3),
  type: getWinnersLosersType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  setMarketOverviewOrderBy: bindActionCreators(setMarketOverviewOrderBy, dispatch),
  setMarketOverviewTimePeriod: bindActionCreators(selectTimePeriod, dispatch),
  setWinnerLoserModalShown: bindActionCreators(setWinnerLoserExperimentModalVisited, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WinnerLoserModalView);
