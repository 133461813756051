/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { map, distinctUntilChanged } from 'rxjs/operators';
import { parseTrackingConfiguration } from 'common/tracking/rules';

import { setTrackingConfiguration } from '../actions/tracking';
import { getTrackingConfigurationJson } from '../selectors/trackingConfig';

const updateTrackingConfiguration = (action$, state$) =>
  state$.pipe(
    map(getTrackingConfigurationJson),
    distinctUntilChanged(),
    map(parseTrackingConfiguration),
    map(setTrackingConfiguration),
  );

export default updateTrackingConfiguration;
