import { initialState } from './initialState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NEWS_SLICE_NAME } from 'common/const/slices';
import { newsAdapter } from 'store/slices/news/adapter';
import { NewsItem } from 'types/news';

const newsSlice = createSlice({
  name: NEWS_SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchNewsFeedRequest(state, action: PayloadAction<{ crypto: string }>) {
      state.isFetching = true;
    },
    fetchNewsFeedSuccess(state, action: PayloadAction<{ newsFeed: NewsItem[] }>) {
      newsAdapter.setAll(state, action.payload.newsFeed);
      state.isFetching = false;
    },
    fetchNewsFeedFailure(state) {
      state.isFetching = false;
    },
    changeNewsLang(state, action: PayloadAction<{ lang: string }>) {
      state.lang = action.payload.lang;
    },

    loadNewsFeedIfNeeded() {},
    invalidateNewsFeed(state) {
      state.isFetching = false;
    },
    subscribeToNewsFeed() {},
    unsubscribeFromNewsFeed(state) {
      newsAdapter.removeAll(state);
    },
  },
});

export default newsSlice;
