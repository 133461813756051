// @flow
import { connect } from 'react-redux';
import { getIsKycDone } from 'store/selectors/auth';
import * as authActions from 'store/actions/auth';

import DisabledFeatures from './view';
import { State } from 'store/types/store';

const mapStateToProps = (state: State) => ({
  isKycDone: getIsKycDone(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  changeAccountType: (newAccountType: any): void => {
    dispatch(authActions.changeAccount.request(newAccountType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DisabledFeatures);
