import styled from 'styled-components';
import { Theme } from 'types/theme';
import { colors } from 'common/utils/theme/colors';
import Icon from 'common/components/Icon';

export const StakingDataRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const Label = styled.div.attrs({
  className: 'small-text',
})`
  display: flex;
  align-items: center;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const LabelBold = styled(Label).attrs({
  className: 'strong-weight',
})``;

export const AprValue = styled(LabelBold)`
  color: ${colors.newSuccess};
`;

export const StakingType = styled(LabelBold)`
  text-transform: uppercase;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.inactive};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 2px 0 14px;

  background-color: ${({ theme }: { theme: Theme }) => theme.color.border.table};
`;

export const InfoIcon = styled(Icon).attrs({
  sizeValue: 'micro',
})`
  cursor: pointer;
  padding-left: 6px;
`;
