import * as Yup from 'yup';
import I18n from 'i18next';
import { CryptoDetails } from 'types/portfolio';
import { crypto, numberFromDecimalString } from 'common/utils/formatting';

export const getValidationSchema = (
  assetCode: string,
  maxAmount: number,
  cryptoDetails: CryptoDetails | undefined,
  minStakeAmount: number,
) =>
  Yup.object().shape({
    amount: Yup.string()
      .required(I18n.t('staking.errors.emptyAmount'))
      .test(
        'too-large',
        I18n.t('staking.errors.tooLarge', {
          maxAmount: crypto(maxAmount, cryptoDetails?.decimalPlaces, { strip_insignificant_zeros: true }),
          code: assetCode.toUpperCase(),
        }),
        (value: any) => numberFromDecimalString(value) <= maxAmount,
      )
      .test(
        'not-enough-left-in-staking',
        I18n.t('unstaking.errors.notEnoughLeftInStake', {
          minAmount: crypto(minStakeAmount, cryptoDetails?.decimalPlaces, {
            strip_insignificant_zeros: true,
          }),
          code: assetCode.toUpperCase(),
          maxAmount: crypto(maxAmount, cryptoDetails?.decimalPlaces, { strip_insignificant_zeros: true }),
        }),
        (value: any) =>
          numberFromDecimalString(value) <= maxAmount - minStakeAmount ||
          numberFromDecimalString(value) === maxAmount,
      ),
  });
