/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StakingProps } from 'types/staking';
import { Loader } from 'common/lib/semantic-ui';
import useStakingFlow from 'common/hooks/useStakingFlow';
import { stakeOrUnstakeOrderTypes } from 'common/const';
import { devices, dimensions } from 'common/styles';
import IconButton from 'common/components/IconButton/IconButton';
import Icon from 'common/components/Icon';
import LayoutNarrow from 'common/components/LayoutContainer/LayourNarrow/LayoutNarrow';
import StakingAmountForm from 'common/components/StakingAmountForm';
import { AprValue, InfoIcon, Label, StakingDataRow, LabelBold } from 'common/components/StakingData/styles';
import StakingPeriodModal from 'common/components/Modal/Staking/StakingPeriodModal';
import { useModalProvider } from 'providers/Modal/useModalProvider';
import { DEFAULT_MODAL_PROPS } from 'common/const/modals';
import { UNSTAKING_PERIOD_DAYS } from 'common/const/staking';
import { HeaderText, LoaderWrapper } from '../AssetStaking/styles';
import { getValidationSchema } from './helpers';

type Props = {
  assetCode: string;
} & StakingProps;

const AssetUnstaking: FunctionComponent<Props> = ({
  navigate,
  setPageTitle,
  assetCode,
  availableCryptos,
}) => {
  const { t } = useTranslation();
  const modalProvider = useModalProvider();

  const { asset, amount, setAmount, parsedStakingInfo, onProceed, isStakingLoading } = useStakingFlow({
    assetCode,
    type: stakeOrUnstakeOrderTypes.Unstake,
    setPageTitleCallback: setPageTitle,
  });

  const selectedCrypto = useMemo(
    () => availableCryptos.find(({ entity }) => entity.toLowerCase() === assetCode?.toLowerCase()),
    [availableCryptos, assetCode],
  );

  if (!isStakingLoading && !parsedStakingInfo) {
    return (
      <LoaderWrapper $active>
        <Loader active />
      </LoaderWrapper>
    );
  }

  return (
    <LayoutNarrow
      maxWidth={502}
      mainContainerCustomStyles={`@media ${devices.tablet} {padding: ${dimensions.modalPadding}px;}`}
      headerElement={
        <HeaderText>
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="icon-arrow" sizeValue="regular" />
          </IconButton>
          <div>{t('unstaking.title')}</div>
        </HeaderText>
      }
    >
      <StakingAmountForm
        amount={amount}
        assetCode={assetCode}
        validationSchema={() =>
          getValidationSchema(
            assetCode,
            selectedCrypto?.stakeVolume ?? 0,
            asset?.cryptoDetails,
            parsedStakingInfo?.minStakeAmount,
          )
        }
        updateAmount={setAmount}
        onProceed={onProceed}
        maxAmount={selectedCrypto?.stakeVolume ?? 0}
        asset={asset}
      >
        <Fragment>
          <StakingDataRow>
            <Label>{t('staking.apr')}</Label>
            <AprValue>{parsedStakingInfo?.aprInfo}</AprValue>
          </StakingDataRow>
          <StakingDataRow>
            <Label>
              {t('unstaking.period')}
              <InfoIcon
                name="icon-outlined-info"
                onClick={() => {
                  modalProvider.addModal({
                    component: <StakingPeriodModal entity={assetCode} {...DEFAULT_MODAL_PROPS} />,
                  });
                }}
              />
            </Label>
            <LabelBold>
              {t('unstaking.periodValue', {
                start: UNSTAKING_PERIOD_DAYS.START,
                end: UNSTAKING_PERIOD_DAYS.END,
              })}
            </LabelBold>
          </StakingDataRow>
        </Fragment>
      </StakingAmountForm>
      <LoaderWrapper $active={isStakingLoading}>
        <Loader active={isStakingLoading} />
      </LoaderWrapper>
    </LayoutNarrow>
  );
};

export default AssetUnstaking;
