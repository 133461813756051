import { State } from 'store/types/store';
import { priceAlertsAdapter } from './adapter';
import { PriceAlertEntity, PriceAlertListItem } from 'types/alerts';
import { createSelector } from 'reselect';
import { getPriceAlertListItem } from 'common/utils/priceAlerts';
import { getCryptoEntities } from '../assets/selectors';
import { Crypto } from 'types/assets';
import { PRICE_ALERTS_FETCH_DELAY_IN_MS } from 'common/const/priceAlerts';
import { PRICE_ALERTS_SLICE_NAME } from 'common/const/slices';

const priceAlertsState = (state: State) => state[PRICE_ALERTS_SLICE_NAME];

const priceAlertsSelectors = priceAlertsAdapter.getSelectors(priceAlertsState);

/**
 * Returns price alert by id or undefined if not found.
 *
 * @summary Price alert by id.
 *
 * @public
 * @param {State} state
 * @param {EntityId} id
 * @returns {PriceAlertEntity | undefined}
 */
export const getPriceAlertById = priceAlertsSelectors.selectById;

/**
 * Returns price alert by entity or undefined if not found.
 *
 * @summary Price alert by entity.
 *
 * @public
 * @param {State} state
 * @param {EntityId} id
 * @returns {PriceAlertEntity | undefined}
 */
export const getPriceAlertByEntity = (state: State, entity: string) =>
  getAllPriceAlerts(state).find(
    (priceAlert: PriceAlertEntity) => priceAlert.entity.toLowerCase() === entity.toLowerCase(),
  );

/**
 * Returns all price alerts that are stored in this state adapter.
 *
 * @summary All price alerts.
 *
 * @public
 * @param {State} state
 * @returns {PriceAlertEntity[]}
 */
export const getAllPriceAlerts = priceAlertsSelectors.selectAll;

/**
 * Returns all price alert list items that are displayed on the price alert page for passed in asset code.
 *
 * @summary Price alert models.
 *
 * @public
 * @param {State} state
 * @returns {PriceAlertListItem[]}
 */
export const getAllPriceAlertListItemsForAssetCode = createSelector(
  [getAllPriceAlerts, getCryptoEntities, (state: State, props: string) => props],
  (priceAlerts: PriceAlertEntity[], cryptos: Crypto[], assetCode: string) =>
    priceAlerts
      .filter((item) => item.entity.toLowerCase() === assetCode.toLowerCase())
      .map<PriceAlertListItem>((item) => getPriceAlertListItem(item, cryptos)),
);

/**
 * Returns all price alerts that are stored in this state adapter.
 *
 * @summary All price alerts.
 *
 * @public
 * @param {State} state
 * @returns {PriceAlertEntity[]}
 */
export const arePriceAlertsFetching = (state: State) => priceAlertsState(state).isPriceAlertFetchInProgress;

/**
 * Calculates if single price alert fetch can be executed.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const shouldPriceAlertFetch = (state: State) => {
  const lastFetched = priceAlertsState(state).lastFetched;

  if (!lastFetched) return true; // Fetch if date is not yet set.

  const now = new Date().getTime();

  return lastFetched + PRICE_ALERTS_FETCH_DELAY_IN_MS < now;
};

/**
 * Returns true if price alerts includes some price alert with passed in entity
 *
 * @summary Any price alert (entity) exists.
 *
 * @public
 * @param {State} state
 * @param {string} entity
 * @returns {boolean}
 */
export const anyPriceAlertWithEntity = (state: State, entity: string) => {
  if (!entity) return false;

  return getAllPriceAlerts(state).some(
    (priceAlert: PriceAlertEntity) => priceAlert.entity.toLowerCase() === entity.toLowerCase(),
  );
};
