// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../lib/semantic-ui';

import { SwitchContainer, SwitchText } from './styles';

type Props = {
  value: boolean;
  onClick: (targetLabel: string) => void;
  inProgress?: boolean;
  onText?: string;
  offText?: string;
  disabled?: boolean;
  className?: string;
};

const Switch = ({ value, inProgress, onClick, onText, offText, disabled, className }: Props) => {
  const { t } = useTranslation();
  const displayOnText = onText === null ? t('common.on') : onText;
  const displayOffText = offText === null ? t('common.off') : offText;
  const displayText = value ? displayOnText : displayOffText;
  const handleOnClick = () => onClick(displayText);
  return (
    <SwitchContainer
      onClick={handleOnClick}
      disabled={disabled}
      active={value}
      className={`banner-switch ${className}`}
    >
      {inProgress ? (
        <Loader active inline size="tiny" />
      ) : (
        <SwitchText disabled={disabled} active={value}>
          {displayText}
        </SwitchText>
      )}
    </SwitchContainer>
  );
};

Switch.defaultProps = {
  inProgress: false,
  disabled: false,
  onText: null,
  offText: null,
  className: '',
};

export default Switch;
