import { OrderType } from 'common/const';
import { Quote } from 'types/orders';
import { ORDER_STATUS_TYPES, QuoteData } from 'types/securityOrder';

// init order

export const INIT_SECURITY_ORDER_REQUEST = 'INIT_SECURITY_ORDER_REQUEST';
export const INIT_SECURITY_ORDER_SUCCESS = 'INIT_SECURITY_ORDER_SUCCESS';
export const INIT_SECURITY_ORDER_ERROR = 'INIT_SECURITY_ORDER_ERROR';

export const initSecurityOrderRequest = ({ Type, Entity }: { Type: OrderType; Entity: string }) => ({
  type: INIT_SECURITY_ORDER_REQUEST,
  payload: { Type, Entity },
});

export const initSecurityOrderSuccess = (payload) => ({
  type: INIT_SECURITY_ORDER_SUCCESS,
  payload,
});

export const initSecurityOrderError = () => ({
  type: INIT_SECURITY_ORDER_ERROR,
});

// quote request

export const STOCK_ETF_PRICE_QUOTE_REQUEST = 'STOCK_ETF_PRICE_QUOTE_REQUEST';
export const STOCK_ETF_PRICE_QUOTE_SUCCESS = 'STOCK_ETF_PRICE_QUOTE_SUCCESS';
export const STOCK_ETF_PRICE_QUOTE_ERROR = 'STOCK_ETF_PRICE_QUOTE_ERROR';

export const stockEtfPriceQuoteRequest = ({
  Volume,
  Type,
  Currency,
  Entity,
}: {
  Volume: number;
  Type: 'Buy' | 'Sell';
  Currency: string;
  Entity: string;
}) => ({
  type: STOCK_ETF_PRICE_QUOTE_REQUEST,
  payload: {
    Volume,
    Type,
    Currency,
    Entity,
  },
});

export const stockEtfPriceQuoteSuccess = (payload: Quote) => ({
  type: STOCK_ETF_PRICE_QUOTE_SUCCESS,
  payload,
});

export const stockEtfPriceQuoteError = () => ({
  type: STOCK_ETF_PRICE_QUOTE_ERROR,
});

// reserve quote

export const RESERVE_SECURITY_PRICE_QUOTE_REQUEST = 'RESERVE_STOCK_ETF_PRICE_QUOTE_REQUEST';
export const RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS = 'RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS';
export const RESERVE_STOCK_ETF_PRICE_QUOTE_ERROR = 'RESERVE_STOCK_ETF_PRICE_QUOTE_ERROR';

export const reserveSecurityPriceQuoteRequest = () => ({
  type: RESERVE_SECURITY_PRICE_QUOTE_REQUEST,
});

export const reserveStockEtfPriceQuoteSuccess = (payload: QuoteData) => ({
  type: RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS,
  payload,
});

export const reserveStockEtfPriceQuoteError = () => ({
  type: RESERVE_STOCK_ETF_PRICE_QUOTE_ERROR,
});

// accept quote

export const ACCEPT_SECURITY_PRICE_QUOTE_REQUEST = 'ACCEPT_SECURITY_PRICE_QUOTE_REQUEST';
export const ACCEPT_SECURITY_PRICE_QUOTE_SUCCESS = 'ACCEPT_SECURITY_PRICE_QUOTE_SUCCESS';
export const ACCEPT_SECURITY_PRICE_QUOTE_ERROR = 'ACCEPT_SECURITY_PRICE_QUOTE_ERROR';

export const acceptSecurityPriceQuoteRequest = () => ({
  type: ACCEPT_SECURITY_PRICE_QUOTE_REQUEST,
});

export const acceptSecurityPriceQuoteSuccess = (payload: Quote) => ({
  type: ACCEPT_SECURITY_PRICE_QUOTE_SUCCESS,
  payload,
});

export const acceptSecurityPriceQuoteError = () => ({
  type: ACCEPT_SECURITY_PRICE_QUOTE_ERROR,
});

// changing of input

export const STOCK_ETF_ORDER_USER_INPUT_CHANGED = 'STOCK_ETF_ORDER_USER_INPUT_CHANGED';

export const stockEtfOrderUserInputChanged = () => ({
  type: STOCK_ETF_ORDER_USER_INPUT_CHANGED,
});

export const RESTART_PRICE_QUOTING = 'RESTART_PRICE_QUOTING';

export const restartPriceQuoting = ({
  Volume,
  Type,
  Currency,
  Entity,
}: {
  Volume: number;
  Type: 'Buy' | 'Sell';
  Entity: string;
}) => ({
  type: RESTART_PRICE_QUOTING,
  payload: {
    Volume,
    Type,
    Entity,
    StockCustomerId: 0,
  },
});

export const MANUAL_ORDER_STATUS_CHANGE = 'MANUAL_ORDER_STATUS_CHANGE';

export const manualOrderStatusChange = (status: ORDER_STATUS_TYPES) => ({
  type: MANUAL_ORDER_STATUS_CHANGE,
  payload: { status },
});

export const RESET_ORDER = 'RESET_ORDER';

export const resetOrder = () => ({
  type: RESET_ORDER,
});

export const CLEAR_RESERVED_ORDER = 'CLEAR_RESERVED_ORDER';

export const clearReservedOrder = () => ({
  type: CLEAR_RESERVED_ORDER,
});

export const UPDATE_QUOTE_VALID_FOR = 'UPDATE_QUOTE_VALID_FOR';

export const updateQuoteValidFor = (time: number | undefined) => ({
  type: UPDATE_QUOTE_VALID_FOR,
  payload: { time },
});
