import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';
import { ModalDimensionsType } from 'common/const/modals';
import {
  ExitIntentHeader,
  ExitIntentText,
  ModalFooter,
} from 'common/components/Modal/TermsAndConditions/TermsAndConditionsModal/styles';

type Props = {
  onBackToPopUp: () => void;
  onClose: () => void;
  isOpened: boolean;
  customText?: { title?: string; text?: string; primaryButton?: string; secondaryButton?: string };
  dimensions?: ModalDimensionsType;
};

const TncExitIntent = ({ onBackToPopUp, onClose, isOpened, customText, dimensions }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      modalDimensions={dimensions}
      defaultOpen={isOpened}
      onCancel={onClose}
      header={
        <ExitIntentHeader>
          {customText?.title ?? t('updatedTermsAndConditions.exitIntent.title')}
        </ExitIntentHeader>
      }
      actions={
        <ModalFooter>
          <Button
            title={customText?.primaryButton ?? t('updatedTermsAndConditions.exitIntent.primaryAction')}
            onClick={onBackToPopUp}
            size="modal"
            fontSize="standard"
            weight="medium"
            isFullWidth
          />
          <Button
            title={customText?.secondaryButton ?? t('updatedTermsAndConditions.exitIntent.secondaryAction')}
            onClick={onClose}
            size="modal"
            fontSize="standard"
            weight="medium"
            isFullWidth
            inverted
          />
        </ModalFooter>
      }
    >
      <ExitIntentText>{customText?.text ?? t('updatedTermsAndConditions.exitIntent.body')}</ExitIntentText>
    </ModalTemplate>
  );
};

export default memo(TncExitIntent);
