/* eslint-disable @typescript-eslint/naming-convention */

import { ValuesType } from 'utility-types';

export const ORDER_STATUS = {
  FORM: 'form',
  CONFIRM_PRICE: 'confirmPrice',
  QUOTE_LOADING: 'quoteLoading',
  MANUAL_RFQ: 'manualRFQ',
  QUOTE_ERROR: 'quoteError',
  BUY_SELL_SUCCESS: 'buySellSuccess',
  BUY_SELL_ERROR: 'buySellError',
  BUY_SELL_PENDING: 'buySellPending',
} as const;

export const ORDER_MANUAL_RFQ_MIN_IN_SECONDS = 2;
export const ORDER_MANUAL_RFQ_MAX_IN_SECONDS = 30;

export const STOCK_ORDER_MAX_AMOUNT_BUY = 100000;

export const STOCK_ORDER_LATENCY_TIMEOUT = 1;

export const ASSET_TRADING_RETAIL_ERRORS_CODES = ['EVA-T2BX', 'EVA-T2AX'];
