import React from 'react';
import { FormikProps } from 'formik';
import ChangeMobileNumber from 'scenes/Settings/PersonalInformation/ChangeMobileNumber';
import { RootState } from 'types/common';
import { getUser } from 'store/selectors/auth';
import { Action, bindActionCreators, Dispatch } from 'redux';
import * as formActions from 'store/actions/forms';
import { connect } from 'react-redux';
import { User } from 'types/state';

type Props = {
  open: boolean;
  onCancel: () => void;
  startDeleteMobile: typeof formActions.startDeleteMobile;
  startAssignMobile: typeof formActions.startAssignMobile;
  confirmDeleteMobile: typeof formActions.confirmDeleteMobile;
  confirmAssignMobile: typeof formActions.confirmAssignMobile;
  mobileChangeCancel: typeof formActions.mobileChangeCancel;
  confirmAssignCallback?: () => void;
  user: User;
};

const PhoneEditModal = ({
  open,
  formikRef,
  onCancel,
  mobileChangeCancel,
  confirmDeleteMobile,
  confirmAssignMobile,
  startDeleteMobile,
  startAssignMobile,
  confirmAssignCallback,
  user,
}: Props): JSX.Element => {
  return (
    <ChangeMobileNumber
      open={open}
      onClose={onCancel}
      startDeleteMobile={startDeleteMobile}
      startAssignMobile={startAssignMobile}
      confirmDeleteMobile={confirmDeleteMobile}
      confirmAssignMobile={confirmAssignMobile}
      mobileChangeCancel={mobileChangeCancel}
      confirmAssignCallback={confirmAssignCallback}
      user={user}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: getUser(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  ...bindActionCreators(
    {
      startDeleteMobile: formActions.startDeleteMobile,
      startAssignMobile: formActions.startAssignMobile,
      confirmDeleteMobile: formActions.confirmDeleteMobile,
      confirmAssignMobile: formActions.confirmAssignMobile,
      mobileChangeCancel: formActions.mobileChangeCancel,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneEditModal);
