import styled from 'styled-components';
import { devices } from 'common/styles';
import { Theme } from 'types/theme';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  flex-direction: column;
  & > * {
    padding: 0;
    margin: 0;
  }

  @media${devices.tablet} {
    flex-direction: row;
    gap: 24px;

    & > * {
      flex: 1;
    }
  }
`;

export const GeometryBox = styled.div`
  padding: 24px 24px 100px 0;
  @media${devices.tablet} {
    padding: 32px 0 120px 0;
  }
`;

export const Text = styled.div.attrs({ className: 'small-text' })`
  &:first-of-type {
    padding-bottom: 16px;
  }
`;

export const Link = styled.a`
  text-decoration: none !important;
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
  &:hover {
    text-decoration: underline !important;
  }
`;
