import { useMedia } from 'common/hooks/useMedia';
import React, { ReactElement } from 'react';
import { HeaderElementContainer, HeaderTextDiv, LayoutNarrowDiv, MainContainer } from './styles';

type Props = {
  headerText?: string;
  children: React.ReactNode;
  headerElement?: React.ReactNode;
  isBottomMarginRemoved?: boolean;
  isTopMarginRemoved?: boolean;
  mainContainerCustomStyles?: string;
  withHeaderOnMobile?: boolean;
  maxWidth?: number;
};

const LayoutNarrow = ({
  headerText,
  children,
  headerElement,
  isBottomMarginRemoved = false,
  isTopMarginRemoved = false,
  mainContainerCustomStyles,
  withHeaderOnMobile = false,
  maxWidth = 562,
}: Props): ReactElement => {
  const isMobile: boolean = useMedia(['(max-width: 768px)', '(min-width: 768px)'], [true, false], false);
  return (
    <LayoutNarrowDiv
      isBottomMarginRemoved={isBottomMarginRemoved}
      isTopMarginRemoved={isTopMarginRemoved}
      maxWidth={maxWidth}
    >
      {!isMobile && headerText && <HeaderTextDiv>{headerText}</HeaderTextDiv>}
      {!isMobile && headerElement && <HeaderElementContainer>{headerElement}</HeaderElementContainer>}
      <MainContainer mainContainerCustomStyles={mainContainerCustomStyles}>
        {isMobile && withHeaderOnMobile && headerElement && (
          <HeaderElementContainer>{headerElement}</HeaderElementContainer>
        )}
        {children}
      </MainContainer>
    </LayoutNarrowDiv>
  );
};

LayoutNarrow.defaultProps = {
  headerText: undefined,
  headerElement: undefined,
  isBottomMarginRemoved: false,
  isTopMarginRemoved: false,
  mainContainerCustomStyles: undefined,
  withHeaderOnMobile: false,
  maxWidth: 562,
};

export default LayoutNarrow;
