import React, { FunctionComponent, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'common/components/Button';
import { LabelBold } from 'common/components/StakingData/styles';
import CryptoInfoWithIconModal from 'common/components/Modal/CryptoInfoWithIconModal';
import { requiredDocumentsForStakingConsent } from 'common/const/consent';
import { UNSTAKING_PERIOD_DAYS } from 'common/const/staking';
import { ModalProps } from 'types/modals';
import { Divider, Label, Title } from '../UnstakingPeriodModal/styles';
import {
  BodyWrapper,
  ButtonWrapper,
  Link,
  NoteText,
  SummaryRow,
  SummaryText,
} from '../Staking/StakingConfirmModal/styles';

type Props = {
  onConfirm: () => void;
  entity: string;
  displayName: string;
  amount: string;
} & ModalProps;

const UnstakingConfirmModal: FunctionComponent<Props> = ({
  onConfirm,
  amount,
  entity,
  displayName,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <CryptoInfoWithIconModal
      hideShortnameInTitle
      open
      headerSubTitle={<Title>{t('unstaking.confirmModal.title')}</Title>}
      displayName={displayName}
      currency={entity}
      onClose={onClose}
      actions={
        <ButtonWrapper>
          <Button
            data-test-id="unstaking.confirmModal.confirm"
            title={t('unstaking.confirmModal.confirm')}
            size="large"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          />
        </ButtonWrapper>
      }
    >
      <BodyWrapper>
        <SummaryRow>
          <Label>{t('unstaking.period')}</Label>
          <LabelBold>
            {t('unstaking.periodValue', {
              start: UNSTAKING_PERIOD_DAYS.START,
              end: UNSTAKING_PERIOD_DAYS.END,
            })}
          </LabelBold>
        </SummaryRow>
        <Divider />
        <SummaryRow>
          <SummaryText>{t('unstaking.unstake')}</SummaryText>
          <SummaryText>{`${amount} ${entity.toUpperCase()}`}</SummaryText>
        </SummaryRow>
        <NoteText>
          <Trans
            i18nKey="unstaking.confirmModal.note"
            components={{
              1: <Link href={requiredDocumentsForStakingConsent.specialConditions.link} target="_blank" />,
            }}
          />
        </NoteText>
      </BodyWrapper>
    </CryptoInfoWithIconModal>
  );
};

export default memo(UnstakingConfirmModal);
