import styled from 'styled-components';
import textStyles from 'common/styles/text';
import { Theme } from 'types/theme';

export const SummaryTextDiv = styled.div`
  padding: 11px 12px;
  width: 100%;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.formAccentInvertedOpacity};
  border-radius: 4px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const SummaryText = styled.span`
  ${textStyles.blueBoxNormal}
`;
