// slices/newsletterSubscription/index.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NEWSLETTER_SLICE_NAME } from 'common/const/slices';
import { NewsletterGetResponse, NewsletterRequestDto } from 'store/types/newsletter';
import NEWSLETTER_SUBSCRIPTION_TYPES from 'common/const/newsletter';
import { initialState } from './initialState';

const newsletterSubscriptionSlice = createSlice({
  name: NEWSLETTER_SLICE_NAME,
  initialState,
  reducers: {
    // Fetching newsletter subscription status
    fetchNewsletterRequest(state) {
      state.refreshing = true;
    },
    fetchNewsletterSuccess(state, action: PayloadAction<NewsletterGetResponse>) {
      state.refreshing = false;
      state.newsletter = action.payload?.newsletter || false;
      state.product = action.payload?.product || false;
    },
    fetchNewsletterFailure(state) {
      state.refreshing = false;
    },

    // Setting newsletter subscription status
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setNewsletterRequest(state, action: PayloadAction<NewsletterRequestDto>) {
      state.refreshing = true;
    },
    setNewsletterSuccess(state, action: PayloadAction<NewsletterRequestDto>) {
      const { subscriptionType, isSubscribed } = action.payload;
      state.refreshing = false;
      if (subscriptionType === NEWSLETTER_SUBSCRIPTION_TYPES.Newsletter) {
        state.newsletter = isSubscribed;
      } else if (subscriptionType === NEWSLETTER_SUBSCRIPTION_TYPES.Product) {
        state.product = isSubscribed;
      }
    },
    setNewsletterFailure(state) {
      state.refreshing = false;
    },
  },
});

export const {
  fetchNewsletterRequest,
  fetchNewsletterSuccess,
  fetchNewsletterFailure,
  setNewsletterRequest,
  setNewsletterSuccess,
  setNewsletterFailure,
} = newsletterSubscriptionSlice.actions;

export default newsletterSubscriptionSlice;
