/* eslint-disable @typescript-eslint/no-unsafe-return */
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, filter, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { isPresent } from 'safetypings';
import { getAccessToken } from 'store/selectors/auth';
import {
  confirmTwoFaAuditProofRequest,
  confirmTwoFaAuditProofSuccess,
  submitAuditProofError,
  submitAuditProofRequest,
  submitAuditProofSuccess,
} from 'store/actions/auditProof';
import { RootAction, RootEpic } from 'types/common';
import { combineEpics } from 'redux-observable';
import { getDeviceData } from 'common/utils/seonSdk';
import { AuditProofData } from 'types/auditProof';
import { errorCodes } from 'common/apiErrors';
import { AjaxError } from 'rxjs/ajax';

const submitAuditProof: RootEpic = (action$, state$, { ajax, api, modalQueue }) =>
  action$.pipe(
    filter(submitAuditProofRequest.match),
    map(({ payload }) => ({ payload, modalQueueInstance: modalQueue() })),
    switchMap(
      ({
        payload: { employmentStatus, professionalField, birthDate, onError, onSuccess },
        modalQueueInstance,
      }) =>
        from(getDeviceData()).pipe(
          switchMap(({ deviceData }) =>
            state$.pipe(
              map(getAccessToken),
              filter(isPresent),
              take(1),
              switchMap(
                (accessToken) =>
                  ajax({
                    method: 'POST',
                    url: api.getBaseUrlForUserServices(accessToken)?.concat(`/user/kyc/aml`),
                    withCredentials: true,
                    headers: {
                      ...api.getCommonHeaders(),
                      Authorization: `Bearer ${accessToken}`,
                      'Content-Type': 'application/json',
                    },
                    body: {
                      amlConfirmed: true,
                      employmentStatus,
                      professionalField,
                      birthDate,
                      deviceData,
                    },
                  }).pipe(
                    pluck('response'),
                    map((response: AuditProofData) => {
                      if (onSuccess) onSuccess(response);
                      return submitAuditProofSuccess(response);
                    }),
                  ) as Observable<RootAction>,
              ),
              catchError((error: AjaxError) => {
                if (onError) onError(error);
                if (error.status === 503) modalQueueInstance?.unblockModalQueue();
                return of(submitAuditProofError({ error }));
              }),
            ),
          ),
        ),
    ),
  );

const confirmTwoFaAuditProof: RootEpic = (action$, state$, { ajax, api, modalQueue }) =>
  action$.pipe(
    filter(confirmTwoFaAuditProofRequest.match),
    map(({ payload }) => ({ payload, modalQueueInstance: modalQueue() })),
    switchMap(({ payload: { data, code2fa, onError, onSuccess, onWrongTwoFa }, modalQueueInstance }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map(getAccessToken),
            filter((accessToken) => isPresent(accessToken)),
            take(1),
            switchMap(
              (accessToken) =>
                ajax({
                  method: 'PATCH',
                  url: api.getBaseUrlForUserServices(accessToken)?.concat(`/user/kyc/aml`),
                  withCredentials: true,
                  headers: {
                    ...api.getCommonHeaders(),
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                  },
                  body: {
                    data,
                    code2fa,
                    deviceData,
                  },
                }).pipe(
                  pluck('response'),
                  map((response) => {
                    if (onSuccess) onSuccess();

                    return confirmTwoFaAuditProofSuccess();
                  }),
                  tap(() => modalQueueInstance?.unblockModalQueue()),
                ) as Observable<RootAction>,
            ),
          ),
        ),
        catchError((error: AjaxError) => {
          if (error?.response?.code === errorCodes.CHANGEPROCESS_ERR_WRONG2FACODE) {
            onWrongTwoFa();
            return EMPTY;
          }

          if (onError) onError(error);

          if (error.status === 503) modalQueueInstance?.unblockModalQueue();

          return of(submitAuditProofError({ error }));
        }),
      ),
    ),
  );

export default combineEpics(submitAuditProof, confirmTwoFaAuditProof);
