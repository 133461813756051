import { devices } from 'common/styles';
import styled from 'styled-components';
import { Theme } from 'types/theme';

export const Title = styled.div`
  text-align: center;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['3'].fontSize};
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['3'].lineHeight};
  font-weight: ${({ theme }: { theme: Theme }) => theme.typography.fontWeight.strong};
  padding-bottom: 24px;

  @media ${devices.tablet} {
    padding-bottom: 36px;
  }
`;

export const EmphTitle = styled.span`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
`;

export const Text = styled.span.attrs({ className: 'small-text' })`
  padding-bottom: 60px;
`;

export const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.color.icons.svgBg};
  width: 172px;
`;

export const ProgressIndicatorWrapper = styled.div`
  @media ${devices.tablet} {
    padding-bottom: 4px;
  }
`;

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > div {
    margin: 0;
  }

  @media ${devices.tablet} {
    gap: 24px;
    flex-direction: row;
    ${({ isFinalStep }: { isFinalStep: boolean }) => isFinalStep && 'justify-content: center;'}
    & > div {
      ${({ isFinalStep }: { isFinalStep: boolean }) => (isFinalStep ? 'width: 343px' : 'flex: 1;')}
    }
  }
`;
