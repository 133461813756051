// @flow
const empty = { value: '', label: '' };

export const getEmploymentStatusList = (t: any) => {
  const employmentStatusTypes = [
    {
      value: 'LeadingEmployee',
      label: t('kyc.employmentInformationStep.employmentStatusTypes.leadingEmployee'),
    },
    {
      value: 'Employee',
      label: t('kyc.employmentInformationStep.employmentStatusTypes.employee'),
    },
    {
      value: 'SelfEmployed',
      label: t('kyc.employmentInformationStep.employmentStatusTypes.selfEmployed'),
    },
    {
      value: 'Retired',
      label: t('kyc.employmentInformationStep.employmentStatusTypes.retired'),
    },
    {
      value: 'NotEmployed',
      label: t('kyc.employmentInformationStep.employmentStatusTypes.notEmployed'),
    },
    {
      value: 'Student',
      label: t('kyc.employmentInformationStep.employmentStatusTypes.student'),
    },
  ];
  employmentStatusTypes.unshift(empty);
  return employmentStatusTypes;
};

export const getIncomeSourceList = (t: any) => {
  const incomeSourceTypes = [
    { value: 'Salary', label: t('kyc.employmentInformationStep.incomeSourceTypes.salary') },
    { value: 'Savings', label: t('kyc.employmentInformationStep.incomeSourceTypes.savings') },
    { value: 'Heritage', label: t('kyc.employmentInformationStep.incomeSourceTypes.heritage') },
    {
      value: 'Investments',
      label: t('kyc.employmentInformationStep.incomeSourceTypes.investments'),
    },
    { value: 'Pension', label: t('kyc.employmentInformationStep.incomeSourceTypes.pension') },
    { value: 'Loan', label: t('kyc.employmentInformationStep.incomeSourceTypes.loan') },
  ];
  incomeSourceTypes.unshift(empty);
  return incomeSourceTypes;
};

export const getProfessionalFieldList = (t: any) => {
  const professionalFieldList = [
    {
      value: 'AerospaceAndDefense',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.aerospaceAndDefense'),
    },
    {
      value: 'AgricultureAndMining',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.agricultureAndMining'),
    },
    {
      value: 'ArchitectureAndEngineering',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.architectureAndEngineering'),
    },
    {
      value: 'BusinessServicesAndConsulting',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.businessServicesAndConsulting'),
    },
    {
      value: 'ComputerAndInformationTechnology',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.computerAndInformationTechnol'),
    },
    {
      value: 'Education',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.education'),
    },
    {
      value: 'EnergyAndUtilities',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.energyAndUtilities'),
    },
    {
      value: 'Environmental',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.environmental'),
    },
    {
      value: 'FinanceAndBanking',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.financeAndBanking'),
    },
    {
      value: 'FoodAndBeverages',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.foodAndBeverages'),
    },
    {
      value: 'GamingAndSports',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.gamingAndSports'),
    },
    {
      value: 'GovernmentPublicSector',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.governmentPublicSector'),
    },
    {
      value: 'HealthcarePharmaAndBiotech',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.healthcarePharmaAndBiotech'),
    },
    {
      value: 'HumanitarianAndNonProfit',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.humanitarianAndNonProfit'),
    },
    {
      value: 'LegalAndCompliance',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.legalAndCompliance'),
    },
    {
      value: 'ManufacturingAndEngineering',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.manufacturingAndEngineering'),
    },
    {
      value: 'MediaAndEntertainment',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.mediaAndEntertainment'),
    },
    { value: 'Other', label: t('kyc.employmentInformationStep.professionalFieldTypes.other') },
    {
      value: 'RealEstateAndConstruction',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.realEstateAndConstruction'),
    },
    {
      value: 'RetailAndSales',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.retailAndSales'),
    },
    {
      value: 'SoftwareAndInternet',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.softwareAndInternet'),
    },
    {
      value: 'TransportationAndStorage',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.transportationAndStorage'),
    },
    {
      value: 'TravelAndHospitality',
      label: t('kyc.employmentInformationStep.professionalFieldTypes.travelAndHospitality'),
    },
  ];
  professionalFieldList.unshift(empty);
  return professionalFieldList;
};

export const getNetWealthList = (t: any) => {
  const netWealthRanges = [
    {
      value: 'LessThan20000',
      label: t('kyc.financialInformationStep.netWealthRanges.lessThan20000'),
    },
    {
      value: 'From20000To49999',
      label: t('kyc.financialInformationStep.netWealthRanges.from20000To49999'),
    },
    {
      value: 'From50000To99999',
      label: t('kyc.financialInformationStep.netWealthRanges.from50000To99999'),
    },
    {
      value: 'From100000To199999',
      label: t('kyc.financialInformationStep.netWealthRanges.from100000To199999'),
    },
    {
      value: 'From200000To250000',
      label: t('kyc.financialInformationStep.netWealthRanges.from200000To250000'),
    },
    {
      value: 'MoreThan250000',
      label: t('kyc.financialInformationStep.netWealthRanges.moreThan250000'),
    },
  ];
  netWealthRanges.unshift(empty);
  return netWealthRanges;
};

export const getInvestmentTargetList = (t: any) => {
  const investmentTargetRange = [
    {
      value: 'LessThan5000',
      label: t('kyc.financialInformationStep.investmentTargetRange.lessThan5000'),
    },
    {
      value: 'From5000To9999',
      label: t('kyc.financialInformationStep.investmentTargetRange.from5000To9999'),
    },
    {
      value: 'From10000To19999',
      label: t('kyc.financialInformationStep.investmentTargetRange.from10000To19999'),
    },
    {
      value: 'From20000To49999',
      label: t('kyc.financialInformationStep.investmentTargetRange.from20000To49999'),
    },
    {
      value: 'From50000To100000',
      label: t('kyc.financialInformationStep.investmentTargetRange.from50000To100000'),
    },
    {
      value: 'MoreThan100000',
      label: t('kyc.financialInformationStep.investmentTargetRange.moreThan100000'),
    },
  ];
  investmentTargetRange.unshift(empty);
  return investmentTargetRange;
};

export const getCryptoDepositList = (t: any) => {
  const cryptoDepositList = [
    { value: 'true', label: t('kyc.financialInformationStep.yes') },
    { value: 'false', label: t('kyc.financialInformationStep.no') },
  ];
  cryptoDepositList.unshift(empty);
  return cryptoDepositList;
};

export const AT_RELATED_FIELDS = [
  'isSelfDeclaredAsPep',
  'incomeSource',
  'netIncome',
  'netWealth',
  'investmentTarget',
  'cryptoDepositSource',
  'accountOpeningPurpose',
  'financialStatus',
];
