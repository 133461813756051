import { AnyAction } from 'redux';
import { TopUpState } from 'types/topUps';
import * as actionTypes from 'store/actions/topUps';
import { EMPTY_PAYMENT_METHOD } from 'common/const';

const initialState: TopUpState = {
  isFeatureTopUpsEnabled: false,
  isTopUpInitialising: false,
  isTwoFeOngoing: false,
  isTopUpInitialisationFailed: false,
  paymentMethods: {
    isPaymentMethodsFetching: false,
    savedPaymentMethods: [],
    alternativePaymentMethods: null,
    selectedPaymentMethod: EMPTY_PAYMENT_METHOD,
  },
};

const updatePaymentMethods = (action: AnyAction) => {
  if (action.payload.paymentMethods.length > 0) {
    return {
      savedPaymentMethods: action.payload.paymentMethods,
      selectedPaymentMethod: action.payload.paymentMethods[0],
    };
  }
  return { savedPaymentMethods: action.payload.paymentMethods };
};

const calculateFeePercentage = (fee: number) => {
  return Number((fee * 100).toFixed(2));
};

const topUps = (state: TopUpState = initialState, action: AnyAction): TopUpState => {
  switch (action.type) {
    case actionTypes.SET_TOP_UPS_FEATURE:
      return { ...state, isFeatureTopUpsEnabled: action.payload };
    case actionTypes.TOGGLE_TOP_UP_INITIALIZING:
      return { ...state, isTopUpInitialising: action.payload };
    case actionTypes.INIT_TOP_UP_REQUEST:
      return { ...state, isTopUpInitialising: true };
    case actionTypes.CONFIRM_TOP_UP_REQUEST:
      return { ...state, isTwoFeOngoing: true };
    case actionTypes.CONFIRM_TOP_UP_SUCCESS:
      return {
        ...state,
        activeTopUp: action.payload,
        isTopUpInitialising: false,
        isTwoFeOngoing: false,
        isTopUpInitialisationFailed: false,
      };
    case actionTypes.INIT_TOP_UP_REQUEST_FAILURE:
    case actionTypes.CONFIRM_TOP_UP_FAILURE:
      return {
        ...state,
        isTopUpInitialising: false,
        isTopUpInitialisationFailed: true,
        isTwoFeOngoing: false,
      };
    case actionTypes.FETCH_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          isPaymentMethodsFetching: true,
          createdPaymentMethod: undefined,
        },
      };
    case actionTypes.FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          ...updatePaymentMethods(action),
          isPaymentMethodsFetching: false,
        },
      } as TopUpState;
    case actionTypes.FETCH_PAYMENT_METHODS_FAILURE:
      return { ...state, paymentMethods: { ...state.paymentMethods, isPaymentMethodsFetching: false } };

    case actionTypes.SET_ALTERNATIVE_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: { ...state.paymentMethods, alternativePaymentMethods: action.payload },
      };
    case actionTypes.DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          savedPaymentMethods: [],
          selectedPaymentMethod: EMPTY_PAYMENT_METHOD,
          createdPaymentMethod: undefined,
        },
      };
    case actionTypes.CREATE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          createdPaymentMethod: action.payload,
          selectedPaymentMethod: action.payload,
        },
      };
    case actionTypes.SELECT_PAYMENT_METHOD:
      return { ...state, paymentMethods: { ...state.paymentMethods, selectedPaymentMethod: action.payload } };
    case actionTypes.TOP_UP_SUCCESS:
      return {
        ...state,
        activeTopUp: undefined,
        isTopUpInitialising: false,
        isTopUpInitialisationFailed: false,
        paymentMethods: {
          ...state.paymentMethods,
          createdPaymentMethod: undefined,
        },
      };
    case actionTypes.TOP_UP_FAILURE:
      return {
        ...state,
        activeTopUp: undefined,
        isTopUpInitialising: false,
        isTopUpInitialisationFailed: false,
        paymentMethods: {
          ...state.paymentMethods,
          createdPaymentMethod: undefined,
          selectedPaymentMethod: initialState.paymentMethods.selectedPaymentMethod,
        },
      };
    case actionTypes.CANCEL_TOP_UP_SUCCESS:
      return {
        ...state,
        activeTopUp: undefined,
        isTopUpInitialising: false,
      };
    case actionTypes.FETCH_TOP_UP_LIMITS_SUCCESS:
      return {
        ...state,
        limits: {
          ...state.limits,
          ...action.payload,
          topUpFeePercent: calculateFeePercentage(action.payload.topUpFee),
        },
      };
    default:
      return state;
  }
};

export default topUps;
