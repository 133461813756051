/* eslint-disable no-nested-ternary, @typescript-eslint/no-unsafe-return */
import { combineEpics } from 'redux-observable';
import { catchError, filter, map, mergeMap, pluck, switchMap, take } from 'rxjs/operators';
import { RootEpic } from 'types/common';
import {
  loadActivityItemsError,
  loadActivityItemsSuccess,
  loadInitialActivityItems,
  loadNextActivityItemsPage,
  setCryptoActivityFilter,
  setSubtypeActivityFilter,
} from 'store/slices/activity/actions';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { getAccessToken } from 'store/selectors/auth';
import {
  getActivityFilters,
  getActivityItemsFullyLoaded,
  getActivityItemsReference,
} from 'store/slices/activity/selectors';
import { SCROLL_PAGE_SIZE } from 'common/const';
import { Order } from 'types/currency';
import { State } from 'store/types/store';
import { isPresent } from 'safetypings';
import { handleAjaxError } from './auth';

const initialLoad: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(loadInitialActivityItems.match),
    switchMap(() =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);

          if (!accessToken) return undefined;

          const filters = getActivityFilters(state);

          return {
            accessToken,
            params: {
              subtypes: filters.subtype,
              size: SCROLL_PAGE_SIZE,
              entities: filters.crypto,
            },
          };
        }),
        filter(isPresent),
        take(1),
        mergeMap(({ accessToken, params }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat('/orders/history/')
              .concat(queryArrayString(params)),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((orders: Order[]) => loadActivityItemsSuccess({ orders })),
          ),
        ),
      ),
    ),
    // TODO: Add fail texts
    catchError(handleAjaxError(action$, (error) => loadActivityItemsError(), {}, {})),
  );
const loadNextPage: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(loadNextActivityItemsPage.match),
    switchMap(() =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);

          if (!accessToken) return undefined;

          const isFullyLoaded = getActivityItemsFullyLoaded(state);

          if (isFullyLoaded) return undefined;

          const filters = getActivityFilters(state);

          const reference = getActivityItemsReference(state);

          return {
            accessToken,
            params: {
              subtypes: filters.subtype,
              size: SCROLL_PAGE_SIZE,
              entities: filters.crypto,
              reference,
            },
          };
        }),
        filter(isPresent),
        take(1),
        mergeMap(({ accessToken, params }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat('/orders/history/')
              .concat(queryArrayString(params)),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((orders: Order[]) => loadActivityItemsSuccess({ orders })),
          ),
        ),
      ),
    ),
    catchError(handleAjaxError(action$, (error) => loadActivityItemsError(), {}, {})),
  );

const cryptoFiltersChange: RootEpic = (action$) =>
  action$.pipe(filter(setCryptoActivityFilter.match), map(loadInitialActivityItems));

const subtypeFiltersChange: RootEpic = (action$) =>
  action$.pipe(filter(setSubtypeActivityFilter.match), map(loadInitialActivityItems));

export default combineEpics(initialLoad, loadNextPage, cryptoFiltersChange, subtypeFiltersChange);
