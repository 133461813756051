/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react';
import * as formatting from 'common/utils/formatting';
import { parseFloatStrict } from 'common/utils/math';
import { DefaultTradingKeys, SpecificKeys } from 'types/tradingRules';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { PortfolioAsset, PortfolioCash } from 'types/portfolio';

export const useTradingFormData = ({
  selectedCrypto,
  formik,
  isBuy,
  specificKey,
  portfolioCash,
  cryptoInstruments,
  buyPrice,
  sellPrice,
  cryptoPrecision,
}: {
  selectedCrypto: string;
  formik: FormikProps<DefaultTradingKeys | SpecificKeys>;
  isBuy: boolean;
  specificKey: SpecificKeys;
  portfolioCash: PortfolioCash;
  cryptoInstruments: PortfolioAsset[];
  buyPrice: number;
  sellPrice: number;
  cryptoPrecision: number | undefined;
}) => {
  const { t } = useTranslation();

  const [isLimitPriceActive, setIsLimitPriceActive] = useState(false);
  const [isCryptoAmountActive, setIsCryptoAmountActive] = useState(false);
  const [isSpendAmountActive, setIsSpendAmountActive] = useState(false);
  const { values, touched, setFieldValue } = formik;

  const [limitPrice, setLimitPrice] = useState<string>(
    formatting.fiat(values[specificKey], { symbol: false, adaptivePrecision: true }),
  );
  const [cryptoAmount, setCryptoAmount] = useState<string>(
    formatting.crypto(values.cryptoAmount, cryptoPrecision),
  );
  const [spendAmount, setSpendAmount] = useState<string>(
    formatting.fiat(values.spendAmount, { symbol: false }),
  );

  const [isNeedValid, setIsNeedValid] = useState(false);
  const [limitPriceWarning, setLimitPriceWarning] = useState(undefined);

  useEffect(() => {
    if (isNeedValid) {
      // eslint-disable-next-line
      formik.validateForm({
        [specificKey]: formatting.numberFromDecimalString(limitPrice),
        cryptoAmount: formatting.numberFromDecimalString(cryptoAmount),
        spendAmount: formatting.numberFromDecimalString(spendAmount),
      });
      setIsNeedValid(false);
    }
  }, [isNeedValid]);
  const [myTimer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (myTimer) clearInterval(myTimer);
    const interval = setInterval(() => {
      // eslint-disable-next-line
      formik.validateForm({
        [specificKey]: formatting.numberFromDecimalString(limitPrice),
        cryptoAmount: formatting.numberFromDecimalString(cryptoAmount),
        spendAmount: formatting.numberFromDecimalString(spendAmount),
      });
    }, 2000);
    setTimer(interval);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    limitPrice,
    cryptoAmount,
    spendAmount,
    touched[specificKey],
    touched.cryptoAmount,
    touched.spendAmount,
  ]);

  useEffect(() => {
    void setFieldValue('spendAmount', formatting.numberFromDecimalString(spendAmount));
  }, [setFieldValue, spendAmount]);
  useEffect(() => {
    void setFieldValue('cryptoAmount', formatting.numberFromDecimalString(cryptoAmount));
  }, [cryptoAmount, setFieldValue]);
  useEffect(() => {
    void setFieldValue(specificKey, formatting.numberFromDecimalString(limitPrice));
  }, [limitPrice, setFieldValue]);

  useEffect(() => {
    if (specificKey === 'limitPrice') {
      if (isBuy) {
        if (+values[specificKey] > buyPrice) setLimitPriceWarning(t('automation.warnings.buyPriceTooGood'));
        else setLimitPriceWarning(undefined);
        return;
      }

      if (+values[specificKey] < sellPrice) setLimitPriceWarning(t('automation.warnings.sellPriceTooGood'));
      else setLimitPriceWarning(undefined);
    } else {
      if (isBuy) {
        if (+values[specificKey] < buyPrice) setLimitPriceWarning(t('stopOrders.warnings.buyPriceTooGood'));
        else setLimitPriceWarning(undefined);
        return;
      }
      if (+values[specificKey] > sellPrice) setLimitPriceWarning(t('stopOrders.warnings.sellPriceTooGood'));
      else setLimitPriceWarning(undefined);
    }
  }, [values[specificKey], sellPrice, buyPrice]);

  useEffect(() => {
    if (limitPrice === '') return;

    if (isLimitPriceActive) {
      if (limitPrice === '0') return;
      const cryptoAmountTemp = parseFloatStrict(spendAmount) / parseFloatStrict(limitPrice);
      setCryptoAmount(
        Number.isNaN(cryptoAmountTemp)
          ? formatting.crypto(0, cryptoPrecision)
          : formatting.crypto(cryptoAmountTemp, cryptoPrecision),
      );
    }

    if (isCryptoAmountActive) {
      if (cryptoAmount === '') {
        setSpendAmount('0');
        return;
      }

      const spendAmountTemp = parseFloatStrict(cryptoAmount) * parseFloatStrict(limitPrice);
      setSpendAmount(
        Number.isNaN(spendAmountTemp)
          ? formatting.crypto(0, cryptoPrecision)
          : formatting.fiat(spendAmountTemp, { symbol: false }),
      );
    }

    if (isSpendAmountActive) {
      if (spendAmount === '') {
        setCryptoAmount(formatting.fiat('0', { symbol: false }));
        return;
      }
      const cryptoAmountTemp = parseFloatStrict(spendAmount) / parseFloatStrict(limitPrice);
      setCryptoAmount(
        Number.isNaN(cryptoAmountTemp)
          ? formatting.crypto(0, cryptoPrecision)
          : formatting.crypto(cryptoAmountTemp, cryptoPrecision),
      );
    }
  }, [
    limitPrice,
    cryptoAmount,
    spendAmount,
    touched[specificKey],
    touched.cryptoAmount,
    touched.spendAmount,
  ]);

  const selectedCryptoData = cryptoInstruments.filter(
    (instrument) => instrument.entity.toLowerCase() === selectedCrypto.toLowerCase(),
  )[0];
  const availableVolume = selectedCryptoData?.volume || 0;

  const maxAmountAvailableToSell = isBuy ? portfolioCash.volume : availableVolume;

  const handleBuyMaxValueClick = () => {
    setSpendAmount(formatting.fiat(maxAmountAvailableToSell, { symbol: false }));
    const cryptoAmountTemp = formatting.crypto(
      maxAmountAvailableToSell / parseFloatStrict(limitPrice),
      cryptoPrecision,
    );
    setCryptoAmount(
      Number.isNaN(cryptoAmountTemp) ? formatting.crypto(0, cryptoPrecision) : cryptoAmountTemp,
    );
    setIsNeedValid(true);
  };

  const handleSellMaxValueClick = () => {
    setCryptoAmount(formatting.crypto(maxAmountAvailableToSell, cryptoPrecision));
    const spendAmountTemp = (maxAmountAvailableToSell * parseFloatStrict(limitPrice)).toFixed(2);
    setSpendAmount(formatting.fiat(spendAmountTemp, { symbol: false }));
  };

  const handleMaxValueClick = isBuy ? handleBuyMaxValueClick : handleSellMaxValueClick;

  return {
    handleMaxValueClick,
    validatedValues: {
      limitPrice: { limitPrice, setLimitPrice },
      cryptoAmount: { cryptoAmount, setCryptoAmount },
      spendAmount: { spendAmount, setSpendAmount },
    },
    maxAmountAvailableToSell,
    setIsActive: {
      setIsLimitPriceActive,
      setIsCryptoAmountActive,
      setIsSpendAmountActive,
    },
    limitPriceWarning,
  };
};
