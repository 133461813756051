import { take, fork, all, put, race, delay, select, call } from 'redux-saga/effects';

import {
  flushTrackedEvents as _flushTrackedEvents,
  getCommonHeaders,
  reportEventToBackend as _reportEventToBackend,
} from 'common/api';
import { isEventEnabled, isEventTriggersFlushing } from 'common/tracking/rules';
import { getWindowDimensions } from 'common/utils/dimensions';
import { expBackoffTime } from 'common/utils/math';
import { getActiveSessionId } from 'common/utils/session';
import { getApiEnvironment } from 'store/selectors/environment';
import * as trackingActions from 'store/actions/tracking';
import { getEventsInBuffer, getFlushRetryMode, getTrackingConfiguration } from 'store/selectors/tracking';
import { getBatchSize, getMaxBatchSeconds } from 'store/selectors/trackingConfig';
import { getAccessToken, getAccountType, getUserId } from 'store/selectors/auth';
import { fetchEntity } from '../auth';

const flushTrackedEvents = fetchEntity.bind(null, trackingActions.flushEvents, _flushTrackedEvents, false);

const reportEventToBackend = fetchEntity.bind(
  null,
  trackingActions.reportEventToBackend,
  _reportEventToBackend,
  false,
);

function* getCommonProperties(): Generator<any, any, any> {
  const headers: any = getCommonHeaders();
  const environment = yield select(getApiEnvironment);
  // const abTest = yield select(getDefinedAbTestGroups);
  const trackingConfiguration = yield select(getTrackingConfiguration);
  const dimensions = getWindowDimensions();
  return {
    sessionId: yield select(getActiveSessionId),
    userId: yield select(getUserId),
    userAccountType: yield select(getAccountType),
    environment,
    app: headers['X-App'],
    appBuild: headers['X-App-Build'],
    appRelease: null,
    appStartTime: headers['X-App-Start-Time'],
    deviceID: headers['X-Device-ID'],
    deviceLanguage: null,
    deviceModel: headers['X-Device-Model'],
    deviceOs: headers['X-OS'],
    deviceOsName: headers['X-OS-Name'],
    devicePlatform: null,
    deviceBrowser: headers['X-Browser-Name'],
    deviceBrowserVersion: headers['X-Browser-Version'],
    deviceType: null,
    deviceBrand: null,
    deviceModelId: null,
    deviceHardwareName: null,
    deviceFontScale: null,
    deviceScreenWidth: dimensions.width,
    deviceScreenHeight: dimensions.height,
    deviceTimeZoneOffsetSeconds: new Date().getTimezoneOffset() * 60,
    abTest: null,
    eventTags: trackingConfiguration?.eventTags,
  };
}

function* flushEvents(): Generator<any, any, any> {
  const accessToken = yield select(getAccessToken);
  const events = yield select(getEventsInBuffer);
  const commonProperties = yield call(getCommonProperties);
  const payload = {
    commonProperties,
    events,
    sendingTime: new Date().toISOString(),
  };
  yield call(flushTrackedEvents, { payload, accessToken });
}

// Flushes events if they haven't been in a pre-defined number of seconds
function* flushTimer(): Generator<any, any, any> {
  while (true) {
    const maxBatchSeconds = yield select(getMaxBatchSeconds);
    let timerDelay = maxBatchSeconds * 1000;

    // If the flush errors out, we attempt in an exponential backoff manner
    // until success
    const { active: flushRetryActive, attempt } = yield select(getFlushRetryMode);
    if (flushRetryActive) {
      timerDelay = expBackoffTime(attempt);
    }

    const { timer } = yield race({
      flush: take('FLUSH_EVENTS_SUCCESS'),
      timer: delay(timerDelay),
    });
    if (timer) {
      yield put(trackingActions.flushEventsTimerExpired());
    }
  }
}

// Filters and queues events
function* filterEvents(): Generator<any, any, any> {
  while (true) {
    const trackingConfiguration = yield select(getTrackingConfiguration);
    const action = yield take(trackingActions.LOG_EVENT);
    const eventEnabled = yield call(isEventEnabled, action.payload, trackingConfiguration);
    if (eventEnabled) {
      yield put(trackingActions.queueEvent(action.payload));
    }
  }
}

function* filterEventsForBackend(): Generator<any, any, any> {
  while (true) {
    const trackingConfiguration = yield select(getTrackingConfiguration);
    const action = yield take(trackingActions.LOG_EVENT);
    const eventEnabled = yield call(isEventEnabled, action.payload, trackingConfiguration, true);
    if (eventEnabled) {
      const accessToken = yield select(getAccessToken);
      yield call(reportEventToBackend, { payload: action.payload, accessToken });
    }
  }
}

function* flushWatcher(): Generator<any, any, any> {
  while (true) {
    const batchSize = yield select(getBatchSize);

    // We check if we need to flush events after every event OR
    // after a certain number of seconds OR
    // when the app goes into background
    const { event, flush } = yield race({
      event: take(trackingActions.QUEUE_EVENT),
      flush: take([trackingActions.FLUSH_EVENTS_TIMER_EXPIRED /* APP_INTO_BG */]),
    });

    let batchFull = false;
    let flushImmediately = false;
    const eventsInBuffer = yield select(getEventsInBuffer);
    if (event) {
      batchFull = eventsInBuffer.length >= batchSize;

      const trackingConfiguration = yield select(getTrackingConfiguration);
      flushImmediately = isEventTriggersFlushing(event.payload, trackingConfiguration);
    }

    // We trigger flush on full batch only if we're not in retry mode,
    // otherwise we wait for flush timer
    const { active: flushRetryActive } = yield select(getFlushRetryMode);

    if (((flushImmediately || batchFull) && !flushRetryActive) || (flush && eventsInBuffer.length > 0)) {
      yield fork(flushEvents);
    }
  }
}

export default function* root(): Generator<any, any, any> {
  try {
    yield all([fork(flushTimer), fork(flushWatcher), fork(filterEvents), fork(filterEventsForBackend)]);
  } catch (error) {
    console.error(error);
  }
}
