/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createSelector } from 'reselect';
import { isOpened } from 'common/utils/math';
import { State } from 'store/types/store';
import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export const getKycHours = createSelector(
  [
    (state) => state.misc.activeHours.kyc.from,
    (state) => state.misc.activeHours.kyc.to,
    (state) => state.misc.activeHours.timezone,
  ],
  (from: string, to: string, timezone) => ({
    from: () => zonedTimeToUtc(parse(from, 'HH:mm', new Date()), timezone),
    to: () => zonedTimeToUtc(parse(to, 'HH:mm', new Date()), timezone),
  }),
);

export const getIsKycOpened = (state: State) => isOpened(getKycHours(state));

export const getIsAccountDeleted = (state: State) => state.misc.account.isDeleted;
