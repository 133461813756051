import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { paths } from 'common/urls';
import { StakingProps } from 'types/staking';
import { stakeOrUnstakeOrderTypes } from 'common/const';
import AssetStaking from 'scenes/AssetStaking';
import AssetUnstaking from 'scenes/AssetUnstaking';

type Props = {} & StakingProps;

const AssetStakingWrapper: FunctionComponent<Props> = ({ params, ...rest }) => {
  const { assetCode, type } = params;

  if (!assetCode || !type) {
    return <Navigate to={paths.PORTFOLIO} />;
  }

  // Match case insensitive
  const typeToUpper = type.toUpperCase();

  switch (typeToUpper) {
    case stakeOrUnstakeOrderTypes.Stake.toUpperCase():
      return <AssetStaking assetCode={assetCode.toLowerCase()} {...rest} />;
    case stakeOrUnstakeOrderTypes.Unstake.toUpperCase():
      return <AssetUnstaking assetCode={assetCode.toLowerCase()} {...rest} />;
    default:
      return <Navigate to={paths.PORTFOLIO} />;
  }
};

export default AssetStakingWrapper;
