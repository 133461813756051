import React, { Fragment, FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Crypto } from 'types/assets';
import {
  StakingDataRow,
  Label,
  StakingType,
  AprValue,
  LabelBold,
  Divider,
  InfoIcon,
} from 'common/components/StakingData/styles';
import StakingPeriodModal from 'common/components/Modal/Staking/StakingPeriodModal';
import { ParsedStakingOverviewItem } from 'types/staking';
import { useModalProvider } from 'providers/Modal/useModalProvider';
import { DEFAULT_MODAL_PROPS } from 'common/const/modals';
import { getColorWithNegateOnLowLuminosity } from 'common/utils/color';
import { ThemeName } from 'types/theme';
import { useSelector } from 'react-redux';
import { getTheme } from 'store/selectors/settings';

type Props = {
  stakingInfo: ParsedStakingOverviewItem;
  cryptoInfo: Crypto;
  unstakeDate: string | null;
  hideButtonInfo?: boolean;
  additionalData?: { label: string; value: string }[];
  additionalSummaryData?: { label: string; value: string }[];
};

const StakingData: FunctionComponent<Props> = ({
  stakingInfo,
  cryptoInfo,
  unstakeDate,
  hideButtonInfo = false,
  additionalData = [],
  additionalSummaryData = [],
}) => {
  const { t } = useTranslation();
  const modalProvider = useModalProvider();
  const themeName: ThemeName = useSelector(getTheme);
  const color = getColorWithNegateOnLowLuminosity(themeName, cryptoInfo?.color);
  return (
    <Fragment>
      <StakingDataRow>
        <Label>{t('staking.apr')}</Label>
        <AprValue>{stakingInfo?.aprInfo}</AprValue>
      </StakingDataRow>
      <StakingDataRow>
        <Label>{t('staking.type')}</Label>
        <StakingType>{t('staking.typeValue')}</StakingType>
      </StakingDataRow>
      <StakingDataRow>
        <Label>
          {t('staking.unstakePeriod')}
          {!hideButtonInfo && (
            <InfoIcon
              name="icon-outlined-info"
              onClick={() => {
                modalProvider.addModal({
                  component: (
                    <StakingPeriodModal entity={cryptoInfo?.code} color={color} {...DEFAULT_MODAL_PROPS} />
                  ),
                });
              }}
            />
          )}
        </Label>
        <LabelBold>{t('staking.periodValue', { start: 7, end: 45 })}</LabelBold>
      </StakingDataRow>
      {additionalData.map(({ label, value }) => (
        <StakingDataRow key={label}>
          <Label>{label}</Label>
          <LabelBold>{value}</LabelBold>
        </StakingDataRow>
      ))}
      <Divider />
      {unstakeDate && (
        <StakingDataRow>
          <Label>{t('staking.stakingOverview.detail.date')}</Label>
          <LabelBold>{unstakeDate}</LabelBold>
        </StakingDataRow>
      )}

      {additionalSummaryData.map(({ label, value }) => (
        <StakingDataRow key={label}>
          <Label>{label}</Label>
          <LabelBold>{value}</LabelBold>
        </StakingDataRow>
      ))}
    </Fragment>
  );
};

export default memo(StakingData);
