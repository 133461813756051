import { seonSdkUrl } from 'common/urls';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deviceMonitoringScriptLoaded } from 'store/actions/deviceMonitoring';
import useExternalScript from './useExternalScript';

const useDeviceMonitoring = () => {
  const dispatch = useDispatch();
  const scriptState = useExternalScript(seonSdkUrl);

  useEffect(() => {
    if (scriptState === 'ready') {
      dispatch(deviceMonitoringScriptLoaded());
    }
  }, [scriptState]);
};

export default useDeviceMonitoring;
