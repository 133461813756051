import React, { useState } from 'react';
import { getKCCRaffleEndDate, getKCCRaffleStartDate } from 'store/selectors/configFeature';
import KCCRaffleEntryModal from 'common/components/Modal/KCCRaffle/KCCRaffleEntryModal';
import KCCRaffleExitModal from 'common/components/Modal/KCCRaffle/KCCRaffleExitModal';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import KCCRaffleConfirmModal from 'common/components/Modal/KCCRaffle/KCCRaffleConfirmModal';
import {
  setAcceptedKansasCityChiefsRaffleTermsForWeeks,
  setDoNotAskForKansasCityChiefsRaffleTerms,
  setRejectedKansasCityChiefsRaffleTermsForWeeks,
} from 'store/actions/settings';
import {
  getAcceptedKansasCityChiefsRaffleTermsForWeeks,
  getRejectedKansasCityChiefsRaffleTermsForWeeks,
} from 'store/selectors/settings';
import Icon from 'common/components/Icon';
import { useMedia } from 'common/hooks/useMedia';
import { devices } from 'common/styles';
import {
  composeWeeksArray,
  defineWeekOfYear,
  isDateInWeeks,
  getWeeksBetweenDates,
} from 'common/utils/kccRaffle';
import { getItem, setItem } from 'services/storage';
import { KCC_RAFFLE_BANNER_HIDDEN_FOR_WEEKS } from 'common/const/kccRaffle';
import { paths } from 'common/urls';
import {
  RaffleButton,
  RaffleCloseButton,
  RaffleText,
  RaffleTitle,
  StockRaffleBannerContainer,
} from './styles';
import BannerLayoutWrapper from '../styles';

type RaffleSteps = 'banner' | 'entry' | 'exit' | 'confirm';

const KCCRaffleBanner = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isMobile = useMedia([devices.maxTablet, devices.tablet], [true, false], false);
  const { t } = useTranslation();
  const kccRaffleStartDate = useSelector(getKCCRaffleStartDate);
  const kccRaffleEndDate = useSelector(getKCCRaffleEndDate);
  const acceptedKansasCityChiefsRaffleTermsForWeeks = useSelector(
    getAcceptedKansasCityChiefsRaffleTermsForWeeks,
  );
  const rejectedKansasCityChiefsRaffleTermsForWeeks = useSelector(
    getRejectedKansasCityChiefsRaffleTermsForWeeks,
  );

  const [stockRaffleStep, setStockRaffleStep] = useState<RaffleSteps>('banner');

  const isOnSettingsPage = matchPath({ path: paths.PERSONAL_INFO }, pathname);
  const raffleJoined = isDateInWeeks(acceptedKansasCityChiefsRaffleTermsForWeeks);
  const raffleDescriptionType = raffleJoined ? 'joined' : 'notJoined';

  const onClickingBannerAway = () => {
    const { response } = getItem(KCC_RAFFLE_BANNER_HIDDEN_FOR_WEEKS);
    const clickedAwayForWeeks = response?.item ? (JSON.parse(response.item) as string) : null;

    setItem(
      KCC_RAFFLE_BANNER_HIDDEN_FOR_WEEKS,
      JSON.stringify(composeWeeksArray(clickedAwayForWeeks, defineWeekOfYear())),
    );
  };

  const onAcceptRaffleForOneWeek = () => {
    dispatch(
      setAcceptedKansasCityChiefsRaffleTermsForWeeks(
        composeWeeksArray(acceptedKansasCityChiefsRaffleTermsForWeeks, defineWeekOfYear()),
      ),
    );
    setStockRaffleStep('banner');
  };

  const onRejectRaffleForOneWeek = () => {
    dispatch(
      setRejectedKansasCityChiefsRaffleTermsForWeeks(
        composeWeeksArray(rejectedKansasCityChiefsRaffleTermsForWeeks, defineWeekOfYear()),
      ),
    );
  };

  const onFullRejectRaffle = () => {
    dispatch(
      setRejectedKansasCityChiefsRaffleTermsForWeeks(
        getWeeksBetweenDates(kccRaffleStartDate, kccRaffleEndDate),
      ),
    );

    dispatch(setDoNotAskForKansasCityChiefsRaffleTerms(true));
  };

  const weeksOfRaffle = getWeeksBetweenDates(kccRaffleStartDate, kccRaffleEndDate).split(',');
  const isMaybeLaterButtonHidden = weeksOfRaffle[weeksOfRaffle.length - 1] === defineWeekOfYear();

  return (
    <>
      <BannerLayoutWrapper>
        <StockRaffleBannerContainer>
          <RaffleTitle>
            <Trans i18nKey={`kccRaffle.banner.${raffleDescriptionType}.title`} />
          </RaffleTitle>
          {isMobile && !isOnSettingsPage && (
            <RaffleCloseButton title="" onClick={onClickingBannerAway}>
              <Icon name="icon-crossIcon" sizeValue="tiniest" />
            </RaffleCloseButton>
          )}
          <RaffleText raffleJoined={raffleJoined}>
            <Trans i18nKey={`kccRaffle.banner.${raffleDescriptionType}.description`} />
          </RaffleText>

          {!raffleJoined && (
            <RaffleButton
              title={t(`kccRaffle.banner.${raffleDescriptionType}.button`)}
              inverted
              size="large"
              onClick={() => setStockRaffleStep('entry')}
            />
          )}
          {!isMobile && !isOnSettingsPage && (
            <RaffleCloseButton title="" onClick={onClickingBannerAway} $raffleJoined={raffleJoined}>
              <Icon name="icon-crossIcon" sizeValue="tiniest" />
            </RaffleCloseButton>
          )}
        </StockRaffleBannerContainer>
      </BannerLayoutWrapper>

      {stockRaffleStep === 'entry' && (
        <KCCRaffleEntryModal
          onClose={() => setStockRaffleStep('banner')}
          onConfirm={() => {
            onAcceptRaffleForOneWeek();
            setStockRaffleStep('confirm');
          }}
          onRejectOneWeek={() => {
            onRejectRaffleForOneWeek();
            setStockRaffleStep('banner');
            onClickingBannerAway();
          }}
          onFullReject={() => setStockRaffleStep('exit')}
          isMaybeLaterButtonHidden={isMaybeLaterButtonHidden}
        />
      )}
      {stockRaffleStep === 'exit' && (
        <KCCRaffleExitModal onDismiss={() => setStockRaffleStep('entry')} onConfirm={onFullRejectRaffle} />
      )}
      {stockRaffleStep === 'confirm' && (
        <KCCRaffleConfirmModal
          onDismiss={() => setStockRaffleStep('banner')}
          onConfirm={() => {
            setStockRaffleStep('banner');
          }}
        />
      )}
    </>
  );
};

export default KCCRaffleBanner;
