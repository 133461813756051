/* eslint-disable no-irregular-whitespace */
export default {
  l10n: {
    lang: 'de',
    muiLocalizationAdapterLocale: 'de-DE',
    helpCenterLinkParam: 'de',
    currency: '€',
    currencyShortName: 'eur',
    cuFormat: '%n %u',
    cuFormatSuffix: '%n%u',
    separator: '.',
    delimiter: ',',
    dateFormat: 'dd.MM.yyyy',
    summaryDateFormat: 'dd.MM.yyyy',
    dateTimeFormat: 'd. MMM yyyy HH:mm',
    dateTimeFormatShort: 'd. MMM yyyy',
    dateTimeMobile: 'd.MM.yy',
    fullDateFormat: 'eeee dd MMMM yyyy',
    transactionHistoryGroupedDateFormat: 'eeee, dd. MMMM yyyy',
    transactionHistoryDatetimeFormat: 'dd.MM.yyyy, HH:mm',
    weekdayFormat: 'eeee',
    timePeriodFormat: {
      '1H': 'HH:mm a',
      '3H': 'HH:mm a',
      '24H': 'HH:mm a',
      '7D': 'd MMM yyyy HH:mm',
      '30D': 'd MMM yyyy HH:mm',
      '12M': 'd MMM yyyy',
      MAX: 'd MMM yyyy',
    },
    timePeriodFormatMarketOverview: {
      '1H': 'HH:mm',
      '3H': 'HH:mm',
      '24H': 'HH:mm',
      '7D': 'd MMM yyyy',
      '30D': 'd MMM yyyy',
      '12M': 'd MMM yyyy',
      MAX: 'd MMM yyyy',
    },
    faq_link: 'https://bisonapp.com/faq',
    appStoreLink: 'https://apps.apple.com/de/app/bison-buy-bitcoin-co/id1362518159',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=de.bisonapp&hl=de&gl=US&pli=1',
    formatCurrency: '{{amount}} {{symbol}}',
  },
  pageTitle: '{{title}} | BISON',
  mainScreenTitles: {
    redeemCode: 'Aktionscode einlösen',
    welcome: 'Kryptowährungen',
    trading: 'Handeln',
    tradeManager: 'Trading Manager',
    portfolio: 'Portfolio', //  Portfolio Overview
    totalBalance: 'Total Balance', // instead of portfolio
    market: 'Markt', //  Market Overview
    limitOrder: 'Limit Order',
    priceAlerts: 'Preisalarm',
    orderHistory: 'Transaktionsübersicht', // Transaction History
    settings: 'Einstellungen', // Settings
    offsetAccounts: 'Verrechnungstöpfe',
    cryptoWithdraw: 'Auszahlung Kryptowährungen',
    savingPlan: 'Sparplan',
    cryptoWithdrawLegal: '{{currency}} abheben',
    cryptoDeposit: 'Einzahlung Kryptowährungen',
    withdraw: 'Auszahlung',
    infoReport: 'Reports',
    deposit: 'Einzahlung',
    depositTransfer: 'Einzahlung per Überweisung',
    depositSelect: 'Einzahlungsart wählen',
    depositTopUp: 'Sofort Einzahlung',
    referral: 'Freunde einladen',
    intro: 'Intro',
    deleteAccount: 'Konto kündigen',
    techIndicators: 'Indikatoren',
    design: 'Design',
    transactionHistory: 'Aktivität',
    riskSettings: 'Risikofragen',
    staking: 'Staking',
    orders: 'Orders',
    stakingRewardHistory: 'Reward Historie',
  },
  tabTitles: {
    portfolio: 'Portfolio',
    market: 'Markt',
    transactions: 'Aktivität',
    settings: 'Konto',
    alerts: 'Alarm',
    tradingManager: 'Manager',
    staking: 'Staking',
    orders: 'Orders',
  },
  banners: {
    paperMoneyText: 'Du befindest dich im Demo-Modus',
    disabledTradingText: `Der Handel mit Aktien und ETFs ist im\nDemo-Modus nicht möglich.`,
    mobilePaperMoneyText: 'Demo-Modus',
    switch: 'Jetzt Verifizieren',
    switchKycedAcc: 'Echtgeldhandel',
    switchMobileKycedAcc: 'Echtgeldhandel',
    switchPendingKyc: 'Verifizierung: In Prüfung',
    tryModeText: 'Demo-Modus aktiv',
    registerNow: 'Jetzt registrieren',
    tradingClosesIn: 'Handel schließt {{time}}',
    tradingOpensIn: 'Handel öffnet {{time}}',
  },
  androidBanner: {
    text: 'Kostenlos － Im Google Play Store',
    view: 'Anzeigen',
  },
  common: {
    agree: 'Akzeptieren',
    buy: 'Kauf',
    sell: 'Verkauf',
    description: 'Beschreibung',
    show: 'Anzeigen',
    hide: 'Verbergen',
    on: 'Ein',
    off: 'Aus',
    close: 'Schließen',
    help: 'Hilfe',
    requiredField: 'Pflichtfeld',
    save: 'Speichern',
    saving: 'Speichern...',
    cancel: 'Abbrechen', // Cancel
    noData: 'Keine Daten',
    ok: 'OK',
    okSend: 'OK, senden',
    okConfirm: 'OK, bestätigen',
    noThanks: 'Nein, danke',
    copied: 'Kopiert',
    back: 'Zurück',
    update: 'Update',
    notNow: 'Nicht jetzt',
    openSettings: 'Einstellungen öffnen',
    cryptoFilter: 'Krypto Filter',
    next: 'Weiter',
    toPortfolio: 'Zum Portfolio',
    support: 'Kundenservice Formular',
    delete: 'Löschen',
    importantNotice: 'Wichtiger Hinweis',
    done: 'Erledigt',
    continue: 'Weiter',
    select: 'Bitte auswählen',
    showMore: 'Mehr anzeigen',
    showLess: 'Weniger Anzeigen',
    twofa: {
      wrongSecurityCode: 'Dein Sicherheitscode ist ungültig',
      resentCode: 'Wir haben dir einen Security-Code gesendet.',
    },
    max: 'Max',
  },
  // Common field errors
  fieldErrors: {
    nameRequired: 'Bitte gib deinen Namen ein.',
    cardNumberRequired: 'Bitte gib eine Kartennummer ein.',
    cardDateRequired: 'Bitte gib ein Ablaufdatum ein.',
    cardCvcRequired: 'Bitte gib deine Prüfnummer an. Diese findest du auf der Rückseite deiner Kreditkarte.',
    emailRequired: 'Bitte gib deine E-Mail-Adresse ein.',
    emailTaken: 'Diese E-Mail-Adresse ist nicht verfügbar.',
    invalidEmail: 'Bitte gib eine gültige E-Mail-Adresse ein.',
    passwordRequired: 'Bitte gib ein Passwort ein.',
    passwordTooShort: 'Dein Passwort sollte mindestens {{minLength}} Zeichen lang sein.',
    passwordTooLong: 'Dein Passwort sollte maximal {{maxLength}} Zeichen lang sein.',
    passwordInvalid: 'Bitte nutze ein sichereres Passwort. Es muss aus mehr Zeichen bestehen',
    firstName: 'Bitte gib deinen Vornamen ein.',
    lastName: 'Bitte gib deinen Nachnamen ein.',
    charsOnly: 'Bitte gib nur Buchstaben ein.',
    street: 'Du musst deine Straße eingeben (max. 35 Zeichen).',
    streetNumber: 'Du musst deine Hausnummer eingeben.',
    postNumber: {
      incorrect: 'Ungültige Postleitzahl (max. 10 Zeichen)',
      required: 'Du musst deine Postleitzahl eingeben (max. 10 Zeichen).',
    },
    city: 'Du musst deine Stadt eingeben (max. 35 Zeichen).',
    selectOption: 'Bitte wähle eine Option',
    enterAmount: 'Ein Betrag ist erforderlich',
    phoneNumberRequired: 'Du musst deine Handynummer eingeben.',
    phoneNumberInvalid: 'Ungültige Handynummer',
    invalidReferralCode: 'Ungültiger Freunde-einladen Code',
    country: 'Bitte wähle ein Land aus',
    web_invalidDate: 'Das Datum muss in folgendem Format sein: TT.MM.JJJJ',
  },
  prompts: {
    tryMode:
      'Du nutzt BISON nun im Demo-Modus. Hierin kannst du die App Features testen, indem du mit Spielgeld handelst.\n\nDein Portfolio sowie deine Transaktionsübersicht werden nicht gespeichert.',
    tryModePromptBtn: 'Verstanden',
    doKyc: `# Wechsel zu Echtgeld

  Du musst dich zunächst verifizieren, bevor du mit Echtgeld handeln kannst.`,

    doKycPending: `Wenn du die Verifizierung erfolgreich abgeschlossen hast, bitte beachte, dass die Bestätigung deiner Verifizierung in der BISON App mehrere Werktage dauern kann. In Ausnahmefällen werden wir uns per E-mail an dich wenden, um zusätzliche Unterlagen anzufordern.

Sobald wir deine Bestätigung erhalten haben, benachrichtigen wir dich per E-Mail.

Falls du dich in die Warteliste eingetragen hast und per SMS benachrichtigt wurdest, dass ein Mitarbeiter verfügbar ist, kannst du die Verifizierung jetzt starten.`,
    doKycBtn: 'Verifizierung starten',
    disabledFeature: {
      tryMode: `Du befindest dich im Demo-Modus. Diese Funktionalität ist nur im Echtgeld-Modus verfügbar.`,
      paperMode: `# Demo-Modus

  Du befindest dich im Demo-Modus. Diese Funktionalität ist nur im Echtgeld-Modus verfügbar.`,
    },
    realMoneyToggleBtn: 'Wechsel zu Echtgeld',
  },
  error: {
    genericNetwork: 'Netzwerk Zugriff konnte nicht hergestellt werden. Bitte probiere es später nochmal.',
    genericApp: 'Etwas ist schiefgelaufen. Bitte versuche es erneut.',
    priceHistory: 'Beim Abruf der Preis-Historie ist etwas schiefgelaufen.',
    priceTicker: 'Beim Abruf der Preise ist etwas schiefgelaufen.',
    portfolio: 'Beim Laden des Portfolios ist etwas schiefgelaufen.',
    orderHistory: 'Beim Laden der Transaktionsübersicht ist etwas schiefgelaufen.',
    inbox: 'Beim Laden deiner Inbox-Nachrichten ist etwas schiefgelaufen.',
    accessToken: 'Beim Validieren ist etwas schiefgelaufen.',
    tooManyAttempts:
      'Zu viele ungültige Versuche. Bitte versuche es später erneut oder setze dein Passwort zurück.',
  },
  intro: {
    biometricsHeader: 'Möchtest du {{type}} nutzen?',
    biometricsMain: 'Mit {{type}} kannst du dich schnell und sicher einloggen.',
    biometricsButton: '{{type}} aktivieren',
    notificationsHeader: 'Benachrichtigungen einschalten?',
    notificationsMain: 'Du erhältst direkte Benachrichtigungen zu deinem BISON-Konto.',
    notificationsButton: 'Benachrichtigungen zulassen',
    notificationsDisabledAlertHeader: 'Benachrichtigungen sind auf dem Gerät ausgeschalten',
    notificationsDisabledAlertMain: 'Bitte öffne zunächst die Einstellungen und erlaube Benachrichtigungen.',
    tutorialHeader: 'Möchtest du das Tutorial starten?',
    tutorialMain: 'Wir zeigen dir alle wichtigen Funktionen, damit du bereit für die App bist.',
    tutorialButton: 'Tutorial starten',
  },
  login: {
    welcomeMain: 'Kryptowährungen kaufen und verkaufen.',
    welcomeText: 'In Kooperation mit BISON von der Börse Stuttgart jetzt einfach in der finanzen.net App.',
    text: 'Anmelden',
    signup: 'Registrieren',
    tryMe: 'Demo',
    back: 'Zurück', //  Back
    rememberMe: 'Keep me signed in',
    form: {
      emailLabel: 'E-Mail',
      emailPlaceholder: 'z.B. max.mustermann@mail.de',
      passwordPlaceholder: 'Passwort',
      keepMeLoggedIn: 'Eingeloggt bleiben', // not used at the moment
      fieldErrors: {
        emailRequired: 'Bitte gib deine E-Mail-Adresse ein.',
        passwordRequired: 'Bitte gib dein Passwort ein.',
      },
    },
    loggingIn: 'Einloggen...',
    start: 'Jetzt starten',
    forgotPassword: 'Passwort vergessen?', //  Forgot password?
    welcome: 'Willkommen bei\nBISON', //  Welcome to\nBISON
    welcomeFirst: 'Willkommen',
    welcomeBack: 'Willkommen zurück',
    buttonPasscode: 'Passwort',
    buttonTouchID: 'Touch ID',
    buttonFaceID: 'Face ID',
    unlock: 'Entsperre BISON',
    logoutText: 'Logout',
    errorTitle: 'Login fehlgeschlagen',
    errorIncorrectCredentials: 'E-Mail-Adresse oder Passwort ist nicht korrekt. Bitte versuche es erneut.',
    errorClientKeyInvalid: 'Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.',
    errorUnconfirmedEmail: 'E-Mail-Adresse ist nicht bestätigt. Bitte bestätigen und erneut versuchen.',
    errorGeneric: 'Wir können dich leider momentan nicht einloggen. Bitte versuche es später erneut.',
    errorUnauthorized: 'E-Mail-Adresse oder Passwort ist nicht korrekt. Bitte versuche es erneut.',
    errorNoKyc:
      'Verifizierung ist noch nicht abgeschlossen. Bitte verifiziere dich zunächst und versuche es erneut.',
    errorTooManyAttempts: 'Zu viele ungültige Anmeldeversuche. Bitte versuche es später erneut.',
    fingerprintPrompt: 'Entsperre die App',
    fingerprintSaveHeader: 'Fingerabdruck verwenden?',
    fingerprintSavePrompt: 'Bestätige durch Fingerabdruck',
    fingerprintSaveSuccess: 'Gespeichert',
    fingerprintHint: 'Touch',
    fingerprintSuccess: 'Fingerabdruck erkannt',
    fingerprintNotRecognizedError: 'Fingerabdruck nicht erkannt, nochmal versuchen.',
    cancel: 'Abbrechen',
    cancelled: 'Authentifizierung wurde abgebrochen',
    error2fa: 'Ungültiger Sicherheits-Code. Bitte versuche es erneut.',
    pairing: {
      info: 'Du versuchst dich von einem neuen Gerät anzumelden.',
      sms: 'Zur Authentifizierung haben wir dir einen Sicherheits-Code per SMS an ',
      email: 'Zur Authentifizierung haben wir dir einen Sicherheits-Code per E-Mail an ',
      lastPart: ' gesendet.',
      pleaseEnter: 'Bitte gib diesen unten zur Bestätigung ein.',
      securityCode: 'Sicherheits-Code',
      securityCodePlaceholder: 'z.B. 123456',
      noAccess:
        'Wenn du keinen Zugang zu deiner Handynummer hast, wende dich bitte an den BISON Support unter',
      note: 'Hinweis: Du kannst dich immer noch mit deinen bisher verwendeten Geräten anmelden.',
      incorrectCode: 'Falsches Codeformat',
      codeRequired: 'Ein Bestätigungscode ist erforderlich, um fortzufahren.',
      resendCode: 'Sicherheits-Code erneut senden',
      resentCode: 'Wir haben dir einen Security-Code gesendet.',
      channels: {
        sms: 'SMS',
        email: 'E-Mail',
      },
      triesLeft: {
        one: 'Noch 1 Versuch.',
        many: 'Noch {{triesLeft}} Versuche.',
      },
    },
  },
  forgotPassword: {
    title: 'Passwort zurücksetzen',
    instructions: '',
    email: 'E-Mail',
    successTitle: 'Der Link zum Zurücksetzen des Passworts wurde an deine Adresse gesendet.',
    errorTitle: 'Anfrage fehlgeschlagen',
    sending: 'Senden...',
    send: 'Link zum Zurücksetzen anfordern',
    backToLogin: 'Zurück zum [Login](/login)',
    successScreen: {
      title: 'E-Mail gesendet',
      text1:
        'Wir haben dir eine E-Mail mit einem Bestätigungslink zum Zurücksetzen deines Passworts an folgende E-Mail-Adresse geschickt:',
      text2: 'Klicke auf den Link in der E-Mail um dein Passwort zurückzusetzen.',
    },
    errors: {
      noUser: 'Dieser Nutzer existiert nicht.',
      notConfirmed:
        'Diese E-Mail-Adresse ist noch nicht bestätigt. Bitte bestätige diese zuerst in deinem Mail-Postfach und versuche es erneut.',
      generic:
        'Entschuldige, wir können dein Passwort aktuell nicht ändern. Bitte versuche es später erneut.',
    },
  },
  resetPassword: {
    securityCodeStep: {
      title: 'Passwort zurücksetzen',
      mainText: `Um dein Passwort zu vergeben, benötigst du einen Sicherheitscode.\n\n Mit Klick auf den unten stehenden Button, wird dir der Sicherheitscode per SMS an deine bei uns hinterlegte Telefonummer **{{phoneNumber}}** gesendet.`,
      subText: `Solltest du keinen Zugang zu dieser Nummer haben, kontaktiere uns bitte unter [Kundenservice Formular]({{supportLink}}).`,
      sendSecurityCode: 'Sicherheitscode anfordern',
    },
    form: {
      title: 'Neues Passwort vergeben',
      text: 'Keinen Sicherheitscode erhalten?',
      resendSecurityCode: 'Sicherheitscode erneut anfordern.',
    },
    successStep: {
      title: 'Passwort erfolgreich angepasst!',
      mainText: `Dein Passwort für deinen BISON Account {{userEmail}} wurde erfolgreich angepasst.\n\n Du kannst dich jetzt mit deinen neuen Login Daten bei der BISON [einloggen](/login).`,
    },
  },
  registration: {
    heading: 'Hey, BISON freut sich, dass du ein Teil der Herde werden möchtest.',
    form: {
      createAccount: 'Account erstellen',
      creating: 'Erstellen...',
      emailPlaceholder: 'E-Mail',
      passwordPlaceholder: 'Passwort',
      successTitle: 'Anmeldung erfolgreich',
      errorTitle: 'Anmeldung fehlgeschlagen',
      referralCodeLabel: 'Dein Referral Code',
      referralInfo:
        'Der Freunde-einladen Code besteht aus sechs Zeichen und ist eine Kombination aus Buchstaben und Ziffern. Der Code befindet sich am Ende deines Einladungslinks. Beachte: hier keinen Gutscheincode eingeben.',
      referralCodeError: 'Ungültiger Freunde-einladen Code',
      newsletter: 'Ja, ich möchte mich zum Newsletter BISONews anmelden',
      info: {
        a: 'Es gelten die ',
        b: 'AGB',
        c: ' und die ',
        d: 'Datenschutzerklärung',
        e: '.',
      },
      existingUser: 'Already have an account?',
    },
    back: 'Zurück',
    passwordStrength: 'Passwort-Stärke:',
    passwordStrengthStrings: {
      0: 'Sehr schwach', // score 0
      1: 'Sehr schwach', // score 1
      2: 'Durchschnitt', // score 2
      3: 'Durchschnitt', // score 3
      4: 'Sehr stark', // score 4
    },
    errorUsernameTaken: 'Dieser Name ist bereits vergeben. Bitte probiere es mit einem anderen.',
    errorCannotSendEmail:
      'Wir konnten keine E-Mail an diese E-Mail-Adresse senden. Bitte verwende eine andere.',
    errorGeneric: 'Entschuldige, wir können dich gerade nicht registrieren. Versuche es später erneut.',
    errorTooManyRetries: {
      smallBlock:
        'Entschuldigung, das Senden deines Sicherheitscodes hat leider nicht geklappt. Bitte versuch es in einer Minute noch einmal.',
      mediumBlock:
        'Entschuldigung, das Senden deines Sicherheitscodes hat leider nicht geklappt. Bitte versuch es in 10 Minuten noch einmal.',
      bigBlock:
        'Entschuldigung, das Senden deines Sicherheitscodes hat leider nicht geklappt. Bitte versuch es morgen noch einmal.',
    },
    confirmTitle: 'E-Mail-Adresse bestätigen',
    emailSent: 'Wir haben eine Bestätigungs-E-Mail an folgende Adresse gesendet: {{email}}',
    waitingForConfirmation: {
      heading: 'Überprüfe deine E-Mail!',
      a: 'Klicke auf den Link in der E-Mail, um deine E-Mail-Adresse zu bestätigen.',
      b: `Überprüfe bitte auch dein Spam-Postfach.`,
    },
    spam: 'Solltest du die E-Mail in deinem Postfach nicht finden, überprüfe bitte dein Spam-Postfach.',
    successResendConfirmationMailTitle: 'Wir haben dir eine Bestätigungs-E-Mail gesendet.',
    successResendSecurityCode: 'Wir haben dir einen Security-Code gesendet.',
    resendConfirmationMail: 'E-Mail nochmal senden',
    resendingConfirmationMail: 'Senden...',
    errorResendConfirmationMailTitle: 'Das Senden der E-Mail ist fehlgeschlagen.',
    errorResendConfirmationMail:
      'Entschuldige, wir können dir aktuell keine E-Mail senden. Bitte versuche es später erneut.',
    errorSecurityCode:
      'Entschuldige, wir können dir aktuell keinen Security-Code senden. Bitte versuche es später erneut.',
  },
  emailConfirm: {
    title: 'Email Bestätigung',
    success: {
      title: 'Herzlichen Glückwunsch!',
      text: `Wir freuen uns, dass du nun Teil von BISON bist! 

      Deine E-Mail-Adresse {{email}} wurde von BISON erfolgreich bestätigt.
      
      Du kannst dich jetzt mit deinen Login Daten bei BISON einloggen!`,
      button: 'Starten',
    },
    error: {
      title: 'Oops!',
      text: `Das BISON konnte deine E-Mail-Adresse mit dem eben genutzten Bestätigungslink nicht verifizieren.\n\n Bitte versuche es erneut bei BISON.\n\nSollte weiterhin zu einer Fehlermeldung kommen sind wir über [Kundenservice Formular]({{supportLink}}) für dich erreichbar.`,
    },
  },
  emailChangeConfirm: {
    title: `E-Mail-Änderungsbestätigung`,
    newEmail: 'Neue E-Mail-Adresse',
    currentEmail: 'Alte E-Mail-Adresse',
    success: {
      title: 'Erfolg!',
      text: 'Ihre E-Mail-Adresse wurde erfolgreich geändert.',
      button: 'Anmelden',
    },
    error: {
      title: 'Oops!',
      text: `Etwas ist schief gelaufen.\n\n Bitte versuche es erneut bei BISON.\n\n Sollte weiterhin zu einer Fehlermeldung kommen sind wir über [Kundenservice Formular]({{supportLink}}) für dich erreichbar.`,
    },
  },
  mobileNotification: {
    welcome: 'Willkomen!',
    recommendation: 'Auf mobilen Geräten empfehlen wir dir die BISON-App.',
    switchToApp: 'Jetzt in die App wechseln',
    continueWeb: 'Weiter mit Web-Version',
  },
  home: {
    currency: {
      balance: 'Balance',
      positions: 'Positionen',
      latestPrice: 'Aktueller Kurs',
      createSavingsPlan: 'Sparplan anlegen',
      buyBtnLabel: 'Kaufen',
      buyLimitLabel: 'Limit KAUF',
      sellBtnLabel: 'Verkaufen',
      sellLimitLabel: 'Limit VERKAUF',
      buyInputLabel: 'Kaufwert',
      sellInputLabel: 'Verkaufswert',
      buyOrderTitle: `{{currency}} kaufen`,
      sellOrderTitle: `{{currency}} verkaufen`,
      buyBtn: 'Verbindliche Kaufanfrage senden', // Buy {{entityName}}
      sellBtn: 'Verbindliche Verkaufsanfrage senden', // Sell {{entityName}}
      buyBtnPaper: 'Kaufanfrage senden',
      sellBtnPaper: 'Verkaufsanfrage senden',
      midPrice: 'Aktueller Preis',
      price: 'Preis',
      currentPurchasePrice: 'Aktueller Kaufpreis',
      currentSellingPrice: 'Aktueller Verkaufspreis',
      showAll: 'Alles anzeigen',
      timePeriods: {
        hour: '1H',
        threeHours: '3H',
        day: '1T',
        week: '1W',
        month: '1M',
        year: '1J',
        max: 'MAX',
      },
      sortingValues: {
        MostTraded: 'Meist gehandelt',
        HighestTradeVolume: 'Höchstes Handelsvolumen',
        Winner: 'Gewinner',
        Loser: 'Verlierer',
      },
      assetTypes: {
        Crypto: 'Krypto',
        Stock: 'Aktien',
        Etf: 'ETFs',
        Commodity: 'Rohstoffe',
        LongShort: 'Long/Short',
        Etn: 'ETNs',
        Etc: 'ETCs',
      },
      searchBar: {
        reviewAssets: 'Assets durchsuchen',
      },
      noResultsFound: {
        crypto: {
          title: 'Keine Kryptos gefunden',
          text: 'Versuchs doch mal mit dem <strong>Kürzel der<br/> Währung</strong> oder in einer anderen <strong>Kategorie</strong>.',
        },
        stock: {
          title: 'Keine Aktien gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>,der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        etf: {
          title: 'Keine ETFs gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        etn: {
          title: 'Keine ETNs gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        etc: {
          title: 'Keine ETCs gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        commodity: {
          title: 'Keine Rohstoffe gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        'long/short': {
          title: 'Keine Long/Short Produkte gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        Etn: {
          title: 'Keine ETNs gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        Etc: {
          title: 'Keine ETCs gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        Commodity: {
          title: 'Keine Rohstoffe gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
        'Long/short': {
          title: 'Keine Long/Short Produkte gefunden',
          text: 'Versuchs doch mal in einer anderen <br/> <strong>Kategorie</strong>, der <strong>ISIN</strong> oder <strong>WKN</strong>.',
        },
      },
      returnWaiverAT:
        'Es besteht kein Rücktrittsrecht, da der Preis von Kryptowährungen erheblichen Wertschwankungen unterliegen kann, auf die wir keinen Einfluss haben.',
      currentTimePeriod: 'Aktueller Preis / Letzte {{currentTimePeriod}}', // Mid Price / Last {{currentTimePeriod}}
      currentTimePeriodShort: 'Letzte {{currentTimePeriod}}', // Last {{currentTimePeriod}}
      timeSinceInstant: 'Seit {{instantTime}}', // Since {{instantTime}}
      yourBuyPrice: 'Du kaufst für', // Your buy price
      yourSellPrice: 'Du verkaufst für', // Your sell price
      buyPrice: '{{currency}} Kaufpreis', // Buy price
      sellPrice: '{{currency}} Verkaufspreis', // Sell price
      confirmBuy: 'Verbindliche Kaufanfrage bestätigen', // Confirm buy
      confirmSell: 'Verbindliche Verkaufsanfrage bestätigen', // Confirm sell
      confirmBuyPaper: 'Kaufanfrage bestätigen', // Confirm buy
      confirmSellPaper: 'Verkaufsanfrage bestätigen', // Confirm sell
      confirmBuyRequest: 'Verbindliche Kaufanfrage bestätigen',
      confirmSellRequest: 'Verbindliche Verkaufsanfrage bestätigen',
      confirm: 'Bestätigen',
      cancel: 'Abbrechen',
      conversionRate: '1 {{fiatCurrencyShortName}} = {{conversionRate}} {{currencyShortName}}',
      youAreReceiving: 'Du erhältst', // You are receiving
      youAreGiving: 'Du bezahlst', // You are giving
      offerValidFor: 'Wir haben den Preis für dich reserviert.\n{{secondsLeft}} Sekunden.', // This offer is valid for {{secondsLeft}} more seconds.
      offerExpired: 'Dein Angebot ist abgelaufen. Bitte erneut anfragen.', // Offer expired, close the popup to reserve a new price.
      minValueLabel: 'min', // Min
      maxValueLabel: 'max',
      notEnoughCurrencyToBuy: 'Du hast nicht genügend verfügbare Euro.', // Not enough currency in your wallet
      notEnoughVolumeToSell: 'Du hast nicht genügend verfügbare {{entityName}}', // Not enough crypto to sell
      maxTradeValue: 'Das maximale Investitionsvolumen pro Trade beträgt {{maxTradeValue}} €',
      orderSuccessTitle: 'Geschafft, ausgeführt!', // Order executed
      orderFailTitle: 'Uuups, das ging nicht durch...', // Order failed
      back: 'Versuch´s erneut', // 'Try again',  // To buy or sell screen
      goToPortfolio: 'Portfolio', // See your new portfolio
      goToMarket: 'Marktübersicht',
      successBuy: 'Du hast für {{value}} \n {{volume}} {{cryptoShortName}} gekauft.', // You have succesfully added {{volume}} {{cryptoShortName}} to your portfolio.
      successSell: 'Du hast für {{value}} \n {{volume}} {{cryptoShortName}} verkauft.', // You have succesfully sold {{volume}} {{cryptoShortName}}.
      orderFail: 'Leider hat es gerade nicht geklappt.', // Sorry, we could not process your order at this time.
      reservingPriceBtnTitle: 'Reserviere...',
      popupPaperTradingWarning: 'Bitte beachte: Du handelst momentan mit Spielgeld',
      tradingClosedTitle: 'Handel geschlossen',
      tradingClosed: `# Handel geschlossen

Leider kannst du gerade keine Kryptowährungen kaufen und verkaufen.\nUnsere Handelszeiten sind: Montag - Sonntag {{from}} - {{to}} Uhr`,
      genericErrors: {
        errorTitle: 'Fehler beim Kauf/Verkauf',
        initOrderError: 'Bei der Abfrage der Preise ist ein Fehler aufgetreten.',
        refreshPriceError: 'Beim Laden der Preise ist ein Fehler aufgetreten.',
        reserveOrderError: 'Beim Reservieren deines Auftrags ist ein Fehler aufgetreten.',
      },
      xrpDisabled: `Ripple (XRP) kann aktuell noch nicht gehandelt werden. Wir arbeiten daran euch das Kaufen und Verkaufen von Ripple (XRP) schnellstmöglich zur Verfügung zu stellen und informieren euch sobald dies möglich ist.`,
      tradingVolume: 'Handelsvolumen',
      tradingVolumeDescription: 'Vergleich des BISON Handelsvolumens, aufgeteilt in Käufe und Verkäufe.',
      tradingVolumeGauge: {
        sell: 'Verkäufe',
        buy: 'Käufe',
        notAvailable: 'Daten derzeit nicht verfügbar.',
        last24h: 'Letzte 24h',
        previous24h: 'Vorherige 24h',
      },
      chartCaption: 'Letzte ',
      chartNotAvailableMessage:
        'Der Chart kann zur Zeit nicht angezeigt werden. Unser Team arbeitet an der Behebung des Problems',
      maxPeriod: 'verfügbare Historie',
      indicators: {
        title: 'Indikatoren',
        apply: 'Anwenden',
        clearAll: 'Alle löschen',
        addIndicators: 'INDIKATOREN ({{count}})',
        sma: {
          title: 'Simple moving average (SMA)',
          option: 'SMA {{period}} Perioden',
          info: `# Simple Moving Average
          
Der Simple Moving Average oder Einfacher Gleitender Durchschnitt (kurz: SMA) ist der durchschnittliche Kurs einer Kryptowährung über eine bestimmte Zeitspanne hinweg.

In der App bieten wir dir folgende Optionen zur Auswahl:

SMA (10) = SMA 10 Perioden\n
SMA (20) = SMA 20 Perioden\n
SMA (50) = SMA 50 Perioden\n
SMA (100) = SMA 100 Perioden

Für die Berechnung des Einfachen Gleitenden Durchschnitts (10, 20, 50, 100) werden je nach ausgewählter Zeitspanne folgende Perioden verwendet:

12M: 1 Periode =  1 Tag\n
30D: 1 Periode =  2 Stunden\n
7D: 1 Periode = 30 Minuten\n
1D: 1 Periode =  5 Minuten\n
3H: 1 Periode = 30 Sekunden

Bitte beachte: Der Simple Moving Average stellt keine Handelsempfehlung dar, sondern soll dir als Informationsquelle dienen und deine Investmententscheidung in den Kryptomarkt zusätzlich unterstützen.

[__Mehr Informationen__](https://tradistats.com/einfacher-gleitender-durchschnitt)`,
        },
        rsi: {
          title: 'Relative strength index (RSI)',
          option: 'RSI {{period}} Perioden',
          info: `# Relative Strength Index

Der Relative Strength Index oder Relative-Stärke-Index (kurz: RSI) ist ein Indikator, der dich dabei unterstützt, überkaufte und überverkaufte Bereiche im Markt zu identifizieren. Der Wert bewegt sich dabei zwischen 0 und 100.

Ein Wert von 30 oder darunter zeigt dabei an, das eine Kryptowährung unterbewertet ist. Das bedeutet, dass ihr Kurs stärker gefallen ist, als der Markt erwartet hat. Damit ist die Kryptowährung überverkauft.

Liegt der Wert bei mindestens 70, ist eine Kryptowährung überbewertet, also überkauft. Der Kurs ist in diesem Fall stärker gestiegen, als vom Markt prognostiziert.

In der App bieten wir dir folgende Optionen zur Auswahl:

RSI (10) = RSI 10 Perioden\n
RSI (20) = RSI 20 Perioden\n
RSI (50) = RSI 50 Perioden\n
RSI (100) = RSI 100 Perioden

Für die Berechnung des Relative-Stärke-Index (10, 20, 50, 100) werden je nach ausgewählter Zeitspanne folgende Perioden verwendet:

12M: 1 Periode = 1 Tag\n
30D: 1 Periode = 2 Stunden\n
7D: 1 Periode = 30 Minuten\n
1D: 1 Periode = 5 Minuten\n
3H: 1 Periode = 30 Sekunden

Bitte beachte: Der Relative Strength Index stellt keine Handelsempfehlung dar, sondern soll dir als Informationsquelle dienen und deine Investmententscheidung in den Kryptomarkt zusätzlich unterstützen.

[__Mehr Informationen__](https://www.finanzen.net/lexikon/chartanalyse/rsi)`,
        },
      },
    },
    news: {
      title: 'Nachrichten',
      info: `# Nachrichten

Die Artikel wurden von Event Registry bereitgestellt. Dieser Inhalt stellt keine Handelsempfehlung dar, sondern soll deine Investmententscheidung in den Kryptomarkt zusätzlich unterstützen und wird nur zu Informationszwecken zur Verfügung gestellt.      
`,
      cta: 'Mehr Nachrichten Anzeigen',
      showLessNews: 'Weniger Nachrichten anzeigen',
      emptyFeed: {
        de: 'deutschsprachigen',
        en: 'englischsprachigen',
      },
      empty:
        'Im {{feed}} Newsfeed gibt es aktuell keine neuen Nachrichten. Schau doch mal im englischen {{another}} vorbei.',
    },
    portfolio: {
      fullValue: 'Portfolio Wert', // Portfolio value
      openPL: 'Veränderung', // Open positions P/L
      cash: 'Euro Wert', // Available cash
      closedPL: 'Realisierte GuV', // Closed positions P/L
      changePL: 'Veränderung P/L',
      openPLLabel: 'Offene GuV',
      openPLLabelSpecial: 'Offene GuV*',
      openPLDetails: '*Der Wert, den du gewinnen oder verlieren würdest, wenn du jetzt verkaufst.',
      realisedPL: 'Realisierte GuV**',
      realisedPLDetails:
        '**Gewinne oder Verluste, die du bereits durch Verkäufe generiert hast. Beachte: Der Wert beinhaltet aktuell nur die Werte deiner Krypto-Trades. Wir arbeiten an einer Erweiterung dieses Features. ',
      invested: 'Investiert', // Invested
      inStaking: 'Im Staking',
      deposit: 'Einzahlen',
      stake: 'Staken',
      buy: 'Kaufen',
      totalRewards: 'Gesamte Rewards',
      value: 'Aktueller Wert', // Value
      cashBoxLabel: 'Guthaben',
      PL: 'Veränderung', // P/L
      amountAbbreviation: 'Anz.',
      emptyPortfolio: 'Keine Kryptowährungen in deinem Portfolio vorhanden.', // No cryptos in your portfolio, yet!
      emptyPortfolioFirst: 'Du hast noch keine Kryptowährungen gekauft. Tätige deinen ersten Kauf.', // No cryptos in your portfolio, yet!
      emptyPortfolioCTAButton: 'Kryptowährungen kaufen',
      noCash: 'Du hast keine verfügbaren Euro.',
      noCashCTAButton: 'Geld einzahlen',
      noCryptoCTAButton: 'Kryptowährungen einzahlen',
      totalValue: 'Gesamtwert',
      web_last7D: 'Letzte 7 Tage',
      donutEuroOnlyLabel: 'Guthaben',
      available: 'Verfügbar',
      infoPopup: {
        availableCash: 'Frei verfügbar',
        blockedCashBySOAndLO: 'Geblockt durch offene Stop & Limit Orders',
        blockedCashByStockBuy: 'Geblockt durch ausstehende Käufe',
        blockedCashByStockSell: 'Geblockt durch offene Verkäufe',
        blockedCash: 'Geblockt durch offene Limit Orders (Kauf)',
        blockedByStaking: 'Geblockt durch Staking',
        availableCrypto: 'Frei verfügbar',
        blockedCrypto: 'Geblockt durch offene Limit Orders (Verkauf)',
        portfolioValue: 'Gesamt',
        available: 'Verfügbar',
        euroValue: 'Gesamt',
        solarisLegalText:
          'Hinweis: Dein Guthabenkonto wird von der Solaris SE zur Verfügung gestellt. Mehr dazu unter <1>Kryptowährungen</1>.',
      },
      emptyState: {
        title: `Baue dein Krypto-Portfolio auf!`,
        body: `Zahle Geld auf dein Konto ein und füge deinem Portfolio die ersten Kryptowährungen hinzu.`,
        web_alreadyHaveCrypto: `Du hast schon Krypto?`,
        lineChartButton: 'Jetzt Geld einzahlen',
        pieChartButton: 'Geld einzahlen',
      },
      emptyAssets: {
        crypto: {
          title: 'Kryptowährungen',
          mainText: 'Hier werden deine Kryptowährungen angezeigt, sobald du welche gekauft hast.',
          buttonText: 'Jetzt Kryptos handeln',
        },
        stock: {
          title: 'Aktien',
          mainText: 'Hier werden deine Aktien angezeigt, sobald du welche gekauft hast.',
          buttonText: 'Jetzt Aktien handeln',
        },
        others: {
          title: 'ETFs & Co.',
          mainText: 'Hier werden deine ETFs & Co. angezeigt, sobald du welche gekauft hast.',
          buttonText: 'Jetzt ETFs & Co. handeln',
        },
      },
      disclaimer: {
        text: 'Die angegebenen Kurse werden als Geldkurs durch unseren Datenprovider Factset angezeigt. Die Renditeberechnung bezieht sich daher auf den Geldkurs und aufgeführte Orderprovisionen, Steuern oder weitere Kosten können die Rendite beeinflussen. Alle angezeigten Daten sind Indikationen, die aufgrund von Verbindungsverzögerungen veraltet sein können.',
      },
      chart: {
        others: 'Andere',
      },
    },
    orderHistory: {
      type: 'Typ',
      crypto: 'Krypto',
      emptyPendingOrders: 'Aktuell keine Transaktionen in Bearbeitung.',
      emptyOrderHistory: 'Keine Transaktionen vorhanden.',
      emptyOrderHistoryTwoFilters: 'Keine {{subtype}}-Transaktion für {{crypto}} vorhanden.',
      emptyOrderHistoryOneFilterCrypto: 'Keine {{crypto}}-Transaktion vorhanden.',
      emptyOrderHistoryOneFilterSubtype: 'Keine {{subtype}}-Transaktion vorhanden.',
      headerList: {
        date: 'DATUM',
        type: 'TYP',
        currency: 'WÄHRUNG',
        amount: 'MENGE',
      },
      inProgress: 'In Bearbeitung',
      completed: 'Abgeschlossen',
      cryptoFilterModal: {
        title: 'Kryptowährung wählen',
        text: 'Wähle eine Kryptowährung aus, nach der du deine Aktivität filtern möchtest:',
        action: 'Filter anwenden',
      },
      orderTypes: {
        all: 'Alle',
        buy: 'Käuf',
        sell: 'Verkäuf',
        deposit: 'Einzahlungen',
        withdraw: 'Auszahlungen',
        depositsAndWithdrawals: 'Ein- & Auszahlungen',
        crypto: 'Kryptofilter',
        corporateactiondecrease: 'Negative Dividenden Ausschüttung',
        corporateactionincrease: 'Dividenden Ausschüttung',
        limitOrder: {
          buy: 'Limit Kauf',
          sell: 'Limit Verkauf',
        },
        emptyState: {
          statusInProgress: {
            All: 'Aktuell keine Transaktionen in Bearbeitung.',

            nonStocksAll: 'Aktuell keine Transaktionen in Bearbeitung.',

            Buy: 'Aktuell keine Käufe in Bearbeitung.',

            Sell: 'Aktuell keine Verkäufe in Bearbeitung.',

            DepositWithdraw: 'Aktuell keine Ein- oder Auszahlungen in Bearbeitung.',
          },
          complete: {
            All: `
Sobald du deine erste Einzahlung getätigt hast, wird sie hier angezeigt.

Käufe, Verkäufe, Ein- und Auszahlungen sowie Dividendenausschüttungen werden hier ebenfalls aufgelistet.
            `,

            nonStocksAll: `
Sobald du deine erste Einzahlung getätigt hast, wird sie hier angezeigt. 

Käufe, Verkäufe, Ein- und Auszahlungen werden hier ebenfalls aufgelistet.
            `,

            Buy: `
Sobald du deinen ersten Kauf getätigt hast, wird dieser hier angezeigt.

Getätigte Verkäufe sowie Ein- & Auszahlungen findest du in den anderen Kategorien.
            `,

            Sell: `
Sobald du deinen ersten Verkauf getätigt hast, wird dieser hier angezeigt.

Getätigte Käufe sowie Ein- & Auszahlungen findest du in den anderen Kategorien.
            `,

            DepositWithdraw: `
Sobald du deine erste Einzahlung getätigt hast, wird diese hier angezeigt.

Getätigte Käufe oder Verkäufe findest du in den anderen Kategorien.
            `,
          },
        },
        stopOrder: {
          buy: 'Kauf (Stop)',
          sell: 'Verkauf (Stop)',
        },
        savingsPlan: {
          buy: 'Sparplan',
        },
        staking: {
          stake: 'Staken',
          unstake: 'Entstaken',
          stakingReward: 'Staking reward',
        },
      },
      emptySubtypeWording: {
        buy: 'Kauf',
        sell: 'Verkaufs',
        deposit: 'Einzahlungs',
        withdraw: 'Auszahlungs',
      },
      rewardTypes: {
        coupon: 'Gutschein',
        referral: 'Empfehlung',
      },
      transactionPendingInfo:
        'Du wirst informiert, sobald die Kryptowährung deinem BISON Konto gutgeschrieben wurde.',
      cryptoDepositLabel: 'Krypto-Einzahlung',
      refresh: 'Aktualisieren',
      report: {
        title: 'Suchst du den Info-Report?',
        mainText:
          'Info-Reports, Kontoauszüge sowie die Transaktionshistorie zum Herunterladen findest du in deinem Konto unter “Reports”.',
        button: 'Zu meinen Reports',
      },
      detail: {
        amount: 'Anzahl',
        marketPrice: 'Kaufpreis je Einheit',
        orderFee: 'Orderprovision',
        fee: 'Gebühr',
        buy: 'Kaufpreis',
        sell: 'Verkaufspreis',
        sellPending: '(abzgl. Steuern)',
        calculating: '...wird berechnet',
        legalText:
          'Alle Angaben ohne Gewähr. Die Kosteninformationen und deine Abrechnung findest du in deinem <1>Posteingang</1>.',
        isin: 'ISIN',
        venue: 'Handelsplatz',
        date: 'Datum & Uhrzeit',
        corporateactionincrease: 'Gesamtausschüttung',
        corporateactiondecrease: 'Gesamtausschüttung',
        wallet: 'Wallet',
        destinationTag: 'Destination Tag',
        depositMethod: 'Einzahlungsmethode',
        instantSepaType: 'Echtzeitüberweisung',
        dividend: 'Dividende pro Stück',
        instantDepositFee: 'Gebühr Sofort Einzahlung',
      },
    },
    ok: 'OK',
    cancel: 'Abbrechen', // Cancel
  },
  automation: {
    newPriceLimit: 'Neue Limit Order',
    web_newPriceLimit: 'Limit Order Regel anlegen',
    deletePriceLimit: 'Löschen',
    deletePriceLimitSuccess: 'Deine Limit Order wurde erfolgreich gelöscht.',
    deletePriceLimitError:
      'Beim Löschen deiner Limit Order ist etwas schief gelaufen. Bitte versuche es erneut.',
    selectYourCrypto: 'Limit Order erstellen',
    limitBuy: 'Limit Kauf',
    limitSell: 'Limit Verkauf',
    stopBuy: 'Stop Kauf',
    stopSell: 'Stop Verkauf',
    web_selectCrypto: 'Wähle deine Krypto',
    cryptoModalSubtitle:
      'Wähle die Währung aus für die du eine Limit Order anlegen möchtest. Entscheide dann ob es eine Limit Kauf oder Limit Verkauf Regel sein soll.',
    detail: {
      title: {
        Buy: 'Limit Kauf Regel',
        Sell: 'Limit Verkauf Regel',
      },
      subTitle: {
        Buy: 'Limit Kauf',
        Sell: 'Limit Verkauf',
      },
      overview: 'Übersicht',
      setPriceLimit: 'Limit Preis',
      priceLimit: 'Preislimit',
      amount: {
        Buy: {
          cryptoAmount: 'Anzahl (indikativ)',
          euroAmount: 'Betrag',
        },
        Sell: {
          cryptoAmount: 'Anzahl',
          euroAmount: 'Betrag (indikativ)',
        },
      },
      validUntil: 'Gültig bis',
      validInDays: '({{days}} Tage)',
      web_buySummaryText: `Wenn der Kaufpreis bei <1>{{price}}</1> oder niedriger ist, möchte ich <3>{{amount}}</3>
      kaufen und insgesamt nicht mehr als <5>{{euroTotal}}</5> ausgeben. Die Regel ist bis zum <7>{{shownDate}}</7> <9>{{daysAmount}}</9> gültig.`,
      web_sellSummaryText: `Wenn der Verkaufspreis bei <1>{{price}}</1> oder höher ist, möchte ich <3>{{amount}}</3>
      verkaufen und mindestens <5>{{euroTotal}}</5> erhalten. Die Regel ist bis zum <7>{{shownDate}}</7> <9>{{daysAmount}}</9> gültig.`,
      delete: 'Order löschen',
    },
    currencyInputLabel: {
      buy: 'Krypto kaufen',
      sell: 'Krypto verkaufen',
    },
    legalText: `Verbindliche {{buyOrSell}} mit Preislimit senden`,
    web_legalText: 'Eine Handelsregel bietet keine Garantie für eine Ausführung.',
    buyInquiry: 'Kaufanfrage',
    sellInquiry: 'Verkaufsanfrage',
    confirmDescriptionLabelBuy:
      'Die Handelsregel wird verbindlich angelegt und eine Kaufanfrage übermittelt, wenn das oben genannte Szenario eintritt.',
    confirmDescriptionLabelSell:
      'Die Handelsregel wird verbindlich angelegt und eine Verkaufsanfrage übermittelt, wenn das oben genannte Szenario eintritt',
    web_deleteDescriptionLabel: 'Verbindliche Kaufanfrage mit Preislimit löschen',
    confirmTradeRuleBtnLabel: 'OK, bestätigen',
    sendTradeRuleBtnLabel: 'OK, senden',
    web_buySendTradeRuleBtnLabel: 'Limit Kauf Regel anlegen',
    web_sellSendTradeRuleBtnLabel: 'Limit Verkauf Regel anlegen',
    fieldErrors: {
      web_required: 'Ein Betrag ist erforderlich',
      web_zero: 'Bitte trag einen höheren Betrag ein',
      web_notEnoughCurrency: 'Nicht genug Euro auf deinem Konto',
      web_notEnoughCrypto: 'Nicht genug {{crypto}} auf deinem Konto',
    },
    successTitle: 'Limit Order übermittelt!',
    web_buySuccessTitle: 'Limit Kauf Regel angelegt',
    web_sellSuccessTitle: 'Limit Verkauf Regel angelegt',
    successText: `Deine {{buyOrSell}} über {{amount}} mit einem Preislimit von {{limitPrice}} wurde erfolgreich übermittelt.

    Du erhältst eine Benachrichtigung, sobald deine {{buyOrSell}} ausgeführt wird.`,

    web_successText: `Deine {{buyOrSell}} über <strong>{{amount}}</strong> mit einem Preislimit von <strong>{{limitPrice}}</strong> wurde erfolgreich übermittelt.
    Du erhältst eine Benachrichtigung, sobald deine {{buyOrSell}} ausgeführt wird.`,
    buyRequest: 'Kaufanfrage',
    sellRequest: 'Verkaufsanfrage',
    failTitle: 'Limit Order abgelehnt!',
    errors: {
      generic: 'Es konnte keine Limit Order erstellt werden.',
      buyLimitReached: `Erklärung: BISON hat bereits sein maximales Limit für die Annahme von Kaufanfragen mit einem von Preislimit {{limitPrice}} erreicht. Bitte versuche es später erneut.`,
      sellLimitReached: `Erklärung: BISON hat bereits sein maximales Limit für die Annahme von Verkaufsanfragen mit einem von Preislimit {{limitPrice}} erreicht. Bitte versuche es später erneut.`,
    },
    warnings: {
      buyPriceTooGood:
        'Deine Kaufanfrage wird voraussichtlich sofort ausgeführt, da das Preislimit über dem aktuellen Kaufpreis liegt.',
      sellPriceTooGood:
        'Deine Verkaufsanfrage wird voraussichtlich sofort ausgeführt, da das Preislimit unter dem aktuellen Verkaufspreis liegt.',
    },
    info: {
      web_title: `Limit Order`,
      web_content: `
Du kannst eine Geschäftsanfrage mit Preislimit (Limit Order) verwenden, um eine bestimmte Stückzahl von Kryptowährungen zu einem vorher von dir festgelegten oder besseren Preis zu kaufen oder zu verkaufen. 

Eine erfolgreich übermittelte, jedoch noch nicht ausgeführte, Limit Order blockt den voraussichtlichen Gegenwert deines Geschäftes. Die geblockten Bestände werden dir in deinem Portfolio angezeigt. 
    
Die Limit-Order wird ausgeführt, wenn das zuvor festgelegte Preislimit erreicht wird. Hierfür sind die Verkaufspreise (Limit Verkauf) bzw. Kaufpreise (Limit Kauf) maßgeblich. In Einzelfällen können nicht alle ausführbaren Limit Orders ausgeführt werden, weil kein indikativer Preis gebildet werden kann. Diese verbleiben in diesen seltenen Fällen im Vorhaltebestand. Jede Anfrage mit Preislimit hat eine Gültigkeitsdauer von 90 Tagen. Am Ende der Gültigkeit verfällt deine Limit Order und wird gelöscht.

Wir benachrichtigen dich, sobald deine Limit Order ausgeführt wurde und du die Kryptowährungen gekauft bzw. verkauft hast. 

Wenn deine Limit Order noch nicht ausgeführt wurde, dann kannst du diese jederzeit im Menüpunkt “Trading Manager” verfolgen oder löschen.
    `,
    },
    emptyState: {
      title: 'Handelsregeln',
      body: `Keine Zeit ständig den Kryptomarkt zu beobachten? Kaufe oder Verkaufe automatisch mit Limit-Orders.`,
      info: {
        web_title: `Handelsregeln`,
        web_content: `
Wir bieten aktuell zwei Typen von Handelsregeln an:

### Limit Order
Buy Limit Regel: Einsteigen, um günstigere Kurse mitzunehmen. Du legst den niedrigeren Preis fest zu dem du kaufen möchtest. Ein Kauf wird aus­geführt, sobald dieser Preis erreicht ist. 

Sell Limit Regel: Aussteigen, um gelaufene Gewinne mitzunehmen. Du legst den höheren Preis fest, zu dem du verkaufen möchtest. Ein Verkauf wird ausgeführt, sobald dieser Preis erreicht ist.

### Stop Order
Stop Buy Regel: Einsteigen, um an Marktaufwärtstrends teilzuhaben. Du kaufst, wenn eine Kryptowährung einen von dir festgelegten Preis erreicht oder überschreitet.

Stop Loss Regel: Aussteigen, um Verluste zu begrenzen. Du verkaufst du, wenn der von dir festgelegte Preis eine niedrigere Marke durchbricht.

Eine übermittelte, aber noch nicht ausgeführte Stop Order, blockt den voraussichtlichen Gegenwert deines Geschäftes. Geblockte Bestände kannst du in deinem Portfolio einsehen. Du kannst deine aktiven Handelsregeln jederzeit im Trading Manager einsehen oder löschen. Stop Orders haben eine Gültigkeitsdauer von 90 Tagen und werden nach Ablauf dieser Frist automatisch gelöscht. Wir benachrichtigen dich, sobald deine Stop Order ausgeführt wurde.
      `,
      },
    },

    web_allLimitOrders: 'Alle Limit Orders',

    web_creationAvailableFiat: 'Verfügbare Euro',
    web_creationAvailableCrypto: 'Verfügbare {{currency}}',
    web_creationTitle: '{{currency}} Limit Order',
    web_buy_creationTitle: 'Limit Kauf Regel anlegen',
    web_sell_creationTitle: 'Limit Verkauf Regel anlegen',
    web_creationBuyPrice: 'Kaufpreis',
    web_creationSellPrice: 'Verkaufspreis',
    web_deletePopupMain: 'Bist du sicher, dass du deine Limit Order löschen möchtest?',
    web_deletePopupConfirm: 'Jetzt Löschen',
    web_deletePopupCancel: 'Abbrechen',

    web_tableHeadType: 'Typ',
    web_tableHeadCrypto: 'Krypto',
    web_tableHeadLimitPrice: 'Preis',
    web_tableHeadAmountEur: 'Menge (EUR)',
    web_tableHeadAmountCry: 'Anzahl',
    web_tableHeadExpiry: 'Ablauf',
    web_tableHeadCurrentPrice: 'Aktueller Preis',

    web_tableBuyPrice: 'Aktueller Kaufpreis',
    web_tableSellPrice: 'Aktueller Verkaufspreis',

    web_tableExpiryField: '{{value}} Tage',
  },
  watchlist: {
    tabTitle: 'Watchlist',
    emptyList: 'Deine Watchlist ist leer.',
    emptyListHeader: 'Noch nichts vorhanden',
    last24h: 'Letzte 24H',
    errors: {
      title: 'Error on watchlist',
      addItem: 'Beim Hinzufügen des neuen Elements ist etwas schief gelaufen.',
      get: 'Fehler beim Abrufen der Informationen, versuchen Sie es später noch einmal.',
    },
    banner: {
      add: 'Du hast {{name}} erfolgreich zur Beobachtungsliste hinzugefügt',
      remove: 'Du hast {{name}} erfolgreich von der Beobachtungsliste entfernt',
    },
  },
  stopOrders: {
    tradingManager: 'Zum Stop & Limit orders',
    newPriceLimit: 'Neue Limit Order',
    deleteStopOrder: 'Löschen',
    deleteStopOrderSuccess: 'Deine Stop Order wurde erfolgreich gelöscht.',
    deleteStopOrderError:
      'Beim Löschen deiner Stop Order ist etwas schief gelaufen. Bitte versuche es erneut.',
    youHaveNoPriceLimitSet: 'Du hast keine Stop Order gesetzt',
    selectYourCrypto: 'Stop Order Regel anlegen',
    selectStopOrderCrypto: {
      web_title: 'Stop Order Regel anlegen',
      web_description:
        'Wähle die Währung aus für die du eine Stop Order anlegen möchtest. Entscheide dann ob es eine Stop Buy oder Stop Loss Regel sein soll.',
    },
    stopBuy: 'Stop Buy',
    stopLoss: 'Stop Loss',
    comingSoon: 'Demnächst verfügbar',
    tradingRule: 'Ordertyp',
    detail: {
      title: {
        buy: 'Stop Buy Regel',
        sell: 'Stop Loss Regel',
      },
      overview: 'Zusammenfassung',
      setStopPrice: 'Stop Preis',
      priceLimit: 'Preislimit',
      cryptoAmount: {
        buy: 'Anzahl (indikativ)',
        sell: 'Anzahl',
      },
      euroAmount: {
        buy: 'Betrag',
        sell: 'Betrag (indikativ)',
      },
      validUntil: 'Gültig bis',
      validInDays: '({{days}} Tage)',
      web_legalText:
        'Die Handelsregel wird verbindlich angelegt und eine {{inquiry}} übermittelt, wenn das oben genannte Szenario eintritt. Eine Handelsregel bietet keine Garantie für eine Ausführung.',
    },
    currencyInputLabel: {
      buy: 'Krypto kaufen',
      sell: 'Krypto verkaufen',
      spendEuro: 'Betrag',
      receiveEuro: 'Betrag',
    },
    buyInquiry: 'Kaufanfrage',
    sellInquiry: 'Verkaufsanfrage',
    confirmDescriptionLabelBuy:
      'Die Handelsregel wird verbindlich angelegt und eine Kaufanfrage übermittelt, wenn das oben genannte Szenario eintritt.',
    confirmDescriptionLabelSell:
      'Die Handelsregel wird verbindlich angelegt und eine Verkaufsanfrage übermittelt, wenn das oben genannte Szenario eintritt.',
    confirmStopOrderBtnLabel: {
      buy: 'Stop Buy Regel anlegen',
      sell: 'Stop Loss Regel anlegen',
    },
    tradingPriceDescription: {
      buy: 'Aktueller Kaufpreis',
      sell: 'Aktueller Verkaufspreis',
    },
    stopOrderDescription: {
      buy: {
        a: 'Wenn der Kaufpreis bei ',
        b: 'oder höher liegt, möchte ich zum nächsten verfügbaren Preis für ',
        c: 'bis zu ',
        d: 'kaufen. Die Regel ist bis zum {{date}} gültig.',
      },
      sell: {
        a: 'Wenn der Verkaufspreis bei ',
        b: 'oder niedriger liegt, möchte ich zum nächsten verfügbaren Preis ',
        c: 'für bis zu ',
        d: 'verkaufen. Die Regel ist bis zum {{date}} gültig.',
      },
    },
    stopOrderFinishedDescription: {
      buy: {
        a: 'Wenn der ',
        b: 'Kaufpreis bei {{stopPrice}} ',
        c: 'oder höher liegt, möchte ich zum nächsten verfügbaren Preis ',
        d: 'für {{spendEuro}} bis zu {{amount}} kaufen',
        e: '. Die Regel ist für 90 Tage gültig.',
      },
      sell: {
        a: 'Wenn der ',
        b: 'Verkaufspreis bei {{stopPrice}} ',
        c: 'oder niedriger liegt, möchte ich zum nächsten verfügbaren Preis ',
        d: 'für {{spendEuro}} bis zu {{amount}} verkaufen',
        e: '. Die Regel ist für 90 Tage gültig.',
      },
    },
    fieldErrors: {
      stopPriceRequired: 'Bitte wähle einen Preis aus',
      amountRequired: 'Bitte wähle einen Betrag aus',
      spendEuroRequired: 'Bitte gib einen Euro Betrag an.',
      notEnoughCurrencyToBuy:
        'Du hast nicht genügend {{crypto}}, um diese Stop Order zu erstellen. Bitte gib einen niedrigeren Betrag an.',
      web_notEnoughCurrencyToBuy:
        'Du hast nicht genügend {{crypto}}, um diese Stop Order zu erstellen. Bitte gib eine niedrigere Anzahl an.',
      web_notEnoughFiat:
        'Du hast nicht genügend Euro, um diese Stop Order zu erstellen. Bitte gib einen niedrigeren Betrag an.',
    },
    addTitle: {
      buy: 'Stop Buy Regel anlegen',
      sell: 'Stop Loss Regel anlegen',
    },
    confirmationTitle: {
      buy: 'Stop Buy Regel {{assetName}}',
      sell: 'Stop Loss Regel {{assetName}}',
    },
    successTitle: {
      buy: 'Stop Buy Regel\nangelegt',
      sell: 'Stop Loss Regel\nangelegt',
    },
    notificationInfo: {
      buy: `Du erhältst eine Benachrichtigung sobald deine Kaufanfrage mit Stop Order ausgeführt wurde.`,
      sell: `Du erhältst eine Benachrichtigung sobald deine Verkaufsanfrage mit Stop Order ausgeführt wurde.`,
    },
    legalText: 'Eine Handelsregel bietet keine Garantie für eine Ausführung.',
    failTitle: {
      buy: `Stop Buy Regel konnte nicht angelegt werden`,
      sell: `Stop Loss Regel konnte nicht angelegt werden`,
    },
    errors: {
      generic:
        'Leider konnten wir deine Handelsregel nicht anlegen. Bitte versuche es zu einem späteren Zeitpunkt nochmal.',
      limitReached: `Stop {{type}} Orders für {{assetName}} in der Preisspanne um {{stopPrice}} sind bereits vergriffen. Bitte versuche es später erneut oder verwende einen anderen Stop Preis.`,
    },
    warnings: {
      buyPriceTooGood:
        'Deine Handelsregel wird voraussichtlich sofort ausgeführt, da dein angegebener Stop Preis unter dem aktuellen Kaufpreis liegt.',
      sellPriceTooGood:
        'Deine Handelsregel wird voraussichtlich sofort ausgeführt, da dein angegebener Stop Preis über dem aktuellen Verkaufspreis liegt.',
    },
    info: {
      web_title: `Stop Order`,
      web_content: `
Eine Stop Order ist eine Regel zum Kauf oder Verkauf, wenn sich der Preis über oder unter einen bestimmten Punkt hinausbewegt.

Stop Buy Regel: Einsteigen, um an Marktaufwärtstrends teilzuhaben. Du kaufst, wenn eine Kryptowährung einen von dir festgelegten Preis erreicht oder überschreitet. 

Stop Loss Regel: Aussteigen, um Verluste zu begrenzen. Du verkaufst, wenn der von dir festgelegte Preis eine niedrigere Marke durchbricht.

Eine übermittelte, aber noch nicht ausgeführte Stop Order, blockt den voraussichtlichen Gegenwert deines Geschäftes. Geblockte Bestände kannst du in deinem Portfolio einsehen.

Du kannst deine aktiven Handelsregeln jederzeit im Trading Manager einsehen oder löschen. Stop Orders haben eine Gültigkeitsdauer von 90 Tagen und werden nach Ablauf dieser Frist automatisch gelöscht.

Wir benachrichtigen dich, sobald deine Regel ausgeführt und ein Kauf oder Verkauf getätigt wurde. 
    `,
    },
    termsAndConditions: {
      title: 'Allgemeine Geschäftsbedingungen',
      firstParagraph:
        'Neu: Wir bieten dir ab sofort die Möglichkeit Geschäftsanfragen mit Preislimit (Limit Order) zu erstellen. Hierfür ist es jedoch erforderlich, dass du den angepassten AGB der EUWAX AG und der blocknox GmbH sowie den geänderten Basis- und Risikoinformationen zustimmst.',
      secondParagraph: 'Mit deiner Zustimmung finden diese geänderten AGB für dich unmittelbar Anwendung.',
      checkboxPreLink: 'Ich habe die aktualisierten ',
      firstCheckboxLink: 'Allgemeinen Geschäftsbedingungen der EUWAX AG und der blocknox GmbH ',
      firstCheckboxPostLink: 'gelesen, gespeichert und stimme diesen ausdrücklich zu.',
      secondCheckboxLink: 'Basis- und Risikoinformationen ',
      secondCheckboxPostLink: 'gelesen, gespeichert und stimme diesen zu.',
    },
    deleteStopOrderConfirmation: {
      web_header: 'Handelsregel löschen?',
      web_text:
        'Bist du sicher, dass du diese Handelsregel löschen möchtest? Du kannst dies nicht rückgängig machen.',
    },
  },
  settings: {
    web_depositWithdrawButton: 'Geld einzahlen / auszahlen',
    web_tradingButton: 'Handeln',
    web_depositMoney: 'Geld einzahlen',
    web_depositCrypto: 'Kryptos einzahlen',
    // Section titles
    moneyTransferButtons: {
      deposit: 'Euro einzahlen',
      withdraw: 'Euro auszahlen',
    },
    help: {
      title: 'Hilfe',
      subsections: {
        featureRequest: 'Feature-Anfrage einreichen',
        customerService: 'Kundenservice',
      },
    },
    newsletter: {
      title: 'BISONews',
      checkboxLabels: {
        newsletter: 'Monatlicher Newsletter',
        product: 'Allgemeine Neuigkeiten von BISON',
      },
      popupMessages: {
        success: 'Deine Änderungen wurden gespeichert.',
        error: 'Deine Änderungen konnten nicht gespeichert werden. Versuch es später erneut.',
      },
    },
    account: 'Mein Konto',
    profile: 'Profil',
    preferences: 'App-Einstellungen',
    misc: 'Sonstiges',
    legal: 'Rechtliche Hinweise',
    about: 'Über Uns',
    supportSection: 'Support',
    taxId: 'Steuer-ID',
    taxResidency: 'Steuerliche Ansässigkeit (freiwillige Angabe)',
    addTaxId: 'Steuerliche Ansässigkeit hinzufügen',
    changeTaxId: 'Steuer-ID ändern',
    // Theme
    theme: 'Design',
    themeDescription: 'Design',
    light: 'hell',
    dark: 'dunkel',
    stocksEnabled: 'Wertpapierhandel',
    stocksRaffle: 'E-Roller Gewinnspiel',

    // Header
    tryMode: 'Demo-Modus',

    // Items
    realMoney: 'Echtgeldhandel',
    realMoneyDescription: 'Wechsel zwischen Echtgeld und Spielgeld',
    realMoneyNoKyc: 'Beende die Verifizierung, um mit Echtgeld zu kaufen.',
    cryptoDeposit: 'Einzahlung Kryptowährungen',
    cryptoWithdraw: 'Kryptowährungen auszahlen',
    cryptoWithdrawDescription: 'Kryptowährungen zur eigenen Wallet auszahlen',
    redeemCode: 'Aktionscode einlösen',
    verification: 'Verifizierung',
    verificationStart: 'Jetzt starten',
    verificationPending: 'In Prüfung',
    verificationConfirmed: 'Bestätigt',
    verificationInvalid: 'Ungültig (Erneut versuchen)',
    verificationDescription: 'Verifiziere dich, bevor du mit Echtgeld handeln kannst',
    inbox: 'Posteingang',
    inboxDescription: 'Nachrichten lesen',
    inboxChips: {
      all: 'Alle',
      notifications: 'Benachrichtigungen',
      documents: 'Dokumente',
    },
    deposit: {
      title: 'Geld einzahlen',
      web_next: 'Weiter',
    },
    depositDescription: 'Zahle Geld auf dein BISON Konto ein',
    withdraw: {
      title: 'Geld auszahlen',
    },
    withdrawDescription: 'Zahle Geld auf dein Bankkonto aus',
    invite: 'Freunde einladen',
    inviteDescription: 'Teile BISON mit deinen Freunden',
    inviteMessage: {
      message: `Hey, werde jetzt Teil der BISON Herde! :)

Du erhälst {{referredUserRewardAmount}} in {{referredUserPayoutCurrency}}, wenn du mit meiner Einladung an dem BISON Freunde-werben-Freunde Programm teilnimmst. Mit der BISON App kannst du einfach Kryptowährungen kaufen und verkaufen.

So einfach gehts:
1. Klicke auf deinem Smartphone auf {{link}} und installiere kostenlos die BISON App
2. Registriere dich und verifiziere deinen Account via Video-Identverfahren
3. Lade Geld auf dein BISON Konto und kaufe deine erste Kryptowährung

GESCHAFFT, Du gehörst zur Herde.

Die Teilnahmebedingungen findest du hier: {{freundeEinladenLink}}.
`,
      title: 'BISON Freunde-werben-Freunde Programm',
    },
    inviteMessageNoKyc: {
      message: `Hey, werde jetzt Teil der BISON Herde! :)

Mit der BISON App kannst du einfach Kryptowährungen kaufen und verkaufen.
So einfach gehts:
1. Klicke auf den Link bisonapp.com/join
2. Installiere kostenlos die BISON App
3. Registriere dich mit deiner Email-Adresse
4. Verifiziere deinen Account via Video-Identverfahren
5. Lade Geld auf dein BISON Konto
6. Kaufe deine erste Kryptowährung

GESCHAFFT, Du gehörst zur Herde.
`,
      title: 'BISON Freunde-werben-Freunde Programm',
    },
    personalInfo: {
      title: 'Persönliche Information',
      name: 'Name',
      email: 'E-Mail',
      mobileNumber: 'Handynummer',
      address: 'Adresse',
      line1: 'Straße',
      line2: 'Hausnummer',
      postalCode: 'PLZ',
      city: 'Stadt',
      country: 'Land',
      iban: 'IBAN',
      ibanNumber: 'Aktuelle IBAN',
      taxId: 'Steuer-ID',
      taxResidency: 'Steuerliche Ansässigkeit (freiwillige Angabe)',
      primaryIban: '✓',
      codeTitle: 'Sicherheits-Code',
      smsCodePopupDescription:
        'Bitte bestätige deine Handynummer in dem du den Sicherheitscode eingibst, den wir dir per SMS an <1>{{phone}}</1> gesendet haben.',
      emailCodePopupDescription: `Wir haben dir einen zweiteiligen Sicherheits-Code per {{method}} gesendet.
        Der erste Teil wird an deine alte E-Mail-Adresse und der zweite Teil an deine neue E-Mail-Adresse gesendet.
        Gib beide Teile nacheinander zur Bestätigung ein.`,
      web_emailMethod: 'E-Mail',
      web_smsMethod: 'SMS',
      code: 'Sicherheits-Code',
      invalidCode: 'Ungültiger Code. Der Code muss exakt 6 Zeichen lang sein.',
      codeRequired: 'Du musst den 6-stelligen Code eingeben, um deine Änderung zu bestätigen.',
      changeIBANMessage: `# IBAN ändern

  Dein Referenzkonto ist immer das Bankkonto, von dem du das letzte mal auf dein BISON Konto eingezahlt hast:

  {{iban}}

  Wenn du dieses ändern möchtest, musst du erst von deinem neuen Referenzkonto Geld auf dein BISON Konto einzahlen. Das Referenzkonto passt sich daraufhin automatisch an.`,
      ibanRequired: 'Bitte wähle eine Referenz-IBAN aus, zu der du wechseln möchtest.',
      changeEmail: 'E-Mail-Adresse ändern',
      changeAddress: 'Addresse ändern',
      changeIban: 'IBAN ändern',
      changeTaxId: 'Steuer-ID ändern',
      addTaxId: 'Steuer-ID hinzufügen',
      addTaxResidency: 'Steuer-ID hinzufügen',
      changeMobileNumber: 'Handynummer ändern',
      deleteTaxId: 'Löschen',
      addTaxIdCountry: 'Steuer-ID hinzufügen',
      addAnotherTaxIdCountry: 'Weitere Steuer-ID hinzufügen',
      changeRisk: {
        title: 'Erfahrung mit Wertpapieren',
        modalTitle:
          'Eine erneute Ermittlung deiner Erfahrungsstufe mit Wertpapieren ist heute nicht möglich.',
        subtitle:
          'Du kannst deine Erfahrungsstufe mit Wertpapieren nur 1x täglich erneut ermitteln. Schau gern wieder morgen vorbei, dann kannst du die Fragen erneut beantworten.',
        details:
          'Noch offene Fragen? Wir helfen dir gerne, kontaktiere uns gerne jederzeit unter <1>{{supportEmail}}</1>.',
        status: {
          basic: 'Basis',
          informed: 'Erweitert',
          advanced: 'Umfangreich',
        },
      },
      errors: {
        incorrectCode2fa: 'Ungültiger Code',
        invalidTaxId: 'Bitte trage eine für dein Land gültige Steuer-ID ein.',
        changeUsernameInitGenericError:
          'Es gab ein Problem bei der Änderung deiner E-Mail-Adresse. Bitte versuche es erneut.',
        changeUsernameGenericError:
          'Es gab ein Problem bei der Änderung deiner E-Mail-Adresse. Bitte versuche es später erneut.',
        changeAddressInitGenericError:
          'Es gab ein Problem bei der Änderung deiner Adresse. Bitte versuche es erneut.',
        changeIbanInitGenericError:
          'Es gab ein Problem beim Ändern deiner Referenz-IBAN. Bitte versuche es später erneut.',
        changeAddressGenericError:
          'Es gab ein Problem bei der Änderung deiner Adresse. Bitte versuche es später erneut.',
        changeIbanGenericError:
          'Es gab ein Problem beim Ändern deiner Referenz-IBAN. Bitte versuche es später erneut.',
        changeIbanNotRequired: 'Das ist bereits deine verwendete Referenz-IBAN.',
        changeTaxIdGenericError:
          'Es gab ein Problem bei der Änderung deiner Steuer-ID. Bitte versuche es später erneut.',
        taxIdCountryExists: 'Du hast bereits eine Steuer-ID für diese steuerliche Ansässigkeit hinzugefügt.',
      },
      success: {
        changeEmail: 'Du hast deine E-Mail-Adresse erfolgreich geändert.',
        changeAddress: 'Deine Adresse wurde erfolgreich geändert.',
        changeIban: 'Du hast deine Referenz-IBAN erfolgreich geändert.',
        changeTaxId: 'Du hast deine Steuer-ID erfolgreich geändert.',
      },

      web_infoChange: `Deine persönlichen Daten kannst du aktuell nur in der BISON App abändern.

  *[Zur BISON App](https://xfgv.adj.st/settings?adjust_t=r1kq7p1_837qlr5&adjust_deeplink=bisonapp%3A%2F%2Fsettings)*`,
    },
    accountInfo: {
      title: 'Kontoinformationen',
      label1: 'Name',
      label1Content: 'Solaris SE',
      label2: 'IBAN',
      label3: 'BIC',
      paragraph:
        'Beachte bitte bei <1>Einzahlungen</1><2>: Der Empfänger ist </2><3>{{name}}</3><4> und als Verwendungszweck ist </4><5>BISON App</5><6> anzugeben.</6>',
      subTitle: 'Depotnummer',
      text: 'Hier wird deine Depotnummer angezeigt, sobald du dich für Aktien und ETFs freigeschaltet hast.',
      secondaryButton: 'Jetzt Freischalten',
      bankName: 'Solarisbank',
    },
    offsetAccounts: {
      title: 'Verrechnungstöpfe',
      description:
        'Gewinne werden mit möglichen Verlusten verrechnet, bevor sie versteuert oder mit dem Freistellungsauftrag verrechnet werden.',
      rows: {
        a: {
          text: 'Verlustverrechnungstopf Aktien',
          hint: 'Enthält Aktienverluste, die nur mit Aktiengewinnen verrechnet wurden.',
        },
        b: {
          text: 'Verlustverrechnungstopf ETFs',
          hint: 'Enthält bspw. Verluste aus Geschäften mit ETFs.',
        },
        c: {
          text: 'Quellensteuerverrechnungstopf',
          hint: 'Nicht anrechenbare ausländische Quellensteuer, die mit der Kapitalertragssteuer verrechnet wird.',
        },
      },
    },
    taxExemption: {
      twoFaModal: {
        title: '2-Faktor Authentifizierung',
        phoneNumberText:
          'Du möchtest deinen Freistellungsauftrag anpassen. Bitte bestätige, dass du es bist in dem du den Sicherheitscode eingibst, den wir dir per SMS an {{phone}} gesendet haben.',
        inputLabel: 'Sicherheitscode',
        supportText: 'Solltest du keinen Zugang zu dieser Nummer haben, kontaktiere uns bitte unter',
        repeatSending: {
          sms: 'Keine SMS erhalten?  <1>SMS erneut senden</1>',
          email: 'Keine Mail erhalten?  <1>Mail erneut senden</1>',
        },
        buttonText: 'Weiter zur Freischaltung',
        error: 'Bitte gib einen Sicherheitscode ein.',
      },
      title: 'Freistellungsauftrag',
      card: {
        title: 'Freistellungsauftrag',
        emptyText:
          'Du hast bisher keinen Freistellungsauftrag angelegt. Ein Freistellungsausftrag (FSA) kann bis zu {{amount}} betragen.',
        emptyButton: 'Freistellungsauftrag anlegen',
        filledText: {
          year: 'Freistellungsauftrag {{year}}',
          amount: 'Betrag',
          maxLegalText:
            'Du hast dieses Jahr bereits {{amountUsed}} von maximal {{maxAmount}} bei BISON in Anspruch genommen (Täglich nach Börsenschluss aktualisiert).',
        },
      },
      infoModal: {
        title: 'Freistellungsauftrag',
        mainText: `Ein angelegter Freistellungsauftrag führt dazu, dass bis zu dem von dir angegebenen Betrag keine Abgeltungssteuer an das Finanzamt abgeführt wird.
          <br/>
          <br/>
          Der maximale bankübergreifende Betrag pro Jahr, beträgt {{amount}}. Beachte, dass ein Freistellungsauftrag für die Kapitalerträge des laufenden Steuerjahres gilt. Als Kapitalerträge gelten z.B. Gewinne aus Wertpapiergeschäften oder Dividenen.`,
      },
      creationAndEditingModal: {
        title: 'Freistellungsauftrag (FSA) anlegen',
        explanation:
          'Du hast bisher keinen Freistellungsauftrag angelegt. Ein Freistellungsauftrag kann bis zu {{amount}} betragen.',
        input: {
          label: 'Betrag',
          placeholder: 'max. {{amount}}',
          minError: 'Minimaler Wert ist 0',
          highError:
            'Der Betrag übersteigt den gesetzlichen Maximalbetrag von {{amount}} pro Steuerjahr. Gib einen Betrag unter {{amount}} an.',
          underPreviousAmount:
            'Der Betrag unterschreitet den von dir bereits im Steuerjahr {{year}} in Anspruch genommenen Betrag von {{currentAmount}}. Gib einen Betrag zwischen {{minimumAmount}} und {{maximumAmount}} an.',
        },
        expirationDate: {
          label: 'Laufzeit bis',
          a: 'ich einen anderen Auftrag erteile',
          b: 'Ende {{year}}',
        },
        checkbox:
          'Ich habe die Hinweise zum Freistellungsauftrag gelesen und bestätige meine Angaben und Steuer-ID: {{taxId}}',
        button: 'Freistellungsauftrag anlegen',
        editButton: 'Änderungen speichern',
        legal:
          'Hiermit erteile ich den Auftrag, meine bei BISON anfallenden Kapitalerträge vom Steuerabzug freizustellen und / oder bei Dividenden u. ä. Kapitalerträgen die Erstattung von Kapitalertragssteuer, in Höhe des o.g. Betrags, zu beantragen.',
      },
      limitReachedModal: {
        title: 'Bearbeitung des Freistellungsauftrags für {{year}} nicht möglich',
        mainText:
          'Du hast den Maximalbetrag von {{amount}} im Steuerjahr {{year}} bei BISON bereits in Anspruch genommen (Täglich nach Börsenschluss aktualisiert). Das Bearbeiten deines Freistellungsauftrags für {{year}} ist daher leider nicht mehr möglich.',
      },
      resultModal: {
        successTitle: '2-Faktor Authentifizierung erfolgreich',
        errorTitle: 'Dein Sicherheitscode ist ungültig',
      },
    },
    mobileNumberChange: {
      mobileNumberRequired: 'Bitte gebe eine gültige Handynummer ein.',
      addMobileNumber: 'Handynummer zuweisen',
      changeMobileInfo: {
        a: 'Das Ändern deiner Handynummer beinhaltet ',
        b: '2 Schritte',
        c: '1.',
        d: ' Entferne deine alte Handynummer. Dafür brauchst du Zugang zu deiner alten Handynummer.',
        e: '2.',
        f: ' Weise deine neue Handynummer deinem BISON Konto zu.',
      },
      startMobileChange: 'Weiter',
      noMobileNumberAccessMsg: `Du hast keinen Zugang mehr zu meiner alten Handynummer?`,
      assignMobileInfo: {
        a: 'Gib deine ',
        b: 'neue Nummer',
        c: ' unten an, damit wir diese deinem BISON Konto zuordnen können. Du erhältst einen Sicherheits-Code an deine neue Handynummer, um deine neue Nummer zu bestätigen.',
      },
      unpairNumber: {
        a: 'Du erhälst einen Sicherheits-Code an deine ',
        b: 'alte Handynummer',
        c: ' ({{currentMobileNumber}}), um diese zu entfernen.',
        sendCode: 'Code senden',
      },
      supportScreen: {
        a: `Wenn du keinen Zugang zu deiner alten Handynummer hast, wende dich bitte an den BISON Support um diese zu entfernen.`,
        b: 'Nachdem deine alte Nummer entfernt wurde, kannst du deinem BISON Konto deine neue Handynummer zuweisen.',
        contactSupport: 'Kontaktiere den Support',
      },
      errors: {
        assignGenericError:
          'Es gab ein Problem bei der Zuweisung deiner Handynummer. Bitte versuche es später nochmal.',
        assignInitGenericError:
          'Es gab ein Problem bei der Zuweisung deiner Handynummer. Bitte versuche es später nochmal.',
        deleteInitGenericError:
          'Es gab ein Problem beim Entfernen deiner alten Handynummer. Bitte versuche es später nochmal.',
        deleteGenericError:
          'Es gab ein Problem beim Entfernen deiner alten Handynummer. Bitte versuche es später nochmal.',
      },
      success: {
        assignMobileNumber: 'Du hast deine neue Handynummer erfolgreich deinem BISON Konto zugewiesen.',
        deleteMobileNumber: 'Du hast deine alte Handynummer erfolgreich entfernt.',
      },
    },
    personalInfoDescription: 'Name, Adresse, E-Mail',
    changePass: {
      title: 'Passwort ändern',
      currentPassword: 'Aktuelles Passwort',
      newPassword: 'Neues Passwort',
      confirmPassword: 'Passwort bestätigen',
      change: 'Ändern',
      changing: 'Ändern...',
      success: 'Passwort erfolgreich geändert',
      errorTitle: 'Änderung des Passworts fehlgeschlagen',
      userMismatch: 'Du hast keine Berechtigung hierfür.',
      oldPwdWrong: 'Aktuelles Passwort ist falsch.',
      newPwdInvalid: 'Das neue Passwort entspricht nicht unseren Mindestanforderungen.',
      newPwdSameAsOld: 'Das neue Passwort darf nicht dem alten Passwort entsprechen.',
      // Errors
      genericError:
        'Entschuldige, wir können dein Passwort leider aktuell nicht ändern. Bitte versuche es später erneut.',
      fieldErrors: {
        passwordConfirmRequired: 'Bitte bestätige dein neues Passwort.',
        passwordConfirm: 'Deine neuen Passwörter stimmen nicht überein.',
      },
    },
    changePassDescription: 'Für deinen Zugriff zur App',
    notifications: {
      title: 'Push-Benachrichtigungen',
      inApp: 'In-App Nachrichten',
    },
    notificationsDescription: 'Push- und E-Mail-Benachrichtigungen',
    accountProtection: {
      title: 'Kontosicherheit',
      biometricLogin: 'Biometrischer Login aktiviert',
      biometricLoginDescription: 'Aktiviere biometrischen Login',
      passwordLogin: 'Passwort-Abfrage',
      passwordLoginDescription: 'Aktiviere Login via Passwort',
    },
    reports: {
      title: 'Reports',
      infoReport: {
        title: 'Info-Report',
        description: `Mit dem Info-Report stellt dir BISON deine Transaktionen übersichtlich und detailliert dar und stellt dir eine unverbindliche Berechnung über die realisierten Gewinne und Verluste bei BISON nach dem FiFo-Verfahren (First-In First-Out) zur Verfügung.`,
        web_description: `Mit dem Info-Report stellt dir BISON deine Transaktionen übersichtlich und detailliert dar und stellt dir eine unverbindliche Berechnung über die realisierten Gewinne und Verluste bei BISON nach dem FiFo-Verfahren (First-In First-Out) zur Verfügung.`,
        success: `Dein Info-Report für das Steuerjahr {{year}} wird dir an deine E-Mail-Adresse gesendet.`,
        successAlreadySent: `Dein Info-Report für das Steuerjahr {{year}} wurde dir bereits an deine E-Mail-Adresse gesendet.`,
        error: {
          pleaseSelect: 'Bitte wähle ein Steuerjahr aus',
          generic: 'Entschuldige, dein Report ist aktuell nicht verfügbar. Bitte versuche es später erneut.',
        },
        web_buttonText: 'Senden',
      },
      bankStatement: {
        title: 'Kontoauszug',
        description: {
          a: 'Mit dem Kontoauszug erhältst du eine Übersicht deines Euro-Guthabens für den jeweiligen Abrechnungszeitraum.',
          b: 'Wähle einfach einen Monat aus und du erhältst deinen Kontoauszug auf die von dir hinterlegte E-Mail-Adresse.',
        },
        selectMonth: 'Bitte wähle einen Monat aus',
        months: {
          january: 'Januar',
          february: 'Februar',
          march: 'März',
          april: 'April',
          may: 'Mai',
          june: 'Juni',
          july: 'Juli',
          august: 'August',
          september: 'September',
          october: 'Oktober',
          november: 'November',
          december: 'Dezember',
        },
        noMonthsAvailable: 'Du hast keine Kontoauszüge verfügbar',
        pickerTitle: 'Wähle einen Monat aus',
        success: 'Dein Kontoauszug für {{month}} wird dir an deine E-Mail-Adresse gesendet.',
        web_success: 'Kontoauszug verschickt',
        error: 'Entschuldigung, es gab ein Problem. Bitte versuchen Sie es später erneut.',
        web_error: 'Kontoauszug konnte nicht verschickt werden',
        pleaseSelect: 'Bitte wähle einen Monat aus',
        web_buttonText: 'Senden',
        notificationText: {
          a: 'Du suchst den Info-Report für Wertpapiere? ',
          b: 'Diesen erhältst du automatisch regelmäßig in deinen ',
          c: 'Posteingang',
          d: '.',
        },
      },
      transactionHistory: {
        web_title: 'Transaktionshistorie',
        title: 'Transaktionshistorie',
        description: {
          a: 'Mit der Transaktionshistorie stellt dir BISON deine Transaktionen für den jeweiligen Transaktionszeitraum übersichtlich und detailliert dar.',
          b: 'Wähle einfach einen Zeitraum aus und du erhältst deine Transaktionshistorie im CSV Format per E-Mail auf die von dir hinterlegte E-Mail-Adresse.',
        },
        pickerTitle: 'Wähle einen Zeitraum aus',
        pleaseSelect: 'Bitte wähle einen Zeitraum aus',
        noMonthsAvailable: 'Du hast noch keine Transaktionshistorie.',
        allTime: 'Gesamte Transaktionshistorie',
        error: 'Entschuldigung, es gab ein Problem. Bitte versuchen Sie es später erneut.',
        success: {
          year: 'Dein Transaktionshistorie für das Jahr {{year}} wird dir an deine E-Mail-Adresse gesendet.',
          allTime: 'Deine gesamte Transaktionshistorie wird dir an deine E-Mail-Adresse gesendet.',
        },
        successAlreadySent: {
          year: 'Deine Transaktionshistorie für das Jahr {{year}} wurde bereits an deine E-Mail-Adresse gesendet.',
          allTime: 'Deine gesamte Transaktionshistorie wurde bereits an deine E-Mail-Adresse gesendet.',
        },
        send: 'Senden',
        sending: 'Senden...',
      },
    },
    tradingExperience: {
      title: 'Erfahrungen & Kenntnisse',
      screenTitle: 'Erfahrung mit wertpapieren bearbeiten',
      risk: 'Risiko',
      lowRisk: 'Niedriges bis mittleres ',
      medRisk: '(Sehr) hohes ',
      highRisk: 'Höchstes ',
      description: 'Bitte gib an, welche Erfahrungen du mit verschiedenen Wertpapieren hast.',
      success: 'Du hast deine Erfahrungen und Kenntnisse erfolgreich aktualisiert',
      fail: 'Etwas ist schief gelaufen ...',
      levels: {
        basic: 'Grundwissen',
        experienced: 'Mittleres Wissen',
        advanced: 'Erweiterte Kenntnisse',
      },
      saveChanges: 'Änderungen speichern',
    },
    terminateAccount: {
      terminateAccount: 'Konto kündigen',
      terminatingAccount: 'Konto kündigen...',
      terminateAccountSuccess: 'Konto gekündigt!',
      reason: 'Bitte nenne uns den Grund, weshalb du BISON verlässt:',
      reasonPlaceholder: 'Grund hier eingeben...',
      confirmTermination: 'Vertrag kündigen bestätigen',
      contactSupport: 'BISON Support kontaktieren',
      demoTerminateInitial: {
        a: 'Schade, dass du BISON verlassen möchtest.',
        b: {
          demo: 'Bitte beachte, dass du mit der Kündigung die Vertragsbeziehung mit der EUWAX AG zur Nutzung des Demo-Modus mit dem Musterdepot kündigst und dein BISON Konto damit unwiderruflich gelöscht wird.',
          stocksNotEnabled:
            'Bitte beachte, dass du mit der Kündigung die Vertragsbeziehung mit der EUWAX AG, der blocknox GmbH und der Solaris SE zur Nutzung der BISON App im Echtgeld-Modus kündigst und dein BISON Konto damit unwiderruf-lich gelöscht wird.',
          stocksEnabled:
            'Bitte beachte, dass du mit der Kündigung die Vertragsbeziehung mit der EUWAX AG, der blocknox GmBH, der Solaris SE und der Max Heinr. Sutor oHG zur Nutzung der BISON App im Echtgeld-Modus kündigst und dein BISON Konto damit unwiderruflich gelöscht wird.',
        },
        c: {
          demo: 'Wir werden dir anschließend den Zugang der Kündigung sowie die Beendigung des Vertrages per E-Mail bestätigen und dein BISON Konto wird anschließend unmittelbar gelöscht.',
          real: 'Bitte beachte und bestätige folgende Punkte, bevor du dein BISON-Konto kündigst und damit unwiderruflich löschst:',
        },
      },
      demoTerminateSuccess: {
        title: 'Vertragskündigung bestätigt',
        a: 'Hiermit bestätigen wir dir den Zugang deiner Kündigung. Wir werden dein BISON Konto unmittelbar und unwiderruflich löschen. Damit hast du keinen Zugang mehr zur BISON-App.',
        b: 'Wir werden dir nach Absenden dieses Formulars den Zugang der Kündigung sowie die Beendigung des Vertrages per E-Mail bestätigen. Dein BISON Konto wird unmittelbar gelöscht.',
        c: 'Deine personenbezogenen Daten werden wir ebenfalls unverzüglich löschen, sofern wir nicht gem. Art 17 Abs. 3 DSGVO ein berechtigtes Interesse an der Weiternutzung, insbesondere zur Erfüllung einer gesetzlichen Aufbewahrungspflicht, haben.',
      },
      reasonIsTooLong: 'Max. 512 Zeichen',
      genericError: 'Entschuldigung, es gab ein Problem. Bitte versuchen Sie es später erneut.',
      real: {
        checkboxStep: {
          title: 'Konto kündigen',
          header: 'Schade, dass du die BISON Herde verlassen möchtest.',
          body: {
            a: 'Wenn du dir sicher bist, dass du die Vertragsbeziehung mit der EUWAX AG, der blocknox GmbH und der Solaris SE zur Nutzung der BISON App mit Echtgeld kündigen und dein BISON Konto unwiderruflich löschen lassen möchtest, ist dir dieses durch Absenden dieser Mitteilung möglich.',
            b: 'Bitte beachte und bestätige folgende Checkliste, bevor du dein Konto löscht:',
            c: 'Wir werden dir anschließend den Zugang der Kündigung sowie die Beendigung des Vertrages per E-Mail bestätigen und dein BISON Konto wird anschließend unmittelbar gelöscht.',
            reason: 'Bitte nenne uns den Grund, weshalb du BISON verlässt:',
            reasonPlaceholder: 'Grund hier eingeben...',
            confirm: 'Vertrag kündigen bestätigen',
            cancel: 'Abbrechen',
          },
          a: {
            stocksNotEnabled: 'Ich habe keine Euros und / oder Kryptowährungen mehr in meinem BISON Konto.',
            stocksEnabled:
              'Ich habe keine Euros, Kryptowährungen und / oder Wertpapiere mehr in meinem BISON Konto.',
          },
          b: 'Ich bin mir bewusst, dass durch Kündigung meine offenen Handelsregeln (Limit & Stop Orders) gelöscht werden.',
          c: 'Ich habe keine bereits initiierten Euro-Einzahlungen oder Kryptowährungs-Einzahlungen offen.',
          d: 'Ich habe meinen Info-Report heruntergeladen.',
          e: 'Ich habe meine Kontoauszüge heruntergeladen.',
        },
        failure: {
          title: 'Assets still available',
          header: {
            currency: 'Du hast noch Euros in deinem Portfolio.',
            currency_crypto: 'Du hast noch Euros und Kryptowährungen in deinem Portfolio.',
            currency_stock: 'Du hast noch Euros und Wertpapiere in deinem Portfolio.',
            currency_crypto_stock: 'Du hast noch Euros, Kryptowährungen und Wertpapiere in deinem Portfolio.',
            crypto: 'Du hast noch Kryptowährungen in deinem Portfolio.',
            crypto_stock: 'Du hast noch Kryptowährungen und Wertpapiere in deinem Portfolio.',
            stock: 'Du hast noch Wertpapiere in deinem Portfolio.',
          },
          body: {
            a: {
              currency:
                'Bitte zahle deine Euros zunächst aus, um Verzögerungen bei der Abwicklung zu vermeiden.',
              currency_crypto:
                'Bitte zahle deine Euros und Kryptowährungen zunächst aus, um Verzögerungen bei der Abwicklung zu vermeiden.',
              currency_stock:
                'Bitte zahle deine Euros und zunächst aus und übertrage deine Wertpapiere auf ein anderes Depot, um Verzögerungen bei der Abwicklung zu vermeiden.',
              currency_crypto_stock:
                'Bitte zahle deine Euros und Kryptowährungen zunächst aus und übertrage deine Wertpapiere auf ein anderes Depot, um Verzögerungen bei der Abwicklung zu verhindern.',
              crypto:
                'Bitte zahle deine Kryptowährungen zunächst aus, um Verzögerungen bei der Abwicklung zu vermeiden.',
              crypto_stock:
                'Bitte zahle deine Kryptowährungen zunächst aus und übertrage deine Wertpapier auf ein anderes Depot, um Verzögerungen bei der Abwicklung zu verhindern.',
              stock:
                'Bitte übertrage deine Wertpapiere zunächst auf ein anderes Depot, um Verzögerungen bei der Abwicklung zu vermeiden.',
            },
            b: 'Noch offene Fragen? Wir helfen dir gerne, kontaktiere uns gerne jederzeit unter <1>{{supportEmail}}</1>.',
          },
          web_button: 'Schließen',
          web_link: 'BISON Support kontaktieren',
          web_mailSubject: 'BISON App - Kündigung {{username}}',
        },
        confirm: {
          text: `#### Bist du dir sicher, dass du dein BISON Konto kündigen möchtest?

Dieser Schritt ist unwiderruflich und dein BISON Konto wird anschließend unmittelbar gelöscht.
`,
          cta: 'Ja, kündigen',
        },
      },
      error: 'Wir konnten deine Löschanfrage leider nicht bearbeiten. Bitte versuche es später nochmal.',
    },
    submitFeature: {
      title: 'Feature-Anfrage einreichen',
      submitRequest: 'Jetzt einreichen',
      textBoxLabel: 'Beschreibung:',
      firstParagraph:
        'Teile deine Ideen zur Verbesserung der BISON App! Wir sind auf deine Wünsche und Anregungen zu neuen Funktionen gespannt.',
      web_firstParagraph:
        'Teile deine Ideen zur Verbesserung von BISON! Wir sind auf deine Wünsche und Anregungen zu neuen Funktionen gespannt.',
      secondParagraph:
        'Bitte habe Verständnis dafür, dass wir nicht jeden einzelnen Vorschlag beantworten können. Sollten wir spezifische Rückfragen zu deiner Anfrage haben, werden wir uns per E-Mail bei dir melden.',
      notification: {
        success: {
          title: 'Feature-Anfrage erfolgreich eingereicht',
        },
        error: {
          title: 'Es ist etwas schief gelaufen. Bitte versuche es erneut.',
        },
      },
      fieldErrors: {
        tooFewChars: 'Bitte beschreibe etwas mehr',
      },
    },
    accountProtectionDescription: 'Ändere zusätzliche Sicherheits-Einstellungen',
    tutorial: 'Tutorials',
    tutorialDescription: 'Schaue dir die Tutorials an',
    rateUs: 'Dir gefällt BISON? Bewerte uns!',
    rateUsDescription: 'Bewerte BISON',
    support: 'BISON Support',
    supportDescription: 'Erhalte Hilfe vom Support',
    website: 'Webseite',
    websiteDescription: 'Besuche unsere Webseite',
    buildVersion: 'Version',
    logout: 'Abmelden',
    logoutDescription: 'Du musst dich erneut anmelden.',
    resetPaper: 'Abmelden vom Demo-Modus',
    resetPaperDescription: 'Dein Portfolio sowie deine Transaktionsübersicht werden zurückgesetzt.',
    faqs: 'FAQs',
    faqsDescription: 'Zu den FAQs',
    attributions: 'Attribute',
    attributionsDescription: 'Lizenzen von Drittparteien',
  },
  inbox: {
    noMessages: {
      title: 'Bisher keine Benachrichtigungen & Dokumente',
      text: 'Hier werden deine Nachrichten und Dokumente angezeigt, sobald du welche erhältst.',
    },
    from: 'Von: ',
    to: 'An: ',
    tableHeader: {
      web_subject: 'Betreff',
      web_from: 'Von',
      web_date: 'Datum',
    },
    successfullyDeleted: 'Nachricht erfolgreich gelöscht.',
    successfullyDeletedMany: 'Nachricht erfolgreich gelöscht',
    tapToUndo: 'Klicke zum rückgängig machen',
    successfullyMarkedAsRead: 'Nachrichten als gelesen markiert.',
    alreadyMarkedAsRead: 'Du hast bereits alle Nachrichten gelesen.',
    errorDeletingMany: 'Keine Nachrichten zum Löschen verfügbar',
    buttons: {
      read: 'Alle als gelesen markieren',
      delete: 'Alle löschen',
    },
    menu: {
      all: {
        markAsRead: 'Alle Nachrichten als gelesen markieren',
        delete: 'Alle Nachrichten löschen',
        select: 'Alle Nachrichten auswählen',
      },
      selected: {
        markAsRead: 'Ausgewählte Nachrichten als gelesen markieren',
        delete: 'Ausgewählte Nachrichten löschen',
        select: 'Alle Nachrichten abwählen',
      },
    },
    confirmDeleteAll: 'Bist du sicher, dass du alle Nachrichten löschen möchtest?',
    confirmDeleteSelected: 'Bist du sicher, dass du die ausgewählten Nachrichten löschen möchtest?',
    web_confirmDeleteSingle: 'Bist du sicher, dass du diese Nachricht löschen möchtest?',
    deleteMessages: 'Nachrichten löschen',
    web_deleteMessage: 'Nachricht löschen',
    web_markAsRead: 'Als gelesen markieren',
    deleteMessagesHeader: 'Alle Nachrichten löschen',
    deleteSingleMessageHeader: 'Nachricht löschen',
    deleteMessagesAdditionalInforation: 'Information: Nachrichten mit Anhängen werden nicht gelöscht.',
  },
  securitiesKyc: {
    entryModal: {
      title: 'Handle jetzt auch Aktien & ETFs.',
      text: 'Schalte dein Konto ganz einfach für Aktien & ETFs frei und starte noch heute mit Wertpapieren durch.',
      unlockNow: 'Jetzt Freischalten',
      remindLater: 'Erinnere mich später',
    },
    leaveKYCPopup: {
      title: 'Du möchtest die Freischaltung abbrechen?',
      text: 'Keine Sorge! Wenn du diese Seite verlässt, werden deine bisherigen Eingaben ge-speichert und du kannst die Freischaltung später an dieser Stelle fortsetzen.',
      continueKYC: 'Weitermachen',
      leaveKYC: 'Später fortsetzen',
    },
    secondAttemptToSubmitKyc: {
      title: 'Freischaltung bereits beantragt',
      text: 'Du hast die Freischaltung bereits erfolgreich beantragt.\n\n Es kann **bis zu 15 Minuten dauern** bis die Freischaltung bestätigt ist und du Aktien & ETFs handeln kannst. Wir benachrichtigen dich per E-mail, sobald die Freischaltung erfolgt ist.\n\n',
      support: `Noch offene Fragen? Wir helfen dir gerne, kontaktiere uns gerne jederzeit unter [Kundenservice Formular]({{supportLink}}).`,
    },
    unavailableModal: {
      title: 'Die Freischaltung für Aktien & ETFs ist  aktuell nicht möglich',
      text: 'Leider können wir Personen, die nicht in Deutschland steuerpflichtig sind, derzeit keinen Handel mit Aktien & ETFs ermöglichen. \n\nWir informieren dich gerne per E-Mail sobald es möglich ist.',
      notifyViaEmail: 'Informiert mich per E-Mail',
      noThanks: 'Nein, danke',
    },
    taxInfoStepOne: {
      question: 'Bist du ausschließlich in Deutschland steuerpflichtig?',
      explanationModal: {
        title: 'Deutsche Steuerpflicht',
        text: 'Unsere Partner Bank bietet den Aktienhandel aktuell nur für Personen an, die ausschließlich in Deutschland steuerpflichtig sind. Wir arbeiten mit Hochdruck daran dies auch allen anderen Nutzern zu ermöglichen.',
      },
      info: `# Deutsche Steuerpflicht

Unsere Partner Bank bietet den Aktienhandel aktuell nur für Personen an, die ausschließlich in Deutschland steuerpflichtig sind.  

Wir arbeiten mit Hochdruck daran dies auch allen anderen Nutzern zu ermöglichen.`,
    },
    taxInfoStepTwo: {
      question: 'Wie lautet deine Steuer-ID?',
      label: 'Steuer-Identifikationsnummer',
      info: `# Steuer-Identifikationsnummer

Die Steuer-ID ist 11-stellig und wird auch als Steuer-Identifikationsnummer bezeichnet.  
      
In Deutschland Steuerpflichtige finden ihre Steuer-ID zum Beispiel auf der Lohnsteuerbescheinigung, dem Einkommenssteuerbescheid oder auf einem Schreiben vom Finanzamt.`,
      error: 'Bitte gib eine Steuer-ID mit 11 Zahlen an.',
    },
    addressStep: {
      title: 'Adresse',
    },
    personalInfoStep: {
      title: 'Persönliches',
      text: 'Für das Handeln von Aktien, ETFs und weitere Finanzinstrumente ist es nötig, dich noch ein bisschen besser kennenzulernen. ',
      salutationLabel: 'Anrede',
      mr: 'Herr',
      ms: 'Frau',
      maritalStatusLabel: 'Familienstand',
      instructions: 'Bitte auswählen',
      single: 'ledig',
      married: 'verheiratet',
      divorced: 'geschieden',
      widowed: 'verwitwet',
    },
    jobInformationStep: {
      title: 'Beruf',
      text: 'Informationen zu deiner beruflichen Situation benötigen wir aufgrund gesetzlicher Vorgaben.',
      employmentStatusLabel: 'Anstellungsart',
      professionalFieldLabel: 'Branche',
      select: 'Bitte auswählen',
      employmentStatusOptions: {
        employee: 'Angestellte:r',
        worker: 'Arbeiter:in',
        civilServant: 'Beamte:r',
        soldier: 'Soldat:in / BFD',
        manager: 'Geschäftsführer:in / Vorstand / Vorständin',
        artisan: 'Handwerker:in',
        homemaker: 'Hausmann / Hausfrau',
        privat: 'Privatier / Privatiére',
        pensioner: 'Rentner:in',
        student: 'Student:in / Auszubildende:r',
        selfEmployed: 'Selbstständige:r / Freiberufler:in',
        noEmployment: 'Ohne Beschäftigung',
        seniorStaff: 'Führungskräfte',
      },
      professionalFieldOptions: {
        construction: 'Baugewerbe',
        energy: 'Energieversorgung',
        education: 'Erziehung/Unterricht',
        finance: 'Finanzen/Versicherungen',
        hospitality: 'Gastgewerbe',
        healthcare: 'Gesundheit-/Sozialwesen',
        retail: 'Handel/Instandhaltung',
        realEstate: 'Immobilienwesen',
        information: 'Information/Kommunikation',
        art: 'Kunst/Unterhaltung',
        agriculture: 'Land-/Forstwirtschaft',
        publicService: 'Öffentlicher Dienst',
        privateHouseholds: 'Private Haushalte',
        other: 'Sonstige Dienstleistungen',
        manufacturing: 'Verarbeitendes Gewerbe',
        transport: 'Verkehr und Lagerei',
        waste: 'Wasser-/Abfallentsorgung',
        mining: 'Bergbau',
        scientificServices: 'Wissenschaftliche Dienstleistungen',
        economicServices: 'Wirtschaftliche Dienstleistungen',
      },
    },
    securitiesStep: {
      question: {
        a: 'Hast du Anlageklassen mit ',
        b: 'niedrigem bis mittlerem ',
        c: 'Risiko bereits gehandelt?',
      },
      reason:
        'Uns interessiert deine Erfahrung mit Wert-papieren. Deine Kenntnisse im Krypto-Bereich, kennen wir ja schon.',
      examples: 'z.B. Rentenfonds & Renten-ETFs oder Wertpapiere von Bund, Ländern und Kommunen ...',
      info: `# Anlageklassen mit geringem bis mittlerem Risiko

#### Wertpapiere von Bund, Ländern & Kommunen in €

Wertpapiere des Staates bzw. öffentliche Anleihen oder Staatsanleihen dienen der Finanzierung des Haushalts und werden vom Staat verwaltet. 

#### Öffentliche Anleihen (EU) in €

Dies sind Anleihen eines EU Staates oder dessen Gebietskörperschaften (z.B. Bund, Länder und Kommunen), die Euro als Nominalwährung (Transaktionswährung) aufweisen.

#### Öffentliche Anleihen (außerhalb EU) in €

Dies sind Anleihen eines Nicht-EU Staates oder dessen Gebietskörperschaften (z.B. Bund, Länder und Kommunen), die Euro als Nominalwährung (Transaktionswährung) aufweisen.

#### Geldmarktfonds & ETFs in €

Geldmarktfonds investieren in Positionen des Geldmarkts, die eine Laufzeit von maximal 12 Monaten besitzen. Diese Fonds zeichnen sich von einer hohen Liquidität und guter Handelbarkeit aus. Beispiele dieser Kategorie sind Staatsanleihen, Einlegezertifikate von Banken oder Schuldscheindarlehen.

#### Geldmarktnahe Fonds/ETFs

Geldmarktnahe Fonds/ETFs investieren das eingezahlte Kapital in festverzinsliche Wertpapiere mit kurzen Restlaufzeiten, variabel verzinslichen Anleihen sowie Termingeldern. Ziel ist es, eine möglichst stetige, von Zinsschwankungen weit-gehend unabhängige Rendite zu erwirtschaften.

#### Rentenfonds & ETFs ohne Währungsrisiko

Rentenfonds und ETFs ohne Währungsrisiko investieren in Anleihen, die als Nominalwährung (Transaktionswährung) den Euro aufweisen.`,
    },
    indexesStep: {
      question: {
        a: 'Hast du Anlageklassen mit ',
        b: '(sehr) hohem ',
        c: 'Risiko bereits gehandelt?',
      },
      reason:
        'Uns interessiert deine Erfahrung mit Wert-papieren. Deine Kenntnisse im Krypto-Bereich, kennen wir ja schon.',
      examples:
        'z.B. Aktien, Aktienfonds, Aktien-ETFs gängiger Indizes (EuroStoxx, DAX o.ä.) oder Indexzertifikate ...',
      info: `# Anlageklassen mit (sehr) hohem Risiko
      
#### Aktien & Anleihen von Unternehmen aus DAX & EuroStoxx 50

Aktien (Eigenkapital) und Anleihen (Fremdkapital) dieser Risikoklasse dienen zur Finanzierung von Unternehmen die im DAX und/oder im EURO STOXX 50 gelistet sind.

#### Indexzertifikate

Indexzertifikate ermöglichen Anlegern eins zu eins an der Entwicklung eines Börsenindex teilzuhaben – ohne die zugrunde liegenden Aktien zu kaufen. Jedes Index-Zertifikat weist ein Bezugsverhältnis aus, welches den Wert des Zertifikats im Verhältnis zum Indexstand definiert.

#### Öffentliche Anleihen in Fremdwährung

Dies sind Anleihen eines Staates oder dessen Gebietskörperschaften (z.B. Bund, Länder und Kommunen), die als Nominalwährung (Transaktionswährung) eine andere Währung als den Euro aufweisen.

#### Rentenfonds & ETFs mit Währungsrisiko

Rentenfonds und ETFs mit Währungsrisiko investieren in Anleihen, die als Nominalwährung (Transaktionswährung) eine andere Währung als den Euro aufweisen.

#### Gemischte Fonds/ETFs

Gemischte Fonds/ETFs können anhand ihrer Anlagestrategie gleichzeitig in verschiedene Anlageklassen, wie z.B. Aktien, Anleihen, Immobilien investieren.

#### Strukturierte Anleihen

Dies sind verzinsliche Schuldverschreibungen mit individuellen Zusatzbedingungen (wie z.B. Laufzeit, Rückzahlung und Verzinsung). 

#### Aktienfonds & ETFs

Aktienfonds und ETFs investieren das eingezahlte Kapital ausschließlich in Aktien, andere Anlageklassen sind nicht möglich.

#### Anlagezertifikate

Anlagezertifikate ermöglichen Anlegern, im Rahmen der Ausgestaltung des jeweiligen Anlagezertifikats, an der Wertentwicklung des Basiswerts zu partizipieren ohne diesen direkt erwerben zu müssen.

#### Bezugsrechte

Bezugsrechte berechtigen den Inhaber, unter Berücksichtigung des Bezugsverhältnisses, zum Erwerb neu ausgegebener Aktien im Rahmen einer Kapitalmaßnahme.
                      `,
    },
    leveragedStep: {
      question: {
        a: 'Hast du Anlageklassen mit ',
        b: 'höchstem ',
        c: 'Risiko bereits gehandelt?',
      },
      reason:
        'Uns interessiert deine Erfahrung mit Wert-papieren. Deine Kenntnisse im Krypto-Bereich, kennen wir ja schon.',
      examples:
        'z.B. Hebelzertifikate, sonstige Finanztermingeschäfte oder Aktien & Anleihen aus sonstigen Indizes ...',
      info: `# Anlageklassen mit höhstem Risiko

#### Aktien & Anleihen aus sonstigen Indizes

Aktien (Eigenkapital) und Anleihen (Fremdkapital) dieser Risikoklasse dienen zur Finanzierung von Unternehmen die nicht im DAX oder im EURO STOXX 50 gelistet sind.

#### Hebelzertifikate

Hebelzertifikate sind Derivate (Schuldverschreibungen) die es dem Anleger ermöglichen, eine überproportionale Wertentwicklung gegenüber dem Basiswert zu erzielen.

#### Sonstige Finanztermingeschäfte

Sonstige Finanztermingeschäfte sind Geschäfte zwischen zwei Vertragsparteien zur bedingten (z.B. Option) oder unbedingten (z.B. Future) Erfüllung.
              `,
    },
    contractDocumentsStep: {
      title: 'Vertragsunterlagen',
      text: `Bestätige, dass du die folgenden Dokumente gelesen, gespeichert und akzeptiert hast:`,
      checkbox1: `Ich habe die [AGB der Sutor Bank](http://bisonapp.com/dokumente/wertpapiere); [Sonderbedingungen für Wertpapiergeschäfte der Sutor Bank](http://bisonapp.com/dokumente/wertpapiere); [Preis- und Leistungsverzeichnis](http://bisonapp.com/dokumente/wertpapiere); [Verbraucherinformation](http://bisonapp.com/dokumente/wertpapiere) und [Informationsbogen für Einleger](http://bisonapp.com/dokumente/wertpapiere) gelesen, gespeichert und erkenne sie an.`,
      checkbox2: `Ich habe die [Datenschutzerklärung der Euwax](http://bisonapp.com/dokumente/wertpapiere), [Sonderbedingungen für Wertpapiere der Euwax](http://bisonapp.com/dokumente/wertpapiere), [Vorvertragliche Verbraucherinformationen der Euwax](http://bisonapp.com/dokumente/wertpapiere), [Kundeninformationen über die Euwax und ihre Dienstleistungen](http://bisonapp.com/dokumente/wertpapiere), [Euwax Grundsätze für die Übermittlung von Wertpapieraufträgen](http://bisonapp.com/dokumente/wertpapiere) und die [Datenschutzerklärung blocknox](http://bisonapp.com/dokumente/wertpapiere) gelesen gespeichert und erkenne sie an.`,
      checkbox3: `Ich habe die [Datenschutzerklärung der Sutor Bank](http://bisonapp.com/dokumente/wertpapiere), [Informationen über den Umgang mit Interessenkonflikten](http://bisonapp.com/dokumente/wertpapiere), [Informationen über die Bank und ihre Dienstleistungen](http://bisonapp.com/dokumente/wertpapiere), [Vertragsbedingungen der Sutor Bank](http://bisonapp.com/dokumente/wertpapiere) gelesen, gespeichert und erkenne sie an.`,
      confirmStatement: 'Bestätige die folgende Aussage:',
      checkbox4: `Ich habe die [Basisinformationen über Wertpapiere und weitere Kapitalanlagen](https://postbox.bisonapp.com/message/1.0/pdf/55/1?salutation={{ salutation }}&firstName={{ firstName }}&lastName={{ lastName }}&owner={{ owner }}&title=) zur Kenntnis genommen.`,
      info: `## Vertragsunterlagen
Mit der Freischaltung deines BISON Kontos für den Handel mit Aktien und ETFs gehst du ein erweitertes Vertragsverhältnis ein.

Um Aktien und ETFs in der BISON App anbieten zu können, kooperieren wir mit der Sutor Bank.

Wir behandeln deine Daten selbstverständlich sorgsam und halten uns an die aktuellen gesetzlichen Anforderungen wie die EU-Datenschutzgrundverordnung, das Bundesdatenschutzgesetz sowie weitere anwendbare datenschutzrechtliche Bestimmungen.
`,
    },
    chargebacksStep: {
      title: 'Rückvergütung',
      confirmStatement: 'Bestätige, dass du der folgenden Aussage zustimmst:',
      checkbox:
        'Ich vereinbare, dass mir kein Anspruch auf Herausgabe von Rückvergütungen entsteht. Die Sutor Bank erhält Rückvergütungen von Dritten (z.B. Börsen, Handelspartnern, Fondgesellschaften) und wird diese einbehalten, sofern sie diese nach dem Wertpapierhandelsgesetz (insb. §70 WpHG) annehmen darf.',
      info: `# Rückvergütung

Was sind Rückvergütungen? Rückvergütungen sind sowas wie Provisionen. So erhalten Banken z.B. Provisionen für verkaufte Produkte von Produktanbietern wie Fondgesellschaften. 
`,
    },
    taxIdConfirmation: {
      title: 'Bestätigung deiner Steuerangaben',
      text: 'Bitte überprüfe deine Steuer-Identifikations-Nummer und steuerliche Ansässigkeit. Sollte sie sich geändert haben, kannst du sie direkt hier anpassen.',
      taxIdLabel: 'Steuer-Identifikationsnummer',
      taxResidencyLabel: 'Steuerliche Ansässigkeit',
      errors: {
        empty: 'Bitte gib eine Steuer-ID an.',
        tooShort: 'Die angegebene Steuer-ID ist zu kurz. Bitte gib eine Steuer-ID mit 11 Zahlen an.',
        tooLong: 'Die angegebene Steuer-ID ist zu lang. Bitte gib eine Steuer-ID mit 11 Zahlen an.',
        notNumber:
          'Die angegebene Steuer-ID enthält Buchstanden oder Sonderzeichen. Bitte gib eine Steuer-ID mit 11 Zahlen an.',
      },
    },
    addressConfirmation: {
      title: 'Bestätigung deiner Adresse',
      text: 'Bitte überprüfe Namen und Adresse. Sollte sich etwas geändert haben, kannst du es hier direkt anpassen.',
      twoFa: {
        title: 'Änderung deiner Adresse bestätigen',
        mainText:
          'Bitte bestätige deine Adressänderung in dem du den Sicherheitscode eingibst, den wir dir per SMS an <1>{{phone}}</1> gesendet haben.',
        button: 'Weiter zur Freischaltung',
      },
      twoFaResult: {
        successTitle: 'Deine Adresse wurde erfolgreich geändert.',
        errorTitle: 'Dein Sicherheitscode ist ungültig',
      },
    },
    residenceAndCitizenshipConfirmation: {
      title: 'Bestätigung deiner Daten',
      text: 'Bitte überprüfe Wohnsitz und Staats-angehörigkeit. Sollte sich etwas geändert haben, kannst du es hier direkt anpassen.',
      residenceLabel: 'Land des Wohnsitzes',
      residencePlaceholder: 'Land auswählen',
      citizenshipLabel: 'Staatsangehörigkeit',
      citizenshipPlaceholder: 'Staatsangehörigkeit auswählen',
      errors: {
        emptyResidence: 'Bitte wähle ein Wohnsitzland aus.',
        emptyCitizenship: 'Bitte wähle eine Staatsangehörigkeit aus.',
      },
    },
    lastNotesStep: {
      title: 'Letzte Hinweise',
      sectionOneHeader: 'Verzicht auf Beratung',
      sectionOneText:
        'Die Sutor Bank führt für dich Wertpapieraufträge aus und erbringt keine Beratung zur Anlage in  Finanzinstrumenten. Die Sutor Bank ist nur zur Prüfung der Angemessenheit der Finanzinstrumente oder Wertpapierdienstleistungen verpflichtet.',
      sectionTwoHeader: 'Bankenabwicklung & Gläubigerbeteiligung',
      sectionTwoText:
        'Hinweise zur Gläubigerhaftung für Anteilseigner oder Gläubiger einer Bank findest du hier.',
      sectionThreeHeader: 'Kirchensteuerabfrage',
      sectionThreeText:
        'Deine Kirchensteuermerkmale können beim BZST abgefragt werden. Ggf. kann Kirchensteuer aus Kapitalerträgen einbehalten werden. Du hast die Möglichkeit Widerspruch einzulegen.',
    },
    pendingStep: {
      title: 'Freischaltung bereits beantragt',
      text: {
        a: 'Du hast die Freischaltung bereits erfolgreich beantragt.\n\nEs ',
        b: 'kann bis zu 15 Minuten dauern ',
        c: 'bis die Freischaltung bestätigt ist und du Aktien & ETFs handeln kannst. Wir benachrichtigen dich per E-mail sobald die Freischaltung erfolgt ist.',
      },
      support: 'Noch offene Fragen? Wir helfen dir gerne, kontaktiere uns gerne jederzeit unter ',
    },
    finishedStep: {
      title: 'Freischaltung für Aktien & ETFs erfolgreich beantragt',
      text: 'Danke für die Angabe deiner Daten. Es dauert nun <bolded>noch etwa 15 Minuten</bolded> bis du Aktien & ETFs handeln kannst. Wir benachrichtigen dich per E-mail sobald die Freischaltung erfolgt ist.',
      specialInfo: 'Beachte: Die Freischaltung kann in Ausnahmefällen bis zu 48 Stunden dauern.',
    },
    title: 'Hast du diese Anlageklassen bereits gehandelt?',
    securities: 'Aktien & ETFs',
    indexes: 'Aktien gängiger Indizes (EuroStoxx, DAX o.ä.) oder vergleichbare ETFs',
    leveraged: 'Gehebelte Aktien / ETFs (abgesehen von Standardindizes wie EuroStoxx, DAX o.ä.)',
    error: 'Bitte wähle eine Option aus.',
    successTitle: 'Request submitted successfully.',
    errors: {
      title: 'Something went wrong ...',
      invalidTIN: 'Invalid German Tax ID number',
      nonGermanResident: 'You are not a German resident',
      noGermanPhoneNumber: 'You need to have a valid german phone number',
    },
  },
  kyc: {
    exit: 'Abbrechen',
    warningClosed: `Leider ist eine Verifizierung deines BISON Kontos aktuell nicht möglich.\n\nDie Verifizierung kannst du Montag bis Sonntag von {{from}} - {{to}} Uhr durchführen.`,
    closesSoon: 'Warnung: Der Service zur Video-Verifizierung schließt {{time}}.',
    warningRestart:
      'Bitte beachte: Wenn du dich bereits erfolgreich verifiziert hast, wird eine weitere Verifizierung deinen bisherigen Status überschreiben. Wenn deine Verifizierung nicht erfolgreich war, kannst du dies erneut versuchen.',
    warningRestartConfirm: 'Verstanden',
    verificationDocuments: {
      id: 'Personalausweis',
      passport: 'Reisepass & Rechnung',
    },
    introStep: {
      a1: 'Für deine Verifizierung bei BISON sind nur noch ein paar Schritte nötig. Halte dafür bitte ein gültiges',
      a2: 'Ausweisdokument', // This is emphasized
      a3: 'bereit.',
      b: 'Bitte beachte, eine Verifizierung musst du nur durchführen, wenn du mit Echtgeld handeln möchtest.',
      c: '1. Daten eingeben\n2. Video-Verifizierung deiner Daten\n3. Erfolgreiche Verifizierung',
      web_d: 'Die Video-Verifizierung ist über Google Chrome, die BISON App und die IDNOW App möglich.',
      next: 'Weiter',
    },
    checkboxStep: {
      a: 'BISON ist **der Schutz deiner Daten** wichtig.\n\nUm die Verifizierung zu starten, bitten wir dich, folgende Dokumente durchzulesen und zu bestätigen.',
      agb: {
        a: 'Ja, ich habe die',
        b: 'AGB der EUWAX', // link to www.bisonapp.com/dokumente
        c: ', die',
        d: 'AGB der blocknox', // link to www.bisonapp.com/dokumente
        e: ', die',
        f: 'AGB der Solaris', // link to www.bisonapp.com/dokumente
        g: 'und die',
        h: 'Sonderbedingungen der Solaris', // link to www.bisonapp.com/dokumente
        i: 'gelesen, gespeichert und erkenne diese an.',
      },
      datenschutz: {
        a: 'Ich habe die',
        b: 'Datenschutzerklärungen', // link to www.bisonapp.com/dokumente
        c: 'gelesen, gespeichert und erkenne diese an.',
      },
      nichtInUSA: 'Ich bin nicht in den USA steuerpflichtig.',
      ownAccount: 'Ich handle bei BISON ausschließlich auf eigene Rechnung und im eigenen Namen.',
      newsletter:
        'Ja, ich möchte mich zum Newsletter BISONews anmelden und weiterhin auf dem Laufenden bleiben (freiwillig).',
      nichtInUSAInfo: `Bist du US-Staatsbürger (auch doppelte Staatsbürgerschaft), hast du einen Wohnsitz bzw. eine ständige Aufenthaltsbewilligung in den USA oder bist du aus einem anderen Grund in den USA steuerpflichtig?\n\nLeider können Personen, die in den USA steuerpflichtig sind, nicht in der BISON App Kryptowährungen kaufen und verkaufen.`,
      next: 'Weiter',
      errors: {
        agb: 'Bevor es weiter gehen kann, bitten wir dich, die AGBs zu bestätigen.',
        datenschutz: 'Bevor es weiter gehen kann, bitten wir dich, die Datenschutzerklärungen zu bestätigen.',
        nichtInUSA:
          'Wenn du in den USA steuerpflichtig bist, können wir leider keinen Account für dich eröffnen.',
        ownAccount:
          'Wenn du nicht ausschließlich auf eigene Rechnung und im eigenen Namen handelst, können wir leider keinen Account für dich eröffnen.',
      },
    },
    cryptoRiskStep: {
      next: 'Weiter',
      title: 'Risikohinweise',
      description: {
        a: 'Investitionen in Kryptowährungen sind mit hohen Risiken verbunden und können neben den Erträgen auch zu erheblichen Verlusten des eingesetzten Kapitals führen. Im schlimmsten Fall kann dein Investment sogar zu einem Totalverlust führen.',
        b: 'Die ',
        c: 'Basis- und Risikoinformationen ', // Link to webpage
        d: 'enthalten einige allgemeine Informationen, jedoch keine individuellen Hinweise. Bitte beachte, dass sich die Angebote zum Handel und zur Verwahrung von Kryptowährungen ausschließlich an gut informierte und erfahrene Anleger richten, die eine hohe Risikobereitschaft mitbringen und finanziell in der Lage sind, Verluste zu tragen. Eine persönliche Beratung erfolgt nicht. Gegebenenfalls solltest du vor der Registrierung einen geeigneten Berater konsultieren.',
      },
      cryptoRisk:
        'Ja, das habe ich verstanden. Die Basis- und Risikoinformationen habe ich gespeichert, gelesen und ebenfalls verstanden.',
      errors: {
        cryptoRisk:
          'Zu deinem Schutz können wir leider kein BISON-Konto für dich eröffnen, wenn du nicht über ausreichend Kenntnisse oder Erfahrungen verfügst, keine hohe Risikobereitschaft mitbringst oder nicht finanziell in der Lage bist, auch einen Totalverlust deines eingesetzten Kapitals zu verkraften.',
      },
    },
    nameAndSurnameStep: {
      firstName: 'Vorname',
      lastName: 'Nachname',
    },
    birthInfoStep: {
      next: 'Weiter',
      dateOfBirth: 'Geburtsdatum',
      birthCity: 'Geburtsort',
      birthCountry: 'Geburtsland',
      errors: {
        dateOfBirth: {
          missing: 'Bitte gib dein Geburtsdatum ein',
          under18: 'Du musst mindestens 18 Jahre alt sein, um bei BISON mit Echtgeld handeln zu dürfen.',
        },
        birthCity: {
          required: 'Bitte gib deinen Geburtsort ein',
        },
        birthCountry: {
          required: 'Bitte wähle dein Geburtsland aus',
        },
      },
    },
    employmentStatusStep: {
      title: 'Berufliche Tätigkeit',
      infoModal: `### Berufliche Tätigkeit\n\nInformationen zu deiner beruflichen Tätigkeit helfen uns, Geldeingänge auf deinem Konto besser zu verstehen. So können wir eine potenzielle Beeinträchtigung der Nutzung unseres Dienstes vermeiden.`,
    },
    nationalityStep: {
      next: 'Weiter',
      country: 'In welchem Land lebst du?',
      nationality: 'Staatsangehörigkeit',
      errors: {
        country: {
          required: 'Bitte wähle ein Land aus',
          notSupported: 'Entschuldige, aber BISON ist noch nicht in diesem Land verfügbar.',
        },
        nationality: {
          required: 'Bitte wähle deine Staatsangehörigkeit aus',
          notSupported:
            'Entschuldige, aktuell akzeptieren wir leider keine Nutzer mit dieser Staatsangehörigkeit.',
        },
      },
    },
    taxInfoStep: {
      taxId: 'Steuer-ID (freiwillige Angabe)',
      taxIdNotOptional: 'Steuer-ID',
      taxIdRequired: 'Steuer-ID',
      taxIdCountry: 'Steuerliche Ansässigkeit (freiwillige Angabe)',
      taxIdCountryNotOptional: 'Steuerliche Ansässigkeit',
      taxIdInfo: `Deine Steuer-ID wird gesetzlich von den Steuerbehörden als freiwillige Angabe verlangt. Wenn du in mehreren Staaten steuerpflichtig bist, kannst du, nach einer erfolgreichen Verifizierung, deine weiteren **Steuer-IDs** und **steuerlichen Ansässigkeiten** innerhalb deines BISON-Kontos unter »Einstellungen« und »Persönliche Information« nachtragen.\n\nSteuerpflichtige in Deutschland finden die Steuer-ID beispielsweise auf der Lohnsteuerbescheinigung, dem Einkommenssteuerbescheid oder auf Schreiben vom Finanzamt.`,
      germanBankAcc: {
        a: 'Ja, ich habe ein',
        b: 'SEPA-fähiges Bankkonto, ', // Emphasized
        c: 'das auf meinen Namen lautet.',
        info: 'Einzahlungen auf dein BISON Konto sind nur von einem SEPA-fähigen Bankkonto möglich.\n\nUnter SEPA versteht man den Europäischen Zahlungsraum, zu welchem so gut wie alle europäischen Banken gehören.',
      },
      errors: {
        taxId: 'Bitte gebe eine gültige Steuer-ID an (max. 20 Zeichen)',
        taxIdRequired: 'Bitte trage deine Steuer-ID ein.',
        taxIdCountryRequired: 'Bitte wähle deine steuerliche Ansässigkeit aus.',
        germanBankAcc: 'Bitte bestätige uns, dass du ein SEPA-fähiges Bankkonto besitzt.',
      },
    },
    introBillStep: {
      web_a:
        'Bitte beachte, dass eine Verifizierung mit deinem Reisepass mehr Zeit in Anspruch nimmt und du zusätzlich eine aktuelle ausgedruckte Rechnung benötigst.',
      web_next: 'Weiter',
    },
    kycOptionsStep: {
      web_start: 'Video-Verifizierung starten',
      web_description: `Nun musst du nur noch deine eingegebenen Daten via **Video-Verifizierung** bestätigen.\n\nBitte beachte, dass hierfür ein Gerät mit Mikrofon und Kamera nötig ist.`,
      web_detailsBold: `Video-Verifizierung mit einem anderen Gerät durchführen?`,
      web_details: `Checke dein E-Mail-Postfach für weitere Optionen.`,
      web_helpLink: 'Problem?',
      web_helpText: `<0>Wenn du die Verifizierung erfolgreich abgeschlossen hast, bitte beachte, dass die Bestätigung deiner Verifizierung in der BISON App mehrere Werktage dauern kann. </0>
                <1>In Ausnahmefällen werden wir uns per E-mail an dich wenden, um zusätzliche Unterlagen anzufordern. Sobald wir deine Bestätigung erhalten haben, benachrichtigen wir dich per E-Mail.
                Falls du dich in die Warteliste eingetragen hast und per SMS benachrichtigt wurdest, dass ein Mitarbeiter verfügbar ist, kannst du die Verifizierung jetzt starten.</1>
      `,
    },
    chooseVerificationDocumentStep: {
      idOrPassBill: {
        a: 'Nun bitten wir dich, deinen gültigen Ausweis bereit zu halten, bevor es zum Video-Anruf geht.',
        b: 'Jetzt heißt es: Frisur nochmal checken und ab zum Video-Anruf, damit wir deine eingegebenen Daten verifizieren können.',
      },
      next: 'Weiter',
      nextAlt: 'Weiter',
      passportLink: {
        a: 'Ich habe keinen Personalausweis.',
        b: 'Hier mit Reisepass verifizieren.', // Emphasized
      },
      errors: {
        selectVerificationDocument: 'Bitte wähle einen Ausweis aus.',
      },
    },
    addressStep: {
      a: 'Deine aktuelle Adresse',
    },
    billStep: {
      a1: 'Mit welchem Typ Rechnung möchtest du dich verifizieren?',
      a2: {
        idOrPassBill: 'Personalausweis oder deinen Reisepass ',
        idBillOrPassBill: 'Personalausweis oder deinen Reisepass ',
        idBill: 'Personalausweis',
        passBill: 'Reisepass ',
      },
      a3: 'Bitte beachte, dass deine Adresse auf der Rechnung',
      a4: 'sichtbar',
      a5: 'ist und diese',
      a6: 'nicht älter als 6 Monate',
      a7: 'sein darf.',
      web_a: 'Für deine Verifizierung bei BISON musst du dich mit deinem',
      web_b: 'in Kombination mit einer aktuellen',
      web_c: 'ausgedruckten Rechnung', // Emphasized
      web_d: 'ausweisen.',
      next: 'Weiter',
      billType: 'Bitte auswählen',
      billDate: 'Rechnungsdatum',
      errors: {
        billType: 'Bitte wähle einen Rechnungstyp aus',
        billDate: {
          olderThan6M: 'Deine Rechnung darf nicht älter als 6 Monate sein',
          futureDate: 'Grüße, lieber Zeitreisender BISON!',
          required: 'Bitte gib das Rechnungsdatum ein',
        },
      },
      billTypes: {
        bankAccountStatement: 'Kontoauszug',
        internetBill: 'Internetrechnung',
        telephoneBill: 'Telefonrechnung',
        electricityBill: 'Elektrizitätsrechnung',
        gasBill: 'Gasrechnung',
        heatingBill: 'Heizkostenabrechnung',
        waterBill: 'Wasserrechnung',
      },
    },
    billAddrStep: {
      next: 'Weiter',
      a: 'Deine Adresse auf der Rechnung',
      billStreet: 'Straße',
      billStreetNumber: 'Nr.',
      billPostNumber: 'PLZ',
      billCity: 'Stadt',
      billCountry: 'Land',
      addressSwitchModal: {
        title: 'Wir haben die Eingabe deiner Adresse korrigiert',
        subtitle:
          'Bitte überprüfe, ob die von uns korrigierte Adresse mit deiner aktuellen Adresse übereinstimmt.',
        labelOriginal: 'Ursprüngliche Adresse',
        labelSuggestion: 'Korrigierte Adresse',
        confirmButton: 'Adresse übernehmen',
        cancelButton: 'Weiter mit meiner Angabe',
      },
      confirmAddressModal: {
        title: 'Bist du dir sicher, dass deine Adresse korrekt ist?',
        subtitle:
          'Bitte überprüfe, ob die von dir gemachten Angaben korrekt sind und ändere diese gegebenenfalls.',
        label: 'Angegebene Adresse',
        confirmButton: 'Die Adresse ist korrekt',
        editButton: 'Adresse ändern',
      },
      nameChangedModal: {
        title: 'Änderung deines Namens',
        subtext1:
          'Um deinen Namen zu ändern, benötigen wir einen Nachweis aus dem hervorgeht, dass sich dein Name geändert hat. Dies kann z.B. ein offizielles behördliches Dokument sein, welches die Namensänderung bestätigt.',
        actionText: 'Sende deinen Nachweise bitte an:',
        supportEmailText: 'support@bisonapp.com',
        subtext2:
          'Keine Sorge dein Fortschritt in der Freischaltung von Aktien & ETFs geht nicht verloren. Sobald die Änderung über den Support vorgenommen wurde, erhältst du eine E-Mail von der aus du über einen Button direkt wieder in die Freischaltung gelangst.',
        subtext3:
          'Beachte: Dafür müssen wir auf unsere Partnerbank, die SolarisBank zugehen. Auf die Bearbeitungsdauer haben wir leider keinen Einfluss. Erfahrungsgemäß erhalten wir innerhalb von einer Woche Rückmeldung.',
      },
    },
    finalBillStep: {
      a: 'Bitte halte folgende Dokumente bereit:',
      c: 'Ausgedruckte Rechnung',
      d: 'Jetzt heißt es: Frisur nochmal checken und ab zum Video-Anruf, damit wir deine eingegebenen Daten verifizieren können.',
      next: 'Weiter',
      idOrPassBill: {
        b: 'Gültiger Reisepass',
      },
      idBillOrPassBill: {
        b: 'Gültiger Reisepass oder Personalausweis',
      },
      idBill: {
        b: 'Gültiger Personalausweis',
      },
      passBill: {
        b: 'Gültiger Reisepass',
      },
    },
    employmentInformationStep: {
      next: 'Weiter',
      explanationLabel: 'Warum ist das erforderlich?',
      explanation:
        'Diese Informationen helfen uns, Geldeingänge auf deinem Konto besser zu verstehen und eine Beeinträchtigung der Nutzung unseres Dienstes zu vermeiden.',
      employmentStatusLabel: 'Was ist dein derzeitiges Arbeitsverhältnis?',
      professionalFieldLabel: 'Was ist dein derzeitiges Beschäftigungsfeld?',
      incomeSourceLabel: 'Woher beziehst du das Geld, das du bei BISON investieren möchtest?',
      netIncomeLabel: 'Wie hoch ist dein jährliches Nettoeinkommen in Euro?',
      netIncomePlaceholder: 'Beispiel: {{exampleValue}}',
      employmentStatusTypes: {
        employee: 'Angestellt',
        leadingEmployee: 'Leitend angestellt',
        selfEmployed: 'Selbstständig (oder Firmeninhaber)',
        retired: 'In Rente',
        notEmployed: 'Nicht beschäftigt',
        student: 'Student',
      },
      professionalFieldTypes: {
        aerospaceAndDefense: 'Luftfahrt & Verteidigung',
        agricultureAndMining: 'Landwirtschaft & Bergbau',
        architectureAndEngineering: 'Architektur und Ingeneurwesen',
        businessServicesAndConsulting: 'Dienstleistungen und Beratung für Unternehmen',
        computerAndInformationTechnol: 'Computer und IT-Technologie',
        education: 'Bildung',
        energyAndUtilities: 'Energie & Versorgung',
        environmental: 'Umweltbranche',
        financeAndBanking: 'Finanzen & Bankwesen',
        foodAndBeverages: 'Lebensmittel & Getränke',
        gamingAndSports: 'Gaming & Sport',
        governmentPublicSector: 'Regierung / Öffentlicher Sektor',
        healthcarePharmaAndBiotech: 'Gesundheitswesen Pharma & Biotech',
        humanitarianAndNonProfit: 'Gemeinnützige Organisation',
        legalAndCompliance: 'Recht & Compliance',
        manufacturingAndEngineering: 'Fertigung & Produktion',
        mediaAndEntertainment: 'Medien & Unterhaltung',
        other: 'Andere',
        realEstateAndConstruction: 'Immobilien und Baugewerbe',
        retailAndSales: 'Einzelhandel & Vertrieb',
        softwareAndInternet: 'Software & Internet',
        transportationAndStorage: 'Logistik & Lagerung',
        travelAndHospitality: 'Reisen & Gastgewerbe',
      },
      incomeSourceTypes: {
        salary: 'Gehalt (persönliches Einkommen)',
        savings: 'Erspartes',
        heritage: 'Erbe',
        investments: 'Ertrag aus vorherigen Investments',
        pension: 'Pension',
        loan: 'Bankkredit',
      },
    },
    financialInformationStep: {
      netWealthLabel: 'Wie hoch ist ungefähr dein Nettovermögen?',
      investmentTargetLabel: 'Welche Summe planst du im Zeitraum eines Jahres bei BISON zu investieren?',
      cryptoDepositLabel: 'Planst du Kryptowährungen auf BISON einzuzahlen?',
      cryptoDepositSourceLabel: 'Woher stammen diese Währungen dann ursprünglich?',
      netWealthRanges: {
        lessThan20000: 'Bis zu 20.000€',
        from20000To49999: '20.000€ bis 49.999€',
        from50000To99999: '50.000€ bis 99.999€',
        from100000To199999: '100.000€ bis 199.999€',
        from200000To250000: ' 200.000€ bis 250.000€',
        moreThan250000: 'Mehr als 250.000€',
      },
      investmentTargetRange: {
        lessThan5000: 'Bis zu 5.000€',
        from5000To9999: '5.000€ bis 9.999€',
        from10000To19999: '10.000€ bis 19.999€',
        from20000To49999: '20.000€ bis 49.999€',
        from50000To100000: '50.000€ bis 100.000€',
        moreThan100000: 'Mehr als 100.000€',
      },
      cryptoDepositSourcePlaceholder: 'Beispiel: Kraken, Coinbase, Binance',
      cryptoDepositSourceRequired: 'Bitte gib mindestens eine Quelle an',
      yes: 'Ja',
      no: 'Nein',
    },
    precontractualMailStep: {
      next: 'Weiter',
      title: 'Bevor wir weitermachen…',
      main: 'Du wirst dich in Kürze für ein auf deinen Namen registriertes BISON-Guthabenkonto bei Solaris verifizieren, das ausschließlich für Transaktionen mit FIAT-Fonds verwendet wird, und du wirst ausschließlich in deinem eigenen Namen handeln.',
      details: {
        intro: 'Ich bestätige, dass ich hiermit ein Konto in eigenem Namen eröffne und bestätige folgendes:',
        a: 'Ich allein trage die rechtliche Verantwortung für alle Kontobewegungen',
        b: 'Ich handele nur im eigenen wirtschaftlichen Interesse und nicht auf Veranlassung eines Dritten.',
        c: 'Ich verstehe, dass Solaris nur das Guthabenkonto zur Verfügung stellt und nicht als Vermittler oder Verwahrer für die Kryptobörse fungiert.',
      },
      checkboxText:
        'Ich bestätige, dass ich die oben genannten Informationen gelesen und zur Kenntnis genommen habe.',
      error: 'Bitte bestätige deine Daten',
    },
    newsletter: {
      description: {
        a: `Toll, dass du von BISON gehört hast!

Echtgeldhandel mit BISON ist in deinem Land oder mit deiner Staatsangehörigkeit leider noch nicht verfügbar.
        `,
        b: `Zeig uns trotzdem dein Interesse und melde dich zu unserem BISON Newsletter an.`,
        c: `Du bist bereits in unserem Newsletter angemeldet und wirst benachrichtigt, wenn wir in deinem Land vertreten sein werden.`,
      },
      email: 'E-Mail',
      newsletter: 'Ja, ich möchte mich zum Newsletter anmelden.',
      errors: {
        newsletterRequired: 'Du musst diese Checkbox bestätigen, um dich anzumelden.',
        problemSubscribing:
          'Beim Anmelden zum Newsletter ist ein Fehler aufgetreten. Bitte versuche es erneut.',
      },
      allClear: 'OK',
      signUp: 'Abonnieren',
      successTitle: 'Fast fertig...',
      success: `Wir müssen deine E-Mail Adresse bestätigen. Um die Anmeldung abzuschließen, klicke bitte auf den Link in der E-Mail, die wir dir gerade gesendet haben.`,
    },
    success: {
      title: 'Prüfung deiner Freischaltung für den Kryptohandel',
      infoText: {
        a: 'Sieht gut aus {{firstName}}. Wenn du die Verifizierung gerade erfolgreich abgeschlossen hast, bitte beachte, dass die endgültige Bestätigung deiner Verifizierung in der BISON App mehrere Werktage dauern kann.',
        b: 'In Ausnahmefällen werden wir uns per E-mail an dich wenden, um zusätzliche Unterlagen anzufordern. Sobald wir deine Bestätigung erhalten haben, benachrichtigen wir dich per E-Mail.',
        c: 'Falls du dich in die Warteliste eingetragen hast, wirst du per SMS benachrichtigt, sobald ein freier Mitarbeiter verfügbar.',
        text: `Du hast den Freischaltungsprozess für den Kryptohandel erfolgreich durchlaufen. Wir prüfen derzeit deine Daten.<br/><br/> Wir <strong>benachrichtigen dich per E-Mail,</strong> wenn deine Verifizierung bestätigt wurde und du mit dem Kryptohandel starten kannst.<br/><br/> Beachte: Es kann in Ausnahmefällen einige Werktage dauern oder vorkommen, dass wir uns per E-Mail an dich wenden, um zusätzliche Unterlagen anzufordern.`,
      },
      invalidInfoText: 'Leider hat deine Verifizierung nicht geklappt.',
      successBanner: {
        title: 'Verifizierung erfolgreich bestätigt',
        subTitle: 'Du kannst nun mit Echtgeld handeln.',
      },
      status: 'Status',
      refresh: 'Aktualisieren',
      refreshed: 'Aktualisiert',
      tryAgain: 'Erneut versuchen',
    },
    forms: {
      sending: 'Sende...',
      errors: {
        title: 'Einreichung fehlgeschlagen',
        alreadyConfirmed:
          'Du bist bereits erfolgreich verifiziert. Bitte Gedulde dich noch einen Augenblick.',
        invalidTaxId:
          'Bitte gib eine gültige Steuer-ID an. Deutsche Steuer-IDs bestehen aus maximal 11 Zahlen und starten nie mit einer 0.',
      },
    },
    idNow: {
      askForPermissionsTitle: 'Dürfen wir auf dein Mikrofon und deine Kamera zugreifen?',
      askForPermissionsMessage:
        'Wir benötigen deine Erlaubnis, damit du die Verifizierung abschließen kannst.',
      denyPermissions: 'Nein',
      acceptPermissions: 'OK',
      openSettings: 'Einstellungen öffnen',
      success: 'Video ID abgeschlossen',
      successMessage: 'Wir werden dich benachrichtigen, sobald die Verifizierung bestätigt ist.',
      errors: {
        title: 'Identifizierung fehlgeschlagen',
        E_NO_PERMISSION:
          'Bitte akzeptiere die erforderlichen Berechtigungen, um die Verifizierung abzuschließen',
        // RNIdNow Error Codes
        E_INIT_ERROR: 'Bei der Initialisierung ist ein Fehler aufgetreten. Bitte versuche es erneut.',
        E_PARAMS_ERROR:
          'Die Identifizierung ist fehlgeschlagen, weil die erforderlichen Berechtigungen fehlen.',
        E_IDENT_START_ERROR: 'Die Identifizierung konnte nicht gestartet werden. Bitte versuche es erneut.',
        E_IDENT_CANCELED: 'Prozess wurde abgebrochen',
        E_IDENT_FAILED: 'Bei der Identifizierung ist etwas schiefgelaufen. Bitte versuche es erneut.',
        E_IDENT_GENERIC_FAIL: 'Bei der Identifizierung ist etwas schiefgelaufen. Bitte versuche es erneut.',
      },
    },
    afterKycPhoneSubmission: {
      title: 'Angabe deiner Handynummer',
      text: 'Um deine Verifizierung abzuschließen, ist die Angabe deiner Handynummer notwendig. Hinterlege sie im nächsten Schritt.',
      buttonText: 'Jetzt hinterlegen',
    },
    renewDocs: {
      redirect: {
        title: 'Dein Ausweisdokument ist abgelaufen!',
        mainText: `
Bitte **öffne die BISON App** auf deinem Smartphone und starte die Freischaltung von dort. Du wirst in der App gebeten, ein gültiges Ausweisdokument zu hinterlegen. 
        
Für den Handel mit Aktien & ETFs kannst du dich direkt im Anschluss freischalten.`,
      },
      intro: {
        title: 'Dein Ausweisdokument ist abgelaufen!',
        mainText: `Bitte hinterlege im nächsten Schritt ein **gültiges Ausweisdokument**, um deine Identität erneut zu verifizieren.\n\nFür den Handel mit Aktien und ETFs kannst du dich direkt im Anschluss freischalten.`,
        verifyNewDocuments: 'Neues Dokument hinterlegen',
      },
      chooseIdentificationDocument: {
        title: 'Womit möchtest du deine Identität erneut verifizieren?',
        text: `Bitte wähle aus, mit welchem **gültigen Ausweisdokument** du dich verifizieren möchtest.`,
        info: `## Dokument zur Identitätsverifizierung\n\nDu benötigst ein gültiges Identitätsdokument, damit wir sicherstellen können, dass nicht etwa andere Person mit fremden Daten versuchen, ein BISON Konten in deinem Namen zu eröffnen.\n\nBitte halte dein **gültiges Ausweisdokument** im nächsten Schritt für die Verifizierung bei unserem Partner IDnow bereit.\n\nZur Verifizierung mit einem **Reisepass** benötigst du außerdem eine **aktuelle Rechnung**. Die Rechnung muss ausgedruckt vorliegen, darf nicht älter als 6 Monate sein und muss deine Meldeadresse aufzeigen.`,
        idCard: 'Personalausweis',
        passport: 'Reisepass',
        continue: 'Weiter',
      },
      billStep: {
        modal: {
          title: 'Hinweise zur Verifizierung mit Reisepass',
          text: `Zur Verifizierung mit einem Reisepass benötigst du zusätzlich eine **aktuelle Rechnung.**`,
        },
        title: 'Verifizierung mit Reisepass',
        text: `Zur Verifizierung mit einem Reisepass benötigst du zusätzlich eine **aktuelle Rechnung**.\n\nBitte beachte: Die Rechnung muss ausgedruckt vorliegen, darf nicht älter als 6 Monate sein und muss deine Meldeadresse aufzeigen.`,
      },
      addressStep: {
        title: '',
      },
      videoCallStep: {
        title: 'Letzter Schritt: Video-Identifikation',
        textPassportOnly: `Im letzten Schritt folgt ein Video-Anruf mit unserem Partner IDnow.\n\nBitte halte dazu deinen **Reisepass und die von dir angegebene Rechnung** bereit.`,
        textIdOnly: `Im letzten Schritt folgt ein Video-Anruf mit unserem Partner IDnow.\n\nBitte halte dazu deinen **Personalausweis** bereit.`,
        button: 'Weiter zum Video-Anruf',
      },
      pending: {
        title: 'Wir prüfen dein neues Ausweisdokument',
        text: {
          a: `Danke für das Hinterlegen deines neuen Ausweisdokuments. Wir benachrichtigen dich, wenn es geprüft wurde. Danach kannst du dein BISON-Konto für Aktien & ETFs freischalten.`,
          b: `Die Prüfung dauert in der Regel 15 Minuten. In Ausnahmefällen kann es auch bis zu 2 Tage dauern.`,
        },
        modal: {
          title: `Wir prüfen gerade dein neues Ausweisdokument`,
          text: `In der Regel ist die Prüfung innerhalb von 15 Minuten abgeschlossen. In Ausnahmefällen kann es auch bis zu 2 Tage dauern. Wir **benachrichtigen dich per E-Mail**, sobald die Freischaltung erfolgt ist.`,
        },
      },
      fail: {
        title: 'Neues Ausweisdokument konnte nicht hinterlegt werden',
        text: `Bitte kontaktiere unseren Support unter [Kundenservice Formular]({{supportLink}}).`,
        modal: {
          title: `Dein neues Ausweisdokument konnte nicht hinterlegt werden`,
          text: `Bitte kontaktiere unseren Support unter [Kundenservice Formular]({{supportLink}}).\n\nSobald dein Ausweisdokument erfolgreich hinterlegt wurde, kannst du mit der Freischaltung deines BISON Kontos für Aktien und ETFs fortfahren.`,
        },
      },
      success: {
        title: 'Dein neues Ausweisdokument wurde erfolgreich hinterlegt',
        text: `Nur noch wenige Schritte bis du dein BISON Konto für Aktien und ETFs freigeschaltet hast.`,
        continueToActivation: 'Weiter zur Freischaltung',
      },
      exitIntentModal: {
        title: `Du möchtest kein neues Ausweisdokument hinterlegen?`,
        text: `Beachte: Ohne die Verifizierung deiner Identität mit einem gültigen Ausweisdokument, kannst du dich nicht für den Handel von Aktien & ETFs freischalten.`,
        submitNewDocument: `Neues Dokument hinterlegen`,
        later: `Später`,
      },
    },
    securities: {
      button: 'Weiter',
      intro: {
        title: 'Handle jetzt auch Aktien & ETFs.',
        mainText:
          'Schalte dein Konto ganz einfach für Aktien & ETFs frei und starte noch heute mit Wertpapieren durch.',
        ok: 'Jetzt freischalten',
        cancel: 'Später',
      },
      failure: {
        title: 'Sorry {{firstName}}, es ist etwas schief gelaufen...',
        mainText: {
          a: 'Leider konnte der Verifizierungsprozesses nicht abgeschlossen werden.\n\nBitte kontaktiere unseren Kundenservice',
          b: '. Der Support wird dir dabei helfen, dass du deine Verifizierung so schnell wie möglich fortsetzen und mit dem Handel mit Wertpapieren loslegen kannst.',
        },
        button: 'Jetzt Support kontaktieren',
        mail: {
          subject: 'Probleme während der Verifizierung',
          body: 'Hallo BISON Team,\nwährend meines Verifizierungsprozesses sind Schwierigkeiten aufgetreten, weshalb die Verifizierung nicht erfolgreich abgeschlossen werden konnte. Damit ich so schnell wie möglich mit dem Handel starten kann, bitte ich um eure Unterstützung',
        },
      },
      eIdData: {
        title: 'Überprüfung deiner Ausweisdaten',
        info: `## Wo finde ich die benötigten Angaben 
## auf meinem Ausweis?

Die Ausweisnummer (auch Dokumentennummer) ist 9-stellig. Du findest sie oben rechts auf der Vorderseite.

Das Datum bzw. den Tag der Ausstellung und die ausstellende Behörde, findest du auf der Rückseite oben links unter Datum und Behörde.
        `,
        note: 'Beachte: Wir benötigen den vollständigen Namen der ausstellenden Behörde. Dieser kann unter Umständen sehr lang sein und über 2 Zeilen laufen.',
        legitimationDocumentNumber: {
          label: 'Ausweisnummer',
          placeholder: 'z.B. T220001293',
          required:
            'Bitte gib deine Ausweisnummer an. Sie befindet sich oben rechts auf der Vorderseite deines Personalausweises.',
          min: 'Die angegebene Ausweisnummer ist zu kurz. Bitte gib eine Ausweisnummer mit 9 Zeichen an.',
          max: 'Die angegebene Ausweisnummer ist zu lang. Bitte gib eine Ausweisnummer mit 9 Zeichen an.',
        },
        legitimationIssuedAt: {
          label: 'Datum (Tag der Ausstellung)',
          required: 'Bitte gib ein Datum an.',
          min: 'Das von dir angegebene Datum liegt zu weit in der Vergangenheit. Bitte überprüfe deine Angabe und passe das Datum an.',
        },
        legitimationIssuer: {
          label: 'Behörde (Ausstellende Behörde)',
          placeholder: 'z.B. Stadt Köln',
          required:
            'Bitte gib die ausstellende Behörde an. Diese findest du oben links auf der Rückseite deines Personalausweises.',
          max: 'Die angegebene Behörde ist zu lang. Bitte gib eine Behörde mit 100 Zeichen an.',
        },
      },
      taxesSolelyGermany: {
        title:
          'Bist du aufgrund deines steuerlichen Wohnsitzes ausschließlich in Deutschland einkommensteuerpflichtig?',
        ok: 'Ja, ich bin ausschließlich in Deutschland steuerpflichtig',
        cancel: 'Nein, ich bin auch in anderen Ländern steuerpflichtig',
        info: `## Deutsche Steuerpflicht

Unsere Partner Bank bietet den Aktienhandel aktuell nur für Personen an, die ausschließlich in Deutschland steuerpflichtig sind.

Wir arbeiten mit Hochdruck daran dies auch allen anderen Nutzern zu ermöglichen.
        `,
      },
      germanyBasedTax: {
        title: 'Die Freischaltung für Aktien & ETFs ist aktuell nicht möglich',
        mainText:
          'Leider können wir Personen, die nicht in Deutschland steuerpflichtig sind, derzeit keinen Handel mit Aktien & ETFs ermöglichen.Wir informieren dich gerne per E-Mail sobald es möglich ist.',
        ok: 'Informiert mich per E-Mail',
        cancel: 'Nein, danke',
      },
      deadEnd: {
        residenceNotGerman: `Leider können wir Personen, die Ihren Wohnsitz nicht in Deutschland haben, derzeit keinen Handel mit Aktien & ETFs ermöglichen.`,
        nationalityNotGerman: `Leider können wir Personen ohne deutsche Staatsbürgerschaft derzeit keinen Handel mit Aktien & ETFs ermöglichen.`,
        phoneNotGerman:
          'Leider können wir Personen ohne deutsche Handynummer derzeit keinen Handel mit Aktien & ETFs ermöglichen.',
        general: 'Wir informieren dich gerne per E-Mail sobald es möglich ist.',
      },
      confirmCellPhone: {
        title: 'Bestätigung deiner Handynummer',
        mainText:
          'Bitte überprüfe deine Handynummer. Sollte diese nicht mehr aktuell sein, kannst du sie direkt hier anpassen.',
        countryCodeLabel: 'Länderkürzel',
        cellPhone: 'Handynummer',
        cellPhoneHint: 'Bitte gib eine Handnummer an.',
        unlinkText:
          '\n' +
          'Falls sich deine Handynummer geändert hat, müsstest du deine alte entkoppeln und die neue Nummer hinzufügen. Du brauchst dafür Zugriff auf beide Handynummern.',
        supportText: 'Du hast keinen Zugang zu deiner alten Handynummer? Kontaktiere uns unter',
        changeCellPhone: 'Handynummer ändern',
        errors: {
          countryCodeEmpty: 'Bitte wähle ein Länderkürzel aus.',
        },
        twofaModal: {
          title: '2-Faktor Authentifizierung',
          text: 'Bitte bestätige deine Handynummer in dem du den Sicherheitscode eingibst, den wir dir per SMS an +49 176 ****34 gesendet haben.',
          label: 'Sicherheitscode',
          emptyError: 'Bitte gib einen Sicherheitscode ein.',
          legal:
            'Solltest du keinen Zugang zu dieser Nummer haben, kontaktiere uns bitte unter support@bisonapp.com.',
          resendSms: 'Keine SMS erhalten?  SMS erneut senden',
          action: 'Weiter zur Freischaltung',
        },
        twofaResult: {
          success: '2-Faktor Authentifizierung erfolgreich',
          fail: 'Dein Sicherheitscode ist ungültig',
        },
      },
      taxId: {
        title: `Wie lautet deine Steuer-ID?`,
        label: 'Steuer-Identifikationsnummer',
        placeholder: 'z.B. 99 999 999 999',
      },
      personalInformation: {
        title: 'Persönliches',
        mainText:
          'Für das Handeln von Aktien und Co. ist es nötig, dich noch ein bisschen besser kennenzulernen.',
        address: {
          label: 'Anrede',
          mr: 'Herr',
          ms: 'Frau',
        },
        maritalStatus: {
          label: 'Familienstand',
          value0: 'Bitte auswählen',
          value1: 'ledig',
          value2: 'verheiratet',
          value3: 'geschieden',
          value4: 'verwitwet',
        },
      },
      occupation: {
        title: 'Beruf',
        mainText: 'Deine berufliche Situation erlaubt es uns auf dich zugeschnittene Vorschläge zu machen.',
        typeOfEmployment: {
          label: 'Anstellungsart',
          value0: 'Bitte auswählen',
          value1: 'Angestellte:r',
          value2: 'Arbeiter:in',
          value3: 'Beamte:r',
          value4: 'Soldat:in / BFD',
          value5: 'Geschäftsführer:in / Vorstand / Vorständin',
          value6: 'Handwerker:in',
          value7: 'Hausmann / Hausfrau',
          value8: 'Privatier / Privatiére',
          value9: 'Rentner:in',
          value10: 'Student:in / Auszubildende:r',
          value11: 'Selbstständige:r / Freiberufler:in',
          value12: 'Ohne Beschäftigung',
        },
        industrySector: {
          label: 'Branche',
          value0: 'Bitte auswählen',
          value1: 'Baugewerbe',
          value2: 'Energieversorgung',
          value3: 'Erziehung/Unterricht',
          value4: 'Finanzen/Versicherungen',
          value5: 'Gastgewerbe',
          value6: 'Gesundheit-/Sozialwesen',
          value7: 'Handel/Instandhaltung',
          value8: 'Immobilienwesen',
          value9: 'Information/Kommunikation',
          value10: 'Kunst/Unterhaltung',
          value11: 'Land-/Forstwirtschaft',
          value12: 'Öffentlicher Dienst',
          value13: 'Private Haushalte',
          value14: 'Sonstige Dienstleistungen',
          value15: 'Verarbeitendes Gewerbe',
          value16: 'Verkehr und Lagerei',
          value17: 'Wasser-/Abfallentsorgung',
        },
      },
      mediumRisk: {
        title: {
          a: 'Hast du Anlageklassen mit',
          b: 'niedrigem bis mittlerem',
          c: 'Risiko bereits gehandelt?',
        },
        mainText:
          'Uns interessiert deine Erfahrung mit Wertpapieren. Deine Kenntnisse im Krypto-Bereich, kennen wir ja schon.',
        secondText: 'z.B. Rentenfonds & Renten-ETFs oder Wertpapiere von Bund, Ländern und Kommunen ...',
        ok: 'Ja',
        cancel: 'Nein',
        info: `## Anlageklassen mit geringem bis mittlerem Risiko

#### Wertpapiere von Bund, Ländern & Kommunen in €

Wertpapiere des Staates bzw. öffentliche Anleihen oder Staatsanleihen dienen der Finanzierung des Haushalts und werden vom Staat verwaltet. 

#### Öffentliche Anleihen (EU) in €

Dies sind Anleihen eines EU Staates oder dessen Gebietskörperschaften (z.B. Bund, Länder und Kommunen), die Euro als Nominalwährung (Transaktionswährung) aufweisen.

#### Öffentliche Anleihen (außerhalb EU) in €

Dies sind Anleihen eines Nicht-EU Staates oder dessen Gebietskörperschaften (z.B. Bund, Länder und Kommunen), die Euro als Nominalwährung (Transaktionswährung) aufweisen.

#### Geldmarktfonds & ETFs in €

Geldmarktfonds investieren in Positionen des Geldmarkts, die eine Laufzeit von maximal 12 Monaten besitzen. Diese Fonds zeichnen sich von einer hohen Liquidität und guter Handelbarkeit aus. Beispiele dieser Kategorie sind Staatsanleihen, Einlegezertifikate von Banken oder Schuldscheindarlehen.

#### Geldmarktnahe Fonds/ETFs

Geldmarktnahe Fonds/ETFs investieren das eingezahlte Kapital in festverzinsliche Wertpapiere mit kurzen Restlaufzeiten, variabel verzinslichen Anleihen sowie Termingeldern. Ziel ist es, eine möglichst stetige, von Zinsschwankungen weit-gehend unabhängige Rendite zu erwirtschaften.

#### Rentenfonds & ETFs ohne Währungsrisiko

Rentenfonds und ETFs ohne Währungsrisiko investieren in Anleihen, die als Nominalwährung (Transaktionswährung) den Euro aufweisen.
        `,
      },
      highRisk: {
        title: {
          a: 'Hast du Anlageklassen mit ',
          b: '(sehr) hohem',
          c: 'Risiko bereits gehandelt?',
        },
        mainText:
          'Uns interessiert deine Erfahrung mit Wertpapieren. Deine Kenntnisse im Krypto-Bereich, kennen wir ja schon.',
        secondText:
          'z.B. Aktien, Aktienfonds, Aktien-ETFs gängiger Indizes (EuroStoxx, DAX o.ä.) oder Indexzertifikate ...',
        ok: 'Ja',
        cancel: 'Nein',
        info: `## Anlageklassen mit (sehr) hohem Risiko

#### Aktien & Anleihen von Unternehmen aus DAX & EuroStoxx 50

Aktien (Eigenkapital) und Anleihen (Fremdkapital) dieser Risikoklasse dienen zur Finanzierung von Unternehmen die im DAX und/oder im EURO STOXX 50 gelistet sind.

#### Indexzertifikate

Indexzertifikate ermöglichen Anlegern eins zu eins an der Entwicklung eines Börsenindex teilzuhaben – ohne die zugrunde liegenden Aktien zu kaufen. Jedes Index-Zertifikat weist ein Bezugsverhältnis aus, welches den Wert des Zertifikats im Verhältnis zum Indexstand definiert.

#### Öffentliche Anleihen in Fremdwährung

Dies sind Anleihen eines Staates oder dessen Gebietskörperschaften (z.B. Bund, Länder und Kommunen), die als Nominalwährung (Transaktionswährung) eine andere Währung als den Euro aufweisen.

#### Rentenfonds & ETFs mit Währungsrisiko

Rentenfonds und ETFs mit Währungsrisiko investieren in Anleihen, die als Nominalwährung (Transaktionswährung) eine andere Währung als den Euro aufweisen.

#### Gemischte Fonds/ETFs

Gemischte Fonds/ETFs können anhand ihrer Anlagestrategie gleichzeitig in verschiedene Anlageklassen, wie z.B. Aktien, Anleihen, Immobilien investieren.

#### Strukturierte Anleihen

Dies sind verzinsliche Schuldverschreibungen mit individuellen Zusatzbedingungen (wie z.B. Laufzeit, Rückzahlung und Verzinsung). 

#### Aktienfonds & ETFs

Aktienfonds und ETFs investieren das eingezahlte Kapital ausschließlich in Aktien, andere Anlageklassen sind nicht möglich.

#### Anlagezertifikate

Anlagezertifikate ermöglichen Anlegern, im Rahmen der Ausgestaltung des jeweiligen Anlagezertifikats, an der Wertentwicklung des Basiswerts zu partizipieren ohne diesen direkt erwerben zu müssen.

#### Bezugsrechte

Bezugsrechte berechtigen den Inhaber, unter Berücksichtigung des Bezugsverhältnisses, zum Erwerb neu ausgegebener Aktien im Rahmen einer Kapitalmaßnahme.
        `,
      },
      highestRisk: {
        title: {
          a: 'Hast du Anlageklassen mit',
          b: 'höchstem',
          c: 'Risiko bereits gehandelt?',
        },
        mainText:
          'Uns interessiert deine Erfahrung mit Wertpapieren. Deine Kenntnisse im Krypto-Bereich, kennen wir ja schon.',
        secondText:
          'z.B. Hebelzertifikate, sonstige Finanztermingeschäfte oder Aktien & Anleihen aus sonstigen Indizes ...',
        ok: 'Ja',
        cancel: 'Nein',
        info: `## Anlageklassen mit höhstem Risiko

#### Aktien & Anleihen aus sonstigen Indizes

Main text: Aktien (Eigenkapital) und Anleihen (Fremdkapital) dieser Risikoklasse dienen zur Finanzierung von Unternehmen die nicht im DAX oder im EURO STOXX 50 gelistet sind.

#### Hebelzertifikate

Main text: Hebelzertifikate sind Derivate (Schuldverschreibungen) die es dem Anleger ermöglichen, eine überproportionale Wertentwicklung gegenüber dem Basiswert zu erzielen.

#### Sonstige Finanztermingeschäfte

Main text: Sonstige Finanztermingeschäfte sind Geschäfte zwischen zwei Vertragsparteien zur bedingten (z.B. Option) oder unbedingten (z.B. Future) Erfüllung.
        `,
      },
      reimbursement: {
        title: 'Rückvergütung',
        mainText: 'Bestätige, dass du der folgenden Aussage zustimmst:',
        checkbox:
          'Ich vereinbare, dass mir kein Anspruch auf Herausgabe von Rückvergütungen entsteht. Die Sutor Bank erhält Rückvergütungen von Dritten (z.B. Börsen, Handelspartnern, Fondgesellschaften) und wird diese einbehalten, sofern sie diese nach dem Wertpapierhandelsgesetz (insb. §70 WpHG) annehmen darf. ',
        info: `## Rückvergütung

Was sind Rückvergütungen? Rückvergütungen sind sowas wie Provisionen. So erhalten Banken z.B. Provisionen für verkaufte Produkte von Produktanbietern wie Fondgesellschaften.
        `,
      },
      notes: {
        title: 'Letzte Hinweise',
        subtitle1: 'Verzicht auf Beratung',
        text1:
          'Die Sutor Bank führt für dich Wertpapieraufträge aus und erbringt keine Beratung zur Anlage in  Finanzinstrumenten. Die Sutor Bank ist nur zur Prüfung der Angemessenheit der Finanzinstrumente oder Wertpapierdienstleistungen verpflichtet.',
        subtitle2: 'Bankenabwicklung & Gläubigerbeteiligung',
        text2:
          'Hinweise zur Gläubigerhaftung für Anteilseigner oder Gläubiger einer Bank findest du <1>hier</1>.',
        subtitle3: 'Kirchensteuerabfrage',
        text3:
          'Deine Kirchensteuermerkmale können beim BZST abgefragt werden. Ggf. kann Kirchensteuer aus Kapitalerträgen einbehalten werden. Du hast die Möglichkeit Widerspruch einzulegen.',
      },
      success: {
        title: 'Freischaltung bereits beantragt',
        mainText:
          'Du hast die Freischaltung bereits erfolgreich beantragt. Es kann <strong>bis zu 15 Minuten dauern</strong> bis die Freischaltung bestätigt ist und du Aktien & ETFs handeln kannst. Wir benachrichtigen dich per E-mail, sobald die Freischaltung erfolgt ist.',
        subtext:
          '<strong>Beachte:</strong> Die Freischaltung kann in Ausnahmefällen bis zu 48 Stunden dauern.',
        button: 'Schließen',
      },
    },
    demoStocksEtfModal: {
      title: {
        etf: 'Du möchtest ETFs handeln?',
        stock: 'Du möchtest Aktien handeln?',
        'long/short': 'Du möchtest Long/Short handeln?',
        commodity: 'Du möchtest Rohstoffe handeln?',
      },
      text: 'Um bei BISON zu handeln, musst du noch einige Fragen beantworten und dich mit deinem Ausweisdokument verifizieren.\n\n Für den Handel mit Aktien & ETFs kannst du dich im Anschluss innerhalb weniger Minuten zusätzlich freischalten.',
      blackButton: 'Jetzt verifizieren',
      whiteButton: 'Später',
    },
  },
  redeemCode: {
    title: 'Aktionscode einlösen',
    form: {
      title: 'Dein Aktionscode',
      codeRequired: 'Bitte trage deinen Aktionscode ein',
      codeInvalid: 'Bitte gebe einen gültigen Aktionscode ein',
    },
    errorTitle: 'Die Einlösung des Aktionscodes ist fehlgeschlagen',
    error: {
      alreadyClaimed: 'Dieser Aktionscode wurde bereits eingelöst.',
      expired: 'Dieser Aktionscode ist abgelaufen.',
      notYetStarted:
        'Die Werbeaktion für diesen Aktionscode hat noch nicht begonnen. Bitte versuche es später nochmal.',
      invalid: 'Der eingegebene Aktionscode ist ungültig',
      reclaimedEvent: 'Du hast bereits einen Aktionscode für diese Werbeaktion eingelöst.',
      kycRequired: 'Um diesen Aktionscode einlösen zu können, musst du dich erfolgreich verifiziert haben.',
      depositRequired:
        'Um diesen Aktionscode einlösen zu können, musst du einmalig erfolgreich Geld auf dein BISON Konto eingezahlt haben.',
      notWon: 'Dieses Mal hast du leider nicht gewonnen.',
      kycbeforecodeactive:
        'Du bist leider nicht berechtigt diesen Gutschein einzulösen, da du dich bereits vor dem Aktionszeitraum verifiziert hast.',
      claimcountlimitreached:
        'Dieser Gutschein ist leider nicht mehr gültig da die maximale Anzahl an verfügbaren Codes eingelöst wurde.',
      nottargetuser:
        'Dieser Gutschein darf nur durch den im Gutschein berechtigten Nutzer eingelöst werden. Bitte wende dich an den Kundenservice.',
      registerbeforecodeactive:
        'Du bist leider nicht berechtigt diesen Gutschein einzulösen da du dich bereits vor dem Aktionszeitraum registriert hast.',
      kycmorethan48hago:
        'Du bist leider nicht berechtigt diesen Gutschein einzulösen, da du dich bereits vor mehr als 48 Stunden verifiziert hast.',
      generic: 'Dein Aktionscode kann zur Zeit nicht eingelöst werden. Bitte versuche es später nochmal.',
    },
    success: {
      headerText: 'Es wurden erfolgreich {{amount}} auf dein BISON Konto übertragen',
      mainText: 'Deine Kryptowährungen sind jetzt in deinem Portfolio verfügbar.',
    },
    notClaimed: {
      notExpired: {
        title:
          'Großartig! Du bist nur einen Schritt davon entfernt deine Gutschrift in Höhe von {{amount}} in {{payoutCurrency}} zu erhalten.',
        mainText: `Folgende Voraussetzungen musst du innerhalb {{deadline}} erfüllen, um deine Gutschrift zu erhalten:

1. Erfolgreiche Verifizierung (Video-Verifizierung).
2. Geld (EURO) Einzahlung.
3. Erster Kauf einer Kryptowährung.
        `,
      },
      processing: 'Deine Gutschrift ist derzeit in Bearbeitung. Bitte schaue später nochmal vorbei.',
      expired: 'Dein eingegebener Aktionscode ist leider abgelaufen.',
    },
    caption: {
      a: 'Die Teilnahmebedingungen zum Einlösen deines Aktionscodes kannst du ',
      b: 'hier',
      c: ' nachlesen.',
    },
    redeem: 'Einlösen',
  },
  referral: {
    link: 'Link teilen',
    or: 'oder',
    web_or: 'oder Einladung versenden',
    participation: 'Teilnahmebedingungen',
    title: 'Freunde einladen',
    accepted: 'Angenommen',
    earned: 'Verdient',
    web_earned: 'Bisher verdient',
    completed: 'Abgeschlossen',
    inviteText: {
      short: 'Erhalte {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}}',
      medium: 'Erhalte {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}} für jedes neue Bison',
      long: 'Teile deine Freude und erhalte {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}} für jedes neue Bison',
    },
    web_cta:
      'Lade deine Freunde ein und erhalte {{referredUserRewardAmount}} in {{codeOwnerRewardPayoutCurrency}}',
    bonus:
      'Erhalte {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}} für jeden erfolgreich geworbenen Nutzer. Der geworbene Nutzer erhält {{referredUserRewardAmount}} in {{referredUserPayoutCurrency}}.',
    noCampaign:
      'Leider ist das BISON Freunde-werben-Freunde Programm derzeit nicht aktiv. Wir informieren dich über Änderungen.',
    send: 'Einladung senden',
    helpPopup: `# Freunde einladen

*Angenommen* = Die Anzahl deiner Freunde, welche sich über deinen Einladungslink in der BISON App registriert haben.

*Abgeschlossen* = Die Anzahl deiner Freunde, welche sich registriert und alle Bedingungen zum Erhalt der Prämie erfüllt haben.

*Verdient* = Die Höhe deiner Prämie, welche du auf Grund erfolgreich geworbener Nutzer gutgeschrieben bekommst.
    `,
    paperWarning:
      'Bitte beachte: Die Teilnahme am Freunde-werben-Freunde-Programm ist nur im Echtgeldmodus möglich.',
    moreInfo: 'Teilnahmebedingungen',
  },
  deposit: {
    select: {
      mainText: 'Zahle Euro-Guthaben auf dein BISON-Konto mit der von dir bevorzugten Methode ein:',
      cardOption: {
        title: 'Sofort Einzahlung',
        description: `
- Dein Geld ist sofort verfügbar. 
- Einmal angelegt, kannst du immer wieder auf deine hinterlegte Methode zurückgreifen.
        `,
        subtitle: 'Wie viel möchtest du einzahlen?',
        inputPlaceholder: 'z.B. 1.000,00',
        fee: 'Zzgl. einer Gebühr von {{fee}} %.',
        subtitleMethod: 'Womit möchtest du einzahlen?',
        mainTextMethod: 'Zahle Euro-Guthaben auf dein BISON-Konto mit der von dir bevorzugten Methode ein:',
        chooseMethod: 'Einzahlungsmethode wählen',
        chooseMethodTitle: 'Methode wählen',
        chooseMethodText: 'Zahle Euro-Guthaben auf dein BISON-Konto mit der von dir bevorzugten Methode ein:',
        methodTypes: {
          creditCard: 'Kreditkarte',
          applePay: 'Apple Pay',
          googlePay: 'Google Pay',
          visa: 'Kreditkarte',
          mastercard: 'Kreditkarte',
        },
        setUpCardTitle: 'Kreditkarte anlegen',
        storeCard: 'Karte hinterlegen',
        successTitle: 'Du hast {{amount}} Euro erfolgreich eingezahlt',
        successText:
          'Wir haben deine Einzahlung per {{depositMethod}} soeben erhalten. Du kannst dein eingezahltes Geld sofort investieren.',
        successAction: 'Zum Portfolio',
        failTitle: 'Deine Einzahlung ist fehlgeschlagen',
        failText:
          'Leider konnte deine Einzahlung nicht ausgeführt werden. Dies kann unterschiedliche Gründe haben. Bitte wähle eine andere Einzahlungsmethode oder überprüfe die Limits.',
        failAction: 'Erneut versuchen',
        cardHolder: 'Vor- und Nachname Karteninhaber',
        cardNumber: 'Kartennummer',
        cardDate: 'Ablaufdatum',
        cardCvv: 'Prüfnummer',
        editCard: 'ändern',
        last4: 'endet auf {{last4}}',
        lastUsedMethod: 'Zuletzt genutzt',
        otherMethods: 'Weitere Zahlungsmethode hinzufügen',
        confirmationHeader: 'EINZAHLUNG (KOSTENPFLICHTIG)',
        confirmationTitle: 'Euro Einzahlung',
        confirmationAmount: 'Einzahlungsbetrag',
        confirmationFee: 'Gebühr',
        confirmationMethod: 'Methode',
        confirmationAction: 'Kostenpflichtig einzahlen',
        confirmationSummary: 'Summe',
        paymentMethodError: 'Failed to create payment method',
        invalidBrandError:
          'Kredikarten dieses Anbieters akzeptieren wir nicht. Bitte nutze eine Master- oder VISA-Karte.',
        errors: {
          noDepositOptionSelected: 'Bitte wähle eine der beiden Optionen aus, um fortzufahren.',
          emptyAmount: 'Bitte gib einen Betrag ein.',
          amountTooLow:
            'Der Einzahlungsbetrag ist zu niedrig. Bitte gib einen Betrag von mindestens {{min}} ein.',
          amountTooHigh:
            'Der maximale Einzahlungsbetrag via Sofort Einzahlung beträgt {{limit}}. Bitte gib einen Betrag von maximal {{limit}} ein.',
          totalLimitExceeded:
            'Der maximale Einzahlungsbetrag pro Monat beträgt {{limit}}. Du hast diesen Monat bereits {{amount}} eingezahlt. Bitte gib einen Betrag von maximal {{value}} ein.',
          topUpLimitExceeded:
            'Der maximale Einzahlungsbetrag via Sofort Einzahlung beträgt {{limit}} pro Monat. Du hast diesen Monat bereits {{amount}} via Sofort Einzahlung eingezahlt. Bitte gib einen Betrag von maximal {{value}} ein.',
          paymentMethodsLimit:
            'Maximalanzahl an Kreditkarten für diesen Zeitraum erreicht. Die nächste Kreditkarte kannst du am {{date}} hinterlegen.',
          duplicateCreditCard: 'Diese Kreditkarte hast du bereits hinterlegt.',
        },
        popup: {
          title: 'Einzahlung via Kreditkarte, Apple & Google Pay jetzt verfügbar!',
          body: `Wähle in der BISON App Sofort-Einzahlung aus und **zahle 24/7 Geld ein**. 

Dein Geld ist **sofort verfügbar** und du kannst direkt handeln.`,
          action: 'Zur Sofort Einzahlung',
          close: 'Jetzt nicht',
        },
        twoFe: {
          title: 'Neue Kreditkarte hinterlegen',
          text: 'Du möchtest eine neue Kreditkarte hinterlegen. Um dich zu authentifizieren, haben wir dir einen Sicherheitscode per SMS an <1>{{phone}}</1> gesendet. Gib den Sicherheitscode unten ein.',
          button: 'Weiter',
        },
        topUpsDisabledModal: {
          title: 'Soforteinzahlung bald verfügbar',
          text: 'Für die Sofort-Einzahlung via Kreditkarte, Google Pay & Apple Pay müssen folgende Kriterien erfüllt sein:',
          condition1: 'Deine Verifizierung bei BISON liegt mindestens 3 Monate zurück.',
          condition2: 'Du hast mindestens eine Einzahlung per Überweisung getätigt.',
          button: 'Alles klar',
        },
        privacyPopup: {
          title: 'Datenschutzhinweise',
          text: {
            0: 'Um den Missbrauch deiner Kreditkarten zu verhindern, speichern wir folgende Informationen, die dir auch bei jeder Nutzung deiner hinterlegten Kreditkarte per E-Mail zugesandt und in deiner Transaktionshistorie angezeigt werden:',
            1: `
- Informationen zu deiner durchgeführten Transaktion;        
- Die letzten 4 Ziffern deiner genutzten Karte;
- Die Kreditkartengesellschaft, zum Beispiel VISA.            
            `,
            2: 'Solltest du eine nicht autorisierte Transaktion feststellen, informiere uns und deine Kreditkartengesellschaft bitte sofort über den Missbrauch. <1>Weitere Informationen zum Datenschutz</1>.',
          },
          action: 'Verstanden',
        },
      },
    },
    warningText: `Um eine erfolgreiche Einzahlung auf dein BISON Konto zu gewährleisten, müssen folgende Voraussetzungen erfüllt sein:

1. Die Einzahlung muss von einem SEPA-fähigen Bankkonto erfolgen.
2. Der Kontoinhaber des Einzahlungskontos muss auf deinen Namen laufen.
3. Du kannst pro Überweisung bis zu 20.000 € einzahlen. Jeden Tag kannst du auch mehrmals überweisen. Monatlich kannst du so bis zu 200.000 € auf dein BISON Konto einzahlen. Auszahlungen sind nicht limitiert.
4. Echtzeitüberweisungen (SEPA Instant Payments) werden nicht akzeptiert.

Bitte beachte, dass eine Überweisung in der Regel einen Werktag benötigt.`,
    warningOk: 'OK',
    description: 'Einzahlung per Überweisung',
    subtitle: 'Bitte überweise deinen gewünschten Betrag auf das folgende Konto:',
    importantInfo: {
      title: 'Hinweise zu Einzahlungen',
      /* a: 'Einzahlungen müssen von einem SEPA-fähigen Bankkonto erfolgen.',
      b: 'Das Einzahlungskonto muss auf deinen vollständigen Namen laufen ',
      c: 'Je Echtzeitüberweisungen wird eine Gebühr von 1,00 € fällig. Diese wird direkt von deinem BISON-Konto abgebucht.', */
      // TODO: Remove when fees are back.
      a: 'Einzahlungen müssen von einem SEPA-fähigen Bankkonto erfolgen.',
      b: 'Das Einzahlungskonto muss auf deinen vollständigen Namen laufen.',
      c: 'Beachte: Es können zusätzliche Gebühren bei deiner Bank anfallen.',
      button: 'Verstanden',
    },
    // descriptionInfo:
    //   'Um eine erfolgreiche Einzahlung auf dein BISON Konto zu gewährleisten, müssen folgende Voraussetzungen erfüllt sein:\n1. Die Einzahlung muss von einem deutschen Bankkonto erfolgen (IBAN beginnend mit DE).\n2. Der Kontoinhaber des Einzahlungskontos darf ausschließlich auf deinen Namen laufen. Zahlungen von Gemeinschaftskonten werden akzeptiert.\nBitte beachte, dass eine Überweisung in der Regel einen Werktag benötigt.',
    transferData: {
      receiver: 'Empfänger',
      receiverInfoTitle: 'Warum sende ich mein Geld an die Solaris SE?',
      receiverInfo:
        'Als etablierter deutscher Bankdienstleister verwaltet die Solaris SE als unser Partner sämtliche Euro-Guthaben bei BISON. Daher sendest du deine Überweisung an die Solaris SE.',
      iban: 'IBAN',
      bic: 'BIC',
      usage: 'Verwendungszweck',
    },
    fees: {
      standard: 'Gebühr Standardüberweisung',
      instant: 'Gebühr Echtzeitüberweisung',
      maxAmount: 'Maximum pro Überweisung',
      monthlyMax: 'Maximum monatlich',
      none: 'keine',
    },
    legal: `Einzahlungen müssen von einem SEPA-fähigen Bankkonto erfolgen.<br/>
      Das Einzahlungskonto muss auf deinen vollständigen Namen laufen.<br/>
      Beachte: Es können zusätzliche Gebühren bei deiner Bank anfallen.`,
    note: `<0>Hinweis:</0>
<1>Deine Einzahlung wird über die für dich persönlich vergebene IBAN auf dein BISON Konto gutgeschrieben.</1>`,
  },
  withdraw: {
    next: 'OK',
    confirmNext: 'Jetzt auszahlen',
    successNext: 'OK',
    available: 'Verfügbarer Betrag: ',
    withdrawAmount: 'Betrag',
    confirmWithdraw: 'Auszahlung bestätigen',
    bankAccount: 'Bankkonto',
    bankAccountInfo:
      'Dein Auszahlungskonto kannst du in der BISON App in den Einstellungen unter »Persönliche Informationen« ändern. Bitte beachte, dass du dein Auszahlungskonto nur zu einem Konto ändern kannst, von welchem du auch bereits erfolgreich eingezahlt hast.',
    bankAccountNotAvailable:
      'Um eine Auszahlung zu tätigen, musst du zunächst dein persönliches Konto durch Einzahlung auf dein BISON Konto verbinden.',
    confirm: 'Bitte bestätige noch einmal deine gewünschte Auszahlung',
    confirmationTitle: 'Bestätigung',
    errors: {
      generic: 'Es gab ein Problem bei der Ausführung deiner Auszahlung.',
      invalidTokenOrTooLong: 'Deine Auszahlungs-Anfrage ist zu lange her. Bitte versuche es erneut.',
    },
    success:
      'Alles klar, wir haben deine Auszahlung soeben veranlasst. Eine Auszahlung kann in Einzelfällen mehrere Werktage dauern.',
    form: {
      fieldErrors: {
        amountRequired: 'Ein Betrag ist erforderlich',
        amountOverAvailable: 'Der angegebene Betrag ist größer als dein verfügbarer Betrag.',
      },
    },
  },
  frontPage: {
    mainText: 'Bitcoin & Co. kaufen und verkaufen.',
    mottoText: 'Einfach. Smart. Zuverlässig.',
    noAccount: 'Du hast noch kein BISON Konto?',
    haveAccount: 'Du hast schon ein BISON-Konto?',
  },
  cryptoWithdraw: {
    exit: {
      headline: 'Du möchtest die Auszahlung abbrechen?',
      body: 'Beachte: Dein Fortschritt wird nicht gespeichert und deine bisherigen Eingaben gehen verloren.',
      proceed: 'Mit Auszahlung fortfahren',
      cancel: 'Auszahlung abbrechen',
    },
    info: {
      title: 'Hinweise zur Auszahlung von Kryptowährungen',
      a: '- Du benötigst eine Wallet-Adresse sowie je nach Währung einen Memo oder Destination Tag.',
      b: '- Du solltest Inhaber dieser Wallet-Adresse sein.',
      c: '- Es erfolgt eine Bestätigung per Passworteingabe und SMS-Code.',
    },
    checkboxText: 'Diese Meldung nicht mehr anzeigen.',
    amountHeadline: 'Was möchtest du auszahlen?',
    specificCryptoInfo: {
      pol: {
        title: {
          a: 'Wichtig:',
          b: 'Wir übertragen POL ausschließlich über das Polygon-Netzwerk.',
        },
        mainText: {
          a: 'Bitte stelle sicher, dass deine Ziel-Wallet mit dem Polygon-Netzwerk kompatibel ist und du Zugriff auf die Blockchain-Adresse im Polygon-Netzwerk hast.',
          b: 'Du hast sonst möglicherweise keinen Zugriff auf deine übertragenen POL.',
          c: 'Wenn du dir nicht sicher bist, informiere dich über die verschiedenen Netzwerke.',
        },
        checkbox:
          'Ich habe den Warnhinweis gelesen und verstehe, dass meine Zieladresse mit dem Polygon-Netzwerk kompatibel sein muss.',
      },
    },
    next: 'Verstanden',
    next2: {
      forward: 'Weiter',
      back: 'zurück',
    },
    next3: 'Auszahlungsanfrage senden',
    next4: 'Weiter',
    next5: 'Jetzt auszahlen',
    returnToApp: 'Zum Portfolio',
    web_available: 'Verfügbare Kryptowährungen: ',
    confirmNext: 'Jetzt auszahlen',
    successNext: 'OK',
    confirm: 'Bitte bestätige nochmal deine gewünschte Auszahlung',
    confirmationTitle: 'Bestätigung',
    resendCode: 'Sicherheits-Code erneut senden',
    resentCode: 'Wir haben dir einen Security-Code gesendet.',
    resendMail: {
      text: 'Keine E-Mail erhalten?',
      action: 'E-mail erneut senden',
    },
    resentMail: 'Wir haben dir eine weitere Sicherheits-Mail gesendet.',
    twofaInvalid: 'Ungültiger Sicherheits-Code. Bitte versuche es erneut.',
    twofaExpired: 'Dein Sicherheits-Code ist abgelaufen. Bitte versuche es erneut.',

    errors: {
      generic: 'Es gab ein Problem bei der Übermittlung deiner Auszahlung.',
      invalidTokenOrTooLong: 'Falscher oder abgelaufener Code',
      wrongPassword: 'Falsches Passwort',
      walletAddressInvalid: 'Deine eingegebene Wallet-Adresse ist ungültig.',
      resendCodeError:
        'Entschuldige, wir können dir aktuell keinen Security-Code senden. Bitte versuche es später erneut.',
      bchLegacy:
        'Deine Auszahlungsadresse konnte nicht validiert werden. Lediglich Cashaddr-Adressen werden akzeptiert. Klicke hier für weitere Informationen',
      resendMail:
        'Entschuldige, wir können dir aktuell keine Sicherheits-Mail senden. Bitte versuche es später erneut.',
    },
    success: {
      header: 'Bitte bestätige deine\nAuszahlungsanfrage per E-Mail',
      body: 'Du erhältst nun eine E-Mail, in der du deine Auszahlung final bestätigen musst. Erst danach wird dein Auftrag ausgeführt.',
    },
    failure: {
      header: 'Die Auszahlung konnte nicht veranlasst werden',
      body: `Leider ist etwas schief gelaufen und deine Auszahlung konnte nicht veranlasst werden.\nBitte starte den Auszahlungsprozess erneut.
      
Noch offene Fragen? Wir helfen dir gerne, kontaktiere uns gerne jederzeit unter support@bisonapp.com.`,
      action: 'Erneut versuchen',
    },
    amountLabel: 'Anzahl',
    amountAvailable: 'Verfügbar: ',
    amountPlaceholder: 'max. {{ amount }}',
    web_walletTitle: 'Wohin soll deine Auszahlung gehen?',
    walletEthWithdraw: 'Ethereum Auszahlung',
    walletEthLegacy:
      'Du zahlst zu einer "Legacy Address" aus, welche wir nicht verifizieren können. Möchtest du fortfahren? (Dies bedeutet nicht, dass deine Adresse ungültig ist, aber wir bitten dich, diese nochmals zu überprüfen).',
    ethWarning: {
      title: 'Wichtig',
      a: 'BISON unterstützt aktuell lediglich die Auszahlung auf External Owned Accounts. Weitere Informationen findest du in unseren ',
      b: 'FAQs',
      c: 'unter Auszahlung & Einzahlung von Kryptowährungen.',
      faqLink: 'https://bisonapp.com/faq',
    },
    walletWarning:
      '**Wichtig:**\n\nMit dem Klick auf “Auszahlungsanfrage senden” bestätigst du, dass du der wirtschaftlich Berechtigte des Empfänger-Wallets bist.',
    codeDescription: {
      a: 'Wir haben dir einen Sicherheitscode per SMS an deine bei uns hinterlegte Telefonummer ',
      b: ` gesendet.`,
      c: `Gib den Sicherheitscode unten ein, um deine Auszahlung zu bestätigen.`,
      title: 'Bestätigung deiner {{ coinName }}-Auszahlung',
      web_text:
        'Wir haben dir einen Sicherheitscode per SMS an deine bei uns hinterlegte Telefonummer <1>{{phone}}</1> gesendet. Gib den Sicherheitscode unten ein, um deine Auszahlung zu bestätigen.',
    },
    noPhoneNumberAccessInfo: {
      a: `Wenn du keinen Zugang zu deiner Handynummer hast, kannst du diese `,
      b: `hier `,
      c: `ändern.`,
    },
    codeLabel: 'Sicherheits-Code',
    passwordDescription: `Bitte gib zur Bestätigung deiner Auszahlung dein BISON-Passwort an.`,
    form: {
      fieldErrors: {
        amountRequired: 'Betrag ist erforderlich',
        amountOverAvailable: 'Der angegebene Betrag ist größer als dein verfügbarer Betrag.',
        minimumAmountRequired: 'Der minimale Auszahlungsbetrag beträgt {{amount}} {{cryptoName}}',
        walletTooShort: 'Bitte trage eine gültige Wallet-Adresse ein',
        walletTooLong: 'Bitte trage eine gültige Wallet-Adresse ein',
        walletRequired: 'Eine Wallet-Adresse ist erforderlich.',
        incorrectCode: 'Falsches Codeformat',
        codeRequired: 'Ein Bestätigungscode ist erforderlich, um fortzufahren.',
        coinSpecificWalletErrors: {
          xlm: 'Die Wallet-Adresse muss mit “G” beginnen.',
          xtz: 'Die Wallet-Adresse muss mit “tz1”, “tz2” oder “tz3” beginnen.',
        },
      },
    },
    walletLabel: 'Wallet-Adresse',
    walletPlaceholder: 'z. B. 0x3k5j323532kqjcr3l',
    destinationTagLabel: {
      xrp: 'Destination Tag',
      xlm: 'Memo Tag',
    },
    walletInfo: {
      default: {
        headline: 'Hinweise zur Wallet-Adresse',
        body: 'Bei Eingabe einer falschen Wallet-Adresse kann die Auszahlung nicht rückgängig gemacht oder korrigiert werden. Auszahlungen auf andere Wallet-Adressen, welche auch andere Personen für Transaktionen von Kryptowährungen nutzen, sind nicht zulässig.',
      },
      xlm: {
        headline: 'Hinweise zur Wallet-Adresse',
        body: `Bei Eingabe einer falschen Wallet-Adresse kann die Auszahlung nicht rückgängig gemacht oder korrigiert werden. Auszahlungen auf andere Wallet-Adressen, welche auch andere Personen für Transaktionen von Kryptowährungen nutzen, sind nicht zulässig.
        
Für die Kryptowährung **Stellar (XLM)** gibt es zwei Typen der Wallet-Adresse. Entweder fängt sie mit “M” oder “G” an. Wir unterstützen lediglich die Wallet-Adressen, die mit einem “G” beginnen.`,
      },
      xtz: {
        headline: 'Hinweise zur Wallet-Adresse',
        body: `Bei Eingabe einer falschen Wallet-Adresse kann die Auszahlung nicht rückgängig gemacht oder korrigiert werden. Auszahlungen auf andere Wallet-Adressen, welche auch andere Personen für Transaktionen von Kryptowährungen nutzen, sind nicht zulässig.
        
Für die Kryptowährung **Tezos (XTZ)** gibt es zwei Typen der Wallet-Adresse. Es gibt Wallet-Adressen beginnend mit “tz” und “KT1” für Smart Contracts. Wir unterstützen nur Wallet-Adressen, die mit “tz1”, “tz2” oder “tz3” beginnen.`,
      },
    },
    destinationTagInfo: {
      xrp: {
        headline: 'Was ist ein Destination Tag?',
        body: `Der Destination Tag wird verwendet, um zu bestimmen, welchem Account eine XRP-Transaktion zugeordnet werden soll.

Bitte stelle vor der Initiierung einer XRP-Transaktion sicher, ob ein Destination Tag erforderlich ist oder nicht. 

- Wenn du eine Hardware-Wallet hast, ist die Angabe eines Destination Tag höchstwahrscheinlich nicht erforderlich, da sich auf dem Gerät nur eine XRP-Wallet befindet.

Jeder Destination Tag kann aus Zahlen, Buchstaben und/oder Sonderzeichen bestehen und wird von dem Dienstanbieter festgelegt, der es erstellt hat. Bitte überprüfe die Richtigkeit des Tags, bevor du die Transaktion einleitest, da wir nicht bestätigen können, ob er korrekt ist. 

Wenn ein Destination Tag erforderlich ist und du ihn vergessen oder einen falschen Tag eingefügt hast, kann es zu erheblichen Verzögerungen bei der Gutschrift oder sogar zu einem unwiederbringlichen Verlust der Transaktion führen.

Hinweis: Um eine neue XRP-Wallet zu aktivieren, muss deine erste Überweisung an eine neue XRP-Wallet mindestens 20 XRP betragen.`,
        note: 'Bitte beachte: Es obliegt in deiner Verantwortung zu prüfen, ob für deine Transaktion ein Destination Tag erforderlich ist.',
      },
      xlm: {
        headline: 'Was ist ein Memo Tag?',
        body: `Der Memo Tag wird verwendet, um zu bestimmen, welchem Account eine XLM-Transaktion zugeordnet werden soll. 

Bitte stelle vor der Initiierung einer XLM-Transaktion sicher, ob ein Memo Tag erforderlich ist oder nicht. 

- Wenn du eine Hardware-Wallet hast, ist die Angabe eines Memo-Tag höchstwahrscheinlich nicht erforderlich, da sich auf dem Gerät nur eine XLM-Wallet befindet.

Jeder Memo Tag kann aus Zahlen, Buchstaben und/oder Sonderzeichen bestehen und wird von dem Dienstanbieter festgelegt, der es erstellt hat. Bitte überprüfe die Richtigkeit des Tags, bevor du die Transaktion einleitest, da wir nicht bestätigen können, ob er korrekt ist. 

Wenn ein Memo-Tag erforderlich ist und du ihn vergessen oder einen falschen Tag eingefügt hast, kann es zu erheblichen Verzögerungen bei der Gutschrift oder sogar zu einem unwiederbringlichen Verlust der Transaktion führen.
`,
        note: 'Bitte beachte: Es obliegt in deiner Verantwortung zu prüfen, ob für deine Transaktion ein Memo Tag erforderlich ist.',
      },
    },
    destinationTagPlaceholder: 'z. B. 012345678',
    checkboxes: {
      title: 'Hinweise',
      description: 'Bitte bestätige folgende Vorraussetzungen für eine erfolgreiche Auszahlung:',
      soleOwner: {
        a: 'Ich zahle auf eine Wallet-Adresse aus, bei der ich die ',
        b: 'einzige wirtschaftlich berechtigte ',
        c: 'Person bin.',
        info: {
          headline: 'Wer sind wirtschaftlich Berechtigte?',
          body: `Wirtschaftlich Berechtigte gemäß § 2 GwG sind:
          
1. Natürliche Personen, in deren Eigentum oder unter deren Kontrolle der Vertragspartner steht.
2. Natürliche Personen, auf deren Veranlassung eine Transaktion durchgeführt oder eine Geschäftsbeziehung begründet wird.
          `,
        },
      },
      onlyUser: {
        a: 'Ich bestätige, dass ',
        b: 'keine anderen Personen ',
        c: 'die von mir eingegebene ',
        d: 'Wallet-Adresse benutzen.',
      },
      cannotBeReversed: {
        a: 'Ich bin mir darüber bewusst, dass die Auszahlung bei der Eingabe einer falschen Wallet-Adresse ',
        b: 'nicht rückgängig gemacht werden kann.',
        c: '',
      },
      moreInfo: {
        a: 'Weitere Informationen findest du in unseren ',
        b: 'FAQs ',
        c: 'unter Auszahlung & Einzahlung von Kryptowährungen.',
      },
    },
  },
  cryptoDeposit: {
    title: 'Einzahlung Kryptowährungen',
    importantInfo: {
      title: 'Wichtige Hinweise',
      a: 'Um eine erfolgreiche Einzahlung auf dein BISON Konto zu gewährleisten, müssen folgende Voraussetzungen erfüllt sein:',
      b: '1. Du darfst nur von Wallets einzahlen, bei denen du der wirtschaftlich Berechtigte bist.',
      c: '2. Bitte sende ausschließlich die entsprechende Kryptowährung an deine zugehörige in der BISON App generierte Wallet-Adresse. Das Senden anderer Kryptowährungen oder digitaler Assets führt zu einem irreversiblen Verlust. Es erfolgt keine Gutschrift in deiner Wallet. ',
      d: 'Bitte beachte, dass die Einzahlung auf deinem BISON Konto in Einzelfällen mehrere Werktage ',
      e: '', // emphasized
      f: 'benötigen kann.',
    },
    description: 'Bitte zahle von deiner Wallet auf dein BISON Konto ein:',
    warningText: `# Hinweise

1. Bitte beachte, dass die Einzahlung auf deinem BISON Konto in Einzelfällen mehrere Werktage benötigen kann.
2. Du darfst nur von Wallets einzahlen, bei denen du der **wirtschaftlich Berechtigte** bist.`,
    notAvailable: 'Noch nicht verfügbar',
    transferData: {
      crypto: 'Kryptowährung',
      wallet: 'Deine {{selectedCrypto}} Einzahlungswallet',
      destinationTag: 'Dein Destination Tag',
      memoTag: 'Dein Memo Tag',
      walletInfo: `Deine Einzahlung wird über die für dich persönlich vergebene Einzahlungsadresse auf dein BISON Konto gutgeschrieben.

Wenn du möchtest, kannst du deine Einzahlungsadresse nach jeder Kryptowährungs-Einzahlung neu generieren. Natürlich kannst du auch deine bisher genutzt Einzahlungsadresse verwenden.`,
      walletInfoNoRegenerate: `Deine Einzahlung wird über die für dich persönlich vergebene Einzahlungsadresse auf dein BISON Konto gutgeschrieben.`,
      walletInfoXRP: `Deine Einzahlung wird über die Einzahlungsadresse und über den für dich persönlich vergebenen Destination Tag auf dein BISON Konto gutgeschrieben. Du musst deinen Destination Tag angeben. Andernfalls kann die Einzahlung nicht auf deinem Konto gutgeschrieben werden.`,
      walletInfoETH: `Deine Einzahlung wird über die für dich persönlich vergebene Einzahlungsadresse auf dein BISON Konto gutgeschrieben. 

ETH Einzahlungen werden auf bis zu 18-Nachkommastellen akzeptiert, jedoch aus technischen Gründen auf 8-Nachkommastellen abgerundet.`,
    },
    destinationTagWarning:
      'Du musst deinen Destination Tag angeben. Andernfalls kann die Einzahlung nicht auf deinem Konto gutgeschrieben werden.',
    memoTagWarning:
      'Du musst deinen Memo Tag angeben. Andernfalls kann die Einzahlung nicht auf deinem Konto gutgeschrieben werden.',
    newAddressBtn: 'Neue Adresse generieren',
    newQrCodeBtn: 'Zum Qr-Code',
    qrCodeWarning: {
      header: 'Wichtig',
      text: 'Bitte überprüfe, ob die von dir mittels QR-Code gescannte Einzahlungsadresse auf die du von deiner eigenen Blockchain-Adresse einzahlen möchtest, richtig eingegeben ist. Ein Transfer auf eine abweichende Blockchain-Adresse oder eine abweichende Blockchain **kann nicht mehr rückgängig gemacht werden** und führt zu einem **irreversiblen Verlust**.',
    },
    newAddressGenerateInfo:
      'Du kannst dir lediglich eine neue Adresse generieren, wenn du auf deine aktuelle Adresse eingezahlt hast.',
    latestTransactions: 'Bisherige Transaktionen',
    showLatestTransactions: 'Bisherige Transaktionen anzeigen',
    hideLatestTransactions: 'Bisherige Transaktionen verbergen',
    transactionStatus: {
      pending: 'Einzahlung in Bearbeitung',
      failed: 'Einzahlung abgelehnt',
      completed: 'Einzahlung erfolgreich',
    },
    important: {
      header: 'Wichtig:',
      generic: `Bitte sende ausschließlich {{displayName}} ({{key}}) an diese Wallet-Adresse! Das Senden anderer digitalen Assets führt zu einem **irreversiblen Verlust**. Es erfolgt keine Gutschrift in deiner Wallet.`,
      eth: `**Bitte sende ausschließlich Ether (ETH) an diese Einzahlungadresse!**
    
Es können ausschließlich Ether über das Ethereum-Netzwerk an diese Einzahlungsadresse gesendet werden.
      
Es können keine Token anderer Blockchains wie beispielsweise der Binance Smartchain oder Polygon empfangen werden. Das Senden digitaler Assets von anderen Blockchain-Netzwerken führt zu einem **irreversiblen Verlust**.
      
Es können ebenfalls keine anderen Token als ETH auf dieser Einzahlungsadresse empfangen werden. Das Senden anderer digitalen Assets als ETH führt zu einem **irreversiblen Verlust**. Dies gilt insbesondere für ERC-20-Token, die zwar auf der Ethereum-Blockchain basieren können, aber nicht mit Ether (ETH) identisch sind (z.B. UNI, LINK oder ERC-20-Token der Blockchain „Binance Smart Chain“).

Es erfolgt in beiden Fällen keine Gutschrift in deiner Wallet.
      
**Diese Einzahlungsadresse unterstützt nur ETH!**
`,
      xrp: `Bitte sende ausschließlich XRP an diese Wallet-Adresse! Bei der Einzahlung von XRP muss unbedingt dein korrekter Destination Tag angegeben werden. Das Senden anderer digitalen Assets oder eine Einzahlung ohne oder mit dem falschen Destination Tag führt zu einem **irreversiblen Verlust**. Es erfolgt keine Gutschrift in deiner Wallet. Wir unterstützten nur den sogenannten "Payment"-Transaktionstyp mit dem erforderlichen Destination Tag.\nDavon abweichende Transaktionstypen, wie z.B. "Escrow" oder "Account-Delete" werden von uns nicht unterstützt. Das Senden solcher anderer Transaktionstypen führt zu einem **irreversiblen Verlust**.`,
      pol: `**Wir empfangen POL ausschließlich über das Polygon-Netzwerk!**

Bitte stelle sicher, dass du bei einer Einzahlung von POL das Polygon-Netzwerk verwendest, du hast sonst möglicherweise keinen Zugriff auf deine übertragenen POL.

Wenn du dir nicht sicher bist, informiere dich über die verschiedenen Netzwerke und die Voreinstellungen deiner externen Wallet oder kontaktiere unseren Support.`,
    },
    acceptWarning: {
      eth: 'Ich habe die Warnhinweise gelesen und bin mir bewusst, dass ich ausschließlich Ether an meine ETH Einzahlungsadresse senden kann.',
      xrp: 'Bitte sende ausschließlich XRP an diese Wallet-Adresse! Bei der Einzahlung von XRP muss unbedingt dein korrekter Destination Tag angegeben werden. Das Senden anderer digitalen Assets oder eine Einzahlung ohne oder mit dem falschen Destination Tag führt zu einem irreversiblen Verlust. Es erfolgt keine Gutschrift in deiner Wallet. Wir unterstützten nur den sogenannten "Payment"-Transaktionstyp mit dem erforderlichen Destination Tag.\nDavon abweichende Transaktionstypen, wie z.B. "Escrow" oder "Account-Delete" werden von uns nicht unterstützt. Das Senden solcher anderer Transaktionstypen führt zu einem **irreversiblen Verlust**.',
      bch: 'Bitte sende ausschließlich Bitcoin Cash (BCH) an diese Adresse! Das Senden anderer digitaler Assets, einschließlich Bitcoin (BTC), führt zu einem **irreversiblen Verlust**.',
      uni: 'Bitte sende ausschließlich Uniswap (UNI) an diese Wallet-Adresse! Das Senden anderer digitalen Assets führt zu einem **irreversiblen Verlust**. Es erfolgt keine Gutschrift in deiner Wallet.\nDies gilt beispielsweise für sogenannte ERC-20-Token, die zwar auf der Ethereum-Blockchain basieren, aber eben nicht mit Uniswap (UNI) identisch sind und daher nicht von dieser Wallet unterstützt werden (z.B. Token der Blockchain „Binance Smart Chain“). Diese Wallet unterstützt nur UNI!',
      link: 'Bitte sende ausschließlich Chainlink (LINK) an diese Wallet-Adresse! Das Senden anderer digitalen Assets führt zu einem **irreversiblen Verlust**. Es erfolgt keine Gutschrift in deiner Wallet.\nDies gilt beispielsweise für sogenannte ERC-20-Token, die zwar auf der Ethereum-Blockchain basieren, aber eben nicht mit Chainlink (LINK) identisch sind und daher nicht von dieser Wallet unterstützt werden (z.B. Token der Blockchain „Binance Smart Chain“). Diese Wallet unterstützt nur LINK!',
      pol: 'Ich habe den Warnhinweis gelesen und verstehe, dass ich für die Einzahlung das Polygon-Netzwerk verwenden muss.',
    },
    messages: {
      successfullyRegenerated: 'Neue Adresse erfolgreich generiert',
      cannotRegenerateInfo:
        'Du kannst dir lediglich eine neue Adresse generieren, wenn du auf deine aktuelle Adresse eingezahlt hast.',
      genericRegenerateError: 'Es gab ein Problem bei der Generierung deiner neuen Adresse.',
      notSupportedError: 'Selected crypto currency is not supported for crypto deposit', // We don't display this
    },
  },
  customerSupport: {
    sentFrom: `Gesendet von:\n{{name}}\n{{username}}\nOS: {{os}}\nBrowser: {{browser}}\nBISON WEB version: {{version}} `,
    mobilePhoneSubject: 'Kein Zugang mehr zur alten Handynummer. [Eingeloggt]',
  },
  anonymous: {
    displayName: 'Anonymer BISON',
  },
  tutorial: {
    replay: 'Wiederholen',
    overview: {
      title: 'App Funktionen',
      text: 'Alle Funktionen der BISON App im Überblick.',
      portfolio: 'Dein Portfolio',
      market: 'Marktübersicht',
      transactions: 'Transaktionsübersicht',
      settings: 'Einstellungen',
    },
    market: {
      title: 'Marktübersicht - Kaufen und Verkaufen',
      1: 'Behalte die Preise der handelbaren Kryptowährungen immer im Blick.',
      2: 'Wähle zwischen der 24 Stunden-, der Wochen-, Monats- und Jahresansicht.',
      3: 'Kaufe und Verkaufe Kryptowährungen innerhalb von Sekunden.',
      4: 'Dein aktueller Kaufpreis.',
      5: 'Gebe einen Betrag ein oder wähle einen vorgegebenen Betrag in der Leiste.',
      6: 'Nun hast du nochmal 10 Sekunden Zeit, um deinen Kauf zu bestätigen.',
      7: 'Und schon hast du Kryptowährungen gekauft.',
    },
    portfolio: {
      title: 'Dein Portfolio',
      1: 'Im Portfolio siehst du deine Kryptowährungen, deine verfügbaren Euros und deinen Gewinn & Verlust.',
      2: 'Der aktuelle Wert deiner gekauften Kryptowährungen sowie die Veränderung seit deinem Kauf.',
      3: 'Dein verfügbares Geld zum Kaufen von Kryptowährungen sowie dein erzielter Gewinn & Verlust.',
      4: 'Die Aufteilung deines Gesamtvermögens in BISON. Klicke auf das Rad, um deine einzelnen Positionen zu sehen.',
      5: 'Alle handelbaren Kryptowährungen auf einen Blick.',
    },
    finished: {
      title: 'Du bist Teil der BISON Herde.',
      text: 'Leg jetzt los!',
    },
  },
  info: {
    price: `# Aktueller Preis

  Der angezeigte Preis (Aktueller Preis), ist der Mittelwert zwischen dem niedrigeren Verkaufspreis (Geldkurs) und dem höheren Kaufpreis (Briefkurs).

  **Aktueller Kaufpreis:** {{buyPrice}}

  **Aktueller Verkaufspreis:** {{sellPrice}}
      `,
    crypto: {
      linkLabel: 'Mehr Informationen',
      btc: {
        description:
          'Bitcoin wurde im Jahr 2009 veröffentlicht und ist damit die erste und zugleich wichtigste Kryptowährung. Als Erfinder gilt ein gewisser Satoshi Nakamoto. Der Bitcoin besteht aus einer Geldeinheit und aus einem Zahlungssystem. Die digitale Währung (BTC) wird in einem globalen dezentralen Rechnernetz mithilfe einer speziellen Software geschöpft und verwaltet. Das globale System basiert auf einer dezentralen Datenbank, in der alle Transaktionen in Form einer Blockchain aufgezeichnet werden. Weltweite Aufmerksamkeit erlangte der Bitcoin durch seinen Wertzuwachs.',
        link: 'https://bisonapp.com/kurswerte/bitcoin/#allgemeine-informationen',
      },
      xrp: {
        description:
          'Ripple (XRP) ist ein Open-Source-Protokoll, das auf einer öffentlichen Datenbank basiert. Das Ripple-Netzwerk unterstützt alle gängigen Währungen. Der Altcoin XRP erschien 2012 und basiert im Gegensatz zu Bitcoin weder auf der Blockchain noch kann der Coin geschürft werden.',
        link: 'https://bisonapp.com/kurswerte/ripple/#allgemeine-informationen',
      },
      eth: {
        description:
          'Die Ethereum Plattform wird für Distributed Apps verwendet und ermöglicht das Anlegen, Ausführen und Verwalten von Smart Contracts. Ether (ETH) ist das interne Zahlungsmittel bei Transaktionsverarbeitungen für die globale Plattform Ethereum.',
        link: 'https://bisonapp.com/kurswerte/ethereum/#allgemeine-informationen',
      },
      ltc: {
        description:
          'Litecoin (LTC) ist ein globales und dezentrales Zahlungsnetzwerk, vergleichbar mit jenem des Bitcoin-Netzwerks. Die wesentlichen Unterscheidungsmerkmale zwischen Litecoin und Bitcoin liegen im Bereich der Technik. Hervorzuheben sind die Transaktionsgeschwindigkeit, das maximale Angebot der jeweiligen Coins und der verwendete Algorithmus.',
        link: 'https://bisonapp.com/kurswerte/litecoin/#allgemeine-informationen',
      },
      bch: {
        description:
          'Bitcoin Cash (BCH) ist durch einen Hard Fork (Abspaltung: eine von der Community initiierte Aktualisierung des Protokolls oder Codes) im August 2017 aus der ursprünglichen Bitcoin-Blockchain entstanden. Ziel der Abspaltung war es die Blockgröße anzuheben und dadurch den Transaktionsdurchsatz (Anzahl der je Zeiteinheit in einem Rechner verarbeiteten Transaktionen) signifikant zu erhöhen.',
        link: 'https://bisonapp.com/kurswerte/bitcoin-cash/#allgemeine-informationen',
      },
      link: {
        description:
          'LINK ist die Kryptowährung des dezentralen Netzwerkes Chainlink. Chainlink verifiziert externe Daten für Smart Contracts auf der Ethereum Blockchain und stellt diese zur Verfügung (sog. Oracle Network). Hierdurch können Smart Contracts beispielsweise auf Ressourcen wie Datenfeeds, Web-APIs oder auch Wetterdaten zugreifen. Teilnehmer des Chainlink-Netzwerks werden in der Kryptowährung LINK (ERC677 Token) vergütet, wenn sie Chainlink Nodes betreiben und Datenanfragen erfüllen.',
        link: 'https://bisonapp.com/kurswerte/chainlink/#allgemeine-informationen',
      },
      uni: {
        description:
          'UNI ist die native Kryptowährung des dezentralen Handelsprotokolls Uniswap. Uniswap ist auf der Ethereum Blockchain verankert und dient dem Austausch von ERC20-Token. UNI selbst ist als ERC20 Token ausgestaltet und ermöglicht bei Verwendung im Uniswap Protokoll das Wahrnehmen von Governance-Rechten über die Ausgestaltung des Uniswap-Protokolls.',
        link: 'https://bisonapp.com/kurswerte/uniswap/#allgemeine-informationen',
      },
      ada: {
        description:
          'Cardano ist die erste Proof-of-Stake-Blockchain-Plattform, die auf einem forschungsorientierten Entwicklungsprozess mit Peer-Reviews basiert. Die Technologie ermöglicht die Entwicklung von dezentralen Anwendungen und Smart Contracts im Baukastenprinzip auf eine sichere und effiziente Weise. Befürworter behaupten, dass das Ouroboros-Protokoll von Cardano die Sicherheit, Skalierbarkeit und Energieeffizienz gegenüber anderen Proof-of-Work-Vorgängern wie Bitcoin verbessert. Die Plattform wurde 2015 von den Ethereum-Mitbegründern Charles Hoskinson und Jeremy Wood gegründet.',
        link: 'https://bisonapp.com/en/coins/cardano/#general-information',
      },
      sol: {
        description:
          'Solana ist eine Blockchain-Plattform. Sie erhöht die Skalierbarkeit für Benutzer durch schnellere Prüfungszeiten für Transaktionen & Smart Contracts sowie eine flexible Infrastruktur. Dazu wird ein Proof-of-History-Konsens mit dem der Blockchain zugrunde liegenden Proof-of-Stake-Konsens kombiniert. Solana wurde 2018 von den Ex-Qualcomm-Angestellten Anatoly Yakovenko und Greg Fitzgerald gegründet.',
        link: 'https://bisonapp.com/en/coins/solana/#general-information',
      },
      dot: {
        description:
          'Polkadot ist eine Blockchain, die darauf abzielt, ein Ökosystem verschiedener Blockchains zu verbinden und den Austausch verschiedenster Daten und Asset-Typen zu ermöglichen – nicht nur den Austausch von Token. Zu diesem Zweck wurden eine Reihe neuer technischer Features eingeführt. Das Netzwerk setzt sich aus zwei Arten von Blockchains zusammen: Dem Herz der Plattform, der sogenannten Relay-Chain, auf der alle Transaktionen dauerhaft vereinbart werden und den parallel von Nutzern generierten Parachains. Polkadot nutzt dabei den Proof-of-Stake-Konsen-Algorithmus und wurde vom Ethereum-Mitbegründer Gavin Wood entwickelt.',
        link: 'https://bisonapp.com/en/coins/polkadot/#general-information',
      },
      shib: {
        description:
          'Eine auf der Ethereum-Blockchain laufende, ebenfalls auf einem Hundenamen basierende Alternative zu Dogecoin. SHIB ist ein ERC20-Token und ein Teil des Gesamtbestandes wurde bereits verbrannt, um die Stabilität des Tokens zu fördern.',
      },
      doge: {
        description:
          'Einer der bekanntesten Altcoins. Eine Open Source und Peer-to-Peer digitale Währung, die auf dem viralen “DOGE” Meme basiert und bereits seit 2013 existiert. Dogecoin wird unter anderem als Trinkgeld auf Reddit und Twitter verwendet, um außergewöhnliche Inhalte auf Social Media zu belohnen.',
      },
      pol: {
        description:
          'Ursprünglich 2017 als Matic Network entwickelt, um die Ethereum-Infrastruktur zu verbessern, wurde das Netzwerk schließlich in „Polygon“ umbenannt. Polygon hat zum Ziel, hohe Gas-Gebühren und langsame Blockbestätigungen zu entschärfen und Kryptowährungen so für einen breiteren Markt zugänglich zu machen. Aufgrund eines großen Upgrades im September 2024 wurde der native Netzwerk-Token MATIC zu POL migriert, um eine skalierbare, einheitliche und global zugängliche Blockchain-Technologie bereitzustellen.',
      },
      xlm: {
        description:
          'Stellar Lumens (XLM) ist ein Open-Source-Protokoll, das eine anpassbare Zahlungsinfrastruktur bietet. Die nativen Assets des Stellar-Netzwerks werden als Lumen (XLM) bezeichnet. Ziel von Stellar ist es, effizientere und kostengünstigere Bankdienstleistungen anzubieten. Das Stellar-Protokoll wurde von der gemeinnützigen Organisation Stellar Development Foundation entwickelt. JedMcCaleb, der Schöpfer von eDonkey, Mtgox.com und des Ripple-Protokolls, ist der Gründer von Stellar. Stellar XLM werden für Transaktionsgebühren verwendet und dienen als Überbrückung für Transaktionen in verschiedenen Währungen.',
      },
      mana: {
        description:
          'Ein Virtual Reality (VR)-Spiel ist die Grundlage dieser Kryptowährung, die auf der Ethereum-Blockchain läuft und mittlerweile die Währung für viele Anwendungen in Decentraland ist. Spieler können mit MANA Güter und Dienstleistungen auf dem Decentraland-Marktplatz kaufen sowie eigene Inhalte auf der Plattform erstellen.',
      },
      sand: {
        description:
          'Diese Technologie verfolgt das Ziel, Blockchains im Gaming-Mainstream zu etablieren. Bei SAND handelt es sich um einen Ethereum-basierten „Utility Token“. Im Sandbox-Umfeld ist dieser Coin die einzig gültige Kryptowährung. Innerhalb des Projekts können Nutzer ganz einfach digitale Assets kaufen, verkaufen, erstellen und entwickeln und somit Inhalte zum Metaverse beitragen. SAND-Besitzer sind zudem Teil der dezentralen Governance (DAO), die zukünftige Produktentwicklungen fördert.',
      },
      aave: {
        description:
          'Eines der bekanntesten DeFi-Protokolle, das unter Anwendung von dezentraler Governance (DAO) entwickelt wurde. AAVE ist der Governance-Token des Aaave-Protokolls und wurde ursprünglich als ETHLEND bezeichnet. Es handelt sich um eine dezentralisierte Kreditplattform und eines der wichtigsten Liquiditätsprotokolle in der DeFi-Welt. Aave ermöglicht in ihrem Protokoll den Verleih von Kryptowährungen: Unter anderem können Aave-Nutzer ohne zentrale Partei digitale Assets hinterlegen, um dort Zinsen zu erhalten.',
      },
      algo: {
        description:
          'Ein dezentralisiertes und skalierbares Open Source Blockchain-Projekt. Das Algorand-Netzwerk basiert auf einem Pure Proof-of-Stake (Pure PoS) Konsensmechanismus und hat sich zum Ziel gesetzt, die erste Blockchain mit negativer CO 2 -Bilanz zu betreiben.',
      },
      axs: {
        description:
          'Axie Infinity Shards (AXS) sind die eigenen Governance-Token des Ökosystems. Axie Infinity ist ein Blockchain-Spiel, das von beliebten Spielen wie Pokémon und Tamagotchi inspiriert wurde und eine der beliebtesten Ethereum-Gaming-dApps ist. Der ERC-20 Standard bildet die Grundlage für AXS. Das Projekt hat es sich zum Ziel gesetzt, seinen Spielern die Blockchain-Technologie auf lehrreiche Art und Weise näher zu bringen. Im Spiel können Kreaturen - sogenannte Axies - gezüchtet, bekämpft und gesammelt werden.',
      },
      bat: {
        description:
          'Basic Attention Token (BAT) ist eine Ethereum-basierte Kryptowährung, die darauf abzielt, die digitale Werbeindustrie zu revolutionieren, indem eine Blockchain-basierte digitale Werbebörse geschaffen wird, die jede Möglichkeit von Betrug ausschließt. Ziel des Projekts ist es daher, einen dezentralen und transparenten Austausch von digitaler Werbung auf der Ethereum-Blockchain zu ermöglichen. Für die Transaktionen wird die Kryptowährung Basic Attention Token (BAT) verwendet. Die Basis der Ethereum Blockchain war für den Basic Attention Token (BAT) von großer Bedeutung: BAT wurde im Rahmen eines Ethereum-basierten ERC-20-Token-Standards erstellt und nutzt die Ethereum Blockchain als primäre Infrastruktur.',
      },
      chz: {
        description:
          'Chiliz (CHZ) ist ein auf der Ethereum-Blockchain basierender Utility-Token (also ein ERC-20-Token), der von der Chiliz-Plattform ausgegeben wird. Über diese Plattform können beispielsweise Sportfans ihre Lieblingsmannschaften auf der ganzen Welt unterstützen. Dabei ist Chiliz kein Coin, sondern ein Token. Der Chiliz-Token wird hauptsächlich für den Kauf von Fan-Token und als Währung für z.B. NFTs auf dem “Socios Fan-Token-Marktplatz” verwendet. Darüber hinaus wird die Kryptowährung Chiliz auch von Anlegern genutzt.',
      },
      crv: {
        description:
          'Curve (CRV) ist eine dezentrale Börse (DEX), die nach einem ähnlichen Muster funktioniert wie der bekannte Vertreter und Konkurrent Uniswap (der Token UNI ist bereits auf BISON handelbar). Curve konzentriert sich jedoch nicht auf den Token-to-Token-Handel, sondern setzt auf sogenannte Stablecoins als Tauschbasis. Der Curve DAO Token ist ein Utility Token, der speziell für den Handel mit Stablecoins und anderen ERC-20 Token verwendet wird. CRV basiert ebenfalls auf der Ethereum-Blockchain und ist somit ein ERC-20-Token.',
      },
      enj: {
        description:
          'Enjin Coin (ENJ) wurde 2017 von dem Team hinter Enjin Network gegründet, einer Social-Gaming-Plattform, die es ihren Nutzern ermöglicht, virtuelle Geschäfte zu betreiben, zu chatten sowie Websites und Clans zu erstellen. Enjin Coins (ENJ) basieren auf dem ERC-20-Standard und sind eine Mining-Ressource, die für NFTs benötigt wird. Der Wert dieser NFTs bzw. Blockchain-Assets wird durch ENJ unterstützt, die Assets erhalten somit auch einen Wert in der realen Welt. Das Enjin Ecosystem ermöglicht es Unternehmen sowie Spieleentwicklern, tokenisierte digitale Assets zu nutzen.',
      },
      etc: {
        description:
          'Ethereum Classic (ETC) entstand durch eine Abspaltung (Hard Fork) von der Ethereum-Blockchain im Jahr 2016. Die Abspaltung war das Ergebnis einer kontroversen Diskussion innerhalb der Ethereum-Community und der Entwickler rund um den Ethereum-Gründer Vitalik Buterin. Ein Teil der Krypto-Community sprach sich gegen eine geplante Änderung aus und entschied sich für die Beibehaltung der ursprünglichen Ethereum-Blockchain. So entstand Ethereum Classic ETC mit dem Proof-of-Work-Konsensmechanismus, während die Ethereum-Blockchain, die die Protokolländerung akzeptierte, fortan als die neue Version von Ethereum existierte. Die nativen Token des Netzwerks werden mit ETC abgekürzt. Mit diesen Token können beispielsweise Transaktionen durchgeführt oder Smart Contracts ausgeführt werden.',
      },
      qnt: {
        description:
          'Quant Network (QNT) hat sich zum Ziel gesetzt, Netzwerke und Blockchains durch Betriebssysteme zu verbinden. Der Quant Token löst das Problem der Interoperabilität durch die Entwicklung des ersten Blockchain-Betriebssystems seiner Art, das es Entwicklern ermöglicht, mehrere Blockchains gleichzeitig zu starten. Dies wird mit Hilfe von QNT, dem nativen Token des Quant-Netzwerks, erreicht. QNT ist ein ERC-20 Utility Token, der auf der Ethereum-Blockchain gehostet wird und dazu dient, Lizenzen und Gebühren zu bezahlen, die Entwickler für den Zugang zum Quant-Netzwerk benötigen.',
      },
      xtz: {
        description:
          'Tezos (XTZ) ist ein dezentrales Blockchain-Netzwerk für Smart Contracts, das es allen Beteiligten ermöglicht, gemeinsam über Änderungen abzustimmen, ähnlich wie bei Ethereum (Proof of Stake). Die Kernidee hinter Tezos ist es, ein Blockchain-Governance-System zu schaffen, das sich selbst durch XTZ-Token-Abstimmungen verbessern kann. Die Tezos-Entwickler streben eine fortschrittliche Infrastruktur an, die es dem Tezos-Netzwerk ermöglichen soll, sich im Laufe der Zeit weiterzuentwickeln und zu verbessern, ohne dabei auf Hard Forks, also Protokolländerungen und damit Abspaltungen von der ursprünglichen Blockchain, zurückgreifen zu müssen. Die technische Basis und die damit verbundene hohe Sicherheit soll die Tezos-Blockchain laut den Gründern daher vor allem für den Einsatz in sensiblen Bereichen, wie z.B. staatlichen Institutionen, qualifizieren.',
      },
      grt: {
        description:
          'The Graph (GRT) ist ein dezentralisiertes Netzwerk für die Abfrage von Blockchain-Daten. Das Netzwerk und seine native Kryptowährung GRT wurden geschaffen, um die Komplexität und Schwierigkeit der Abfrage von Daten aus Blockchains wie der Ethereum-Blockchain zu reduzieren. Das Netzwerk funktioniert wie eine Suchmaschine für Daten innerhalb bestimmter Blockchains. So ermöglicht The Graph die Abfrage von Daten, die direkt aus der Blockchain nur schwer auslesbar sind. Das Netzwerk wird durch die native Kryptowährung GRT unterstützt. Sie wird für die Bezahlung von Dienstleistungen im Netzwerk verwendet.',
      },
    },
    openPL: `# Veränderung

  Die Veränderung deines Portfolios wird auf Basis des Verkaufspreises berechnet. Als Veränderung wird dir die Summe deiner Gewinne und Verluste angezeigt, die du durch den Verkauf deiner gesamten Kryptowährungen erzielen könntest.

  # Rendite

  Die Prozentangabe ist die Rendite, die du durch den Verkauf deiner gesamten Kryptowährungen erzielen könntest.
      `,
    closedPL: `# Realisierte GuV

  Als realisierte Gewinne und Verluste (GuV) wird die Summe deiner Gewinne und Verluste angezeigt, die du durch den Verkauf von Kryptowährungen erzielt hast. Gewinne und Verluste werden bei BISON nach dem FIFO Prinzip (First-In-First-Out) errechnet.
  
  # Rendite

  Die Prozentanzeige ist die durchschnittliche volumenbasierte Rendite deiner Verkäufe von Kryptowährungen.
      `,
  },
  maintenance: {
    text: 'Entschuldigung, BISON befindet sich aktuell in Wartung und wird dir schnellstmöglich wieder zur Verfügung stehen.',
  },
  statusFallback: {
    down: 'Entschuldigung, BISON befindet sich aktuell in Wartung und wird dir schnellstmöglich wieder zur Verfügung stehen.',
    warn: 'Aufgrund der geplanten Wartung kann es zu Unterbrechungen kommen',
  },
  copyToClipboard: 'In die Zwischenablage kopiert',
  footer: {
    poweredBy: 'powered by',
    de: 'Deutschland',
    relevantLinks: 'Relevante Links',
    contact: 'Kontakt',
    dataProtection: 'Datenschutzerklärung',
    boerse: {
      text: 'Börse Stuttgart',
      url: 'https://www.boerse-stuttgart.de/de-de/',
    },
    solaris: {
      text: 'Kooperationspartner Solaris SE',
      url: 'https://www.solarisgroup.com/de/',
    },
    impressum: {
      text: 'Impressum',
      url: 'https://bisonapp.com/impressum/',
    },
    datenschutzerklarung: {
      text: 'Datenschutzerklärungen',
      url: 'https://bisonapp.com/dokumente/datenschutz/',
    },
    disclaimer: {
      text: 'Disclaimer',
      url: 'https://bisonapp.com/disclaimer/',
    },
    riskInfo: {
      text: 'Basis- und Risikoinformationen',
      url: 'https://bisonapp.com/dokumente/risikoinformationen/',
    },
    agb: {
      text: 'AGB',
      url: 'https://bisonapp.com/dokumente/agb/',
    },
    termsOfUse: 'Nutzungsbedingungen',
  },
  betaAccess: {
    forbidden: ` Du bist leider nicht für die Beta Phase für BISON Web (PC Version) freigeschalten.

Wenn du Beta-Nutzer werden möchtest, schreibe uns gerne eine E-Mail an [Kundenservice Formular]({{supportLink}}).

Natürlich kannst du weiterhin BISON über die App nutzen.
    `,
    announcement: {
      header: '*BISON Web ist in der Beta Phase.*',
      body: ` Du hast eine Einladung zur Closed Beta vom BISON Team erhalten? Dann melde dich einfach mit deinen Zugangsdaten aus der App an. 

Du möchtest uns Feedback geben, hast offene Fragen oder willst als BISON Nutzer Zugang zur Beta beantragen? Schreib uns gerne an [hallo@bisonapp.com](mailto:hallo@bisonapp.com).
 `,
    },
  },
  beta: {
    header:
      'Du nimmst an einer Beta-Test-Phase teil. Du musst dir bewusst sein, dass in dieser Phase in der Web-Version u.U. nicht ständig alle Funktionalitäten zur Verfügung stehen. Für die Nutzung von BISON Web gelten für den Zeitraum der geschlossene Beta-Phase dieselben Bedingungen wie für die BISON App.',
    checkbox: 'Ja, das habe ich verstanden.',
    name: 'Beta-Phase',
  },
  updatedTermsAndConditions: {
    title: 'Änderung der Allgemeinen Geschäftsbedingungen',
    header:
      'Wir haben dich über die Änderung der Allgemeinen Geschäftsbedingungen informiert. Diese gelten mit deiner Zustimmung.',
    mainText:
      'Bitte nimm dir einen Moment Zeit, um unsere aktualisierten AGB zu lesen und diesen zuzustimmen.',
    contentTerms: {
      a: `Ich habe die aktualisierten`,
      b: `und der`,
      c: ` gelesen, gespeichert und stimme diesen ausdrücklich zu.`,
    },
    contentTerms2:
      'Ich stimme ausdrücklich zu und weise blocknox damit an, für mich in Verwahrung genommene Kryptowährungen zum Staking zu nutzen und die draus erzielten Rewards zu verwenden. Die Verwahrung bleibt für mich kostenlos.',
    termsLinks: {
      a: 'AGB der blocknox',
      b: 'EUWAX AG',
      c: 'Sonderbedingungen der Solaris SE und die Sonderbedingungen der Solaris SE für die Soforteinzahlung',
      d: 'AGB der EUWAX AG',
    },
    dataLinks: {
      a: 'Datenschutzerklärung der EUWAX AG',
      b: 'die Datenschutzerklärung der blocknox GmbH',
      and: 'und',
    },
    agree: 'Ich stimme zu',
    cancel: 'Abbrechen',
    exitIntent: {
      title: 'Bist du sicher, dass du dieses Fenster schließen möchtest?',
      body: 'Beachte: Wenn du BISON weiterhin wie gewohnt nutzen möchtest, ist es notwendig, dass du unseren neuen AGB zustimmst.',
      primaryAction: 'Zurück zur Zustimmung',
      secondaryAction: 'Fenster schließen',
    },
    notification: {
      success: 'Zustimmung zu neuen AGB erfolgreich.',
      fail: 'Zustimmung zu den neuen AGB hat nicht geklappt. Bitte versuche es später noch einmal.',
      failAgain:
        'Zustimmung zu den neuen AGB hat nicht geklappt. Bitte kontaktiere unseren Kundenservice unter {{email}}.',
    },
  },
  updatedTermsAndConditionsSavingsPlan: {
    title: 'Allgemeine Geschäftsbedingungen',
    header:
      'Neu: Wir bieten dir ab sofort die Möglichkeit Sparpläne zu definieren. Hierfür ist es jedoch erforderlich, dass du den angepassten AGB der EUWAX AG sowie den geänderten Basis- und Risikoinformationen zustimmst. Mit deiner Zustimmung finden diese geänderten AGB für dich unmittelbar Anwendung.',
    contentTerms: {
      a: `Ich habe die aktualisierten`,
      b: `gelesen, gespeichert und stimme diesen ausdrücklich zu.`,
    },
    termsLinks: {
      a: 'Allgemeinen Geschäftsbedingungen der EUWAX AG ',
    },
    dataLinks: {
      a: 'Basis- und Risikoinformationen',
    },
    agree: 'Akzeptieren',
    cancel: 'Abbrechen',
  },
  featureTermsAndConditions: {
    title: 'Sonderbedingungen für Soforteinzahlungen',
    bodyText:
      'Für die Nutzung von Soforteinzahlungen ist deine Zustimmung zu den Sonderbedingungen notwendig.',
    checkboxText:
      'Ich habe die Sonderbedingungen der Solaris, die Sonderbedingungen Soforteinzahlung und die Anlage Preise für Sonderleistungen gelesen, gespeichert und stimme diesen ausdrücklich zu.',
    agree: 'Ich stimme zu',
    failure: 'Zustimmung zu den Sonderbedingungen hat nicht geklappt. Bitte versuche es später noch einmal.',
    success: 'Zustimmung zu den Sonderbedingungen erfolgreich.',
    exitPopup: {
      title: 'Bist du sicher, dass du dieses Fenster schließen möchtest?',
      body: 'Beachte: Wenn du die Funktion nutzen möchtest, ist es notwendig, dass du den Sonderbedingungen zustimmst.',
      primaryAction: 'Zurück zur Zustimmung',
      secondaryAction: 'Fenster schließen',
    },
    documents: {
      1: 'BISON_Solaris_Sonderleistungen_Preise_1.0 (81 KB)',
      2: 'BISON_Solaris_Sonderbedingungen_1.11 (396 KB)',
      3: 'BISON_Solaris_Sonderbedingungen_Soforteinzahlung_1.1.1 (94 KB)',
    },
  },
  stakingFeatureTermsAndConditions: {
    title: 'Sonderbedingungen für Staking',
    bodyText: {
      withoutTandC:
        'Für die Nutzung von Staking ist deine Zustimmung zu den Staking-Sonderbedingungen erforderlich:',
      withTandC:
        'Für die Nutzung von Staking ist deine Zustimmung zu den aktuellen Allgemeinen Geschäftsbedingungen und den Staking-Sonderbedingungen der Boerse Stuttgart Digital Custody GmbH für Privatkunden erforderlich:',
    },
    checkboxText: {
      a: 'Ich habe die AGB der Boerse Stuttgart Digital Custody GmbH gelesen, gespeichert und stimme diesen ausdrücklich zu.',
      b: 'Ich habe die Staking-Sonderbedingungen der Boerse Stuttgart Digital Custody GmbH für Privatkunden gelesen, gespeichert und stimme diesen ausdrücklich zu.',
      c: 'Ich stimme ausdrücklich zu und weise Boerse Stuttgart Digital Custody GmbH damit an, für mich in Verwahrung genommene Kryptowährungen zum Staking zu nutzen und die daraus erzielten Rewards zu verwenden.',
    },
    rewards: `Info:
<1/><1/>Die Rewards deiner von dir ins Staking gegebenen Kryptowährungen erhältst du gemäß unseren Staking-Sonderbedingungen der Boerse Stuttgart Digital Custody GmbH wöchentlich.
<1/><1/>Solltest du den Staking-Sonderbedingungen zugestimmt und deine Weisung zum Staking erteilt haben, deine Kryptowährungen jedoch nicht ins Staking geben, darf die Boerse Stuttgart Digital Custody GmbH deine Kryptowährungen zum Staking nutzen und die daraus erzielten Rewards verwenden.
<1/><1/>Die Verwahrung bleibt für dich kostenlos. Eine Staking-Versicherung ist für dich in das Angebot integriert.
<1/><1/>Eine Zustimmung zu den Sonderbedingungen und das Erteilen der Weisung zum Staking ist für dich nicht zwingend. Wenn du BISON nicht für dein Staking verwenden möchtest, klicke einfach auf das "X" des Pop-ups.`,
    agree: 'Ich stimme zu',
    updateStatusNotifications: {
      withoutTandC: {
        success: 'Die Zustimmung zu den Sonderbedingungen war erfolgreich.',
        failure:
          'Die Zustimmung zu den Sonderbedingungen hat nicht geklappt. Bitte versuche es später erneut.',
        failureForMultipleTimes:
          'Die Zustimmung zu den Staking-Sonderbedingungen hat nicht geklappt. Bitte kontaktiere unseren Kundenservice unter {{email}}.',
      },
      withTandC: {
        success: 'Die Zustimmung zu neuen AGB & Staking-Sonderbedingungen war erfolgreich.',
        failure:
          'Die Zustimmung zu den neuen AGB & Staking-Bedingungen hat nicht geklappt. Bitte versuche es später erneut.',
        failureForMultipleTimes:
          'Die Zustimmung zu den neuen AGB & Staking-Sonderbedingungen hat nicht geklappt. Bitte kontaktiere unseren Kundenservice unter {{email}}.',
      },
    },
    exitPopup: {
      title: 'Bist du sicher, dass du dieses Fenster schließen möchtest?',
      body: {
        withoutTandC:
          'Beachte: Wenn du die Funktion Staking nutzen möchtest, ist es erforderlich, dass du den Sonderbedingungen zustimmst.',
        withDirective:
          'Beachte: Wenn du die Funktion Staking nutzen möchtest, ist es erforderlich, dass du den Sonderbedingungen zustimmst und die Weisung zum Staking erteilst.',
        withTandC:
          'Beachte: Wenn du die Funktion Staking nutzen möchtest, ist es erforderlich, dass du den aktuellen AGB und den Staking-Sonderbedingungen der Boerse Stuttgart Digital Custody GmbH zustimmst, sowie die Weisung zum Staking erteilst.',
      },
      primaryAction: 'Zurück zur Zustimmung',
      secondaryAction: 'Fenster schließen',
    },
    documents: {
      specialConditions: 'Staking-Sonderbedingungen.pdf',
      general: 'AGB-BSD-Custody.pdf',
    },
  },
  tradeOrder: {
    buyPrice: 'Kaufpreis', // Buy price
    sellPrice: 'Verkaufspreis', // Sell price
    cryptoAmount: 'Anzahl',
    eurAmount: 'Betrag',
    sellRequest: 'Verbindliche Verkaufsanfrage senden',
    buyRequest: 'Verbindliche Kaufanfrage senden',
    limitOrder: '+ Limit Order',
    cryptoAvailable: 'Verfügbare {{crypto}} : ',
    eurAvailable: 'Verfügbare Euro: ',
    illiquidMarketWarning: {
      buy: {
        text: 'Beachte, dass der Kaufpreis aktuell stark vom Marktpreis abweicht. Du kannst ggf. einen besseren Preis zu erhalten, in dem du den Betrag reduzierst oder deine Orders aufteilst.',
        modalTitle: 'Warum ist der Kaufpreis aktuell höher?',
        modalText: `
Ist das Volumen einer angefragten Order sehr hoch, kann es zu größeren Differenzen zum angezeigten Marktpreis kommen. Aber Warum?

Die Differenz zwischen Kauf- und Verkaufspreis unterliegt Schwankungen am Markt. Bei großen Käufen kann es vorkommen, dass auf ein breiteres Angebot am Markt zurückgegriffen werden muss, wodurch dann im Durchschnitt ein höherer Kaufpreis entstehen kann. 

Um ggf. einen besseren Preis zu erhalten, kannst du den Betrag reduzieren oder in mehrere kleine Orders aufteilen.
        `,
      },
      sell: {
        text: 'Beachte, dass der Verkaufspreis aktuell stark vom Marktpreis abweicht. Du kannst ggf. einen besseren Preis erhalten, in dem du den Betrag reduzierst oder deine Orders aufteilst.',
        modalTitle: 'Warum ist der Verkaufspreis aktuell niedriger?',
        modalText: `
Ist das Volumen einer angefragten Order sehr hoch, kann es zu größeren Differenzen zum angezeigten Marktpreis kommen. Aber Warum?

Die Differenz zwischen Kauf- und Verkaufspreis unterliegt Schwankungen am Markt. Bei großen Verkäufen kann es vorkommen, dass auf ein breiteres Angebot am Markt zurückgegriffen werden muss, wodurch dann im Durchschnitt ein niedrigerer Verkaufspreis entstehen kann. 

Um ggf. einen besseren Preis zu erhalten, kannst du den Betrag reduzieren oder in mehrere kleine Orders aufteilen.
        `,
      },
    },
  },
  stocksEtfsTradeOrder: {
    common: {
      title: {
        buy: '{{assetType}} kaufen',
        sell: '{{assetType}} verkaufen',
      },
      priceLabel: {
        buy: 'Kaufpreis',
        sell: 'Verkaufspreis',
      },
      inputLabel: 'Anzahl',
      inputPlaceholder: 'max. {{maxAmount}}',
      expected: 'Voraussichtliche Summe (Indikativ)',
      available: 'Verfügbar: {{available}}',
      commission: 'Die Gebühr pro Trade beträgt <strong>{{fee}}</strong><br/> Zu den',
      linkText: 'Kosteninformationen',
      buttonText: 'Zum Portfolio',
      summary: {
        a: 'Anzahl',
        b: 'Kurs',
        c: 'Orderprovision',
        price: {
          buy: 'Kaufpreis',
          sell: 'Verkaufspreis',
        },
        countdown: {
          running: 'Wir haben den Preis für dich reserviert.',
          expired: 'Dein Angebot ist abgelaufen.',
          expiredExplanation: 'Dein Angebot ist abgelaufen. Bitte erneut abfragen.',
        },
        confirmButton: {
          buy: 'Kaufanfrage senden',
          sell: 'Verkaufsanfrage senden',
        },
        refreshButton: 'Angebot aktualisieren',
      },
      success: {
        headline: {
          one: '1 {{assetCategory}} {{type}}',
          multiple: '{{amount}} {{assetCategory}} {{type}} ',
        },
        type: {
          buy: 'gekauft',
          sell: 'verkauft',
        },
        summaryText: {
          one: 'Du hast {{amount}} <strong>{{assetName}}</strong> {{assetCategory}} \nzu {{price}}für <strong>{{totalValue}}</strong> {{type}}.',
          multiple:
            'Du hast {{amount}} <strong>{{assetName}}</strong> {{assetCategory}} \nzu je {{price}} für <strong>{{totalValue}}</strong> {{type}}.',
        },
        pendingSuccessText: {
          buy:
            '<strong>Beachte:</strong> Deine Kaufanfrage wird noch verarbeitet. Es kann bis zu 5 Minuten dauern bis die Bearbeitung deiner Transaktion unter <1>Aktivität</1> abgeschlossen ist.\n' +
            '\n' +
            'Keine Sorge, in den allermeisten Fällen wird der Kauf regulär durchgeführt. Sollte dies nicht der Fall sein, informieren wir dich sofort.',
          sell:
            '<strong>Beachte:</strong> Deine Verkaufsanfrage wird noch verarbeitet. Es kann bis zu 5 Minuten dauern bis die Bearbeitung deiner Transaktion unter <1>Aktivität</1> abgeschlossen ist.\n' +
            '\n' +
            'Keine Sorge, in den allermeisten Fällen wird der Verkauf regulär durchgeführt. Sollte dies nicht der Fall sein, informieren wir dich sofort.',
        },
      },
      error: {
        title: {
          buy: '{{assetCategory}} konnten leider nicht gekauft werden',
          sell: '{{assetCategory}} konnten leider nicht verkauft werden',
        },
        text: {
          buy: 'Leider konnten wir deine Kaufanfrage für {{assetName}} nicht ausführen. Bitte versuche es später noch einmal.',
          sell: 'Leider konnten wir deine Verkaufsanfrage für {{assetName}} nicht ausführen. Bitte versuche es später noch einmal.',
        },
        maxAmount: {
          aboveMaxTradeValue: 'Das maximale Investitionsvolumen pro Trade beträgt {{max}}',
          aboveMaxAccountFunds: 'Du hast nicht genügend verfügbare Euro.',
          aboveMaxSellAmount:
            'Die angegebene Anzahl überschreitet deine verfügbaren Anteile {{max}}. Bitte gib eine Anzahl von maximal {{max}} an.',
        },
        minAmount:
          'Die Anzahl ist zu niedrig. Gib eine Anzahl an, die eine Summe von mindestens {{min}} ergibt.',
      },
      pending: {
        title: {
          buy: 'Wir verarbeiten gerade deine Kaufanfrage ...',
          sell: 'Wir verarbeiten gerade deine Verkaufsanfrage ...',
        },
        text: 'Dies kann in Ausnahmefällen einige Minuten dauern. Bitte verlasse diesen Screen nicht.',
      },
    },
    riskConfirmation: {
      label:
        'Der Handel dieses Finanzinstruments ist auf Basis meiner Erfahrungen und Kenntnisse nicht angemessen. Ich möchte trotz des bekannten Risikos handeln.',
    },
    fundConfirmation: {
      label:
        'Ich bin damit einverstanden, dass mir die gesetzlichen <1>{{linkLabel}}</1> anstatt in Papierform hier in elektronischer Form zur Verfügung gestellt werden. Ich habe diese heruntergeladen und abgespeichert.',
      linkLabel: 'Verkaufsunterlagen',
    },
    nonUsConfirmation: {
      label:
        'Ich bestätige, dass ich keine US-Person bin und ich mich aktuell auch nicht in den Vereinigten Staaten von Amerika aufhalte.',
      modalMarkdown: `Das von dir ausgewählte Wertpapier unterliegt US-Sanktionen. Entsprechend der Verordnung ("Executive Order") Nr. 13959 darf dieses von US-Personen weder erworben noch gehalten werden. 

US-Personen sind:
- Personen mit US-amerikanischer Nationalität;
- Personen mit dauerhaftem Aufenthalt in den Vereinigten Staaten von Amerika;
- US-amerikanische natürliche und juristische Personen unabhängig des Aufenthaltsorts;
- Green-Card Inhaber unabhängig ihres Aufenthaltsorts;
- Personen, die sich vorübergehend auf US-amerikanischen Boden befinden, z.B. für einen Urlaub. 

Um das Wertpapier daher handeln zu können, musst du daher bestätigen, dass du keine US-Person nach oben genannter Definition bist und dich insbesondere während der Order-Erteilung nicht in den USA aufhältst. Andernfalls würdest du gegen bestehende US-Sanktionen verstoßen.`,
      modalHeader: 'Wertpapier unterliegt US-Sanktionen',
    },
    structuredConfirmation: {
      label:
        'Ich bin damit einverstanden, dass mir die gesetzlichen <1>{{linkLabel}}</1> anstatt in Papierform hier in elektronischer Form zur Verfügung gestellt werden. Ich habe diese heruntergeladen und abgespeichert.',
      linkLabel: 'Verkaufsunterlagen',
      modalMarkdown: `Diese Anlegerinformationen enthalten auch einen Hinweis darauf, wo der Verkaufsprospekt und der letzte veröffentlichte Jahres- und Halbjahresbericht aus der Seite des Fondsanbieters zum Download zur Verfügung stehen.`,
      modalHeader: 'Zusätzliche Hinweise zu Anlegerinformationen (KID)',
    },
    highRiskModal: {
      title: '{{assetType}} konnten leider nicht gekauft werden',
      body: 'Auf Basis deiner Erfahrung, die auf deinen Antworten im Freischaltungsprozess basiert, hast du nicht genug Kenntnisse innerhalb der Anlageklasse dieses Wertpapiers.<br><br> Deine Erfahrung mit Wertpapieren kannst du in deinem Konto im Bereich <1>{{profileLink}}</1> einsehen und bearbeiten.',
      bodyLink: 'Profil',
    },
    costInformationModal: {
      quantityAbbreviation: 'Stk.',
      title: 'Kosteninformationen zum Wertpapierhandel',
      transaction: {
        title: 'Transaktion',
        orderTypeAmount: 'Ordertyp & Anzahl',
        orderTypeAmountText: '{{orderType}} {{count}} Stk.',
        amountBuyOrderType: 'Kauf',
        amountSellOrderType: 'Verkauf',
        buyOrderType: 'Kaufpreis',
        sellOrderType: 'Verkaufspreis',
        security: 'Wertpapier',
        fee: 'Orderprovision',
        price: '{{orderType}} pro Stück',
        totalOrderPriceBuy: 'Kaufpreis gesamt',
        totalOrderPriceSell: 'Verkaufspreis gesamt',
        details:
          'Die Gesamtkosten eines Geschäfts setzen sich aus den Kosten des Kaufs, während der Haltedauer und des Verkaufs zusammen und betragen bei einer angenommenen Haltedauer von einem Jahr {{fee}} und damit {{feePercantage}} vom Kurswert. Die Rendite würde bei einer angenommenen Haltedauer von einem Jahr damit ebenfalls um {{feePercantage}} reduziert werden. Die tatsächlichen Gesamtkosten können davon abweichen, z.B. aufgrund einer anderen Haltedauer.',
      },
      costs: {
        title: 'Kosten',

        buy: {
          title: 'Kauf (einmalig)',
          serviceFees: 'Dienstleistungskosten',
          fee: 'Orderprovision',
          discount: 'Rabattierung',
          incentives: 'Zuwendungen',
          productCosts: 'Produktkosten',
        },
        holdingPeriod: {
          title: 'Pro Jahr Haltedauer (fortlaufend)',
          tpcSecurity: 'Fremdkosten Wertpapier',
        },
        sell: {
          title: 'Verkauf (einmalig)',
          serviceFees: 'Dienstleistungskosten',
          fee: 'Orderprovision',
          discount: 'Rabattierung',
          incentives: 'Zuwendungen',
          productCosts: 'Produktkosten',
        },
        details:
          'Wir weisen darauf hin, dass justTRADE für diese Transaktion eine durchschnittliche Rückvergütung gemäß oben stehender Tabelle erhält, die wir an unsere Kunden in Form einer Rabattierung der Orderprovision ausschütten. Sollte die Zuwendung geringer als die Orderprovision ausfallen, erklärt die Bank ihren Verzicht auf die Differenz. Die vom Kunden zu zahlende, effektive Orderprovision beträgt daher im Ergebnis in jedem Fall {{fee}}.',
      },
      totalCosts: {
        title: 'Gesamtkosten',
        firstYear: 'Im ersten Jahr (zzgl. anteiliger Produktkosten)',
        holdingCosts: 'Pro Jahr Haltedauer',
        saleYear: 'Im ersten Jahr des Verkaufs (zzgl. anteiliger Produktkosten)',
        important: {
          title: 'Wichtiger Hinweis:',
          details:
            'Bitte beachte, dass einige Gebührenbestandteile in der Auflistung nicht berücksichtigt werden, da diese vor Orderaufgabe noch nicht feststehen bzw. nicht in exakter Höhe ermittelt werden können. Hierunter fallen bspw. die Servicegebühr für die Eintragung von Namensaktien oder Verwahrgebühren für bspw. Xetra-Gold. Die ggf. bei Anleihen anfallenden Stückzinsen werden in der Auflistung ebenfalls nicht berücksichtigt.',
        },
      },
      actionButton: 'Zurück zur Zusammenfassung',
      error: {
        title: 'Fehler bei Kosteninformationen',
        get: 'Beim Abrufen der Kosteninformationen ist ein Fehler aufgetreten.',
      },
    },
    noteModal: {
      title: {
        buy: 'Hinweis zur Dauer des Kaufangebots',
        sell: 'Hinweis zur Dauer des Verkaufsangebots',
      },
      text: 'Bitte beachte, dass wir den Preis für dich nur wenige Sekunden halten können.\n\nFalls dein Angebot abläuft, kannst du es im folgenden Schritt einfach aktualisieren.',
      checkboxText: 'Diese Meldung nicht mehr anzeigen.',
      buttonText: 'Ok',
    },
    stock: {
      name: 'Aktien',
      summaryName: 'Aktien',
      summaryNameOne: 'Aktie',
    },
    etf: {
      name: 'ETF',
      summaryName: 'ETF-Anteile',
      summaryNameOne: 'ETF-Anteil',
    },
    etc: {
      name: 'ETC',
      summaryName: 'Rohstoff-ETPs',
      summaryNameOne: 'Rohstoff-ETP',
    },
    etn: {
      name: 'ETN',
      summaryName: 'ETN-Anteile',
      summaryNameOne: 'ETN-Anteil',
    },
    confirmPriceLoadingModal: {
      title: 'Derzeit dauert der Austausch länger. Bitte warten Sie, bis ein Angebot fertig ist.',
    },
    errorModal: {
      notBuyable: {
        title: 'Leider können Sie {{assetName}} nicht kaufen',
        note: 'Das ausgewählte Wertpapier kann derzeit nicht gekauft werden.',
      },
      notSalable: {
        title: 'Leider können Sie {{assetName}} nicht verkaufen',
        note: 'Das ausgewählte Wertpapier kann derzeit nicht verkauft werden.',
      },
      general: {
        title: 'Du kannst {{assetName}} leider nicht erwerben.',
        note: 'Auf Basis deiner Erfahrung, die auf deinen Antworten im Freischaltungsprozess basiert, hast du nicht genug Kenntnisse innerhalb der Anlageklasse dieses Wertpapiers.  Deshalb können wir dir leider keinen Handel mit diesem Wertpapier ermöglichen.\n\nDeine Erfahrung mit Wertpapieren kannst du in deinem Konto im Bereich Profil einsehen.',
      },
      button: 'Zurück zum Wertpapier',
      support:
        'Noch offene Fragen? Wir helfen dir gerne, kontaktiere uns gerne jederzeit unter <1>{{link}}</1>.',
    },
    quoteErrorModal: {
      title: 'Entschuldigung, dein Angebot kann im Moment nicht berechnet werden',
      text: {
        buy: 'Leider konnte kein Angebot zu deiner Kaufanfrage für {{assetName}} berechnet werden. Bitte versuche es später noch einmal.',
        sell: 'Leider konnte kein Angebot zu deiner Verkaufsanfrage für {{assetName}} berechnet werden. Mögliche Gründe dafür können z. B. sein, dass eine Kapitalmaßnahme durchgeführt wird, diese Aktie nicht mehr handelbar ist oder sich in einer Handelsunterbrechung befindet. \nBitte versuche es später noch einmal.',
      },
      button: 'Nochmal versuchen',
    },
  },
  cookieConsent: {
    text: {
      a: 'Wir verwenden',
      b: 'Cookies',
      c: 'oder verwandte Technologien („Cookies”), um unsere Webseite und die dortigen Dienste bereitstellen, nutzerfreundlich gestalten und fortlaufend verbessern zu können. Während „notwendige Cookies“ für die Nutzung der Webseite erforderlich sind, helfen uns die übrigen Cookies bei der Optimierung und Fortentwicklung unserer Dienste und dienen damit deren wirtschaftlichen Betrieb. Nicht notwendige Cookies setzen wir erst ein, wenn du damit ausdrücklich einverstanden bist. Dein Einverständnis kannst du durch Anklicken von „Alle Cookies akzeptieren“ erklären oder in dem du einer bestimmen Nutzung von Cookies zustimmst. Deine Einwilligung in die Nutzung von Cookies kann jederzeit für die Zukunft widerrufen und die Einstellungen nachträglich geändert werden.',
      d: 'Zudem weisen wir darauf hin, dass durch die Nutzung dieser Webseite deine Daten auch von US-amerikanischen Unternehmen erfasst und verarbeitet werden. Wenn du damit einverstanden bist, klicke auf „Alle Cookies akzeptieren“. Damit willigst du ein, dass nach Art. 49 Abs. 1 S. 1 a) DS-GVO deine Daten in Drittländer wie den USA übermittelt und verarbeitet werden können.',
      e: 'Nähere Informationen zu der Übermittlung in Drittstaaten befinden sich in der',
      f: 'Datenschutzerklärung',
      g: '.',
    },
    select: {
      necessary: 'Notwendig',
      marketing: 'Marketing / Analyse',
    },
    buttons: {
      acceptAll: 'Alle Cookies akzeptieren',
      notAccept: 'Keine Cookies akzeptieren',
      select: 'Cookies auswählen',
      save: 'Auswahl anzeigen',
    },
  },
  alerts: {
    title: 'Preisalarm',
    web_pageHeadline: '{{currency}} Preisalarm',
    noAlerts: 'Kein Preisalarm',
    newAlert: 'Neuer Preisalarm',
    editAlertTitle: 'Edit Preisalarm',
    createAlertTitle: 'Preisalarm erstellen',
    reactivationTime: 'Dein Alarm ist bis {{time}} deaktiviert. Drücke hier, um diesen wieder zu aktivieren.',
    showAdvanced: 'Erweiterte Optionen anzeigen',
    hideAdvanced: 'Erweiterte Optionen verbergen',
    notificationsDisabled: `Deine Push-Benachrichtigungen sind derzeit deaktiviert\n\nUm Preisalarme erhalten zu können, musst du zunächst Push-Benachrichtigungen von BISON zulassen.`,
    enableNotifications: 'Benachrichtigungen erlauben',
    sendMe: 'Sende mir',
    for: 'Für',
    when: 'Wenn',
    price: 'Preis',
    afterBeingTriggeredThisAlertWillBeSuspended: 'Dieser Preisalarm wird deaktiviert',
    gracePeriod: 'Unterbrechungszeitraum',
    web_crypto: 'KRYPTO',
    web_alertPrice: 'ALARM PREIS',
    web_deleteOrder: 'Preisalarm löschen',
    web_currentPrice: 'Aktueller Preis',
    currentPrice: {
      buy: 'Aktueller Kaufpreis',
      sell: 'Aktueller Verkaufspreis',
    },
    explanationTitle: 'Erläuterung',
    disclaimerTitle: 'Disclaimer',
    gracePeriods: {
      '1h': 'für 1h nachdem er ausgelöst wurde',
      '2h': 'für 2h nachdem er ausgelöst wurde',
      '6h': 'für 6h nachdem er ausgelöst wurde',
      '12h': 'für 12h nachdem er ausgelöst wurde',
      '24h': 'für 24h nachdem er ausgelöst wurde',
      triggerOnce: 'nach Auslösung',
    },
    sendingType: {
      Push: 'eine Push-Benachrichtigung',
      Email: 'eine Email',
      web_Push: 'eine Push-Benachrichtigung (Nur Mobile App)',
    },
    types: {
      WhenSellPriceFallsBelow: 'Verkaufspreis kleiner als',
      WhenBuyPriceFallsBelow: 'Kaufpreis kleiner als',
      WhenSellPriceRisesAbove: 'Verkaufspreis größer als',
      WhenBuyPriceRisesAbove: 'Kaufpreis größer als',
      WhenMidPriceCrosses: 'Aktueller Preis gleich',
    },
    typesText: {
      WhenSellPriceFallsBelow: 'ich für weniger verkaufen kann als',
      WhenBuyPriceFallsBelow: 'ich für weniger kaufen kann als',
      WhenSellPriceRisesAbove: 'ich für mehr verkaufen kann als',
      WhenBuyPriceRisesAbove: 'ich für mehr kaufen kann als',
      WhenMidPriceCrosses: 'der aktuelle Preis beträgt',
    },
    typesExplanationText: {
      WhenSellPriceFallsBelow: {
        a: 'wenn',
        b: 'ich für weniger als',
        c: 'verkaufen kann',
      },
      WhenBuyPriceFallsBelow: {
        a: 'wenn',
        b: 'ich für weniger als',
        c: 'kaufen kann',
      },
      WhenSellPriceRisesAbove: {
        a: 'wenn',
        b: 'ich für mehr als',
        c: 'verkaufen kann',
      },
      WhenBuyPriceRisesAbove: {
        a: 'wenn',
        b: 'ich für mehr als',
        c: 'kaufen kann',
      },
      WhenMidPriceCrosses: {
        a: 'wenn',
        b: 'der aktuelle Preis gleich',
        c: 'beträgt',
      },
      NotSelected: {
        a: '...',
        b: '',
        c: '',
      },
    },
    web_explanationSplit: `Sende mir <1>{{alertType}}</1> für <3>{{selectedCrypto}}</3> wenn <5>{{timeSelectValue}}</5> <6>{{alertPriceValue}}</6>. Dieser Preisalarm wird <8>{{optionalPart}}</8> deaktiviert, nachdem er ausgelöst wurde.`,
    explanationSplit: {
      a: 'Sende mir',
      b: 'für',
      regular: {
        c: '. Dieser Preisalarm wird für',
        d: 'Stunde{{ending}}',
        e: 'deaktiviert, nachdem er ausgelöst wurde.',
        ending: {
          singular: '',
          plural: 'n',
        },
      },
      once: {
        c: '. Dieser Preisalarm wird',
        d: 'nach Auslösung deaktiviert.',
      },
    },
    form: {
      fieldErrors: {
        entityRequired: 'Bitte wähle eine Kryptowährung aus',
        priceRequired: 'Bitte wähle einen Preis aus',
        priceAlertTypeRequired: 'Bitte wähle einen Alarmtyp aus',
        gracePeriodRequired: 'Bitte wähle einen Unterbrechungszeitraum aus',
      },
    },
    banners: {
      successCreated: 'Preisalarm erfolgreich angelegt.',
      successEdited: 'Preisalarm erfolgreich bearbeitet.',
      successDelete: 'Preisalarm erfolgreich gelöscht.',
      alreadyExists: 'Du hast bereits einen identischen Preisalarm.',
      editError: 'Preisalarm bearbeiten fehlgeschlagen.',
      createError: 'Preisalarm anlegen fehlgeschlagen.',
      genericError: 'Es gab ein Problem beim Erstellen deines Preisalarms.',
      deleteError: 'Es gab ein Problem mit der Löschung deines Preisalarms.',
      fetchingError: 'Es gab ein Problem beim Abrufen deiner Preisalarme.',
    },
    emptyState: {
      title: 'Nichts mehr verpassen!',
      body: `Lege jetzt einen individuellen Preisalarm fest, damit du rechtzeitig von Preisentwicklungen erfährst.`,
    },
    web_subheaderInfo:
      'Bleibe informiert wenn sich die Preise am Krypto-Markt verändern. Erstelle dir so viele Preisalarme wie du möchtest.',
    web_mobileNotifications:
      'Für mobile Benachrichtigungen benötigst du die BISON App auf deinem Smartphone. Einfach herunterladen und Push-Benachrichtigungen von BISON zulassen.',
    status: {
      paused: 'Pausiert',
      active: 'Aktiv',
    },
    pause: {
      success: 'Preisalarm pausiert',
    },
    unpause: {
      success: 'Preisalarm aktiviert',
    },
  },
  savings: {
    web_modalTitle: 'Neuer Sparplan',
    web_selectionTitle: 'Sparplan erstellen',
    tableList: {
      web_crypto: 'KRYPTOWÄHRUNG',
      web_interval: 'INTERVALL',
      web_eurAmount: 'EURO BETRAG',
      web_startingPoint: 'NÄCHSTE AUSFÜHRUNG',
    },
    savingsPlanDescriptionAmountAndTrigger: {
      web_weekly: 'wöchentlich {{amount}}',
      web_monthly: 'monatlich {{amount}}',
      web_quarterly: 'quartalsweise {{amount}}',
    },
    web_currentPrice: 'Aktueller Kaufpreis',
    web_eurAvailable: 'Verfügbare Euro: ',
    web_successText: `
      Dein {{cryptoName}} Sparplan wurde erfolgreich übermittelt. Du wirst benachrichtigt, sobald ein Kauf ausgeführt wurde.
      \n Bitte sorge dafür, dass dein BISON Konto zu den Kaufterminen immer ausreichend gedeckt ist (z. B. via Dauerauftrag).
      `,
    title: 'Sparplan',
    selectYourCrypto: 'Neuer Sparplan',
    listTitle: 'Sparplan',
    trigger: {
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
      quarterly: 'Quartalsweise',
    },
    startingPoint: {
      FirstOfMonth: '1. Tag des Monats',
      FifteenthOfMonth: '15. Tag des Monats',
      Monday: 'Montag',
      Tuesday: 'Dienstag',
      Wednesday: 'Mittwoch',
      Thursday: 'Donnerstag',
      Friday: 'Freitag',
      Saturday: 'Samstag',
      Sunday: 'Sonntag',
    },
    nextExecution: 'Nächste Ausführung',
    noSavings: {
      title: 'Sparen nach Plan!',
      text: `Wähle einen Sparbetrag und ein Sparintervall für die Kryptowährung deiner Wahl. Wir machen den Rest.`,
      info: `# Sparplan

Der BISON Sparplan ermöglicht es dir regelmäßig mit einen festgelegten Euro Betrag in Kryptowährungen zu investieren. Du bestimmt dabei die Kryptowährung, den Euro Betrag, das Intervall sowie das Kaufdatum innerhalb des Intervalls. Selbstverständlich kannst du auch mehrere Sparpläne parallel laufen lassen. Du kannst deine Sparpläne jederzeit im Trading Manager einsehen, pausieren oder löschen. Wir benachrichtigen dich, sobald dein Sparplan ausgeführt wurde und du einen Kauf getätigt hast.  Bitte beachte, dass dein Sparplan nur wie geplant ausgeführt werden kann, wenn dein BISON-Konto über ausreichend Guthaben verfügt.

Sollte dein Sparplan wegen fehlendem Guthaben nicht ausgeführt werden können, bleibt er weiterhin aktiv und wird zum nächsten Ausführungsdatum erneut ausgeführt. Um eine reibungslose Ausführung von Sparplänen zu gewährleisten, empfiehlt es sich einen Dauerauftrag von deinem Referenzkonto an dein BISON-Konto anzulegen. 
      `,
    },
    newSaving: 'New Savings Plan',
    status: {
      paused: 'Pausiert',
      active: 'Aktiv',
    },
    detail: {
      overview: 'Übersicht',
      crypto: 'Kryptowährung',
      cryptoAmount: 'Krypto Betrag',
      amount: 'Betrag',
      interval: 'Intervall',
      firstExecution: 'Nächste Ausführung',
      invested: 'Investiert',
      value: 'Aktueller Wert',
      delete: 'Löschen',
      pause: 'Sparplan pausieren',
      resume: 'Sparplan fortsetzen',
      profitLoss: 'Veränderung',
      startingPoint: 'Ausführung',
    },
    savingsPlanDescription: {
      text: `Ich möchte <1>{{amountAndTrigger}}</1> in <2>{{crypto}}</2> investieren. 
      
Die nächste Ausführung des Sparplans erfolgt am <3>{{execution}}</3>.`,
    },
    pause: {
      success: 'Dein Sparplan wurde erfolgreich pausiert',
      error: 'Dein Sparplan kann nicht pausiert werden',
    },
    unpause: {
      success: 'Dein Sparplan wurde erfolgreich fortgesetzt',
      error: 'Dein Sparplan kann nicht fortgesetzt werden',
    },
    disable: {
      success: 'Dein Sparplan wurde erfolgreich gelöscht',
      error: 'Dein Sparplan kann nicht gelöscht werden',
    },
    info: {
      web_title: `Sparplan`,
      web_content: `
Der BISON Sparplan ermöglicht dir eine regelmäßige Investition eines festgelegten Euro Betrags in die entsprechenden Kryptowährungen.
    
Zu jeder Kryptowährung kannst du einen festgelegten Euro Betrag, ein regelmäßiges Intervall sowie ein individuelles Kaufdatum innerhalb dieses Intervalls festlegen.
    
Du kannst deinen Sparplan im Menüpunkt "Trading Manager" jederzeit pausieren oder löschen. Ein pausierter Sparplan kann zu einem anderen Zeitpunkt jederzeit fortgesetzt werden. Das Anlegen von mehreren Sparplänen ist bei BISON parallel möglich.
    
Wir benachrichtigen dich, sobald dein Sparplan ausgeführt wurde und du die Kryptowährung gekauft hast.
    
Bitte achte darauf, dass dein Sparplan nur wie geplant ausgeführt werden kann, wenn dein BISON Euro Konto ein entsprechendes Guthaben aufweist. Hierzu kannst du gegebenenfalls ein Dauerauftrag von deinem Referenzkonto anlegen. Wird dein Sparplan aufgrund von unzureichendem Guthaben nicht ausgeführt, bleibt dein Sparplan aktiv und wird zum nächsten Ausführungsdatum ausgeführt, sofern ein entsprechendes Guthaben vorhanden ist. `,
    },
    legalText: `Käufe erfolgen zu einem aktualisierten Kaufpreis`,
    legalTextConfirm: 'Käufe erfolgen zu einem aktualisierten Kaufpreis',
    currencyVolumeLabel: 'Betrag',
    triggerLabel: 'Intervall',
    startingPointLabel: 'Ausführung',
    send: 'Weiter',
    web_send: 'OK, Weiter',
    next: 'Weiter',
    savingsPlanConfirmTitle: '{{cryptoName}} Sparplan',
    confirmSavingsPlanBtnLabel: 'OK, bestätigen',
    successTitle: 'Sparplan übermittelt!',
    failTitle: 'Sparplan abgelehnt.',
    successText: `Dein {{cryptoName}} Sparplan wurde erfolgreich übermittelt. Du wirst benachrichtigt, sobald ein Kauf ausgeführt wurde. 
    
Bitte sorge dafür, dass dein BISON Konto zu den Kaufterminen immer ausreichend gedeckt ist (z. B. via Dauerauftrag).`,

    failText: 'Es gab ein Problem bei der Erstellung deines Sparplans.',
    termsAndConditions: {
      title: 'Title',
      firstParagraph:
        'Tell us your ideas on how to improve the BISON app! We look forward to hearing your feedback and suggestions for new features.',
      secondParagraph:
        "We hope you understand that we can't respond to every single suggestion. If we have specific questions about your request, we will get back to you via email.",
      checkboxPreLink: 'Yes, I read, saved, and accept the updated ',
      firstCheckboxLink: 'terms & conditions of EUWAX AG and blocknox GmbH ',
      firstCheckboxPostLink: '.',
      secondCheckboxLink: 'basis and risk information ',
      secondCheckboxPostLink: '.',
    },
    notEnoughFundsWarning:
      'Bitte sorge dafür, dass dein BISON Konto zu den Kaufterminen immer ausreichend gedeckt ist (z. B. via Dauerauftrag). Für diesen Sparplan fehlen dir aktuell {{amount}} Euro (Kontostand abzgl. offener Aufträge), damit die Transaktion erfolgreich ausgeführt werden kann. \n\n Bitte beachte: Weitere Sparpläne werden bei dieser Berechnung nicht berücksichtigt!',
    confirm: {
      web_header: '{{cryptoName}} Sparplan',
      web_subheader: 'Übersicht',
      web_firstItem: 'Kryptowährung',
      web_secondItem: 'Betrag',
      web_thirdItem: 'Intervall',
      web_fourthItem: 'Ausführung',
      web_fifthItem: 'Nächste Ausführung',
    },
    delete: {
      popUp: {
        web_text: 'Bist du sicher, dass du deinen Sparplan löschen möchtest?',
      },
      screen: {
        web_cryptocurrency: 'Kryptowährung',
        web_euroAmount: 'Betrag',
        web_interval: 'Intervall',
        web_nextExecution: 'Nächste Ausführung',
        web_execution: 'Ausführung',
        web_cryptoAmount: 'Krypto Betrag',
        web_invested: 'Investiert',
        web_value: 'Aktueller Wert',
        web_openPL: 'Veränderung',
        web_deletePlan: 'Löschen',
      },
    },
    inputs: {
      euroAmount: {
        errors: {
          web_zero: 'Bitte trag einen höheren Betrag ein',
          web_exceededValue: 'Das maximale Investitionsvolumen pro Trade beträgt 100.000,00 €.',
        },
        web_label: 'Betrag',
      },
      interval: {
        web_label: 'Intervall',
      },
      buyDate: {
        web_label: 'Ausführung',
        web_weekLabel: 'Ausführung',
      },
    },
    web_confirmText: 'Käufe erfolgen zu einem aktualisierten Kaufpreis',
    confirmDescriptionLabel: 'Verbindlichen Sparplan bestätigen',
    buttons: {
      web_cancel: 'Abbrechen',
      web_confirm: 'Weiter',
    },
  },
  devices: {
    title: 'Geräte',
    device: 'Gerät',
    OSVersion: 'OS version',
    web_OS: 'OS',
    unpair: 'Gerät entkoppeln',
    lastActivity: 'Letzte Aktivität:',
    unknownLastActivity: 'Unbekannt',
    unknownPhone: 'Unbekanntes Gerät',
    unknownBrowser: 'Unbekannter Browser',
    currentDevice: 'Aktuelles Gerät',
    otherDevices: 'Andere Geräte',
    unpairSuccess: 'Dieses Gerät wurde erfolgreich entfernt.',
    unpairError: 'Es gab ein Problem beim Entfernen deines Gerätes. Bitte versuche es später erneut.',
    fetchError: 'Es gab ein Problem beim Empfangen deiner Geräte-Informationen.',
    confirmUnpair: 'Bist du sicher, dass du die Verbindung zu diesem Gerät trennen möchtest?',
    confirmUnpairModal: {
      title: `Bist du sicher, dass du Gerät entkoppeln möchtest?`,
      text: `Bist du sicher, dass du die Verbindung zu diesem Gerät trennen möchtest?`,
    },
    revokeDeviceConsentModal: {
      title: 'Bist du sicher, dass du die Geräteüberwachung für {{device}} aufheben möchtest?',
      text: 'Beachte: Ohne Zustimmung zur Überprüfung der Geräte-Informationen, kann es zur Sperrung oder Kündigung deines Kontos kommen. Die Überprüfung deiner Geräte-Anmeldung schützt dich z.B. vor Kontoübernahmen durch Dritte. ',
      confirmButton: 'Monitoring aufheben',
    },
    revokeDeviceConsectResultNotification: {
      success: 'Geräteüberwachung für {{device}} aufgehoben.',
      error: 'Aufhebung der Geräteüberwach-ung für {{device}} fehlgeschlagen.',
    },
  },
  demoExplanationPopup: {
    headline: 'Ohne Risiko testen',
    subText: 'Willkommen im Demo-Modus - hier kannst du BISON und den Krypto-Markt kennenlernen.',
    1: 'Handel mit Spielgeld',
    2: 'keine Verifizierung nötig',
    3: 'risikofrei den Markt kennenlernen',
    finalText:
      "<1>Please note:</1> While in demo mode you can't make any real losses, but you can't make any real profits either.",
    buttonText: 'Starten',
    verifyText:
      'Du kennst dich bereits gut genug im Krypto Markt aus? <1>Verifiziere dich hier</1> um direkt mit echtem Geld zu handeln.',
  },
  demoPopup: {
    headline: 'Verifzieren und loslegen!',
    subText: 'Alles was du brauchst:',
    1: 'ein gültiger Personalausweis oder Reisepass mit einer aktuellen Rechnung. (z.B.: Telefonabrechnung)',
    2: 'eine stabile Internetverbindung',
    3: 'eine Webcam, einen Laptop mit Kamera oder ein Smartphone',
    finalText: 'Danach kannst du mit Echtgeld Kryptowährungen handeln.',
    header: 'Verifizierung',
  },
  demoPopupForRealUser: {
    headline: 'Demo Modus',
    subText: 'Du befindest dich im Demo-Modus. Diese Funktion ist nur im Echtgeldmodus verfügbar.',
  },
  emptyCryptoPortfolioPage: {
    headline: 'Noch nichts zu sehen',
    mainText:
      'Sobald du deine erste Transaktion gemacht hast, siehst du hier die Übersicht deiner Kryptowährungen.',
    buyCryptoBtn: 'Kryptowährungen kaufen',
    depositCryptoBtn: 'Hier einzahlen.',
    preDepositCryptoText: 'Du hast schon Kryptos?',
  },
  fewCryptoPortfolioPage: {
    headline: 'Weitere Kryptos',
    mainText: 'In der Marktübersicht kannst du verfügbare Kryptos und Kurse ansehen.',
    btnText: 'Marktübersicht',
  },
  lotteryBanner: {
    header: 'BISON App Adventskalender',
    mainText:
      '**Jetzt exklusiv in deiner BISON App!** Zur Adventszeit hast du täglich die Gewinnchance auf 5x100€ in Bitcoin - im letzten Kalendertürchen verstecken sich sogar 1000€ in Bitcoin!',
    linkText: 'Die Aktion findet im Zeitraum 1.12.-24.12. statt. Es gelten die Teilnahmebedingungen.',
  },
  metaTags: {
    app: {
      title: 'BISON Web',
      description:
        'BISON Web ▶ Die Webversion zum Handeln von Kryptowährung - einfach ✅ smart ✅ zuverlässig ✅ powered by Börse Stuttgart',
    },
    signup: {
      title: 'BISON Web Registrieren',
      description:
        'BISON Web Registrieren ▶ Registriere dich jetzt ▶ Die Webversion zum Handeln von Kryptowährung - einfach ✅ smart ✅ zuverlässig ✅ powered by Börse Stuttgart',
    },
    signin: {
      title: 'BISON Web Login',
      description:
        'BISON Web Login ▶ Einloggen und loslegen ▶ Die Webversion zum Handeln von Kryptowährung - einfach ✅ smart ✅ zuverlässig ✅ powered by Börse Stuttgart',
    },
  },
  techIndicators: {
    button: 'Schließen',
  },
  phishing: {
    header_title: 'Phishingversuche',
    headline: 'Achtung, momentan treten vermehrt Phishingversuche auf!',
    text: {
      a: 'BISON wird dich nie per E-Mail nach deinen Zugangsdaten fragen oder dich zu Transaktionen auf fremde Wallet-Adressen auffordern!',
      b: 'Stelle sicher, dass du dich wirklich auf den echten BISON Seiten oder in der BISON App befindest.',
      c: 'Für mehr Details informiere dich',
      linkText: 'hier',
    },
  },
  promotion: {
    savingPlan: {
      termsAndConditions: {
        title: 'Sparen lohnt sich!',
        mainText: `
          Lege deinen ersten Sparplan an und sichere dir bis zu 35€ in BTC! Für die erste erfolgreiche Sparplanausführung erhältst du bereits 10€ in BTC. Dran bleiben lohnt sich: Nach der dritten erfolgreichen Ausführung erhältst du weitere 25€ in BTC.
        `,
        subtitle: 'So einfach ist es:',
        points: {
          a: 'Erstelle deinen ersten Sparplan in der Kryptowährung und in dem Sparintervall deiner Wahl.',
          b: 'Wähle einen Sparbetrag von mindestens 35€ pro Intervall aus und investiere diesen in drei aufeinanderfolgenden Intervallen.',
          c: 'Bitte achte darauf, dass dein BISON Konto ausreichend gedeckt ist, damit dein Sparplan ausgeführt werden kann. Andernfalls kann der Bonus nicht gutgeschrieben werden.',
        },
        label: {
          a: 'Ich möchte teilnehmen, habe die',
          b: 'Teilnahmebedingungen',
          c: 'sowie die',
          d: 'Datenschutzbestimmungen',
          e: 'gelesen und akzeptiere diese. Außerdem habe ich meinen Wohnsitz in Deutschland, Österreich oder der Schweiz. ',
        },
        buttons: {
          agree: 'Mitmachen & Sparplan anlegen',
          cancel: 'Vielleicht später',
        },
      },
      succsessModal: {
        title: 'Du bist dabei!',
        mainText: `
          Wir freuen uns, dass du dich für die Teilnahme an unserer Sparplan Bonus Aktion entschieden hast.
          \n
          Dein nächster Schritt
          \n
          Erstelle deinen ersten Sparplan & investiere mindestens 35€ pro Intervall. Lasse diesen mindestens drei Intervalle laufen, um deinen Bonus in voller Höhe zu erhalten.
        `,
        button: 'Jetzt Sparplan anlegen',
      },
      portfolioBanner: {
        title: 'Erhalte bis zu 35€ in Bitcoin für deinen ersten Sparplan!',
        button: 'Jetzt Sparplan anlegen',
        confirmed: 'Du nimmst teil!',
      },
      accountBanner: {
        title: 'Sparplan Aktion',
        text: 'Läuft noch bis einschließlich {{date}}',
      },
      demoPopup: {
        title: 'Demo-Modus',
        description: `
Aktuell befindest du dich im Demo-Modus. Die Sparplan Funktion ist nur im Echtgeld-Modus verfügbar.

Du möchtest an der Sparplan Bonus Aktion teilnehmen? Dann verifiziere dich ganz einfach in wenigen Minuten per Video-Identifikation und schon kann es losgehen!
      `,
      },
    },
  },
  savingsPlanPromo: {
    title: 'Sparplan Aktion',
    settingsLabel: 'Sparplan Aktion',
    settingsLabelExpiration: 'Läuft noch bis einschließlich ',
    executions: 'Ausgeführt {{executions}} / 3',
    banner: {
      title: 'Erhalte bis zu 35€ in Bitcoin für deinen ersten Sparplan!',
      button: 'Jetzt Sparplan anlegen',
      optedIn: 'Du nimmst teil!',
    },
    optIn: {
      title: 'Sparen lohnt sich!',
      a: 'Lege deinen ersten Sparplan an und sichere dir bis zu 35€ in BTC! Für die erste erfolgreiche Sparplanausführung erhältst du bereits 10€ in BTC. Dran bleiben lohnt sich: Nach der dritten erfolgreichen Ausführung erhältst du weitere 25€ in BTC.',
      b: 'Und so einfach ist es:',
      c: 'Erstelle deinen ersten Sparplan in der Kryptowährung und in dem Sparintervall deiner Wahl.',
      d: 'Wähle einen Sparbetrag von mindestens 35€ pro Intervall aus und investiere diesen in drei aufeinanderfolgenden Intervallen.',
      e: 'Bitte achte darauf, dass dein BISON Konto ausreichend gedeckt ist, damit dein Sparplan ausgeführt werden kann. Andernfalls kann der Bonus nicht gutgeschrieben werden.',
      checkBox: {
        a: 'Ich möchte teilnehmen, habe die ',
        b: 'Teilnahmebedingungen ',
        c: ' sowie die ',
        d: 'Datenschutzbestimmungen ',
        e: ' gelesen und akzeptiere diese. ',
        f: 'Außerdem habe ich meinen Wohnsitz in Deutschland, Österreich oder der Schweiz.',
      },
      confirmButton: 'Mitmachen & Sparplan anlege',
      remindMeLater: 'Vielleicht später',
    },
    optInFinished: {
      title: 'Du bist dabei!',
      text: `Wir freuen uns, dass du dich für die Teilnahme an unserer Sparplan Bonus Aktion entschieden hast.
      
      Dein nächster Schritt: 
      Erstelle deinen ersten Sparplan & investiere mindestens 35€ pro Intervall. Lasse diesen mindestens drei Intervalle laufen, um deinen Bonus in voller Höhe zu erhalten. `,
      cta: 'Jetzt Sparplan anlegen',
    },
    pauseConfirm: {
      title: 'Du möchtest deinen Sparplan wirklich pausieren?',
      text: `Wenn du deinen Sparplan jetzt pausierst, nimmst du nicht mehr an der Sparplan Aktion teil und der Bonus verfällt automatisch.`,
      confirm: 'Sparplan Pausieren',
    },
    deleteConfirm: {
      title: 'Du möchtest deinen Sparplan wirklich löschen?',
      text: `Wenn du deinen Sparplan jetzt löschst, nimmst du nicht mehr an der Sparplan Aktion teil und der Bonus verfällt automatisch. 
      Wurde dein erster Sparplan bislang noch nicht ausgeführt, kannst du ihn jetzt löschen und einen neuen Sparplan anlegen. Somit kannst du erneut an der Aktion teilnehmen.`,
      confirm: 'Sparplan Löschen',
    },
  },
  tradingRules: {
    emptyScreen:
      'Keine Zeit den Kryptomarkt zu beobachten? Erstelle Handelsregeln und kaufe oder verkaufe automatisiert.',
    stopOrderButton: 'Stop Order',
    limitOrderButton: 'Limit Order',
    tradingRules: 'Handelsregeln',
    limitBuy: 'Limit Kauf',
    limitSell: 'Limit Verkauf',
    stopBuy: 'Stop Buy',
    stopLoss: 'Stop Loss',
    buy: 'Kauf',
    sell: 'Verkauf',
  },
  abbreviations: {
    million: 'Mio.',
    billion: 'Mrd.',
  },
  performanceIndicators: {
    title: 'Kennzahlen',
    firstPriceOfTheDay: 'Eröffnungspreis',
    lastPriceOfPreviousTradingDay: 'Letzter Preis des Vortages',
    priceEarningRatio: 'KGV',
    dividendYield: 'Dividenden Rendite',
    dividendByStock: 'Dividende pro Aktie',
    marketCapitalisation: 'Marktkapitalisierung',
    totalExpenseRatio: 'Gesamtkostenquote',
    modalMarkdown: `# Kennzahlen

Kennzahlen sind eine Reihe quantifizierbarer Messgrößen, die zur Beurteilung der langfristigen Leistung von Unternehmen verwendet werden. Sie helfen dabei, die strategische, finanzielle und operative Leistungen eines Unternehmens zu bestimmen, insbesondere im Vergleich zu anderen Unternehmen desselben Wirtschaftszweiges.
    `,
  },
  assetInformation: {
    title: 'Informationen',
    name: 'Name',
    issuerName: 'Emittent',
    headOfficeCountry: 'Herkunftsland',
    sector: 'Sektor',
    wkn: 'WKN',
    isin: 'ISIN',
    typeAppropriationEarnings: 'Ausschüttung',
    showMoreButton: 'Mehr Informationen',
    showLessButton: 'Weniger Informationen',
    information: 'Basisinformationsblatt',
  },
  revenue: {
    title: 'Umsatz',
    modalMarkdown: `# Umsatz
    
Der Umsatz ist der aus dem normalen Geschäftsbetrieb erwirtschaftete Erlös. Der Umsatz ergibt sich aus dem durchschnittlichen Verkaufspreis x Anzahl der verkauften Einheiten. Es handelt sich also um das Bruttoeinkommen, von dem die Kosten abgezogen werden, um das Nettoeinkommen zu ermitteln.
    `,
  },
  ebit: {
    title: 'EBIT',
    modalMarkdown: `# EBIT
    
EBIT (Englisch: Earnings Before Interest and Taxes) ist ein Indikator für die Rentabilität eines Unternehmens. Das EBIT berechnet sich aus den Einnahmen minus Ausgaben ohne Steuern und Zinsen, beschreibt demnach dem Gewinn vor Steuern und Zinsen.
    `,
  },
  ebitda: {
    title: 'EBITDA',
    modalMarkdown: `# EBITDA
    
Das EBITDA (Englisch: Earnings Before Interest, Taxes, Depreciation, and Amortization) beschreibt einen Gewinn vor Zinsen, Steuern, Abschreibungen auf Sachanlagen und immateriellen Vermögensgegenständen. 

Es ist ein Maß für die finanzielle Gesamtleistung eines Unternehmens und eignet sich bspw. für grenzüberschreitende Vergleiche, da Verzerrungen (wie Abschreibungs- oder, Finanzierungspolitik) auf internationaler Ebene aus dem EBITDA herausgerechnet sind. 
    `,
  },
  stockMarketHours: {
    night: {
      banner: 'Kein Handel von Aktien & ETFs in der Zeit von {{close}} – {{start}} Uhr',
      modal: {
        header: 'Kein Handel von Aktien & ETFs in der Zeit von {{close}} - {{start}} Uhr',
        bodyMarkdown: `Aufgrund von Börsenschlusszeiten findet in der Zeit von {{close}} bis {{start}} Uhr kein Wertpapierhandel statt. 

Du kannst Aktien und ETFs bei BISON an Werktagen in der Zeit von {{start}} bis {{close}} Uhr handeln. 
          
Schaue einfach wieder innerhalb der Handelszeiten vorbei!`,
        action: 'Schließen',
      },
    },
    weekend: {
      banner: 'Kein Handel von Aktien & ETFs an Wochenenden',
      modal: {
        header: 'Kein Handel von Aktien & ETFs an Wochenenden',
        bodyMarkdown: `Aufgrund von Börsenschlusszeiten findet an Wochenenden kein Wertpapierhandel statt. 

Du kannst Aktien und ETFs bei BISON an Werktagen in der Zeit von {{start}} bis {{close}} Uhr handeln. 
        
Schaue einfach wieder innerhalb der Handelszeiten vorbei!`,
        action: 'Schließen',
      },
    },
    holiday: {
      banner: 'Kein Handel von Aktien & ETFs am {{date}}',
      modal: {
        header: 'Kein Handel von Aktien & ETFs am {{date}}',
        bodyMarkdown: `Aufgrund eines Feiertags findet am {{date}} kein Wertpapierhandel statt. 

Du kannst Aktien und ETFs bei BISON an Werktagen in der Zeit von {{start}} bis {{close}} Uhr handeln. 
        
Schaue einfach wieder innerhalb der Handelszeiten vorbei!`,
        action: 'Schließen',
      },
    },
    transactionHistory: {
      stockLabel: 'Aktie',
    },
  },
  riskSettings: {
    step1: {
      title: `Wie oft hast du strukturierte Exchange Traded Funds (ETFs) im Wert von mehr als 500 Euro in den letzten 3 Jahren gehandelt?`,
      subtitle: 'Frage 1',
      details: 'z.B. Short-ETFs (inverse ETFs), Faktor-ETFs oder gehebelte ETFs',
      option1: '0 bis 5 mal',
      option2: 'Mehr als 5 mal',
    },
    step2: {
      title: `Wie oft hast du Zertifikate im Wert von mehr als 500 Euro in den letzten 3 Jahren gehandelt?`,
      subtitle: 'Frage 2',
      details: 'z.B. Aktienanleihen oder ETCs',
      option1: '0 bis 5 mal',
      option2: 'Mehr als 5 mal',
    },
    step3: {
      title: `Wie oft hast du Optionsscheine im Wert von mehr als 500 Euro in den letzten 3 Jahren gehandelt?`,
      subtitle: 'Frage 3',
      details: 'z.B. Standard-Optionsscheine, Turbo-Optionsscheine mit Knock-Out oder Faktor-Optionsscheine',
      option1: '0 bis 5 mal',
      option2: 'Mehr als 5 mal',
    },
    step4: {
      title: 'Worin unterscheiden sich gehebelte ETFs von klassischen ETFs?',
      subtitle: 'Frage 4',
      option1: 'Gehebelte und klassische ETFs unterscheiden sich lediglich in der Produktbezeichnung.',
      option2:
        'Ein klassischer ETF bildet die Wertentwicklung des zugrunde liegenden Index nahezu 1:1 ab. Gehebelte ETFs versuchen hingegen, die Wertentwicklung des Index um einen festgelegten Hebel zu vervielfachen.',
      option3: 'Ich bin mir nicht sicher.',
    },
    step5: {
      title: 'Was versteht man unter einem Short-ETF?',
      subtitle: 'Frage 5',
      option1:
        'Short‑ETFs setzen auf eine fallende Wertentwicklung des Basiswerts. Fällt der Index um z.B. 5 %, steigt der Short‑ETF um 5 %.',
      option2:
        'Short-ETFs sind ETFs investieren in Unternehmen die in naher Zukunft wahrscheinlich Insolvenz anmelden werden.',
      option3: 'Ich bin mir nicht sicher.',
    },
    step6: {
      title: 'Wie hoch kann das Verlustrisiko von Zertifikaten sein?',
      subtitle: 'Frage 6',
      option1: 'Das Verlustrisiko ist auf 50% des investierten Kapitals oder maximal 20% pro Jahr begrenzt.',
      option2:
        'Es kann aufgrund des Emittentenrisikos zu einem Totalverlust kommen, sodass Anleger 100% ihres eingesetzten Kapitals verlieren können.',
      option3: 'Ich bin mir nicht sicher.',
    },
    step7: {
      title: 'Welche Anlagestrategie verfolgt ein Zertifikat?',
      subtitle: 'Frage 7',
      option1:
        'Ein Zertifikat ist ein Finanzinstrument, das sich auf den Kauf von Immobilien spezialisiert hat.',
      option2:
        'Anleger erhalten, je nach Produktausgestaltung, eine vereinbarte Rückzahlung, wenn der zugrunde liegende Basiswert (z.B. Index, Rohstoff, Aktie oder eine Währung) eine bestimmte Wertentwicklung erfüllt.',
      option3: 'Ich bin mir nicht sicher.',
    },
    step8: {
      title: 'Wodurch entsteht die Hebelwirkung bei Hebelprodukten?',
      subtitle: 'Frage 8',
      option1:
        'Bei Hebelprodukten benötigen Anleger im Vergleich zur Direktinvestition in den unterliegenden Basiswert nur einen Bruchteil des Kapitals, partizipieren aber dennoch in gleichem Maße an der Kursentwicklung des Basiswertes.',
      option2:
        'Der Hebel wird durch den Emittenten festgelegt und wirkt ausschließlich bei steigenden Kursen des Basiswertes.',
      option3: 'Ich bin mir nicht sicher.',
    },
    step9: {
      title: 'Welche Faktoren beeinflussen den Preis eines Standard-Optionsscheins?',
      subtitle: 'Frage 9',
      option1:
        'Der Wert eines Optionsscheins wird durch eine Vielzahl von Faktoren beeinflusst, wie z.B. der Restlaufzeit oder dem aktuellen Marktpreis des Basiswerts.',
      option2:
        'Maßgeblich für die Wertentwicklung ist die Restlaufzeit, da der Optionsschein mit abnehmender Restlaufzeit automatisch an Wert gewinnt.',
      option3: 'Ich bin mir nicht sicher.',
    },
    continueButton: 'Weiter',
    submitButton: 'Antworten abschicken',
    exitModal: {
      title: 'Du möchtest die Beantwortung der Fragen zu deiner Erfahrung mit Wertpapieren abbrechen?',
      subtitle: 'Beachte: Ohne die Beantwortung, kannst du keine Aktien oder ETFs handeln. ',
      continue: 'Weitermachen',
      cancel: 'Abbrechen',
    },
    entryModal: {
      title: 'Deine Erfahrung mit Wertpapieren',
      titleProfile: 'Du möchtest deine Erfahrungsstufe mit Wertpapieren erneut ermitteln?',
      subtitleMarkdownProfile: `Beantworte einfach **9 kurze Fragen.** Du erfährst deine Erfahrungsstufe direkt im Anschluss. Beachte: Du kannst dies nur 1x täglich machen.`,
      subtitleMarkdown: `Um sicherzustellen, dass du im Rahmen deiner Erfahrungen und Kenntnisse handelst, müssen wir dir **9 kurze Fragen** zu komplexen Finanzinstrumenten stellen.`,
      details: `Bitte beantworte alle Fragen vollständig und nach deinem Wissenstand. Als Ergebnis kann es sein, dass du einzelne Produkte nicht handeln darfst oder einen Warnhinweis erhältst.
        Für nicht komplexe Produkte wie Aktien und ETFs führen wir keine Angemessenheitsprüfung durch. Wir beurteilen auch nicht deine finanziellen Verhältnisse oder Anlageziele, da wir keine Anlageberatung erbringen.`,
      actionText: 'Weiter zu den Fragen',
    },
    successModal: {
      title: {
        main: 'Danke, {{firstname}}! Deine Erfahrungsstufe: ',
        basic: 'Basis',
        informed: 'Erweitert',
        advanced: 'Umfangreich',
      },
      details1: 'Du kannst diese in deinem Konto unter <1>Profil</1> einsehen.',
      details2: 'Viel Spaß beim Handeln von Aktien & ETFs.',
      actionText: 'Weiter zum Kauf',
      actionTextProfile: 'Zum Profil',
    },
  },
  pdfDisplayModal: {
    pages: 'Seite {{current}} von {{max}}',
  },
  inboxImportantMessagesModal: {
    title: 'Wichtige Nachricht',
    text: 'Du hast eine neue Nachricht mit wichtigen Dokumenten von der Solaris SE. Bitte prüfe deinen Posteingang.',
    action: 'Zum Posteingang',
  },
  instantSEPA: {
    title: 'SEPA-Einzahlungen via Echtzeitüberweisung sind jetzt verfügbar!',
    // text: 'Wähle einfach <strong>Echtzeitüberweisung</strong> bei deiner Bank aus, wenn du die Überweisung zu BISON vornimmst. Beachte: <strong>pro Echtzeitüberweisung</strong> (angeboten von Solaris) wird <strong>eine Gebühr von 1,00 €</strong> fällig, die direkt von deinem BISON-Konto abgebucht wird. Es können zusätzliche Gebühren bei deiner Bank anfallen.',
    // TODO: Remove when sepa fees are back.
    text: 'Wähle einfach <strong>Echtzeitüberweisung</strong> bei deiner Bank aus, wenn du die Überweisung zu BISON vornimmst. Beachte: Es können zusätzliche Gebühren bei deiner Bank anfallen.',
    button: 'Alles klar!',
  },
  deviceMonitoringConsent: {
    modal: {
      title: 'Zur Sicherung deines BISON-Kontos ...',
      paragraphOnboarding: `... müssen wir deine Geräte-Informationen überprüfen. Dafür benötigen wir deine Zustimmung. Beachte: Ohne Zustimmung, kannst du dich nicht verifizieren.`,
      paragraphLogin: `... müssen wir deine Geräte-Informationen überprüfen. Dafür benötigen wir deine Zustimmung. Beachte: Ohne Zustimmung, kannst du dich nicht einloggen.`,
      paragraphResetPassword: `... müssen wir deine Geräte-Informationen überprüfen. Dafür benötigen wir deine Zustimmung. Beachte: Ohne Zustimmung, kannst du dein Passwort nicht zurücksetzen.`,
      paragraphFallBack: `...müssen wir deine Geräte-Informationen überprüfen. Dafür benötigen wir deine Zustimmung. Beachte: Ohne Zustimmung, kannst du dich nicht fortfahren.`, // for edge cases where deviceConsentId gets lost (should not happen)
      paragraphOptOut: `... müssen wir deine Geräte-Informationen überprüfen. Dafür benötigen wir deine Zustimmung.`,
      text: `Mit deiner Zustimmung erlaubst du uns, bestimmte Informationen, wie Verkehrs- und Standortdaten, sowie andere gerätebezogene Informationen wie deine Session-ID, von deinem Gerät über deinen Browser abzurufen. Wir werden diese Informationen zusammen mit deiner E-Mail-Adresse, deinem Namen und deiner Telefonnummer an Solaris weitergeben, welche diese Daten gegebenenfalls mit weiteren Informationen ergänzen und an einen Dienstleister zur Überprüfung auf Betrug und Geldwäsche weiterleiten wird. \n\nDu kannst deine Zustimmung jederzeit in deinen Einstellungen > Verknüpfte Geräte widerrufen. Weitere Informationen findest du in unserer Datenschutzerklärung.`,
      action: 'Ich stimme zu',
    },
    exitModal: {
      title: 'Bist du sicher, dass du dieses Fenster schließen möchtest?',
      paragraphOnboarding: `Beachte: Ohne die erforderliche Zustimmung, kannst du die Verifizierung nicht abschließen.`,
      paragraphLogin: `Beachte: Ohne die erforderliche Zustimmung, kannst du dich nicht einloggen.`,
      paragraphResetPassword: `Beachte: Ohne die erforderliche Zustimmung, kannst du dein Passwort nicht zurücksetzen.`,
      paragraphFallBack: `Beachte: Ohne die erforderliche Zustimmung kannst du nicht fortfahren.`, // for edge cases where deviceConsentId gets lost (should not happen)
      paragraphOptOut: `Beachte: Ohne Zustimmung kann es zur Sperrung oder Kündigung deines Kontos kommen. Die Überprüfung deiner Geräte-Informationen schützt dich z.B. vor Kontoübernahmen durch Dritte.`,
      goBack: 'Zurück zur Zustimmung',
      close: 'Fenster schließen',
    },
  },
  stockRaffle: {
    banner: {
      title: 'Gewinne 1 von 3 E-Rollern',
      stocksLocked: {
        description: 'Jetzt für Wertpapiere freischalten, um am Gewinnspiel teilzunehmen.',
        button: 'Freischalten',
      },
      joined: {
        description: 'Noch {{transactionCount}} Wertpapiergeschäfte, dann nimmst du am Gewinnspiel teil.',
        button: 'Jetzt handeln',
      },
      notJoined: {
        description: 'Mach bei unserem Wertpapier-Gewinnspiel mit.',
        button: 'Jetzt mitmachen',
      },
    },
    entryModal: {
      title: 'Gewinne 1 von 3 E-Rollern',
      subtitle: 'oder 30 € in Bitcoin (BTC)',
      terms: {
        title: 'So nimmst du an dem Gewinnspiel teil:',
        item1:
          'Klicke auf den Button unten, um den Teilnahmebedingungen zuzustimmen und an dem Gewinnspiel teilzunehmen.',
        item2: 'Schalte dich für Aktien & ETFs frei.',
        item3: 'Führe 3 Wertpapiergeschäfte zwischen dem {{startDate}} und {{endDate}} um 23:59 aus.',
      },
      actions: {
        confirm: 'Ich möchte teilnehmen',
        dismiss: 'Ich habe kein Interesse',
      },
      note: 'Mit dem Klick auf “Ich möchte teilnehmen”, stimmst du den <1>Teilnahmebedingungen</1> zu. Um dich erfolgreich zu qualifizieren, musst du mindestens 3 Wertpapiergeschäfte ausgeführt haben. Das Gewinnspiel läuft vom {{startDate}} 08:00 bis zum {{endDate}} um 23:59. Die Gewinner werden per E-Mail benachrichtigt.',
    },
    exitModal: {
      title: 'Bist du sicher, dass du nicht an dem Gewinnspiel teilnehmen möchtest?',
      note: 'Beachte: Wenn du auf “Ich möchte nicht teilnehmen” klickst, wird dir dieses Gewinnspiel nicht mehr angezeigt.',
      actions: {
        confirm: 'Ich möchte nicht teilnehmen',
        dismiss: 'Zurück zum Gewinnspiel',
      },
    },
    confirmModal: {
      title: {
        bolded: 'Fast geschafft!',
        regular: ' Qualifiziere dich jetzt für das Gewinnspiel:',
      },
      terms: {
        title: 'Folgende Schritte sind notwendig:',
        item1: 'Schalte dich für Aktien & ETFs frei',
        item2: 'Führe 3 Wertpapiergeschäfte zwischen dem {{startDate}} und {{endDate}} um 23:59 aus.',
      },
      confirm: 'Jetzt freischalten',
    },
    successModal: {
      title: 'Geschafft! Du nimmst an dem Gewinnspiel teil!',
      note: 'Die Gewinner der <1>3 x E-Roller</1> und der <1>100 x 30 € in Bitcoin (BTC)</1> werden ab dem {{announcementDate}} per E-Mail von uns benachrichtigt.',
      confirm: 'Alles klar',
    },
  },
  kccRaffle: {
    banner: {
      notJoined: {
        title: 'Gewinne 2 Tickets für die<br/> Kansas City Chiefs',
        description: 'Sei live beim <strong>American Football Spiel</strong> in Frankfurt dabei.',
        button: 'Jetzt mitmachen',
      },
      joined: {
        title: 'Verpass nicht deine Chance!',
        description:
          '<strong>Handle Krypto und Wertpapiere bis Ende dieser Woche<br/> und gewinne 2 Tickets für das Kansas City Chiefs Spiel.</strong>',
      },
    },
    entryModal: {
      title: 'Gewinne 2 Tickets für das Kansas City Chiefs Spiel!',
      subtitle:
        'Bis zum 29. Oktober verlost BISON <strong>jede Woche 3x2 Tickets für das Spiel am 05. November in Frankfurt.</strong>',
      terms: {
        title: 'So nimmst du am Gewinnspiel teil:',
        item1: 'Klicke auf „ich möchte teilnehmen“ um die <1>Teilnahmebedingungen</1> zu akzeptieren.',
        item2:
          'Handle Kryptowährung für min. 100€ und führe 1 Wertpapiergeschäft innerhalb der Aktionswoche (Mo-So) durch.',
        link: 'https://bisonapp.com/bison-kc/app',
      },
      actions: {
        confirm: 'Ich möchte teilnehmen',
        maybeLater: 'Vielleicht nächste Woche',
        dismiss: 'Das Gewinnspiel nicht mehr anzeigen',
      },
      note: '',
    },
    exitModal: {
      title: 'Bist du sicher, dass du nicht an dem Gewinnspiel teilnehmen möchtest ?',
      note: `Beachte: Wenn du jetzt auf „Gewinnspiel nicht mehr anzeigen“ klickst, kannst du dich nicht mehr für das Kansas City Chiefs Gewinnspiel qualifizieren. Andere Gewinnspiele wirst du wie gewohnt sehen.`,
      actions: {
        confirm: 'Gewinnspiel nicht mehr anzeigen',
        dismiss: 'Zurück zum Gewinnspiel',
      },
    },
    confirmModal: {
      title: {
        bolded: 'Fast geschafft!',
        regular: ' Qualifiziere dich jetzt für das Gewinnspiel:',
      },
      terms: {
        title: 'Folgende Schritte sind notwendig:',
        item1:
          'Verifiziere dich für den Krypto- <strong>und</strong> Wertpapierhandel (sofern noch nicht geschehen).',
        item2:
          'Handle Kryptowährung für min. 100€ und führe 1 Wertpapiergeschäft innerhalb dieser Woche durch.',
      },
      note: 'Die Gewinner werden wöchentlich per E-Mail benachrichtigt.',
      confirm: 'Los geht’s',
    },
  },
  auditProof: {
    onLoginModal: {
      title: 'Sind deine Daten noch aktuell?',
      text: 'Um sicherzustellen, dass deine Daten noch aktuell sind, bestätige oder aktualisiere sie bitte innerhalb der Frist.',
      daysLeft: 'Noch {{daysLeft}} Tage möglich',
      button: 'Jetzt überprüfen',
      hardFlowExtraButton: 'Später erledigen',
    },
    postponeReviewModal: {
      title: 'Die Überprüfung verschieben',
      text: `Bitte beachte, dass du innerhalb der  {{daysLeft}} Tage deine Daten überprüfen und gegebenenfalls ändern musst.
      <br/>
      <br/>
        Solltest du dies nicht tun, sind wir rechtlich dazu verpflichtet, dein Konto zu sperren, bis eine Prüfung erfolgt ist.`,
      button: 'Jetzt überprüfen',
    },
    accountBlocked: {
      title: 'Dein Konto wurde gesperrt.',
      text: 'Leider hast du deine Daten nicht innerhalb der Frist von 60 Tagen bestätigt. Bitte überprüfe deine Daten, um wieder Zugriff auf dein Konto zu haben.',
      button: 'Jetzt überprüfen',
    },
    accountInManualProgress: {
      title: 'Wir prüfen gerade deine Daten.',
      text: 'Wir bitten dich noch um etwas Geduld. Es kann bis zu wenigen Tagen dauern dein Konto zu entsperren.',
    },
    summary: {
      title: 'Bestätigung deiner Daten',
      text: 'Bitte überprüfe deine Daten und aktualisiere sie gegebenenfalls. Bestätige, dass deine Angaben aktuell sind, indem du sie abschickst.',
      fields: {
        address: 'Adresse',
        email: 'E-Mail',
        phone: 'Handynummer',
        employmentDetails: 'Arbeitsverhältnis',
      },
      hardFlowCheckbox:
        'Hiermit bestätige ich, dass ich die Daten geprüft und - sofern erforderlich - aktualisiert bzw. die Aktualisierung eingeleitet habe.',
      button: 'Jetzt abschicken',
    },
    edit: {
      email: {
        title: 'E-Mail Adresse bearbeiten',
        text: 'Bitte überprüfe und bestätige deine E-Mail Adresse. Sollte sich etwas geändert haben, kannst du es hier direkt anpassen. ',
        label: 'E-Mail',
        placeholder: 'Bitte gib deine E-Mail Adresse an.',
        button: 'Bestätigen',
      },
      employmentStatus: {
        title: 'Angabe deines Arbeitsverhältnisses',
        label: 'Anstellungsart',
        emptyType: 'Bitte auswählen',
        button: 'Bestätigen',
        typeOfEmployment: {
          Unspecified: 'Bitte auswählen',
          Apprentice: 'Lehrling',
          Employee: 'Angestellter',
          Freelancer: 'Freiberufler',
          Housework: 'Hausarbeit',
          LeadingEmployee: 'Leitender Angestellter',
          Management: 'Geschäftsführer',
          MilitaryOrCommunityService: 'Militär-/ Zivildienst',
          NotEmployed: 'Ohne Beschäftigung',
          ProfessionalSoldier: 'Berufssoldat',
          PublicSectorEmployee: 'Angestellter im öffentlichen Dienst',
          Retired: 'Rentner',
          SelfEmployed: 'Selbstständig',
          Student: 'Student/Auszubildender',
        },
        professionalField: {
          error: 'Bitte wähle eine Branche aus.',
          title: 'Branche',
          empty: 'Branche auswählen',
          AgricultureOrForestry: 'Land- und Forstwirtschaft, Fischerei',
          Mining: 'Bergbau und Gewinnung von Steinen und Erden',
          Manufacturing: 'Verarbeitendes Gewerbe',
          EnergyOrPowerSupply: 'Energieversorgung',
          WaterOrWasteRemoval:
            'Wasserversorgung; Abasser- und Ab-fallentsorgung und Beseitigung von Umweltverschutzungen',
          Construction: 'Baugewerbe',
          RetailOrMaintenance: 'Handel; Instandhaltung und Reparatur von Kraftfahrzeugen',
          TransportAndStorage: 'Verkehr und Lagerei',
          Hospitality: 'Gastgewerbe',
          InformationOrCommunication: 'Information und Kommunikation',
          FinanceOrInsurance: 'Erbringung von Finanz- und Versicherungsdienstleistungen',
          RealEstate: 'Grundstücks- und Wohnungswesen',
          ScientificServices:
            'Erbringung von freiberuflichen, wissenschaftlichen und technischen Dienstleistungen',
          EconomicServices: 'Erbringung von sonstigen wirtschaftlichen Dienstleistungen',
          PublicService: 'Öffentliche Verwaltung, Verteidigung; Sozialversicherung',
          EducationOrTeaching: 'Erziehung und Unterricht',
          HealthcareOrSocialServices: 'Gesundheits- und Sozialwesen',
          ArtOrEntertainment: 'Kunst, Unterhaltung und Erholung',
          OtherServices: 'Erbringung von sonstigen Dienstleistungen',
          PrivateHouseholds:
            'Private Haushalte mit Hauspersonal; Herstellung von Waren und Erbringung von Dienstleistungen durch private Haushalte für den Eigenbedarf ohne ausgeprägten Schwerpunkt',
          ActivitiesOfExtraterritorial: 'Exterritoriale Organisationen und Körperschaften',
        },
      },
      addressChange: {
        title: 'Adresse bearbeiten',
        text: 'Bitte überprüfe und bestätige deine Adresse. Sollte sich etwas geändert haben, kannst du es hier direkt anpassen.',
        button: 'Bestätigen',
      },
    },
    twoFa: {
      title: 'Änderung deiner Daten bestätigen',
      text: 'Bitte bestätige deine Änderungen, indem du den Sicherheitscode eingibst, den wir dir per SMS an <1>{{phone}}</1> gesendet haben.',
    },
    success: {
      title: 'Deine Daten wurden erfolgreich akualisiert.',
      additionalText:
        'Bitte beachte, dass es bis zu wenigen Tagen dauern kann bis du wieder Zugriff auf dein Konto hast. Wir benachrichtigen dich per E-Mail.',
    },
    solarisError: 'Unser Service ist derzeit nicht verfügbar. Bitte versuche es später erneut.',
  },
  staking: {
    demoOnlyModal: {
      title: 'Diese Funktion ist nur im Echtgeld-modus verfügbar',
      text: 'Jetzt verifizieren und BISONs volle Power ausschöpfen.',
      support: `Noch offene Fragen? Wir helfen dir gerne, kontaktiere uns jederzeit unter <1>{{supportEmail}}</1>.`,
      buttons: {
        stayInDemo: 'Erstmal im Demo-Modus bleiben',
        verify: 'Jetzt verifizieren',
      },
    },
    stakingFeatureIntroduction: {
      0: {
        title: '<1>Neu:</1><br/> Entdecke Staking mit BISON',
        body: 'Unsere neue Funktion bekommt einen eigenen Menüpunkt. Damit du ohne Umwege mit dem <strong>Staking</strong> loslegen kannst, findest du diese Funktion direkt in der Navigationsleiste.',
      },
      1: {
        title: '<1>Behalte alle Orders im Überblick</1>',
        body: 'Deine <strong>Limit & Stop Orders, Sparpläne</strong> und weitere Informationen zu deinen <strong>Aktivitäten</strong> findest du jetzt unter <strong>Orders</strong> (früher “Aktivität”) unten in der Navigationsleiste. ',
      },
      2: {
        title: '<1>Organisiere deine Preisalarm-Einstellungen</1>',
        body: 'Die Übersicht über deine Preisalarme findest du jetzt <strong>im jeweiligen Asset-Profil</strong>. Dort kannst du wie gewohnt neue Preisalarme anlegen und bestehenden Preisalarme aktivieren oder deaktivieren.',
      },
      actions: {
        skip: 'Überspringen',
        start: 'Loslegen',
      },
    },
    stakingBlockedModal: {
      title: 'Staking not available in your country',
      text: 'This feature is available only for German tax residents.',
    },
    assetOverview: {
      stakingSectionTitle: '{{shortName}} Staking',
      stake: 'Staken',
      apr: ' Ø APR',
      missingFundsModal: {
        title: '{{shortName}} staken',
        text: 'Du hast nicht genügend {{shortName}} in deinem Portfolio. Kaufe oder zahle {{shortName}} auf dein BISON-Konto ein.',
        buttons: {
          buy: 'Kaufen',
          deposit: 'Einzahlen',
        },
      },
    },
    joinWaitingList: {
      title: 'Du möchtest {{displayName}} staken?',
      text: `Wir arbeiten bereits daran, dass du auch <strong>{{currency}} staken</strong> kannst.
       
       Melde dich für die Warteliste an und erfahre als Erster, wann es soweit ist.`,
      buttons: {
        join: 'Jetzt anmelden',
        later: 'Später',
      },
    },
    title: 'Krypto staken',
    infoPopup: {
      title: `{{assetCode}} Staken: <1>{{apr}} APR</1> erhalten`,
      body: {
        0: {
          title: 'Ab dem 1. Folgetag im Staking*',
          text: 'Bis dahin sind deine {{assetCode}} in Bearbeitung und können nicht entstaket werden.',
        },
        1: {
          title: 'Gestakte {{assetCode}} können nicht gehandelt werden',
          text: 'Solange deine {{assetCode}} gestaket sind, kannst du sie nicht handeln oder auszahlen lassen.',
        },
        2: {
          title: 'Wiederfreigabe-Zeitraum: {{periodStart}} bis {{periodEnd}} Tage',
          text: 'Während des Wiederfreigabe-Prozesses sind der Zugriff auf, der Handel mit und die Auszahlung von {{assetCode}} nicht möglich.',
        },
        3: {
          title: 'Verdiene Staking-Rewards',
          text: 'Beteilige dich am Staking und hol dir wöchentlich Rewards. <1>Mehr erfahren</1> Da die Staking-Rewards netzwerkabhängig sind, kann nur eine ungefähre Spanne angegeben werden.',
        },
      },
      confirm: 'Verstanden',
      legal:
        '*Bei unmittelbar gekauften {{assetCode}} kann es aus technischen Gründen bis zu 2 Tage dauern, bis das Staking startet.',
    },
    amount: 'Anzahl',
    amountAvailable: 'Verfügbar: {{amount}}',
    apr: 'Effektiver Jahreszins (APR)',
    type: 'Ausschüttung',
    typeValue: 'Wöchentlich',
    unstakePeriod: 'Wiederfreigabe-Zeitraum',
    periodValue: '{{start}} bis {{end}} Tage',
    rewardDate: 'Erster Reward',
    continue: 'Weiter zur Zusammenfassung',
    periodPopup: {
      title: 'Wiederfreigabe-Zeitraum',
      text: `Nachdem du dich entschieden hast, die eingesetzten {{assetCode}} aus dem Staking zurückzuziehen, beginnt der Wiederfreigabe-Zeitraum<br/><br/> 
In diesem Zeitraum sind deine {{assetCode}} nicht für Transaktionen oder Handel verfügbar, um das Netzwerk vor potenziellem Missbrauch zu schützen und seine Stabilität sowie Sicherheit zu gewährleisten.<br/><br/> 
Nach Abschluss des Zeitraums werden deine {{assetCode}} wieder freigegeben, wobei die Dauer der Wiederfreigabe je nach Blockchain-Netzwerk variiert.`,
    },
    errors: {
      emptyAmount: 'Bitte gib eine Anzahl ein.',
      tooSmall: 'Die Anzahl ist zu gering, bitte gib eine Anzahl von mind. {{minAmount}} {{code}} an.',
      tooLarge: 'Die Anzahl ist zu hoch, bitte gib eine Anzahl von max. {{maxAmount}} {{code}} an.',
    },
    confirmPopup: {
      stakeType: 'Staken',
      note: 'Deine {{assetCode}} sind ab dem 1. Folgetag im Staking. Bei unmittelbar gekauften ETH kann es aus technischen Gründen bis zu 2 Tage dauern. Sobald du ETH gestaket hast, kannst du sie erst am nächsten Tag wieder entstaken. Weitere Informationen zu den <1>Sonderbedingungen</1> findest du hier.',
      confirm: 'Jetzt staken',
    },
    finishPopup: {
      proceed: 'Zum Staking',
      titleError: 'Staking ist fehlgeschlagen',
      titleSuccess: '{{amount}} {{assetCode}} gestaket',
      textSuccess:
        'Die {{amount}} {{assetCode}} sind ab dem 1. Folgetag im Staking. Bei unmittelbar gekauften {{assetCode}} kann es aus technischen Gründen bis zu 2 Tage dauern, bis das Staking startet.\n\nIn der Zwischenzeit findest du deine {{assetCode}} im Bereich “In Bearbeitung” auf der Staking-Seite.',
      textError:
        'Das Staken deiner {{amount}} {{assetCode}} hat leider nicht geklappt. Bitte versuche es später noch einmal.',
    },
    stakingOverview: {
      buyButton: 'Kaufen',
      stakeButton: 'Staken',
      chart: {
        chartMeta: {
          totalRewards: 'Gesamte Rewards',
          apr: ' Ø APR',
        },
      },
      availableForStaking: {
        title: 'Verfügbar für Staking',
        emptyText:
          'Keine Assets für Staking verfügbar. Kaufe stakebare Assets, dann werden diese hier angezeigt.',
        info: `## Verfügbar für Staking\n\nHier werden alle stakebaren Kryptowährungen angezeigt, die du bei BISON besitzt.`,
      },
      buyableForStaking: {
        title: 'Kaufbar für Staking',
        emptyText:
          'Derzeit bieten wir keine weiteren Assets für Staking an. Wir arbeiten an der Erweiterung der für Staking verfügbaren Assets.',
        info: `## Kaufbar für Staking\n\nHier werden alle stakebaren Kryptowährungen angezeigt, die du bei BISON kaufen kannst, aber noch nicht besitzt.`,
      },
      availableInFuture: {
        title: 'Bald verfügbar',
      },
      inRelease: {
        title: 'In Bearbeitung',
        emptyText:
          'Aktuell befinden sich keine deiner Assets in Bearbeitung. Entstakte Assets werden hier angezeigt, solange sie sich im Wiederfreigabe-Prozess befinden. Gestakte Assets werden hier angezeigt, während sie bearbeitet werden.',
        info: `## In Bearbeitung \n\n **Entstaken**\n\n Nachdem du dich entschieden hast, die von dir eingesetzten Kryptowährungen aus dem Staking zurückzuziehen, beginnt der Zeitraum der Wiederfreigabe. \n\nWährend dieser Zeit können die Kryptowährungen nicht für Transaktionen verwendet oder gehandelt werden. Dies dient dazu, das Netzwerk vor möglichem Missbrauch zu schützen und die Stabilität und Sicherheit zu gewährleisten.  \n\nNach Ablauf des Zeitraums werden deine Kryptowährungen wieder freigegeben. Die Dauer des Wiederfreigabe-Prozesses variiert je nach Blockchain-Netzwerk. \n\n**Staken**\n\nDas Staking deiner eingesetzten ETH beginnt mit dem 1. Folgetag ab 0 Uhr.`,
        detailsModal: {
          type: 'In unstaking',
          inProgress: 'In Bearbeitung',
          periodLabel: 'Remaining duration in unstaking process:',
          id: 'ID',
          date: 'Date & time',
          legalStakeText:
            'Your ETH will be staked from the 1st following day. If you have any questions, please contact us at any time at ',
        },
      },
      inStaking: {
        title: 'Im Staking',
        emptyText: 'Zurzeit wird nichts gestakt. Sobald du Assets stakst, werden diese hier angezeigt.',
      },
      rewardsHistory: {
        type: 'Staking Reward',
        title: 'Reward Historie',
        showMore: 'Mehr erfahren',
        emptyText:
          'Du hast noch keine Staking-Rewards generiert. Diese werden hier angezeigt, sobald die ersten Rewards generiert wurden.',
      },
      info: {
        title: 'Was ist Staking?',
        mainText: `Beim Staking hältst du Kryptowährungen deiner Wahl in einer digitalen Geldbörse (Wallet), während diese zur Sicherung und Abwicklung von Transaktionen in einem Blockchain-Netzwerk verwendet werden. Als Belohnung für deine Unterstützung erhältst du regelmäßig zusätzliche Kryptowährungen. Durch aktives Engagement im Kryptoökosystem kannst du passives Einkommen generieren, statt nur Kryptowährungen zu besitzen.`,
        apr: 'APR',
        aprExplanation:
          'Der effektive Jahreszins (abgekürzt APR). Die jährliche Rendite, ausgedrückt in Prozent.',
        everyWeek: 'WÖCHENTLICH',
        everyWeekExplanation: 'Die Ausschüttung der Staking Rewards erfolgt wöchentlich.',
        showMore: 'Mehr erfahren',
      },
      detail: {
        stake: 'Staken',
        unstake: 'Entstaken',
        apr: 'Ø APR',
        type: 'Ausschüttung',
        typeValue: 'Wöchentlich',
        period: 'Wiederfreigabe-Zeitraum',
        date: 'Nächste Ausschüttung',
        estimatedAmount: 'Geschätzte Anzahl',
      },
    },
  },
  unstaking: {
    title: 'Krypto entstaken',
    period: 'Wiederfreigabe-Zeitraum',
    periodValue: 'ca. {{start}} bis {{end}} Tage',
    unstake: 'Entstaken',
    inProgress: 'In bearbeitung',
    errors: {
      notEnoughLeftInStake:
        'Bitte gib entweder die max. Anzahl von {{maxAmount}} {{code}} an oder so viel, dass mind. {{minAmount}} {{code}} übrig bleiben.',
    },
    infoModal: {
      title: 'entstaken In <1>Bearbeitung</1>',
      periodLabel: 'Verbleibende Dauer in Wiederfreigabe Prozess:',
      periodValue: '{{days}} Tage',
      periodValueLessThanOneDay: 'Weniger als 1 Tag',
      id: 'ID',
      date: 'Datum & Uhrzeit',
      legalStakeText:
        'Ab dem 1. Folgetag sind deine {{assetCode}} im Staking. Solltest du Fragen haben, kontaktiere uns gerne jederzeit unter ',
    },
    confirmModal: {
      title: 'ENTSTAKEN',
      confirm: 'Jetzt entstaken',
      note: 'Hier findest du weitere Informationen zu den <1>Sonderbedingungen.</1>',
    },
    finishModal: {
      proceed: 'Zum Staking',
      titleSuccess: '{{amount}} {{assetCode}} entstakt',
      textSuccess: `Es kann **{{start}} bis {{end}} Tage** dauern bis du die {{amount}} {{assetCode}} in deinem verfügbaren Portfolio hast.\n\nWährend dem Wiederfreigabe-Zeitraum findest du deine {{assetCode}} im Abschnitt "Im Wiederfreigabe-Prozess" unter Staking.
      `,
      titleError: 'Entstaken ist fehlgeschlagen',
      textError:
        'Das Entstaken deiner {{amount}} {{assetCode}} hat leider nicht funktioniert. Bitte versuche es später noch einmal.',
    },
  },
  experiments: {
    winnersLosers: {
      winner: {
        title: 'Die Top 3 Gewinner von gestern:',
      },
      loser: {
        title: 'Die Top 3 Verlierer von gestern:',
      },
      crypto: {
        subtitle: 'Leg los mit Aktien!',
        text: 'Verpasse keine Chance mehr und profitiere von den Kursentwicklungen am Aktienmarkt. Wähle aus über 1.600 Aktien und optimiere jetzt dein Anlageprofil.',
        button: 'Jetzt freischalten',
      },
      securities: {
        subtitle: 'Nutze deine nächste Chance!',
        text: 'Diversifiziere dein Portfolio mit einer Auswahl an +1.600 Aktien und partizipiere so an der Kursentwicklung. Schalte den Wertpapierhandel auf BISON frei und optimiere deine Anlagestrategie.',
        button: 'Aktien auswählen',
      },
    },
    cryptoShort: {
      title: {
        eth: 'Jetzt Ethereum als Wertpapier handeln',
        btc: 'Jetzt Bitcoin als Wertpapier handeln',
      },
      description: {
        eth: 'Mit dem {{displayName}} kannst du von in einen fallenden Ethereum-Kurs investieren. ',
        btc: 'Mit dem {{displayName}} kannst du in einen fallenden Bitcoin-Kurs investieren. ',
      },
      infoMarkdown: {
        eth: `# Was ist der {{displayName}}?
        
Der {{displayName}} bildet die entgegengesetzte Wertentwicklung von Ethereum nach. 

Das Produkt bildet den entsprechenden Basiswert 1:1 ab. Bei hoher Volatilität auf dem Kryptowährungsmarkt kannst du so in einen fallenden Ethereum-Kurs investieren. 
        `,
        btc: `# Was ist der {{displayName}}? 
        
Der {{displayName}} bildet die entgegengesetzte Wertentwicklung von Bitcoin nach.

Das Produkt bildet den entsprechenden Basiswert 1:1 ab. Bei hoher Volatilität auf dem Kryptowährungsmarkt kannst du so in einen fallenden Bitcoin-Kurs investieren.
        `,
        button: 'ETP handeln',
      },
      timeSpanText: 'Letzte 24h',
      button: 'ETP handeln',
    },
  },
  stakingRollout: {
    banner: {
      title: 'Staking derzeit nur in der mobilen App von BISON verfügbar',
      text: `Die Einführung von Staking in der Web-Anwendung folgt. Wenn du bereits in der mobilen BISON App gestaket hast, kannst du deine gestaketen Kryptowährungen in der Web-Anwendung noch nicht sehen.<br/>  Deshalb sieht es dann so aus, als ob sich dein Wallet um die Kryptowährungen verringert hat, die du gestaket hast. Dies ist jedoch nicht der Fall. Um die korrekte Anzeige deines Portfolios zu erhalten, zu staken, zu entstaken und weitere Details zum Staking zu erhalten, nutze bitte die mobile BISON App.`,
    },
    portfolioInfoPopupStakingData: {
      blockedByStaking: 'Geblockt durch Staking',
      checkInApp: 'wird in der BISON App angezeigt**',
    },
    assetOverviewStakingBlock: {
      title: 'Ethereum Staking',
      text: 'Alles rund um Staking findest du momentan nur in der mobilen BISON App. Wenn du bereits ETH gestaket hast, benutze bitte die mobile App um zu staken, zu entstaken und weitere Details einzusehen.',
    },
  },
  blockpitBanner: {
    title: 'Einfach & schnell zum Steuer-Report mit <image />',
    info: 'Erfasse deine Transaktionen schnell und erhalte alle Assets auf einen Blick!',
    buttonText: 'Mehr erfahren',
  },
  blockpitModal: {
    title: 'Steuer-Reporting war noch nie so einfach',
    url: 'https://www.blockpit.io/de-de/lp/bison',
    table: {
      a: {
        mainText: 'Einfache Erfassung deiner Transaktionen',
        secondaryText: 'Importiere deine Transaktionshistorie als CSV',
      },
      b: {
        mainText: 'Spare Zeit und Geld',
        secondaryText: 'Als BISON Kunde erhältst du einen exklusiven Rabatt',
      },
      c: {
        mainText: 'Erhalte alle Assets auf einen Blick',
        secondaryText: 'Behalte die Übersicht über dein Portfolio',
      },
    },
    buttonTitle: 'Steuer-Report jetzt erstellen',
  },
  blockpitInfoSection: {
    title: 'Steuer-Report mit <image />',
    text: 'Schluss mit Steuer-Stress! Erfasse deine Transaktionen schnell und erhalte alle Assets auf einen Blick. Dank BISON und Blockpit behältst du deine Kryptos einfach und mühelos im Blick und sparst Zeit bei deiner Steuererklärung!',
    button: 'Mehr erfahren',
  },
  orders: {
    tradeManager: {
      title: 'Offene Orders',
      filters: {
        tradeManager: 'Offene Orders',
        activity: 'Aktivität',
        all: 'Alle',
        saving: 'Sparplan',
        limitStop: 'Limit & Stop',
      },
      saving: {
        title: 'Sparplan',
        emptyText: 'Wähle einen Sparbetrag und ein Sparintervall für die Kryptowährung deiner Wahl.',
        action: 'Sparplan anlegen',
      },
      limitStop: {
        title: 'Handelsregeln',
        emptyText:
          'Keine Zeit den Kryptomarkt zu beobachten? Erstelle Handelsregeln und kaufe oder verkaufe automatisiert.',
        actions: {
          0: 'Limit Order',
          1: 'Stop Order',
        },
        modal: {
          title: 'Handelsregeln',
          action: 'Schließen',
          text: `
  Wir bieten aktuell zwei Typen von Handelsregeln an:
  <2/>
  <2/>
  <1>Limit Order</1>
  <2/>
  Buy Limit Regel: Einsteigen, um günstigere Kurse mitzunehmen. Du legst den niedrigeren Preis fest, zu dem du kaufen möchtest. Ein Kauf wird ausgeführt, sobald dieser Preis erreicht ist. 
  <2/>
  <2/>
  Sell Limit Regel: Aussteigen, um den Gewinn mitzunehmen. Du legst den höheren Preis fest, zu dem du verkaufen möchtest. Sobald dieser Preis erreicht ist, wird ein Verkauf ausgeführt.
  <2/>
  <2/>
  <1>Stop Order</1>
  <2/>
  Stop Buy Regel: Einsteigen, um an Marktaufwärtstrends teilzuhaben. Du kaufst, wenn eine Kryptowährung einen von dir festgelegten Preis erreicht oder überschreitet. 
  <2/>
  <2/>
  Stop Loss Regel: Aussteigen, um Verluste zu begrenzen. Du verkaufst, wenn der von dir festgelegte Preis eine niedrigere Marke durchbricht.
          `,
          bottomText: `
  Eine übermittelte, aber noch nicht ausgeführte Order blockiert den voraussichtlichen Gegenwert deiner Transaktion. 
 <1/>
  <1/>
Geblockte Positionen sind in deinem Portfolio sichtbar. Deine aktiven Handelsregeln kannst du jederzeit im Trading Manager einsehen oder löschen. 
  <1/>
  <1/>
  Handelsregeln haben eine Gültigkeit von 90 Tagen und werden nach Ablauf dieser Frist automatisch gelöscht. 
  <1/>
  <1/>
  Wir benachrichtigen dich, sobald deine Handelsregel ausgeführt wurde. 
          `,
        },
      },
    },
    transactionHistory: {
      title: 'Aktivität',
      inProgress: {
        title: 'In Bearbeitung',
        emptyText: 'Aktuell keine Transaktionen in Bearbeitung.',
      },
      completed: {
        title: 'Abgeschlossen',
        emptyText:
          'Sobald du deine erste Einzahlung getätigt hast, wird sie hier angezeigt.  Alles weitere wie Käufe, Verkäufe oder Ein- & Auszahlungen werden hier gelistet.',
      },
    },
  },
  priceAlerts: {
    title: 'Preisalarme',
    emptyList:
      'Du hast bisher keinen Preisalarm. Sobald du einen Preisalarm angelegt hast, wird dieser hier angezeigt.',
    create: 'Preisalarm anlegen',
    currentPrice: 'Aktueller Preis: {{price}}',
  },
};
