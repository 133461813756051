/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { kycEnvironments } from 'common/const';
import { State } from 'store/types/store';

export const getIsBeta = (state: State) => state.environment.isBeta;

export const getKycEnvironment = () =>
  window.env.ENVIRONMENT === 'production' ? kycEnvironments.PRODUCTION : kycEnvironments.SANDBOX;

export const getApiEnvironment = (): any => window.env.ENVIRONMENT as any;

export const getFeatureStocksEnabled = (state: State) => state.environment.isFeatureStocksEnabled;

export const getFeatureTopUpsEnabled = (state: State) =>
  state.environment.isFeatureTopUpsEnabled as boolean | 'BETA_ONLY';
