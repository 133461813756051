import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Theme } from 'types/theme';
import { dimensions, devices } from 'common/styles';

export const Menu = styled.div`
  position: fixed;
  z-index: 1001;
  width: 100%;
  left: 0;
  bottom: 0;
  height: ${dimensions.mobileMenuTablet}px;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.primary};
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  padding: 0 10px;
  @media ${devices.maxMobileL} {
    height: ${dimensions.mobileMenu}px;
  }
  @media ${devices.maxTablet} {
    padding: 0;
    bottom: auto;
    transition: opacity 0.3s;
    opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
    pointer-events: ${({ show }: { show: boolean }) => (show ? 'all' : 'none')};
  }

  top: 61px;

  @media ${devices.mobileM} {
    top: 71px;
  }
`;

export const Blackout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: none;
  transition: opacity 0.3s;
  opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
  pointer-events: ${({ show }: { show: boolean }) => (show ? 'all' : 'none')};

  @media ${devices.maxTablet} {
    display: block;
  }
`;

export const NavItem = styled(NavLink).attrs({ className: 'large-text medium-weight' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  border: none;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;

  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.tertiary};
  & > span {
    &:before {
      color: ${({ theme }: { theme: Theme }) => theme.color.foreground.tertiary};
    }
    margin-bottom: 10px;
    @media ${devices.maxMobileL} {
      margin-bottom: 6px;
    }
  }

  &.active {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
    & > span:before {
      color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
    }
  }

  @media ${devices.maxMobileL} {
    font-size: 12px !important;
    line-height: 14px !important;
    font-weight: normal !important;
  }

  padding-top: 5px;

  @media (${devices.maxTablet}) {
    padding-top: 15px;
  }
`;
