import styled from 'styled-components';
import { Theme } from 'types/theme';

export const DotsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: relative;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ active, theme }: { active: boolean; theme: Theme }) =>
    active ? theme.color.foreground.primary : theme.color.background.progressIndicator};
  border-radius: 50%;
`;

export const DotCover = styled.div`
  position: absolute;
  height: 8px;
  border-radius: 10px;
  width: ${(props) => (props.span > 1 ? 16 * props.span - 8 : 0)}px;
  background: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  transition: width 0.5s;
`;
