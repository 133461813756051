import styled from 'styled-components';
import { Theme } from 'types/theme';
import imageSuccessBackground from 'assets/img/staking-success-bg.svg';
import imageErrorBackground from 'assets/img/staking-error-bg.svg';

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 46px;
`;

export const Title = styled.h2`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  margin-bottom: 24px;
`;

export const Text = styled.div.attrs({ className: 'small-text' })``;

export const ImageWrapper = styled.div<{ $error: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${({ $error }) => ($error ? imageErrorBackground : imageSuccessBackground)});
  background-size: 100% 100%;
`;
