/* eslint-disable  @typescript-eslint/no-unsafe-return */
import { AlternativePaymentMethods, PaymentMethodType, TopUpLimits, TopUpType } from 'types/topUps';
import { State } from 'store/types/store';
import { NUMBER_OF_SAVED_CREDIT_CARDS_TO_SHOW } from 'scenes/MoneyTransfer/Deposit/Instant/const';
import { createSelector } from 'reselect';

export const getIsFeatureTopUpsEnabled = (state: State): boolean => state.topUps.isFeatureTopUpsEnabled;

export const getSavedPaymentMethods = (state: State): PaymentMethodType[] =>
  state.topUps.paymentMethods.savedPaymentMethods;

export const getMostRecentSavedPaymentMethods = createSelector(
  [
    getSavedPaymentMethods,
    (state: State, length: number | undefined = NUMBER_OF_SAVED_CREDIT_CARDS_TO_SHOW) => length,
  ],
  (savedPaymentMethods, length): PaymentMethodType[] =>
    savedPaymentMethods
      .filter(
        (paymentMethod: PaymentMethodType, index: number, self: PaymentMethodType[]) =>
          index ===
          self.findIndex(
            (pm) =>
              pm.cardNumberLast4 === paymentMethod.cardNumberLast4 &&
              pm.cardBrand === paymentMethod.cardBrand,
          ),
      )
      .slice(0, length),
);

export const getAlternativePaymentMethods = (
  state: State,
): { [key in AlternativePaymentMethods]: boolean } | null =>
  state.topUps.paymentMethods.alternativePaymentMethods;

export const getCreatedPaymentMethod = (state: State): PaymentMethodType | undefined =>
  state.topUps.paymentMethods.createdPaymentMethod;

export const getPaymentMethodsWithCreated = createSelector(
  [getCreatedPaymentMethod, getMostRecentSavedPaymentMethods],
  (createdPaymentMethod, mostRecentSavedPaymentMethods): PaymentMethodType[] => {
    if (!createdPaymentMethod) {
      return mostRecentSavedPaymentMethods;
    }

    if (
      mostRecentSavedPaymentMethods.findIndex(
        (method) =>
          method.cardNumberLast4 === createdPaymentMethod.cardNumberLast4 &&
          method.cardBrand === createdPaymentMethod.cardBrand,
      ) !== -1
    ) {
      return mostRecentSavedPaymentMethods;
    }

    return [createdPaymentMethod, ...mostRecentSavedPaymentMethods];
  },
);

export const getIsTopUpInitialising = (state: State): boolean => state.topUps.isTopUpInitialising;

export const getActiveTopUp = (state: State): TopUpType | undefined => state.topUps.activeTopUp;

export const getSelectedPaymentMethod = (state: State): PaymentMethodType =>
  state.topUps.paymentMethods.selectedPaymentMethod;

export const getIsFetchingPaymentMethods = (state: State): boolean =>
  state.topUps.paymentMethods.isPaymentMethodsFetching;

export const getTopUpLimits = (state: State): TopUpLimits | undefined => state.topUps.limits;

export const getTopUpFeePercentage = (state: State): number | undefined =>
  state.topUps.limits?.topUpFeePercent;

export const getIsTopUpInitialisationFailed = (state: State): boolean =>
  state.topUps.isTopUpInitialisationFailed;

export const getIsTwoFeOngoing = (state: State): boolean => state.topUps.isTwoFeOngoing;
