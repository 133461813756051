import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { getTheme } from 'store/selectors/settings';
import { themeByName } from 'common/utils/theme';
import { useInputStyles } from 'common/styles/inputStyles';
import GlobalStyles from 'common/styles/global';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

/**
 * ThemeProvider adds theme to the app.
 */
const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const themeName = useSelector(getTheme);
  const theme = themeByName[themeName || 'light'];
  const muiInputThemeStyles = useInputStyles(theme);

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyles />
      <MuiThemeProvider theme={muiInputThemeStyles}>{children}</MuiThemeProvider>
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
