import { useEffect, useState } from 'react';

const useExternalScript = (url: string) => {
  const [state, setState] = useState(url ? 'loading' : 'idle');

  const handleScript = (e: Event) => {
    setState(e.type === 'load' ? 'ready' : 'error');
  };

  useEffect(() => {
    let script = document.querySelector(`script[src="${url}"]`);

    if (!url) {
      setState('idle');
      // return;
    } else {
      if (!script) {
        script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener('load', handleScript);
        script.addEventListener('error', handleScript);
      }

      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    return () => {
      script?.removeEventListener('load', handleScript);
      script?.removeEventListener('error', handleScript);
    };
  }, [url]);

  return state;
};

export default useExternalScript;
