import React, { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export const ListItemBoxDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px;
  margin-top: 10px;
  margin-bottom: 40px;
  border-top: 0.5px solid #cccccc;
  border-bottom: 0.5px solid #cccccc;
`;

const ListItemBox = ({ children }: Props): ReactElement => <ListItemBoxDiv>{children}</ListItemBoxDiv>;

export default ListItemBox;
