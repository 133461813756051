import { createSlice } from '@reduxjs/toolkit';
import { priceHistoryInitialState } from './initialState';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PRICE_HISTORY_SLICE_NAME } from 'common/const/slices';
import { ASSET_CLASSES } from 'types/assets';
import { TimePeriod } from 'types/currency';
import {
  PriceHistoryCryptoData,
  PriceHistoryEntity,
  PriceHistorySecurityData,
} from 'store/types/priceHistory';
import { priceHistoryAdapter } from './adapter';
import {
  getPriceHistoryEntityId,
  isPriceHistoryEntityEligibleForFetching,
  priceHistoryCryptoDataToPriceHistoryEntity,
  priceHistorySecurityDataToPriceHistoryEntity,
} from 'common/utils/priceHistory';
import { SELECT_TIME_PERIOD } from 'store/actions/currency';

const priceHistorySlice = createSlice({
  name: PRICE_HISTORY_SLICE_NAME,
  initialState: priceHistoryInitialState,
  reducers: {
    // CRYPTO PRICE HISTORY
    subscribeToCryptoPriceHistory(state, action: PayloadAction<{ code: string }>) {
      // Skip setting loading flag if the request will not happen
      if (!state.selectedTimePeriod) return;

      const entityId = getPriceHistoryEntityId({
        assetCode: action.payload.code,
        timePeriod: state.selectedTimePeriod,
      });

      const entity = state.entities[entityId];

      if (entity) {
        const isEligible = isPriceHistoryEntityEligibleForFetching(entity.lastFetched, ASSET_CLASSES.CRYPTO);

        if (!isEligible) return;

        state.cryptoFetchAssetCode = action.payload.code;
      } else {
        state.cryptoFetchAssetCode = action.payload.code;
      }
    },
    subscribeToCryptoPriceHistorySuccess(
      state,
      action: PayloadAction<{
        priceHistory: PriceHistoryCryptoData;
        timePeriod: TimePeriod;
        assetCode: string;
      }>,
    ) {
      const entity: PriceHistoryEntity = priceHistoryCryptoDataToPriceHistoryEntity(
        action.payload.priceHistory,
        action.payload.assetCode,
        ASSET_CLASSES.CRYPTO,
        action.payload.timePeriod,
      );

      priceHistoryAdapter.upsertOne(state, entity);
      state.cryptoFetchAssetCode = undefined;
    },
    subscribeToCryptoPriceHistoryError(state) {
      state.cryptoFetchAssetCode = undefined;
    },
    unsubscribeFromCryptoPriceHistory(state) {
      state.cryptoFetchAssetCode = undefined;
    },

    // SECURITY PRICE HISTORY
    subscribeToSecurityPriceHistory(state, action: PayloadAction<{ code: string }>) {
      // Skip setting loading flag if the request will not happen
      if (!state.selectedTimePeriod) return;

      const entityId = getPriceHistoryEntityId({
        assetCode: action.payload.code,
        timePeriod: state.selectedTimePeriod,
      });

      const entity = state.entities[entityId];

      if (entity) {
        const isEligible = isPriceHistoryEntityEligibleForFetching(
          entity.lastFetched,
          ASSET_CLASSES.SECURITY,
        );

        if (!isEligible) return;

        state.securityFetchAssetCode = action.payload.code;
      } else {
        state.securityFetchAssetCode = action.payload.code;
      }
    },
    subscribeToSecurityPriceHistorySuccess(
      state,
      action: PayloadAction<{
        priceHistory: PriceHistorySecurityData;
        timePeriod: TimePeriod;
        assetCode: string;
      }>,
    ) {
      const entity: PriceHistoryEntity = priceHistorySecurityDataToPriceHistoryEntity(
        action.payload.priceHistory,
        action.payload.assetCode,
        ASSET_CLASSES.SECURITY,
        action.payload.timePeriod,
      );

      priceHistoryAdapter.upsertOne(state, entity);
      state.securityFetchAssetCode = undefined;
    },
    subscribeToSecurityPriceHistoryError(state) {
      state.securityFetchAssetCode = undefined;
    },
    unsubscribeFromSecurityPriceHistory(state) {
      state.securityFetchAssetCode = undefined;
    },

    // SECURITY CHART HEALTH
    subscribeToSecurityChartHealthCheck() {},
    subscribeToSecurityChartHealthCheckError() {},
    subscribeToSecurityChartHealthCheckSuccess(state, action: PayloadAction<{ isHealthy: boolean }>) {
      state.isStockChartHealthy = action.payload.isHealthy;
    },
    unsubscribeFromSecurityChartHealthCheck() {},
  },
  extraReducers(builder) {
    builder.addCase(SELECT_TIME_PERIOD, (state, { timePeriod }: { timePeriod: TimePeriod | undefined }) => {
      state.selectedTimePeriod = timePeriod;
    });
  },
});

export default priceHistorySlice;
