import React, { createContext, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ParamTypes } from 'common/components/LimitOrderForm/types';

type StopOrderCreationContextType = {
  stopPrice: string;
  setStopPrice: (value: string) => void;
  spendAmount: string;
  setSpendAmount: (value: string) => void;
  cryptoAmount: string;
  setCryptoAmount: (value: string) => void;
  isConfirm: boolean;
  setIsConfirm: (value: boolean) => void;
  isBuy: boolean;
  currencyPrecision: number;
  setCurrencyPrecision: (value: number) => void;
};

export const StopOrderCreationContext = createContext<StopOrderCreationContextType>({
  stopPrice: '',
  setStopPrice: () => undefined,
  cryptoAmount: '',
  setCryptoAmount: () => undefined,
  spendAmount: '',
  setSpendAmount: () => undefined,
  isConfirm: false,
  setIsConfirm: () => undefined,
  isBuy: true,
  currencyPrecision: 2,
  setCurrencyPrecision: () => undefined,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const StopOrderCreationContextProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const { type } = useParams<ParamTypes>();
  const [stopPrice, setStopPrice] = useState<string>('');
  const [cryptoAmount, setCryptoAmount] = useState<string>('');
  const [spendAmount, setSpendAmount] = useState<string>('');
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [currencyPrecision, setCurrencyPrecision] = useState<number>(2);

  return (
    <StopOrderCreationContext.Provider
      value={{
        stopPrice,
        setStopPrice,
        spendAmount,
        setSpendAmount,
        cryptoAmount,
        setCryptoAmount,
        isConfirm,
        setIsConfirm,
        isBuy: type === 'buy',
        currencyPrecision,
        setCurrencyPrecision,
      }}
    >
      {children}
    </StopOrderCreationContext.Provider>
  );
};
