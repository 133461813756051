import pricesSlice from '.';

export const {
  // Utility
  subscribeToPriceChangesForSelectedAssetTag,
  unsubscribeFromPriceChangesForSelectedAssetTag,

  // Crypto
  subscribeToCryptoPriceChanges,
  subscribeToCryptoPriceChangesError,
  subscribeToCryptoPriceChangesSuccess,
  unsubscribeFromCryptoPriceChanges,

  // Securities
  subscribeToSecurityPriceChanges,
  subscribeToSecurityPriceChangesError,
  subscribeToSecurityPriceChangesSuccess,
  unsubscribeFromSecurityPriceChanges,
} = pricesSlice.actions;
