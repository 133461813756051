import { setBrazeInstance } from 'common/utils/braze';
import { useBrazeProvider } from 'providers/Braze/useBrazeProvider';
import { useEffect } from 'react';

const useEpicBraze = () => {
  const braze = useBrazeProvider();

  useEffect(() => {
    setBrazeInstance(braze);
  }, [braze]);
};

export default useEpicBraze;
