import { themes } from 'common/const';

const iconExchangeBlack = '/assets/img/limit-order.svg';
const iconExchangeWhite = '/assets/img/limit-order-white.svg';

const iconWatchBlack = '/assets/img/saving-plans.svg';
const iconWatchWhite = '/assets/img/saving-plans-white.svg';

const iconBellBlack = '/assets/img/price-alerts.svg';
const iconBellWhite = '/assets/img/price-alerts-white.svg';

const bisonLogoTextBlack = '/assets/img/bison_text_logo_black.svg';
const bisonLogoTextWhite = '/assets/img/bison_text_logo.svg';

const bisonLogo = '/assets/img/bison_notitle.png';

const themeAssets = {
  [themes.light]: {
    iconBell: iconBellBlack,
    iconExchange: iconExchangeBlack,
    iconWatch: iconWatchBlack,
    bisonLogoText: bisonLogoTextBlack,
    bisonLogo,
  },
  [themes.dark]: {
    iconBell: iconBellWhite,
    iconExchange: iconExchangeWhite,
    iconWatch: iconWatchWhite,
    bisonLogoText: bisonLogoTextWhite,
    bisonLogo,
  },
};

export default themeAssets;
