import { connect } from 'react-redux';
import { getUser } from 'store/selectors/auth';
import { State } from 'store/types/store';
import Footer from './view';

const mapStateToProps = (state: State) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(Footer);
