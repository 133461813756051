import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  acceptCryptoTermsAndConditions,
  acceptStocksTermsAndConditions,
  cryptoTermsAndConditionsClickedAway,
  stocksTermsAndConditionsClickedAway,
} from 'store/actions/termsAndConditions';
import {
  getCryptoTermsAndConditionsData,
  getStocksTermsAndConditionsData,
} from 'store/selectors/termsAndConditions';
import { useCheckSpecificTermsAndConditionsCase } from './useCheckSpecificTermsAndConditionsCase';
import { TermsAndConditionsType } from 'types/termsAndConditions';

export const useTnCPopUp = (onClose: () => void) => {
  const dispatch = useDispatch();
  const cryptoTermsAndConditionsData = useSelector(getCryptoTermsAndConditionsData);
  const stocksTermsAndConditionsData = useSelector(getStocksTermsAndConditionsData);

  const { requiresSecondCheckBox: cryptoRequiresSecondCheckBox } = useCheckSpecificTermsAndConditionsCase(
    cryptoTermsAndConditionsData,
  );
  const { requiresSecondCheckBox: stocksRequiresSecondCheckBox } = useCheckSpecificTermsAndConditionsCase(
    stocksTermsAndConditionsData,
  );

  const agreeToTerms = useCallback(
    (type: TermsAndConditionsType) => {
      if (!type) return;

      if (type === TermsAndConditionsType.CRYPTO) {
        dispatch(acceptCryptoTermsAndConditions.request());
      }
      if (type === TermsAndConditionsType.STOCKS) {
        dispatch(acceptStocksTermsAndConditions.request());
      }

      onClose();
    },
    [dispatch],
  );

  const onCancel = useCallback(
    (type: TermsAndConditionsType) => {
      if (!type) return;

      if (type === TermsAndConditionsType.CRYPTO) {
        dispatch(cryptoTermsAndConditionsClickedAway());
      }
      if (type === TermsAndConditionsType.STOCKS) {
        dispatch(stocksTermsAndConditionsClickedAway());
      }

      onClose();
    },
    [dispatch],
  );

  return {
    agreeToTerms,
    onCancel,
    cryptoRequiresSecondCheckBox,
    stocksRequiresSecondCheckBox,
    cryptoTerms: cryptoTermsAndConditionsData.latestTerms,
    stocksTerms: stocksTermsAndConditionsData.latestTerms,
  };
};
