export const MARKET_OVERVIEW_SLICE_NAME = 'marketOverview';
export const FILTERS_SLICE_NAME = 'filters';
export const INBOX_SLICE_NAME = 'inbox';
export const ASSETS_SLICE_NAME = 'assets';
export const ACTIVITY_SLICE_NAME = 'activity';
export const PRICES_SLICE_NAME = 'prices';
export const PRICE_CHANGES_SLICE_NAME = 'priceChanges';
export const PRICE_HISTORY_SMALL_CHART_SLICE_NAME = 'priceHistorySmallChart';
export const PRICE_HISTORY_SLICE_NAME = 'priceHistory';
export const STAKING_SLICE_NAME = 'staking';
export const PORTFOLIO_SLICE_NAME = 'portfolio';
export const PORTFOLIO_CHART_SLICE_NAME = 'portfolioChart';
export const PRICE_ALERTS_SLICE_NAME = 'priceAlerts';
export const SAVINGS_PLANS_SLICE_NAME = 'savingsPlans';
export const TRADING_RULES_SLICE_NAME = 'tradingRules';
export const REFERRAL_SLICE_NAME = 'referral';
export const NEWS_SLICE_NAME = 'news';
export const DEVICES_SLICE_NAME = 'devices';
export const TAX_EXEMPTION_SLICE_NAME = 'taxExemption';
export const LOSS_TAX_EXEMPTIONS_SLICE_NAME = 'lossTaxExemptions';
export const NEWSLETTER_SLICE_NAME = "newsletter"
