import { statusValues } from 'common/const';
import { useSelector } from 'react-redux';
import { getStatus } from 'store/selectors/status';

// Returns bool that represents the display value of Maintenance page.
const useMaintenance = () => {
  const status = useSelector(getStatus);

  return status === statusValues.down;
};

export default useMaintenance;
