import { DEVICE_ID } from 'services/auth';
import { getItem } from 'services/storage';
import { stringHashCode } from './hash';

/**
 * deviceConsentPairHash generates a hash depending on username and deviceId.
 * @param username
 * @returns a hash code
 */
export function deviceConsentPairHash(username: string) {
  const { error, response } = getItem(DEVICE_ID);
  if (!error && response?.item) {
    return stringHashCode(`${username}${response.item}`);
  }
  return null;
}
