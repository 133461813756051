import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { locationChange } from 'store/actions/navigation';

const useLocationChange = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationChange(location));
  }, [location]);
};

export default useLocationChange;
