/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StakingProps } from 'types/staking';
import { Loader } from 'common/lib/semantic-ui';
import LayoutNarrow from 'common/components/LayoutContainer/LayourNarrow/LayoutNarrow';
import Icon from 'common/components/Icon';
import IconButton from 'common/components/IconButton/IconButton';
import StakingAmountForm from 'common/components/StakingAmountForm';
import { stakeOrUnstakeOrderTypes } from 'common/const';
import { devices, dimensions } from 'common/styles';
import useStakingFlow from 'common/hooks/useStakingFlow';
import StakingData from 'common/components/StakingData';
import { HeaderText, LoaderWrapper } from './styles';
import { getValidationSchema } from './helpers';

type Props = {
  assetCode: string;
} & StakingProps;

const AssetStaking: FunctionComponent<Props> = ({ navigate, availableCryptos, setPageTitle, assetCode }) => {
  const { t } = useTranslation();

  const { asset, amount, setAmount, parsedStakingInfo, onProceed, isStakingLoading, additionalSummaryData } =
    useStakingFlow({
      assetCode,
      type: stakeOrUnstakeOrderTypes.Stake,
      setPageTitleCallback: setPageTitle,
    });

  const selectedCrypto = useMemo(
    () => availableCryptos.find(({ entity }) => entity.toLowerCase() === assetCode?.toLowerCase()),
    [availableCryptos, assetCode],
  );

  if (!parsedStakingInfo && !isStakingLoading) {
    return (
      <LoaderWrapper $active>
        <Loader active />
      </LoaderWrapper>
    );
  }

  return (
    <LayoutNarrow
      maxWidth={502}
      mainContainerCustomStyles={`@media ${devices.tablet} {padding: ${dimensions.modalPadding}px;}`}
      headerElement={
        <HeaderText>
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="icon-arrow" sizeValue="regular" />
          </IconButton>
          <div>{t('staking.title')}</div>
        </HeaderText>
      }
    >
      <StakingAmountForm
        onProceed={onProceed}
        assetCode={assetCode}
        amount={amount}
        updateAmount={setAmount}
        maxAmount={selectedCrypto?.volume}
        asset={asset}
        validationSchema={() =>
          getValidationSchema(
            assetCode,
            parsedStakingInfo?.minStakeAmount,
            selectedCrypto?.volume,
            asset?.cryptoDetails,
          )
        }
      >
        <StakingData
          stakingInfo={parsedStakingInfo}
          cryptoInfo={asset}
          additionalSummaryData={additionalSummaryData}
        />
      </StakingAmountForm>

      <LoaderWrapper $active={isStakingLoading}>
        <Loader active={isStakingLoading} />
      </LoaderWrapper>
    </LayoutNarrow>
  );
};

export default AssetStaking;
