import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REFERRAL_SLICE_NAME } from 'common/const/slices';
import { initialState } from './initialState';
import { ReferralReward } from 'types/referral';

const referralSlice = createSlice({
  name: REFERRAL_SLICE_NAME,
  initialState: initialState,
  reducers: {
    // fetching of referral reward
    fetchReferralRequest(state) {
      state.refreshing = true;
    },
    fetchReferralSuccess(state, action: PayloadAction<{ response: ReferralReward | undefined }>) {
      state.refreshing = false;
      state.hasActiveCampaign = true;
      state.reward = action.payload.response;
    },
    fetchReferralFailure(state) {
      state.refreshing = false;
      state.hasActiveCampaign = false;
    },

    //helper to trigger invalidation
    invalidateReferral() {},
  },
});

export default referralSlice;
