import { instrumentsAdapter } from 'store/slices/portfolio/adapter';
import { Portfolio } from 'types/portfolio';

export const initialState: Portfolio = {
  firstLoadComplete: false,
  loading: false,
  errMsg: '',
  currency: 'eur',
  cash: {
    volume: undefined,
  },
  invested: {
    investedValue: undefined,
    value: undefined,
    openPL: undefined,
    openPLPerc: undefined,
    closedPL: undefined,
    closedPLPerc: undefined,
  },
  instruments: instrumentsAdapter.getInitialState([]),
};
