/* eslint-disable */
import * as CryptoJS from 'crypto-js';
import { parseQueryString } from 'common/utils/location';

export default function parseTimestampFromGuid(tsGuid: string): string {
  var rfStr = '-9FX1ea' + 'O8_8gt';
  var tsPrep = tsGuid.toUpperCase();
  rfStr = '14AE8e' + rfStr;
  var r = rfStr.slice(0, rfStr.length / 2).split('');
  var f = rfStr.slice(rfStr.length / 2, rfStr.length).split('');
  for (var i = 0; i < r.length - 1; i++) {
    tsPrep = tsPrep.replace(new RegExp(r[i], 'g'), f[i]) + r[i] + f[i];
    if (i % 2 == 0) {
      tsPrep = tsPrep.split('').reverse().join('');
    }
  }
  var tsDone = CryptoJS.MD5(tsPrep).toString().toLowerCase();
  return (
    tsDone.slice(0, 8) +
    '-' +
    tsDone.slice(8, 12) +
    '-' +
    tsDone.slice(12, 16) +
    '-' +
    tsDone.slice(16, 20) +
    '-' +
    tsDone.slice(20, 32)
  );
}

export const getUTMParams = () => parseQueryString();
