import { CRYPTO_PRICE_DATA_TTL, SECURITY_PRICE_DATA_TTL } from 'common/const/prices';
import { PriceData, PriceEntity } from 'store/types/prices';
import { ASSET_CLASSES, AssetClass } from 'types/assets';

// PUBLIC METHODS

/**
 * Calculates if the given price data is old and is ready to be re-fetched.
 *
 * @summary Calculates if price is stale.
 *
 * @public
 * @param {number} lastFetchedDate
 * @param {AssetClass} assetClass
 * @returns {boolean}
 */
export const isPriceEntityEligibleForFetching = (lastFetchedDate: number, assetClass: AssetClass): boolean =>
  assetClass === ASSET_CLASSES.SECURITY
    ? lastFetchedDate + SECURITY_PRICE_DATA_TTL < new Date().getTime()
    : lastFetchedDate + CRYPTO_PRICE_DATA_TTL < new Date().getTime();

/**
 * Converts an array of price data to price entities. Asset class and last fetched date are added.
 * Added data is then later used to calculate if a specific price entity is stale (old).
 *
 * @summary Converts an array of price data to an array of price entity.
 *
 * @public
 * @param {PriceData[]} priceDataItems
 * @param {AssetClass} assetClass
 * @returns {PriceEntity[]}
 */
export const priceDataItemsToPriceEntityItems = (
  priceDataItems: PriceData[],
  assetClass: AssetClass,
): PriceEntity[] => priceDataItems.map((priceData) => priceDataToPriceEntity(priceData, assetClass));

// PRIVATE METHODS
const priceDataToPriceEntity = (priceData: PriceData, assetClass: AssetClass): PriceEntity => ({
  ...priceData,
  assetClass,
  lastFetched: new Date().getTime(),
});
