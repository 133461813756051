import { paths } from 'common/urls';
import { call } from 'redux-saga/effects';
import * as trackingActions from 'store/actions/tracking';
import * as biEvents from '../biEventDefinitions';

export function* logSettingsEvents(
  action: ReturnType<typeof trackingActions.trackAppEvent>,
): Generator<any, any, any> {
  const screenId = paths.DESIGN;
  switch (action.eventName) {
    case 'themeChanged': {
      const { theme } = action.payload;
      yield call(biEvents.trackSettingsModifySettingTheme, screenId, theme);
      break;
    }
    default:
      break;
  }
}
