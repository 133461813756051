import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CONSENT_TYPES,
  FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS,
} from 'common/const/consent';
import { TNC_POPUP_TYPES } from 'common/const/termsAndConditions';
import {
  acceptCryptoTermsAndConditions,
  acceptFeatureTermsAndConditions,
  declineFeatureTermsAndConditions,
  fetchFeatureTermsAndConditionsActiveStaking,
  notifyUserAfterActiveStakingAcceptance,
} from 'store/actions/termsAndConditions';
import { TnCPopUpType } from 'types/termsAndConditions';
import { ModalProps } from 'types/modals';
import { FormikProps } from 'formik';
import getRequiredDocuments from 'common/components/Modal/TermsAndConditions/StakingFeatureSpecificTncConsentModal/utils';
import * as Yup from 'yup';
import {
  getIsCryptoTermsAccepted,
  getIsFeatureSpecificTncNotAccepted,
  getIsFeatureTermsAndConditionsShown,
} from 'store/selectors/termsAndConditions';
import { useNavigate } from 'react-router-dom';
import { paths } from 'common/urls';

interface LabelTexts {
  general: boolean;
  specialConditions: boolean;
  directive: boolean;
}

const fullValidationSchema = Yup.object().shape({
  general: Yup.boolean().oneOf([true]).required(),
  specialConditions: Yup.boolean().oneOf([true]).required(),
  directive: Yup.boolean().oneOf([true]).required(),
});

export const getValidationSchema = (
  isConfirmGeneralTnCNeeded: boolean,
  isConfirmDirectiveNeeded: boolean,
) => {
  const fieldsToValidate: ('general' | 'specialConditions' | 'directive')[] = ['specialConditions'];
  if (isConfirmGeneralTnCNeeded) fieldsToValidate.push('general');
  if (isConfirmDirectiveNeeded) fieldsToValidate.push('directive');

  return fullValidationSchema.pick(fieldsToValidate);
};

export const useStakingFeatureSpecificTncConsentModal = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<LabelTexts>>(null);
  const navigate = useNavigate();

  const isStakingTermsAndConditionsShown = useSelector((state) =>
    getIsFeatureTermsAndConditionsShown(state, CONSENT_TYPES.ACTIVE_STAKING),
  );
  const isConfirmGeneralTnCNeeded = !useSelector(getIsCryptoTermsAccepted);

  const isConfirmDirectiveNeeded = useSelector((state) =>
    getIsFeatureSpecificTncNotAccepted(state, CONSENT_TYPES.PASSIVE_STAKING),
  );

  const [activePopUp, setActivePopUp] = useState<TnCPopUpType | undefined>();
  const textVersion = isConfirmGeneralTnCNeeded
    ? FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITH_T_AND_C
    : FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITHOUT_T_AND_C;

  const extendedTextVersionForExitPopup = isConfirmGeneralTnCNeeded
    ? FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITH_T_AND_C
    : isConfirmDirectiveNeeded
      ? FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITH_DIRECTIVE
      : FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITHOUT_T_AND_C;

  const definedDocuments = getRequiredDocuments(isConfirmGeneralTnCNeeded);

  const declineTerms = () => {
    dispatch(declineFeatureTermsAndConditions(CONSENT_TYPES.ACTIVE_STAKING));
    navigate(paths.PORTFOLIO);
    formikRef.current?.resetForm();
    onClose();
  };

  const agreeTerms = () => {
    dispatch(notifyUserAfterActiveStakingAcceptance(isConfirmGeneralTnCNeeded, isConfirmDirectiveNeeded));

    dispatch(acceptFeatureTermsAndConditions(CONSENT_TYPES.ACTIVE_STAKING, true));
    if (isConfirmDirectiveNeeded) {
      dispatch(acceptFeatureTermsAndConditions(CONSENT_TYPES.PASSIVE_STAKING, true));
    }
    if (isConfirmGeneralTnCNeeded) {
      dispatch(acceptCryptoTermsAndConditions.request(true));
    }

    formikRef.current?.resetForm();
    setActivePopUp(undefined);
    onClose();
  };

  useEffect(() => {
    dispatch(fetchFeatureTermsAndConditionsActiveStaking());
  }, []);

  useEffect(() => {
    if (!isStakingTermsAndConditionsShown) {
      setActivePopUp(undefined);
      return;
    }
    setActivePopUp(TNC_POPUP_TYPES.TNC);
  }, [isStakingTermsAndConditionsShown]);

  return {
    isConfirmGeneralTnCNeeded,
    isConfirmDirectiveNeeded,
    definedDocuments,
    validationSchema: getValidationSchema(isConfirmGeneralTnCNeeded, isConfirmDirectiveNeeded),
    activePopUp,
    setActivePopUp,
    declineTerms,
    agreeTerms,
    formikRef,
    textVersion,
    extendedTextVersionForExitPopup,
  };
};
