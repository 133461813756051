/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ofType } from 'redux-observable';
import { combineLatest } from 'rxjs';
import {
  take,
  map,
  ignoreElements,
  tap,
  switchMap,
  filter,
  distinctUntilChanged,
  pluck,
  withLatestFrom,
} from 'rxjs/operators';
import { getUser } from 'store/selectors/auth';
import * as formsActions from 'store/actions/forms';
import * as orderActions from 'store/actions/orders';
import * as authActions from 'store/actions/auth';
import { logNewScreenOpen, logOrderReservation } from 'store/actions/misc';
import { buyOrSellOrderTypes, accountTypes } from 'common/const';
import * as events from 'common/utils/analytics/gtm';
import { notCompleteUrl, paths } from 'common/urls';
import getCurrentPath from 'store/selectors/navigation';
import { LOCATION_CHANGE } from 'store/actions/navigation';
import { Location } from 'react-router-dom';
import { FetchPortfolioSuccess, fetchPortfolioSuccess } from 'store/slices/portfolio/actions';
import { PortfolioResponse } from 'store/types/portfolio';

const getPathname = ({ location }: { location: Location }) => ({ pathname: location.pathname });
const isPortfolio = (pathname: string) => pathname.indexOf(paths.PORTFOLIO) > -1;
const isBuySell = (pathname: string) => pathname.indexOf(paths.TRADE_CURRENCY) > -1;
const isBuyPathname = (pathname: string) => pathname.indexOf(buyOrSellOrderTypes.Buy.toLowerCase()) > -1;

const analytics = {
  watchPortfoliChange: (action$) =>
    action$.pipe(
      ofType(LOCATION_CHANGE),
      map(getPathname),
      filter(({ pathname }) => !notCompleteUrl(pathname) && isPortfolio(pathname)),
      switchMap(({ pathname }) =>
        action$.pipe(
          ofType(fetchPortfolioSuccess.type),
          take(1),
          map(({ payload }: FetchPortfolioSuccess) => {
            const portfolio = payload?.response;
            const totalValue = (portfolio.cash.volume as number) + (portfolio.invested.value as number);
            const params = {};
            try {
              params.value = parseFloat(totalValue.toFixed(2));
              params.portfolio_value = parseFloat(portfolio.invested.value.toFixed(2));
              params.portfolio_change = parseFloat(portfolio.invested.openPL.toFixed(2));
              params.available_value = parseFloat(portfolio.cash.volume.toFixed(2));
              params.invest_value = parseFloat(portfolio.invested.investedValue.toFixed(2));
            } catch (e) {
              // eslint-disable-next-line
              console.log(e);
            }
            return { pathname, params };
          }),
          map(logNewScreenOpen),
        ),
      ),
    ),
  wathBuySellUrl: (action$, state$) =>
    combineLatest(
      action$.pipe(
        ofType(LOCATION_CHANGE),
        map(getPathname),
        filter(({ pathname }) => !notCompleteUrl(pathname) && isBuySell(pathname)),
      ),
      state$.pipe(pluck('currency', 'lastSelectedCryptoCurrency'), distinctUntilChanged()),
    ).pipe(
      map(([{ pathname }, selectedCrypto]) => {
        const params = {
          product: selectedCrypto.toUpperCase(),
          product_variant: isBuyPathname(pathname) ? buyOrSellOrderTypes.Buy : buyOrSellOrderTypes.Sell,
        };
        return { pathname, params };
      }),
      map(logNewScreenOpen),
    ),
  watchReserveOrder: (action$) =>
    action$.pipe(
      ofType(orderActions.RESERVE_ORDER.SUCCESS),
      map(({ orderPreview: { entity, orderType, price } }) => ({
        entity,
        orderType,
        price,
      })),
      map(logOrderReservation),
    ),
  watchPublicPaths: (action$) =>
    action$.pipe(
      ofType(LOCATION_CHANGE),
      map(getPathname),
      filter(({ pathname }) => !notCompleteUrl(pathname) && !isPortfolio(pathname) && !isBuySell(pathname)),
      map(logNewScreenOpen),
    ),
  watchKycCompleted: (action$, state$) =>
    action$.pipe(
      ofType(authActions.AUTH.SUCCESS, authActions.LOGIN_USER_SUCCESS),
      withLatestFrom(state$),
      map(([, state]) => getUser(state)),
      filter((user) => {
        const kycStarted = localStorage.getItem(`${user.guid}_kycIdentStarted`);
        return user.accountType === accountTypes.real && kycStarted;
      }),
      tap(events.kycCompleted),
      tap((user) => localStorage.removeItem(`${user.guid}_kycIdentStarted`)),
      ignoreElements(),
    ),
  watchKycVideStarted: (action$, state$) =>
    action$.pipe(
      ofType(formsActions.OPEN_VERIFICATION_PAGE),
      withLatestFrom(state$),
      map(([, state]) => getUser(state)),
      tap((user) => localStorage.setItem(`${user.guid}_kycIdentStarted`, true)),
      ignoreElements(),
    ),
  watchLimitOrderSelectCrypto: (action$, state$) =>
    action$.pipe(
      ofType('LIMIT_ORDER_SELECT_CRYPTO'),
      withLatestFrom(state$),
      map(([, state]) => getCurrentPath(state)),
      tap((pathname) => events.limitOrderSelectCrypto(pathname)),
      ignoreElements(),
    ),
  watchLimitOrderSend: (action$, state$) =>
    action$.pipe(
      ofType('LIMIT_ORDER_SEND'),
      map(({ isBuy, selectedCrypto }) => ({
        action: isBuy ? buyOrSellOrderTypes.Buy : buyOrSellOrderTypes.Sell,
        currency: selectedCrypto,
      })),
      tap(events.limitOrderSend),
      ignoreElements(),
    ),
  watchLimitOrderReview: (action$) =>
    action$.pipe(
      ofType('SUBMIT_TRADING_RULE'),
      map(
        ({
          payload: {
            orderType,
            entity,
            entityVolume,
            price,
            auxiliary: { euro },
          },
        }) => ({
          action: orderType,
          priceLimit: price,
          currency: entity,
          amount: entityVolume,
          euro,
        }),
      ),
      tap(events.limitOrderConfirm),
      switchMap((payload) =>
        action$.pipe(
          ofType('SUBMIT_TRADING_RULES_SUCCESS'),
          tap(() => events.limitOrderConfirm(payload)),
          ignoreElements(),
        ),
      ),
    ),
  watchLimitOrderDelete: (action$) =>
    action$.pipe(ofType('SAVE_DELETING_TRADING_RULE'), tap(events.limitOrderDeletePreview), ignoreElements()),
  watchLimitOrderDeleteConfirm: (action$) =>
    action$.pipe(ofType('DELETE_TRADING_RULE'), tap(events.limitOrderDeleteConfirm), ignoreElements()),
};

export default analytics;
