import { TTL_STAKING_OVERVIEW_IN_MINUTES } from 'common/const/staking';
import { add } from 'date-fns';
import { PortfolioAsset, PortfolioInstruments } from 'types/portfolio';

export const shouldLoadStakingInfo = (lastFetchTime: null | string) => {
  if (!lastFetchTime) return true;

  return new Date() > add(new Date(lastFetchTime), { minutes: TTL_STAKING_OVERVIEW_IN_MINUTES });
};
export const extractTotalStakingSums = (instruments: PortfolioAsset[]) => {
  return instruments.reduce(
    (acc, { stakingRewardValue, stakeValue, unstakeValue }) => ({
      totalStakingRewards: acc.totalStakingRewards + stakingRewardValue,
      inStakingValue: acc.inStakingValue + stakeValue + unstakeValue,
      inUnstakingValue: acc.inUnstakingValue + unstakeValue,
    }),
    { totalStakingRewards: 0, inStakingValue: 0, inUnstakingValue: 0 },
  );
};
export const getLastPointOfChartFromTotalSum = (cryptoInstruments: PortfolioInstruments) => {
  const now = new Date();
  const currentValue = extractTotalStakingSums(cryptoInstruments).inStakingValue;

  return {
    y: currentValue,
    x: now.toISOString(),
    assetValue: 0,
    openInvestedValue: 0,
  };
};
