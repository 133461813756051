import { combineReducers } from 'redux';

import winnersLosersState from './winnersLosers/reducer';
import cryptoShortState from './cryptoShort/reducer';

export default combineReducers({
  winnersLoser: winnersLosersState,
  cryptoShort: cryptoShortState,
  // Add others...
});
