import { SecurityMarketStatus } from 'types/status';
import { getHours } from 'date-fns';

export const getIsOutOfTradingHours = (stockMarketStatus: SecurityMarketStatus) => {
  if (stockMarketStatus.isMarketOpen) return false;

  const startHours = getHours(new Date(stockMarketStatus.tradingHoursStartAt));
  const endHours = getHours(new Date(stockMarketStatus.tradingHoursCloseAt));
  const currentHour = getHours(new Date());

  return startHours > currentHour || currentHour > endHours;
};
