import { AmlConfirmationStatus } from 'types/state';
import { AML_STATUSES } from 'common/const/auditProof';

export const isAmlOverdue = (status: AmlConfirmationStatus) => status === AML_STATUSES.OVERDUE;

export const isAmlOpen = (status: AmlConfirmationStatus) => status === AML_STATUSES.OPEN;

export const isAmlRequired = (status: AmlConfirmationStatus) => isAmlOverdue(status) || isAmlOpen(status);

export const isCountdownValid = (countdown: number) => countdown > 0;
