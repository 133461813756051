import useDisplayCryptoTnC from './useDisplayCryptoTnC';
import useDisplayStocksTnC from './useDisplayStocksTnC';

export const useTnCPopUpDisplay = () => {
  const displayCryptoTnc = useDisplayCryptoTnC();
  const displayStocksTnc = useDisplayStocksTnC();

  return {
    displayCryptoTnc,
    displayStocksTnc: !displayCryptoTnc && displayStocksTnc,
  };
};
