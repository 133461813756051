/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  delay,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { getParentPage, paths } from 'common/urls';
import I18n from 'i18next';
import { getCurrentPath } from 'store/selectors/navigation';
import { createPageStatusSelector } from 'store/selectors/status';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import {
  PARENT_LOCATION_CHANGED,
  parentLocationChanged,
  STATUS_PAGE_CHANGED,
  statusPageChanged,
} from 'store/actions/misc';
import { getNotificationMap } from 'store/selectors/notification';
import { statusValues } from 'common/const';
import { LOCATION_CHANGE } from 'store/actions/navigation';
import { Location } from 'react-router-dom';

const getPageStatusSelector = createPageStatusSelector();
const screenStatusId = 'statusScreen';

// epics
export const handlePageStatusChange = (action$, state$) => {
  return action$.pipe(
    ofType(STATUS_PAGE_CHANGED),
    map(({ pathname }) => ({ notifications: getNotificationMap(state$.value), pathname })),
    map(({ notifications, pathname }) => {
      return notifications[screenStatusId]
        ? notifications[screenStatusId].filter((item) => item.screen === pathname)
        : [];
    }),
    filter((notifications) => notifications.length > 0),
    map((oldNotifications) => notificationBannerActions.clearNotification(oldNotifications)),
  );
};

export const handleLocationsChange = (action$) =>
  action$.pipe(
    ofType(PARENT_LOCATION_CHANGED),
    map(({ notifications, pathname }) => {
      return notifications[screenStatusId]
        ? notifications[screenStatusId].filter((item) => item.screen !== pathname)
        : [];
    }),
    filter((notifications) => notifications.length > 0),
    map((oldNotifications) => notificationBannerActions.clearNotification(oldNotifications)),
  );

export const handleBisonPageStatus = (action$, state$) => {
  return action$.pipe(
    ofType(PARENT_LOCATION_CHANGED),
    switchMap(({ pathname }) =>
      state$.pipe(
        map((state) => getPageStatusSelector(state, pathname)),
        distinctUntilChanged(
          (prev, current) => prev.status === current.status && prev.block === current.block,
        ),
        map((args) => statusPageChanged({ ...args, pathname })),
      ),
    ),
  );
};
// this epic redirects to prtfolio page if the other page is blocked
export const handleRedirectStatusBlock = (action$, state$) => {
  return action$.pipe(
    ofType(STATUS_PAGE_CHANGED),
    filter(({ status }) => [statusValues.warn, statusValues.down].indexOf(status) > -1),
    map(({ pathname }) => getPageStatusSelector(state$.value, pathname)),
    filter(({ block, status }) => {
      const currentPath = getCurrentPath(state$.value);
      return block && status === statusValues.down && currentPath.indexOf(paths.PORTFOLIO) !== 0;
    }),
    delay(2000),
    // TODO: Find solution here
    // mapTo(push(paths.PORTFOLIO)),
  );
};

export const handleParentLocationChange = (action$, state$) => {
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    map(({ location }: { location: Location }) => {
      return {
        pathname: getParentPage(location.pathname),
        notifications: getNotificationMap(state$.value),
      };
    }),
    distinctUntilKeyChanged('pathname'),
    map((args) => parentLocationChanged(args)),
  );
};

export const handleBisonStatusLocation = (action$, state$) =>
  action$.pipe(
    ofType(STATUS_PAGE_CHANGED),
    filter(({ status }) => [statusValues.warn, statusValues.down].indexOf(status) > -1),
    map(({ pathname }) => {
      const { status, message } = getPageStatusSelector(state$.value, pathname);
      const params = {
        toastType: screenStatusId,
        screen: pathname,
        message: message[I18n.t('l10n.lang')],
        options: {
          autoClose: 24 * 60 * 60,
        },
      };
      if (status === statusValues.warn) {
        return notificationBannerActions.notifyWarn(params);
      }
      return notificationBannerActions.notifyError(params);
    }),
  );
