import { ModalType } from 'providers/Modal/types';
import { ModalProps } from 'types/modals';
import { $Values } from 'utility-types';

export const MODAL_DIMENSIONS = {
  DEFAULT: 'default',
  WIDE: 'wide',
  SMALL: 'small',
  // reduces vertical paddings from 32px to 24px
  DEFAULT_WITH_REDUCED_VERTICAL_PADDING: 'defaultWithReducedPadding',
} as const;

export type ModalDimensionsType = $Values<typeof MODAL_DIMENSIONS>;

export const MODAL_ACTIONS_MARGIN = {
  DEFAULT: 'default',
  BIG: 'big',
};

export type ModalActionsMarginType = $Values<typeof MODAL_ACTIONS_MARGIN>;

export const DEFAULT_MODAL_PROPS: ModalProps = { onClose: () => {} };

export const DEFAULT_MODAL_TYPE: ModalType = 'private';
