import { ASSET_SECURITY_CLASSES } from 'types/assets';

// WINNERS AND LOSERS
export const WINNERS_LOSERS_ASSETS_COUNT = 3;
export const WINNERS_LOSERS_TIME_PERIOD = 'OneDay';
export const WINNERS_LOSERS_PRICE_CHANGE_PERIOD = '1d';
export const WINNERS_LOSERS_TAG = 'stock';

// CRYPTO SHORT
export const CRYPTO_SHORT_ALLOWED_CODES = ['eth', 'btc'];
export const CRYPTO_SHORT_ISINS = { eth: 'ch1210548884', btc: 'ch0514065058' };
export const CRYPTO_SHORT_ASSET_SECURITY_CLASS = ASSET_SECURITY_CLASSES.ETN;
export const CRYPTO_SHORT_DEFAULT_DISPLAY_NAMES = {
  eth: '21Shares Short Ethereum ETP',
  btc: '21Shares Short Bitcoin ETP',
};
export const CRYPTO_SHORT_PRICE_CHANGE_PERIOD = '24H';

// If set to true that means that we can skip the request that fetches the asset info
export const CRYPTO_SHORT_USE_HARDCODED_NAMES = true;
