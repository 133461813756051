import React from 'react';
import { Navigate, Params, RelativeRoutingType, generatePath, useParams } from 'react-router-dom';

export interface RedirectProps {
  to: string;
  state?: Record<string, string | undefined>;
  relative?: RelativeRoutingType;
  additionalParams: Readonly<Params<string>>;
}

/**
 * Wraps the <Navigate> component and replaces "/:<paramName>" with "/<paramValue"
 */
export const Redirect: React.FC<RedirectProps> = ({ to, additionalParams, ...rest }) => {
  const params = useParams();
  return <Navigate to={generatePath(to, { ...params, ...additionalParams })} {...rest} replace />;
};
