import { State } from 'store/types/store';
import { pricesAdapter } from './adapter';
import { AssetClass } from 'types/assets';
import { isPriceEntityEligibleForFetching } from 'common/utils/prices';
import { createSelector } from 'reselect';
import { getMarketOverviewSearchAssetCodes } from '../filters/selectors';
import { PriceEntity } from 'store/types/prices';
import { isEqualIgnoreCase } from 'common/utils/common';
import { getLimitOrdersPageItems } from 'store/selectors/tradingRules';
import { TradingRuleWithCategory } from 'types/tradingRules';
import { getAlertItems } from 'store/selectors/alerts';
import { Alert } from 'types/alerts';

const pricesState = (state: State) => state.prices;

const pricesSelectors = pricesAdapter.getSelectors(pricesState);

// Both private for now. Make it public if needed.
const areCryptoPricesFetching = (state: State) => pricesState(state).areCryptoPricesFetching;
const areSecuritiesPricesFetching = (state: State) => pricesState(state).areSecuritiesPricesFetching;

/**
 * Returns price data of a single passed-in asset.
 * If the asset is not there then it returns `undefined`
 *
 * @summary Price data of a single stored asset.
 *
 * @public
 * @param {State} state
 * @param {string} id Code/ID of an asset you want price data for.
 * @returns {PriceEntity | undefined}
 */
export const getPriceByCode = pricesSelectors.selectById;

/**
 * Returns all prices that are stored in this state adapter.
 *
 * @summary Price data of all stored assets.
 *
 * @public
 * @param {State} state
 * @returns {PriceEntity[]}
 */
export const getAllPrices = pricesSelectors.selectAll;

/**
 * Returns all prices that are stored in this state adapter filtered by asset class.
 *
 * @summary Price data of all stored assets filtered by passed in asset class.
 *
 * @public
 * @param {State} state
 * @param {AssetClass} assetClass
 * @returns {PriceEntity[]}
 */
export const getAllPricesByAssetClass = (state: State, assetClass: AssetClass) =>
  getAllPrices(state).filter((item) => item.assetClass === assetClass);

/**
 * Returns all passed in filter codes that can be included in the price fetching.
 * If some asset's price was already fetched recently that asset will be left out.
 *
 * @summary Filtered codes for price fetching.
 *
 * @public
 * @param {State} state
 * @param {string[]} codes Asset codes that are ready to be fetched.
 * @returns {string[]}
 */
export const getEligiblePriceCodes = (state: State, codes: string[], assetClass: AssetClass) => {
  const allPrices = getAllPricesByAssetClass(state, assetClass);

  if (!allPrices.length) return codes;

  return codes.filter((code: string) => {
    const priceEntity = allPrices.find((entity) => isEqualIgnoreCase(entity.baseEntity, code));

    // If there is no entity yet stored than we need to fetch it.
    if (!priceEntity) return true;

    return isPriceEntityEligibleForFetching(priceEntity.lastFetched, assetClass);
  });
};

/**
 * Returns all memo price data for searched assets on Market Overview page.
 * If you need all prices please refer to: `getAllPrices` or `getAllPricesByAssetClass`
 *
 * @summary Prices for Market Overview page.
 *
 * @public
 * @param {State} state
 * @returns {PriceEntity[]}
 */
export const getMarketOverviewPrices = createSelector(
  [getAllPrices, getMarketOverviewSearchAssetCodes],
  (prices: PriceEntity[], codes: string[]) =>
    prices.filter((price: PriceEntity) => codes.includes(price.baseEntity.toLowerCase())),
);

/**
 * Returns a boolean value if some price fetching is in progress at this moment.
 *
 * @summary Price fetching in progress.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const isAnyPriceFetchingInProgress = areSecuritiesPricesFetching || areCryptoPricesFetching;
