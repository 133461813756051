import { call, select } from 'redux-saga/effects';
import I18n from 'i18next';
import { matchPathToUrl } from 'common/urls';
import { formatScreenProps } from 'common/utils/formatting';
import { getSessionData } from 'common/utils/session';
import { trackAppEvent } from 'store/actions/tracking';
import { getCurrentPath } from 'store/selectors/navigation';
import * as biEvents from '../biEventDefinitions';

// logAppOpen equivalent
export function* logOnLoad(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  if (action.eventName === 'windowOnLoad') {
    const screen: string = yield select(getCurrentPath);
    const screenData = matchPathToUrl(screen);
    const screenProps = formatScreenProps(screenData.match, 'screen');

    // Page load time calculation: https://developer.mozilla.org/en-US/docs/Glossary/Page_load_time
    const performanceTimimg = performance.getEntriesByType('navigation')[0];

    // performanceTiming is empty on old safari versions and no other performance data is available
    let loadTimeMs = 0;
    if (performanceTimimg) loadTimeMs = performanceTimimg.loadEventStart - performanceTimimg.startTime;
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const lang = navigator.language;
    const appLang = I18n.t('l10n.lang');
    const appOpenVariant = 'startup';
    const deeplink = '';
    const { referrer } = document;
    yield call(
      biEvents.trackAppOpenAppBison,
      screenData.path,
      loadTimeMs,
      tz,
      lang,
      appLang,
      appOpenVariant,
      deeplink,
      referrer,
      screenProps,
    );
  }
}
// logAppClose equivalent
export function* logBeforeUnload(action): Generator<any, any, any> {
  if (action === 'windowBeforeUnload') {
    const screen: string = yield select(getCurrentPath);
    const screenData = matchPathToUrl(screen);
    const sessionData = yield select(getSessionData);
    const sessionTimeMsec = sessionData[0] - sessionData[1];
    const screenProps = formatScreenProps(screenData.match, 'screen');
    yield call(biEvents.trackAppCloseAppBison, screenData.path, sessionTimeMsec, screenProps);
  }
}
