import styled from 'styled-components';
import { Theme } from 'types/theme';
import { devices } from 'common/styles';

export const Container = styled.div.attrs({ className: 'background-primary' })`
  border-radius: ${({ theme }: { theme: Theme }) => theme.border.radius.standard};
  display: flex;
  justify-content: space-between;
  padding: 4px;
  gap: 5px;
`;

export const OrderType = styled.div.attrs({ className: 'small-text medium-weight' })`
  padding: 11px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  cursor: pointer;
  border-radius: ${({ theme }: { theme: Theme }) => theme.border.radius.standard};
  color: ${({ theme, isActive, isDisabled }: { theme: Theme; isActive: boolean; isDisabled: boolean }) => {
    if (isDisabled) return theme.color.foreground.footer.text;

    return isActive ? theme.color.foreground.focused : theme.color.foreground.primaryLight;
  }};
  background-color: ${({ theme, isActive }: { theme: Theme; isActive: boolean }) =>
    isActive ? theme.color.background.primary : 'transparent'};
  transition: all 0.3s;

  @media ${devices.maxMobileS} {
    padding: 4px 0;
  }

  ${({ theme, isActive, isDisabled }: { theme: Theme; isActive: boolean; isDisabled: boolean }) =>
    !isDisabled &&
    `
    &:hover{
       ${!isActive && `background-color: ${theme.color.background.selected};`}
    } 
     
    &:active{
       ${!isActive && `background-color: ${theme.color.background.emph};`}
    
    `}
`;
