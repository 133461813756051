import tradingRulesSlice from '.';

export const {
  // GET ALL
  subscribeToTradingRules,
  subscribeToTradingRulesError,
  subscribeToTradingRulesSuccess,
  unsubscribeFromTradingRules,
  // DELETE
  deleteTradingRule,
  deleteTradingRuleError,
  deleteTradingRuleSuccess,
  // CREATE
  createTradingRule,
  createTradingRuleError,
  createTradingRuleSuccess,
} = tradingRulesSlice.actions;
