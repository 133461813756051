import { EnvironmentState } from 'store/types/environment';
import * as ActionTypes from '../actions/environment';
import { AnyAction } from 'redux';

const initialState: EnvironmentState = {
  isBeta: false,
  isFeatureStocksEnabled: false,
  isFeatureTopUpsEnabled: false,
};

function root(state: EnvironmentState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.SET_BETA_MODE:
      return {
        ...state,
        isBeta: true,
      };
    case ActionTypes.TOGGLE_STOCKS_ENABLED:
      return {
        ...state,
        isFeatureStocksEnabled: action.payload === 'true' || action.payload === true,
      };
    case ActionTypes.TOGGLE_FEATURE_TOP_UPS_ENABLED:
      return {
        ...state,
        isFeatureTopUpsEnabled:
          action.payload === 'true' ||
          action.payload === true ||
          (action.payload === 'BETA_ONLY' && state.isBeta) ||
          (action.payload === 'BETA_ONLY' ? 'BETA_ONLY' : false),
      };
    default:
      return state;
  }
}

export default root;
