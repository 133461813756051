import { Device } from 'types/devices';
import I18n from 'i18next';

const appDisplayName: {
  [key: string]: string;
} = {
  BISON_app: 'BISON App',
  BISON_web: 'BISON Web',
  BISON_web_beta: 'BISON Web',
  BISON_finanzen: 'BISON Finanzen.net',
};

export const getDeviceInfo = (device?: Device) => {
  if (!device) return { clientName: '', osVersion: '' };

  const app = appDisplayName[device.app] || device.app;

  let clientName: string;
  let osVersion: string;
  if (app === 'BISON App') {
    clientName = device.clientName !== 'unknown' ? device.clientName : I18n.t('devices.unknownPhone');
    osVersion = device.osVersion ? device.osVersion : '';
  } else {
    // Web browser
    clientName = device.clientName !== 'null null' ? device.clientName : I18n.t('devices.unknownBrowser');
    osVersion = device.osName ? device.osName : '';
  }
  return { clientName, osVersion };
};

export const deviceComparator = (d1: Device, d2: Device): number => {
  if (d1.lastActivityAt === d2.lastActivityAt) {
    return 0;
  }

  // Always sort null last
  if (d1.lastActivityAt === null) {
    return 1;
  }
  if (d2.lastActivityAt === null) {
    return -1;
  }

  // Sort non-null combinations
  if (d1.lastActivityAt > d2.lastActivityAt) {
    return -1;
  }
  if (d1.lastActivityAt < d2.lastActivityAt) {
    return 1;
  }
  return 0;
};

export const updateTargetDevice = (list: Device[], target: Device, payload: Partial<Device>): Device[] => {
  return list.map((item: Device) => {
    if (item.deviceHandle === target.deviceHandle) {
      return {
        ...item,
        ...payload,
      };
    }
    return item;
  });
};

export const removeTargetDevice = (list: Device[], target: Device): Device[] => {
  return list.filter((item: Device) => item.deviceHandle !== target.deviceHandle);
};
