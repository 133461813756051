import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useThemeContext } from 'common/utils/theme/ThemeContext';
import { Headline } from './delete-account-styles';

type Props = {
  deleteReasonText: string;
  setDeleteReasonText: (value: string) => void;
};

const DeleteAccountContent = ({ deleteReasonText, setDeleteReasonText }: Props): ReactElement => {
  const { t } = useTranslation();
  const theme = useThemeContext();

  return (
    <>
      <Headline>{t('settings.terminateAccount.terminateAccount')}</Headline>
      <ReactMarkdown>
        {` ${t('settings.terminateAccount.demoTerminateInitial.a')}
        \n${t('settings.terminateAccount.demoTerminateInitial.b.demo')}
        \n${t('settings.terminateAccount.demoTerminateInitial.c.demo')}`}
      </ReactMarkdown>
      <TextField
        id="outlined-multiline-static"
        value={deleteReasonText}
        onChange={(event) => setDeleteReasonText(event.target.value)}
        multiline
        fullWidth
        rows={4}
        variant="filled"
        sx={{
          '& .MuiInputBase-root': {
            fontSize: '16px',
            borderRadius: '4px',
            color: theme.color.foreground.primary,
          },
        }}
        InputProps={{ disableUnderline: true }}
      />
    </>
  );
};

export default DeleteAccountContent;
