import styled from 'styled-components';
import { Theme } from 'types/theme';
import { dimensions } from 'common/styles';

export const Title = styled.div.attrs({
  className: 'small-text strong-weight',
})`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.inactive};
  text-transform: uppercase;
  margin-top: ${dimensions.smallPadding}px;
`;

export const TitleYellow = styled(Title)`
  display: inline;
  color: #ffce2b;
`;

export const Body = styled.div`
  padding-top: 44px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.div.attrs({
  className: 'small-text',
})`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const PeriodValue = styled.div.attrs({
  className: 'xxsmall-text strong-weight',
})`
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.bankCard};
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.logo};
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 3px 7px;
`;

export const Divider = styled.div<{ $customMargin?: number }>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.border.table};

  margin: ${({ $customMargin }) => $customMargin ?? dimensions.regularMobilePadding}px 0;
`;

export const Summary = styled.div.attrs({
  className: 'strong-weight xlarge-text',
})`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const Spacer = styled.div`
  margin-top: 84px;
`;

export const AdditionalInfo = styled(Spacer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.bankCard};
  border-radius: 4px;
  opacity: 0.72;
  padding: ${dimensions.regularMobilePadding}px;
`;
