/* eslint-disable @typescript-eslint/no-unsafe-return, no-case-declarations */
import { AnyAction, combineReducers } from 'redux';
import * as ActionTypes from 'store/actions/currency';
import { TechIndicatorData, TimePeriod, TradingVolume } from 'types/currency';
import { Fiat } from 'common/const';

function techIndicatorDataResponse(state = {}, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.TECH_INDICATOR_DATA.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.TECH_INDICATOR_DATA.SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: new Date().getTime(),
        items: action.response.items?.map((entry, idx) => ({
          x: new Date(entry.closeTime).getTime(),
          epoch: new Date(entry.closeTime).getTime(),
          y: entry.y,
        })),
      };
    case ActionTypes.TECH_INDICATOR_DATA.FAILURE:
      return {
        isFetching: false,
      };
    default:
      return state;
  }
}

function techIndicatorByPairAndTimePeriod(state = {}, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.TECH_INDICATOR_DATA.REQUEST:
    case ActionTypes.TECH_INDICATOR_DATA.SUCCESS:
    case ActionTypes.TECH_INDICATOR_DATA.FAILURE:
      return {
        ...state,
        [action.timePeriod]: techIndicatorDataResponse(state[action.timePeriod], action),
      };
    default:
      return state;
  }
}

function techIndicatorByPair(state = {}, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.TECH_INDICATOR_DATA.REQUEST:
    case ActionTypes.TECH_INDICATOR_DATA.SUCCESS:
    case ActionTypes.TECH_INDICATOR_DATA.FAILURE:
      return {
        ...state,
        [action.pair]: techIndicatorByPairAndTimePeriod(state[action.pair], action),
      };
    default:
      return state;
  }
}

function techIndicatorData(state: TechIndicatorData = {}, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.TECH_INDICATOR_DATA.REQUEST:
    case ActionTypes.TECH_INDICATOR_DATA.SUCCESS:
    case ActionTypes.TECH_INDICATOR_DATA.FAILURE:
      return {
        ...state,
        [action.indicator.value]: techIndicatorByPair(state[action.indicator.value], action),
      };
    default:
      return state;
  }
}

function selectedEntity(state = 'btc', action: AnyAction) {
  switch (action.type) {
    case ActionTypes.SELECT_ENTITY:
      return action.entity;
    default:
      return state;
  }
}

function selectedTimePeriod(state: TimePeriod = '24H', action: AnyAction) {
  switch (action.type) {
    case ActionTypes.SELECT_TIME_PERIOD:
      return action.timePeriod;
    default:
      return state;
  }
}

function selectedFiatCurrency(state: Fiat = 'eur', action: AnyAction) {
  switch (action.type) {
    default:
      return state;
  }
}

function lastSelectedCryptoCurrency(state = 'btc', action: AnyAction) {
  switch (action.type) {
    case ActionTypes.UPDATE_CRYPTO_SELECTION:
      return action.crypto;
    default:
      return state;
  }
}

function tradingVolume(state: TradingVolume = {}, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.TRADE_VOLUME_DATA.SUCCESS:
      return {
        ...state,
        ...action.response.reduce((obj, val) => ({ ...obj, [val.entity.toLowerCase()]: val }), {}),
        //
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  selectedFiatCurrency,
  selectedEntity,
  techIndicatorData,
  selectedTimePeriod,
  lastSelectedCryptoCurrency,
  tradingVolume,
});

export default rootReducer;
