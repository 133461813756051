import { UserDetails } from 'types/resetPassword';
import { action } from './_common';
import { ApiError } from 'types/error';

export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE';
export const fetchUserDetailsRequest = (token: string) => action(FETCH_USER_DETAILS, { token });
export const fetchUserDetailsSuccess = (payload: UserDetails) =>
  action(FETCH_USER_DETAILS_SUCCESS, { userDetails: payload });
export const fetchUserDetailsFailure = () => action(FETCH_USER_DETAILS_FAILURE);

export const SKIP_RESET_PASSWORD_SECURITY_CODE = 'SKIP_RESET_PASSWORD_SECURITY_CODE';
export const skipPasswordResetSecurityCode = (token: string, userDetails: UserDetails) =>
  action(SKIP_RESET_PASSWORD_SECURITY_CODE, { token, userDetails });

export const SEND_RESET_PASSWORD_SECURITY_CODE_REQUEST = 'SEND_RESET_PASSWORD_SECURITY_CODE_REQUEST';
export const sendResetPasswordSecurityCodeRequest = ({ token, email }: { token: string; email: string }) =>
  action(SEND_RESET_PASSWORD_SECURITY_CODE_REQUEST, { token, email });

export const SEND_RESET_PASSWORD_SECURITY_CODE_SUCCESS = 'SEND_RESET_PASSWORD_SECURITY_CODE_SUCCESS';
export const sendResetPasswordSecurityCodeSuccess = (
  payload: {
    confirmationId: string;
    isSuccessful: boolean;
  },
  token: string,
  deviceData: string,
) =>
  action(SEND_RESET_PASSWORD_SECURITY_CODE_SUCCESS, {
    confirmationId: payload.confirmationId,
    token,
    deviceData,
  });

export const SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE = 'SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE';
export const sendResetPasswordSecurityCodeFailure = (payload: {
  error?: ApiError;
  token: string;
  email: string;
}) => action(SEND_RESET_PASSWORD_SECURITY_CODE_FAILURE, payload);

export const CONFIRM_RESET_PASSWORD_REQUEST = 'CONFIRM_RESET_PASSWORD_REQUEST';
export const CONFIRM_RESET_PASSWORD_SUCCESS = 'CONFIRM_RESET_PASSWORD_SUCCESS';
export const CONFIRM_RESET_PASSWORD_FAILURE = 'CONFIRM_RESET_PASSWORD_FAILURE';
export const confirmResetPasswordRequest = ({
  newPassword,
  confirmPassword,
  securityCode,
}: {
  newPassword: string;
  confirmPassword: string;
  securityCode?: string;
}) =>
  action(CONFIRM_RESET_PASSWORD_REQUEST, {
    form: {
      newPassword,
      newPasswordConfirm: confirmPassword,
      code: securityCode,
    },
  });
export const confirmResetPasswordSuccess = () => action(CONFIRM_RESET_PASSWORD_SUCCESS);
export const confirmResetPasswordFailure = () => action(CONFIRM_RESET_PASSWORD_FAILURE);

export const PASSWORD_RESET_FLOW_FINISH = 'PASSWORD_RESET_FLOW_FINISH';
export const passwordResetFlowFinish = () => action(PASSWORD_RESET_FLOW_FINISH);
