import { ApiError } from 'types/error';
import { action } from './_common';

export const SEND_CONFIRM_EMAIL_REQUEST = 'SEND_CONFIRM_EMAIL_REQUEST';
export const sendEmailConfirmRequest = (token: string) => action(SEND_CONFIRM_EMAIL_REQUEST, { token });

export const SEND_CONFIRM_EMAIL_SUCCESS = 'SEND_CONFIRM_EMAIL_SUCCESS';
export const sendEmailConfirmSuccess = (payload: { isSuccessful: boolean; username: string }) =>
  action(SEND_CONFIRM_EMAIL_SUCCESS, { payload });

export const SEND_CONFIRM_EMAIL_FAILURE = 'SEND_CONFIRM_EMAIL_FAILURE';
export const sendEmailConfirmFailure = (payload: { error?: ApiError }) =>
  action(SEND_CONFIRM_EMAIL_FAILURE, { payload });

// email change confirmation (initiated from customer support)
export const SEND_CONFIRM_EMAIL_CHANGE_REQUEST = 'SEND_CONFIRM_EMAIL_CHANGE_REQUEST';
export const sendEmailChangeConfirmRequest = (payload: { token: string; currentEmail: string }) =>
  action(SEND_CONFIRM_EMAIL_CHANGE_REQUEST, payload);

export const SEND_CONFIRM_EMAIL_CHANGE_SUCCESS = 'SEND_CONFIRM_EMAIL_CHANGE_SUCCESS';
export const sendEmailChangeConfirmSuccess = (payload: { isSuccessful: boolean; username: string }) =>
  action(SEND_CONFIRM_EMAIL_CHANGE_SUCCESS, { payload });

export const SEND_CONFIRM_EMAIL_CHANGE_FAILURE = 'SEND_CONFIRM_EMAIL_CHANGE_FAILURE';
export const sendEmailChangeConfirmFailure = (payload: { error?: ApiError }) =>
  action(SEND_CONFIRM_EMAIL_CHANGE_FAILURE, { payload });
