import { createAction } from '@reduxjs/toolkit';
import { InboxCursorType } from 'common/const/inbox';
import { InboxUnreadCount, Message, MessageWithDocument } from 'types/inbox';

export const triggerFetchMessagesByCategory = createAction<{ cursorDirection: InboxCursorType }>(
  'triggerFetchMessagesByCategory',
);

export const inboxRefreshRequest = createAction('inboxRefreshRequest');

export const inboxRefreshSuccess = createAction<{ messages: Message[]; inboxCount: number }>(
  'inboxRefreshSuccess',
);
export const inboxRefreshFailure = createAction<{ errMsg: string }>('inboxRefreshFailure');

export const invalidateInbox = createAction('invalidateInbox');

export const fetchTotalUnreadCountRequest = createAction('fetchTotalUnreadCountRequest');

export const fetchTotalUnreadCountSuccess = createAction<{ numOfUnread: InboxUnreadCount }>(
  'fetchTotalUnreadCountSuccess',
);
export const fetchTotalUnreadCountFailure = createAction<{ errMsg: string }>('fetchTotalUnreadCountFailure');

export const visitInboxPage = createAction('visitInboxPage');

export const leaveInboxPage = createAction('leaveInboxPage');

// inbox
export const inboxRequest = createAction<{ cursorDirection: InboxCursorType }>('messages/request');

export const inboxSuccess = createAction<{ messages: Message[]; inboxCount: number }>('messages/success');

export const inboxFailure = createAction<{ errMsg: string }>('inbox/error');

export const deleteMessageRequest = createAction<{ id: string; notifyUser: boolean }>('deleteMessageRequest');

export const deleteMessageSuccess = createAction<{
  id: string;
  notifyUser: boolean;
}>('deleteMessageSuccess');

export const deleteMessageError = createAction<{ errMsg: string }>('deleteMessageError');

export const readMessageRequest = createAction<{ id: string; notifyUser: boolean }>('readMessageRequest');

export const readMessageSuccess = createAction<{ id: string; notifyUser: boolean }>('readMessageSuccess');

export const readMessageError = createAction<{ errMsg: string }>('readMessageError');

export const editAllMessagesRequest = createAction<{ isRead?: boolean; isDeleted?: boolean }>(
  'editAllMessagesRequest',
);

export const editAllMessagesSuccess = createAction<{ isRead?: boolean; isDeleted?: boolean }>(
  'editAllMessagesSuccess',
);

// postbox
export const documentsRequest = createAction<{ cursorDirection: InboxCursorType }>(
  'messagesWithDocuments/request',
);

export const documentsFailure = createAction<{ errMsg: string }>('messagesWithDocuments/error');
export const documentsSuccess = createAction<{ messages: MessageWithDocument[]; totalPostbox: number }>(
  'messagesWithDocuments/success',
);

export const readMessageWithDocumentRequest = createAction<{
  id: string;
  notifyUser?: boolean;
  refreshLink?: boolean;
  readWithoutLooking?: boolean;
}>('readMessageWithDocumentRequest');

export const readMessageWithDocumentSuccess = createAction<{
  document: MessageWithDocument;
  refreshLink?: boolean;
  readWithoutLooking?: boolean;
}>('readMessageWithDocumentSuccess');

export const readMessageWithDocumentError = createAction<{ errMsg: string }>('readMessageWithDocumentError');

export const readAllDocumentsRequest = createAction('readAllDocumentsRequest');

export const readAllDocumentsSuccess = createAction('readAllDocumentsSuccess');

export const readAllDocumentsError = createAction('readAllDocumentsError');

export const clearSingleMessageWithDocument = createAction('clearSingleMessageWithDocument');
