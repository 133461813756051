import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import boerseLogo from 'assets/img/boerse_stuttgart.png';
import bisonLogo from 'assets/img/footer-logo.svg';
import { Theme } from 'types/theme';

const BaseContainer = styled.div`
  padding-top: 0;
  padding-bottom: ${dimensions.mediumPadding}px;
`;

export const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.footer};
  padding-top: 22px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: ${dimensions.largePadding}px;

  @media ${devices.tablet} {
    padding-top: ${dimensions.hugePadding}px;
  }

  ${({ withMobileMenu }: { withMobileMenu: boolean }) =>
    withMobileMenu &&
    `
    @media ${devices.maxTablet} {
      margin-bottom: ${dimensions.mobileMenuTablet}px;
    }
    @media ${devices.maxMobileL} {
      margin-bottom: ${dimensions.mobileMenu}px;
    }
    @media ${devices.maxMobileS} {
      margin-bottom: 0;
    }
  `}
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media ${devices.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Section = styled.div`
  text-align: left;
`;

export const SectionLogo = styled.div`
  padding-left: 5px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.logo};
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  order: 1;
  display: flex;
  justify-content: space-between;
  @media${devices.tablet} {
    padding-top: 0;
    display: block;
    order: 2;
    margin-left: auto;
    padding-right: ${dimensions.standardPaddings}px;
  }
`;

export const SectionHeader = styled(BaseContainer).attrs({
  className: 'xlarge-text strong-weight',
})`
  margin-top: 44px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.header};
  padding-bottom: 24px;
  @media ${devices.tablet} {
    margin-top: 0;
  }
`;

export const BoerseImg = styled.img.attrs({ src: boerseLogo })`
  display: block;
  width: 68px;
  height: auto;
  padding-top: 9px;
  @media ${devices.tablet} {
  }
`;

export const BisonImg = styled.img.attrs({ src: bisonLogo })`
  display: block;
  width: 105px;
  height: auto;
  align-self: flex-start;
  padding-top: 8px;
  @media ${devices.tablet} {
    padding-top: 5px;
    padding-bottom: 25px;
  }
`;

export const MenuItem = styled.div.attrs({ className: 'small-text medium-weight' })`
  margin-bottom: ${dimensions.tinyPadding}px;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.footer.text};
`;

export const FooterItems = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0.7;
  flex-direction: column;
  align-items: start;
  order: 2;
  @media${devices.tablet} {
    flex-direction: row;
    order: 1;
  }
  @media ${devices.laptop} {
    flex: 0.78;
  }
`;

export const TwoSectionsWrapper = styled.div`
  & > *:last-child {
    margin-top: 43px;
  }
`;

export default FooterContainer;
