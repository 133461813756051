/* eslint-disable import/prefer-default-export, @typescript-eslint/no-unsafe-return */

import { OrdersState } from 'store/types/orders';

export const getTradeTypeFromUrl = (_, props: any) => props.match.params.type;

export const getReservation = ({ orders }: { orders: OrdersState }) => orders.reservation;

export const getOrderTypeData = ({ orders }: { orders: OrdersState }) => ({
  isBuyOrder: orders.isBuyOrder,
  entity: orders.entity,
  currency: orders.currency,
});

export const getCurrentAmount = ({ orders }: { orders: OrdersState }) => ({
  amount: { value: orders.quote.orderPreview.value, volume: orders.quote.orderPreview.volume },
  amountType: orders.isFocusOnValue ? 'value' : 'volume',
  validAmounts: orders.validAmounts,
});

export const getFocusData = ({ orders }: { orders: OrdersState }) => ({
  fieldInFocus: orders.fieldInFocus,
  lastEditedField: orders.lastEditedField,
});

export const getReservationOpen = ({ orders }: { orders: OrdersState }) => orders.reservedPricePopupShow;

export const getQuotePreview = ({ orders }: { orders: OrdersState }) => orders.quote.orderPreview;
