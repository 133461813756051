import { paths } from 'common/urls';
import { $Values } from 'utility-types';
import { InboxCategory } from 'types/inbox';

export const IMPORTANT_MESSAGES_MODAL_ALLOWED_PAGES = [
  paths.PORTFOLIO,
  paths.PORTFOLIO_CRYPTO,
  paths.MARKET_OVERVIEW,
  paths.PERSONAL_INFO,
  paths.TRANSACTION_HISTORY,
];

export enum TTL {
  INBOX = 120000,
  INBOX_COUNT = 120000,
  TIMEOUT_ON_ERROR = 180000,
}

export const INBOX_CURSOR_DIRECTIONS = {
  // default cursor  will fetch older messages that specified in reference field
  DEFAULT: 'Default',
  // refresh cursor will fetch fresher messages that specified in reference field
  REFRESH: 'Refresh',
} as const;

export type InboxCursorType = $Values<typeof INBOX_CURSOR_DIRECTIONS>;
export const inboxTypes: InboxCategory[] = ['notifications', 'documents'];
