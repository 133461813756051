import { createSearchParams, useNavigate } from 'react-router-dom';

const useSearchParamsNavigate = (): ((path: string, params: Record<any, string>) => void) => {
  const navigate = useNavigate();

  return (path: string, params: Record<any, string>) => {
    if (!params) {
      navigate(path);
      return;
    }

    const searchParams = createSearchParams(params).toString();

    if (!searchParams || !searchParams.length) {
      navigate(path);
      return;
    }

    navigate(`${path}?${searchParams}`);
  };
};

export default useSearchParamsNavigate;
