import { State } from 'store/types/store';
import { EditTaxExemptionData, TaxExemptionData } from 'types/taxExemption';

export const getTaxExemption = (state: State): TaxExemptionData =>
  state.taxExemption.fetchedTaxExemption.data;

export const getIsFetching = (state: State): boolean => state.taxExemption.fetchedTaxExemption.isFetching;

export const getEditedTaxExemption = (state: State): EditTaxExemptionData =>
  state.taxExemption.editTaxExemptionData.data;
