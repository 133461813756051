import portfolioSlice from '.';
import { createControlActions, createControlTypes } from 'store/actions/_common';

export const { fetchPortfolioRequest, fetchPortfolioSuccess, fetchPortfolioFailure, invalidatePortfolio } =
  portfolioSlice.actions;

export type FetchPortfolioSuccess = ReturnType<typeof fetchPortfolioSuccess>;

export const SYNC_PORTFOLIO = createControlTypes('syncPortfolio');
export const syncPortfolio = createControlActions(SYNC_PORTFOLIO);
