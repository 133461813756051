import i18next from 'i18next';
import * as Yup from 'yup';
import { OrderOption, BUY_SELL_VALUES } from 'common/const';
import { isEqualIgnoreCase } from 'common/utils/common';
import { getCurrencyLabels } from 'common/utils/tradingRulesUtils';
import { GetLabels, GetValidationSchemaType } from 'types/tradingRules';
import { SOLabelsBag, SOValidationSchema } from './types';

export const getValidationSchema: GetValidationSchemaType<SOValidationSchema> = (
  assetCode: string,
  portfolioCash,
  cryptoInstruments,
  isBuy,
) => {
  if (isBuy)
    return Yup.object().shape({
      stopPrice: Yup.number().positive(i18next.t('automation.fieldErrors.web_zero')),
      cryptoAmount: Yup.number().positive(i18next.t('automation.fieldErrors.web_zero')),
      spendAmount: Yup.number()
        .positive(i18next.t('automation.fieldErrors.web_zero'))
        .max(portfolioCash.volume, i18next.t('stopOrders.fieldErrors.web_notEnoughFiat')),
    });

  const selectedCryptoData = cryptoInstruments.find((instrument) =>
    isEqualIgnoreCase(instrument.entity, assetCode),
  );
  const availableVolume = selectedCryptoData?.volume ?? 0;

  return Yup.object().shape({
    stopPrice: Yup.number().positive(i18next.t('automation.fieldErrors.web_zero')),
    cryptoAmount: Yup.number()
      .positive(i18next.t('stopOrders.fieldErrors.amountRequired'))
      .max(
        availableVolume,
        i18next.t('stopOrders.fieldErrors.web_notEnoughCurrencyToBuy', {
          crypto: assetCode.toUpperCase(),
        }),
      ),
    spendAmount: Yup.number().positive(i18next.t('automation.fieldErrors.web_zero')),
  });
};

const getCommonLabels = (type: OrderOption): SOLabelsBag => ({
  stopPrice: i18next.t('stopOrders.detail.setStopPrice'),
  cryptoAmount: i18next.t(`stopOrders.detail.cryptoAmount.${type.toLowerCase()}`),
  spendAmount: i18next.t(`stopOrders.detail.euroAmount.${type.toLowerCase()}`),
});

export const getSellLabels: GetLabels<SOLabelsBag> = (selectedFiatCurrency, selectedCrypto: string) => {
  const currencyLabels = getCurrencyLabels(selectedFiatCurrency, selectedCrypto, 'stopPrice') as SOLabelsBag;

  const labelTexts: SOLabelsBag = getCommonLabels(BUY_SELL_VALUES.SELL);
  return { currencyLabels, labelTexts };
};

export const getBuyLabels: GetLabels<SOLabelsBag> = (selectedFiatCurrency, selectedCrypto) => {
  const currencyLabels = getCurrencyLabels(selectedFiatCurrency, selectedCrypto, 'stopPrice') as SOLabelsBag;

  const labelTexts: SOLabelsBag = getCommonLabels(BUY_SELL_VALUES.BUY);
  return { currencyLabels, labelTexts };
};
