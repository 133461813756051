import { StakingState } from 'types/staking';
import { stakingRewardAdapter } from './adapter';

export const getRewardsHistoryEntitiesInitialState = () => stakingRewardAdapter.getInitialState();

export const initialState: StakingState = {
  overview: {
    isLoading: false,
    info: {},
    lastFetchedAt: null,
  },
  chart: {
    isFetching: false,
    items: [],
  },
  stakeUnstake: {
    isLoading: false,
    type: undefined,
    isSuccess: undefined,
  },
  rewardsHistory: {
    ...getRewardsHistoryEntitiesInitialState(),
    loading: false,
    fullyLoaded: false,
    reference: undefined,
  },
  nextRewardInfo: {},
  stakingBlockedModalStatus: {
    isOpened: false,
    type: undefined,
  },
  pendingOrders: {
    isLoading: false,
    items: [],
  },
};
