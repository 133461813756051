import { createAction } from '@reduxjs/toolkit';
import { AuditProofData } from 'types/auditProof';
import { AmlConfirmationStatus } from 'types/state';
import { AjaxError } from 'rxjs/ajax';

export const submitAuditProofRequest = createAction<{
  employmentStatus: string;
  professionalField: string | null;
  birthDate: string | null;
  onSuccess: (response: AuditProofData) => void;
  onError: (err: AjaxError) => void;
}>('SUBMIT_AUDIT_PROOF_REQUEST');

export const submitAuditProofSuccess = createAction<{
  change: Record<string, any>;
  submitToken: string;
  needsConfirmation: boolean;
}>('SUBMIT_AUDIT_PROOF_SUCCESS');

export const submitAuditProofError = createAction<{ error: AjaxError }>('SUBMIT_AUDIT_PROOF_ERROR');

export const confirmTwoFaAuditProofRequest = createAction<{
  code2fa: string | null;
  data: AuditProofData;
  onSuccess: () => void;
  onError: (err: AjaxError) => void;
  onWrongTwoFa?: () => void;
}>('CONFIRM_TWO_FA_AUDIT_PROOF_REQUEST');

export const confirmTwoFaAuditProofSuccess = createAction('CONFIRM_TWO_FA_AUDIT_PROOF_SUCCESS');

export const confirmTwoFaAuditProofError = createAction<{ error: string }>(
  'CONFIRM_TWO_FA_AUDIT_PROOF_ERROR',
);

export const changeAmlStatus = createAction<{ confirmationStatus: AmlConfirmationStatus }>(
  'CHANGE_AML_STATUS',
);
