import { CRYPTO_PRICE_CHANGE_DATA_TTL, SECURITY_PRICE_CHANGE_DATA_TTL } from 'common/const/priceChanges';
import { PriceChangeData, PriceChangeEntity } from 'store/types/priceChanges';
import { ASSET_CLASSES, AssetClass } from 'types/assets';
import { TimePeriod } from 'types/currency';

// PUBLIC METHODS

/**
 * Calculates if the given price change's data is old and is ready to be re-fetched.
 *
 * @summary Calculates if price change is stale.
 *
 * @public
 * @param {number} lastFetchedDate
 * @param {AssetClass} assetClass
 * @returns {boolean}
 */
export const isPriceChangeEntityEligibleForFetching = (
  lastFetchedDate: number,
  assetClass: AssetClass,
): boolean =>
  assetClass === ASSET_CLASSES.SECURITY
    ? lastFetchedDate + SECURITY_PRICE_CHANGE_DATA_TTL < new Date().getTime()
    : lastFetchedDate + CRYPTO_PRICE_CHANGE_DATA_TTL < new Date().getTime();

/**
 * Returns price change entity's unique id which is composed from unique code and timePeriod.
 *
 * @summary Calculates price change entity's id.
 *
 * @public
 * @param {PriceChangeEntity} priceChange
 * @returns {string}
 */
export const getPriceChangeEntityId = (priceChange: Pick<PriceChangeEntity, 'assetCode' | 'timePeriod'>) =>
  `${priceChange.assetCode.toLowerCase()}_${priceChange.timePeriod.toString().toLowerCase()}`;

/**
 * Converts an array of price change data to price change entities. Asset class, time period and last fetched date are added.
 * Added data is then later used to calculate if a specific price change entity is stale (old).
 *
 * @summary Converts an array of price change data to an array of price change entity.
 *
 * @public
 * @param {PriceChangeData[]} priceChangeDataItems
 * @param {AssetClass} assetClass
 * @param {TimePeriod} timePeriod
 * @returns {PriceChangeEntity[]}
 */
export const priceChangeDataItemsToPriceEntityItems = (
  priceChangeDataItems: PriceChangeData[],
  assetClass: AssetClass,
  timePeriod: TimePeriod,
): PriceChangeEntity[] =>
  priceChangeDataItems.map((priceData) => priceChangeDataToPriceEntity(priceData, assetClass, timePeriod));

// PRIVATE METHODS

const priceChangeDataToPriceEntity = (
  priceChangeData: PriceChangeData,
  assetClass: AssetClass,
  timePeriod: TimePeriod,
): PriceChangeEntity => ({
  ...priceChangeData,
  assetClass,
  lastFetched: new Date().getTime(),
  timePeriod,
});
