import filtersSlice from '.';

export const {
  onMarketOverviewUnmount,
  selectInboxCategory,
  setMarketOverviewCategory,
  setMarketOverviewOrderBy,
  setMarketOverviewSearchText,
  setMarketOverviewVisibleItems,
  resetMarketOverviewFilter,
} = filtersSlice.actions;
