import React, { FunctionComponent, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';
import AssetStakingImage from 'common/components/AssetStakingImage';
import { UNSTAKING_PERIOD_DAYS } from 'common/const/staking';
import { ModalProps } from 'types/modals';
import { Header, ImageWrapper, Title } from '../Staking/StakingFinishModal/styles';
import { Wrapper } from './styles';

type Props = {
  onProceed: () => void;
  error: boolean;
  assetCode: string;
  amount: string;
  assetColor: string;
} & ModalProps;

const UnstakingFinishModal: FunctionComponent<Props> = ({
  amount,
  assetCode,
  error,
  onProceed,
  assetColor,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen
      persist
      onCancel={onClose}
      header={
        <Header>
          <ImageWrapper $error={error}>
            <AssetStakingImage color={assetColor} />
          </ImageWrapper>
        </Header>
      }
      actions={
        <Button
          inverted
          size="modal"
          title={t('staking.finishPopup.proceed')}
          onClick={() => {
            onProceed();
            onClose();
          }}
        />
      }
    >
      <Wrapper>
        <Title>
          {t(`unstaking.finishModal.${error ? 'titleError' : 'titleSuccess'}`, {
            amount,
            assetCode: assetCode.toUpperCase(),
          })}
        </Title>
        <ReactMarkdown>
          {t(`unstaking.finishModal.${error ? 'textError' : 'textSuccess'}`, {
            amount,
            assetCode: assetCode.toUpperCase(),
            start: UNSTAKING_PERIOD_DAYS.START,
            end: UNSTAKING_PERIOD_DAYS.END,
          })}
        </ReactMarkdown>
      </Wrapper>
    </ModalTemplate>
  );
};

export default memo(UnstakingFinishModal);
