/* eslint-disable @typescript-eslint/no-unsafe-return */
import { State } from 'store/types/store';
import { WinnerLoserItem } from 'types/winnersLosers';
import { getWinnersAndLosersTypeFromGroup } from '../remoteData/selectors';
import { getIsWinnerLoserModalVisited } from 'store/selectors/settings';
import { getIsUserInPaperTrading } from 'store/selectors/auth';

export const getWinnersLosersType = (state: State) => state.experiments.winnersLoser.type;

export const getWinnersLosersItemByOrderNumber = (
  state: State,
  index: number,
): WinnerLoserItem | undefined => {
  const assets = state.experiments.winnersLoser.assets;

  if (index > assets.length) return undefined;

  return assets[index - 1];
};

export const getIsWinnerLoserModalShown = (state: State) =>
  !!getWinnersAndLosersTypeFromGroup(state) &&
  !state.experiments.winnersLoser.isLoading &&
  !getIsWinnerLoserModalVisited(state) &&
  !getIsUserInPaperTrading(state);
