/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AnyAction } from 'redux';
import { action } from './_common';

export const STATUS_PAGE_CHANGED = 'STATUS_PAGE_CHANGED';
export const PARENT_LOCATION_CHANGED = 'PARENT_LOCATION_CHANGED';
export const SET_NEW_SESSION_ID = 'SET_NEW_SESSION_ID';
export const WATCH_SESSION_TIMEOUT = 'WATCH_SESSION_TIMEOUT';
export const WATCH_SESSION_VALIDITY = 'WATCH_SESSION_VALIDITY';
export const LOG_NEW_SCREEN_OPEN = 'LOG_NEW_SCREEN_OPEN';
export const LOG_ORDER_RESERVATION = 'LOG_ORDER_RESERVATION';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const RESET_ACCOUNT_DELETED = 'RESET_ACCOUNT_DELETED';

export const statusPageChanged = (args: any): AnyAction => action(STATUS_PAGE_CHANGED, { ...args });

export const parentLocationChanged = (args: any): AnyAction => action(PARENT_LOCATION_CHANGED, { ...args });

export const setNewSessionId = (): AnyAction => action(SET_NEW_SESSION_ID);

export const watchSessionTimeout = (): AnyAction => action(WATCH_SESSION_TIMEOUT);

export const watchSessionValidity = (): AnyAction => action(WATCH_SESSION_VALIDITY);

export const logNewScreenOpen = (args: any): AnyAction => action(LOG_NEW_SCREEN_OPEN, args);

export const logOrderReservation = (args: any): AnyAction => action(LOG_ORDER_RESERVATION, args);

export const accountDeleted = (): AnyAction => action(ACCOUNT_DELETED);

export const resetAccountDeleted = (): AnyAction => action(RESET_ACCOUNT_DELETED);
