import { setNavigate } from 'common/utils/router';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useEpicNavigate = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);
};

export default useEpicNavigate;
