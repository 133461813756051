export const getDayOfYear = () => {
  const currentDate = new Date();

  return Math.floor((currentDate.getTime() - new Date(currentDate.getFullYear(), 0, 1).getTime()) / 86400000);
};

export const defineWeekOfYear = () => {
  const dayOfYear = getDayOfYear();

  return `W${Math.ceil(dayOfYear / 7)}`;
};

export const isDateInGivenWeek = (weekStr: string) => {
  const weekNumber = +weekStr.slice(1);

  const dayOfYear = getDayOfYear();

  const currentWeekNumber = Math.ceil(dayOfYear / 7);

  return currentWeekNumber === weekNumber;
};

export const isDateInWeeks = (weeks: string | null) => {
  if (!weeks) return false;

  const weeksArr = weeks.split(',');

  return weeksArr.some(isDateInGivenWeek);
};

export const composeWeeksArray = (weeks: string | null, addedWeek: string): string => {
  if (!weeks) return addedWeek;

  const weeksArr = weeks.split(',');
  if (weeksArr.includes(addedWeek)) return weeks;

  return [...weeksArr, addedWeek].join();
};

const AMOUNT_OF_RAFFLE_WEEKS = 2;

export const getWeeksBetweenDates = (startDate: Date, endDate: Date) => {
  const weeks = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const startOfWeek = new Date(currentDate);
    const endOfWeek = new Date(currentDate);

    // Move to the start of the current week (Monday)
    startOfWeek.setDate(currentDate.getDate() - (currentDate.getDay() - 1));

    // Move to the end of the current week (Sunday)
    endOfWeek.setDate(currentDate.getDate() + (7 - currentDate.getDay()));

    // Calculate the week number

    const weekNumber = Math.ceil(
      Math.floor((currentDate.getTime() - new Date(currentDate.getFullYear(), 0, 1).getTime()) / 86400000) /
        7,
    );
    if (weeks.length < AMOUNT_OF_RAFFLE_WEEKS) {
      weeks.push(`W${weekNumber}`);
    }

    currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
  }

  return weeks.join();
};
