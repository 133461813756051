import styled from 'styled-components';
import { dimensions } from 'common/styles';
import { Theme } from 'types/theme';
import _Button from 'common/components/Button';
import { getTextStyles } from 'common/styles/global';

export const Wrapper = styled.div`
  min-height: 464px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Headline = styled.div.attrs({
  className: 'strong-weight xlarge-text',
})`
  display: flex;
  align-items: center;
  gap: ${dimensions.smallIndent}px;
  margin-bottom: ${dimensions.standardPaddings}px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputLabel = styled.div.attrs({
  className: 'standard-text',
})`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const AvailableAmount = styled.div.attrs({
  className: 'small-text',
})`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.disabledText};
`;

export const InputWrapper = styled.div`
  margin-bottom: 59px;
`;

export const Button = styled(_Button)`
  margin-top: auto;
`;

export const ButtonMax = styled(_Button)`
  width: 47px;
  min-width: auto;
  height: 28px;
  border-radius: 4px;
  background: ${({ theme }: { theme: Theme }) => theme.color.background.inputAdornment};
  margin: 10px 0;

  & > * {
    color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused} !important;
    ${({ theme }: { theme: Theme }) => getTextStyles('xxsmall', theme)};
    font-weight: 600;
  }
`;
