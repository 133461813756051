import { deviceConsentPairHash } from 'common/utils/deviceConsentPairHash';
import { RootAction } from 'types/common';
import * as ActionTypes from '../actions/deviceMonitoring';
import { DeviceMonitoringState } from 'store/types/deviceMonitoring';

const initialState: DeviceMonitoringState = {
  isDeviceMonitoringScriptLoaded: false,
  isDeviceMonitoringConsentOpen: false,
  consentModel: undefined,
  deviceConsents: {},
  consentState: undefined,
};

function root(state: DeviceMonitoringState = initialState, action: RootAction): DeviceMonitoringState {
  switch (action.type) {
    case ActionTypes.DEVICE_MONITORING_SCRIPT_LOADED:
      return { ...state, isDeviceMonitoringScriptLoaded: true };
    case ActionTypes.DEVICE_MONITORING_INIT:
      return {
        ...state,
        consentModel: action.consentModel,
        isDeviceMonitoringConsentOpen: true,
      };
    case ActionTypes.DEVICE_CONSENT_ID: {
      const { username, deviceConsentId } = action;
      const deviceConsentPairHashKey = deviceConsentPairHash(username);
      return {
        ...state,
        isDeviceMonitoringConsentOpen: false,
        deviceConsents: deviceConsentPairHashKey
          ? {
              ...state.deviceConsents,
              [deviceConsentPairHashKey]: deviceConsentId,
            }
          : { ...state.deviceConsents },
      };
    }

    case ActionTypes.DEVICE_CONSENT_ID_ERROR:
    case ActionTypes.DEVICE_DATA_END:
      return {
        ...state,
        consentModel: undefined,
        isDeviceMonitoringConsentOpen: false,
      };

    case ActionTypes.DEVICE_MONITORING_CONSENT_MODEL_SUCCESS:
      return {
        ...state,
        consentModel: action.res?.consentModel,
        isDeviceMonitoringConsentOpen:
          action.res?.consentModel?.toLowerCase() !== 'Unspecified'.toLowerCase(),
      };
    case ActionTypes.DEVICE_MONITORING_CONSENT_STATE_SUCCESS:
      return {
        ...state,
        consentState: action.res?.consentState,
      };
    case ActionTypes.DEVICE_MONITORING_CONSENT_DECLINE_SUCCESS: {
      const { consentId } = action;
      const oldDeviceConsents = { ...state.deviceConsents };

      Object.entries(oldDeviceConsents).forEach(([key, value]) => {
        if (value !== consentId) return;
        delete oldDeviceConsents[key];
      });

      return {
        ...state,
        deviceConsents: oldDeviceConsents,
        consentState: 'Declined',
      };
    }

    default:
      return state;
  }
}

export default root;
