import jwtDecode from 'jwt-decode';

import { accountTypes, kycStatuses } from 'common/const';
import * as ActionTypes from 'store/actions/auth';
import * as FormsActionTypes from 'store/actions/forms';
import * as RenewKycDocsActionTypes from 'store/actions/renewKycDocs';
import { AnyAction } from 'redux';
import * as resetPasswordActions from 'store/actions/resetPassword';
import * as amlActions from 'store/actions/auditProof';
import { AuthState } from 'store/types/auth';
import * as emailConfirmActions from 'store/actions/emailConfirm';

// import * as FormsActionTypes from 'store/actions/forms';

const initialState: AuthState = {
  initialized: false, // set after the first attempt to ssoLogin
  loggedIn: false,
  loggingIn: false,
  loggingInAnonymously: false,
  autoLogin: true, // we rely on this to show/hide the loading indicator, `true` by default
  splash: false,
  errorMsg: '',
  fetchingProfile: false,
  triggerRegisterModal: false,
  resendingConfirmationEmail: false,
  isWaitingConfirmation: false,
  changingAccount: false,
  authInitError: false,
  isAccessAllowed: true,
  devicePairing2fa: false,
  twofa: {},
  submitting_2fa: false,
  renewKycCode: undefined,

  user: {
    id: undefined,
    mobileNumber: '',
    username: '',
    name: undefined,
    guid: undefined,
    isFirstLogin: true,
    hasTokensInKeychain: false,
    tokensInvalidated: false,
    needsToRecheckCheckboxes: false,
    accountType: accountTypes.paper, // Default account type on first login
    roles: [],
    kycStatus: kycStatuses.NotStarted,
    kycRenewStatus: kycStatuses.NotStarted,
    stocksKnowledgeLevel: undefined,
    stocksQuestionsLastAnsweredDate: '',
    newsletter: false,
    firstName: '',
    lastName: '',
    taxResidency: [],
    address: {
      line1: undefined,
      line2: undefined,
      postalCode: undefined,
      city: undefined,
      country: undefined,
      state: undefined,
    },
    isKycRenewRequired: false,
    stocksTradingAccountNumber: undefined,
    stocksUserAccountStatus: kycStatuses.NotStarted,
    professionalField: undefined,
    employmentStatus: undefined,
    amlUpdate: {
      status: 'Unspecified',
      followUpDate: '',
      countdown: 0,
      confirmationStatus: 'Unspecified',
      confirmedAt: '',
    },
  },
  tokens: {
    accessToken: undefined,
    clientKey: undefined,
    isRefreshing: false,
    deviceId: undefined,
  },
};

export default function auth(state: AuthState = initialState, action: AnyAction) {
  switch (action.type) {
    case FormsActionTypes.CHANGE_SECURITIES_KNOWLEDGE.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          stocksKnowledgeLevel: action.response.stocksKnowledgeLevel,
        },
      };

    case FormsActionTypes.SECURITY_ADDITIONAL_INFO.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.response,
        },
      };

    case ActionTypes.WAIT_CONFIRMATION:
      return {
        ...state,
        isWaitingConfirmation: action.displayWaitingScreen,
        loggingIn: true,
        user: {
          ...state.user,
          username: action.username,
          accountType: accountTypes.paper,
        },
      };

    case ActionTypes.CANCEL_WAIT_CONFIRMATION:
      return {
        ...state,
        isWaitingConfirmation: false,
        loggingIn: false,
      };

    case FormsActionTypes.CHANGE_SECURITIES_KYC_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          stocksUserAccountStatus: action.status,
        },
      };

    case FormsActionTypes.SECURITIES_KYC_FORM_SUBMIT.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          stocksUserAccountStatus: action.response.stockAccountStatus,
        },
      };

    case ActionTypes.CONFIRMATION_MAIL.REQUEST:
      return {
        ...state,
        resendingConfirmationEmail: true,
      };

    case ActionTypes.CONFIRMATION_MAIL.SUCCESS: // fallthrough
    case ActionTypes.CONFIRMATION_MAIL.FAILURE:
      return { ...state, resendingConfirmationEmail: false };

    case ActionTypes.RESET_ERROR_MESSAGE:
      return { ...state, errorMsg: '' };

    case ActionTypes.LOCAL_AUTH.REQUEST:
      return { ...state, errorMsg: '' };
    case ActionTypes.AUTH.REQUEST: // starts logging in by credentials
      return {
        ...state,
        errorMsg: '',
        loggingIn: true,
      };
    case ActionTypes.TOKEN_VALIDATION.REQUEST: // starts logging in after successful local auth
      return { ...state, errorMsg: '', loggingIn: true };
    case ActionTypes.TRY_ANONYMOUS:
      return { ...state, errorMsg: '', loggingInAnonymously: true };

    case ActionTypes.CHANGE_ACCOUNT.REQUEST:
      return {
        ...state,
        changingAccount: true,
      };
    case ActionTypes.CHANGE_ACCOUNT.FAILURE:
      return {
        ...state,
        changingAccount: false,
        user: {
          ...state.user,
          username: action.username,
          accountType: action.newAccountType,
        },
      };
    case ActionTypes.AUTH_INIT_DONE:
      return {
        ...state,
        initialized: true,
        authInitError: false,
      };

    case ActionTypes.TOKEN_REFRESH.REQUEST:
      return {
        ...state,
        tokens: { ...state.tokens, isRefreshing: true },
      };

    case ActionTypes.AUTH.SUCCESS:
      return {
        ...state,
        isWaitingConfirmation: false,
        submitting_2fa: false,
        devicePairing2fa: false,
        user: {
          ...state.user,
          id: jwtDecode(action.response.accessToken).uid,
          username: jwtDecode(action.response.accessToken).uname,
          accountType: jwtDecode(action.response.accessToken).aty,
          guid: jwtDecode(action.response.accessToken).sub,
          tokensInvalidated: true, // received new client key, indicate to refresh keychain
        },
        tokens: {
          ...state.tokens,
          accessToken: action.response.accessToken,
          clientKey: action.response.clientKey,
        },
      };

    case ActionTypes.TOKEN_REFRESH.SUCCESS:
      return {
        ...state,
        changingAccount: false,
        user: {
          ...state.user,
          id: jwtDecode(action.response.accessToken).uid,
          username: jwtDecode(action.response.accessToken).uname,
          accountType: jwtDecode(action.response.accessToken).aty,
          guid: jwtDecode(action.response.accessToken).sub,
        },
        tokens: {
          ...state.tokens,
          accessToken: action.response.accessToken,
          isRefreshing: false,
        },
      };

    // user is logged in
    case ActionTypes.TOKEN_VALIDATION.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.response,
          firstName: action.response.firstName ? action.response.firstName : 'Bison',
          // If the user's kycStatus changes to Invalid, they need to recheck the checkboxes
          needsToRecheckCheckboxes:
            state.user.kycStatus !== kycStatuses.Invalid && action.response.kycStatus === kycStatuses.Invalid,
          newsletter: action.response.newsletter,
        },
      };

    case ActionTypes.FETCH_USER_KYC_STATUS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          // If the user's kycStatus changes to Invalid, they need to recheck the checkboxes
          needsToRecheckCheckboxes:
            state.user.kycStatus !== kycStatuses.Invalid && action.payload.kycStatus === kycStatuses.Invalid,
        },
      };
    case ActionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        autoLogin: false,
        loggingInAnonymously: false,
        loggedIn: true,
        isWaitingConfirmation: false,
      };

    case ActionTypes.LOCAL_AUTH.FAILURE:
    case ActionTypes.AUTH.FAILURE:
    case ActionTypes.TOKEN_VALIDATION.FAILURE:
      return {
        ...state,
        errorMsg: action.error.message,
        loggedIn: false,
        loggingIn: false,
        loggingInAnonymously: false,
        autoLogin: false,
        submitting_2fa: false,
      };

    case ActionTypes.TOKEN_REFRESH.FAILURE:
      return {
        ...state,
        errorMsg: action.error.message,
        changingAccount: false,
        loggingIn: false,
        tokens: { ...state.tokens, isRefreshing: false },
      };

    case ActionTypes.KEYCHAIN_SAVE.SUCCESS:
      return {
        ...state,
        user: { ...state.user, tokensInvalidated: false, hasTokensInKeychain: true },
      };

    case ActionTypes.KEYCHAIN_CLEAR.SUCCESS:
      return {
        ...state,
        loggedIn: false,
        user: { ...state.user, tokensInvalidated: false, hasTokensInKeychain: false },
      };

    case ActionTypes.KEYCHAIN_SAVE.FAILURE:
    case ActionTypes.KEYCHAIN_CLEAR.FAILURE:
      return {
        ...state,
        user: { ...state.user, tokensInvalidated: false },
      };

    case ActionTypes.AUTH_INIT_ERROR:
      return {
        ...state,
        authInitError: true,
      };
    case FormsActionTypes.CHANGE_USERNAME_CONFIRM.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          username: action.username,
        },
      };
    case ActionTypes.LOGOUT_USER:
    case ActionTypes.LOGOUT_USER_SUCCESS:
    case ActionTypes.AUTO_LOGIN_FAILED:
      return {
        ...state,
        isWaitingConfirmation: false,
        autoLogin: false,
        initialized: true,
        loggedIn: false,
      };
    case FormsActionTypes.MOBILE_NUMBER_ASSIGN.SUCCESS:
    case FormsActionTypes.MOBILE_NUMBER_DELETE.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.response,
        },
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        tokens: {
          ...((action.payload &&
            action.payload.tokens && { ...action.payload.tokens, isRefreshing: false }) ||
            state.tokens),
        },
      };
    case ActionTypes.START_REGISTRATION:
      return {
        ...state,
      };
    case ActionTypes.ALLOW_BETA_ACCESS:
      return {
        ...state,
        isAccessAllowed: action.allow,
      };
    case FormsActionTypes.PAIR_DEVICE_START:
      return {
        ...state,
        user: {
          ...state.user,
          username: action.username,
          accountType: action.accountType,
        },
        devicePairing2fa: true,
        twofa: {
          ...action.twofa,
          ...state.twofa,
        },
      };
    case FormsActionTypes.SUBMIT_2FA_CODE.REQUEST:
      return {
        ...state,
        submitting_2fa: true,
      };
    case FormsActionTypes.PAIR_DEVICE_STOP:
    case FormsActionTypes.SUBMIT_2FA_CODE.SUCCESS:
      return {
        ...state,
        devicePairing2fa: false,
        submitting_2fa: false,
        twofa: {},
      };

    case FormsActionTypes.PUT_NEW_ADDRESS_DATA:
      return {
        ...state,
        user: { ...state.user, address: { ...action.address } },
      };
    case RenewKycDocsActionTypes.START_KYC_RENEW_PROCESS_SUCCESS:
      return {
        ...state,
        renewKycCode: action.code,
      };
    case RenewKycDocsActionTypes.START_KYC_RENEW_PROCESS_FAILURE:
      return {
        ...state,
        user: { ...state.user, kycRenewStatus: 'Failed' },
      };
    case RenewKycDocsActionTypes.FETCH_USER_KYC_STATUS_RENEW_INFO_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isKycRenewRequired: action.renewRequired,
        },
      };
    case resetPasswordActions.FETCH_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          username: action.userDetails.username,
        },
      };
    }
    case resetPasswordActions.PASSWORD_RESET_FLOW_FINISH:
    case resetPasswordActions.CONFIRM_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          username: '',
        },
      };
    }
    case amlActions.changeAmlStatus.type: {
      return {
        ...state,
        user: {
          ...state.user,
          amlUpdate: {
            ...state.user.amlUpdate,
            confirmationStatus: action.payload.confirmationStatus,
          },
        },
      };
    }
    case emailConfirmActions.SEND_CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        emailConfirmation: action.payload,
      };
    case emailConfirmActions.SEND_CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        emailConfirmation: action.payload,
      };
    case emailConfirmActions.SEND_CONFIRM_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        emailChangeConfirmation: action.payload,
      };
    case emailConfirmActions.SEND_CONFIRM_EMAIL_CHANGE_FAILURE:
      return {
        ...state,
        emailChangeConfirmation: action.payload,
      };
    default:
      return state;
  }
}
