import styled from 'styled-components';
import { devices, dimensions } from 'common/styles';
import _Switch from 'common/components/Switch';
import { getTextStyles } from 'common/styles/global';
import { colors } from 'common/utils/theme/colors';
import { Theme } from 'types/theme';

export const Container = styled.div<{ isBannerHidden: boolean }>`
  border-radius: 6px;
  left: 0;
  width: 100%;
  height: ${dimensions.paperTradingBannerHeight}px;
  background-color: ${colors.green};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
  .banner-switch {
    border-color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
  }
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);

  ${({ isBannerHidden }: { isBannerHidden: boolean }) => isBannerHidden && 'opacity: 0; z-index: 0;'}
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: ${dimensions.maxContentWidth}px;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  @media ${devices.tablet} {
    padding: 0 ${dimensions.mediumPadding}px;
  }
`;

export const Text = styled.span.attrs({ className: 'strong-weight' })`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primaryInverted};
  ${({ theme }: { theme: Theme }) => getTextStyles('small', theme)}

  @media ${devices.tablet} {
    ${({ theme }: { theme: Theme }) => getTextStyles('xlarge', theme)}
  }
`;

export const IconText = styled.span.attrs(({ isMobileL }: { isMobileL: boolean }) => ({
  className: `regular-weight ${isMobileL ? 'small-text' : 'standard-text'}`,
}))`
  margin: 0 ${dimensions.mediumPadding}px 0 ${dimensions.tinyPadding}px;
`;

export const Switch = styled(_Switch)`
  padding: 13px 18px;
  height: unset;
  @media ${devices.maxMobileL} {
    padding: 13px 6px;
    > span {
      ${({ theme }: { theme: Theme }) => getTextStyles('standard', theme)}
    }
  }
`;
