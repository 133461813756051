import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const usePrevLocation = (): { currentLocation: string; prevLocation: string } => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location.pathname);
  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  useEffect(() => {
    setPrevLocation(currentLocation);
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  return { prevLocation, currentLocation };
};

export default usePrevLocation;
