import { paths } from 'common/urls';
import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';

import { trackAppEvent } from 'store/actions/tracking';
import {
  trackNewsFeedModifySelectionNewsLanguage,
  trackNewsFeedOpenScreen,
  trackNewsFeedPressButtonOpenNewsFeed,
  trackNewsFeedPressListNewsItem,
  trackNewsFeedScrollListNewsItem,
} from '../biEventDefinitions';

export function* logNewsFeedEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const marketOverviewScreenId = paths.MARKET_OVERVIEW;
  const lastScreenId: string = yield select(getCurrentPath);
  switch (action.eventName) {
    case 'newsFeedScreenOpen':
      if (lastScreenId.includes(marketOverviewScreenId)) {
        yield call(
          trackNewsFeedOpenScreen,
          marketOverviewScreenId,
          lastScreenId,
          action.payload.lang,
          action.payload.loadedNewsIds,
        );
      }
      break;

    case 'moreNewsPress':
      yield call(
        trackNewsFeedPressButtonOpenNewsFeed,
        marketOverviewScreenId,
        action.payload.lang,
        action.payload.loadedNewsIds,
      );
      break;

    case 'newsLangChanged':
      yield call(
        trackNewsFeedModifySelectionNewsLanguage,
        marketOverviewScreenId,
        action.payload.lang,
        action.payload.loadedNewsIds,
      );
      break;

    case 'scrollNewsFeed':
      yield call(
        trackNewsFeedScrollListNewsItem,
        marketOverviewScreenId,
        action.payload.lang,
        action.payload.loadedNewsIds,
        action.payload.visibleNewsIds,
      );
      break;

    case 'newsItemClicked':
      yield call(
        trackNewsFeedPressListNewsItem,
        marketOverviewScreenId,
        action.payload.lang,
        action.payload.loadedNewsIds,
        action.payload.pressedNewsId,
      );
      break;

    default:
      break;
  }
}
