import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

export const TextBold = styled.text.attrs({
  className: 'strong-weight',
})``;
