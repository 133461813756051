import { useSelector } from 'react-redux';
import { getIsFeatureSpecificTncNotAccepted } from 'store/selectors/termsAndConditions';
import { getIsTopUpsEnabled } from 'store/selectors/configFeature';
import { getIsKycConfirmedLongerThanNumberOfMonths } from 'store/selectors/auth';
import { getIsAnyDepositsMade } from 'store/selectors/money';
import { State } from 'store/types/store';
import { MIN_MONTH_KYC_CONFIRMED } from 'scenes/MoneyTransfer/Deposit/Instant/const';
import { getIsEligibleForTopUps } from 'store/selectors/settings';
import { getIsFeatureTopUpsEnabled } from 'store/selectors/topUps';
import { CONSENT_TYPES } from 'common/const/consent';

export type UseTopUpsType = {
  isTopUpsEnabled: boolean;
  isEligibleForTopUps: boolean;
  isTopUpsEnabledAndEligible: boolean;
  isFeatureTopUpsBlocked: boolean;
  isAnyDepositsMade: boolean;
  isKycConfirmedMoreThanMinRequiredMonths: boolean;
};

export const useTopUps = (): UseTopUpsType => {
  // Takes in consideration FEATURE_TOP_UPS_ENABLED env variable from config.env
  // Additionaly, if FEATURE_TOP_UPS_ENABLED === "BETA_ONLY" we also check that user has the user role BetaUser
  const isFeatureTopUpsEnabled = useSelector(getIsFeatureTopUpsEnabled);

  // "TopUps enabled" means the user is part of the correct A/B test group
  const isTopUpsEnabled = useSelector(getIsTopUpsEnabled);

  // "TopUps eligible" means the user is German, their account is older than 3 months, has deposited before, etc.
  // if you need a specific up-to-date list of requirements, ask backend, it might've changed
  const isEligibleForTopUps = useSelector(getIsEligibleForTopUps);

  // user is TopUps eligible and TopUps enabled
  const isTopUpsEnabledAndEligible = isTopUpsEnabled && isEligibleForTopUps;

  // is TopUps blocked because of Terms and Conditions
  const isFeatureTopUpsBlocked = useSelector((state) =>
    getIsFeatureSpecificTncNotAccepted(state, CONSENT_TYPES.TOP_UPS),
  );

  const isAnyDepositsMade = useSelector(getIsAnyDepositsMade);
  const isKycConfirmedMoreThanMinRequiredMonths = useSelector((state: State) =>
    getIsKycConfirmedLongerThanNumberOfMonths(state, MIN_MONTH_KYC_CONFIRMED),
  );

  return {
    isTopUpsEnabled: isFeatureTopUpsEnabled && isTopUpsEnabled,
    isEligibleForTopUps: isFeatureTopUpsEnabled && isEligibleForTopUps,
    isTopUpsEnabledAndEligible: isFeatureTopUpsEnabled && isTopUpsEnabledAndEligible,
    isFeatureTopUpsBlocked: isFeatureTopUpsEnabled && isFeatureTopUpsBlocked,
    isAnyDepositsMade: isFeatureTopUpsEnabled && isAnyDepositsMade, // is user made any deposits by transfer
    isKycConfirmedMoreThanMinRequiredMonths:
      isFeatureTopUpsEnabled && isKycConfirmedMoreThanMinRequiredMonths, // is user passed KYC more than required number of months
  };
};
