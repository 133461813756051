import { paths } from 'common/urls';

export const TNC_ALLOWED_TYPES = Object.freeze({
  TNC_ALLOWED_LO: 'TNC_ALLOWED_LO',
  TNC_ALLOWED_SP: 'TNC_ALLOWED_SP',
  TNC_ALLOWED_SO: 'TNC_ALLOWED_SO',
  TNC_ALLOWED_TRADING: 'TNC_ALLOWED_TRADING',
} as const);

export const BLOCK_TYPES = Object.freeze({
  AUDIT_PROOF_HARD_BLOCK: 'AUDIT_PROOF_HARD_BLOCK',
} as const);

export const API_USER_ROLES = Object.freeze({
  BETA_USER: 'BetaUser',
  KYC_DONE: 'KycDone',
  LOGIN: 'Login',
  REGISTERED: 'Registered',
  STOCKS: 'Stocks',
} as const);

export const ROUTE_MANAGEMENT_ROLES = Object.freeze({
  DEMO_USER_WITHOUT_KYC: 'DEMO_USER_WITHOUT_KYC',
  CRYPTO_TRADING_AVAILABLE: 'CRYPTO_TRADING_AVAILABLE', // kycOnly prop in appWrapper is replaced by this
  STOCKS_TRADING_AVAILABLE: 'STOCKS_TRADING_AVAILABLE', // stocksOnly prop in appWrapper is replaced by this
  REAL_STOCKS_FEATURE_AVAILABLE: 'REAL_STOCKS_FEATURE_AVAILABLE',
  REAL_ACCOUNT: 'REAL_ACCOUNT',
  STAKING_AVAILABLE: 'STAKING_AVAILABLE',
  ...TNC_ALLOWED_TYPES,
  ...BLOCK_TYPES,
} as const);

// use to navigate user to url that related to your BLOCK_TYPES type
export const HARD_BLOCK_TYPE_TO_REDIRECT = Object.freeze({
  [BLOCK_TYPES.AUDIT_PROOF_HARD_BLOCK]: paths.AUDIT_PROOF,
} as const);
