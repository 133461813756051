import { action, createRequestTypes, REQUEST, SUCCESS, FAILURE } from './_common';

export const CHECK_BISON_STATUS = createRequestTypes('CHECK_BISON_STATUS');
export const checkBisonStatus = {
  request: () => action(CHECK_BISON_STATUS[REQUEST]),
  success: (_, response) => action(CHECK_BISON_STATUS[SUCCESS], { response }),
  failure: (error) => action(CHECK_BISON_STATUS[FAILURE], { error }),
};

export const USER_CHECK_BISON_STATUS = 'USER_CHECK_BISON_STATUS';
export const BISON_MAINTAINANCE_STARTED = 'BISON_MAINTAINANCE_STARTED';
export const BISON_MAINTAINANCE_ENDED = 'BISON_MAINTAINANCE_ENDED';

export const userCheckBisonStatus = () => action(USER_CHECK_BISON_STATUS);
export const startMaintenance = () => action(BISON_MAINTAINANCE_STARTED);
export const endMaintenance = () => action(BISON_MAINTAINANCE_ENDED);

export const SYNC_STOCK_MARKET_STATUS = 'SYNC_STOCK_MARKET_STATUS';
export const syncStockMarketStatus = () => action(SYNC_STOCK_MARKET_STATUS);

export const SYNC_STOCK_MARKET_STATUS_FAILURE = 'SYNC_STOCK_MARKET_STATUS_FAILURE';
export const syncStockMarketStatusFailure = () => action(SYNC_STOCK_MARKET_STATUS_FAILURE);

export const UPDATE_STOCK_MARKET_STATUS = 'UPDATE_STOCK_MARKET_STATUS';
export const updateStockMarketStatus = (response) => action(UPDATE_STOCK_MARKET_STATUS, { response });
