import { requiredDocumentsForStakingConsent } from 'common/const/consent';

const getRequiredDocuments = (isConfirmGeneralTnCNeeded: boolean) => {
  const definedRequiredDocuments = [requiredDocumentsForStakingConsent.specialConditions];

  if (isConfirmGeneralTnCNeeded) definedRequiredDocuments.push(requiredDocumentsForStakingConsent.general);

  return definedRequiredDocuments;
};

export default getRequiredDocuments;
