import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { SavingPlanStatus, SavingsPlanEntity } from 'types/savingsPlans';
import { SavingsPlanPauseAction, SavingsPlanUnpauseAction } from './action-types';

const PAUSED_STATUS: SavingPlanStatus = 'Paused';
const UNPAUSED_STATUS: SavingPlanStatus = 'Active';

export const handlePauseSavingPlanAction = (
  state: EntityState<SavingsPlanEntity>,
  action: SavingsPlanPauseAction,
  adapter: EntityAdapter<SavingsPlanEntity>,
  isUpdating: boolean,
  updateStatus = false,
) => {
  if (!action.payload) return;

  const id = action.payload;

  const entity: SavingsPlanEntity | undefined = state.entities[id];

  if (!entity) return;

  const changes: SavingsPlanEntity = {
    ...entity,
    updating: isUpdating,
    status: updateStatus ? PAUSED_STATUS : entity.status,
  };

  adapter.updateOne(state, { id, changes });
};

export const handleUnpauseSavingPlanAction = (
  state: EntityState<SavingsPlanEntity>,
  action: SavingsPlanUnpauseAction,
  adapter: EntityAdapter<SavingsPlanEntity>,
  isUpdating: boolean,
  updateStatus = false,
) => {
  if (!action.payload) return;

  const id = action.payload;

  const entity: SavingsPlanEntity | undefined = state.entities[id];

  if (!entity) return;

  const changes: SavingsPlanEntity = {
    ...entity,
    updating: isUpdating,
    status: updateStatus ? UNPAUSED_STATUS : entity.status,
  };

  adapter.updateOne(state, { id, changes });
};
