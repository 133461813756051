import { call, select } from 'redux-saga/effects';

import getCurrentPath from 'store/selectors/navigation';
import { trackAppEvent } from 'store/actions/tracking';
import * as biEvents from '../biEventDefinitions';

export function* logComponentEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const screenId = yield select(getCurrentPath);
  const {
    componentId,
    buttonText,
    componentType,
    entityType,
    entityId,
    oldValue,
    newValue,
    text,
    componentList,
    listItemIndex,
    listItemDetails,
    listLength,
    listDetails,
  } = action.payload;

  switch (action.eventName) {
    case 'buttonComponentPress':
      yield call(biEvents.trackInteractPressButton, componentId, screenId, componentType, buttonText);
      break;
    case 'entityComponentPress':
      yield call(
        biEvents.trackInteractPressEntityButton,
        componentId,
        screenId,
        componentType,
        buttonText,
        entityType,
        entityId,
      );
      break;
    case 'toggleComponentPress':
      yield call(biEvents.trackInteractToggleSwitch, componentId, screenId, componentType, newValue);
      break;
    case 'modifyNumericField':
      yield call(
        biEvents.trackInteractModifyNumeric,
        componentId,
        screenId,
        componentType,
        oldValue,
        newValue,
      );
      break;
    case 'modifySelectionField':
      yield call(
        biEvents.trackInteractModifySelection,
        componentId,
        screenId,
        componentType,
        oldValue,
        newValue,
      );
      break;
    case 'focusInTextField':
      yield call(biEvents.trackInteractSelectText, componentId, screenId, componentType, text?.length);
      break;
    case 'focusOutTextField':
      yield call(biEvents.trackInteractEnterText, componentId, screenId, componentType, text?.length);
      break;
    case 'modalPressOpenModal':
      yield call(biEvents.trackDisplayOpenModal, componentId, screenId, '', 'modal');
      break;
    case 'modalPressCloseModalUnknown':
      yield call(biEvents.trackDisplayCloseModalUnknown, screenId, '', 'modal');
      break;
    case 'displayValidationErrors':
      yield call(biEvents.trackDisplayShowValidationError, componentId, screenId, componentList);
      break;
    case 'listItemComponentPress':
      yield call(
        biEvents.trackInteractPressListItem,
        componentId,
        screenId,
        componentType,
        listItemIndex,
        listItemDetails,
        listLength,
        listDetails,
      );
      break;
    default:
      break;
  }
}
