import { State } from 'store/types/store';
import { priceHistoryAdapter } from './adapter';
import { createSelector } from 'reselect';
import { getSelectedTimePeriod } from 'store/selectors/currency';
import { TimePeriod } from 'types/currency';
import { AssetClass } from 'types/assets';
import {
  getCryptoPriceHistoryChartData,
  getPriceHistoryEntityId,
  getSecurityPriceHistoryChartData,
  isPriceHistoryEntityEligibleForFetching,
} from 'common/utils/priceHistory';
import { getSecurityMarketStatus } from 'store/selectors/status';
import { getAllPrices } from '../prices/selectors';
import { PRICE_HISTORY_SLICE_NAME } from 'common/const/slices';

const priceHistoryState = (state: State) => state[PRICE_HISTORY_SLICE_NAME];

const priceHistorySelectors = priceHistoryAdapter.getSelectors(priceHistoryState);

/**
 * Returns price history data of a single passed-in asset.
 * If the asset is not there then it returns `undefined`
 *
 * @summary Price history data of a single stored asset.
 *
 * @public
 * @param {State} state
 * @param {string} id Code/ID of an asset you want price history data for. Id is composed from asset code and timePeriod.
 * @returns {PriceHistoryEntity | undefined}
 */
export const getPriceHistoryById = priceHistorySelectors.selectById;

/**
 * Returns chart data for passed in security asset.
 *
 * @summary Price history data of passed-in security asset.
 *
 * @public
 * @param {string} code
 * @returns {PriceHistorySecurityChartItem[] | undefined}
 */
export const getPriceHistoryChartDataForSecurity = createSelector(
  [
    priceHistorySelectors.selectAll,
    getSelectedTimePeriod,
    getSecurityMarketStatus,
    getAllPrices,
    (state: State, props: string) => props,
  ],
  getSecurityPriceHistoryChartData,
);

/**
 * Returns chart data for passed in crypto asset.
 *
 * @summary Price history data of passed-in crypto asset.
 *
 * @public
 * @param {string} code
 * @returns {PriceHistorySecurityChartItem[] | undefined}
 */
export const getPriceHistoryChartDataForCrypto = createSelector(
  [
    priceHistorySelectors.selectAll,
    getSelectedTimePeriod,
    getAllPrices,
    (state: State, props: string) => props,
  ],
  getCryptoPriceHistoryChartData,
);

/**
 * Returns all passed in filter codes that can be included in the price change fetching.
 * If some asset's price change was already fetched recently that asset will be left out.
 *
 * @summary Calculate if code is ready for price history fetching.
 *
 * @public
 * @param {State} state
 * @param {string} code Asset code that is ready to be fetched.
 * @returns {boolean}
 */
export const getEligiblePriceHistoryCodeByAssetClassAndTimePeriod = (
  state: State,
  code: string,
  assetClass: AssetClass,
  timePeriod: TimePeriod,
) => {
  const priceHistory = getPriceHistoryById(state, getPriceHistoryEntityId({ assetCode: code, timePeriod }));

  if (!priceHistory) return true;

  return isPriceHistoryEntityEligibleForFetching(priceHistory.lastFetched, assetClass);
};

/**
 * Returns a boolean value if passed in crypto asset's price history fetching is in progress at this moment.
 *
 * @summary Price history fetching in progress.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const isCryptoAssetFetchingPriceHistory = (state: State, assetCode: string) =>
  priceHistoryState(state).cryptoFetchAssetCode === assetCode;

/**
 * Returns a boolean value if passed in security asset's price history fetching is in progress at this moment.
 *
 * @summary Price history fetching in progress.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const isSecurityAssetFetchingPriceHistory = (state: State, assetCode: string) =>
  priceHistoryState(state).securityFetchAssetCode === assetCode;

/**
 * Returns a boolean value if security chart is healthy.
 *
 * @summary Is security chart healthy.
 *
 * @public
 * @param {State} state
 * @returns {boolean}
 */
export const isSecurityChartHealthy = (state: State) => priceHistoryState(state).isStockChartHealthy;
