import { timePeriodToStockEtfChartIncrement, timePeriods } from 'common/const';
import { CRYPTO_PRICE_HISTORY_DATA_TTL, SECURITY_PRICE_HISTORY_DATA_TTL } from 'common/const/priceHistory';
import { fromUnixTime, getUnixTime, setHours, setSeconds } from 'date-fns';
import {
  PriceHistoryCryptoData,
  PriceHistoryEntity,
  PriceHistoryItem,
  PriceHistorySecurityChartItem,
  PriceHistorySecurityData,
} from 'store/types/priceHistory';
import { PriceEntity } from 'store/types/prices';
import { ASSET_CLASSES, AssetClass } from 'types/assets';
import { TimePeriod } from 'types/currency';
import { SecurityMarketStatus } from 'types/status';
import I18n from 'i18next';

/**
 * Returns price history entity's unique id which is composed from unique code and timePeriod.
 *
 * @summary Calculates price history entity's id.
 *
 * @public
 * @param {PriceHistoryEntity} priceHistory
 * @returns {string}
 */
export const getPriceHistoryEntityId = (priceChange: Pick<PriceHistoryEntity, 'assetCode' | 'timePeriod'>) =>
  `${priceChange.assetCode.toLowerCase()}_${priceChange.timePeriod.toString().toLowerCase()}`;

/**
 * Calculates if the given price history's data is old and is ready to be re-fetched.
 *
 * @summary Calculates if price history is stale.
 *
 * @public
 * @param {number} lastFetchedDate
 * @param {AssetClass} assetClass
 * @returns {boolean}
 */
export const isPriceHistoryEntityEligibleForFetching = (
  lastFetchedDate: number,
  assetClass: AssetClass,
): boolean =>
  assetClass === ASSET_CLASSES.SECURITY
    ? lastFetchedDate + SECURITY_PRICE_HISTORY_DATA_TTL < new Date().getTime()
    : lastFetchedDate + CRYPTO_PRICE_HISTORY_DATA_TTL < new Date().getTime();

/**
 * Converts a crypto price history data to price history entity. Asset class, time period, asset code and last fetched date are added.
 * Added data is then later used to calculate if a specific price history entity is stale (old).
 *
 * @summary Converts a price history data to an array of price history entity.
 *
 * @public
 * @param {PriceHistoryData} priceHistoryData
 * @param {string} assetCode
 * @param {AssetClass} assetClass
 * @param {TimePeriod} timePeriod
 * @returns {PriceHistoryEntity}
 */
export const priceHistoryCryptoDataToPriceHistoryEntity = (
  priceHistoryData: PriceHistoryCryptoData,
  assetCode: string,
  assetClass: AssetClass,
  timePeriod: TimePeriod,
): PriceHistoryEntity => ({
  assetClass,
  assetCode,
  lastFetched: new Date().getTime(),
  timePeriod,
  items: priceHistoryData.items.map(({ close, closeTime }) => ({
    x: new Date(closeTime).getTime(),
    y: close,
  })),
});

/**
 * Converts a security price history data to price history entity. Asset class, time period, asset code and last fetched date are added.
 * Added data is then later used to calculate if a specific price history entity is stale (old).
 *
 * @summary Converts a price history data to an array of price history entity.
 *
 * @public
 * @param {PriceHistoryData} priceHistoryData
 * @param {string} assetCode
 * @param {AssetClass} assetClass
 * @param {TimePeriod} timePeriod
 * @returns {PriceHistoryEntity}
 */
export const priceHistorySecurityDataToPriceHistoryEntity = (
  priceHistoryData: PriceHistorySecurityData,
  assetCode: string,
  assetClass: AssetClass,
  timePeriod: TimePeriod,
): PriceHistoryEntity => ({
  assetClass,
  assetCode,
  lastFetched: new Date().getTime(),
  timePeriod,
  items: priceHistoryData.items.map(({ x, y }) => ({ x, y })),
});

export const getSecurityPriceHistoryChartData = (
  priceHistories: PriceHistoryEntity[],
  timePeriod: TimePeriod,
  marketStatus: SecurityMarketStatus,
  securityPrices: PriceEntity[],
  assetCode: string,
): PriceHistorySecurityChartItem[] | undefined => {
  if (!assetCode) return undefined;

  const priceHistory = priceHistories.find(
    (priceHistoryEntity) =>
      priceHistoryEntity.timePeriod === timePeriod && priceHistoryEntity.assetCode === assetCode,
  );

  if (!priceHistory) return undefined;

  const price = securityPrices.find(({ baseEntity }) => baseEntity.toLowerCase() === assetCode.toLowerCase());

  const midPrice = price?.midPrice ?? 0;

  let sortedValues = priceHistory.items.slice().sort((a, b) => (a.x && b.x ? a?.x - b?.x : 0));

  if (marketStatus.isMarketOpen && priceHistory.items.length && midPrice) {
    sortedValues = sortedValues.concat([
      {
        x: Math.floor(new Date().getTime() / 1000),
        y: midPrice,
      },
    ]);
  }

  let totalIncrement = 0;

  return sortedValues.map((item, index) => {
    const increment =
      index > 0
        ? getIncrement({ value: item.x, lastValue: sortedValues[index - 1].x, selectedTP: timePeriod })
        : 0;
    totalIncrement += increment;

    return { ...item, x: index + totalIncrement, epoch: item.x }; // We want to show values one after another. If we use epochs, then there is a gap when market is closed and we do not want to show those gaps.
  });
};

export const getCryptoPriceHistoryChartData = (
  priceHistories: PriceHistoryEntity[],
  timePeriod: TimePeriod,
  cryptoPrices: PriceEntity[],
  assetCode: string,
): PriceHistoryItem[] | undefined => {
  if (!assetCode) return undefined;

  const priceHistory = priceHistories.find(
    (item) =>
      item.assetCode.toLowerCase() === assetCode.toLowerCase() &&
      item.timePeriod.toLowerCase() === timePeriod.toLowerCase(),
  );

  if (!priceHistory) return undefined;

  const priceData = cryptoPrices.find((item) => item.baseEntity.toLowerCase() === assetCode.toLowerCase());

  return priceHistory.items.map((item, index) => {
    let date = new Date(item.x);
    let price = item.y;
    if (index === priceHistory.items.length - 1 && priceData) {
      date = new Date(priceData.quotedAt);
      price = priceData.midPrice;
    }

    return {
      x: date.getTime(),
      y: price,
    };
  });
};

// PRIVATE

const getTodaysClosingUnixTime = () => {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  return new Date(startOfDay).setHours(22) / 1000;
};

const getIncrementFromStartOfTheDay = (timestamp: number) => {
  return timestamp - getUnixTime(setSeconds(setHours(fromUnixTime(timestamp / 1000), 7), 0));
};

const getIncrementToTheEndOfTheWorkingDay = (timestamp: number) => {
  return getUnixTime(setSeconds(setHours(fromUnixTime(timestamp / 1000), 23), 0)) - timestamp;
};

const getOvernightIncrement = (value: number, lastValue: number) => {
  return Math.floor(
    (getIncrementToTheEndOfTheWorkingDay(lastValue) + getIncrementFromStartOfTheDay(value)) / 1000,
  );
};

const getIncrement = ({
  value,
  lastValue,
  selectedTP,
}: {
  value: number;
  lastValue: number;
  selectedTP: TimePeriod;
}) => {
  const timeDelta = value - lastValue;
  const lastPointWasOnAnotherDay = new Date(value * 1000).getDay() !== new Date(lastValue * 1000).getDay();
  if (
    (selectedTP === timePeriods['7D'] ||
      selectedTP === timePeriods['30D'] ||
      selectedTP === timePeriods['12M'] ||
      selectedTP === timePeriods.MAX) &&
    lastPointWasOnAnotherDay
  ) {
    return Math.floor(
      getOvernightIncrement(value, lastValue) / timePeriodToStockEtfChartIncrement[selectedTP],
    );
    /* eslint-disable-next-line no-else-return */
  } else if (timeDelta > timePeriodToStockEtfChartIncrement[selectedTP]) {
    return Math.floor(timeDelta / timePeriodToStockEtfChartIncrement[selectedTP]) - 1;
  } else return 0;
};
