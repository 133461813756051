import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { resetAccountDeleted } from 'store/actions/misc';
import AlertModal from 'common/components/Modal/AlertModal';
import { ModalProps } from 'types/modals';
import { Headline } from './delete-account-styles';

const DeleteAccountConfirmation = ({ onClose }: ModalProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onCloseCallback = (): ReturnType<typeof dispatch> => {
    dispatch(resetAccountDeleted());
    onClose();
  };

  return (
    <AlertModal
      open={true}
      onClose={onCloseCallback}
      ButtonTitle={t('common.close')}
      Content={
        <>
          <Headline>{t('settings.terminateAccount.demoTerminateSuccess.title')}</Headline>
          <ReactMarkdown>
            {`${t('settings.terminateAccount.demoTerminateSuccess.a')}  
            \n${t('settings.terminateAccount.demoTerminateSuccess.b')}
            \n${t('settings.terminateAccount.demoTerminateSuccess.c')}`}
          </ReactMarkdown>
        </>
      }
    />
  );
};

export default DeleteAccountConfirmation;
