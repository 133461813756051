import { combineEpics } from 'redux-observable';
import { RootEpic } from 'types/common';
import { filter, map } from 'rxjs/operators';
import { isOfType } from 'safetypings';
import {
  resetMarketOverviewFilter,
  setMarketOverviewCategory,
  setMarketOverviewOrderBy,
  setMarketOverviewSearchText,
} from 'store/slices/filters/actions';
import { CHANGE_ACCOUNT, LOGOUT_USER } from 'store/actions/auth';
import { loadMarketOverviewAssets } from 'store/slices/common/actions';

const marketOverviewFilterChangeEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(
      isOfType([
        setMarketOverviewSearchText.type,
        setMarketOverviewOrderBy.type,
        setMarketOverviewCategory.type,
      ]),
    ),
    map(() => loadMarketOverviewAssets()),
  );

const watchLogout: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType([LOGOUT_USER, CHANGE_ACCOUNT.REQUEST])),
    map(() => resetMarketOverviewFilter()),
  );
};

export default combineEpics(marketOverviewFilterChangeEpic, watchLogout);
