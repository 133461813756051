import {
  PriceHistoryMarketOverviewSmallChartPoint,
  PriceHistorySmallChartData,
  PriceHistorySmallChartEntity,
} from 'store/types/priceHistory';
import { TimePeriod } from 'types/currency';
import { CRYPTO_SMALL_CHART_DATA_TTL } from 'common/const/priceHistory';

/**
 * Returns price history for small chart entity's unique id which is composed of unique code and timePeriod.
 *
 * @summary Calculates price history for small chart entity's id.
 *
 * @public
 * @param {PriceChangeEntity} priceHistorySmallChart
 * @returns {string}
 */
export const getPriceHistorySmallChartEntityId = (
  priceHistorySmallChart: Pick<PriceHistorySmallChartEntity, 'assetCode' | 'timePeriod'>,
): string =>
  `${priceHistorySmallChart.assetCode.toLowerCase()}_${priceHistorySmallChart.timePeriod
    .toString()
    .toLowerCase()}`;

export const priceHistorySmallChartDataToEntity = (
  priceHistorySmallChartData: PriceHistorySmallChartData,
  assetCode: string,
  timePeriod: TimePeriod,
): PriceHistorySmallChartEntity => ({
  items: priceHistorySmallChartData.map(({ y }, idx) => ({ x: idx, y })),
  assetCode,
  lastFetched: new Date().getTime(),
  timePeriod,
});

export const smallChartEntityToMarketOverviewChart = (
  chartEntity: PriceHistorySmallChartEntity,
): PriceHistoryMarketOverviewSmallChartPoint[] => {
  return chartEntity?.items.map((point, index) => ({ x: index, y: point.y }));
};

export const isSmallChartEntityEligibleForFetching = (lastFetchedDate: number): boolean =>
  lastFetchedDate + CRYPTO_SMALL_CHART_DATA_TTL < new Date().getTime();
