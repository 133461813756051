import * as ActionTypes from 'store/actions/orders';
import { INVALIDATE_ACCOUNT_DATA } from 'store/actions/auth';
import { AnyAction } from 'redux';
import { OrdersState } from 'store/types/orders';

const initialState: OrdersState = {
  isBuyOrder: false,
  isSuggestionSelected: false,
  entity: 'btc',
  currency: 'eur',
  firstLoadComplete: false,
  error: '',
  initingOrder: false,
  initOrderError: '',
  fetchingQuote: false,
  fetchingQuoteError: '',
  reservingPrice: false,
  reservationError: '',
  reservedPricePopupShow: false,
  isFocusOnValue: true,
  latestQuoteAmountType: 'value',
  validAmounts: false,
  userInputChangeInProgress: false,
  quote: {
    orderPreview: {},
    auxInfo: {},
  },
  reservation: {
    orderPreview: {},
    auxInfo: {},
  },
  submittingOrder: false,
  submissionError: '',
  orderDone: {},
  orderFinished: false,
  fieldInFocus: null,
  lastEditedField: null,
};

export default function orders(state: OrdersState = initialState, action: AnyAction) {
  switch (action.type) {
    // Resets state on account change
    case INVALIDATE_ACCOUNT_DATA:
      return initialState;

    case ActionTypes.START_ORDER_SESSION:
      return {
        ...state,
        isBuyOrder: action.isBuyOrder,
        entity: action.entity,
        isFocusOnValue: action.isBuyOrder, // Focus on value if we're buying, focus on crypto if selling
      };
    case ActionTypes.END_ORDER_SESSION:
      return initialState;
    case ActionTypes.SWITCH_ORDER_MODE:
      return {
        ...state,
        isBuyOrder: action.isBuyOrder,
        entity: action.entity,
        isFocusOnValue: action.isBuyOrder, // Focus on value if we're buying, focus on crypto if selling
      };
    case ActionTypes.INIT_ORDER.REQUEST:
      return { ...state, initingOrder: true, initOrderError: '' };
    case ActionTypes.INIT_ORDER.SUCCESS:
      return {
        ...state,
        firstLoadComplete: true,
        initingOrder: false,
        validAmounts: true,
        quote: { orderPreview: action.orderPreview, auxInfo: action.auxInfo },
      };
    case ActionTypes.INIT_ORDER.FAILURE:
      return { ...state, initingOrder: false, initOrderError: action.error.message };
    case ActionTypes.PRICE_QUOTE.REQUEST:
      return {
        ...state,
        fetchingQuote: true,
        fetchingQuoteError: '',
      };
    case ActionTypes.PRICE_QUOTE.SUCCESS:
      return {
        ...state,
        fetchingQuote: false,
        fetchingQuoteError: '',
        latestQuoteAmountType: action.amountType,
        validAmounts: true,
        quote: {
          ...state.quote,
          orderPreview: action.response,
        },
      };
    case ActionTypes.PRICE_QUOTE.FAILURE:
      return {
        ...state,
        fetchingQuote: false,
        fetchingQuoteError: action.error,
      };
    case ActionTypes.RESERVE_ORDER.REQUEST:
      return { ...state, reservingPrice: true, reservationError: '' };
    case ActionTypes.RESERVE_ORDER.SUCCESS:
      return {
        ...state,
        reservingPrice: false,
        reservedPricePopupShow: true,
        reservation: { orderPreview: action.orderPreview, auxInfo: action.auxInfo },
      };
    case ActionTypes.RESERVE_ORDER.FAILURE:
      return { ...state, reservingPrice: false, reservationError: action.error.message };
    case ActionTypes.CANCEL_USER_SUBMIT:
      return { ...state, reservedPricePopupShow: false };
    case ActionTypes.SUBMIT_ORDER.REQUEST:
      return {
        ...state,
        submittingOrder: true,
        reservedPricePopupShow: false,
        submissionError: '',
        orderFinished: false,
      };
    case ActionTypes.SUBMIT_ORDER.SUCCESS:
      return {
        ...state,
        submittingOrder: false,
        reservedPricePopupShow: false,
        orderDone: action.orderDone,
        orderFinished: true,
      };
    case ActionTypes.SUBMIT_ORDER.FAILURE:
      return {
        ...state,
        submittingOrder: false,
        submissionError: action.error.message,
        reservedPricePopupShow: false,
        orderDone: {},
        orderFinished: true,
      };
    case ActionTypes.RETRY_ORDER:
      return {
        ...state,
        submittingOrder: false,
        submissionError: '',
        reservedPricePopupShow: false,
        orderDone: {},
        orderFinished: false,
      };
    case ActionTypes.USER_INPUT_CHANGED:
      return {
        ...state,
        isSuggestionSelected: action.isSuggestion,
        userInputChangeInProgress: true,
      };
    case ActionTypes.USER_INPUT_CHANGE_FINISHED:
      return {
        ...state,
        userInputChangeInProgress: false,
      };
    case ActionTypes.INVALID_USER_INPUT:
      return {
        ...state,
        validAmounts: false,
        quote: {
          ...state.quote,
          orderPreview: {
            ...state.quote.orderPreview,
            ...(action.resetToZero
              ? {
                  value: 0,
                  volume: 0,
                }
              : {}),
          },
        },
      };
    case ActionTypes.CHANGE_FIELD_IN_FOCUS:
      return {
        ...state,
        fieldInFocus: action.fieldInFocus,
      };
    case ActionTypes.CHANGE_LAST_EDITED_FIELD:
      return {
        ...state,
        lastEditedField: action.lastEditedField,
      };
    default:
      return state;
  }
}
