/* eslint-disable @typescript-eslint/no-unsafe-return */
import { State } from 'store/types/store';
import { ThemeName } from 'types/theme';

export const getTimeToRequireAuthentication = (state: State): string | undefined =>
  state.settings.timeToRequireAuthentication;

export const getTheme = (state: State): ThemeName => state.settings.themeName;

export const getHasNotAgreedTo247Trading = (state: State): boolean => state.settings.hasNotAgreedTo247Trading;

export const getSelectedTechIndicators = (state: State): string[] =>
  state.settings.selectedTechIndicators.split(',').filter((item: string) => item !== '');

export const getStocksSwitch = (state: State) => state.settings.stocksSwitch;

export const getBackendIsEligibleCandidateForStocks = (state: State): boolean =>
  state.settings.isEligibleCandidateForStocks;

export const getAcceptedKansasCityChiefsRaffleTermsForWeeks = (state: State): string | null =>
  state.settings.acceptedKansasCityChiefsRaffleTermsForWeeks;

export const getRejectedKansasCityChiefsRaffleTermsForWeeks = (state: State): string | null =>
  state.settings.rejectedKansasCityChiefsRaffleTermsForWeeks;

export const getDoNotAskForKansasCityChiefsRaffleTerms = (state: State): boolean =>
  state.settings.doNotAskForKansasCityChiefsRaffleTerms;

export const getTncPopupClosedAt = (state: State): string | null => state.settings.tncPopupClosedAt;

// Uncomment when stocks TNCs are live.
/* export const getStocksTncPopupClosedAt = (state: State): string | null =>
  state.settings.tncStocksPopupClosedAt; */

export const getIsWinnerLoserModalVisited = (state: State): boolean =>
  !!state.settings.hasSeenStocksWinnersLosersModal;

export const getIsEligibleForTopUps = (state: State): boolean => state.settings.isEligibleForTopUps;

export const getHasClosedEidPopup = (state: State): boolean => state.settings.hasClosedEidPopup;

export const getHasSeenTopUpsDataPrivacyPopup = (state: State): boolean =>
  state.settings.hasSeenTopUpsDataPrivacyPopup;
