const DARK_ICONS_FAILED_SESSION_STORAGE_KEY = 'darkIcons';

const getFailedDarkIconsFromStorage = () => sessionStorage.getItem(DARK_ICONS_FAILED_SESSION_STORAGE_KEY);

const setFailedDarkIconsToStorage = (set: Set<string>) =>
  sessionStorage.setItem(DARK_ICONS_FAILED_SESSION_STORAGE_KEY, JSON.stringify([...set]));

const getFailedDarkIcons = () => {
  const data = getFailedDarkIconsFromStorage();
  return data ? new Set(JSON.parse(data)) : new Set();
};

export const addFailedDarkIcon = (code: string) => {
  const set = getFailedDarkIcons();
  set.add(code);
  setFailedDarkIconsToStorage(set);
};

export const isDarkIconCodeInSet = (code: string) => {
  const set = getFailedDarkIcons();
  return set.has(code);
};
