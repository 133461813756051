/* eslint-disable @typescript-eslint/no-unsafe-return */

import { combineEpics } from 'redux-observable';
import { RootEpic } from 'types/common';
import {
  catchError,
  filter,
  map,
  mergeMap,
  pluck,
  switchMap,
  take,
  takeUntil,
  startWith,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { interval, merge, timer } from 'rxjs';
import { isOfType, isPresent } from 'safetypings';
import { getAccessToken } from 'store/selectors/auth';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import {
  initSecurityOrderSuccess,
  initSecurityOrderError,
  stockEtfPriceQuoteError,
  stockEtfPriceQuoteSuccess,
  INIT_SECURITY_ORDER_REQUEST,
  INIT_SECURITY_ORDER_SUCCESS,
  STOCK_ETF_ORDER_USER_INPUT_CHANGED,
  RESTART_PRICE_QUOTING,
  RESERVE_SECURITY_PRICE_QUOTE_REQUEST,
  reserveStockEtfPriceQuoteSuccess,
  reserveStockEtfPriceQuoteError,
  ACCEPT_SECURITY_PRICE_QUOTE_REQUEST,
  acceptSecurityPriceQuoteSuccess,
  acceptSecurityPriceQuoteError,
  restartPriceQuoting,
  RESET_ORDER,
  manualOrderStatusChange,
  RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS,
  updateQuoteValidFor,
  RESERVE_STOCK_ETF_PRICE_QUOTE_ERROR,
  CLEAR_RESERVED_ORDER,
} from 'store/actions/stockEtfOrder';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import { getOrderData, getReservedOrderData } from 'store/selectors/stockEtfOrder';
import {
  ORDER_MANUAL_RFQ_MAX_IN_SECONDS,
  ORDER_MANUAL_RFQ_MIN_IN_SECONDS,
  ORDER_STATUS,
  STOCK_ORDER_LATENCY_TIMEOUT,
} from 'common/const/stockOrder';
import { State } from 'store/types/store';
import { getSelectedFiatCurrency } from 'store/selectors/currency';
import { Fiat } from 'types/currency';
import { QuoteData } from 'types/stockEtfOrder';

const initializeStockEtfOrder: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(INIT_SECURITY_ORDER_REQUEST)),
    switchMap(({ payload }) =>
      state$.pipe(
        map((state: State) => ({
          accessToken: getAccessToken(state),
          Currency: getSelectedFiatCurrency(state),
        })),
        filter(({ accessToken }) => isPresent(accessToken)),
        take(1),
        switchMap(({ Currency, accessToken }: { Curreny: Fiat; accessToken: string }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat('/stocks/rfq-orders/init')
              .concat(queryArrayString({ ...payload, Currency })),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response) => initSecurityOrderSuccess(response)),
          ),
        ),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, initSecurityOrderError)),
      ),
    ),
  );

const watchInitSuccess: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(INIT_SECURITY_ORDER_SUCCESS)),
    map(({ payload: { orderPreview } }) =>
      restartPriceQuoting({
        Type: orderPreview.orderType,
        Entity: orderPreview.entity,
        Currency: orderPreview.currency,
        Volume: orderPreview.volume,
      }),
    ),
  );

const refreshPriceQuote: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(RESTART_PRICE_QUOTING)),
    switchMap(({ payload }) =>
      state$.pipe(
        map((state: State) => ({
          accessToken: getAccessToken(state),
          Currency: getSelectedFiatCurrency(state),
        })),
        filter(({ accessToken }) => isPresent(accessToken)),
        take(1),
        switchMap(({ Currency, accessToken }: { Curreny: Fiat; accessToken: string }) =>
          interval(5000).pipe(
            startWith(0),
            takeUntil(action$.pipe(filter(isOfType([STOCK_ETF_ORDER_USER_INPUT_CHANGED, RESET_ORDER])))),
            mergeMap(() =>
              ajax({
                url: api
                  .getBaseUrlForTrading(accessToken)
                  .concat('/stocks/rfq-orders/refresh-price')
                  .concat(queryArrayString({ ...payload, Currency })),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }).pipe(
                pluck('response'),
                map((response) => stockEtfPriceQuoteSuccess(response)),
              ),
            ),
          ),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, stockEtfPriceQuoteError)),
      ),
    ),
  );

const reserveStockEtfQuote: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(RESERVE_SECURITY_PRICE_QUOTE_REQUEST)),
    switchMap(({ payload }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) => {
          const orderData = getOrderData(state$.value);
          const {
            orderPreview: { orderType, volume, entity, currency },
          } = orderData;
          const requestPayload = {
            Type: orderType,
            Currency: currency,
            Entity: entity,
            Volume: volume,
            KnowledgeLeveLRestrictionOverruled: true,
            FundRestrictionOverruled: true,
            NonUsRestrictionOverruled: true,
            StructuredRestrictionOverruled: true,
          };

          const timer$ = timer(0, 1000).pipe(
            map((time: number) => {
              let status = '';

              if (time < ORDER_MANUAL_RFQ_MIN_IN_SECONDS) status = ORDER_STATUS.QUOTE_LOADING;

              if (time >= ORDER_MANUAL_RFQ_MIN_IN_SECONDS && time < ORDER_MANUAL_RFQ_MAX_IN_SECONDS)
                status = ORDER_STATUS.MANUAL_RFQ;

              // In this case we need to display the error modal
              if (time >= ORDER_MANUAL_RFQ_MAX_IN_SECONDS) status = ORDER_STATUS.QUOTE_ERROR;

              return manualOrderStatusChange(status);
            }),
          );

          const httpCall$ = ajax({
            url: api
              .getBaseUrlForTrading(accessToken)
              .concat('/stocks/rfq-orders/quotes')
              .concat(queryArrayString(requestPayload)),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map(({ result, marketMaker }: { result: QuoteData; marketMaker: string }) =>
              reserveStockEtfPriceQuoteSuccess({
                marketMaker,
                ...result,
              }),
            ),
            catchError(handleAjaxError(action$, reserveStockEtfPriceQuoteError)),
          );

          return merge(httpCall$, timer$).pipe(
            takeUntil(
              action$.pipe(
                filter(
                  isOfType([
                    RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS,
                    RESERVE_STOCK_ETF_PRICE_QUOTE_ERROR,
                    CLEAR_RESERVED_ORDER,
                  ]),
                ),
              ),
            ),
          );
        }),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, reserveStockEtfPriceQuoteError)),
      ),
    ),
  );

const quoteValidFor: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(RESERVE_STOCK_ETF_PRICE_QUOTE_SUCCESS)),
    map(({ payload }: { payload: QuoteData }) => payload.validForSeconds - STOCK_ORDER_LATENCY_TIMEOUT),
    switchMap((validForSeconds: number) =>
      timer(0, 1000).pipe(
        takeWhile((time: number) => time < validForSeconds + 1),
        map((time: number) =>
          time === validForSeconds
            ? updateQuoteValidFor(undefined)
            : updateQuoteValidFor(validForSeconds - time),
        ),
      ),
    ),
  );

const acceptStockEtfQuote: RootEpic = (action$: any, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(ACCEPT_SECURITY_PRICE_QUOTE_REQUEST)),
    switchMap(({ payload }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) => {
          const orderData = getReservedOrderData(state$.value);

          return ajax({
            url: api.getBaseUrlForTrading(accessToken).concat('/stocks/rfq-orders/quotes'),
            method: 'POST',
            body: orderData,
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response) => acceptSecurityPriceQuoteSuccess(response)),
          );
        }),
        catchError(handleAjaxError(action$, acceptSecurityPriceQuoteError)),
      ),
    ),
  );

export default combineEpics(
  initializeStockEtfOrder,
  refreshPriceQuote,
  reserveStockEtfQuote,
  acceptStockEtfQuote,
  watchInitSuccess,
  quoteValidFor,
);
