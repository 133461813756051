import { combineEpics } from 'redux-observable';
import { RootEpic } from 'types/common';
import { Observable } from 'rxjs';
import { AnyAction } from 'redux';
import { catchError, filter, map, pluck, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  fetchPortfolioChartFailure,
  fetchPortfolioChartRequest,
  fetchPortfolioChartSuccess,
  fetchPortfolioChartWithSelectedTimePeriod,
} from 'store/slices/portfolioChart/actions';
import { getPortfolioChartBySelectedTimePeriod } from 'store/slices/portfolioChart/selectors';
import { getAccessToken } from 'store/selectors/auth';
import { getBaseUrlForTrading } from 'common/api';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { timePeriodToPortfolioChartTime } from 'common/const';
import { PortfolioChartItem } from 'types/portfolio';
import { handleAjaxError } from 'store/epics/auth';
import I18n from 'i18next';
import { getSelectedTimePeriod } from 'store/selectors/currency';
import { addSeconds, isBefore } from 'date-fns';
import {
  subscribeToPriceHistorySmallChart,
  unsubscribeFromPriceHistorySmallChart,
} from 'store/slices/priceHistorySmallChart/actions';
import { getAvailableCryptoCodes } from 'store/slices/portfolio/selectors';
import { fetchPortfolioSuccess } from 'store/slices/portfolio/actions';

export const fetchPortfolioChart: RootEpic = (action$: Observable<AnyAction>, state$, { ajax, api }) =>
  action$.pipe(
    filter(fetchPortfolioChartRequest.match),
    switchMap(({ payload: { timePeriod } }) => {
      const accessToken = getAccessToken(state$.value);
      if (!accessToken) return;

      return ajax({
        url: getBaseUrlForTrading(accessToken)
          .concat('/portfolio/chart')
          .concat(queryArrayString({ TimePeriod: timePeriodToPortfolioChartTime[timePeriod] })),
        method: 'GET',
        withCredentials: true, // include cookies
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        pluck('response', 'portfolioHistory'),
        map((response: PortfolioChartItem[]) => fetchPortfolioChartSuccess({ timePeriod, response })),
        catchError(
          handleAjaxError(
            action$,
            (error) => fetchPortfolioChartFailure({ timePeriod }),
            {},
            { message: I18n.t('error.portfolio') },
          ),
        ),
      );
    }),
  );

const watchPortfolioSuccessAndSubscribeSmallChartsEpic: RootEpic = (action$: Observable<AnyAction>, state$) =>
  action$.pipe(
    filter(fetchPortfolioSuccess.match),
    take(1),
    withLatestFrom(state$),
    switchMap(([_, state]) => [
      unsubscribeFromPriceHistorySmallChart(),
      subscribeToPriceHistorySmallChart({ codes: getAvailableCryptoCodes(state) }),
    ]),
  );

const watchFetchPortfolioChartsWithSelectedTimePeriodEpic: RootEpic = (
  action$: Observable<AnyAction>,
  state$,
) =>
  action$.pipe(
    filter(fetchPortfolioChartWithSelectedTimePeriod.match),
    withLatestFrom(state$),
    filter(([_, state]) => {
      const selectedTimeframeChartData = getPortfolioChartBySelectedTimePeriod(state);
      const isExpired = !(
        selectedTimeframeChartData?.lastUpdatedAt &&
        isBefore(new Date(), addSeconds(new Date(selectedTimeframeChartData?.lastUpdatedAt), 5))
      );

      return !selectedTimeframeChartData.isFetching && isExpired;
    }),
    switchMap(([_, state]) => [
      fetchPortfolioChartRequest({ timePeriod: getSelectedTimePeriod(state) }),
      unsubscribeFromPriceHistorySmallChart(),
      subscribeToPriceHistorySmallChart({ codes: getAvailableCryptoCodes(state) }),
    ]),
  );

export default combineEpics(
  fetchPortfolioChart,
  watchPortfolioSuccessAndSubscribeSmallChartsEpic,
  watchFetchPortfolioChartsWithSelectedTimePeriodEpic,
);
