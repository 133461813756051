import { ActionsObservable, combineEpics, Epic, StateObservable } from 'redux-observable';
import { race, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  pluck,
  repeatWhen,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { isOfType } from 'safetypings';

import { RootAction, RootEpic } from 'types/common';
import { NewsItem } from 'types/news';
import {
  changeNewsLang,
  fetchNewsFeedFailure,
  fetchNewsFeedRequest,
  fetchNewsFeedSuccess,
  invalidateNewsFeed,
  loadNewsFeedIfNeeded,
  subscribeToNewsFeed,
  unsubscribeFromNewsFeed,
} from '../slices/news/actions';
import { handleAjaxError } from './auth';
import { getIsFetchingNewsFeed, getLangOfNews } from '../slices/news/selectors';
import { getLastSelectedCurrency } from '../selectors/currency';
import { NEWS_REFETCH_INTERVAL } from 'common/const/news';

const fetchNewsFeedForCryptoEpic: Epic<any> = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(fetchNewsFeedRequest.match),
    switchMap(({ payload }) => {
      const lang = getLangOfNews(state$.value);
      const crypto = payload.crypto;
      return race(
        ajax({
          url: api.getBaseUrlForNews().concat(`/${crypto}/${lang}`),
        }).pipe(
          pluck('response'),
          map((response: NewsItem[]) => fetchNewsFeedSuccess({ newsFeed: response })),
          catchError(handleAjaxError(action$, fetchNewsFeedFailure, { crypto })),
        ),
        action$.pipe(filter(invalidateNewsFeed.match), take(1)),
      );
    }),
  );

const updateNewsFeedList: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(subscribeToNewsFeed.match),
    take(1),
    switchMap(() => {
      return timer(0, NEWS_REFETCH_INTERVAL).pipe(
        takeUntil(action$.pipe(filter(unsubscribeFromNewsFeed.match))),
        repeatWhen(() => action$.pipe(filter(subscribeToNewsFeed.match))),
        mapTo(loadNewsFeedIfNeeded()),
      );
    }),
  );

export const loadNewsFeedPlans: Epic<any> = (action$, state$) =>
  action$.pipe(
    filter(loadNewsFeedIfNeeded.match),
    map(() => ({
      crypto: getLastSelectedCurrency(state$.value),
      isFetching: getIsFetchingNewsFeed(state$.value),
    })),
    filter(({ isFetching }) => !isFetching),
    map(({ crypto }) => fetchNewsFeedRequest({ crypto })),
  );

export const changeLanguageOfNews: Epic<any> = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<any>,
) => action$.pipe(filter(changeNewsLang.match), mapTo(invalidateNewsFeed()));

export const newsfeedUpdateUponCryptoSelection: Epic<any> = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<any>,
) =>
  action$.pipe(
    filter(isOfType('SELECT_ENTITY')),
    take(1),
    switchMap(() =>
      state$.pipe(map(getLastSelectedCurrency), distinctUntilChanged(), mapTo(invalidateNewsFeed())),
    ),
  );

export const invalidateNewsFeedEpic: Epic<any> = (action$) =>
  action$.pipe(filter(invalidateNewsFeed.match), mapTo(loadNewsFeedIfNeeded()));

export default combineEpics(
  updateNewsFeedList,
  loadNewsFeedPlans,
  fetchNewsFeedForCryptoEpic,
  changeLanguageOfNews,
  invalidateNewsFeedEpic,
  newsfeedUpdateUponCryptoSelection,
);
