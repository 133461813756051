import * as platform from 'platform';
import { User } from 'types/state';
import I18next from 'i18next';
import { queryString } from 'common/utils/rxjs-ajax';

export const linkToInviteToBison = ({
  email,
  body,
  subject,
}: {
  email: string;
  body: string;
  subject: string;
}): string => {
  return encodeURI(`mailto:${email}?subject=${subject}&body=${body}`);
};

export const linkToHelpCenter = (): string => {
  const langRoute = I18next.t('l10n.helpCenterLinkParam');
  return encodeURI(`https://support.bisonapp.com/hc/${langRoute}`);
};

export const linkToSupportForm = (user: User): string => {
  const langRoute = I18next.t('l10n.helpCenterLinkParam');
  const { id, username, address, firstName, lastName } = user;
  const os = `${platform.os}`;
  const browser = `${platform.name} ${platform.version}`;
  const version = window.env.VERSION || '';
  // both ticket_form_id and queryParamNameToMiscellaneous have hardcoded values - they are specific to the help center
  const ticket_form_id = 360000141778;
  const queryParamNameToMiscellaneous = 'tf_17941118169757';
  const misc = {
    UserID: id,
    Land: address?.country,
    OS: os,
    browser,
    version,
    ['Gesendet von']: `${firstName} ${lastName}`,
  };

  const queryParams = queryString({
    ticket_form_id,
    tf_anonymous_requester_email: username,
    [queryParamNameToMiscellaneous]: Object.entries(misc)
      .map(([key, value]) => `${key}:${value}`)
      .join(','),
  });

  return `https://support.bisonapp.com/hc/${langRoute}/requests/new${queryParams}`;
};
