import { OrderType } from 'common/const';
import { SpecificKeys } from 'types/tradingRules';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useTradingTitle = ({
  type,
  specificKey,
  assetName,
}: {
  type: OrderType;
  specificKey: SpecificKeys;
  assetName: string;
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isPrevScreenIsBuySell = location?.state?.isFromBuySellScreen;

  // stop order title
  if (specificKey === 'stopPrice')
    return isPrevScreenIsBuySell
      ? t(`home.currency.${type}OrderTitle`, {
          currency: assetName,
        })
      : t(`stopOrders.addTitle.${type}`);

  // limit order
  return isPrevScreenIsBuySell
    ? t(`home.currency.${type}OrderTitle`, { currency: assetName })
    : t(`automation.web_${type}_creationTitle`);
};

export default useTradingTitle;
