import React, { useEffect, useRef, useState } from 'react';
import * as formatting from 'common/utils/formatting';
import { useSelector } from 'react-redux';
import { getAssetsAsEntityDictionary } from 'store/slices/assets/selectors';
import { FormikProps } from 'formik';
import { useTracking } from 'common/tracking/hooks';
import { State } from 'store/types/store';
import { ASSET_CLASSES } from 'types/assets';

type Props = {
  isCrypto?: boolean;
  cryptoName?: string;
  currencyPrecision?: number;
  checkCursorPosition: (
    { target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formattingValue: string,
  ) => void;
  onFocus?: () => void;
  onChange: (value: string) => void;
  onBlur?: FormikProps<{
    limitPrice: number;
    cryptoAmount: number;
    spendAmount: number;
  }>['handleBlur'];
  setIsActive?: (value: boolean) => void;
  formatOnBlur?: boolean;
  fieldValue: string;
  testId?: string;
};

export const useCryptoEuroInputActions = ({
  isCrypto,
  cryptoName,
  currencyPrecision,
  checkCursorPosition,
  onFocus = () => null,
  onBlur,
  onChange,
  setIsActive = () => null,
  formatOnBlur = false,
  fieldValue,
  testId,
}: Props) => {
  const trackAppEvent = useTracking();
  const cryptoAssets = useSelector((state: State) =>
    getAssetsAsEntityDictionary(state, ASSET_CLASSES.CRYPTO),
  );

  const formatInputValue = (value: string, options?: { fillDecimals?: boolean }) => {
    const { fillDecimals } = options ?? {};

    return isCrypto
      ? formatting.inputFormatCrypto(
          value,
          cryptoName ?? '',
          cryptoAssets[cryptoName?.toLowerCase() ?? '']?.cryptoDetails?.decimalPlaces,
          { fillDecimals },
        )
      : formatting.inputFormatCurrency(value, currencyPrecision, undefined, { fillDecimals });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattingValue = formatInputValue(event.target.value);
    onChange(formattingValue);
    checkCursorPosition(event, formattingValue);
  };

  const handleBlur = (e: React.FocusEvent) => {
    if (onBlur) {
      onBlur(e);
    }
    setIsActive(false);

    if (formatOnBlur) {
      const formattedValue = formatInputValue(fieldValue, { fillDecimals: true });
      onChange(formattedValue);
    }

    if (testId)
      trackAppEvent('focusOutTextField', {
        componentId: testId,
        componentType: 'CryptoEuroInput',
        text: fieldValue,
      });
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
    onFocus();
    setIsActive(true);

    if (testId)
      trackAppEvent('focusInTextField', {
        componentId: testId,
        componentType: 'CryptoEuroInput',
        text: fieldValue,
      });
  };

  return { handleChange, handleBlur, handleFocus, formatInputValue };
};

export const useCursorPositionTracking = () => {
  const field = useRef<HTMLInputElement>();
  const [cursorPosition, setCursorPosition] = useState(0);

  const checkCursorPosition = (
    { target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    formattingValue: string,
  ) => {
    const { value, selectionStart } = target;
    let position = Number(selectionStart);

    if (formattingValue.length < value.length) {
      position -= Math.abs(formattingValue.length - value.length);
    }

    if (formattingValue.length > value.length) {
      position += Math.abs(formattingValue.length - value.length);
    }

    setCursorPosition(position);
  };

  useEffect(() => {
    field.current?.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition]);

  return { checkCursorPosition, field };
};
