import { RootEpic } from 'types/common';
import { filter, map } from 'rxjs/operators';
import { kycStatuses } from 'common/const';
import { actions as remotePersistActions } from 'redux-remote-persist';
import { combineEpics, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { PayloadAction } from '@reduxjs/toolkit';
import { FETCH_USER_KYC_STATUS_SUCCESS } from 'store/actions/auth';

const watchKycConfirmed: RootEpic = (
  action$: Observable<
    PayloadAction<{
      kycStatus: string;
      kycRenewStatus: string;
    }>
  >,
) =>
  action$.pipe(
    ofType(FETCH_USER_KYC_STATUS_SUCCESS),
    filter(({ payload }) => payload.kycStatus === kycStatuses.Confirmed),
    map(() => remotePersistActions.rehydrate()),
  );

export default combineEpics(watchKycConfirmed);
