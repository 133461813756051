import { KycStatus, kycStatuses, supportedStocksCountries } from 'common/const';
import {
  ASSET_CLASSES,
  ASSET_TAGS,
  ASSET_SECURITY_CLASSES,
  AssetClassInfo,
  AssetTag,
  AssetCategory,
  ASSET_CATEGORY,
  AssetClass,
  AssetSecurityClass,
} from 'types/assets';
import {  PortfolioAssetClassInfo } from 'types/portfolio';

export const canUseSecurities = (
  isFeatureStocksEnabled: boolean,
  isStocksEnabledByUser: boolean,
  cryptoKycStatus: KycStatus,
  stocksKycStatus: KycStatus,
  isB2bUser: boolean,
  isDemo: boolean,
  kycData: any,
  backendIsEligibleCandidateForStocks: boolean,
): {
  stocksEnabled: boolean;
  canTradeStocks: boolean;
  isUserSwitchStocksAvailable: boolean;
  isEligibleForStocks: boolean;
} => {
  const { nationality, country } = kycData;

  const isFeatureStocksActive = isFeatureStocksEnabled && isStocksEnabledByUser;

  const isCountryFitForStocks =
    supportedStocksCountries.includes(country) && supportedStocksCountries.includes(nationality);

  const cryptoKycStatusConfirmed = cryptoKycStatus === kycStatuses.Confirmed;

  const isDemoOnlyUser = !cryptoKycStatusConfirmed && isDemo;

  const stocksKycStatusConfirmed =
    stocksKycStatus === kycStatuses.Confirmed || stocksKycStatus === kycStatuses.Pending;

  const isEligibleForStocks =
    isFeatureStocksActive &&
    cryptoKycStatusConfirmed &&
    !isB2bUser &&
    isCountryFitForStocks &&
    backendIsEligibleCandidateForStocks;

  const isStocksEnabled =
    isFeatureStocksEnabled &&
    cryptoKycStatusConfirmed &&
    stocksKycStatusConfirmed &&
    !isB2bUser &&
    isCountryFitForStocks;

  const stocksEnabled =
    isDemoOnlyUser || // Initial status before user is crypto KYC-ed and can only be in DEMO mode
    isEligibleForStocks || // User is crypto KYC-ed, is not stocks KYC-ed, but is eligible to do stocks KYC and wants stocks to be displayed
    isStocksEnabled; // User is stocks KYC-ed

  // Can a user actually perform a trade.
  const canTradeStocks = isStocksEnabled && isEligibleForStocks && !isDemo; // User is stocks KYC-ed

  const isUserSwitchStocksAvailable =
    isFeatureStocksEnabled &&
    window.env.TOGGLE_SWITCH_STOCKS_ACTIVE === 'true' &&
    cryptoKycStatusConfirmed &&
    !isB2bUser &&
    isCountryFitForStocks &&
    !stocksKycStatusConfirmed &&
    backendIsEligibleCandidateForStocks;

  return {
    stocksEnabled,
    canTradeStocks,
    isUserSwitchStocksAvailable, // Should the Switch be available in account side menu
    isEligibleForStocks,
  };
};

export const isSecurity = (asset: AssetClassInfo): boolean => {
  return asset.securityClass !== undefined;
};

export const isPortfolioAssetSecurity = (asset: PortfolioAssetClassInfo): boolean => {
  return !!asset.assetSecurityClass;
};

export const transformAssetTagToAssetClassInfo = (tag: AssetTag): AssetClassInfo => {
  if (tag === ASSET_TAGS.CRYPTO) return { class: ASSET_CLASSES.CRYPTO, securityClass: undefined };

  switch (tag) {
    case ASSET_TAGS.COMMODITY:
      return { class: ASSET_CLASSES.SECURITY, securityClass: ASSET_SECURITY_CLASSES.ETC };
    case ASSET_TAGS.ETF:
      return { class: ASSET_CLASSES.SECURITY, securityClass: ASSET_SECURITY_CLASSES.ETF };
    case ASSET_TAGS.LONG_SHORT:
      return { class: ASSET_CLASSES.SECURITY, securityClass: ASSET_SECURITY_CLASSES.STOCK };
    case ASSET_TAGS.STOCK:
      return { class: ASSET_CLASSES.SECURITY, securityClass: ASSET_SECURITY_CLASSES.STOCK };
    default:
      return { class: ASSET_CLASSES.SECURITY, securityClass: ASSET_SECURITY_CLASSES.STOCK };
  }
};

export const transformAssetTagToAssetCategory = (tag: AssetTag): AssetCategory | undefined => {
  switch (tag) {
    case ASSET_TAGS.COMMODITY:
      return ASSET_CATEGORY.COMMODITY;
    case ASSET_TAGS.LONG_SHORT:
      return ASSET_CATEGORY.LONG_SHORT;
    default:
      return undefined;
  }
};

export const transformAssetClassInfoToAssetTag = (
  assetClass: AssetClass,
  assetSecurityClass?: AssetSecurityClass,
): AssetTag | 'etn' | 'etc' => {
  if (assetClass === ASSET_CLASSES.CRYPTO) return ASSET_TAGS.CRYPTO;

  return assetSecurityClass?.toLowerCase() as AssetTag;
};
