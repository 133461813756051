import React from 'react';
import { useModalNavigate } from 'common/hooks/useModalNavigate';
import { Link, Path } from 'react-router-dom';
import styled from 'styled-components';
import { Theme } from 'types/theme';

type Props = {
  to: string | Partial<Path>;
  children: string | JSX.Element;
};

/**
 * A custom React component that handles navigation within a modal to avoid page transition bugs.
 *
 * Note: This component includes a workaround to prevent page transition bugs within modals. Once the underlying issue
 * causing the transition bug is resolved, you can safely remove or replace this component with a standard React Router `Link`.
 *
 * @component
 * @example
 * // Usage within a modal:
 * // <ModalLink to="/dashboard">Go to Dashboard</ModalLink>
 *
 * @param {string} to - The target URL to navigate to.
 * @param {React.ReactNode} children - The content of the link.
 * @returns {React.ReactElement} The rendered component.
 */
const StyledLink = styled(Link)`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
  && {
    text-decoration: none;
  }
`;

function ModalLink({ to, children }: Props) {
  const navigate = useModalNavigate();

  return (
    <StyledLink
      to={to}
      onClick={(event) => {
        navigate(to, event);
      }}
    >
      {children}
    </StyledLink>
  );
}

export default ModalLink;
