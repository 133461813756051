/* eslint-disable import/prefer-default-export */
import { put, delay } from 'redux-saga/effects';
import * as notificationBannerActions from 'store/actions/notificationBanner';

import type { Saga } from 'redux-saga';
import { ApiError } from 'types/error';

const errorList = {};
export function* handleApiError(apiFn: any, error: ErrorEvent): Saga<void> {
  if (apiFn in errorList || !error.message) {
    return;
  }
  // show notification banner
  yield put(notificationBannerActions.notifyError({ message: error.message }));
  errorList[apiFn] = error;
  // wait a bit before allowing another error notification for specified apiFn
  yield delay(10000);
  delete errorList[apiFn];
}
