const dimensions = {
  inputHeight: 50,
  btnBorderRadius: 5,
  btnHeight: 40,
  btnSmallHeight: 30,
  btnMargin: 12,
  btnWidth: 384,
  switchWidth: 52,
  switchHeight: 27,
  checkboxSide: 33,
  checkboxIconSize: 22,
  newCheckboxSide: 22,
  radioSide: 20,
  radioLineHeight: 55,
  suggestionsHeight: 48,
  pickerHeight: 48,
  topBarHeight: 62,
  paperTradingBannerHeight: 72,
  paperTradingBannerHeightMobile: 30,
  navigationBarHeight: 40,
  expandedNavigationBarHeight: 140,
  tabBarHeight: 40,
  tabBarIconSize: 25,
  tinyPadding: 4,
  smallPadding: 8,
  regularMobilePadding: 16,
  mediumPadding: 20,
  largePadding: 39,
  hugePadding: 52,
  footerHeight: 50,
  listElementMinHeight: 60,
  maxContentWidth: 1085,
  listItemHeight: 155,
  formWidth: 450,
  maxCenteredContentWidth: 600,
  transactionItem: 74,
  standardPaddings: 35,
  widePopup: 950,
  valueHeight: 48,
  modalMaxWidth: 640,
  modalMinHeight: 320,
  modalMaxHeight: 640,
  modalMinBodyHeight: 280,
  modalPadding: 32,
  modalPaddingReduced: 24,
  modalHeaderPaddingRight: 60,
  modalHeaderMediumPaddingRight: 44,
  modalContentPaddingRight: 28,
  modalContentMediumPaddingRight: 24,
  modalBorderRadius: 12,
  smallIndent: 10,
  mobileMenu: 70,
  mobileMenuTablet: 104,
  tabletPadding: 30,
};

export default dimensions;
