import { RootEpic } from 'types/common';
import { catchError, filter, map, pluck, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { getAccessToken } from 'store/selectors/auth';
import { handleAjaxError } from 'store/epics/auth';
import I18n from 'i18next';
import {
  fetchPortfolioFailure,
  fetchPortfolioRequest,
  fetchPortfolioSuccess,
  invalidatePortfolio,
  syncPortfolio,
} from 'store/slices/portfolio/actions';
import { getBaseUrlForTrading } from 'common/api';
import { combineEpics, ofType } from 'redux-observable';
import { PortfolioResponse } from 'store/types/portfolio';
import { isOfType, isPresent } from 'safetypings';
import { getPortfolio } from 'store/slices/portfolio/selectors';
import { interval, Observable } from 'rxjs';
import { AnyAction } from 'redux';
import { TTL_PORTFOLIO } from 'common/utils/portfolio';

const fetchPortfolio: RootEpic = (action$: Observable<AnyAction>, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(fetchPortfolioRequest.type)),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: getBaseUrlForTrading(accessToken).concat('/portfolio/assets'),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response: PortfolioResponse[]) => response[0]),
            map((response: PortfolioResponse) => fetchPortfolioSuccess({ receivedAt: Date.now(), response })),
          ),
        ),
        catchError(
          handleAjaxError(
            action$,
            (error) => fetchPortfolioFailure({ errMsg: I18n.t('error.portfolio') }),
            {},
            { message: I18n.t('error.portfolio') },
          ),
        ),
      ),
    ),
  );

const syncPortfolioEpic: RootEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(syncPortfolio.START.type),
    switchMap(() =>
      interval(TTL_PORTFOLIO).pipe(
        startWith(0),
        map(invalidatePortfolio),
        takeUntil(action$.pipe(ofType(syncPortfolio.STOP.type))),
      ),
    ),
  );

const triggerPortfolioFetchEpic: RootEpic = (action$: Observable<AnyAction>, state$) =>
  action$.pipe(
    filter(invalidatePortfolio.match),
    map(() => getPortfolio(state$.value)),
    filter(({ loading }) => !loading),
    map(() => fetchPortfolioRequest()),
  );

export default combineEpics(fetchPortfolio, syncPortfolioEpic, triggerPortfolioFetchEpic);
