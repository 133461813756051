import Icon from 'common/components/Icon';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SecurityMarketStatus } from 'types/status';
import getBannerDetails from './helpers';
import { BannerText, BannerWrapper, CircleIcon } from './styles';

const StockTradingHours = ({
  stockMarketStatus,
}: {
  stockMarketStatus: SecurityMarketStatus;
}): ReactElement | null => {
  const { t } = useTranslation();
  const { isWeekend, isHoliday } = stockMarketStatus;

  const { icon, text } = getBannerDetails(isWeekend, isHoliday, stockMarketStatus, t);

  return (
    <BannerWrapper>
      <CircleIcon>
        <Icon name={icon} sizeValue="regular" />
      </CircleIcon>
      <BannerText>{text}</BannerText>
    </BannerWrapper>
  );
};

export default StockTradingHours;
