import styled from 'styled-components';
import _Icon from 'common/components/Icon';
import { dimensions } from 'common/styles';
import { Theme } from 'types/theme';

export const Icon = styled(_Icon)`
  cursor: pointer;
`;

export const ModalContent = styled.div.attrs({
  className: 'body-text',
})`
  img {
    max-width: 100%;
  }
  padding: ${dimensions.mediumPadding}px;

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['3'].fontSize};
    line-height: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['4'].lineHeight};
  }

  h2 {
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['4'].fontSize};
    line-height: ${({ theme }: { theme: Theme }) => theme.typography.heading.size['3'].fontSize};
  }
`;
