export const loginRetryDelays = Object.freeze({
  min: { maxReps: 10, delay: 5000 }, // Delay for up to 10 attempts
  medium: { maxReps: 20, delay: 10000 }, // Delay for up to 20 attempts
  max: { maxReps: null, delay: 30000 }, // Delay for more than 20 attempts
});

export const errorTooManyRetriesMessageCodes = Object.freeze({
  smallBlock: 'registration.errorTooManyRetries.smallBlock',
  mediumBlock: 'registration.errorTooManyRetries.mediumBlock',
  bigBlock: 'registration.errorTooManyRetries.bigBlock',
});
export const BETA_DOMAINS = ['sowalabs.com', 'bsdigital.com'];
