/* eslint-disable @typescript-eslint/no-unsafe-return */
import { StakingCryptoInfo, StakingReward } from 'types/staking';
import { STAKING_SLICE_NAME } from 'common/const/slices';
import { State } from 'store/types/store';
import { stakingRewardAdapter } from 'store/slices/staking/adapter';
import { Order } from 'types/currency';
import { orderMoneySubtypes, orderSubTypes } from 'common/const';
import { getAsset } from 'store/slices/assets/selectors';
import { ASSET_CLASSES } from 'types/assets';

const selectSlice = (state: State) => state[STAKING_SLICE_NAME];

const stakingRewardSelectors = stakingRewardAdapter.getSelectors<State>(
  (state) => state.staking.rewardsHistory,
);

export const selectStakingInfoAllCryptos = (state: State): Record<string, StakingCryptoInfo> => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.overview.info;
};

export const selectIsStakingInfoLoading = (state: State): boolean => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.overview.isLoading;
};

export const selectIsStakingInfoLastFetchedTime = (state: State): string | null => {
  const selectedSlice = selectSlice(state);
  return selectedSlice.overview.lastFetchedAt;
};

export const selectAprForSpecificCrypto =
  (crypto: string) =>
  (state: State): StakingCryptoInfo => {
    return selectStakingInfoAllCryptos(state)[crypto] ?? null;
  };

export const getStakeUnstakeState = (state: State) => selectSlice(state).stakeUnstake;

export const getRewardsHistory = (state: State): StakingReward[] => stakingRewardSelectors.selectAll(state);

export const getRewardsHistoryAsOrders = (state: State): Order[] => {
  const rewards = getRewardsHistory(state);
  return rewards.map(
    ({
      assetCode,
      rewardCurrencyVolume,
      rewardVolume,
      accountedAt,
      reference,
      oldestRecord,
      marketPrice,
    }) => {
      const asset = getAsset(state, ASSET_CLASSES.CRYPTO, assetCode.toLowerCase(), undefined);
      return {
        currency: assetCode,
        volume: rewardVolume,
        accountedAt,
        oldestRecord,
        reference,
        marketPrice,
        marketValue: rewardCurrencyVolume,
        subtype: orderSubTypes.Deposit,
        moneySubType: orderMoneySubtypes.STAKING_REWARD,
        displayName: asset?.displayName,
        cryptoDetails: asset?.cryptoDetails,
      } as Order;
    },
  );
};

export const getRewardsHistoryItemsCount = (state: State): number =>
  stakingRewardSelectors.selectAll(state)?.length ?? 0;

export const getRewardsHistoryItemsLoading = (state: State): boolean => state.staking.rewardsHistory.loading;

export const getStakingRewardsFullyLoaded = (state: State) => state.staking.rewardsHistory.fullyLoaded;

export const getStakingRewardsReference = (state: State) => state.staking.rewardsHistory.reference;

export const getStakingChart = (state: State) => state.staking.chart;

export const getStakingNextRewardInfoForAllCryptos = (state: State) => state.staking.nextRewardInfo;

export const getStakingBlockedModalStatus = (state: State) => state.staking.stakingBlockedModalStatus;

export const getPendingOrders = (state: State) => state.staking.pendingOrders;
