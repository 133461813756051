import { put, select, fork, take, all, takeLatest, race, call, cancel, delay } from 'redux-saga/effects';
import { fetchEntity } from 'store/sagas/auth';
import * as api from 'common/api';
import { kycStatuses, statusValues } from 'common/const';
import {
  getStatus,
  getStatusMessage,
  getSecurityMarketStatus,
  getStockMarketStatusIsUpdating,
} from 'store/selectors/status';
import { getNotificationMap } from 'store/selectors/notification';
import * as actions from 'store/actions/status';
import * as authActions from 'store/actions/auth';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { getAccessToken, getLoggedIn, getSecuritiesKycStatus } from 'store/selectors/auth';
import { LOCATION_CHANGE } from 'store/actions/navigation';

const appStatusId = 'appStatus';
const checkBisonStatus = fetchEntity.bind(null, actions.checkBisonStatus, api.checkBisonStatus, false);
const STATUS_TTL = 7 * 1000;
const MARKET_STATUS_TTL = 30 * 1000;
// check the healgth status of bison and lock user out if the health status is 'down'

export function* checkStatusOnce(): Saga<void> {
  let prevStatus = yield select(getStatus);

  const stocksKycStatus = yield select(getSecuritiesKycStatus);
  const isLoggedIn = yield select(getLoggedIn);

  const isLoggedInAndStocksUser =
    isLoggedIn &&
    (stocksKycStatus === kycStatuses.Confirmed ||
      stocksKycStatus === kycStatuses.Pending ||
      stocksKycStatus === kycStatuses.Submitted);

  yield fork(checkBisonStatus, isLoggedInAndStocksUser);
  const { success, failure } = yield race({
    success: take(actions.CHECK_BISON_STATUS.SUCCESS),
    failure: take(actions.CHECK_BISON_STATUS.FAILURE),
  });
  if (success) {
    const status = yield select(getStatus);
    const message = yield select(getStatusMessage);
    if (prevStatus !== status) {
      const notificationsMap = yield select(getNotificationMap);
      const oldNotification = notificationsMap[appStatusId];
      if (oldNotification) {
        yield put(notificationBannerActions.clearNotification(oldNotification));
      }
    }
    switch (status) {
      case statusValues.down:
        if (prevStatus !== status) {
          // clear all notifications
          yield put(
            notificationBannerActions.notifyWarn({
              toastType: appStatusId,
              message,
              options: {
                autoClose: false,
                closeOnClick: false,
                closeButton: false,
                draggable: false,
              },
            }),
          );
          yield put(actions.startMaintenance());
        }
        break;
      case statusValues.warn:
        if (prevStatus === statusValues.down) {
          // re-login after status is OK again
          yield put(actions.endMaintenance());
        }
        if (prevStatus !== status) {
          // show long-lasting warning alert
          yield put(
            notificationBannerActions.notifyWarn({
              toastType: appStatusId,
              message,
              options: {
                autoClose: 24 * 60 * 60,
              },
            }),
          );
        }
        break;
      case statusValues.ok:
        if (prevStatus === statusValues.down) {
          // re-login after status is OK again
          yield put(actions.endMaintenance());
        }
        break;
      default:
        break;
    }
    prevStatus = status;
  }

  return { failure };
}

function* periodicStatusCheck(): Saga<void> {
  while (true) {
    yield call(checkStatusOnce);
    yield race({
      delay: delay(STATUS_TTL),
      routeChange: take(LOCATION_CHANGE),
    });
  }
}

function* checkStatusTillCancelled(): Saga<void> {
  while (true) {
    const { task, error } = yield race({
      task: call(periodicStatusCheck),
      error: take(authActions.AUTH_INIT_ERROR),
    });
    if (error) {
      yield cancel(task);
    }
  }
}

function* watchStatusCheck(): Saga<void> {
  const stocksKycStatus = yield select(getSecuritiesKycStatus);
  const isLoggedIn = yield select(getLoggedIn);

  const isLoggedInAndStocksUser =
    isLoggedIn &&
    (stocksKycStatus === kycStatuses.Confirmed ||
      stocksKycStatus === kycStatuses.Pending ||
      stocksKycStatus === kycStatuses.Submitted);

  yield takeLatest(actions.USER_CHECK_BISON_STATUS, checkBisonStatus, isLoggedInAndStocksUser);
}

function* watchBgSyncMarketStatus(): Saga<void> {
  while (true) {
    const isFeatureStocksEnabled = window?.env?.FEATURE_STOCKS_ENABLED || false;
    if (isFeatureStocksEnabled) {
      const accessToken = yield select(getAccessToken);
      const { lastUpdated } = yield select(getSecurityMarketStatus);
      const isUpdating = yield select(getStockMarketStatusIsUpdating);
      if (accessToken != null) {
        if (lastUpdated != null) {
          const delayForNextUpdate = MARKET_STATUS_TTL - (Date.now() - new Date(lastUpdated).getTime());
          yield delay(delayForNextUpdate > 0 ? delayForNextUpdate : 0);
        }
        if (!isUpdating) {
          yield put(actions.syncStockMarketStatus());
        }
      } else yield delay(1000);
    } else yield delay(1000);
  }
}

export default function* root(): Saga<void> {
  yield all([fork(checkStatusTillCancelled), fork(watchStatusCheck), fork(watchBgSyncMarketStatus)]);
}
