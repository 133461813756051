import styled from 'styled-components';
import { Theme } from 'types/theme';
import { devices } from 'common/styles';

export const NameDiv = styled.div`
  flex-grow: 2;
  font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.standard.fontSize};
  line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.standard.lineHeight};

  @media ${devices.maxTablet} {
    font-size: ${({ theme }: { theme: Theme }) => theme.typography.text.small.fontSize};
    line-height: ${({ theme }: { theme: Theme }) => theme.typography.text.small.lineHeight};
  }
`;

export const ValueDiv = styled(NameDiv).attrs({ className: 'medium-weight' })`
  text-align: right;
  color: ${({ theme, color }: { theme: Theme; color?: string }) => color ?? theme.color.foreground.primary};
`;

export const ListItemDiv = styled.div`
  display: flex;

  &:not(:last-child) {
    padding-bottom: 6px;
  }

  &:not(:first-child) {
    padding-top: 6px;
  }
`;
