/* eslint-disable @typescript-eslint/no-shadow */
import { useMemo } from 'react';
import { generatePath, matchPath, useLocation, useMatch } from 'react-router-dom';
import { paths } from 'common/urls';
import { checkUrlInPathname, MAIN_ROUTES_WITH_BACK_ARROW_ROUTE_DEFINITIONS } from 'common/const/backArrow';
import { AssetSecurityClass } from 'types/assets';
import { useDesktop } from './useMedia';

const useMainPages = () => {
  const { pathname: locationPathname } = useLocation();
  const isDesktopLayout = useDesktop();

  const match = useMatch(locationPathname);

  const pathname = match?.pattern.path;

  const goBackPages = [paths.INBOX, paths.DEPOSIT_INSTANT, paths.DEPOSIT_TRANSFER];

  const pagesWithFooter = [paths.SETTINGS];

  return useMemo(() => {
    // backUrl url for arrow
    let backUrl;
    const url = checkUrlInPathname({ pathname, isDesktopLayout });

    if (url === paths.KYC) {
      backUrl = paths.PORTFOLIO;
    } else if (url === paths.TRADE_CURRENCY || url === paths.TECH_INDICATORS) {
      const pathNameSplitList = pathname?.split('/');

      if (!pathNameSplitList) backUrl = undefined;

      // If it is security then we need to build that path with parameters.
      const tradeAssetRouteData = matchPath({ path: paths.TRADE_ASSET, end: true }, pathname ?? '');

      if (!tradeAssetRouteData?.params) {
        // Set btc as a default
        backUrl = generatePath(paths.MARKET_OVERVIEW_CRYPTO, {
          currency: 'btc',
        });
      } else {
        const assetSecurityClass = tradeAssetRouteData.params.assetSecurityClass as
          | AssetSecurityClass
          | undefined;
        const code = tradeAssetRouteData.params.assetCode!;

        // If assetSecurityClass is set then it is security trade.
        backUrl = assetSecurityClass
          ? generatePath(paths.MARKET_OVERVIEW_SECURITY, {
              class: assetSecurityClass,
              isin: code,
            })
          : generatePath(paths.MARKET_OVERVIEW_CRYPTO, {
              currency: code,
            });
      }
    } else {
      backUrl = url !== pathname ? url : null;
    }

    // isMainCategory for visibility mobile menu
    const isMain = Object.keys(MAIN_ROUTES_WITH_BACK_ARROW_ROUTE_DEFINITIONS).includes(pathname ?? '');
    const isPortfolio = pathname?.includes(paths.PORTFOLIO) ?? false;
    const isTransactionHistory = matchPath(paths.ORDERS, pathname ?? '');
    const isPriceAlerts = matchPath(paths.PRICE_ALERTS_NEW, pathname ?? '');
    const isMainCategory = isMain || isPortfolio || isTransactionHistory || isPriceAlerts;

    // isShouldGoBack for arrow back
    const isShouldGoBack = goBackPages.includes(pathname ?? '');

    // isShowFooter for visibility footer on mobile
    const isShowFooter = pagesWithFooter.includes(pathname ?? '');

    return {
      backUrl,
      isMainCategory,
      isShouldGoBack,
      isShowFooter,
    };
  }, [pathname, isDesktopLayout]);
};

export default useMainPages;
