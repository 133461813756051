/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useSelector } from 'react-redux';
import { getSecurityMarketStatus } from 'store/selectors/status';
import { getIsOutOfTradingHours } from 'common/utils/time';
import { SecurityMarketStatus } from 'types/status';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { privatePaths } from 'common/urls';
import { useSecurities } from 'common/hooks/useSecurities';

const pathsForShowing = [privatePaths.MARKET_OVERVIEW, privatePaths.MARKET_OVERVIEW_SECURITY];

export const useStocksTradingBanner = (): boolean => {
  const { pathname } = useLocation();
  const stockMarketStatus: SecurityMarketStatus = useSelector(getSecurityMarketStatus);
  const { canTradeStocks } = useSecurities();

  const { isWeekend, isHoliday } = stockMarketStatus;
  const isOutOfTradingHours = getIsOutOfTradingHours(stockMarketStatus);

  const isPathSuitableForShowing = pathsForShowing.some((privatePath) =>
    matchPath(
      {
        path: privatePath,
        caseSensitive: false,
      },
      pathname,
    ),
  );

  return (isOutOfTradingHours || isWeekend || isHoliday) && canTradeStocks && isPathSuitableForShowing;
};
