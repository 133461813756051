import { Navigate } from 'react-router-dom';
import * as React from 'react';
import { ReactElement } from 'react';

type ClosedFolderRedirectProps = {
  pathname: string;
};

const ClosedFolderRedirect = ({ pathname }: ClosedFolderRedirectProps): ReactElement => {
  const slicedPAth = pathname.slice(0, -1);

  return <Navigate to={slicedPAth} replace />;
};

export default ClosedFolderRedirect;
