import { combineEpics } from 'redux-observable';
import { RootEpic } from 'types/common';
import { catchError, filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { getAccessToken } from 'store/selectors/auth';
import { getBaseUrlForUserServices } from 'common/api';
import { handleAjaxError } from 'store/epics/auth';
import { ReferralReward } from 'types/referral';
import {
  fetchReferralFailure,
  fetchReferralRequest,
  fetchReferralSuccess,
  invalidateReferral,
} from 'store/slices/referral/actions';

const watchFetchReferral: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(fetchReferralRequest.match),
    switchMap(() => {
      const accessToken = getAccessToken(state$.value);
      if (!accessToken) return;

      return ajax({
        url: getBaseUrlForUserServices(accessToken).concat('/referral'),
        method: 'GET',
        withCredentials: true, // include cookies
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        pluck('response'),
        map((response: ReferralReward | undefined) => fetchReferralSuccess({ response })),
        catchError(handleAjaxError(action$, () => fetchReferralFailure())),
      );
    }),
  );

const watchInvalidate: RootEpic = (action$) => {
  return action$.pipe(filter(invalidateReferral.match), map(fetchReferralRequest));
};

export default combineEpics(watchFetchReferral, watchInvalidate);
