import { useSelector } from 'react-redux';
import { useUserGrantedRoles } from 'common/hooks/useUserGrantedRoles';
import { ROUTE_MANAGEMENT_ROLES } from 'common/const/user';
import { getIsUserInPaperTrading } from 'store/selectors/auth';
import { StakingRoleBlockedReason } from 'types/staking';

export const useStakingRoleBlockedReason = (): StakingRoleBlockedReason => {
  const isDemo = useSelector(getIsUserInPaperTrading);
  const grantedRoles = useUserGrantedRoles();

  if (isDemo) return 'Demo';
  return grantedRoles.includes(ROUTE_MANAGEMENT_ROLES.STAKING_AVAILABLE) ? undefined : 'NoUserRole';
};
