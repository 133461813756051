import { Theme, ThemeName } from 'types/theme';
import Color from 'color';

export const getColorWithNegateOnLowLuminosity = (themeName: ThemeName, baseColor?: string) => {
  let baseColorParsed = Color(baseColor);

  return baseColorParsed.luminosity() === 0 && themeName === 'dark' ? Color(baseColor).negate() : baseColor;
};

export const getCryptoSpecificGradient = (theme: Theme, themeName: ThemeName, baseColor?: string) => {
  if (!baseColor)
    return {
      lineColor: theme.color.charts.marketChartLine,
      gradient: theme.color.charts.marketChartGradient,
    };

  let baseColorParsed = Color(baseColor);
  if (baseColorParsed.luminosity() === 0 && themeName === 'dark') baseColorParsed = Color(baseColor).negate();

  return {
    gradient: [baseColorParsed.alpha(0.8), baseColorParsed.alpha(0.444), baseColorParsed.alpha(0.06)],
    lineColor: baseColorParsed.alpha(1),
  };
};
