import { Fiat } from 'common/const';
import i18next from 'i18next';
import { SpecificKeys } from 'types/tradingRules';
import * as formatting from 'common/utils/formatting';

export const getInitialValues = (price: number, specificKey: SpecificKeys) => ({
  [specificKey]: price.toString(),
  cryptoAmount: `${(10 / price).toFixed(8)}`,
  spendAmount: '10',
});

export const getCurrencyLabels = (
  selectedFiatCurrency: Fiat,
  selectedCrypto: string,
  specificKey: SpecificKeys,
) => ({
  [specificKey]: `${selectedFiatCurrency.toUpperCase()}`,
  cryptoAmount: (selectedCrypto as unknown as string).toUpperCase(),
  spendAmount: selectedFiatCurrency.toUpperCase(),
});

export const validationSchemaCommonFields = {
  zero: i18next.t('automation.fieldErrors.web_zero'),
};

export const handleValidInput = (setter: (x: string) => void) => (value: string) => {
  const { decimalSeparator, groupingSeparator } = formatting.separators();
  const regex = new RegExp(`^([0-9]|${groupingSeparator})+(${decimalSeparator}[0-9]*)?$`);
  const zeroRegex = /^0[0-9]$/;
  if (zeroRegex.test(value)) return;
  if (!regex.test(value) && value !== '') return;
  setter(value);
};
