/* eslint-disable @typescript-eslint/no-unsafe-return */

import { State } from 'store/types/store';

export const getConfigFeature = (state: State) => state.configFeature;

export const getCryptoDepositEnabled = (state: State) => state.configFeature.CRYPTO_DEPOSIT_ENABLED;

export const getBackendStockRaffleStartDate = (state: State): Date =>
  new Date(state.configFeature.STOCKS_RAFFLE_START_DATE);

export const getBackendStockRaffleEndDate = (state: State): Date =>
  new Date(state.configFeature.STOCKS_RAFFLE_END_DATE);

export const getBackendStockRaffleAnnouncementDate = (state: State): Date =>
  new Date(state.configFeature.STOCKS_RAFFLE_ANNOUNCEMENT_DATE);

export const getIsTopUpsEnabled = (state: State): boolean => state.configFeature.TOP_UPS_ENABLED;

export const getKCCRaffleStartDate = (state: State): Date =>
  new Date(state.configFeature.KANSAS_CITY_CHIEFS_RAFFLE_START_DATE);

export const getKCCRaffleEndDate = (state: State): Date =>
  new Date(state.configFeature.KANSAS_CITY_CHIEFS_RAFFLE_END_DATE);
