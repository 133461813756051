/* eslint-disable @typescript-eslint/no-unsafe-return */

import { LegalAgreementsState } from 'store/types/legalAgreements';
import { State } from 'store/types/store';

export const getLegalAgreements = (state: State): LegalAgreementsState => state.legalAgreements;

export const getDisplayTradingReturnWaiver = (state: State): boolean =>
  state.legalAgreements.displayTradingReturnWaiver;

export const getAcceptedTermsAndConditionsForCountry = (state: State) =>
  state.legalAgreements.acceptedTermsAndConditionsForCountry;
