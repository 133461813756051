// SECURITIES
/**
 * This is how long a specific security's price will not be re-fetched.
 * Instead the stored/cached price will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const SECURITY_PRICE_DATA_TTL = 1000 * 60 * 5;

/**
 * This is how often the re-fetching of cryptos is re-calculated.
 * If the calculation states that a specific security is ready to be re-fetched its new price data will be retrieved from the server.
 *
 * The value is specified in milliseconds.
 */
export const SECURITY_PRICE_FETCH_INTERVAL_IN_MS = 1000 * 30;

/**
 * An endpoint from where prices for securities are retrieved.
 */
export const SECURITY_PRICES_ENDPOINT = '/prices/stockPrices';

// CRYPTO
/**
 * This is how long a specific crypto's price will not be re-fetched.
 * Instead the stored/cached price will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_PRICE_DATA_TTL = 1000 * 30;
/**
 * This is how long a specific crypto's price will not be re-fetched.
 * Instead the stored/cached price will be retrieved.
 *
 * The value is specified in milliseconds.
 */
export const CRYPTO_PRICE_FETCH_INTERVAL_IN_MS = 1000 * 30;

/**
 * An endpoint from where prices for cryptos are retrieved.
 */
export const CRYPTO_PRICES_ENDPOINT = '/prices';
