import styled from 'styled-components';
import { Theme } from 'types/theme';
import _Icon from 'common/components/Icon';
import { dimensions } from 'common/styles';
import { colors } from 'common/utils/theme/colors';
import imageBackground from 'assets/img/staking-intro-bg.svg';

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.text.attrs({ className: 'h3' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const TitleGreen = styled(Title)`
  color: ${colors.newSuccess} !important;
`;

export const BodyWrapper = styled.div`
  padding: 16px 0 5px;

  & > div {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
`;

export const InformationItem = styled.div`
  width: 100%;
  display: flex;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const Icon = styled(_Icon).attrs({
  sizeValue: 'regular',
})``;

export const Text = styled.div.attrs({ className: 'small-text' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const ItemTitle = styled(Text).attrs({ className: 'strong-weight' })`
  margin-bottom: ${dimensions.tinyPadding}px;
`;

export const Link = styled.a.attrs({
  className: 'small-text',
})`
  text-decoration: underline;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.focused};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${imageBackground});
  background-size: 100% 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 343px;

  & > div {
    margin-top: 0;
    min-width: unset;
  }
`;

export const LegalText = styled.div.attrs({ className: 'xxsmall-text' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.disabledText};
  text-align: center;
`;
