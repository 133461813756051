import styled from 'styled-components';

import bisonWinkLogo from 'assets/img/bison_wink.png';
import { devices, dimensions } from 'common/styles';
import { Theme } from 'types/theme';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  max-width: 100%;
  margin: 0 auto 40px;
`;

export const InfoText = styled.div`
  text-align: center;
  letter-spacing: 1;
  margin: ${dimensions.largePadding}px;
`;

export const SuccessText = styled.div.attrs({ className: 'strong-weight primary-color-text' })`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 40px;
  line-height: 54px;

  @media ${devices.tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`;

export const FailedText = styled(InfoText)`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.critical};
`;

export const BisonWink = styled.img.attrs({
  src: bisonWinkLogo,
})`
  display: block;
  text-align: center;
  width: 150px;
  height: auto;
  margin-bottom: ${dimensions.mediumPadding}px;
  margin: 0 auto;
`;

export const SuccessImageWrapper = styled.div`
  margin: 40px auto 0 auto;
  position: relative;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media ${devices.tablet} {
    margin: 0 auto;
  }
`;

export const DescriptionText = styled.span.attrs({ className: 'primary-color-text standard-text' })`
  white-space: pre-line;
  width: 360px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
`;

export const TextWrapper = styled.div`
  text-align: center;
  @media ${devices.tablet} {
    margin: 25px 20px 60px;
    padding: 0;
  }
`;
