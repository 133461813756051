export const SECURITY_CODE_LENGTH = 6;

export const getHiddenPhone = (phoneNumber: string): string =>
  phoneNumber
    .split('')
    .map((digit, idx, arr) => (idx === arr.length - 3 || idx === arr.length - 4 ? '*' : digit))
    .join('');

export const validateInput = (inputValue: string) => {
  if (inputValue.trim().length === SECURITY_CODE_LENGTH && !Number.isNaN(Number(inputValue))) {
    return true;
  }

  return false;
};
