import { State } from 'store/types/store';
import { ASSET_CLASSES, ASSET_SECURITY_CLASSES, AssetClass, AssetSecurityClass } from 'types/assets';
import { PortfolioAsset } from 'types/portfolio';
import { createSelector } from '@reduxjs/toolkit';
import { calcTotalValue, instrumentComparator } from 'common/utils/portfolio';
import { isEqualIgnoreCase } from 'common/utils/common';
import { instrumentsAdapter } from './adapter';

const instrumentsState = (state: State) => state.portfolio.instruments;

const instrumentsSelectors = instrumentsAdapter.getSelectors(instrumentsState);

export const getPortfolio = (state: State) => state.portfolio;

export const getAvailableCash = (state: State) => state.portfolio.cash.volume;

export const getAvailableAssetsByClass = createSelector(
  [instrumentsSelectors.selectAll, (state: State, props: { assetClass: AssetClass }) => props],
  (assets: PortfolioAsset[], { assetClass }) =>
    assets.filter((asset: PortfolioAsset) => asset.assetClass === assetClass),
);

export const getAvailableAssetsBySecurityClass = createSelector(
  [
    instrumentsSelectors.selectAll,
    (state: State, props: { assetSecurityClass: AssetSecurityClass }) => props,
  ],
  (assets: PortfolioAsset[], { assetSecurityClass }) =>
    assets.filter((asset: PortfolioAsset) => asset.assetSecurityClass === assetSecurityClass),
);

export const getAvailableCrypto = (state: State) =>
  getAvailableAssetsByClass(state, { assetClass: ASSET_CLASSES.CRYPTO });

export const getAvailableStocks = (state: State) =>
  getAvailableAssetsBySecurityClass(state, { assetSecurityClass: ASSET_SECURITY_CLASSES.STOCK });

export const getAvailableEtfs = (state: State) =>
  getAvailableAssetsBySecurityClass(state, { assetSecurityClass: ASSET_SECURITY_CLASSES.ETF });

export const getAvailableCurrency = (state: State) =>
  getAvailableAssetsByClass(state, { assetClass: ASSET_CLASSES.CURRENCY });

export const getAvailableOthers = createSelector(
  [instrumentsSelectors.selectAll],
  (assets: PortfolioAsset[]) =>
    assets.filter(
      (asset: PortfolioAsset) =>
        asset.assetClass !== ASSET_CLASSES.CRYPTO &&
        asset.assetClass !== ASSET_CLASSES.CURRENCY &&
        asset.assetSecurityClass !== ASSET_SECURITY_CLASSES.STOCK,
    ),
);

export const getSortedInstruments = createSelector(
  [
    getAvailableCrypto,
    getAvailableStocks,
    getAvailableCurrency,
    getAvailableOthers,
    instrumentsSelectors.selectAll,
  ],
  (crypto, stock, currency, others, total) => {
    const allInstruments = crypto.concat(stock).concat(others).concat(currency);
    const isFiatOnly = currency.length === total.length;
    const sortedInstruments = allInstruments.sort(instrumentComparator);

    return {
      crypto: crypto.sort(instrumentComparator),
      stock: stock.sort(instrumentComparator),
      currency: currency.sort(instrumentComparator),
      others: others.sort(instrumentComparator),
      totalLength: total.length,
      isFiatOnly,
      sortedInstruments,
      allInstruments,
    };
  },
);

export const getTotalPortfolioValue = createSelector([getPortfolio], (portfolio) =>
  calcTotalValue(portfolio.cash, portfolio.invested),
);

export const getPortfolioSecurityAsset = createSelector(
  [
    (state: State, props: { code: string; assetSecurityClass: AssetSecurityClass | undefined }) => props,
    getAvailableStocks,
    getAvailableEtfs,
    getAvailableOthers,
  ],
  ({ code, assetSecurityClass }, stocks, etfs, others) => {
    if (!code) return undefined;

    let items: PortfolioAsset[] = [];

    switch (assetSecurityClass) {
      case ASSET_SECURITY_CLASSES.STOCK:
        items = stocks;
        break;
      case ASSET_SECURITY_CLASSES.ETF:
        items = etfs;
        break;
      case ASSET_SECURITY_CLASSES.ETC:
      case ASSET_SECURITY_CLASSES.ETN:
        items = others.filter((asset: PortfolioAsset) => asset.assetSecurityClass === assetSecurityClass);
        break;
      default:
        break;
    }

    if (!items.length) return undefined;

    return items.find((asset: PortfolioAsset) => asset.entity.toLowerCase() === code);
  },
);

export const getPortfolioCryptoAsset = createSelector(
  [(state: State, code: string) => code, getAvailableCrypto],
  (code, crypto) => {
    if (!code) return undefined;

    if (!crypto.length) return undefined;

    return crypto.find(({ entity }: PortfolioAsset) => isEqualIgnoreCase(entity, code));
  },
);

export const getAvailableCryptoCodes = createSelector([getAvailableCrypto], (crypto): string[] =>
  crypto.map((asset: PortfolioAsset) => asset.entity),
);

export const getIsAnyCryptoAssetsStaked = createSelector([getAvailableCrypto], (crypto) =>
  // temp local type definition - whole commit will be reverted on staking release
  (
    crypto as (PortfolioAsset & {
      stakeVolume: number;
      unstakeVolume: number;
      pendingStakeVolume: number;
      stakingRewardVolume: number;
    })[]
  ).some((asset) => {
    const { stakeVolume = 0, unstakeVolume = 0, pendingStakeVolume = 0, stakingRewardVolume = 0 } = asset;
    return stakeVolume + unstakeVolume + pendingStakeVolume + stakingRewardVolume > 0;
  }),
);

export const getSortedAvailableCrypto = createSelector(
  [
    (state: State, comparator: (a: PortfolioAsset, b: PortfolioAsset) => number) => comparator,
    getAvailableCrypto,
  ],
  (comparator, crypto) => [...crypto].sort(comparator),
);
