import React from 'react';
import styled from 'styled-components';
import ModalTemplate from 'common/components/Modal';
import Button from 'common/components/Button';
import { useTranslation } from 'react-i18next';
import SuccessImg from 'assets/img/twoFASuccess.svg';
import ErrorImg from 'assets/img/twoFAFailure.svg';

type Props = {
  open: boolean;
  title: string;
  imgSrc: string;
  onClose: () => void;
};

export type DerivedModalProps = {
  open: boolean;
  onClose: () => void;
};

const Img = styled.img.attrs(({ src }: { src: string }) => ({
  src,
}))`
  display: block;
  text-align: center;
  width: 172px;
  height: auto;
  margin: 0 auto;
`;

const Title = styled.div.attrs({ className: 'xlarge-text strong-weight' })`
  text-align: center;
`;

export const DEFAULT_RESULT_VALUES = {
  SUCCESS: {
    title: 'settings.taxExemption.resultModal.successTitle',
    src: SuccessImg,
  },
  ERROR: {
    title: 'settings.taxExemption.resultModal.errorTitle',
    src: ErrorImg,
  },
};

const ResultModal = ({ open, title, imgSrc, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <ModalTemplate
      defaultOpen={open}
      onCancel={onClose}
      actions={<Button title={t('common.close')} onClick={onClose} size="modal" />}
    >
      <Img src={imgSrc} />
      <Title>{title}</Title>
    </ModalTemplate>
  );
};

export default ResultModal;
