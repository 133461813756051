import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOSS_TAX_EXEMPTIONS_SLICE_NAME } from 'common/const/slices';
import { LossTaxExemptionsData, LossTaxExemptionsResponse } from 'types/lossTaxExemptions';
import { initialState } from './initialState';

const lossTaxExemptionsSlice = createSlice({
  name: LOSS_TAX_EXEMPTIONS_SLICE_NAME,
  initialState,
  reducers: {
    fetchLossTaxExemptionsRequest: (state) => {
      state.isFetching = true;
    },
    fetchLossTaxExemptionsSuccess: (state, action: PayloadAction<LossTaxExemptionsResponse>) => {
      state.isFetching = false;

      const data: LossTaxExemptionsData | undefined = action.payload.result[0];

      if (!data) return;

      state.lossPotShares = data.lossPotShares;
      state.lossPotOther = data.lossPotOther;
      state.withholdingTaxOffset = data.withholdingTaxOffset;
    },
    fetchLossTaxExemptionsFailure: (state) => {
      state.isFetching = false;
    },
  },
});

export default lossTaxExemptionsSlice;
