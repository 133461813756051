import React, { FunctionComponent, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import {
  Header,
  AssetCode,
  Body,
  Paragraph,
} from 'common/components/Modal/Staking/StakingPeriodModal/styles';
import { ModalProps } from 'types/modals';

type Props = {
  entity: string;
  color?: string;
} & ModalProps;

const StakingPeriodModal: FunctionComponent<Props> = ({ entity, color, onClose }) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen
      onCancel={onClose}
      actions={<div />}
      header={
        <Header>
          {t('staking.periodPopup.title')} <AssetCode $color={color}>{entity}</AssetCode>
        </Header>
      }
    >
      <Body>
        <Paragraph>
          <Trans i18nKey="staking.periodPopup.text" values={{ assetCode: entity?.toUpperCase() }} />
        </Paragraph>
      </Body>
    </ModalTemplate>
  );
};

export default memo(StakingPeriodModal);
