// @flow
import React from 'react';
import styled from 'styled-components';

import { dimensions } from 'common/styles';

const ContentWrapper = styled.div`
  max-width: ${dimensions.maxContentWidth}px;
  width: 100%;
`;

const Content = ({ children, ...rest }: { children: React.ReactNode }) => (
  <ContentWrapper>
    {React.Children.map(children, (child) => React.cloneElement(child, { ...rest }))}
  </ContentWrapper>
);

export default Content;
