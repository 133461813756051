/* eslint-disable @typescript-eslint/no-unsafe-return */

import { State } from 'store/types/store';

export const getCurrentPath = (state: State) => state.navigation.location?.pathname ?? '/';

export const isFirstRendered = (state: State) => state.navigation.isFirstRendering;

export const getKeepScroll = (state: State) => state.navigation.keepScroll;

export default getCurrentPath;
