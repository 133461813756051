/* eslint-disable @typescript-eslint/no-unsafe-return */
import { RootEpic } from 'types/common';
import { combineEpics, ofType } from 'redux-observable';
import { combineLatest, from, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  mergeMapTo,
  pluck,
  skipWhile,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { ConsentType } from 'types/consent';
import {
  ACCEPT_CRYPTO_TERMS_AND_CONDITIONS,
  ACCEPT_FEATURE_TERMS_AND_CONDITIONS,
  ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
  ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  ACCEPT_STOCKS_TERMS_AND_CONDITIONS,
  acceptCryptoTermsAndConditions,
  acceptFeatureTermsAndConditionsFailure,
  acceptFeatureTermsAndConditionsSuccess,
  acceptStocksTermsAndConditions,
  DECLINE_FEATURE_TERMS_AND_CONDITIONS,
  DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
  DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  declineFeatureTermsAndConditions,
  declineFeatureTermsAndConditionsFailure,
  declineFeatureTermsAndConditionsSuccess,
  FETCH_CRYPTO_TERMS_AND_CONDITIONS,
  FETCH_FEATURE_TERMS_AND_CONDITIONS,
  FETCH_FEATURE_TERMS_AND_CONDITIONS_ACTIVE_STAKING,
  FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS,
  FETCH_STOCKS_TERMS_AND_CONDITIONS,
  fetchCryptoTermsAndConditions,
  fetchFeatureTermsAndConditions,
  fetchFeatureTermsAndConditionsActiveStaking,
  fetchFeatureTermsAndConditionsFailure,
  fetchFeatureTermsAndConditionsSuccess,
  fetchFeatureTermsAndConditionsTopups,
  fetchStocksTermsAndConditions,
  NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE,
  RESET_FEATURE_TERMS_AND_CONDITIONS,
  showFeatureTermsAndConditions,
} from 'store/actions/termsAndConditions';
import {
  getAccessToken,
  getIsB2bUser,
  getIsStakingAllowed,
  getIsUserInPaperTrading,
  getKycStatus,
  getSecuritiesKycStatus,
} from 'store/selectors/auth';
import { handleAjaxError } from './auth';
import { TermsAndConditionsData } from 'types/termsAndConditions';
import { AUTH, AUTO_LOGIN } from 'store/actions/auth';
import { State } from 'store/types/store';
import { getBackendIsEligibleCandidateForStocks, getStocksSwitch } from 'store/selectors/settings';
import { getFeatureStocksEnabled } from 'store/selectors/environment';
import { getKycFormData } from 'store/selectors/forms';
import { canUseSecurities } from 'common/utils/securities';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import I18n from 'i18next';
import { max } from 'lodash';
import {
  getCryptoTermsAndConditionsFailedAttempts,
  getFeatureSpecificTncConsent,
  getFeatureSpecificTncConsentFailedAttempts,
} from 'store/selectors/termsAndConditions';
import { TERMS_AND_CONDITIONS_MAX_ATTEMPTS } from 'common/const';
import {
  CONSENT_TYPES,
  FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS,
} from 'common/const/consent';
import { getIsFeatureTopUpsEnabled } from 'store/selectors/topUps';
import { AnyAction } from 'redux';

const loadAllTncOnLogin = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType([AUTH.SUCCESS, AUTO_LOGIN])),
    mergeMapTo(
      from([
        fetchCryptoTermsAndConditions.request(),
        fetchStocksTermsAndConditions.request(),
        fetchFeatureTermsAndConditionsTopups(),
        fetchFeatureTermsAndConditionsActiveStaking(),
      ]),
    ),
  );

const loadCryptoTnC = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(FETCH_CRYPTO_TERMS_AND_CONDITIONS.REQUEST)),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) => {
          if (accessToken) {
            return ajax({
              url: api.getBaseUrlForUserServices(accessToken).concat('/termsAndConditions'),
              withCredentials: true, // include cookies
              headers: {
                ...api.getCommonHeaders(),
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
              timeout: 20000,
            }).pipe(
              pluck('response'),
              map((response: TermsAndConditionsData) =>
                fetchCryptoTermsAndConditions.success(undefined, response),
              ),
            );
          }
          return of(fetchCryptoTermsAndConditions.failure(undefined, 'test'));
        }),
        catchError(handleAjaxError(action$, fetchCryptoTermsAndConditions.failure)),
      ),
    ),
  );

export const loadStocksTnC = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(FETCH_STOCKS_TERMS_AND_CONDITIONS.REQUEST)),
    skipWhile(() => window.env.STOCKS_TNC_ENABLED !== 'true'),
    switchMap(() =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);

          // If there is no accessToken we can have an early return since this request cannot be made.
          if (!accessToken) return null;

          const isFeatureStocksEnabled = getFeatureStocksEnabled(state);
          const isStocksEnabledByUser = getStocksSwitch(state);
          const cryptoKycStatus = getKycStatus(state);
          const stocksKycStatus = getSecuritiesKycStatus(state);
          const isB2bUser = getIsB2bUser(state);
          const isDemo = getIsUserInPaperTrading(state);
          const kycData = getKycFormData(state);
          const backendIsEligibleCandidateForStocks = getBackendIsEligibleCandidateForStocks(state);

          const { canTradeStocks } = canUseSecurities(
            isFeatureStocksEnabled,
            isStocksEnabledByUser,
            cryptoKycStatus,
            stocksKycStatus,
            isB2bUser,
            isDemo,
            kycData,
            backendIsEligibleCandidateForStocks,
          );

          // If stocks are enabled then this request can be made so we can just return accessToken so it can be used later.
          if (canTradeStocks) return accessToken;

          // Return null so isPresent filter can then skip it.
          return null;
        }),
        filter(isPresent),
        take(1),
        switchMap((accessToken) => {
          if (accessToken) {
            return ajax({
              url: api.getBaseUrlForUserServices(accessToken).concat('/termsAndConditions/stocks'),
              withCredentials: true, // include cookies
              headers: {
                ...api.getCommonHeaders(),
                Authorization: `Bearer ${accessToken}`,
              },
              timeout: 20000,
            }).pipe(
              pluck('response'),
              map((response: TermsAndConditionsData) =>
                fetchStocksTermsAndConditions.success(undefined, response),
              ),
            );
          }
          return of(fetchStocksTermsAndConditions.failure(undefined, 'test'));
        }),
        catchError(handleAjaxError(action$, fetchStocksTermsAndConditions.failure)),
      ),
    ),
  );

export const acceptCryptoTnC = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.REQUEST)),
    switchMap(({ disableSuccessAndFailureNotifications }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken).concat('/termsAndConditions/accept'),
            method: 'POST',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
            },
            timeout: 20000,
          }).pipe(
            pluck('response'),
            mergeMap((response: TermsAndConditionsData) =>
              from([
                acceptCryptoTermsAndConditions.success(
                  undefined,
                  response,
                  disableSuccessAndFailureNotifications,
                ),
                fetchCryptoTermsAndConditions.request(),
              ]),
            ),
          ),
        ),
        catchError((err) =>
          of(acceptCryptoTermsAndConditions.failure(null, err, disableSuccessAndFailureNotifications)),
        ),
      ),
    ),
  );

const acceptTnCSuccessNotification = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(
      isOfType([ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS, ACCEPT_STOCKS_TERMS_AND_CONDITIONS.SUCCESS]),
    ),
    filter(({ disableSuccessAndFailureNotifications }) => !disableSuccessAndFailureNotifications),
    switchMap(() =>
      of(
        notificationBannerActions.notifySuccess({
          message: I18n.t('updatedTermsAndConditions.notification.success'),
        }),
      ),
    ),
  );

const acceptTnCFailureNotification = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(
      isOfType([ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.FAILURE, ACCEPT_STOCKS_TERMS_AND_CONDITIONS.FAILURE]),
    ),
    filter(({ disableSuccessAndFailureNotifications }) => !disableSuccessAndFailureNotifications),
    switchMap(() =>
      state$.pipe(
        take(1),
        switchMap((state: State) => {
          const attempts = getCryptoTermsAndConditionsFailedAttempts(state);

          if (attempts > TERMS_AND_CONDITIONS_MAX_ATTEMPTS) {
            return of(
              notificationBannerActions.notifyError({
                message: I18n.t('updatedTermsAndConditions.notification.failAgain', {
                  email: I18n.t('common.support'),
                }),
              }),
            );
          }

          return of(
            notificationBannerActions.notifyError({
              message: I18n.t('updatedTermsAndConditions.notification.fail'),
            }),
          );
        }),
      ),
    ),
  );

export const acceptStocksTnC = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(ACCEPT_STOCKS_TERMS_AND_CONDITIONS.REQUEST)),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken).concat('/termsAndConditions/stocks/accept'),
            method: 'POST',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
            },
            timeout: 20000,
          }).pipe(
            pluck('response'),
            mergeMap((response: TermsAndConditionsData) =>
              from([
                acceptStocksTermsAndConditions.success(undefined, response),
                fetchStocksTermsAndConditions.request(),
              ]),
            ),
          ),
        ),
        catchError(handleAjaxError(action$, acceptStocksTermsAndConditions.failure)),
      ),
    ),
  );

// FEATURE SPECIFIC CONSENTS

const shouldFetchTopUpsTermsAndConditionsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType([FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS])),
    switchMap(() =>
      state$.pipe(
        map(getIsFeatureTopUpsEnabled),
        filter((isFeatureTopUpsEnabled) => isFeatureTopUpsEnabled),
        take(1),
        switchMap(() => of(fetchFeatureTermsAndConditions(CONSENT_TYPES.TOP_UPS))),
      ),
    ),
  );

const shouldFetchActiveStakingTermsAndConditionsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType([FETCH_FEATURE_TERMS_AND_CONDITIONS_ACTIVE_STAKING])),
    switchMap(() =>
      state$.pipe(
        map(getIsStakingAllowed),
        filter((isFeatureAllowed) => isFeatureAllowed),
        take(1),
        switchMap(() =>
          from([
            fetchFeatureTermsAndConditions(CONSENT_TYPES.ACTIVE_STAKING),
            fetchFeatureTermsAndConditions(CONSENT_TYPES.PASSIVE_STAKING),
          ]),
        ),
      ),
    ),
  );

const fetchFeatureTermsAndConditionsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(
      isOfType([
        FETCH_FEATURE_TERMS_AND_CONDITIONS,
        ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
        DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
      ]),
    ),
    mergeMap(({ payload: { consentName } }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForUserServices(accessToken).concat(`/consents/${consentName}`),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response: ConsentType) => fetchFeatureTermsAndConditionsSuccess(response, consentName)),
          ),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, () => fetchFeatureTermsAndConditionsFailure(consentName))),
      ),
    ),
  );

const declineFeatureTermsAndConditionsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(DECLINE_FEATURE_TERMS_AND_CONDITIONS)),
    mergeMap(({ payload: { consentName } }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            method: 'POST',
            url: api.getBaseUrlForUserServices(accessToken).concat('/consents/decline'),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: { consentType: consentName },
          }).pipe(
            pluck('response'),
            map(() => declineFeatureTermsAndConditionsSuccess(consentName)),
          ),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, () => declineFeatureTermsAndConditionsFailure(consentName))),
      ),
    ),
  );

const acceptFeatureTermsAndConditionsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(ACCEPT_FEATURE_TERMS_AND_CONDITIONS)),
    mergeMap(({ payload: { consentName, disableNotification } }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            method: 'POST',
            url: api.getBaseUrlForUserServices(accessToken).concat('/consents/accept'),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: { consentType: consentName },
          }).pipe(
            pluck('response'),
            mergeMap(() => {
              const emittedActions: AnyAction[] = [acceptFeatureTermsAndConditionsSuccess(consentName)];
              if (!disableNotification) {
                emittedActions.push(
                  notificationBannerActions.notifySuccess({
                    message: I18n.t('featureTermsAndConditions.success'),
                  }),
                );
              }
              return emittedActions;
            }),
          ),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(
          handleAjaxError(
            action$,
            (error) => acceptFeatureTermsAndConditionsFailure(consentName),
            {},
            !disableNotification ? { message: I18n.t('featureTermsAndConditions.failure') } : undefined,
          ),
        ),
      ),
    ),
  );

const closeFeatureTermsAndConditionsEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(
      isOfType([
        DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
        DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
        ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
      ]),
    ),
    map(({ payload: { consentName } }) => showFeatureTermsAndConditions(false, consentName)),
  );

const resetFeatureTermsAndConditionsEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType(RESET_FEATURE_TERMS_AND_CONDITIONS)),
    switchMap(({ payload: { consentName } }) =>
      state$.pipe(
        map(() => getFeatureSpecificTncConsent(state$.value, consentName)),
        filter(isPresent),
        take(1),
        map(() => declineFeatureTermsAndConditions(consentName)),
      ),
    ),
  );

const monitorActiveStakingAcceptSuccessToNotifyUserEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    ofType(NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE),
    switchMap(({ payload: { isConfirmGeneralTnCNeeded, isConfirmDirectiveNeeded } }) => {
      const observedTypesOfConsents = [
        action$.pipe(
          filter(
            (action: AnyAction) =>
              action.type === ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS &&
              action.payload.consentName === CONSENT_TYPES.ACTIVE_STAKING,
          ),
        ),
      ];

      if (isConfirmDirectiveNeeded) {
        observedTypesOfConsents.push(
          action$.pipe(
            filter(
              (action: AnyAction) =>
                action.type === ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS &&
                action.payload.consentName === CONSENT_TYPES.PASSIVE_STAKING,
            ),
          ),
        );
      }

      if (isConfirmGeneralTnCNeeded) {
        observedTypesOfConsents.push(
          action$.pipe(filter(isOfType(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS))),
        );
      }

      return combineLatest(observedTypesOfConsents).pipe(
        filter((consents) => consents.every((consent) => !!consent)),
        map((consents) =>
          notificationBannerActions.notifySuccess({
            message: I18n.t(
              `stakingFeatureTermsAndConditions.updateStatusNotifications.${
                isConfirmGeneralTnCNeeded
                  ? FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITH_T_AND_C
                  : FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITHOUT_T_AND_C
              }.success`,
            ),
          }),
        ),
        takeUntil(action$.pipe(ofType(NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE))),
      );
    }),
  );
};

const monitorActiveStakingAcceptFailureToNotifyUserEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    ofType(NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE),
    switchMap(({ payload: { isConfirmGeneralTnCNeeded, isConfirmDirectiveNeeded } }) => {
      const observedTypesOfConsents = [
        action$.pipe(
          filter(
            (action: AnyAction) =>
              action.type === ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE &&
              action.payload.consentName === CONSENT_TYPES.ACTIVE_STAKING,
          ),
        ),
      ];

      if (isConfirmDirectiveNeeded) {
        observedTypesOfConsents.push(
          action$.pipe(
            filter(
              (action: AnyAction) =>
                action.type === ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE &&
                action.payload.consentName === CONSENT_TYPES.PASSIVE_STAKING,
            ),
          ),
        );
      }

      if (isConfirmGeneralTnCNeeded) {
        observedTypesOfConsents.push(
          action$.pipe(filter(isOfType(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.FAILURE))),
        );
      }

      return combineLatest(observedTypesOfConsents).pipe(
        filter((consents) => consents.some((consentFailure) => !!consentFailure)),
        map((consents) => {
          const activeStakingAttempts = getFeatureSpecificTncConsentFailedAttempts(
            state$.value,
            CONSENT_TYPES.ACTIVE_STAKING,
          );

          const passiveStakingAttempts = getFeatureSpecificTncConsentFailedAttempts(
            state$.value,
            CONSENT_TYPES.ACTIVE_STAKING,
          );

          const generalTancCattempts = getCryptoTermsAndConditionsFailedAttempts(state$.value);

          const isTooManyFailures =
            max([activeStakingAttempts, passiveStakingAttempts, generalTancCattempts]) >
            TERMS_AND_CONDITIONS_MAX_ATTEMPTS;

          const textVariation = isConfirmGeneralTnCNeeded
            ? FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITH_T_AND_C
            : FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS.WITHOUT_T_AND_C;

          return notificationBannerActions.notifyError({
            message: I18n.t(
              `stakingFeatureTermsAndConditions.updateStatusNotifications.${textVariation}.${
                isTooManyFailures ? 'failureForMultipleTimes' : 'failure'
              }`,
              {
                email: I18n.t('common.support'),
              },
            ),
          });
        }),
        takeUntil(action$.pipe(ofType(NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE))),
      );
    }),
  );
};

export default combineEpics(
  loadAllTncOnLogin,
  loadCryptoTnC,
  loadStocksTnC,
  acceptCryptoTnC,
  acceptStocksTnC,
  acceptTnCSuccessNotification,
  acceptTnCFailureNotification,
  shouldFetchTopUpsTermsAndConditionsEpic,
  fetchFeatureTermsAndConditionsEpic,
  declineFeatureTermsAndConditionsEpic,
  acceptFeatureTermsAndConditionsEpic,
  closeFeatureTermsAndConditionsEpic,
  resetFeatureTermsAndConditionsEpic,
  shouldFetchActiveStakingTermsAndConditionsEpic,
  monitorActiveStakingAcceptSuccessToNotifyUserEpic,
  monitorActiveStakingAcceptFailureToNotifyUserEpic,
);
