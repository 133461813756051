/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import I18n from 'i18next';
import { concat, of, from, merge, EMPTY } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { getDeviceData } from 'common/utils/seonSdk';
import {
  getTokens,
  getUser,
  getAccessToken,
  getGet2faData,
  getSecuritiesKycStatus,
} from 'store/selectors/auth';
import { getKycCountry, getStocksKnowledgeAnswers } from 'store/selectors/forms';
import { handleAjaxError } from 'store/epics/auth';
import {
  map,
  catchError,
  switchMap,
  tap,
  filter,
  withLatestFrom,
  take,
  pluck,
  mergeMap,
} from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { FAILURE } from 'store/actions/_common';
import { makeError, errorCodes } from 'common/apiErrors';
import * as moneyActions from 'store/actions/money';
import { backoff } from 'common/utils/rxjs-operators';
import { DEVICE_MONITORING_CONSENT_CONTINUE, errorCheckDeviceData } from 'store/actions/deviceMonitoring';
import { getItem, setItem } from 'services/storage';
import { AUTH, CONFIRMATION_MAIL, loginWith2Fa, RESEND_CONFIRMATION_MAIL } from 'store/actions/auth';
import {
  changeSecuritiesKnowledge,
  changeSecuritiesKycStatus,
  CONFIRM_CHANGE_IBAN,
  confirmChangeIbanReq,
  IBAN_CHANGE_FINISH,
  IBAN_CHANGE_START,
  PRECONTRACTUAL_MAIL,
  start2faProccess,
  START_CHANGE_IBAN,
  startChangeIbanReq,
  SECURITY_ADDITIONAL_INFO,
  fetchAdditionalSecurityInfo,
  securitiesKycFormSubmit,
  stop2faProcess,
  submit2FaCode,
  SUBMIT_2FA_CODE_START,
  submitPrecontractualMailActions,
  startChangeIban,
  PAIR_DEVICE_START,
  SECURITIES_KYC_FORM_SUBMIT,
  CHANGE_SECURITIES_KNOWLEDGE,
} from 'store/actions/forms';
import { kycStatuses } from 'common/const';
import { State } from 'react-inlinesvg';
import { changeIbanInit } from 'common/api';

const changeStocksKnowledgeEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(CHANGE_SECURITIES_KNOWLEDGE.REQUEST)),
    pluck('payload'),
    switchMap(({ successCallback }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            method: 'POST',
            url: api.getBaseUrlForUserServices(accessToken).concat(`/stocks/knowledge-levels`),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: getStocksKnowledgeAnswers(state$.value),
          }).pipe(
            pluck('response'),
            map((response) => {
              notificationBannerActions.notifySuccess({
                title: I18n.t('settings.tradingExperience.title'),
                subTitle: I18n.t('settings.tradingExperience.success'),
              });
              if (successCallback) successCallback(response?.stocksKnowledgeLevel);

              return changeSecuritiesKnowledge.success(response);
            }),
          ),
        ),
        backoff(2, 500, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(
          handleAjaxError(action$, changeSecuritiesKnowledge.failure, undefined, {
            title: I18n.t('settings.tradingExperience.title'),
            subTitle: I18n.t('settings.tradingExperience.fail'),
          }),
        ),
      ),
    ),
  );

const changeIbanInitEpic = (action$, state$) =>
  action$.pipe(
    ofType(IBAN_CHANGE_START),
    map((props) => ({
      ...props,
      tokens: getTokens(state$.value),
    })),
    switchMap(({ iban, actions, isResendCode, tokens }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          concat(
            of(startChangeIbanReq.request),
            from(changeIbanInit({ iban, deviceData }, tokens.accessToken)).pipe(
              switchMap(({ response, error }) => {
                if (error) {
                  if (error?.code === errorCodes.GENERAL_DEVICEDATA_REQUIRED) {
                    return merge(
                      action$.pipe(
                        ofType(DEVICE_MONITORING_CONSENT_CONTINUE),
                        mergeMap(({ shouldContinue }) => {
                          if (shouldContinue) {
                            return of(startChangeIban(iban, actions));
                          }
                          return of(startChangeIbanReq.failure(error));
                        }),
                      ),
                      of(errorCheckDeviceData(error)),
                    );
                  }
                  return of(startChangeIbanReq.failure(error));
                }

                return concat(
                  // handle resend code success notification
                  isResendCode
                    ? of(
                        notificationBannerActions.notifySuccess({
                          message: I18n.t('common.twofa.resentCode'),
                        }),
                      )
                    : [],
                  of(startChangeIbanReq.success(response, deviceData)),
                );
              }),
              catchError(handleAjaxError(action$, startChangeIbanReq.failure)),
              tap(({ type }) => {
                if (type === START_CHANGE_IBAN.SUCCESS) {
                  actions.continue();
                } else if (type === START_CHANGE_IBAN.FAILURE) {
                  actions.cancel();
                }
                actions.setSubmitting(false);
              }),
            ),
          ),
        ),
      ),
    ),
  );

const confirmIbanChange = (action$, state$, { api }) =>
  action$.pipe(
    ofType(START_CHANGE_IBAN.SUCCESS),
    switchMap(({ response, deviceData }) =>
      action$.pipe(
        ofType(IBAN_CHANGE_FINISH),
        map((props) => ({
          ...props,
          tokens: getTokens(state$.value),
        })),
        switchMap(({ values: { code2fa }, actions, tokens }) =>
          from(api.changeIban({ data: response, code2fa, deviceData }, tokens.accessToken)).pipe(
            switchMap(({ error }: any) => {
              if (error) {
                if (error?.code === errorCodes.CHANGEPROCESS_ERR_WRONG2FACODE) {
                  actions.wrongTwoFaCode();
                  return EMPTY;
                }

                return of(confirmChangeIbanReq.failure(error));
              }
              return of(moneyActions.updateWithdrawInfo());
            }),
            tap(({ type, error }) => {
              if (type === moneyActions.UPDATE_WITHDRAW_INFO) {
                actions.continue();
              }
              actions.setSubmitting(false);
            }),
          ),
        ),
      ),
    ),
  );

const showErrorNotification = (action$) =>
  action$.pipe(
    ofType(CONFIRM_CHANGE_IBAN.FAILURE, START_CHANGE_IBAN.FAILURE),
    map(({ error }) => {
      const { message } = makeError(error, I18n.t('settings.personalInfo.errors.changeIbanGenericError'));
      return notificationBannerActions.notifyError({ message });
    }),
  );

const pairingWithCode = (action$, state$) =>
  action$.pipe(
    ofType(AUTH.FAILURE),
    switchMap(({ error, request }) => {
      if (error.code === errorCodes.LOGIN_ERR_DEVICE_2FA) {
        return action$.pipe(
          ofType(PAIR_DEVICE_START),
          withLatestFrom(state$),
          switchMap(() => {
            return action$.pipe(
              ofType(SUBMIT_2FA_CODE_START),
              withLatestFrom(state$),
              map(([{ values, actions }, state]) => {
                return {
                  actions,
                  credentials: request.credentials,
                  accountType: request.accountType,
                  twofa: {
                    token: getGet2faData(state).submitToken,
                    code: values.code,
                  },
                };
              }),
              switchMap(({ credentials, accountType, twofa, actions }) => {
                return of(
                  submit2FaCode.request(),
                  loginWith2Fa({ ...credentials, accountType, twofa, actions }),
                );
              }),
            );
          }),
        );
      }
      return of(stop2faProcess());
    }),
  );

const resendConfirmationCodee = (action$: ActionsObservable, state$: StateObservable) =>
  action$.pipe(
    ofType(RESEND_CONFIRMATION_MAIL),
    filter(({ sendSecurityCode }) => sendSecurityCode),
    switchMap(() =>
      action$.pipe(
        ofType(CONFIRMATION_MAIL[FAILURE]),
        withLatestFrom(state$),
        switchMap(
          ([
            {
              error: { detail },
            },
            state,
          ]) => {
            if (detail !== undefined && Object.keys(detail).length > 0) {
              const { accountType, username } = getUser(state);
              return of(
                start2faProccess({
                  twofa: detail,
                  username,
                  accountType,
                }),
              );
            }
            return of(stop2faProcess());
          },
        ),
      ),
    ),
  );

const saveStocksKycProccess = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(SECURITIES_KYC_FORM_SUBMIT.REQUEST)),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          of(state$.value).pipe(
            map(({ auth, forms }) => {
              const extractedData = {};
              const extractedFields = ['citizenship', 'mobileNumber', 'residence', 'hasGermanTaxID'];
              Object.keys(forms.stocksKycFormData).forEach((key) => {
                if (extractedFields.includes(key)) return;
                extractedData[key] = forms.stocksKycFormData[key];
              });
              return {
                status: auth.user.stocksUserAccountStatus,
                data: extractedData,
              };
            }),
            switchMap(({ data }) =>
              ajax({
                method: 'POST',
                url: api.getBaseUrlForUserServices(accessToken).concat(`/stocks/onboard`),
                withCredentials: true, // include cookies
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
                body: data,
              }).pipe(
                pluck('response'),
                mergeMap((response) => [
                  notificationBannerActions.notifySuccess({
                    title: I18n.t('securitiesKyc.successTitle'),
                  }),
                  securitiesKycFormSubmit.success(response),
                  changeSecuritiesKycStatus(response.stocksUserAccountStatus),
                ]),
              ),
            ),
            catchError((err) =>
              of(err).pipe(
                mergeMap((error) => {
                  let message = '';
                  switch (error.response?.code) {
                    case 'enablestockstrading_err_nongermantaxid':
                      message = I18n.t('securitiesKyc.errors.invalidTIN');
                      break;
                    case 'enablestockstrading_err_nongermanresident':
                      message = I18n.t('securitiesKyc.errors.nonGermanResident');
                      break;
                    case 'enablestockstrading_err_nongermanmobilenumber':
                      message = I18n.t('securitiesKyc.errors.noGermanPhoneNumber');
                      break;
                    default:
                      message = I18n.t('securitiesKyc.errors.title');
                      break;
                  }
                  return [
                    securitiesKycFormSubmit.failure(error),
                    notificationBannerActions.notifyError({ message }),
                  ];
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

const fetchAdditionalStockInfoEpic = (action$, state$, { api, ajax }) =>
  action$.pipe(
    filter(isOfType(SECURITY_ADDITIONAL_INFO.REQUEST)),
    pluck('payload'),
    switchMap(({ successCallback }) =>
      state$.pipe(
        map((state) => {
          const accessToken = getAccessToken(state);

          if (!accessToken) return undefined;

          const stocksKycStatue = getSecuritiesKycStatus(state);

          // Only when a user is stocks confirmed we can and should execute this api call.
          if (stocksKycStatue !== kycStatuses.Confirmed) return undefined;

          return accessToken;
        }),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            method: 'GET',
            url: api.getBaseUrlForUserServices(accessToken).concat(`/stocks/user/info`),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response) => {
              if (successCallback)
                successCallback(response.stocksQuestionsLastAnsweredDate, response.stocksKnowledgeLevel);
              return fetchAdditionalSecurityInfo.success(response);
            }),
          ),
        ),
        catchError((err) => of(err).pipe(mergeMap((error) => [fetchAdditionalSecurityInfo.failure(error)]))),
      ),
    ),
  );

const submitPrecontractualRequestEpic = (action$, state$, { api, ajax }) =>
  action$.pipe(
    filter(isOfType(PRECONTRACTUAL_MAIL.REQUEST)),
    switchMap(() =>
      state$.pipe(
        map((state: State) => {
          const accessToken = getAccessToken(state);

          if (!accessToken) return null;

          const { response } = getItem('precontractualMailSent');

          const precontractualMailSent = JSON.parse(response.item) ?? false;

          if (precontractualMailSent) return null;

          const country = getKycCountry(state);

          if (!country) return null;

          return { accessToken, country };
        }),
        filter(isPresent),
        take(1),
        switchMap(({ accessToken, country }) =>
          ajax({
            method: 'GET',
            url: api.sendPrecontractEmailRequest({ country }, accessToken),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            map(() => {
              setItem('precontractualMailSent', JSON.stringify(true));
              return submitPrecontractualMailActions.success();
            }),
          ),
        ),
        catchError((err) => of(err).pipe(mergeMap((error) => [submitPrecontractualMailActions.failure()]))),
      ),
    ),
  );

const formsEpics = {
  pairingWithCode,
  showErrorNotification,
  confirmIbanChange,
  changeIbanInitEpic,
  resendConfirmationCodee,
  saveStocksKycProccess,
  fetchAdditionalStockInfoEpic,
  changeStocksKnowledgeEpic,
  submitPrecontractualRequestEpic,
};

export default formsEpics;
