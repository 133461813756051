/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable spaced-comment */

import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isOfType } from 'safetypings';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { RootEpic } from 'types/common';
import { AjaxError } from 'rxjs/ajax';
import { Asset, ASSET_TAGS } from 'types/assets';
import { backoff } from 'common/utils/rxjs-operators';
import { getSelectedTimePeriod } from '../selectors/currency';
import { timePeriodToPortfolioChartTime } from 'common/const';
import { getAccessToken } from 'store/selectors/auth';
import { handleAjaxError } from './auth';
import { AppReduxEpicMiddleware, State } from 'store/types/store';
import {
  getMarketOverviewAssetTag,
  getMarketOverviewOrderBy,
  getMarketOverviewSearchText,
  getMarketOverviewVisibleAssets,
} from 'store/slices/filters/selectors';
import { FETCH_MARKET_OVERVIEW_PAGE_SIZE } from 'common/const/assets';
import { loadMarketOverviewAssetsError } from 'store/slices/marketOverview/actions';
import {
  getMarketOverviewIsFullyLoaded,
  getMarketOverviewReference,
} from 'store/slices/marketOverview/selectors';
import { Observable } from 'rxjs';
import { AnyAction } from 'redux';
import {
  onMarketOverviewUnmount,
  setMarketOverviewCategory,
  setMarketOverviewVisibleItems,
} from 'store/slices/filters/actions';
import {
  subscribeToPriceHistorySmallChart,
  unsubscribeFromPriceHistorySmallChart,
} from 'store/slices/priceHistorySmallChart/actions';
import { SELECT_TIME_PERIOD } from 'store/actions/currency';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  subscribeToPriceChangesForSelectedAssetTag,
  unsubscribeFromPriceChangesForSelectedAssetTag,
} from 'store/slices/priceChanges/actions';
import {
  subscribeToCryptoPrices,
  subscribeToSecurityPrices,
  unsubscribeFromCryptoPrices,
  unsubscribeFromSecurityPrices,
} from 'store/slices/prices/actions';
import { paths } from 'common/urls';
import { loadMarketOverviewAssets, loadMarketOverviewAssetsSuccess } from 'store/slices/common/actions';

const loadMarketOverviewAssetsEpic: RootEpic = (action$, state$, { ajax, api }: AppReduxEpicMiddleware) =>
  action$.pipe(
    filter(isOfType(loadMarketOverviewAssets.type)),
    switchMap(() =>
      state$.pipe(
        map((state: State) => ({
          accessToken: getAccessToken(state),
          isFullyLoaded: getMarketOverviewIsFullyLoaded(state),
          params: {
            search: getMarketOverviewSearchText(state),
            orderMechanism: getMarketOverviewOrderBy(state),
            tag: getMarketOverviewAssetTag(state),
            pageSize: FETCH_MARKET_OVERVIEW_PAGE_SIZE,
            timePeriod: timePeriodToPortfolioChartTime[getSelectedTimePeriod(state)],
            reference: getMarketOverviewReference(state),
          },
        })),
        filter(({ accessToken, isFullyLoaded }) => !!accessToken && !isFullyLoaded),
        take(1),
        mergeMap(({ accessToken, params }) =>
          ajax({
            url: api
              .getBaseUrlForTrading(accessToken ?? '')
              ?.concat('/assets')
              .concat(queryArrayString(params)),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map(({ assets }: { assets: Asset[] }) => {
              return loadMarketOverviewAssetsSuccess({
                assets,
              });
            }),
          ),
        ),
        // catch outside of ajax's switchMap to grab a fresh token when resubscribing to observable (on token refresh)
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, loadMarketOverviewAssetsError)),
      ),
    ),
  );

// Re-load market overview assets every time a time period is changed on Market overview screen.
const reloadMarketOverviewAssetsOnTimePeriodChangeEpic = (
  action$: Observable<AnyAction>,
  state$: Observable<State>,
) =>
  action$.pipe(
    ofType(loadMarketOverviewAssets.type),
    switchMap(() =>
      action$.pipe(
        ofType(SELECT_TIME_PERIOD),
        take(1),
        mapTo(loadMarketOverviewAssets({ forceReload: true })),
        takeUntil(action$.pipe(filter(isOfType(onMarketOverviewUnmount.type)))),
      ),
    ),
  );

const watchMarketOverviewUnmount: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType(onMarketOverviewUnmount.type)),
    switchMap(() => [
      unsubscribeFromPriceChangesForSelectedAssetTag(),
      unsubscribeFromPriceHistorySmallChart(),
    ]),
  );

const watchMarketOverviewCategory: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType(setMarketOverviewCategory.type)),
    switchMap(() => [
      unsubscribeFromPriceChangesForSelectedAssetTag(),
      unsubscribeFromPriceHistorySmallChart(),
      unsubscribeFromSecurityPrices(),
    ]),
  );

const watchTimePeriod = (action$: Observable<AnyAction>, state$: Observable<State>) =>
  action$.pipe(
    ofType('SELECT_TIME_PERIOD'),
    switchMap(() =>
      state$.pipe(
        map((state: State) => ({ visibleItems: getMarketOverviewVisibleAssets(state) })),
        filter(({ visibleItems }) => !!visibleItems.length),
        take(1),
        switchMap(({ visibleItems }) => [
          subscribeToPriceChangesForSelectedAssetTag({ codes: visibleItems }),
        ]),
        takeUntil(action$.pipe(filter(isOfType(unsubscribeFromPriceChangesForSelectedAssetTag.type)))),
      ),
    ),
  );

const watchMarketOverviewVisibleItemsChange: RootEpic = (action$, state$: Observable<State>) =>
  action$.pipe(
    filter(isOfType(setMarketOverviewVisibleItems.type)),
    filter((action: PayloadAction<{ items: string[] }>) => !!action.payload.items.length),
    mergeMap((action: PayloadAction<{ items: string[] }>) =>
      state$.pipe(
        take(1),
        map((state: State) => ({ tag: getMarketOverviewAssetTag(state) })),
        switchMap(({ tag }) =>
          tag === ASSET_TAGS.CRYPTO
            ? [unsubscribeFromCryptoPrices(), subscribeToCryptoPrices({ codes: action.payload.items })]
            : [unsubscribeFromSecurityPrices(), subscribeToSecurityPrices({ codes: action.payload.items })],
        ),
        takeUntil(
          action$.pipe(
            filter(
              isOfType([
                onMarketOverviewUnmount.type,
                unsubscribeFromSecurityPrices.type,
                unsubscribeFromCryptoPrices.type,
              ]),
            ),
          ),
        ),
      ),
    ),
  );

const watchMarketOverviewVisibleItems: RootEpic = (action$, state$: Observable<State>) =>
  action$.pipe(
    filter(isOfType(setMarketOverviewVisibleItems.type)),
    filter((action: PayloadAction<{ items: string[] }>) => !!action.payload.items.length),
    switchMap((action: PayloadAction<{ items: string[] }>) =>
      state$.pipe(
        map((state: State) => ({ assetTag: getMarketOverviewAssetTag(state) })),
        take(1),
        switchMap(({ assetTag }) => {
          return [
            unsubscribeFromPriceChangesForSelectedAssetTag(),
            subscribeToPriceChangesForSelectedAssetTag({ codes: action.payload.items }),
            ...(assetTag === ASSET_TAGS.CRYPTO
              ? [
                  unsubscribeFromPriceHistorySmallChart(),
                  subscribeToPriceHistorySmallChart({ codes: action.payload.items }),
                ]
              : []),
          ];
        }),
      ),
    ),
  );

export default combineEpics(
  loadMarketOverviewAssetsEpic,
  reloadMarketOverviewAssetsOnTimePeriodChangeEpic,
  watchMarketOverviewVisibleItems,
  watchMarketOverviewUnmount,
  watchMarketOverviewCategory,
  watchTimePeriod,
  watchMarketOverviewVisibleItemsChange,
);
