/* eslint-disable @typescript-eslint/unbound-method */
import { fork, all, takeLatest, call } from 'redux-saga/effects';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { toast } from 'react-toastify';

function* dismissToast({ id }): Saga<void> {
  if (!id) {
    yield call(toast.dismiss);
  } else {
    const dismissAll = id.map((item) => call(toast.dismiss, item.id));
    yield all(dismissAll);
  }
}

function* watchNotificationClear(): Saga<void> {
  yield takeLatest(notificationBannerActions.CLEAR_NOTIFICATION, dismissToast);
}

export default function* root(): Saga<void> {
  yield all([fork(watchNotificationClear)]);
}
