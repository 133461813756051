import { take, takeEvery, call, fork, all, select, put, race } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import I18n from 'i18next';
import { accountTypes, kycStatuses } from 'common/const';
import { getUser } from 'store/selectors/auth';
import { urlTitleCodes, paths, isPublic } from 'common/urls';
import { getCurrentPath } from 'store/selectors/navigation';
import { getOrderTypeData, getReservation } from 'store/selectors/orders';
import { setItem } from 'services/storage';
import { getIsKycStarted } from 'store/selectors/forms';
import * as authActions from 'store/actions/auth';
import * as moneyActions from 'store/actions/money';
import * as formsActions from 'store/actions/forms';
import * as ordersActions from 'store/actions/orders';
import { LOG_NEW_SCREEN_OPEN, LOG_ORDER_RESERVATION } from 'store/actions/misc';
// import * as events from 'common/utils/analytics/events';
import * as events from 'common/utils/analytics/gtm';

// ********************************* WATCHERS **********************************

// Just mapping the account type enums to the wording required by analytics
function analyticsAccountType(accountType) {
  return accountType === accountTypes.real ? 'real' : 'demo';
}

function* currentMode(): Saga<string> {
  const user = yield select(getUser);
  return analyticsAccountType(user.accountType);
}

function* currentKycStatus(): Saga<string> {
  const user = yield select(getUser);
  return user.kycStatus === kycStatuses.Confirmed ? 'yes' : 'no';
}

function* logCreateAccount(): Saga<void> {
  yield call(events.createAccount);
}

function* logLogin(): Saga<void> {
  yield call(events.login);
}

function* logKycStarted(): Saga<void> {
  const isKycStarted = yield select(getIsKycStarted);
  const currentPath = yield select(getCurrentPath);
  if (!isKycStarted) {
    yield put(formsActions.setKycStarted());
    yield call(events.kycStarted, currentPath);
  }
}

function* logFirstLogin(): Saga<void> {
  const user = yield select(getUser);
  yield call(events.firstLogin, user.guid);
}

function* logChangeAccount({ newAccountType }): Saga<void> {
  const accountType = analyticsAccountType(newAccountType);
  const currentPath = yield select(getCurrentPath);
  const user = yield select(getUser);
  yield call(events.changeMode, accountType, newAccountType, currentPath, user.guid);
}

function* logScreenOpen({ pathname, params, ...rest }): Saga<void> {
  const { mode, verified, user } = yield all({
    mode: call(currentMode),
    verified: call(currentKycStatus),
    user: select(getUser),
  });

  const titleCode =
    pathname.indexOf(paths.KYC) === 0 ? urlTitleCodes[paths.KYC] : urlTitleCodes[pathname] || '';
  yield call(events.screenOpen, {
    screenName: pathname,
    mode,
    verified,
    title: I18n.t(titleCode),
    params,
    userId: user.guid,
    ...rest,
  });
}

function* logBuySellOk({ type, ...rest }): Saga<void> {
  const mode = yield call(currentMode);
  const user = yield select(getUser);
  yield call(events.buySell, { ...rest, mode, userId: user.guid });
}

function* logBuySellConfirmCancel(): Saga<void> {
  const mode = yield call(currentMode);
  const currentPath = yield select(getCurrentPath);
  yield call(events.buttonClick, 'BuySell', 'Cancel', currentPath, mode);
}

function* logBuySellSuccess(action: any): Saga<void> {
  if (action != null && action.orderDone != null) {
    const {
      orderPreview: { confirmationFlowId },
    } = yield select(getReservation);
    const { volume, orderType, entity, value } = action.orderDone;
    const mode = yield call(currentMode);
    const user = yield select(getUser);
    const storageData = JSON.parse(localStorage.getItem('analytics')) || {};
    storageData[user.username] = storageData[user.username] || {};
    storageData[user.username][`${mode}_firstTransaction`] = storageData[user.username][
      `${mode}_firstTransaction`
    ]
      ? 'no'
      : 'yes';
    localStorage.setItem('analytics', JSON.stringify(storageData));
    const firstTransaction = storageData[user.username][`${mode}_firstTransaction`];
    yield call(events.buySellSuccess, {
      entity,
      mode,
      orderType,
      value: volume,
      confirmationFlowId,
      firstTransaction,
      userId: user.guid,
      price: value,
    });
  }
}

function* logBuySellFailure(): Saga<void> {
  const {
    orderPreview: { value },
  } = yield select(getReservation);
  const { isBuyOrder, entity } = yield select(getOrderTypeData);
  const mode = yield call(currentMode);

  yield call(events.buySellFailure, entity, mode, isBuyOrder ? 'Buy' : 'Sell', value);
}

function* logMoneyWithdrawStepOne(): Saga<void> {
  yield call(events.moneyWithdraw, 'OkMoveOn');
}

function* logMoneyWithdrawStepTwo(): Saga<void> {
  yield call(events.moneyWithdraw, 'PayOutNow');
}

function* logCryptoWithdrawStepOne(): Saga<void> {
  yield call(events.cryptoWithdraw, 'OkMoveOn');
}

function* logCryptoWithdrawStepTwo(): Saga<void> {
  yield call(events.cryptoWithdraw, 'SendPayoutRequest');
}

function* logStartIdentification(): Saga<void> {
  yield call(events.startIdentification);
}

function* logOpenVerification(): Saga<void> {
  const currentPath = yield select(getCurrentPath);
  const user = yield select(getUser);
  yield call(events.logOpenVerification, currentPath, user.guid);
}

function* watchSimpleAnalyticsActions(): Saga<void> {
  // yield takeEvery(formsActions.START_REGISTRATION, logStartRegistration);
  yield takeEvery(formsActions.SUBMIT_REGISTER_USER, logCreateAccount);
  yield takeEvery(formsActions.KYC_STARTED, logKycStarted);
  yield takeEvery(authActions.CHANGE_ACCOUNT.REQUEST, logChangeAccount);
  yield takeEvery(formsActions.START_IDENTIFICATION, logStartIdentification);
  yield takeEvery(ordersActions.CANCEL_USER_SUBMIT, logBuySellConfirmCancel);
  yield takeEvery(formsActions.OPEN_VERIFICATION_PAGE, logOpenVerification);
  yield takeEvery(ordersActions.SUBMIT_ORDER.SUCCESS, logBuySellSuccess);
  yield takeEvery(ordersActions.SUBMIT_ORDER.FAILURE, logBuySellFailure);
  yield takeEvery(moneyActions.CHECK_WITHDRAW_ORDER, logMoneyWithdrawStepOne);
  yield takeEvery(moneyActions.SUBMIT_WITHDRAW_ORDER, logMoneyWithdrawStepTwo);
  yield takeEvery(moneyActions.AFTER_CRYPTO_ENTRY, logCryptoWithdrawStepOne);
  yield takeEvery(moneyActions.SUBMIT_CRYPTO_WITHDRAW, logCryptoWithdrawStepTwo);
}

function* watchLogin(): Saga<void> {
  while (true) {
    const action = yield take(authActions.AUTH.SUCCESS);
    if (action.response.isFirstDeviceLogin) {
      yield call(logFirstLogin);
    }
  }
}

function* watchScreenChange(): Saga<void> {
  while (true) {
    const { logNewScreen, reserveOrder } = yield race({
      logNewScreen: take(LOG_NEW_SCREEN_OPEN),
      reserveOrder: take(LOG_ORDER_RESERVATION),
    });
    let storageData = {};
    const viewParams = {};
    const user = yield select(getUser);
    const currentPath = yield select(getCurrentPath);

    if (logNewScreen) {
      // check if path is private
      if (!isPublic(currentPath)) {
        storageData = JSON.parse(localStorage.getItem('analytics')) || {};
        storageData[user.guid] = storageData[user.guid] || {};
        if (!(`viewedInDemo` in storageData[user.guid])) {
          viewParams.first_view = 'yes';
          storageData[user.guid].viewedInDemo = true;
        } else {
          viewParams.first_view = 'no';
        }
        if (user.accountType === accountTypes.real && !(`viewedInReal` in storageData[user.guid])) {
          viewParams.first_verified_view = 'yes';
          storageData[user.guid].viewedInReal = true;
        } else {
          viewParams.first_verified_view = 'no';
        }
        yield call(setItem, 'analytics', JSON.stringify(storageData));
      }

      const { pathname, params, type, ...rest } = logNewScreen;
      yield call(logScreenOpen, {
        pathname,
        params: params || {},
        userId: user.guid,
        ...rest,
        ...viewParams,
      });
    } else {
      yield call(logBuySellOk, reserveOrder, user);
    }
  }
}

export default function* root(): Saga<void> {
  yield all([fork(watchScreenChange), fork(watchLogin), fork(watchSimpleAnalyticsActions)]);
}
