import styled from 'styled-components';
import { WinnerLoserType } from 'types/winnersLosers';

const getListItemPaddingByOrderNumber = (orderNumber?: number): string => {
  if (orderNumber === 2) return '16px 16px 16px 6px';

  if (orderNumber === 3) return '11px 11px 11px 6px';

  return '17.5px 17.5px 17.5px 6px';
};

const getListItemWidthByOrderNumber = (orderNumber?: number): string => {
  if (orderNumber === 2) return '190px';

  if (orderNumber === 3) return '173px';

  return '205px';
};

const getListIconSizeByOrderNumber = (orderNumber?: number): string => {
  if (orderNumber === 2) return '46px';

  if (orderNumber === 3) return '36px';

  return '50px';
};
const getListIconRightByOrderNumber = (orderNumber?: number): string => {
  if (orderNumber === 2) return '-23px';

  if (orderNumber === 3) return '-18px';

  return '-25px';
};

export const Header = styled.h1`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 32px;
`;

export const Subtitle = styled.h3`
  text-align: center;
  margin-top: 60px;
`;

export const Text = styled.p`
  text-align: center;
  margin-top: 24px;
`;

export const List = styled.ol`
  list-style-type: none;
  width: 229px;
`;

export const ListItem = styled.li<{ $order?: number; $type?: WinnerLoserType }>`
  background: ${(props) =>
    props.$type === 'winner'
      ? 'linear-gradient(270deg, #D1F4DF 0%, rgba(209, 244, 223, 0.00) 100%)'
      : 'linear-gradient(270deg, #F3A1A7 0%, rgba(229, 59, 71, 0.00) 100%, rgba(243, 161, 167, 0.00) 100%)'};
  margin-top: 15px;
  padding: ${(props) => getListItemPaddingByOrderNumber(props.$order)};
  width: ${(props) => getListItemWidthByOrderNumber(props.$order)};
  font-size: 10px !important;
  font-weight: 700 !important;
  letter-spacing: -0.2px !important;
  line-height: normal !important;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    height: ${(props) => getListIconSizeByOrderNumber(props.$order)} !important;
    width: ${(props) => getListIconSizeByOrderNumber(props.$order)} !important;
    right: ${(props) => getListIconRightByOrderNumber(props.$order)};
  }
`;

export const ListItemRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const ListItemText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WinnerLoserPriceChangeText = styled.span<{ $type?: WinnerLoserType }>`
  margin-left: 6.5px;
  margin-right: 11px;
  white-space: nowrap;
  color: ${(props) => (props.$type === 'winner' ? '#00C14D' : '#E53B47')};
`;

export const RowCenter = styled.div`
  display: flex;
  justify-content: center;
`;
