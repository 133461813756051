import { call, select } from 'redux-saga/effects';
import getCurrentPath from 'store/selectors/navigation';
import { trackAppEvent } from 'store/actions/tracking';
import {
  trackDisplayShowBanner,
  trackDisplayCloseBanner,
  trackDisplayClickBanner,
} from '../biEventDefinitions';

export function* logBannerEvents(action: ReturnType<typeof trackAppEvent>): Generator<any, any, any> {
  const screenId: string = yield select(getCurrentPath);

  switch (action.eventName) {
    case 'showBanner': {
      yield call(
        trackDisplayShowBanner,
        action.payload.bannerId,
        screenId,
        action.payload.message,
        action.payload.displayProperties,
      );
      break;
    }
    case 'closeBanner': {
      yield call(trackDisplayCloseBanner, action.payload.bannerId, screenId, action.payload.closeType);
      break;
    }
    case 'clickOnBanner': {
      yield call(trackDisplayClickBanner, action.payload.bannerId, screenId, action.payload.clickTarget);
      break;
    }
    default:
      break;
  }
}
