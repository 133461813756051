/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { LogEventPayload, TrackingConfiguration } from 'types/tracking';
import { AppTrackingEvent } from '../sagas/tracking/handlers/types';
import { action, createRequestTypes, REQUEST, SUCCESS, FAILURE } from './_common';

export const SET_TRACKING_CONFIGURATION = 'SET_TRACKING_CONFIGURATION';
export const setTrackingConfiguration = (trackingConfiguration: TrackingConfiguration) =>
  action(SET_TRACKING_CONFIGURATION, { trackingConfiguration });

export const FLUSH_EVENTS = createRequestTypes('FLUSH_EVENTS');
export const flushEvents = {
  request: () => action(FLUSH_EVENTS[REQUEST]),
  success: ({ payload }: any, response: any) => action(FLUSH_EVENTS[SUCCESS], { payload, response }),
  failure: (error: any) => action(FLUSH_EVENTS[FAILURE], { error }),
};

export const REPORT_EVENT_TO_BACKEND = createRequestTypes('REPORT_EVENT_TO_BACKEND');
export const reportEventToBackend = {
  request: () => action(REPORT_EVENT_TO_BACKEND[REQUEST]),
  success: ({ payload }: any, response: any) =>
    action(REPORT_EVENT_TO_BACKEND[SUCCESS], { payload, response }),
  failure: (error: any) => action(REPORT_EVENT_TO_BACKEND[FAILURE], { error }),
};

export const FLUSH_EVENTS_TIMER_EXPIRED = 'FLUSH_EVENTS_TIMER_EXPIRED';
export const flushEventsTimerExpired = () => action(FLUSH_EVENTS_TIMER_EXPIRED);

export const LOG_EVENT = 'LOG_EVENT';
export const logEvent = (payload: Partial<LogEventPayload>) => action(LOG_EVENT, { payload });

export const QUEUE_EVENT = 'QUEUE_EVENT';
export const queueEvent = (payload: LogEventPayload) => action(QUEUE_EVENT, { payload });

// -----------------------  Actions to track specific events -----------------------

export const TRACK_APP_EVENT = 'TRACK_APP_EVENT';
export const trackAppEvent = (
  eventName: AppTrackingEvent,
  payload: any = {},
): {
  eventName?: any;
  payload?: any;
  type: 'TRACK_APP_EVENT';
} => action(TRACK_APP_EVENT, { eventName, payload });
