import { table } from 'console';

/* eslint-disable no-irregular-whitespace */
export default {
  l10n: {
    lang: 'en',
    muiLocalizationAdapterLocale: 'en-EN',
    helpCenterLinkParam: 'en-gb',
    currency: '€',
    currencyShortName: 'eur',
    cuFormat: '%u %n',
    cuFormatSuffix: '%n%u',
    separator: ',',
    delimiter: '.',
    dateFormat: 'dd.MM.yyyy',
    summaryDateFormat: 'dd.MM.yyyy',
    dateTimeFormat: 'd. MMM yyyy HH:mm',
    dateTimeFormatShort: 'd. MMM yyyy',
    dateTimeMobile: 'd.MM.yy',
    fullDateFormat: 'dddd DD MMMM yyyy',
    transactionHistoryGroupedDateFormat: 'eeee, dd. MMMM yyyy',
    transactionHistoryDatetimeFormat: 'dd/MM/yy, HH:mm',
    weekdayFormat: 'eeee',
    timePeriodFormat: {
      '1H': 'HH:mm a',
      '3H': 'HH:mm a',
      '24H': 'HH:mm a',
      '7D': 'MMM d, yyyy h:mm a',
      '30D': 'MMM d, yyyy h:mm a',
      '12M': 'MMM d, yyyy',
      MAX: 'MMM d, yyyy',
    },
    timePeriodFormatMarketOverview: {
      '1H': 'h:mm a',
      '3H': 'h:mm a',
      '24H': 'h:mm a',
      '7D': 'MMM d, yyyy',
      '30D': 'MMM d, yyyy',
      '12M': 'MMM d, yyyy',
      MAX: 'MMM d, yyyy',
    },
    faq_link: 'https://bisonapp.com/en/questions',
    appStoreLink: 'https://apps.apple.com/app/bison-buy-bitcoin-co/id1362518159',
    googlePlayLink: 'https://play.google.com/store/apps/details?id=de.bisonapp',
    formatCurrency: '{{symbol}} {{amount}}',
  },
  pageTitle: '{{title}} | BISON',
  mainScreenTitles: {
    referral: 'Invite friends',
    redeemCode: 'Redeem code',
    welcome: 'Cryptocurrencies',
    trading: 'Trade',
    portfolio: 'Portfolio',
    totalBalance: 'Total Balance', // instead of portfolio
    market: 'Market',
    tradeManager: 'Trading Manager',
    watchList: 'Watch List',
    limitOrder: 'Limit Orders',
    tradingRules: 'Trading Rules',
    priceAlerts: 'Price Alert',
    savingPlan: 'Savings Plans',
    orderHistory: 'Transaction history',
    settings: 'Settings',
    offsetAccounts: 'Offset accounts',
    cryptoWithdraw: 'Withdraw crypto',
    cryptoWithdrawLegal: 'Withdraw {{currency}}',
    cryptoDeposit: 'Crypto deposit',
    withdraw: 'Withdraw',
    infoReport: 'Reports',
    deposit: 'Deposit',
    depositSelect: 'Choose deposit method',
    depositTransfer: 'Deposit via bank transfer',
    depositTopUp: 'Instant deposit',
    intro: 'Intro',
    deleteAccount: 'Terminate account',
    techIndicators: 'Tech indicators',
    design: 'Design',
    transactionHistory: 'Activity',
    riskSettings: 'Risk questions',
    staking: 'Staking',
    orders: 'Orders',
    stakingRewardHistory: 'Reward history',
  },
  tabTitles: {
    portfolio: 'Portfolio',
    market: 'Market',
    transactions: 'Activity',
    settings: 'Account',
    tradingManager: 'Manager',
    alerts: 'Alerts',
    staking: 'Staking',
    orders: 'Orders',
  },
  banners: {
    paperMoneyText: 'You are in demo mode',
    disabledTradingText: `Trading with action and ETFs is not possible\nin demo mode.`,
    mobilePaperMoneyText: 'Demo mode',
    switch: 'Verify now',
    switchKycedAcc: 'Real money trading',
    switchMobileKycedAcc: 'Real trading',
    switchPendingKyc: 'Verification: Pending',
    tryModeText: 'Demo mode active',
    registerNow: 'Register now',
    tradingClosesIn: 'Trading closes {{time}}',
    tradingOpensIn: 'Trading opens {{time}}',
  },
  androidBanner: {
    text: 'Free － In Google Play Store',
    view: 'View',
  },
  common: {
    agree: 'Agree',
    buy: 'Buy',
    sell: 'Sell',
    description: 'Description',
    show: 'show',
    hide: 'hide',
    on: 'on',
    off: 'off',
    close: 'Close',
    help: 'Help',
    requiredField: 'Required',
    save: 'Save',
    saving: 'Saving...',
    cancel: 'Cancel',
    noData: 'No data',
    ok: 'OK',
    okSend: 'OK, send',
    okConfirm: 'OK, confirm',
    noThanks: 'No, thank you',
    copied: 'Copied',
    back: 'Back',
    update: 'Update',
    notNow: 'Not now',
    openSettings: 'Open Settings',
    cryptoFilter: 'Crypto filter',
    next: 'Next',
    toPortfolio: 'To Portfolio',
    support: 'customer support form',
    delete: 'Delete',
    importantNotice: 'Important notice',
    done: 'Done',
    continue: 'Continue',
    select: 'Select',
    showMore: 'Show more',
    showLess: 'Show less',
    twofa: {
      wrongSecurityCode: 'Your security code is invalid',
      resentCode: 'We have sent you a new security code.',
    },
    max: 'Max',
  },
  // Common field errors
  fieldErrors: {
    nameRequired: 'Enter your name.',
    cardNumberRequired: 'Enter your card number.',
    cardDateRequired: "Enter your card's expiration date.",
    cardCvcRequired: 'Enter your verification number. You can find it on the back of your credit card.',
    emailRequired: 'Please enter your email.',
    emailTaken: 'This email is not available.',
    invalidEmail: 'Please enter a valid email.',
    passwordRequired: 'Please enter a password.',
    passwordTooShort: 'Your password should be at least {{minLength}} characters long.',
    passwordTooLong: 'Your password should be at most {{maxLength}} characters long.',
    passwordInvalid: 'Please choose a stronger password. Try making it longer.',
    firstName: 'First name is required.',
    lastName: 'Last name is required.',
    charsOnly: 'Only letters allowed.',
    street: 'You must enter a valid street name (max. 35 characters).',
    streetNumber: 'You must enter your house number.',
    postNumber: {
      incorrect: 'Incorrect post number (max. 10 characters).',
      required: 'You must enter a valid post number (max. 10 characters).',
    },
    city: 'You must enter a valid city name (max. 35 characters).',
    selectOption: 'Please select an option',
    enterAmount: 'Please enter an amount',
    phoneNumberRequired: 'Your phone number is required.',
    phoneNumberInvalid: 'Invalid phone number',
    invalidReferralCode: 'Entered referral code is invalid',
    country: 'Please select a country',
    web_invalidDate: 'The date needs to be in the following format: DD.MM.YYYY',
  },
  prompts: {
    tryMode:
      'You are now using BISON in demo mode, where you can test many app features by trading with virtual money.\n\nYour portfolio and trading history will not be saved.',
    tryModePromptBtn: 'I understand',
    doKyc: `# Switch to real money

  You need to go through our verification process before you can trade with real money.`,

    doKycPending: `If you have completed the verification successfully, please note that the confirmation of your verification may take a couple of business days. In exceptional cases we will reach out to you via email in order to request some additional documents.

Once your confirmation has been received, we will notify you via email.

In case you joined the waiting list and have been notified via SMS an agent is available, you can start verification now.`,
    doKycBtn: 'Start verification',
    disabledFeature: {
      tryMode: `You're in demo mode. This functionality is only available in real money mode.`,
      paperMode: `# Demo mode

  You're in demo mode. This functionality is only available in real money mode.`,
    },
    realMoneyToggleBtn: 'Switch to real money',
  },
  error: {
    genericNetwork: 'Something bad happened while accessing the network. Please try again later.',
    genericApp: 'BISON experienced an unexpected exception. Please log in again.',
    priceHistory: 'Something bad happened while loading price history.',
    priceTicker: 'Something bad happened while loading price ticker.',
    portfolio: 'Something bad happened while loading portfolio.',
    orderHistory: 'Something bad happened while loading order history.',
    inbox: 'Something went wrong while loading inbox messages.',
    accessToken: 'Something bad happened while validating access token.',
    tooManyAttempts: 'Too many wrong attempts. Please try again later or reset your password.',
  },
  intro: {
    biometricsHeader: 'Do you want to use {{type}} to log in?',
    biometricsMain: 'Enabling {{type}} allows you quick and secure access to your account.',
    biometricsButton: 'Enable {{type}}',
    notificationsHeader: 'Turn on notifications?',
    notificationsMain: "You'll receive instant notifications regarding your account.",
    notificationsButton: 'Allow notifications',
    notificationsDisabledAlertHeader: 'Notifications disabled on device',
    notificationsDisabledAlertMain:
      'Please open the Settings app to enable notifications and then try again.',
    tutorialHeader: 'Do you want to start the tutorial?',
    tutorialMain: "We'll walk you through all the important features of the app to get you going.",
    tutorialButton: 'Start tutorial',
    registrationSuccessTitle: 'You now have a BISON account!',
    registrationSuccessCta: 'Start trading now',
    registrationSuccessDemo: 'Explore the app in demo mode',
  },
  login: {
    welcomeMain: 'Buy and sell cryptocurrencies.',
    welcomeText: 'In cooperation with BISON from Börse Stuttgart now easy in the finanzen.net App.',
    text: 'Log in',
    signup: 'Join now',
    tryMe: 'Demo',
    back: 'Back',
    rememberMe: 'Keep me signed in',
    form: {
      emailLabel: 'Email',
      emailPlaceholder: 'e.g. max.mustermann@mail.de',
      passwordPlaceholder: 'Password',
      keepMeLoggedIn: 'Keep me logged in',
      fieldErrors: {
        emailRequired: 'Please enter your email.',
        passwordRequired: 'Please enter your password.',
      },
    },
    loggingIn: 'Logging in...',
    start: 'Start now',
    forgotPassword: 'Forgot password?',
    welcome: 'Welcome to\nBISON',
    welcomeFirst: 'Welcome',
    welcomeBack: 'Welcome back',
    buttonPasscode: 'Password',
    buttonTouchID: 'Touch ID',
    buttonFaceID: 'Face ID',
    unlock: 'Unlock your BISON',
    logoutText: 'Logout',
    errorTitle: 'Login Failed',
    errorIncorrectCredentials: 'Incorrect email or password. Please try again.',
    errorClientKeyInvalid: 'Looks like your session expired. Please sign in with your password to continue.',
    errorUnconfirmedEmail: 'Email is not confirmed. Please confirm it and try again.',
    errorGeneric: 'Sorry, we could not sign you in at this time. Please try again later.',
    errorUnauthorized: 'Email address or password is incorrect. Please try again.',
    errorNoKyc: 'verification not done yet. Please finish the verification process and try again.',
    errorTooManyAttempts: 'Too many wrong login attempts. Please try again later.',
    fingerprintPrompt: 'Place your finger to log in',
    fingerprintSaveHeader: 'Use Fingerprint to login?',
    fingerprintSavePrompt: 'Confirm by touching',
    fingerprintSaveSuccess: 'Saved',
    fingerprintHint: 'Touch',
    fingerprintSuccess: 'Fingerprint recognized',
    fingerprintNotRecognizedError: 'Fingerprint not recognized, try again.',
    cancel: 'Cancel',
    cancelled: 'Authentication was cancelled',
    error2fa: 'Incorrect security code. Please try again.',
    pairing: {
      info: 'You are trying to log in on a new device.',
      sms: 'To authenticate this device we sent you a security code via SMS to ',
      lastPart: '.',
      email: 'To authenticate this device we sent you a security code via email to',
      pleaseEnter: 'Please enter it below.',
      securityCode: 'Security code',
      securityCodePlaceholder: 'E.g. 123456',
      incorrectCode: 'Incorrect code format',
      codeRequired: 'A confirmation code is required to continue',
      noAccess: `If you don’t have access to your number, please contact our support via`,
      note: 'Note: You are still able to login from your previous used devices',
      resendCode: 'Resend confirmation code',
      resentCode: 'We have sent you a new security code.',
      channels: {
        sms: 'SMS',
        email: 'Email',
      },
      triesLeft: {
        one: 'You have 1 try left.',
        many: 'You have {{triesLeft}} tries left.',
      },
    },
  },
  forgotPassword: {
    title: 'Reset your password',
    instructions: '',
    email: 'Email',
    successTitle: 'The reset password link has been sent to your address.',
    errorTitle: 'Request Failed',
    sending: 'Sending...',
    send: 'Request reset link',
    backToLogin: 'Back to [login](/login)',
    successScreen: {
      title: 'Email sent',
      text1: `We've sent an email to the following email address with a confirmation link to reset your password:`,
      text2: `Click on the link provided in the email to reset your password.`,
    },
    errors: {
      noUser: 'This user does not exist.',
      notConfirmed:
        'This email address is not confirmed. Please confirm it first in your email and try again.',
      generic: 'Sorry, we could not reset your password at this time. Please try again later.',
    },
  },
  resetPassword: {
    securityCodeStep: {
      title: 'Reset password',
      mainText: `To create a new password, we require that you enter a security code. By clicking on the button below, this security code will be sent via SMS to the phone number you've registered with us: **{{phoneNumber}}**.`,
      subText: `If you do not have access to this number, please contact us via [customer support form]({{supportLink}}).`,
      sendSecurityCode: 'Request security code',
    },
    form: {
      title: 'Enter a new password',
      text: `Didn't receive a security code?`,
      resendSecurityCode: 'Request security code again.',
    },
    successStep: {
      title: 'Password successfully changed!',
      mainText: `The password for your BISON account {{userEmail}} has successfully been changed.\n\n You can now [log in](/login) to BISON with your new login details.`,
    },
  },
  registration: {
    heading: 'Hey, BISON is happy, that you want to become part of the herd.',
    form: {
      createAccount: 'Create Account',
      creating: 'Creating...',
      emailPlaceholder: 'Email',
      passwordPlaceholder: 'Password',
      successTitle: 'Registration Successful',
      errorTitle: 'Registration Failed',
      referralCodeLabel: 'Referral Code',
      referralInfo:
        'The invite-a-friend code consists of six characters and is a combination of letters and numbers. The code is located at the end of your invitation link. Note: do not enter coupon codes here.',
      referralCodeError: 'Invalid friend-referral code',
      newsletter: 'Yes, I want to subscribe to the BISONews newsletter',
      info: {
        a: 'The ',
        b: 'terms & conditions',
        c: ' and the ',
        d: 'privacy policy',
        e: ' apply.',
      },
      existingUser: 'Already have an account?',
    },
    back: 'Back',
    passwordStrength: 'Password strength:',
    passwordStrengthStrings: {
      0: 'Weak', // score 0
      1: 'Weak', // score 1
      2: 'Average', // score 2
      3: 'Average', // score 3
      4: 'Strong', // score 4
    },
    errorUsernameTaken: 'That username is taken. Please try another.',
    errorCannotSendEmail: "We couldn't send an email to this address. Please use another one.",
    errorGeneric: 'Sorry, we could not register you at this time. Please try again later.',
    errorTooManyRetries: {
      smallBlock: 'Sorry, we could not send your security code at this time. Please try again in 1 minute.',
      mediumBlock:
        'Sorry, we could not send your security code at this time. Please try again in 10 minutes.',
      bigBlock: 'Sorry, we could not send your security code at this time. Please try again tomorrow.',
    },
    confirmTitle: 'Confirm your mail address',
    emailSent: 'We sent a confirmation link to: {{email}}',
    waitingForConfirmation: {
      heading: 'Check your inbox!',
      a: 'Click the link in the email to confirm your email address.',
      b: `Don't forget to check your spam folder.`,
    },
    spam: 'If you cannot find the mail please check your spam folder.',
    successResendConfirmationMailTitle: 'We have sent you a confirmation email.',
    successResendSecurityCode: 'We have sent you a new security code.',
    resendConfirmationMail: 'Resend mail',
    resendingConfirmationMail: 'Resending...',
    errorResendConfirmationMailTitle: 'Sending Confirmation Email Failed.',
    errorResendConfirmationMail:
      'Sorry, we could not send you confirmation email at this time. Please try again later.',
    errorSecurityCode: 'Sorry, we could not send your security code at this time. Please try again later.',
  },
  emailConfirm: {
    title: 'Email confirmation',
    success: {
      title: 'Congratulations!',
      text: `We are delighted that you are now part of BISON! 

      Your email address {{email}} has been successfully confirmed by BISON.
      
      You can now log in to BISON with your login data!`,
      button: 'Start',
    },
    error: {
      title: 'Oops!',
      text: `BISON could not verify your email address with the confirmation link you just used.\n\n Please try again at BISON.\n\nIf you continue to receive an error message you can reach us via [customer support form]({{supportLink}})`,
    },
  },
  emailChangeConfirm: {
    title: 'Email change confirmation',
    newEmail: 'New email',
    currentEmail: 'Old email',
    success: {
      title: 'Success!',
      text: 'Your email address has been successfully changed.',
      button: 'Login',
    },
    error: {
      title: 'Oops!',
      text: `Something went wrong.\n\n Please try again at BISON.\n\n If you continue to receive an error message you can reach us via [customer support form]({{supportLink}})`,
    },
  },
  mobileNotification: {
    welcome: 'Welcome!',
    recommendation: 'On mobile devices we recommend the BISON app.',
    switchToApp: 'Switch to the app',
    continueWeb: 'Continue on web',
  },
  home: {
    currency: {
      balance: 'Balance',
      positions: 'Positions',
      latestPrice: 'Latest price',
      createSavingsPlan: 'Create a savings plan',
      buyBtnLabel: 'Buy',
      buyLimitLabel: 'Limit BUY',
      sellBtnLabel: 'Sell',
      sellLimitLabel: 'Limit SELL',
      buyInputLabel: 'Buy value',
      sellInputLabel: 'Sell value',
      buyOrderTitle: `Buy {{currency}}`,
      sellOrderTitle: `Sell {{currency}}`,
      buyBtn: 'Send binding purchase inquiry',
      sellBtn: 'Send binding sell inquiry',
      buyBtnPaper: 'Send purchase inquiry',
      sellBtnPaper: 'Send sell inquiry',
      midPrice: 'Current Price',
      price: 'Price',
      currentPurchasePrice: 'Current purchase price',
      currentSellingPrice: 'Current selling price',
      showAll: 'Show all',
      timePeriods: {
        hour: '1H',
        threeHours: '3H',
        day: '1D',
        week: '1W',
        month: '1M',
        year: '1Y',
        max: 'MAX',
      },
      sortingValues: {
        MostTraded: 'Most traded',
        HighestTradeVolume: 'Highest trading volume',
        Winner: 'Winner',
        Loser: 'Loser',
      },
      assetTypes: {
        Crypto: 'Crypto',
        Stock: 'Stocks',
        Etf: 'ETFs',
        Commodity: 'Commodities',
        LongShort: 'Long/short',
        Etn: 'ETNs',
        Etc: 'ETCs',
      },
      searchBar: {
        reviewAssets: 'Review assets',
      },
      noResultsFound: {
        crypto: {
          title: 'No cryptos found',
          text: "Try entering the <strong>currency's <br/>abbreviation</strong> or another <strong>category</strong>.",
        },
        stock: {
          title: 'No stocks found',
          text: 'Try another <strong>category</strong>, <br/>the <strong>ISIN</strong>, or the <strong>WKN</strong>.',
        },
        etf: {
          title: 'No ETFs found',
          text: 'Try another <strong>category</strong>, <br/>the <strong>ISIN</strong>, or the <strong>WKN</strong>.',
        },
        'long/short': {
          title: 'No long/short products found',
          text: 'Try another <strong>category</strong>, <br/>the <strong>ISIN</strong>, or the <strong>WKN</strong>.',
        },
        etn: {
          title: 'No ETNs found',
          text: 'Try another <strong>category</strong>, <br/>the <strong>ISIN</strong>, or the <strong>WKN</strong>.',
        },
        etc: {
          title: 'No ETCs found',
          text: 'Try another <strong>category</strong>, <br/>the <strong>ISIN</strong>, or the <strong>WKN</strong>.',
        },
        commodity: {
          title: 'No commodities found',
          text: 'Try another <strong>category</strong>, <br/>the <strong>ISIN</strong>, or the <strong>WKN</strong>.',
        },
      },
      returnWaiverAT:
        'There is no right to revoke given that the price of cryptocurrencies is subject to significant fluctuations in value we have no influence on.\n',
      currentTimePeriod: 'Current Price / Last {{currentTimePeriod}}',
      currentTimePeriodShort: 'Last {{currentTimePeriod}}',
      timeSinceInstant: 'Since {{instantTime}}',
      yourBuyPrice: 'Your buy price',
      yourSellPrice: 'Your sell price',
      buyPrice: '{{currency}} buy price',
      sellPrice: '{{currency}} sell price',
      confirmBuy: 'Confirm binding purchase inquiry',
      confirmSell: 'Confirm binding sell inquiry',
      confirmBuyPaper: 'Confirm purchase inquiry',
      confirmSellPaper: 'Confirm sell inquiry',
      confirmBuyRequest: 'Confirm binding purchase request',
      confirmSellRequest: 'Confirm binding sell enquiry',
      confirm: 'Confirm',
      cancel: 'Cancel',
      conversionRate: '1 {{fiatCurrencyShortName}} = {{conversionRate}} {{currencyShortName}}',
      youAreReceiving: 'You receive',
      youAreGiving: 'You pay',
      offerValidFor: 'We have reserved the price for you\n{{secondsLeft}} seconds.',
      offerExpired: 'Your offer has expired. Please request again.',
      minValueLabel: 'min',
      maxValueLabel: 'max',
      notEnoughCurrencyToBuy: 'Not enough currency in your wallet.',
      notEnoughVolumeToSell: 'Not enough {{entityName}} to sell',
      maxTradeValue: 'The maximum investment volume per trade is € {{maxTradeValue}}.',
      orderSuccessTitle: 'Done, executed!',
      orderFailTitle: "Oops, that didn't go through.",
      back: 'Try again', // To buy or sell screen
      goToPortfolio: 'Portfolio',
      goToMarket: 'Market',
      successBuy:
        'You have successfully added {{volume}} {{cryptoShortName}} \n for {{value}} to your portfolio.',
      successSell: 'You have successfully sold {{volume}} {{cryptoShortName}} \n for {{value}}.',
      orderFail: 'Sorry, we could not process your order at this time.',
      reservingPriceBtnTitle: 'Reserving...',
      popupPaperTradingWarning: 'Warning: you are currently trading with virtual money',
      tradingClosedTitle: 'Trading Closed',
      tradingClosed: `# Trading Closed

  Unfortunately, you can't buy and sell cryptocurrencies right now. Our trading hours are: Monday - Sunday from {{from}} to {{to}}`,
      genericErrors: {
        errorTitle: 'Buy/sell error',
        initOrderError: 'Something bad happened while fetching the price quote.',
        refreshPriceError: 'Something bad happened while refreshing the price.',
        reserveOrderError: 'Something bad happened while reserving your order.',
      },
      xrpDisabled: `Ripple (XRP) cannot yet be traded. We're heavily working to make sure that you can buy and sell Ripple (XRP) and we'll let you know as soon as possible.`,
      tradingVolume: 'Trade volume',
      tradingVolumeDescription: 'Comparison of the BISON trade volume, divided into buys and sells.',
      tradingVolumeGauge: {
        sell: 'Sell',
        buy: 'Buy',
        notAvailable: 'Data not available at the moment.',
        last24h: 'Last 24h',
        previous24h: 'Previous 24h',
      },
      chartCaption: 'Last ',
      chartNotAvailableMessage:
        'The chart cannot be displayed at this time. Our team is working on resolving the issue',
      maxPeriod: 'available history',
      indicators: {
        title: 'Indicators',
        apply: 'Apply',
        clearAll: 'Clear all',
        addIndicators: 'INDICATORS ({{count}})',
        sma: {
          title: 'Simple moving average (SMA)',
          option: 'SMA {{period}} periods',
          info: `# Simple Moving Average
  
  A simple moving average is formed by computing the average price of a cryptocurrency over a specific number of periods.
  
  In the app we offer you the following options: 
  
  SMA (10) = SMA 10 periods\n
  SMA (20) = SMA 20 periods\n
  SMA (50) = SMA 50 periods\n
  SMA (100) = SMA 100 periods
  
  The following periods are used to calculate the Simple Moving Average (10, 20, 50, 100) depending on the selected time period:
  
  12M:  1 period = 1 day\n
  30D: 1 period = 2 hours\n
  7D: 1 period = 30 minutes\n
  1D: 1 period = 5 minutes\n
  3H:  1 period = 30 seconds
  
  Important - The Simple Moving Average does not represent a recommendation for action, but should additionally support your investment decision in the crypto market and serve as a source of information.
  
  [__More information__](https://www.investopedia.com/terms/s/sma.asp)`,
        },
        rsi: {
          title: 'Relative strength index (RSI)',
          option: 'RSI {{period}} periods',
          info: `# Relative Strength Index
  
  The Relative Strength Index indicator (RSI) is a line whose value moves between 0 to 100 and tries to indicate whether a cryptocurrency is under- or overvalued based on the magnitude of recent changes in the price of the cryptocurrency.
  
  Values below 30 are thought to indicate that a cryptocurrency is undervalued (i.e. oversold).
  
  Values above 70 are thought to indicate that a cryptocurrency is overvalued (i.e. overbought).
  
  In the app we offer you the following options:
  
  RSI (10) = RSI 10 periods\n
  RSI (20) = RSI 20 periods\n
  RSI (50) = RSI 50 periods\n
  RSI (100) = RSI 100 periods
  
  The following periods are used to calculate the Relative-Strength-Index (10, 20, 50, 100) depending on the selected time period:
  
  12M:  1 period = 1 day\n
  30D: 1 period = 2 hours\n
  7D: 1 period = 30 minutes\n
  1D: 1 period = 5 minutes\n
  3H:  1 period = 30 seconds
  
  Important - The Relative Strength Index does not represent a recommendation for action, but should additionally support your investment decision in the crypto market and serve as a source of information.
  
  [__More information__](https://www.investopedia.com/terms/r/rsi.asp)`,
        },
      },
    },
    news: {
      title: 'News',
      info: `# News
      
These articles are provided by Event Registry. While this content does not serve as trading advice, it does serve to provide additional guidance for your investment decisions in the crypto market and is solely intended as a source of information.
`,
      cta: 'Show more news',
      showLessNews: 'Show less news',
      emptyFeed: {
        de: 'German',
        en: 'English',
      },
      empty:
        'There are currently no recent news in the {{feed}} newsfeed. But you can have a look at the {{another}} newsfeed.',
    },
    portfolio: {
      fullValue: 'Portfolio value',
      openPL: 'Open P/L',
      cash: 'Euro value',
      closedPL: 'Closed P/L',
      changePL: 'Change P/L',
      openPLLabel: 'Open P&L',
      openPLLabelSpecial: 'Open P&L*',
      openPLDetails: '*The amount of value you would gain or lose if you sold now.',
      realisedPL: 'Realized P&L**',
      realisedPLDetails: "**Gains or losses you've already generated through sales.",
      invested: 'Invested',
      inStaking: 'In Staking',
      deposit: 'Deposit',
      stake: 'Stake',
      buy: 'Buy',
      totalRewards: 'Total Rewards',
      value: 'Value',
      cashBoxLabel: 'EUR value',
      PL: 'Open P/L',
      amountAbbreviation: 'Amt.',
      emptyPortfolio: 'No cryptos available in your portfolio.',
      emptyPortfolioFirst: 'No cryptos in your portfolio, yet. Make your first purchase.',
      emptyPortfolioCTAButton: 'Buy cryptocurrencies',
      noCash: 'You have no available Euro.',
      noCashCTAButton: 'Deposit money',
      noCryptoCTAButton: 'Deposit Crypto',
      totalValue: 'Total value',
      web_last7D: 'Last 7 days',
      donutEuroOnlyLabel: 'Balance',
      available: 'Available',
      infoPopup: {
        availableCash: 'Available for use',
        blockedCashBySOAndLO: 'Blocked by open stop & limit orders',
        blockedCashByStockBuy: 'Blocked by pending purchases',
        blockedCashByStockSell: 'Blocked by pending sales',
        blockedByStaking: 'Blocked by staking',
        availableCrypto: 'Available for use',
        blockedCrypto: 'Blocked by open limit orders (Sell)',
        portfolioValue: 'Portfolio value',
        available: 'Available',
        euroValue: 'Euro value',
        solarisLegalText:
          'Disclaimer: Your balance account is provided by Solaris SE. Find out more under <1>account information</1>.',
      },
      emptyState: {
        title: `Start building your crypto portfolio!`,
        body: `Deposit money to your account and add the first cryptocurrencies to your portfolio.`,
        web_alreadyHaveCrypto: `Already have crypto?`,
        lineChartButton: 'Deposit money now',
        pieChartButton: 'Deposit money',
      },
      emptyAssets: {
        crypto: {
          title: 'Crypto',
          mainText: "As soon as you've bought cryptocurrencies, they'll be displayed here.",
          buttonText: 'Trade cryptos now',
        },
        stock: {
          title: 'Stocks',
          mainText: "As soon as you've bought stocks, they'll be displayed here.",
          buttonText: 'Trade stocks now',
        },
        others: {
          title: 'ETFs & Co.',
          mainText: "As soon as you've bought ETFs & Co., they'll be displayed here.",
          buttonText: 'Trade ETFs & Co. now',
        },
      },
      disclaimer: {
        text: 'The prices displayed are bid prices supplied by our data provider, Factset. The return calculation is based on the bid price and may be impacted by order commissions, taxes, or other costs. All data shown is indicative and may be outdated as a result of connection lags.',
      },
      chart: {
        others: 'Others',
      },
    },
    orderHistory: {
      type: 'Type',
      crypto: 'Crypto',
      emptyPendingOrders: 'There are currently no transactions in progress.',
      emptyOrderHistory: 'No transaction available.',
      emptyOrderHistoryTwoFilters: 'No {{crypto}} {{subtype}} transaction available.',
      emptyOrderHistoryOneFilterCrypto: 'No {{crypto}} transaction available.',
      emptyOrderHistoryOneFilterSubtype: 'No {{subtype}} transaction available.',
      headerList: {
        date: 'DATE',
        type: 'TYPE',
        currency: 'CURRENCY',
        amount: 'AMOUNT',
      },
      inProgress: 'In progress',
      completed: 'Completed',
      cryptoFilterModal: {
        title: 'Choose cryptocurrency',
        text: 'Choose a cryptocurrency to filter your activity by:',
        action: 'Apply filter',
      },
      orderTypes: {
        all: 'All',
        buy: 'Purchase',
        sell: 'Sale',
        deposit: 'Deposit',
        withdraw: 'withdrawal',
        depositsAndWithdrawals: 'Deposits & withdrawals',
        crypto: 'Crypto filter',
        corporateactiondecrease: 'Corporate Action Decrease',
        corporateactionincrease: 'Corporate Action Increase',
        limitOrder: {
          buy: 'Limit buy',
          sell: 'Limit sell',
        },
        emptyState: {
          statusInProgress: {
            All: 'There are currently no transactions in progress.',

            nonStocksAll: 'There are currently no transactions in progress.',

            Buy: 'There are currently no purchases in progress.',

            Sell: 'There are currently no sales in progress.',

            DepositWithdraw: 'There are currently no deposits or withdrawals in progress.',
          },
          complete: {
            All: `
Once you've made your first deposit, it will be displayed here.

Everything else – such as buys, sells, deposits & withdrawals, as well as dividend payouts – is also listed here.
            `,

            nonStocksAll: `
Once you've made your first deposit, it will be displayed here.

Everything else – such as buys, sells, deposits & withdrawals – are also listed here.
            `,

            Buy: `
Once you've made your first buy, it will be displayed here.

Transacted sell orders, as well as deposits & withdrawals, can be found under the other categories.
            `,

            Sell: `
Once you've made your first sale, it will be displayed here.

Transacted buy orders, as well as deposits & withdrawals, can be found under the other categories.
            `,

            DepositWithdraw: `
Once you've made your first deposit, it will be displayed here.

Transacted buy or sell orders can be found under the other categories.
            `,
          },
        },
        stopOrder: {
          buy: 'Stop buy',
          sell: 'Stop loss',
        },
        savingsPlan: {
          buy: 'Savings Plan Buy',
        },
        staking: {
          stake: 'Stake',
          unstake: 'Unstake',
          stakingReward: 'Staking reward',
        },
      },
      emptySubtypeWording: {
        buy: 'buy',
        sell: 'sell',
        deposit: 'deposit',
        withdraw: 'withdraw',
      },
      rewardTypes: {
        coupon: 'Coupon',
        referral: 'Referral',
      },
      refresh: 'Refresh',
      transactionPendingInfo:
        'You will be informed as soon as the cryptocurrency has been deposited to your BISON account.',
      cryptoDepositLabel: 'Crypto Deposit',
      report: {
        title: 'Looking for the Info Report?',
        mainText:
          'Info reports, account statements, and downloadable transaction histories are available in your account under the "Reports" section.',
        button: 'To my reports',
      },
      detail: {
        amount: 'Amount',
        marketPrice: 'Purchase price per unit',
        fee: 'Fee',
        orderFee: 'Order Fee',
        buy: 'Buy Price',
        sell: 'Sell price',
        sellPending: '(after taxes)',
        calculating: '...being calculated',
        legalText:
          'All information is without guarantee. You can find information regarding costs as well as your invoice in your <1>inbox</1>.',
        isin: 'ISIN',
        venue: 'Exchange',
        date: 'Date & Time',
        corporateactionincrease: 'Total Disbursement',
        corporateactiondecrease: 'Total Disbursement',
        wallet: 'Wallet',
        destinationTag: 'Destination Tag',
        depositMethod: 'Deposit method',
        instantSepaType: 'Instant payment',
        dividend: 'Dividend per share',
        instantDepositFee: 'Instant Deposit Fee',
      },
    },
    ok: 'OK',
    cancel: 'Cancel',
  },
  automation: {
    newPriceLimit: 'New Limit Order',
    web_newPriceLimit: 'Set Limit Order Rule',
    deletePriceLimit: 'Delete',
    deletePriceLimitSuccess: 'Your limit order has been successfully deleted.',
    deletePriceLimitError: 'Something went wrong while deleting your limit order.',
    selectYourCrypto: 'Create Limit Order',
    limitBuy: 'Buy limit',
    limitSell: 'Sell limit',
    stopBuy: 'Stop Buy',
    stopSell: 'Stop Sell',
    web_selectCrypto: 'Select your crypto',
    cryptoModalSubtitle:
      'Select the currency you want to create a Limit Order for. Next, decide whether it should be a Limit Buy or Limit Sell rule',
    detail: {
      title: {
        Buy: 'Limit Buy Rule',
        Sell: 'Limit Sell Rule',
      },
      subTitle: {
        Buy: 'Limit Buy',
        Sell: 'Limit Sell',
      },
      overview: 'Overview',
      setPriceLimit: 'Limit price',
      priceLimit: 'Price limit',
      amount: {
        Buy: {
          cryptoAmount: 'Amount (indicative)',
          euroAmount: 'Euro amount',
        },
        Sell: {
          cryptoAmount: 'Amount',
          euroAmount: 'Euro amount (indicative)',
        },
      },
      validUntil: 'Valid until',
      validInDays: '({{days}} days)',
      web_buySummaryText: `If the buy price is at <1>{{price}}</1> or lower, then I would like to buy <3>{{amount}}</3> 
      and spend no more than <5>{{euroTotal}}</5> in total. The rule is valid until <7>{{shownDate}}</7> <9>{{daysAmount}}</9>.`,
      web_sellSummaryText: `If the sell price is at <1>{{price}}</1> or higher, then I would like to sell <3>{{amount}}</3>
      and receive at least <5>{{euroTotal}}</5> in total. The rule is valid until <7>{{shownDate}}</7> <9>{{daysAmount}}</9>.`,
      delete: 'Delete order',
    },
    currencyInputLabel: {
      buy: 'Buy crypto',
      sell: 'Sell crypto',
    },
    legalText: `Send binding {{buyOrSell}} with price limit`,
    web_legalText: 'A trading rule does not guarantee a trade will be executed.',
    buyInquiry: 'buy inquiry',
    sellInquiry: 'sell inquiry',
    confirmDescriptionLabelBuy:
      'This trading rule will be applied bindingly and a request to buy will be sent if the scenario stated above occurs.',
    confirmDescriptionLabelSell:
      'This trading rule will be applied bindingly and a request to sell will be sent if the scenario stated above occurs.',
    web_deleteDescriptionLabel: 'Delete binding purchase inquiry with price limit',
    confirmTradeRuleBtnLabel: 'OK, confirm',
    sendTradeRuleBtnLabel: 'OK, send',
    web_buySendTradeRuleBtnLabel: 'Confirm Limit Buy rule',
    web_sellSendTradeRuleBtnLabel: 'Confirm Limit Sell rule',
    fieldErrors: {
      web_required: 'Amount is required',
      web_zero: 'Please enter a higher value',
      web_notEnoughCurrency: 'Not enough Euro on your account',
      web_notEnoughCrypto: 'Not enough {{crypto}} on your account',
    },
    successTitle: 'Limit Order submitted!',
    web_buySuccessTitle: 'Limit Buy rule created',
    web_sellSuccessTitle: 'Limit Sell rule created',
    successText: `Your {{buyOrSell}} for {{amount}} with a price limit of {{limitPrice}} has successfully been submitted.
    You will receive a notification as soon as your {{buyOrSell}} is processed.`,
    web_successText: `Your {{buyOrSell}} for <strong>{{amount}}</strong> with a price limit of <strong>{{limitPrice}}</strong> has successfully been submitted.
    You will receive a notification as soon as your {{buyOrSell}} is processed.`,
    buyRequest: 'purchase request',
    sellRequest: 'sell request',
    failTitle: 'Limit Order declined!',
    errors: {
      generic: 'Could not create a limit order.',
      buyLimitReached: `Explanation: BISON has already reached its maximum limit for accepting buy requests with a price limit of {{limitPrice}}. Please try again later.`,
      sellLimitReached: `Explanation: BISON has already reached its maximum limit for accepting sell requests with a price limit of {{limitPrice}}. Please try again later.`,
    },
    warnings: {
      buyPriceTooGood:
        'Your purchase request will likely be processed promptly because your price limit is higher than the current purchase price.',
      sellPriceTooGood:
        'Your sell request will likely be processed promptly because your price limit is lower than the current selling price.',
    },
    info: {
      web_title: `Limit Order`,
      web_content: `
You can use a trade request with a price limit (limit order) to buy or sell a certain amount of cryptocurrencies at a price previously set by you or for a better price.

A successfully submitted – but not yet processed – limit order will block the expected equivalent value of your trade. The blocked holdings will be displayed in your portfolio. 

A limit order is executed once in the previously defined price limit is reached. The sell prices (sell limit) or buy prices (buy limit) are decisive for this. In certain cases, not all executable limit orders can be filled because no indicative price can be calculated. In such rare cases, these orders remain on hold. Each limit order has a validity period of 90 days. Once the validity period is over, your limit order expires and is deleted.

We will notify you as soon as your limit order has been processed and you have bought or sold the cryptocurrencies. 

If your limit order has not been processed yet, you can track or delete it at any time in the "Trading Manager" menu.
    `,
    },
    emptyState: {
      title: 'Trade without any stress!',
      body: `Don't have time to watch the crypto market around the clock? Buy or sell automatically with limit orders.`,
      info: {
        web_title: `Trading Rules`,
        web_content: `
We currently support two types of trading rules:

### Limit Order

Buy Limit Rule: Enter the market to take advantage of lower prices. You specify a lower price at which you'd like to buy, and a purchase is executed as soon as that price is reached.  

Sell Limit Rule: Exit the market to take advantage of profits. You specify a higher price at which you'd like to sell, and a sale is executed as soon as that price is reached.

### Stop Order

Stop Buy Rule: Enter the market to participate in upward trends. You buy when a cryptocurrency reaches or exceeds a price that you've specified. 

Stop Loss Rule: Exit the market to limit losses. You sell when the price you've specified crosses a lower price point.

---

A pending order is blocking the expected value of your trade. Blocked assets can be viewed in your portfolio.

You can always view or delete your active trading rules in the Trading Manager. Trading rules remain valid for 90 days and are automatically deleted once this period is over.

We will notify you as soon as your trading rule is executed.
`,
      },
    },

    web_allLimitOrders: 'All Limit Orders',

    web_creationAvailableFiat: 'Available Euro',
    web_creationAvailableCrypto: 'Available {{currency}}',
    web_creationTitle: '{{currency}} Limit Order',
    web_buy_creationTitle: 'Set Limit Buy rule',
    web_sell_creationTitle: 'Set Limit Sell rule',
    web_creationBuyPrice: 'Buy price',
    web_creationSellPrice: ' Sell price',

    web_deletePopupMain: 'Are you sure you want to delete your limit order?',
    web_deletePopupConfirm: 'Delete Now',
    web_deletePopupCancel: 'Cancel',

    web_tableHeadType: 'Type',
    web_tableHeadCrypto: 'Crypto',
    web_tableHeadLimitPrice: 'Price',
    web_tableHeadAmountEur: 'Amount (EUR)',
    web_tableHeadAmountCry: 'Amount',
    web_tableHeadExpiry: 'Expiry',
    web_tableHeadCurrentPrice: 'Current Price',

    web_tableBuyPrice: 'Current Buy Price',
    web_tableSellPrice: 'Current Sell Price',

    web_tableExpiryField: '{{value}} days',
  },
  watchlist: {
    tabTitle: 'Watchlist',
    emptyList: 'Your watchlist is empty.',
    emptyListHeader: 'Nothing here yet',
    last24h: 'Last 24H',
    errors: {
      title: 'Error on watchlist',
      addItem: 'Something went wrong with adding the new item.',
      get: 'Error fetching information, try again later.',
    },
    banner: {
      add: 'You successfully added {{name}} to the watchlist',
      remove: 'You successfully removed {{name}} from the watchlist',
    },
  },
  stopOrders: {
    tradingManager: 'To Stop & Limit orders',
    newStopOrder: 'Stop Order',
    deleteStopOrder: 'Delete',
    deleteStopOrderSuccess: 'Your stop order has been successfully deleted.',
    deleteStopOrderError: 'Something went wrong while deleting your stop order.',
    youHaveNoPriceLimitSet: 'You have no stop order set',
    selectYourCrypto: 'Create Stop Order',
    selectStopOrderCrypto: {
      web_title: 'Set Stop Order Rule',
      web_description:
        'Select the currency you want to create a stop order for. Next, decide whether it should be a Stop Buy or Stop Loss rule.',
    },
    stopBuy: 'Stop buy',
    stopLoss: 'Stop loss',
    comingSoon: 'Coming soon',
    tradingRule: 'Order Type',
    detail: {
      title: {
        buy: 'Stop Buy Rule',
        sell: 'Stop Loss Rule',
      },
      overview: 'Summary',
      setStopPrice: 'Stop price',
      priceLimit: 'Price limit',
      cryptoAmount: {
        buy: 'Quantity (indicative)',
        sell: 'Quantity',
      },
      euroAmount: {
        buy: 'Euro value',
        sell: 'Euro value (indicative)',
      },
      validUntil: 'Valid until',
      validInDays: '({{days}} days)',
      web_legalText:
        'This trading rule will be applied bindingly and a request to {{inquiry}} will be sent if the scenario stated above occurs. A trading rule does not guarantee a trade will be executed.',
    },
    buyInquiry: 'buy inquiry',
    sellInquiry: 'sell inquiry',
    confirmDescriptionLabelBuy:
      'This trading rule will be applied bindingly and a request to buy will be sent if the scenario stated above occurs.',
    confirmDescriptionLabelSell:
      'This trading rule will be applied bindingly and a request to sell will be sent if the scenario stated above occurs.',
    confirmStopOrderBtnLabel: {
      buy: 'Confirm Stop Buy rule',
      sell: 'Confirm Stop Loss rule',
    },
    stopPriceDescription: {
      buy: 'Current Buy Price',
      sell: 'Current Sell Price',
    },
    stopOrderDescription: {
      buy: {
        a: 'If the price to buy is at ',
        b: 'or higher, I would like to buy up to ',
        c: 'for ',
        d: 'at the next available price. This rule will be valid through {{date}}.',
      },
      sell: {
        a: 'If the price to sell is at ',
        b: 'or lower, I would like to sell ',
        c: 'for up to ',
        d: 'at the next available price. This rule will be valid through {{date}}.',
      },
    },
    stopOrderFinishedDescription: {
      buy: {
        a: 'If the ',
        b: 'price to buy is at {{stopPrice}} ',
        c: 'or higher, I would like to ',
        d: 'buy up to {{amount}} for {{spendEuro}} ',
        e: 'at the next available price.',
      },
      sell: {
        a: 'If the ',
        b: 'price to sell is at {{stopPrice}} ',
        c: 'or lower, I would like to ',
        d: 'sell {{amount}} for up to {{spendEuro}} ',
        e: 'at the next available price.',
      },
    },
    fieldErrors: {
      stopPriceRequired: 'Please enter a price',
      amountRequired: 'Please enter an amount',
      spendEuroRequired: 'Please enter a EUR value.',
      notEnoughCurrencyToBuy:
        'You do not have enough {{crypto}} to place this stop order – try entering a lower amount.',
      web_notEnoughCurrencyToBuy:
        'You do not have enough {{crypto}} to place this stop order – try entering a lower amount.',
      web_notEnoughFiat: 'You do not have enough EUR to place this stop order – try entering a lower amount.',
    },
    addTitle: {
      buy: 'Set Stop Buy Rule',
      sell: 'Set Stop Loss Rule',
    },
    confirmationTitle: {
      buy: 'Stop Buy Rule {{assetName}}',
      sell: 'Stop Loss Rule {{assetName}}',
    },
    successTitle: {
      buy: 'Stop Buy rule\ncreated',
      sell: 'Stop Loss rule\ncreated',
    },
    notificationInfo: {
      buy: `You'll receive a notification as soon as your buy-request stop order has been executed.`,
      sell: `You'll receive a notification as soon as your sell-request stop order has been executed.`,
    },
    legalText: 'A trading rule does not guarantee a trade will be executed.',
    failTitle: {
      buy: `Stop Buy rule failed to be created`,
      sell: `Stop Loss rule failed to be created`,
    },
    errors: {
      generic: `We're afraid we could not create your trading rule. Please give it another try later.`,
      limitReached: `Stop {{type}} Orders for {{assetName}} in the price range of {{stopPrice}} have all already been taken. You can try to place it again later or use a different stop price.`,
    },
    warnings: {
      buyPriceTooGood:
        'Your trading rule will likely be executed immediately because your specified stop price is lower than the current buy price.',
      sellPriceTooGood:
        'Your trade rule will likely be executed immediately because your specified stop price is higher than the current sell price.',
    },
    info: {
      web_title: `Stop Order`,
      web_content: `
A stop order is a rule used to buy or sell when a price moves above or below a certain point.

Stop Buy Rule: Enter the market to participate in upward trends. You buy when a cryptocurrency reaches or exceeds a price that you've specified. 

Stop Loss Rule: Exit the market to limit losses. You sell when the price you've specified crosses a lower price point.

A stop order that has been submitted but not yet executed will block the expected value of your trade. You can view blocked stocks in your portfolio.

You can view or delete your active trading rules at any time in the Trading Manager. Stop orders have a validity period of 90 days and will automatically be deleted after this period.

We will notify you as soon as your rule has been executed and a buy or sell has been made.`,
    },
    termsAndConditions: {
      title: 'Terms & conditions',
      firstParagraph:
        'New: As of now, you have the possibility to set trade requests with a price limit (limit order). To continue doing so, however, it is necessary that you agree to the changed terms and conditions of EUWAX AG and blocknox GmbH as well as the changes to the basis and risk information.',
      secondParagraph:
        'With your acceptance these changed terms and conditions will become effective immediately.',
      checkboxPreLink: 'Yes, I read, saved, and accept the updated ',
      firstCheckboxLink: 'terms & conditions of EUWAX AG and blocknox GmbH ',
      firstCheckboxPostLink: '.',
      secondCheckboxLink: 'basis and risk information ',
      secondCheckboxPostLink: '.',
    },
    deleteStopOrderConfirmation: {
      web_header: 'Delete trading rule?',
      web_text: `Are you sure you'd like to delete this trading rule? Deleting it cannot be undone.`,
    },
  },
  settings: {
    web_depositWithdrawButton: 'Deposit / Withdraw Funds',
    web_tradingButton: 'Trade',
    web_depositMoney: 'Deposit money',
    web_depositCrypto: 'Deposit crypto',
    // Section titles
    moneyTransferButtons: {
      deposit: 'Deposit EUR',
      withdraw: 'Withdraw EUR',
    },
    help: {
      title: 'Help',
      subsections: {
        featureRequest: 'Submit Feature Request',
        customerService: 'Customer Service',
      },
    },
    newsletter: {
      title: 'BISONews',
      checkboxLabels: {
        newsletter: 'Monthly newsletter',
        product: 'General updates from BISON',
      },
      popupMessages: {
        success: 'Your changes were saved.',
        error: 'Your changes could not be saved. Try again later.',
      },
    },
    general: 'General',
    account: 'Account',
    profile: 'Profile',
    preferences: 'App Settings',
    supportSection: 'Support',
    misc: 'Miscellaneous',
    legal: 'Legal',
    about: 'About us',
    theme: 'Theme',
    themeDescription: 'Appearance',
    light: 'light',
    dark: 'dark',
    stocksEnabled: 'Securities trading',
    stocksRaffle: 'E-Scooter Raffle',

    // Header
    tryMode: 'Demo mode',

    // Items
    realMoney: 'Real money trading',
    realMoneyDescription: 'Switch between real and paper money',
    realMoneyNoKyc: 'Complete Verification process to enter.',
    cryptoDeposit: 'Crypto deposit',
    cryptoWithdraw: 'Crypto withdraw',
    cryptoWithdrawDescription: 'Withdraw cryptos to your wallet',
    redeemCode: 'Redeem code',
    verification: 'Verification',
    verificationStart: 'Start now',
    verificationPending: 'Pending',
    verificationConfirmed: 'Confirmed',
    verificationInvalid: 'Invalid (try again)',
    verificationDescription: 'Verify your identity before trading with real money',
    inbox: 'Inbox',
    inboxDescription: 'Read in-app messages',
    inboxChips: {
      all: 'All',
      notifications: 'Notifications',
      documents: 'Documents',
    },
    deposit: {
      title: 'Deposit',
      web_next: 'Next',
    },
    depositDescription: 'Adds funds to your BISON account',
    withdraw: {
      title: 'Withdraw',
    },
    withdrawDescription: 'Transfer money to your bank account',
    invite: 'Invite friends',
    inviteDescription: 'Share BISON with your friends',
    inviteMessage: {
      message: `Hey, become part of the BISON herd now! :)

You will get {{referredUserRewardAmount}} in {{referredUserPayoutCurrency}} if you join the BISON refer-a-friends program with my invitation. With the BISON App you can easily buy and sell cryptocurrencies.

It's that easy:
1. Click on your smartphone at {{link}} and install the BISON App for free
2. Register and verify your account via video identification process
3. Upload money to your BISON account buy your first cryptocurrency

MADE IT, you belong to the herd.

You can find the conditions here: {{freundeEinladenLink}}.`,
      title: 'BISON refer-a-friend program',
    },
    inviteMessageNoKyc: {
      message: `Hey, become part of the BISON herd now! :)

With the BISON App you can easily buy and sell crypto currencies.
It's that easy:
1. Click on the link {{link}}
2. Install the BISON App for free
3. Register with your email address
4. Verify your account via video identification process
5. Transfer money to your BISON account

MADE IT, you belong to the herd.`,
      title: 'BISON refer-a-friend program',
    },
    personalInfo: {
      title: 'Personal information',
      name: 'Name',
      email: 'Email',
      mobileNumber: 'Mobile number',
      address: 'Home address',
      line1: 'Street',
      line2: 'Street number',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country',
      iban: 'IBAN',
      ibanNumber: 'Current IBAN',
      taxId: 'Tax-ID',
      taxResidency: 'Tax Residency',
      primaryIban: '✓',
      codeTitle: 'Security code',
      smsCodePopupDescription: 'Please enter the confirmation code that we have sent to <1>{{phone}}</1>.',
      emailCodePopupDescription: `We have sent you a code via {{method}} consisting of two parts.
        The first part is send to your old email and the second part to your new email.
        Please enter both parts below to confirm your changes.`,
      code: 'Security code',
      invalidCode: 'Invalid code, the code must be exactly 6 digits.',
      codeRequired: 'You must enter the 6-digit code to change your personal info.',
      web_emailMethod: 'email',
      web_smsMethod: 'SMS',
      changeIBANMessage: `# Change IBAN

  Your reference account is always the bank account from which you made the last deposit to your BISON account:

  {{iban}}

  If you want to change this, you must first deposit money to your BISON account from your new reference account. The reference account then adapts automatically.`,
      ibanRequired: 'Please select a reference IBAN to change to.',
      changeEmail: 'Change email',
      changeAddress: 'Change address',
      changeIban: 'Change IBAN',
      changeTaxId: 'Change Tax-ID',
      addTaxId: 'Add Tax Residency',
      addTaxResidency: 'Add tax residency',
      changeMobileNumber: 'Change mobile number',
      deleteTaxId: 'Delete',
      addTaxIdCountry: 'Add a Tax-ID',
      addAnotherTaxIdCountry: 'Add another Tax-ID',
      changeRisk: {
        title: 'Experience with Securities',
        modalTitle: "It's not possible to re-determine your experience level with securities today.",
        subtitle:
          "You can only determine your experience level with securities once a day. Come back tomorrow and you'll be able to answer the questions again.",
        details:
          "Open questions? We'd be happy to help – feel free to contact us any time via <1>{{supportEmail}}</1>.",
        status: {
          basic: 'Basic',
          informed: 'Intermediate',
          advanced: 'Advanced',
        },
      },
      errors: {
        incorrectCode2fa: 'Invalid confirmation code',
        invalidTaxId: 'Please enter a valid Tax-ID for your country.',
        changeUsernameInitGenericError: 'There was a problem changing your email, please try again later.',
        changeUsernameGenericError: 'There was a problem changing your email, please try again later.',
        changeAddressInitGenericError: 'There was a problem changing your address, please try again later.',
        changeIbanInitGenericError: 'There was a problem changing your address, please try again later.',
        changeAddressGenericError: 'There was a problem changing your address, please try again later.',
        changeIbanGenericError: 'There was a problem changing your reference IBAN, please try again later.',
        changeIbanNotRequired: 'This is already your primary reference IBAN.',
        changeTaxIdGenericError: 'There was a problem changing your Tax-ID, please try again later.',
        taxIdRequired: 'Please enter your Tax-ID.',
        taxIdCountryExists: 'You have already submitted a Tax-ID for this country.',
      },
      success: {
        changeEmail: 'You have successfully changed your email.',
        changeAddress: 'You have successfully changed your address.',
        changeIban: 'You have successfully changed your primary reference IBAN.',
        changeTaxId: 'You have successfully changed your Tax-ID information.',
      },

      web_infoChange: `Your personal data can currently only be changed in the BISON App.

  *[Go to BISON App](https://xfgv.adj.st/settings?adjust_t=r1kq7p1_837qlr5&adjust_deeplink=bisonapp%3A%2F%2Fsettings)*`,
    },
    accountInfo: {
      title: 'Account information',
      label1: 'Name',
      label1Content: 'Solaris SE',
      label2: 'IBAN',
      label3: 'BIC',
      paragraph:
        'Please note when making <1>deposits</1><2>: The recipient is </2><3>{{name}}</3><4> and </4><5>BISON App</5><6> must be stated as the purpose of payment.</6>',
      subTitle: 'Securities account number',
      text: "Your securities account number will be displayed here as soon as you've enabled your account for stocks and ETFs.",
      secondaryButton: 'Enable now',
      bankName: 'Solarisbank',
    },
    offsetAccounts: {
      title: 'Offset Accounts',
      description:
        'Profits are offset against potential losses before they are taxed or offset against the exemption order.',
      rows: {
        a: {
          text: 'Loss Offset Account Stocks',
          hint: 'Holds stock losses that have only been offset against stock gains.',
        },
        b: {
          text: 'Loss Offset Account ETFs',
          hint: 'Includes e.g. losses from transactions with ETFs.',
        },
        c: {
          text: 'Withholding Tax Offset Account',
          hint: 'Non-creditable foreign withholding tax that is offset against capital gains tax.',
        },
      },
    },
    taxExemption: {
      twoFaModal: {
        title: '2-Factor Authentication',
        phoneNumberText:
          "You want to adjust your exemption order. Please confirm that it is you by entering the security code we've sent via SMS to <1>{{phone}}</1>.",
        inputLabel: 'Security code',
        supportText: 'If you do not have access to this phone number, please contact us via',
        repeatSending: {
          sms: "Haven't received an SMS? <1>Send SMS again</1>",
          email: "Haven't received an mail? <1>Send mail again</1>",
        },
        buttonText: 'Continue to activation',
        error: 'Please enter a security code.',
      },
      title: 'Exemption Order',
      card: {
        title: 'Exemption Order',
        emptyText:
          'You have not created an exemption order yet. An exemption order (FSA) can be up to {{amount}}.',
        emptyButton: 'Create exemption order',
        filledText: {
          year: 'Exemption order {{year}}',
          amount: 'Amount',
          maxLegalText:
            "You've already claimed {{amountUsed}} of a maximum of {{maxAmount}} with BISON this year (updated daily after the market closes).",
        },
      },
      infoModal: {
        title: 'Exemption Order',
        mainText: `    
        If you have created an exemption order, no tax will be deducted from your capital gains up to the amount you have specified. 
<br/>
<br/>
          The maximum amount that can be deducted from your capital gains is {{amount}} per year. Keep in mind that an exemption order applies to the investment income of the current tax year. Capital gains include, for example, profits from securities transactions or dividends.`,
      },
      creationAndEditingModal: {
        title: 'Create exemption order (FSA)',
        explanation:
          'You have not created an exemption order yet. An exemption order can amount to up to {{amount}} .',
        input: {
          label: 'Amount',
          placeholder: 'Max. {{amount}}',
          minError: 'Minimal value is 0.',
          highError:
            'This amount exceeds the statutory maximum of {{amount}} per tax year. Specify an amount under {{amount}}.',
          underPreviousAmount:
            'The amount is less than the amount of {{currentAmount}} you already claimed in the {{year}} tax year. Enter an amount between {{minimumAmount}} and {{maximumAmount}}.',
        },
        expirationDate: {
          label: 'Run until',
          a: 'I place another order',
          b: 'End of {{year}}',
        },
        checkbox:
          'I have read the instructions regarding exemption orders and confirm my information and tax ID: {{taxId}}',
        button: 'Create exemption order',
        editButton: 'Save changes',
        legal:
          'I hereby request that my investment income accruing with BISON be exempt from capital gains tax and/or, in the case of dividends and similar investment income, I request a refund of capital gains tax in the above-mentioned amount.',
      },
      limitReachedModal: {
        title: 'Editing exemption order for {{year}} is not possible',
        mainText:
          'You have already claimed the maximum amount of {{amount}} in the tax year {{year}} with BISON (updated daily after the market closes). Editing your exemption order for {{year}} is, therefore, unfortunately no longer possible.',
      },
      resultModal: {
        successTitle: '2-factor authentication successful',
        errorTitle: 'Your security code is invalid',
      },
    },
    mobileNumberChange: {
      mobileNumberRequired: 'Please enter a valid mobile number.',
      addMobileNumber: 'Assign mobile number',
      changeMobileInfo: {
        a: 'Changing your mobile number takes ',
        b: '2 steps',
        c: '1.',
        d: ' Unpair your old mobile number. Therefore you need to have access to your old number.',
        e: '2.',
        f: ' Assign your new mobile number.',
      },
      startMobileChange: 'Start',
      noMobileNumberAccessMsg: `You don't have access to your old number?`,
      assignMobileInfo: {
        a: 'Enter your ',
        b: 'new number',
        c: ' below to assign it to your account. You will receive a security code to your new number to confirm it.',
      },
      unpairNumber: {
        a: 'You will receive a security code to your ',
        b: 'old mobile number',
        c: ' ({{currentMobileNumber}}) in order to unpair it.',
        sendCode: 'Send code',
      },
      supportScreen: {
        a: `If you don't have access to your old mobile number, please contact the BISON support in order to unpair it.`,
        b: 'After your old number is unpaired, you will be able to proceed with assigning your new mobile number.',
        contactSupport: 'Contact support',
      },
      errors: {
        assignGenericError: 'There was a problem assigning your mobile number, please try again later.',
        assignInitGenericError: 'There was a problem assigning your mobile number, please try again later.',
        deleteInitGenericError: 'There was a problem unpairing your mobile number, please try again later.',
        deleteGenericError: 'There was a problem unpairing your mobile number, please try again later.',
      },
      success: {
        assignMobileNumber: 'You have successfully assigned your new mobile number.',
        deleteMobileNumber: 'You have successfully unpaired your old mobile number.',
      },
    },
    personalInfoDescription: 'Name, address, email',
    changePass: {
      title: 'Change password',
      currentPassword: 'Current password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      change: 'Change',
      changing: 'Changing...',
      success: 'Successfully changed password',
      errorTitle: 'Password Change Failed',
      userMismatch: "Sorry, you don't have permissions to do that.",
      oldPwdWrong: 'Incorrect current password',
      newPwdInvalid: 'New password does not meet our mininmal security standards.',
      newPwdSameAsOld: 'New password should be different from the current',
      // Errors
      genericError: 'Sorry, we could not change your password at this time. Please try again later.',
      fieldErrors: {
        passwordConfirmRequired: 'Please confirm your new password',
        passwordConfirm: 'Your confirmation does not match password',
      },
    },
    changePassDescription: 'For your main app access',
    notifications: {
      title: 'Notifications',
      inApp: 'In-app messages',
    },
    notificationsDescription: 'Push and email notifications',
    accountProtection: {
      title: 'Account protection',
      biometricLogin: 'Biometric login enabled',
      biometricLoginDescription: 'Log in with {{biometryType}}',
      passwordLogin: 'Password login enabled',
      passwordLoginDescription: 'Activate log in via password',
    },
    reports: {
      title: 'Reports',
      infoReport: {
        title: 'Info-Report',
        description: `BISON's Info-Report gives you a clear and detailed overview of your transactions and provides you with a non-binding calculation of realised profits and losses at BISON using the FIFO (First-In First-Out) principle.`,
        web_description: `With the Info Report, BISON shows your transactions clearly and in detail and provides you with a non-binding calculation of the realised profits and losses at BISON according to the FiFo procedure (First-In-First-Out). Simply select the tax year and you will receive your info report by e-mail.

For the current year, a preliminary info report can be called up, which shows the profits and losses currently realised for the cryptocurrencies at the time of the call. In addition, a corresponding transaction overview is provided.`,
        success: `Your info report for the tax year {{year}} has been sent to your e-mail address.`,
        successAlreadySent: `Your info report for the tax year {{year}} has already been sent to your e-mail address.`,
        error: {
          pleaseSelect: 'Please select a tax year',
          generic: 'Sorry, reporting is currently unavailable. Please try again later.',
        },
        web_buttonText: 'Send',
      },
      bankStatement: {
        title: 'Bank account statement',
        description: {
          a: 'Your account statement provides you with an overview of your balance in EUR for the respective billing period.',
          b: 'Simply select a month and you will receive your account statement to the email address you have provided.',
        },
        selectMonth: 'Please, select a month',
        months: {
          january: 'January',
          february: 'February',
          march: 'March',
          april: 'April',
          may: 'May',
          june: 'June',
          july: 'July',
          august: 'August',
          september: 'September',
          october: 'October',
          november: 'November',
          december: 'December',
        },
        noMonthsAvailable: "You don't have any bank account statements yet",
        pickerTitle: 'Select a month',
        success: 'Your bank statement for {{month}} has been sent.',
        web_success: 'Bank account statement sent',
        error: 'There was an error, please try again later ... ',
        web_error: 'Bank account statement could not be sent',
        pleaseSelect: 'Please select a month',
        web_buttonText: 'Send',
        notificationText: {
          a: 'Looking for your securities info report? ',
          b: 'This will automatically be sent to your ',
          c: 'inbox',
          d: ' periodically.',
        },
      },
      transactionHistory: {
        web_title: 'Export history',
        title: 'Transaction history',
        description: {
          a: 'With the transaction history BISON shows you your transactions for the respective transaction period clearly and in detail.',
          b: 'Simply select a time period and you will receive your transaction history in CSV format via email to the email address you provided.',
        },
        pickerTitle: 'Select a period',
        pleaseSelect: 'Please select a period',
        noMonthsAvailable: "You don't have any transaction history statements yet",
        allTime: 'Total transaction history',
        success: {
          year: 'Your transaction history for {{year}} has been sent to your email address',
          allTime: 'Your total transaction history has been sent to your email address.',
        },
        error: 'There was an error, please try again later ...',
        successAlreadySent: {
          year: 'Your transaction history report for {{year}} has already been sent to your email address.',
          allTime: 'Your total transaction history has been already sent to your email address.',
        },
        send: 'Send',
        sending: 'Sending...',
      },
    },
    tradingExperience: {
      title: 'Experience & Knowledge',
      screenTitle: 'Update experience with securities',
      risk: 'risk',
      lowRisk: 'Low to mid ',
      medRisk: '(Very) high ',
      highRisk: 'Highest ',
      description: 'Please indicate what experience you have with various securities.',
      success: 'You successfully updated your experience and knowledge',
      fail: 'Something went wrong ...',
      levels: {
        basic: 'Basic knowledge',
        experienced: 'Intermediate knowledge',
        advanced: 'Advanced knowledge',
      },
      saveChanges: 'Save changes',
    },
    terminateAccount: {
      terminateAccount: 'Terminate account',
      terminatingAccount: 'Terminating account ...',
      terminateAccountSuccess: 'Account terminated!',
      reason: 'Please state why you are leaving BISON:',
      reasonPlaceholder: 'Enter your reason here ...',
      confirmTermination: 'Confirm contract termination',
      contactSupport: 'Contact BISON support',
      demoTerminateInitial: {
        a: "It's a shame to hear you'd like to leave us.",
        b: {
          demo: "If you're confident that you want to terminate your contractual relationship with EUWAX AG regarding your use of our demo mode together with our sample securities account and have your BISON account irrevocably deleted, you can do so by sending this message.",
          stocksNotEnabled:
            'Please be aware that by terminating your account, you are terminating your contractual relationship with EUWAX AG, blocknox GmbH, and Solaris SE regarding your use of the BISON app in real-money mode, and your BISON account will be irrevocably deleted.',
          stocksEnabled:
            'If you are confident that you want to terminate your contractual relationship with EUWAX AG, blocknox GmbH, Solaris SE, and Max Heinr. Sutor oHG regarding your use of the BISON app in real-money mode and have your BISON account irrevocably deleted, you can do so by sending this message.',
        },
        c: {
          demo: 'Afterward, we will confirm both your request as well as the termination of your contract by email, and your BISON account will be deleted immediately.',
          real: 'Before terminating – and therefore irrevocably deleting – your BISON account, take note of and confirm the following points:',
        },
      },
      demoTerminateSuccess: {
        title: 'Contract termination confirmed',
        a: 'We hereby confirm that we have received your request for termination. We will immediately and permanently delete your BISON account. You will no longer have access to the BISON app.',
        b: 'Next, we will confirm via email that we have received your termination request and your contract termination, and your BISON account will be deleted immediately.',
        c: 'In addition, we will delete your personal data immediately provided that we do not have a legitimate interest in its further use, as defined in Article 17 (3) of the GDPR, in particular for the purpose of fulfilling a legal obligation to retain data.',
      },
      reasonIsTooLong: 'Max. 512 characters',
      genericError:
        'Unfortunately, we were unable to process your account termination request. Please try again later.',

      real: {
        checkboxStep: {
          title: 'Delete real trade account',
          header: 'We’re sorry to hear that you want to leave the BISON herd!',
          body: {
            a: 'If you are certain that you would like to terminate your contractual relationship with EUWAX AG, blocknox GmbH, and Solaris SE regarding the use of the BISON app with real money, and would like to have your BISON account permanently deleted, you can do so by submitting this form.',
            b: 'Please read and confirm the following checklist before proceeding to delete your account:',
            c: 'We will send you an email confirming the termination of your contract; your BISON account will then be deleted immediately.',
            reason: 'Why are you leaving BISON? Help us understand by explaining below:',
            reasonPlaceholder: 'Enter reasoning here...',
            confirm: 'Confirm contract termination',
            cancel: 'Cancel',
          },
          a: {
            stocksNotEnabled: 'I no longer have any EUR and / or cryptocurrencies in my BISON account.',
            stocksEnabled: 'I no longer have any EUR, cryptocurrencies, or securities in my BISON account.',
          },
          b: 'I am fully aware that termination will result in the deletion of any open limit orders I may have.',
          c: 'I do not have any previously initiated EUR deposits or cryptocurrency deposits that are still pending.',
          d: 'I have downloaded my respective info report.',
          e: 'I have downloaded my bank statements.',
        },
        failure: {
          title: 'Assets still available',
          header: {
            currency: 'You still have EUR and/or cryptocurrencies in your portfolio.',
            currency_crypto: 'You still have EUR and cryptocurrencies in your portfolio.',
            currency_stock: 'You still have EUR and securities in your portfolio.',
            currency_crypto_stock: 'You still have EUR, cryptocurrencies, and securities in your portfolio.',
            crypto: 'You still have cryptocurrencies in your portfolio.',
            crypto_stock: 'You still have cryptocurrencies and securities in your portfolio.',
            stock: 'You still have securities in your portfolio.',
          },
          body: {
            a: {
              currency: 'To prevent delays in processing, please clear out your EUR/crypto first. ',
              currency_crypto:
                'To prevent delays in processing, please clear out your EUR and cryptocurrencies first.',
              currency_stock:
                'To prevent delays in processing, please clear out your EUR and transfer your securities to another securities account first.',
              currency_crypto_stock:
                'To prevent delays in processing, please clear out your EUR and cryptocurrencies and transfer your securities to another securities account first.',
              crypto: 'To prevent delays in processing, please clear out your cryptocurrencies first.',
              crypto_stock:
                'To prevent delays in processing, please clear out your cryptocurrencies and transfer your security to another securities account first.',
              stock:
                'To prevent delays in processing, please transfer your securities to another securities account first.',
            },
            b: 'If you have any further questions, feel free to contact BISON Support at <1>{{supportEmail}}</1>.',
          },
          web_button: 'Close',
          web_link: 'Contact BISON Support',
          web_mailSubject: 'BISON App - Deletion {{username}}',
        },
        confirm: {
          text: `#### Are you sure you would like to terminate your BISON account?
Doing so is irreversible – your BISON account will be deleted immediately and permanently after this step.
`,
          cta: 'Yes, terminate',
        },
      },
      error: 'Unfortunately, we could not process your deletion request. Please try again later.',
    },
    submitFeature: {
      title: 'Submit a feature request',
      submitRequest: 'Submit now',
      textBoxLabel: 'Description:',
      firstParagraph:
        'Tell us your ideas on how to improve the BISON app! We look forward to hearing your feedback and suggestions for new features.',
      web_firstParagraph:
        'Tell us your ideas on how to improve BISON! We look forward to hearing your feedback and suggestions for new features.',
      secondParagraph:
        "We hope you understand that we can't respond to every single suggestion. If we have specific questions about your request, we will get back to you via email.",
      notification: {
        success: {
          title: 'Your feature request has been received!',
        },
        error: {
          title: 'Something went wrong with your submission. Please try again.',
        },
      },
      fieldErrors: {
        tooFewChars: 'Tell us a bit more',
      },
    },
    accountProtectionDescription: 'Change additional app security',
    tutorial: 'Tutorials',
    tutorialDescription: 'Take a look at the tutorials',
    rateUs: 'Like BISON? Rate us!',
    rateUsDescription: 'Rate BISON',
    support: 'BISON Support',
    supportDescription: 'Get help from support',
    website: 'Website',
    websiteDescription: 'Visit our website',
    buildVersion: 'Version',
    logout: 'Sign out',
    logoutDescription: "You'll need to login again",
    resetPaper: 'Logout from demo mode',
    resetPaperDescription: 'Your portfolio and transaction history will be reset.',
    faqs: 'FAQs',
    faqsDescription: 'To the FAQs',
    attributions: 'Attributions',
    attributionsDescription: 'Licences of 3rd party software',
  },
  inbox: {
    header: 'Message',
    noMessages: {
      title: 'No notifications or documents yet',
      text: 'Your messages and documents will appear here as soon as you receive them.',
    },
    from: 'From: ',
    to: 'To: ',
    tableHeader: {
      web_subject: 'Subject',
      web_from: 'From',
      web_date: 'Date',
    },
    successfullyDeleted: 'Successfully deleted message.',
    successfullyDeletedMany: 'Successfully deleted messages.',
    tapToUndo: 'Tap here to undo',
    successfullyMarkedAsRead: 'Messages marked as read.',
    alreadyMarkedAsRead: `You've already read all messages.`,
    errorDeletingMany: 'Nothing to delete',
    buttons: {
      read: 'Mark all as read',
      delete: 'Delete all',
    },
    menu: {
      all: {
        markAsRead: 'Mark all as read',
        delete: 'Delete all messages',
        select: 'Select all messages',
      },
      selected: {
        markAsRead: 'Mark selected as read',
        delete: 'Delete selected messages',
        select: 'Unselect all messages',
      },
    },
    confirmDeleteAll: 'Are you sure, you want to delete all inbox messages?',
    confirmDeleteSelected: 'Are you sure, you want to delete selected messages?',
    web_confirmDeleteSingle: 'Are you sure, you want to delete this message?',
    deleteMessages: 'Delete messages',
    web_deleteMessage: 'Delete message',
    web_markAsRead: 'Mark as read',
    deleteMessagesHeader: 'Delete all messages',
    deleteSingleMessageHeader: 'Delete message',
    deleteMessagesAdditionalInforation: 'Information: Messages with attachements will not be deleted.',
  },
  redeemCode: {
    title: 'Redeem code',
    form: {
      title: 'Your code',
      codeRequired: 'Please enter your code',
      codeInvalid: 'Please enter a valid code',
    },
    errorTitle: 'Redeem Code Failed',
    error: {
      alreadyClaimed: 'Entered code has already been used.',
      expired: 'Entered code has expired.',
      notYetStarted: 'The promotion event for entered code has not yet started. Please try again later.',
      invalid: 'Entered code is not valid',
      reclaimedEvent: 'You have already redeemed a code for this event.',
      kycRequired: "Sorry, we can't redeem your code until you complete the KYC process.",
      depositRequired: "Sorry, we can't redeem your code until you make your first deposit.",
      notWon: "Sorry, you didn't win this time.",
      kycbeforecodeactive:
        'Sorry, it looks like you have completed the verification before this campaign started.',
      claimcountlimitreached:
        'Sorry, this coupon is no longer valid as the maximum number of available codes has been claimed.',
      nottargetuser:
        'This code may only be claimed by the user specified in the coupon. Please contact customer support.',
      registerbeforecodeactive:
        'Sorry, it looks like you have already registered before this campaign started.',
      kycmorethan48hago: "Sorry, it looks like you've missed the verification window for this campaign.",
      generic: 'Sorry, we could not redeem your code at this time. Please try again later.',
    },
    success: {
      headerText: "You've successfully added {{amount}} to your account",
      mainText: 'Your cryptocurrencies are now available in your portfolio.',
    },
    notClaimed: {
      notExpired: {
        title:
          'Great! You’re just one step away from claiming your reward of {{amount}} in {{payoutCurrency}}',
        mainText: `Complete the following steps within {{deadline}} to claim your reward:

1. Successful verification (Video-Ident).
2. Money (EURO) deposit.
3. First trade of a cryptocurrency.
        `,
      },
      processing: 'Your reward is currently being processed. Please check back later.',
      expired: 'Unfortunately your reward code has expired',
    },
    caption: {
      a: 'You can read the terms and conditions for redeeming your promotion code ',
      b: 'here.',
      c: '',
    },
    redeem: 'Redeem',
  },
  referral: {
    link: 'Share link',
    or: 'or',
    web_or: 'or send out an invitation',
    participation: 'Terms and Conditions',
    title: 'Invite friends',
    accepted: 'Accepted',
    earned: 'Earned',
    web_earned: 'Earned so far',
    completed: 'Completed',
    inviteText: {
      short: 'Get {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}}',
      medium: 'Get {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}} for each new Bison',
      long: 'Share your joy and receive {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}} for each new BISON',
    },

    web_cta: 'Invite your friends and get {{referredUserRewardAmount}} in {{codeOwnerRewardPayoutCurrency}}',
    bonus:
      'Receive {{codeOwnerRewardAmount}} in {{codeOwnerRewardPayoutCurrency}} for each successfully referred user. The referred user receives {{referredUserRewardAmount}} in {{referredUserPayoutCurrency}}.',
    noCampaign:
      'Unfortunately the BISON refer-a-friend program is currently not active. We will inform you about changes.',
    send: 'Send invitation',
    helpPopup: `# Invite friends

*Accepted* = The number of your friends who have registered via your invitation link in the BISON App.

*Completed* = The number of your friends who have registered and fulfilled all conditions to receive the bonus.

*Earned* = The amount of your bonus, which you get credited due to successfully referred users.
    `,
    paperWarning:
      'Please note: Participation in the refer-a-friend program is only possible in real money mode.',
    moreInfo: 'Terms and conditions',
  },
  securitiesKyc: {
    entryModal: {
      title: 'Trade Stocks & ETFs Now, Too.',
      text: 'Unlock your account for stocks & ETFs and start trading securities today.',
      unlockNow: 'Enable now',
      remindLater: 'Remind me later',
    },
    leaveKYCPopup: {
      title: 'Do you want to cancel the activation?',
      text: "Don't worry! When you leave this page, your previous entries will be saved and you can continue the verification at this point later on.",
      continueKYC: 'Continue',
      leaveKYC: 'Resume later',
    },
    secondAttemptToSubmitKyc: {
      title: 'Unlocking Already Requested',
      text: "You have already successfully requested to unlock your account.\n\n It can take **up to about 15 minutes** until the process is confirmed and you can start trading stocks & ETFs. We'll send you an e-mail as soon as your account is enabled to do so.\n\n",
      support:
        "Open questions? We'd be happy to help – feel free to contact us any time via  [customer support form]({{supportLink}}).",
    },
    unavailableModal: {
      title: 'Trading stocks & ETFs cannot be activated',
      text: "Unfortunately, we're unable to allow individuals who are not liable to German tax to trade stocks & ETFs at this time. \n\nIf you'd like, we can notify you via email as soon as this becomes possible.",
      notifyViaEmail: 'Notify me via email',
      noThanks: 'No thanks',
    },
    taxInfoStepOne: {
      question: 'Are you liable to pay taxes solely in Germany?',
      info: `# German Tax Liability

Right now, our partner bank only offers stock trading for individuals who are only liable to tax in Germany.  

We are, however, working diligently to make this feature available to all of our other users as well.`,
    },
    taxInfoStepTwo: {
      question: "What's your tax ID?",
      label: 'Tax Identification Number',
      info: `# Tax Identification Number

In Germany, tax IDs contain 11 digits and are also referred to as tax identification numbers.  

Individuals liable to tax in Germany can find their tax number on, for example, their income tax return, their income tax assessment, or on a letter from their local tax authorities.`,
      error: 'German Tax ID consists of exactly 11 digits',
    },
    addressStep: {
      title: 'Address',
    },
    personalInfoStep: {
      title: 'Personal Information',
      text: "To enable trading stocks, ETFs and the like, it's necessary to get to know you a bit better.",
      salutationLabel: 'Salutation',
      mr: 'Mr.',
      ms: 'Ms.',
      maritalStatusLabel: 'Marital status',
      instructions: 'Select from the following',
      single: 'Single',
      married: 'Married',
      divorced: 'Divorced',
      widowed: 'Widowed',
    },
    jobInformationStep: {
      title: 'Occupation',
      text: 'We need details about your professional background due to legal requirements.',
      employmentStatusLabel: 'Type of employment',
      professionalFieldLabel: 'Industry / Sector',
      select: 'Select from the following',
      employmentStatusOptions: {
        employee: 'Employee',
        worker: 'Worker',
        civilServant: 'Civil servant',
        soldier: 'Soldier / BFD',
        manager: 'Manager / Board of Directors',
        artisan: 'Artisan / Handyman',
        homemaker: 'Homemaker',
        privat: 'Person of independent means',
        pensioner: 'Pensioner / Retiree',
        student: 'Student / Apprentice',
        selfEmployed: 'Self-employed / Freelancer',
        noEmployment: 'No employment',
        seniorStaff: 'Senior staff',
      },
      professionalFieldOptions: {
        construction: 'Construction',
        energy: 'Energy / Power supply',
        education: 'Education / Teaching',
        finance: 'Finance / Insurance',
        hospitality: 'Hospitality',
        healthcare: 'Healthcare / Social services',
        retail: 'Retail / Maintenance',
        realEstate: 'Real estate',
        information: 'Information / Communication',
        art: 'Art / Entertainment',
        agriculture: 'Agriculture / Forestry',
        publicService: 'Public service',
        privateHouseholds: 'Private households',
        other: 'Other services',
        manufacturing: 'Manufacturing',
        transport: 'Transport and storage',
        waste: 'Water / waste removal',
        mining: 'Mining',
        scientificServices: 'Scientific services',
        economicServices: 'Economic services',
      },
    },
    securitiesStep: {
      question: {
        a: 'Have you ever traded ',
        b: 'low to medium-risk ',
        c: 'asset classes?',
      },
      reason:
        "We're interested in learning more about your experience with securities - after all, we're already familiar with your crypto background.",
      examples: 'For example, bond funds & bond ETFs or federal, state, and local government securities...',
      info: `# Asset classes with low to medium-risk
      
#### Federal, state, & local government securities in €

Government securities, i. e. public bonds or state bonds, are used to finance public budgets and are administered by the government. 

#### Public bonds (EU) in €

These are bonds issued by an EU state or its local authorities (e.g. federal, state, and local governments) that have EUR as their nominal currency (transaction currency).

#### Public bonds (outside the EU) in €

These are bonds issued by a non-EU state or its local authorities (e.g. federal, state, and local governments) that have EUR as their nominal currency (transaction currency).

#### Geldmarktfonds & ETFs in €

Money market funds invest in money market positions with a maximum maturity of 12 months. These funds are characterized by high liquidity and good tradability. Examples of this type include government bonds, certificates of deposit issued by banks, and promissory note loans.

#### Near-money-market fund / ETFs

Near-money-market funds / ETFs invest paid-in capital in fixed-income securities with short residual maturities, floating-rate bonds, and time deposits. They aim to generate a steady return that is largely unaffected by interest rate fluctuations.

#### Bond funds & ETFs without currency risk

Bond funds and ETFs without currency risk invest in bonds that have EUR as their nominal currency (transaction currency).
      `,
    },
    indexesStep: {
      question: {
        a: 'Have you ever traded ',
        b: '(very) high-risk ',
        c: 'asset classes?',
      },
      reason:
        "We're interested in learning more about your experience with securities - after all, we're already familiar with your crypto background.",
      examples:
        'For example, stocks, equity funds, equity ETFs of popular indices (EuroStoxx, DAX, or the like), or index certificates...',
      info: `# Asset classes with (very) high risk
      
#### Stocks & bonds of companies listed in the DAX & Euro Stoxx 50

Stocks (equity) and bonds (debt) in this risk class are used to finance companies listed in the DAX and/or the EURO STOXX 50.

#### Index certificates

Index certificates allow investors to participate one-to-one in the performance of a stock market index – without buying the underlying shares. Each index certificate has a reference index ratio that defines the value of the certificate in relation to the index level.

#### Public bonds in foreign currency

These are bonds issued by a state or its local authorities (e.g. federal, state, and local governments) whose nominal currency (transaction currency) is not the EUR.

#### Bond funds & ETFs with currency risk

Bond funds and ETFs with currency risk invest in bonds whose nominal currency (transaction currency) is not the EUR.

#### Mixed funds / ETFs

Mixed funds / ETFs can invest in various asset classes at the same – e.g. equities, bonds, real estate – by means of their investment strategy.

#### Structured bonds

These are interest-bearing bonds with individual additional terms (such as maturity, repayment, and interest).

#### Stock Funds & ETFs

Stock funds and ETFs invest paid-in capital exclusively in stocks; other asset classes are not possible.

#### Investment Certificates

Investment certificates allow investors to participate in the performance of an underlying asset without having to actually purchase it, depending on the structure of the respective investment certificate.

#### Subscription Rights

Subscription rights entitle the holder, with consideration of the subscription ratio, to acquire newly issued shares in the context of corporate action.
              `,
    },
    leveragedStep: {
      question: {
        a: 'Have you ever traded ',
        b: 'highest-risk ',
        c: 'asset classes?',
      },
      reason:
        "We're interested in learning more about your experience with securities - after all, we're already familiar with your crypto background.",
      examples:
        'For example, leveraged certificates, other financial futures, or stocks & bonds from other indices...',
      info: `# Asset classes with the highest risk

#### Stocks & bonds from other indices

Stocks (equity) and bonds (debt) in this risk class are used to finance companies that are not listed in the DAX or the EURO STOXX 50.

#### Leverage Certificates

Leverage certificates are derivatives (debt instruments) that allow investors to realize a disproportionate performance compared to the underlying asset.

#### Other Financial Futures

Other financial futures include transactions between two contracting parties for conditional (e.g. option) or unconditional (e.g. future) settlement.
      `,
    },
    contractDocumentsStep: {
      title: 'Contract Documents',
      text: 'Confirm that you have read, saved, and accept the following documents:',
      checkbox1: `I have read, saved, and accept the [general terms and conditions of Sutor Bank](http://bisonapp.com/dokumente/wertpapiere), the [special conditions for securities transactions](http://bisonapp.com/dokumente/wertpapiere), the [list of prices and services](http://bisonapp.com/dokumente/wertpapiere), the [consumer information and information for depositors](http://bisonapp.com/dokumente/wertpapiere), as well as the [general terms and conditions and information documents issued by Euwax](http://bisonapp.com/dokumente/wertpapiere).`,
      checkbox2: `I have read, saved, and accept the [Euwax data protection declaration](http://bisonapp.com/dokumente/wertpapiere), the [Euwax special terms and conditions for securities](http://bisonapp.com/dokumente/wertpapiere), the [Euwax pre-contractual user Information](http://bisonapp.com/dokumente/wertpapiere), the [Euwax customer information on Euwax and its services](http://bisonapp.com/dokumente/wertpapiere), the [Euwax securities order transmission principles](http://bisonapp.com/dokumente/wertpapiere) and the [blocknox data protection declaration](http://bisonapp.com/dokumente/wertpapiere).`,
      checkbox3: `I have read, saved, and accept the [Sutor Bank privacy policy](http://bisonapp.com/dokumente/wertpapiere), [information on handling conflicts of interest](http://bisonapp.com/dokumente/wertpapiere), [information on the bank and its services](http://bisonapp.com/dokumente/wertpapiere), and the [Sutor Bank contractual terms and conditions](http://bisonapp.com/dokumente/wertpapiere).`,
      confirmStatement: 'Confirm the following statement:',
      checkbox4: `I have reviewed the [basic information on securities and other investments](https://postbox.bisonapp.com/message/1.0/pdf/55/1?salutation={{ salutation }}&firstName={{ firstName }}&lastName={{ lastName }}&owner={{ owner }}&title=).`,
      info: `# Contract Documents

By unlocking your BISON account for stock and ETF trading, you are entering into an extended contractual relationship.

To offer stocks and ETFs in the BISON app, we work together with Sutor Bank.

It goes without saying that we handle your data with the utmost care and adhere to the latest legal requirements, such as the EU General Data Protection Regulation and the German Federal Data Protection Act, alongside other applicable data protection regulations.
`,
    },
    chargebacksStep: {
      title: 'Chargeback',
      confirmStatement: 'Confirm that you are in agreement with the following statement:',
      checkbox:
        'I acknowledge that I am not entitled to the return of any chargebacks. Sutor Bank receives chargebacks from third parties (e.g. stock exchanges, trading partners, fund companies) and will retain these if it is permitted to accept them under the Securities Trading Act (esp. §70 WpHG).',
      info: `# Chargebacks

What are chargebacks? Chargebacks are similar to commissions. For example, banks receive commissions for products sold by product providers such as investment companies. 
`,
    },
    taxIdConfirmation: {
      title: `Confirmation of Your Tax Information`,
      text: 'Make sure to check your tax identification number and tax residency. If they have changed, you can update them right here.',
      taxIdLabel: 'Tax Identification Number',
      taxResidencyLabel: 'Tax residency',
      errors: {
        empty: 'Please enter a tax ID.',
        tooShort: "The tax ID you've entered is too short. Please enter a tax ID with 11 numbers. ",
        tooLong: "The tax ID you've entered is too long. Please enter a tax ID with 11 numbers. ",
        notNumber:
          "The tax ID you've entered contains letters or special characters. Please enter a tax ID with 11 numbers.",
      },
    },
    addressConfirmation: {
      title: 'Confirmation of Your Address',
      text: 'Make sure to check your name and address. If something has changed, you can update it right here.',
      twoFa: {
        title: 'Confirm Address Change',
        mainText:
          "Confirm your address change by entering the security code we've sent via SMS to <1>{{phone}}</1>.",
        button: 'Continue to activation',
      },
      twoFaResult: {
        successTitle: 'Your address has been successfully updated.',
        errorTitle: 'Your security code is invalid',
      },
    },
    residenceAndCitizenshipConfirmation: {
      title: 'Confirmation of Your Data',
      text: 'Make sure to check your residence and nationality. If something has changed, you can update it right here.',
      residenceLabel: 'Country of residence',
      residencePlaceholder: 'Select country',
      citizenshipLabel: 'Nationality',
      citizenshipPlaceholder: 'Select nationality',
      errors: {
        emptyResidence: 'Please select a country of residence.',
        emptyCitizenship: 'Please select a nationality.',
      },
    },
    lastNotesStep: {
      title: 'Last Notes',
      sectionOneHeader: 'Consultation waiver',
      sectionOneText:
        'Sutor Bank executes securities orders for you, yet does not provide advice on investing in financial instruments. Sutor Bank is only obligated to verify the adequacy of financial instruments or investment services.',
      sectionTwoHeader: 'Bank Resolution & Creditor Participation',
      sectionTwoText:
        'Information on creditor liability for shareholders or creditors of a bank can be found here.',
      sectionThreeHeader: 'Church tax check',
      sectionThreeText:
        "Your church tax details can be requested from the BZST (Germany's Federal Central Tax Office). If applicable, you may be subject to church tax on investment income. It is possible to lodge an appeal.",
    },
    pendingStep: {
      title: 'Activation already requested',
      text: {
        a: 'You have already successfully requested activation.\n\nIt can take ',
        b: 'up to about 15 minutes ',
        c: "until the activation is confirmed and you can start trading stocks & ETFs. We'll send you an e-mail as soon as your account has been activated.",
      },
      support: "Open questions? We'd be happy to help - feel free to contact us any time via ",
    },
    finishedStep: {
      title: 'Unlocking Stocks & ETFs Successfully Requested',
      text: "Thank you for providing us with your information. It'll take <bolded>about 15 minutes</bolded> until you're able to trade stocks & ETFs. We'll send you an e-mail as soon as your account has been unlocked to do so.",
      specialInfo: 'Note: Activation can take up to 48 hours in exceptional cases.',
    },
    title: 'Have you traded this asset class before?',
    securities: 'Stocks and ETFs',
    indexes: 'Shares of common indexes (EuroStoxx, DAX or similar) or comparable ETFs',
    leveraged: 'Leveraged shares / ETFs (apart from standard indexes such as EuroStoxx, DAX or similar)',
    error: 'Choose an option first.',
    successTitle: 'Request submitted successfully.',
    errors: {
      title: 'Something went wrong ...',
      invalidTIN: 'Invalid German Tax ID number',
      nonGermanResident: 'You are not a German resident',
      noGermanPhoneNumber: 'You need to have a valid german phone number',
    },
  },
  kyc: {
    exit: 'Exit',
    warningClosed: `Unfortunately, a verification of your BISON account is currently not possible.\n\nThe verification can be done Monday to Sunday from {{from}} - {{to}}.`,
    closesSoon: 'Warning: The video verification service closes in {{time}}.',
    warningRestart: `Please note: if you have already successfully went through the verification, a second start of the verification will reset your status. If you didn't have a successful verification, you can start again.`,
    warningRestartConfirm: 'I understand',
    verificationDocuments: {
      id: 'ID card',
      passport: 'Passport & Invoice',
    },
    introStep: {
      a1: 'For your verification at BISON only a few steps are necessary. Please have a valid',
      a2: 'identity document', // This is emphasized
      a3: 'ready.',
      b: 'Please note that you only need to verify if you want to trade for real money.',
      c: '1. Enter personal data\n2. Video verification of your data\n3. Successful verification',
      web_d: 'The video verification is possible via Google Chrome, the BISON App and the IDNOW App.',
      next: 'Next',
    },
    checkboxStep: {
      a: 'For BISON the **protection of your data** is important.\n\nTo start the verification, we need to ask you to read and confirm the following documents.',
      agb: {
        a: 'Yes, I read, saved and acknowledged the ',
        b: 'terms & conditions of EUWAX', // link to www.bisonapp.com/dokumente
        c: ', the',
        d: 'terms & conditions of blocknox', // link to www.bisonapp.com/dokumente
        e: ', the',
        f: 'terms & conditions of Solaris', // link to www.bisonapp.com/dokumente
        g: ' and the',
        h: 'special terms & conditions of Solaris', // link to www.bisonapp.com/dokumente
        i: '.',
      },
      datenschutz: {
        a: 'Yes, I read, saved and acknowledged the ',
        b: 'data protection agreement', // link to www.bisonapp.com/dokumente
        c: '.',
      },
      nichtInUSA: 'I am not taxable in the United States.',
      ownAccount: 'At BISON I act exclusively on my own account and in my own name.',
      newsletter: 'Yes, I would like to subscribe to the BISONews newsletter and keep up to date (optional).',
      nichtInUSAInfo:
        'Are you a US citizen (also dual citizenship), have a residence respectively a permanent United States residence or are you taxable in the United States for some other reason?\n\nPeople who are taxable in the United States cannot buy and sell cryptocurrencies in the BISON App.',
      next: 'Next',
      errors: {
        agb: 'Before you can proceed, you need to confirm the terms & conditions.',
        datenschutz: 'Before you can proceed, you need to confirm the data protections agreement.',
        nichtInUSA: 'If you are taxed in the United States, we cannot open an account for you.',
        ownAccount:
          'If you do not trade exclusively on your own account and in your own name, we cannot open an account for you.',
      },
    },
    cryptoRiskStep: {
      next: 'Next',
      title: 'Risk Warnings',
      description: {
        a: 'Investments in cryptocurrencies are associated with high risks and can lead not only to income but also to considerable losses of capital employed. In the worst case your investment can even lead to a total loss.',
        b: 'The ',
        c: 'basic and risk information ', // Link to webpage
        d: 'contains some general information, but no individual notes. Please note that the offers for trading and custody of cryptocurrencies are only for well-informed and experienced investors who have a high risk tolerance and are financially able to bear losses. A personal consultation does not take place. If necessary you should consult a suitable advisor before the registration.',
      },
      cryptoRisk: 'Yes, I understand. I read, saved and understood the basic and risk information.',
      errors: {
        cryptoRisk:
          'For your protection, you cannot open a BISON account if you do not have sufficient knowledge or experience, are not willing to take high risks, or are not financially able to cope with a total loss of your invested capital.',
      },
    },
    nameAndSurnameStep: {
      firstName: 'First name',
      lastName: 'Last name',
    },
    birthInfoStep: {
      next: 'Next',
      dateOfBirth: 'Date of birth',
      birthCity: 'Birth city',
      birthCountry: 'Country of birth',
      errors: {
        dateOfBirth: {
          missing: 'Please enter your date of birth.',
          under18: 'You must be at least 18 years old to trade at BISON.',
        },
        birthCity: {
          required: 'Please enter your birth city.',
        },
        birthCountry: {
          required: 'Please select your birth country.',
        },
      },
    },
    employmentStatusStep: {
      title: 'Occupational Information',
      infoModal: `### Occupational Information\n\nInformation about your Occupation helps us to better understand the money coming into your account. This enables us to avoid any potential impairment of the use of our service.`,
    },
    nationalityStep: {
      next: 'Next',
      country: 'What country do you live in?',
      nationality: 'Nationality',
      errors: {
        country: {
          required: 'Please select a country',
          notSupported: 'Sorry, but BISON is not yet available in this country.',
        },
        nationality: {
          required: 'Please select your nationality',
          notSupported: 'Sorry, currently we do not accept users with this nationality.',
        },
      },
    },
    taxInfoStep: {
      taxId: 'Tax-ID (optional)',
      taxIdNotOptional: `Tax-ID`,
      taxIdRequired: 'Please insert your Tax-ID.',
      taxIdCountry: 'Tax Residency (optional)',
      taxIdCountryNotOptional: 'Tax Residency',
      taxIdInfo:
        'Your Tax-ID is required by law by the tax authorities as voluntary information. If you are liable to tax in several states, you can add further **Tax-IDs** and **Tax Residences** after a successful verification within your BISON account under "Settings" and "Personal Information".\n\nPersons that are taxable in Germany can find their Tax-ID for example on the wage tax certificate (Lohnsteuerbescheinigung) or on the income tax assessment (Einkommenssteuerbescheid).',
      germanBankAcc: {
        a: 'Yes, I have a ',
        b: 'SEPA-enabled bank account ', // Emphasized
        c: 'in my name.',
        info: 'Deposits to your BISON account are only possible from a SEPA-enabled bank account.\n\nSEPA is the European Payments Area, to which almost all European banks belong.',
      },
      errors: {
        taxId: 'Please provide a valid tax ID (max. 20 characters)',
        taxIdRequired: 'Please insert your Tax-ID',
        taxIdCountryRequired: 'Please select your Tax Residency.',
        germanBankAcc: 'Please confirm, that you have a SEPA-enabled bank account in your name.',
      },
    },
    introBillStep: {
      web_a:
        'Please note that the verification with your passport will take more time and you will also need a current printed invoice.',
      web_next: 'Next',
    },
    kycOptionsStep: {
      web_start: 'Start video identification',
      web_description: `Now we just have to confirm your data via **video verification**.\n\nPlease note that a device with microphone and a camera is required.`,
      web_detailsBold: `Video verification with a different device?`,
      web_details: `Check your emails for more options.`,
      web_helpLink: 'Problem?',
      web_helpText: `<0>If you have completed the verification successfully, please note that the confirmation of your verification may take a couple of business days.</0>
              <1> In exceptional cases we will reach out to you via email in order to request some additional documents. Once your confirmation has been received, we will notify you via email.
                In case you have joined the waiting list, you will be notified via SMS when there is a free agent available for you.</1>`,
    },
    chooseVerificationDocumentStep: {
      idOrPassBill: {
        a: 'Please hold your valid ID ready before you start the video call.',
        b: 'There is only one final step now: Check your hairstyle and go to the video ident call so that we can verify your data.',
      },
      next: 'Next',
      nextAlt: 'Next',
      passportLink: {
        a: 'I do not have a passport.',
        b: 'Verify here with passport.', // Emphasized
      },
      errors: {
        selectVerificationDocument: 'Please select a verification document.',
      },
    },
    addressStep: {
      a: 'Your current address',
    },
    billStep: {
      a1: 'What type of invoice would you like to verify yourself with?\n',
      a2: {
        idOrPassBill: 'ID card or passport ',
        idBillOrPassBill: 'ID card or passport ',
        idBill: 'ID card ',
        passBill: 'passport ',
      },
      a3: 'Please note that your address is ',
      a4: 'visible',
      a5: 'on the invoice and',
      a6: 'must not be older than 6 months',
      a7: '.',
      web_a: 'For your verification at BISON you have to verify yourself with a',
      web_b: 'in combination with a current',
      web_c: 'printed invoice',
      web_d: '.',
      next: 'Next',
      billType: 'Please select',
      billDate: 'Invoice date',
      errors: {
        billType: 'Please select an invoice type',
        billDate: {
          olderThan6M: 'Your invoice must not be older than 6 months.',
          futureDate: 'Greetings, time traveler BISON!',
          required: 'Please enter the invoice date',
        },
      },
      billTypes: {
        bankAccountStatement: 'Bank account statement',
        internetBill: 'Internet bill',
        telephoneBill: 'Telephone bill',
        electricityBill: 'Electricity bill',
        gasBill: 'Gas bill',
        heatingBill: 'Heating bill',
        waterBill: 'Water bill',
      },
    },
    billAddrStep: {
      next: 'Next',
      a: 'Your address on the invoice',
      billStreet: 'Street',
      billStreetNumber: 'No.',
      billPostNumber: 'ZIP Code',
      billCity: 'City',
      billCountry: 'Country',
      addressSwitchModal: {
        title: 'We have corrected your address',
        subtitle: 'Please check if the address we have corrected matches your current address.',
        labelOriginal: 'Original address',
        labelSuggestion: 'Corrected address',
        confirmButton: 'Accept address',
        cancelButton: 'Continue with my specification',
      },
      confirmAddressModal: {
        title: 'Are you sure that your address is correct?',
        subtitle: 'Please check if the information you have provided is correct and change it if necessary.',
        label: 'Address provided',
        confirmButton: 'The address is correct',
        editButton: 'Change address',
      },
      nameChangedModal: {
        title: 'Changing Your Name',
        subtext1:
          'To change your name, we require proof that your name has in fact changed. This can be proven through an official document from the authorities confirming that your name has changed.',
        actionText: 'Please send your document to:',
        supportEmailText: 'support@bisonapp.com',
        subtext2:
          "Don't worry, your progress in activating stocks & ETFs won't get lost. As soon as the change has been made via our support team, you'll receive an e-mail containing a button that leads you directly back to the activation process.",
        subtext3:
          'If you do not have access to this phone number, please contact us via support@bisonapp.com.',
      },
    },
    finalBillStep: {
      a: 'Please keep the following documents ready:',
      c: 'Printed invoice',
      d: 'There is only one final step now:: Check your hairstyle and go to the video ident call so that we can verify your data.',
      next: 'Next',
      idOrPassBill: {
        b: 'Valid passport',
      },
      idBillOrPassBill: {
        b: 'Valid passport or ID card',
      },
      idBill: {
        b: 'Valid ID card',
      },
      passBill: {
        b: 'Valid passport',
      },
    },
    employmentInformationStep: {
      next: 'Next',
      explanationLabel: 'Why is this necessary?',
      explanation:
        'This information helps us understand the flow of money to the account and avoid disruptions to service.',
      employmentStatusLabel: 'What is your current employment status?',
      professionalFieldLabel: 'What is your current professional field?',
      incomeSourceLabel: 'What is the origin of funds you intend to invest on BISON?',
      netIncomeLabel: 'How high is your yearly net income in Euro?',
      netIncomePlaceholder: 'Example: 360,000',
      employmentStatusTypes: {
        employee: 'Employee',
        leadingEmployee: 'Leading employee',
        selfEmployed: 'Self-employed (or business owner)',
        retired: 'Retired',
        notEmployed: 'Not employed',
        student: 'Student',
      },
      professionalFieldTypes: {
        aerospaceAndDefense: 'Aerospace & Defense',
        agricultureAndMining: 'Agriculture & Mining',
        architectureAndEngineering: 'Architecture & Engineering',
        businessServicesAndConsulting: 'Business services & Consulting',
        computerAndInformationTechnol: 'Computer & Information Technology',
        education: 'Education',
        energyAndUtilities: 'Energy & Utilities',
        environmental: 'Environmental',
        financeAndBanking: 'Finance & Banking',
        foodAndBeverages: 'Food & Beverages',
        gamingAndSports: 'Gaming & Sports',
        governmentPublicSector: 'Government / Public sector',
        healthcarePharmaAndBiotech: 'Healthcare Pharma & Biotech',
        humanitarianAndNonProfit: 'Humanitarian & Non Profit',
        legalAndCompliance: 'Legal & Compliance',
        manufacturingAndEngineering: 'Manufacturing & Engineering',
        mediaAndEntertainment: 'Media & Entertainment',
        other: 'Other',
        realEstateAndConstruction: 'Real Estate and Construction',
        retailAndSales: 'Retail & Sales',
        softwareAndInternet: 'Software & Internet',
        transportationAndStorage: 'Transportation & Storage',
        travelAndHospitality: 'Travel & Hospitality',
      },
      incomeSourceTypes: {
        salary: 'Salary (personal income)',
        savings: 'Savings',
        heritage: 'Heritage',
        investments: 'Earnings on previous investments',
        pension: 'Pension',
        loan: 'Loan from financial institution',
      },
    },
    financialInformationStep: {
      netWealthLabel: 'How high is your current net wealth?',
      investmentTargetLabel: 'How much do you plan to invest overall in BISON?',
      cryptoDepositLabel: 'Will you deposit crypto from another source?',
      cryptoDepositSourceLabel: 'Where do they originally come from?',
      netWealthRanges: {
        lessThan20000: 'Less than 20.000€',
        from20000To49999: '20.000€ to 49.999€',
        from50000To99999: '50.000€ to 99.999€',
        from100000To199999: '100.000€ to 199.999€',
        from200000To250000: ' 200.000€ to 250.000€',
        moreThan250000: 'More than 250.000€',
      },
      investmentTargetRange: {
        lessThan5000: 'Less than 5.000€',
        from5000To9999: '5.000€ to 9.999€',
        from10000To19999: '10.000€ to 19.999€',
        from20000To49999: '20.000€ to 49.999€',
        from50000To100000: '50.000€ to 100.000€',
        moreThan100000: 'More than 100.000€',
      },
      cryptoDepositSourcePlaceholder: 'Example: Kraken, Coinbase, Binance',
      cryptoDepositSourceRequired: 'Please enter at least one crypto source',
      yes: 'Yes',
      no: 'No',
    },
    precontractualMailStep: {
      next: 'Next',
      title: 'Before we continue…',
      main: "You'll soon identify yourself for a BISON balance account with Solaris registered in your name; this account will be used exclusively for transactions in FIAT funds, and you'll be trading exclusively on your own behalf.",
      details: {
        intro:
          'I confirm that I am opening a balance account under my own name and agree to the following information:',
        a: 'I am fully legally responsible for all account activity.',
        b: 'I will trade in my own economic interest and not on behalf of, or instructed by, any third party.',
        c: 'I understand that Solaris will only provide the balance account, and will not act as an intermediary or custodian for the crypto exchange.',
      },
      checkboxText: "I confirm that I've read and acknowledged the information above.",
      error: 'Please confirm your data',
    },
    newsletter: {
      description: {
        a: `Cool that you heard about BISON!

Real money trading with BISON is not yet available in your (birth) country or nationality.
    `,
        b: `Show us your interest and subscribe to our BISON Newsletter.`,
        c: `You are already subscribed to our newsletter and will be notified when we are represented in your country.`,
      },
      email: 'Email',
      newsletter: 'Yes, I would like to subscribe to the newsletter.',
      errors: {
        newsletterRequired: 'You must confirm this checkbox to register.',
        problemSubscribing: 'An error occurred when subscribing to the newsletter. Please try again.',
      },
      allClear: 'OK',
      signUp: 'Subscribe',
      successTitle: 'Almost done...',
      success: `We need to confirm your email address. To complete the registration process, please click on the link in the email we just sent you.`,
    },
    success: {
      title: 'Reviewing your activation for crypto trading',
      infoText: {
        a: 'Looks good {{firstName}}, if you have just completed the videoident successfully, please note that the confirmation of your verification may take a couple of business days.',
        b: 'In exceptional cases we will reach out to you via mail in order to request some additional documents. Once your confirmation has been received, we will notify you via email.',
        c: 'In case you have joined the waiting list, you will be notified via SMS when there is a free agent.',
        text: `You have successfully completed the activation process for crypto trading. We are currently checking your data.<br/><br/> We will <strong>notify you by email</strong> when your verification has been confirmed and you can start crypto trading.<br/><br/> Note: In exceptional cases, it may take a few business days or we may contact you by email to request additional documentation.`,
      },
      invalidInfoText: 'Unfortunately, the verification did not work.',
      successBanner: {
        title: 'Verification successfully confirmed',
        subTitle: 'You can trade with real money',
      },
      status: 'Status',
      refresh: 'Refresh',
      refreshed: 'Refreshed',
      tryAgain: 'Try again',
    },
    forms: {
      sending: 'Sending...',
      errors: {
        title: 'Submission failed',
        alreadyConfirmed: 'You are already successfully verified. Please wait a moment.',
        invalidTaxId:
          'Please enter a valid tax ID. German tax IDs consist of a maximum of 11 numbers and never start with a 0.',
      },
    },
    idNow: {
      askForPermissionsTitle: 'Can we access your microphone and your camera?',
      askForPermissionsMessage: 'We need your permission to complete the verification.',
      denyPermissions: 'No',
      acceptPermissions: 'OK',
      openSettings: 'Open settings',
      success: 'Video ID completed',
      successMessage: 'We will notify you as soon as the verification is confirmed.',
      errors: {
        title: 'Verification failed',
        E_NO_PERMISSION: 'Please accept the necessary permissions to complete the verification',
        // RNIdNow Error Codes
        E_INIT_ERROR: 'An error occurred during initialization. Please try again.',
        E_PARAMS_ERROR: 'The identification failed because the required authorizations were missing.',
        E_IDENT_START_ERROR: 'The identification could not be started. Please try again.',
        E_IDENT_CANCELED: 'Process was aborted',
        E_IDENT_FAILED: 'Something went wrong with the identification. Please try again.',
        E_IDENT_GENERIC_FAIL: 'Something went wrong with the identification. Please try again.',
      },
    },
    afterKycPhoneSubmission: {
      title: 'Enter your mobile phone number',
      text: "To complete your verification, you'll need to enter your cell phone number. Enter it in the next step.",
      buttonText: 'Enter now',
    },
    renewDocs: {
      redirect: {
        title: 'Your identification document has expired!',
        mainText: `
Please **open the BISON app** on your smartphone and start the activation process from there. In the app, you will be asked to provide a valid form of identification. 

You can activate your account to trade stocks & ETFs immediately afterward.`,
      },
      intro: {
        title: 'Your identification document has expired!',
        mainText: `In the next few steps, you'll be asked to provide a **valid identification document** in order to verify your identity again.\n\nAfterward, you'll be able to enable your account to trade stocks and ETFs.`,
        verifyNewDocuments: 'Submit new document',
      },
      chooseIdentificationDocument: {
        title: 'What would you like to use to verify your identity again?',
        text: `Choose whichever one you have at hand. You'll need your **valid identification** document in the next step.`,
        info: `## Identification verification document\n\nIn order to ensure that you are actually you, we require that you provide us with a valid identification document. This way, we are able to verify that no one is trying to open a BISON account under a false name, using someone else's personal information.\n\nKeep in mind that you will need your **valid identification document** in the next step to be verified with our partner IDnow. Make sure you have it ready.\n\nTo complete the verification process with your **passport**, you will also need to provide a **recent bill**. This bill should be printed, no older than 6 months, and your registered address should be visible on it.`,
        idCard: 'ID card',
        passport: 'Passport',
        continue: 'Continue to IDnow',
      },
      billStep: {
        modal: {
          title: 'Instructions on verification with a passport',
          text: `To complete the verification process with your passport, you also need to provide a **recent bill**.\n\nNote: This bill should be printed out, no older than **6 months**, and your registered address should be visible on it.`,
        },
        title: 'Verification with a passport',
        text: `To complete the verification process with your passport, you also need to provide a **recent bill**.\n\nNote: This bill should be printed out, no older than 6 months, and your registered address should be visible on it. `,
      },
      addressStep: {
        title: '',
      },
      videoCallStep: {
        title: 'Last step: ID-Verification',
        textPassportOnly: `To complete the verification process with your passport, you also need to provide a **recent bill**.\n\nNote: This bill should be printed out, no older than 6 months, and your registered address should be visible on it. `,
        textIdOnly: `Please hold your valid ID ready before you start the ID verification.`,
        button: 'Continue to video call',
      },
      pending: {
        title: `We're reviewing your new identification document`,
        text: {
          a: `Thank you for submitting your new identification document. We'll notify you when it's been verified. Afterward, you can activate your BISON account for stocks & ETFs.`,
          b: `This review usually takes 15 minutes. It may, in exceptional cases, take up to 2 days.`,
        },
        modal: {
          title: `We're in the process of reviewing your new identification document`,
          text: `It usually takes about 15 minutes to complete the review. In exceptional cases, it may take up to 2 days. We'll **notify you via e-mail** as soon as activation is complete.\n\nOnce your identification document has been verified, you can continue activating your BISON account for stocks & ETFs.`,
        },
      },
      fail: {
        title: 'New identification document could not be submitted',
        text: `Please get in touch with our customer service team by emailing [customer support form]({{supportLink}}).`,
        modal: {
          title: `Your new identification document could not be submitted`,
          text: `Please contact our customer service via [customer support form]({{supportLink}}).\n\nOnce your identification document has been successfully submitted, you can continue unlocking your BISON account for stocks & ETFs.`,
        },
      },
      success: {
        title: 'Your new ID document was successfully submitted',
        text: `There are just a few steps left to enable your BISON account for stocks & ETFs.`,
        continueToActivation: 'Continue to activation',
      },
      exitIntentModal: {
        title: `Don't want to submit a new identification document?`,
        text: `Note: Without verifying your identity using a valid identification document, you will not be able to activate trading stocks & ETFs.`,
        submitNewDocument: `Submit new document`,
        later: `Later`,
      },
    },
    securities: {
      button: 'Continue',
      intro: {
        title: 'Trade Stocks & ETFs Now, Too.',
        mainText: 'Unlock your account for stocks & ETFs and start trading securities today.',
        ok: 'Enable now',
        cancel: 'Later',
      },
      failure: {
        title: 'Sorry {{firstName}},  something went wrong',
        mainText: {
          a: 'Unfortunately, the verification process could not be completed.\n\nFeel free to contact our support via the button below',
          b: ". They'll assist you with your verification and you'll be able to start trading securities as soon as possible!",
        },
        button: 'Contact support now',
        mail: {
          subject: 'Issues during verification',
          body: "Hi BISON Team,\nI ran into a few issues during my verification process, which is why my verification failed to successfully finish. I'd appreciate your support in this so I can start trading as soon as possible.",
        },
      },
      eIdData: {
        title: 'Verification of ID details',
        info: `## Where on my ID card can I find the required information?

The ID number (and document number) consists of 9 digits. You can find it in the top right-hand corner on the front side.

You'll find the date of issue and the issuing authority on the back in the top left-hand corner under Date and Authority.
        `,
        note: 'Note: We require the complete name of the issuing authority. This may be lengthy and run over 2 lines.',
        legitimationDocumentNumber: {
          label: 'ID number',
          placeholder: 'E.g T220001293',
          required:
            "Please enter your ID number. You'll find this in the top right-hand corner of the front of your ID card.",
          min: "The ID number you've entered is too short. Please enter an ID number with 9 characters.",
          max: "The ID number you've entered is too long. Please enter an ID number with 9 characters.",
        },
        legitimationIssuedAt: {
          label: 'Date (date of issue)',
          required: 'Please enter a date.',
          min: "The date you've entered is too far in the past. Please double-check your information and adjust the date.",
        },
        legitimationIssuer: {
          label: 'Issuing authority',
          placeholder: 'E.g. City of Cologne',
          required:
            "Please enter the issuing authority. You'll find this in the top left-hand corner of the back of your ID card.",
          max: "The Issuing authority you've entered is too long. Please enter an Issuing authority with 100 characters.",
        },
      },
      taxesSolelyGermany: {
        title: 'Are you liable to pay taxes solely in Germany?',
        ok: 'Yes, I pay taxes solely in Germany',
        cancel: 'No, I have other tax residencies too',
        info: `## German Tax Liability

Right now, our partner bank only offers stock trading for individuals who are only subject to tax in Germany.

We are, however, working diligently to make this feature available to all of our other users as well.
        `,
      },
      germanyBasedTax: {
        title: 'Trading Stocks & ETFs Cannot Be Enabled',
        mainText: `Unfortunately, we're unable to allow individuals who are not subject to tax in Germany to trade stocks & ETFs at this time. If you'd like, we can notify you via email as soon as this becomes possible.`,
        ok: 'Notify me via email',
        cancel: 'No thanks',
      },
      deadEnd: {
        residenceNotGerman: `We're unfortunately not able to offer stock & ETF trading to individuals who do not reside in Germany.`,
        nationalityNotGerman: `We're unfortunately not able to permit individuals without German citizenship to trade stocks & ETFs.`,
        phoneNotGerman:
          "We're unfortunately not able to permit individuals without a German cell phone number to trade stocks & ETFs.",
        general: "If you'd like, we can notify you via email as soon as this becomes possible.",
      },
      confirmCellPhone: {
        title: 'Confirmation of Your Cell Phone Number',
        mainText: 'Please check and confirm your mobile number.',
        countryCodeLabel: 'Country code',
        cellPhone: 'Cell phone number',
        cellPhoneHint: 'Please enter a cell phone number.',
        unlinkText:
          "If your cell phone number has changed, you will need to unlink your old number and add the new one. To do so, you'll need access to both cell phone numbers.",
        supportText: "Don't have access to your old cell phone number? Contact us at",
        changeCellPhone: 'Change cell phone number',
        errors: {
          countryCodeEmpty: 'Please select a country code.',
        },
        twofaModal: {
          title: '2-Factor Authentication',
          text: "Confirm your mobile phone number by entering the security code we've sent via SMS to +49 176 ****34.",
          label: 'Security code',
          emptyError: 'Please enter a security code',
          legal:
            'If you do not have access to this phone number, please contact us via support@bisonapp.com.',
          resendSms: "Haven't received an SMS? Send SMS again",
          action: 'Continue to activation',
        },
        twofaResult: {
          success: '2-factor authentication successful',
          fail: 'Your security code is invalid',
        },
      },
      taxId: {
        title: `What's your tax ID?`,
        label: 'Tax Identification Number',
        placeholder: '99 999 999 999',
      },
      personalInformation: {
        title: 'Personal Information',
        mainText: `To enable trading stocks and the like, it's necessary to get to know you a bit better.`,
        address: {
          label: 'Address',
          mr: 'Mr.',
          ms: 'Ms.',
        },
        maritalStatus: {
          label: 'Marital status',
          value0: 'Select from the following',
          value1: 'Single',
          value2: 'Married',
          value3: 'Divorced',
          value4: 'Widowed',
        },
      },
      occupation: {
        title: 'Occupation',
        mainText: 'Your professional background allows us to make suggestions tailored to you.',
        typeOfEmployment: {
          label: 'Type of employment',
          value0: 'Select from the following',
          value1: 'Employee',
          value2: 'Worker',
          value3: 'Civil servant',
          value4: 'Soldier / BFD',
          value5: 'Manager / Board of Directors',
          value6: 'Artisan / Handyman',
          value7: 'Homemaker',
          value8: 'Person of independent means',
          value9: 'Pensioner / Retiree',
          value10: 'Student / Apprentice',
          value11: 'Self-employed / Freelancer',
          value12: 'No employment',
        },
        industrySector: {
          label: 'Industry / Sector',
          value0: 'Select from the following',
          value1: 'Construction',
          value2: 'Energy / power supply',
          value3: 'Education / Teaching',
          value4: 'Finance / Insurance',
          value5: 'Hospitality',
          value6: 'Healthcare / Social services',
          value7: 'Retail / Maintenance',
          value8: 'Real estate',
          value9: 'Information / Communication',
          value10: 'Art / Entertainment',
          value11: 'Agriculture / Forestry',
          value12: 'Public service',
          value13: 'Private households',
          value14: 'Other services',
          value15: 'Manufacturing',
          value16: 'Transport and storage',
          value17: 'Water / waste removal',
        },
      },
      mediumRisk: {
        title: {
          a: 'Have you ever traded ',
          b: 'low to medium-risk',
          c: 'asset classes?',
        },
        mainText: `We're interested in learning more about your experience with securities – after all, we're already familiar with your crypto background.`,
        secondText: 'E.g. bond funds & bond ETFs or federal, state, and local government securities...',
        ok: 'Yes',
        cancel: 'No',
        info: `## Asset Classes with Low to Medium-Risk

#### Federal, state, & local government securities in €

Government securities, i. e. public bonds or state bonds, are used to finance public budgets and are administered by the government.

#### Public bonds (EU) in €

These are bonds issued by an EU state or its local authorities (e.g. federal, state, and local governments) that have EUR as their nominal currency (transaction currency).

#### Public bonds (outside the EU) in €

These are bonds issued by a non-EU state or its local authorities (e.g. federal, state, and local governments) that have EUR as their nominal currency (transaction currency).

#### Market money funds & ETFs in €

Money market funds invest in money market positions with a maximum maturity of 12 months. These funds are characterized by high liquidity and good tradability. Examples of this type include government bonds, certificates of deposit issued by banks, and promissory note loans.

#### Near-money-market fund / ETFs

Near-money-market funds / ETFs invest paid-in capital in fixed-income securities with short residual maturities, floating-rate bonds, and time deposits. They aim to generate a steady return that is largely unaffected by interest rate fluctuations.

#### Bond funds & ETFs without currency risk

Bond funds and ETFs without currency risk invest in bonds that have EUR as their nominal currency (transaction currency).
        `,
      },
      highRisk: {
        title: {
          a: 'Have you ever traded ',
          b: '(very) high-risk',
          c: 'asset classes?',
        },
        mainText: `We're interested in learning more about your experience with securities – after all, we're already familiar with your crypto background. `,
        secondText:
          'E.g. stocks, equity funds, equity ETFs of popular indices (EuroStoxx, DAX, or the like), or index certificates...',
        ok: 'Yes',
        cancel: 'No',
        info: `## Asset Classes with (Very) High Risk

#### Stocks & bonds of companies listed in the DAX & Euro Stoxx 50

Stocks (equity) and bonds (debt) in this risk class are used to finance companies listed in the DAX and/or the EURO STOXX 50.

#### Index certificates

Index certificates allow investors to participate one-to-one in the performance of a stock market index – without buying the underlying shares. Each index certificate has a reference index ratio that defines the value of the certificate in relation to the index level.

#### Public bonds in foreign currency

These are bonds issued by a state or its local authorities (e.g. federal, state, and local governments) whose nominal currency (transaction currency) is not the EUR.

#### Bond funds & ETFs with currency risk

Bond funds and ETFs with currency risk invest in bonds whose nominal currency (transaction currency) is not the EUR.

#### Mixed funds / ETFs

Mixed funds / ETFs can invest in various asset classes at the same – e.g. equities, bonds, real estate – by means of their investment strategy.

#### Structured bonds

These are interest-bearing bonds with individual additional terms (such as maturity, repayment, and interest).

#### Stock funds & ETFs

Stock funds and ETFs invest paid-in capital exclusively in stocks; other asset classes are not possible.

#### Investment certificates

Investment certificates allow investors to participate in the performance of an underlying asset without having to actually purchase it, depending on the structure of the respective investment certificate.

#### Subscription rights

Subscription rights entitle the holder, with consideration of the subscription ratio, to acquire newly issued shares in the context of corporate action.
        `,
      },
      highestRisk: {
        title: {
          a: 'Have you ever traded ',
          b: 'highest-risk',
          c: 'asset classes?',
        },
        mainText: `We're interested in learning more about your experience with securities – after all, we're already familiar with your crypto background.`,
        secondText:
          'E.g. leveraged certificates, other financial futures, or stocks & bonds from other indices...',
        ok: 'Yes',
        cancel: 'No',
        info: `## Asset Classes with the Highest Risk

#### Stocks & bonds from other indices

Stocks (equity) and bonds (debt) in this risk class are used to finance companies that are not listed in the DAX or the EURO STOXX 50.

#### Leverage certificates

Leverage certificates are derivatives (debt instruments) that allow investors to realize a disproportionate performance compared to the underlying asset.

#### Other financial futures

Other financial futures include transactions between two contracting parties for conditional (e.g. option) or unconditional (e.g. future) settlement.
        `,
      },
      reimbursement: {
        title: 'Reimbursement',
        mainText: 'Confirm that you are in agreement with the following statement:',
        checkbox:
          'I acknowledge that I am not entitled to any reimbursements. Sutor Bank receives reimbursements from third parties (e.g. stock exchanges, trading partners, fund companies) and will retain these if it is permitted to accept them under the Securities Trading Act (esp. §70 WpHG).',
        info: `## Reimbursement

What are reimbursements? Reimbursements are similar to commissions. For example, banks receive commissions for products sold by product providers such as investment companies.
        `,
      },
      notes: {
        title: 'Final notes',
        subtitle1: 'Consultation waiver',
        text1:
          'Sutor Bank executes securities orders for you, yet does not provide advice on investing in financial instruments. Sutor Bank is only obligated to verify the adequacy of financial instruments or investment services.',
        subtitle2: 'Bank resolution & creditor participation',
        text2:
          'Information on creditor liability for shareholders or creditors of a bank can be found <1>here</1>.',
        subtitle3: 'Church tax check',
        text3: `Your church tax details can be requested from the BZST (Germany's Federal Central Tax Office). If applicable, you may be subject to church tax on investment income. It is possible to lodge an appeal.`,
      },
      success: {
        title: 'Unlocking Already Requested',
        mainText:
          "You have already successfully requested to unlock your account. It can take <strong>up to about 15 minutes</strong> until the process is confirmed and you can start trading stocks & ETFs. We'll send you an e-mail as soon as your account is enabled to do so.",
        subtext: '<strong>Note:</strong> In exceptional cases, activation can take up to 48 hours.',
        button: 'Close',
      },
    },
    demoStocksEtfModal: {
      title: {
        etf: "You'd like to trade ETFs?",
        stock: "You'd like to trade stocks?",
        'long/short': "You'd like to trade long/short?",
        commodity: "You'd like to trade commodities?",
      },
      text: 'Before you can start trading with BISON, you need to answer a few questions and verify your identity providing proof of your identity.\n\n After that, you can additionally activate your account to trade stocks & ETFs in just a matter of minutes.',
      blackButton: 'Verify now',
      whiteButton: 'Later',
    },
  },
  deposit: {
    select: {
      mainText: 'Deposit EUR funds to your BISON account using the method of your choice:',
      cardOption: {
        title: 'Instant deposit',
        description: `
- Your money is available immediately 
- Once you've set up a method, it can always be used in the future.
        `,
        subtitle: 'How much would you like to deposit?',
        inputPlaceholder: 'E.g. 1,000.00',
        fee: 'Plus a fee of {{fee}}%.',
        emptyAmount: 'Enter an amount.',
        amountTooLow: 'The amount is too low. Please enter an amount of at least {{minAmount}}.',
        amountTooHigh: 'The amount entered is too high. Enter an amount below {{maxAmount}}.',
        subtitleMethod: 'What would you like to use to make your deposit?',
        mainTextMethod: 'Deposit EUR funds to your BISON account using the method of your choice:',
        chooseMethod: 'Choose deposit method',
        chooseMethodTitle: 'Choose method',
        chooseMethodText: 'Deposit EUR funds to your BISON account using the method of your choice:',
        methodTypes: {
          creditCard: 'Credit card',
          applePay: 'Apple Pay',
          googlePay: 'Google Pay',
          visa: 'Credit card',
          mastercard: 'Credit card',
        },
        setUpCardTitle: 'Set up credit card',
        storeCard: 'Store card',
        successTitle: "You've successfully deposited {{amount}} EUR",
        successText:
          "We've just received your deposit via {{depositMethod}}. Now you can start investing the money you deposited.",
        successAction: 'To Portfolio',
        failTitle: "Your deposit didn't go through",
        failText:
          "Your deposit unfortunately couldn't be processed. This might be due to a variety of reasons. Try to use a different deposit method or check your limits.",
        failAction: 'Try again',
        cardHolder: 'First and last name of cardholder',
        cardNumber: 'Card number',
        cardDate: 'Expiration date',
        cardCvv: 'Card Verification Value',
        editCard: 'Change',
        last4: 'ends on {{last4}}',
        lastUsedMethod: 'Last used',
        otherMethods: 'Add further payment methods',
        confirmationHeader: 'DEPOSIT (SUBJECT TO CHARGE)',
        confirmationTitle: 'EUR deposit',
        confirmationAmount: 'Deposit amount',
        confirmationFee: 'Fee',
        confirmationMethod: 'Method',
        confirmationAction: 'Pay now to deposit',
        confirmationSummary: 'Summary',
        paymentMethodError: 'Failed to create payment method',
        invalidBrandError:
          "We don't accept credit cards from this provider. Please use a Mastercard or VISA.",
        errors: {
          noDepositOptionSelected: 'Please select one of the two options to continue.',
          emptyAmount: 'Enter an amount.',
          amountTooLow: 'The deposit amount is too low. Please enter an amount of at least {{min}}.',
          amountTooHigh:
            'The maximum amount allowed for instant deposits is {{limit}}. Please enter an amount that does not exceed {{limit}}.',
          totalLimitExceeded:
            'The maximum deposit amount per month is {{limit}}. So far this month, you have deposited {{amount}}. Please enter an amount that does not exceed your remaining limit of {{value}}.',
          topUpLimitExceeded:
            'The maximum amount allowed for instant deposits is {{limit}} per month. So far this month, you have deposited {{amount}} using instant deposit. Please enter an amount that does not exceed your remaining limit of {{value}}.',
          paymentMethodsLimit:
            'Maximum number of credit cards reached for this period. You can add the next credit card on {{date}}.',
          duplicateCreditCard: 'You have already saved this credit card.',
        },
        popup: {
          title: 'Depositing with a credit card, Apple, & Google Pay is now possible!',
          body: `Choose instant deposit in the BISON app **to fund your account 24/7**.

Your money will be **instantly available** and you'll be able to trade right away.`,
          action: 'Go to instant deposit',
          close: 'Not now',
        },
        twoFe: {
          title: 'Add a new card',
          text: 'You would like to register a new credit card. To authenticate you, we have sent you a security code by SMS to <1>{{phone}}</1>. Enter the security code below.',
          button: 'Continue',
        },
        topUpsDisabledModal: {
          title: 'Instant Deposit soon available',
          text: 'For Instant Deposit via credit card, Google pay & Apple Pay the following criteria must be met:',
          condition1: 'Your BISON verification was completed at least 3 months ago.',
          condition2: 'You’ve made at least one deposit via bank transfer.',
          button: 'Got it',
        },
        privacyPopup: {
          title: 'Privacy Notice',
          text: {
            0: 'To prevent the misuse of your credit card, we store the information listed below. This will also be sent to you via email each time you use your registered credit card, and displayed in your transaction history:',
            1: `
- Details of your completed transaction;        
- The last 4 digits of the card used;
- The credit card issuer (e.g. VISA).        
            `,
            2: "Should you notice any unauthorized charges, it's crucial to notify both us and your card issuer without delay. <1>More info on data protection</1>.",
          },
          action: 'Got it',
        },
      },
    },
    warningText: `To ensure a successful deposit to your BISON account, the following requirements must be met:

1. The deposit must be made from a SEPA-enabled bank account.
2. The account holder of the deposit account may run in your name.
3. You can transfer at most 20,000 € with one single deposit. You can do multiple deposits in a single day, but no more than 200,000 € in total per month.\nWithdrawals are not limited.
4. SEPA Instant Payments are not accepted.

Please note that a bank transfer usually takes one business day.`,
    warningOk: 'OK',
    next: 'Next',
    description: 'Deposit via bank transfer',
    subtitle: 'Please transfer the amount of your choice to the following account:',
    importantInfo: {
      title: 'Notes on deposits',
      /* a: 'Deposits have to be made from a SEPA-compliant bank account.',
      b: 'The deposit account must be in your full name.',
      c: 'A fee of €1.00 is charged for every instant payment. This will be deducted directly from your BISON account.', */
      // TODO: Remove when fees are back.
      a: 'Deposits are required to be made from a SEPA-compliant bank account.',
      b: 'The depositing account must be under your full name.',
      c: 'Note: Additional fees may be charged by your bank.',
      button: 'I understand',
    },
    // descriptionInfo:
    //   'To ensure a successful deposit to your BISON account, the following requirements must be met:\n1. The deposit must be made from a German bank account (IBAN starting with DE).\n2. The account holder of the deposit account may only run in your name. Payments from joint accounts will not be accepted.\nPlease note that a bank transfer usually takes one business day.',
    transferData: {
      receiver: 'Recipient',
      receiverInfoTitle: 'Why am I sending my money to Solaris SE?',
      receiverInfo:
        'As an established German banking service provider and our partner, Solaris SE handles all EUR assets with BISON. This is why you send your bank transfer to Solaris SE.',
      iban: 'IBAN',
      bic: 'BIC',
      usage: 'Purpose',
    },
    fees: {
      standard: 'Fee Standard transfer',
      instant: 'Fee Instant transfer',
      maxAmount: 'Maximum amount per transfer',
      monthlyMax: 'Monthly maximum',
      none: 'None',
    },
    legal: `Deposits must be made from a SEPA-enabled bank account.<br/>
     The deposit account must be in your full name.<br/>
     Note: Additional fees might be charged by your bank.`,
    note: `<0>Note:</0> 
<1>Your deposit will be credited to your BISON account via a personal IBAN that is assigned to you.</1>`,
  },
  withdraw: {
    next: 'OK',
    confirmNext: 'Pay out now',
    successNext: 'OK',
    withdrawAmount: 'Withdraw amount',
    confirmWithdraw: 'Confirm withdraw',
    available: 'Available amount: ',
    bankAccount: 'Bank account',
    bankAccountInfo:
      'You can change your withdrawal account in the BISON App in the settings under "Personal Information". Please note that you can only change your withdrawal account to an account from which you have already successfully deposited.',
    bankAccountNotAvailable:
      'To withdraw you first need to connect your personal account by depositing to your BISON account.',
    confirm: 'Please confirm your withdrawal again',
    confirmationTitle: 'Confirmation',
    errors: {
      generic: 'There was a problem submitting your withdraw request',
      invalidTokenOrTooLong: 'Your withdrawal took too long, please start again',
    },
    success:
      "All right, we've just initiated your payout. A payout might take several business days in some cases.",
    form: {
      fieldErrors: {
        amountRequired: 'Amount is required',
        amountOverAvailable: 'Specified amount is larger than your available amount',
      },
    },
  },
  frontPage: {
    mainText: 'Buying and selling Bitcoin and Co.',
    mottoText: 'Simple. Smart. Reliable.',
    noAccount: "Don't have an account?",
    haveAccount: 'Already have a BISON account?',
  },
  cryptoWithdraw: {
    exit: {
      headline: "You'd like to cancel your withdrawal?",
      body: 'Note: Your progress will not be saved and your previous entries will be lost.',
      proceed: 'Continue withdrawal',
      cancel: 'Cancel withdrawal',
    },
    info: {
      title: 'Information on cryptocurrency withdrawals',
      a: "- You'll need a wallet address and, depending on the currency, a memo or destination tag.",
      b: `- You must be the owner of this wallet address.`,
      c: `- You'll be prompted to confirm by entering your password and an SMS code.`,
    },
    checkboxText: 'Do not show this message again.',
    amountHeadline: 'What would you like to withdraw?',
    specificCryptoInfo: {
      pol: {
        title: {
          a: 'Important:',
          b: 'We only transfer POL via the Polygon network.',
        },
        mainText: {
          a: 'Be sure that your destination wallet is compatible with the Polygon network and that you have access to the blockchain address on the Polygon network.',
          b: "If you don't, you may not have access to your transferred POL.",
          c: 'If you feel unclear, read up on the different networks.',
        },
        checkbox:
          "I've read the warning and understand that my destination address must be compatible with the Polygon network.",
      },
    },
    next: 'Understood',
    next2: {
      forward: 'Continue',
      back: 'Back',
    },
    next3: 'Send withdrawal request',
    next4: 'Next',
    next5: 'Pay out now',
    returnToApp: 'To Portfolio',
    web_available: 'Available cryptocurrencies: ',
    confirmNext: 'Pay out now',
    successNext: 'OK',
    confirm: 'Pleas confirm your requested withdraw again',
    confirmationTitle: 'Confirmation',
    resendCode: 'Resend security code',
    resentCode: 'We have sent you a new security code.',
    resendMail: {
      text: "Didn't receive an email?",
      action: 'Resend email',
    },
    resentMail: 'We have sent you a new security mail.',
    resendingCode: 'Resending security code',
    resendCodeSuccess: 'We have sent you a new security code.',
    twofaInvalid: 'Incorrect security code. Please try again.',
    twofaExpired: 'Your security code has expired. Please try again.',
    errors: {
      generic: 'There was a problem submitting your withdraw request',
      invalidTokenOrTooLong: 'Wrong or expired confirmation code',
      wrongPassword: 'Wrong password',
      walletAddressInvalid: 'The crypto wallet address could not be validated',
      resendCodeError: 'Sorry, we could not send your security code at this time. Please try again later.',
      bchLegacy:
        'The crypto wallet address could not be validated. Only cashaddr-address are accepted. Click here for further information.',
      resendMail: 'Sorry, we could not send your security mail at this time. Please try again later.',
    },
    success: {
      header: 'Please confirm your\nwithdrawal request by e-mail',
      body: "You'll now receive an email requesting you to confirm your withdrawal. Once you've done this, your request will be processed.",
    },
    failure: {
      header: 'Your withdrawal could not be initiated',
      body: `Unfortunately, something went wrong and your withdrawal could not be processed.\nPlease start the withdrawal process again.
      
Open questions? We'd be happy to help – feel free to contact us any time via support@bisonapp.com.`,
      action: 'Try again',
    },
    amountLabel: 'Amount',
    amountAvailable: 'Available: ',
    amountPlaceholder: 'max. {{ amount }}',
    web_walletTitle: 'Where should your withdrawal go?',
    walletEthWithdraw: 'Ethereum Withdrawal',
    walletEthLegacy:
      'You are withdrawing to a legacy address that we cannot verify. Are you sure you want to do this? (This does not mean that the address is invalid, but please double check)',
    ethWarning: {
      title: 'Important',
      a: 'BISON only supports withdrawals to external owned accounts. You can find more information in our ',
      b: 'FAQs',
      c: 'under withdraw & deposit of cryptocurrencies.',
      faqLink: 'https://bisonapp.com/en/questions',
    },
    walletWarning:
      '**Important:**\n\nWith your click on "Send withdrawal request" you confirm, that you are the beneficial owner of the receiving wallet.',
    codeDescription: {
      a: "As an additional security measure, we've sent a security code to you via SMS to ",
      b: `. `,
      c: `Enter the security code below to confirm your withdrawal.`,
      title: 'Confirmation of your {{ coinName }}-withdrawal',
      web_text: `As an additional security measure, we've sent a security code to you via SMS to <1>{{phone}}</1>. Enter the security code below to confirm your withdrawal.`,
    },
    noPhoneNumberAccessInfo: {
      a: `If you don't have access to your number, you can change it `,
      b: `here.`,
      c: ``,
    },
    codeLabel: 'Security code',
    passwordDescription: `Before we can begin the withdrawal process, you'll need to enter your BISON password.`,
    form: {
      fieldErrors: {
        amountRequired: 'Amount is required',
        amountOverAvailable: 'Specified amount is larger than your available amount',
        minimumAmountRequired: 'The minimum withdraw amount is {{amount}} {{cryptoName}}',
        walletTooShort: 'Please enter a valid wallet address',
        walletTooLong: 'Please enter a valid wallet address',
        walletRequired: 'A wallet address is required',
        incorrectCode: 'Incorrect code format',
        codeRequired: 'A confirmation code is required to continue',
        coinSpecificWalletErrors: {
          xlm: 'The wallet address must begin with "G".',
          xtz: 'The wallet address must begin with "tz1", "tz2" or "tz3".',
        },
      },
    },
    walletLabel: 'Withdrawal-wallet address',
    walletPlaceholder: {
      default: 'E.g. 0x3k5j323532kqjcr3l',
      xlm: 'E.g. Gx3k5j323532kqjcr3l',
      xtz: 'E.g. tz1x3k5j323532kqjcr3l',
    },
    destinationTagLabel: {
      xrp: 'Destination tag',
      xlm: 'Memo tag',
    },
    destinationTagPlaceholder: 'E.g. 012345678',
    walletInfo: {
      default: {
        headline: 'Information on wallet addresses',
        body: 'If an incorrect wallet address is entered, the withdrawal cannot be reversed or corrected. Withdrawals to other wallet addresses, which are used by other individuals for cryptocurrency transactions, are not permitted.',
      },
      xlm: {
        headline: 'Information on wallet addresses',
        body: `If an incorrect wallet address is entered, the withdrawal cannot be reversed or corrected. Withdrawals to other wallet addresses, which are used by other individuals for cryptocurrency transactions, are not permitted.
        
There are two types of wallet address for the cryptocurrency Stellar (XLM). It either starts with "M" or "G". We only support wallet addresses that begin with a "G".`,
      },
      xtz: {
        headline: 'Information on wallet addresses',
        body: `If an incorrect wallet address is entered, the withdrawal cannot be reversed or corrected. Withdrawals to other wallet addresses, which are used by other individuals for cryptocurrency transactions, are not permitted.
        
There are two types of wallet address for the cryptocurrency Tezos (XTZ). There are wallet addresses starting with "tz" and "KT1" for smart contracts. We only support wallet addresses starting with "tz1", "tz2" or "tz3".`,
      },
    },
    destinationTagInfo: {
      xrp: {
        headline: 'What is a Destination Tag?',
        body: `The Destination Tag is used to determine which account an XRP transaction should be assigned to. 
        
Please make sure whether a destination tag is required or not before initiating an XRP transaction.
        
- If you have a hardware wallet, specifying a destination tag is most likely not required as there is only one XRP wallet on the device.
       
Each destination tag can consist of numbers, letters and/or special characters and is determined by the service provider who created it. Please check the accuracy of the tag before initiating the transaction as we cannot confirm if it is correct.
       
If a destination tag is required and you have forgotten it or inserted an incorrect tag, it can lead to significant delays in crediting or even irretrievable loss of the transaction.

Note: To activate a new XRP wallet, your first transfer to the new XRP wallet needs to be at least 20 XRP.`,
        note: "Keep in mind: It's <strong>your responsibility</strong> to check if a destination tag is required for your transaction.",
      },
      xlm: {
        headline: 'What is a Memo Tag?',
        body: `The memo tag is used to determine which account an XLM transaction should be assigned to. 

Before initiating an XLM transaction, be sure to check whether or not a memo tag is required.

- If you have a hardware wallet, providing a memo tag is probably not necessary, given that there's only one XLM wallet on the device.

Each memo tag can consist of numbers, letters, and/or special characters and is determined by the service provider who created it. Before initiating the transaction, check the accuracy of the tag; we are not able to confirm whether it is correct or not.

If a memo tag is required and you either forget it or enter an incorrect tag, there may be significant delays in issuing funds or you may even lose your transaction for good.`,
        note: "Keep in mind: It's <strong>your responsibility</strong> to check if a memo tag is required for your transaction.",
      },
    },

    checkboxes: {
      title: 'Important',
      description: 'The following requirements must be met for a successful withdrawal:',
      soleOwner: {
        a: 'I am the ',
        b: 'sole beneficial owner ',
        c: 'of the receiver wallet address.',
        info: {
          headline: 'Who are beneficial owners?',
          body: `Beneficial owners, as defined in section 2 of the German Anti-Money Laundering Act (GwG), are:
          
1. natural persons who ultimately hold or control the contracting party, or
2. natural persons who ultimately initiate a transaction or establish a business relationship.
          `,
        },
        error: '',
      },
      onlyUser: {
        a: 'I confirm that no other ',
        b: 'persons will use the wallet address ',
        c: 'I have entered.',
        d: '',
        error: '',
      },
      cannotBeReversed: {
        a: 'I am aware that the ',
        b: 'withdrawal cannot be reversed ',
        c: 'if you enter a wrong wallet address.',
      },
      moreInfo: {
        a: 'For more information, see our Withdraw & Deposit of Cryptocurrencies section in our ',
        b: 'FAQs',
        c: '.',
      },
    },
  },
  cryptoDeposit: {
    title: 'Crypto deposit',
    importantInfo: {
      title: 'Important information',
      a: 'To ensure a successful deposit to your BISON account, the following requirements must be met:',
      b: '1. You may only deposit from wallets in which you are the beneficial owner.',
      c: '2. Please send only the corresponding cryptocurrency to your corresponding wallet address generated in the BISON App. Sending other cryptocurrencies or digital assets result in an irreversible loss. There will be no credit to your wallet.',
      d: 'Please note, that in some cases your crypto deposit to your BISON account may take ',
      e: '', // emphasized
      f: 'several business days.',
    },
    description: 'Please deposit from your wallet to your BISON account:',
    warningText: `# Important information
1. Please note, that in some cases your crypto deposit to your BISON account may take several business days.
2. You may only deposit from wallets in which you are the **beneficial owner**.`,
    notAvailable: 'Not yet available',
    transferData: {
      crypto: 'Cryptocurrency',
      wallet: 'Your {{selectedCrypto}} wallet',
      destinationTag: 'Your destination tag',
      memoTag: 'Your Memo tag',
      walletInfo: `Your deposit will be credited to your BISON account via your personal deposit address.

You are able to regenerate a new deposit address after each cryptocurrency deposit. You can also still use your previous deposit address.`,
      walletInfoNoRegenerate: `Your deposit will be credited to your BISON account via your personal deposit address.`,
      walletInfoXRP: `Your deposit will be credited to your BISON account via the deposit address and your personal destination tag. You must include your destination tag otherwise the deposit cannot be credited to your account.`,
      walletInfoETH: `Your deposit will be credited to your BISON account via your personal deposit address.

ETH deposits are accepted with up to 18 decimal places; for technical reasons, however, they are rounded to 8 decimal places.`,
    },
    destinationTagWarning:
      'You must include your destination tag. Otherwise the deposit cannot be credited to your account.',
    memoTagWarning:
      'You must include your Memo tag. Otherwise the deposit cannot be credited to your account.',
    newAddressBtn: 'Generate new address',
    newQrCodeBtn: 'Get QR code',
    qrCodeWarning: {
      header: 'Important:',
      text: 'Please check whether the address to which you want to transfer funds from your own blockchain address is entered correctly. Transferring to a different blockchain address or a different blockchain **cannot be reversed** and will result in an **unrecoverable loss**.',
    },
    newAddressGenerateInfo:
      'You can only generate a new deposit address if you have successfully deposited to your current address.',
    latestTransactions: 'Past transactions',
    showLatestTransactions: 'Show past transactions',
    hideLatestTransactions: 'Hide past transactions',
    transactionStatus: {
      pending: 'Deposit pending',
      failed: 'Deposit failed',
      completed: 'Deposit confirmed',
    },
    important: {
      header: 'Important',
      generic: `Please send only {{displayName}} ({{key}}) to this wallet address! Sending other digital assets result in an **irreversible loss**. There will be no credit to your wallet. `,
      eth: `**Please send only Ether (ETH) to this wallet address!**
      
Only Ether can be sent to this wallet address via the Ethereum network.

No tokens from other blockchains such as the Binance smartchain can be received. Sending digital assets from other networks will result in an **irreversible loss**.

No tokens other than ETH can be received on this wallet address either. Sending digital assets other than ETH will result in an irreversible **loss**. This applies in particular to ERC-20 tokens, which can be based on the Ethereum blockchain but are not identical to Ether (ETH) (e.g. UNI, LINK or ERC-20 tokens of the blockchain "Binance Smart Chain").
There will be no credit to your wallet in either case.

**This deposit address only supports ETH!**
`,
      xrp: `Please send XRP to this wallet address only! When depositing XRP, it is essential to include your correct Destination Tag. Sending other digital assets or making a deposit without or with the wrong Destination Tag result in an **irreversible loss**. There will be no credit to your wallet. We only support the so-called "payment" transaction type with the required Destination Tag. Other transaction types, such as "Escrow" or "Account-Delete" are not supported by us. Sending such other transaction types result in an irreversible loss. `,
      pol: `**We only accept POL via the Polygon network!**

Please make sure that you use the Polygon network for your POL deposit, otherwise you may not have access to your transferred POL.

If you're not sure, find out more about the different networks and the pre-settings for your external wallet or contact our support.
      `,
    },
    acceptWarning: {
      eth: 'I have read the warnings and I am aware that I can only send Ether to my ETH wallet address.',
      xrp: 'Please send XRP to this wallet address only! When depositing XRP, it is essential to include your correct Destination Tag. Sending other digital assets or making a deposit without or with the wrong Destination Tag result in an irreversible loss. There will be no credit to your wallet. We only support the so-called "payment" transaction type with the required Destination Tag.\nOther transaction types, such as "Escrow" or "Account-Delete" are not supported by us. Sending such other transaction types result in an irreversible loss.',
      bch: 'Please send only Bitcoin Cash (BCH) to this address! Sending other digital assets, including Bitcoin (BTC), will result in an irreversible loss.',
      uni: 'Please send only Uniswap (UNI) to this wallet address! Sending other digital assets result in an irreversible loss. There will be no credit to your wallet.\nThis applies, for example, to so-called ERC-20 tokens, which are based on the Ethereum blockchain but are not identical to Uniswap (UNI) and therefore are not supported by this wallet (e.g. tokens of the blockchain "Binance Smart Chain"). This wallet only supports UNI!',
      link: 'Please send only Chainlink (LINK) to this wallet address! Sending other digital assets result in an irreversible loss. There will be no credit to your wallet.\nThis applies, for example, to so-called ERC-20 tokens, which are based on the Ethereum blockchain but are not identical to Chainlink (LINK) and therefore are not supported by this wallet (e.g. tokens of the blockchain "Binance Smart Chain"). This wallet only supports LINK!',
      pol: 'I have read the warning and understand that I have to use the Polygon network for my deposit.',
    },
    messages: {
      successfullyRegenerated: 'New address successfully generated',
      cannotRegenerateInfo:
        'You can only generate a new address if you transferred cryptocurrencies to your current address.',
      genericRegenerateError: 'There was an unexpected problem regenerating your address.',
      notSupportedError: 'Selected crypto currency is not supported for crypto deposit',
    },
  },
  customerSupport: {
    sentFrom: `Sent from:\n{{name}}\n{{username}}\nOS: {{os}}\nBrowser: {{browser}}\nBISON WEB version: {{version}}`,
    mobilePhoneSubject: 'No more access to the old mobile number [Logged in]',
  },
  anonymous: {
    displayName: 'Anonymous BISON',
  },
  tutorial: {
    replay: 'Replay',
    overview: {
      title: 'App functions',
      text: 'All functions of the BISON App at a glance.',
      portfolio: 'Your Portfolio',
      market: 'Market Overview',
      transactions: 'Transaction History',
      settings: 'Settings',
    },
    market: {
      title: 'Market Overview - Buy and Sell',
      1: 'Stay informed about the prices of the tradable cryptocurrencies.',
      2: 'Choose between 24-hour, weekly, monthly and yearly views.',
      3: 'Buy and sell cryptocurrencies within seconds.',
      4: 'Your current buy price.',
      5: 'Enter an amount or select a preset amount in the bar.',
      6: 'Now you have another 10 seconds to confirm your buy.',
      7: "That's how you buy cryptocurrencies.",
    },
    portfolio: {
      title: 'Your Portfolio',
      1: 'In your portfolio you can see your cryptocurrencies, your available Euros and your profit & loss.',
      2: 'The current value of your cryptocurrencies and the change since you bought them.',
      3: 'Your available money to buy cryptocurrencies as well as your profit & loss.',
      4: 'The division of your total assets in BISON. Click on the wheel to see your individual positions.',
      5: 'All tradable cryptocurrencies at a glance.',
    },
    finished: {
      title: "You're part of the BISON tribe.",
      text: 'Start now!',
    },
  },
  info: {
    price: `# Current price

  The displayed price (current price) is the average value between the lower selling price (bid price) and the higher purchase price (ask price).

  **Current purchase price:** {{buyPrice}}

  **Current selling price:** {{sellPrice}}
      `,
    crypto: {
      linkLabel: 'More Information',
      btc: {
        description:
          "Bitcoin was released in 2009, making it the world's first and most important cryptocurrency. Its inventor is said to be someone by the name of Satoshi Nakamoto. Bitcoin consists of a monetary unit and a payment system. The digital currency (BTC) is created and managed in a global decentralized computer network by means of special software. The global system is based on a decentralized database in which all transactions are recorded in the form of a blockchain. Bitcoin gained worldwide attention through its growth in value.",
        link: 'https://bisonapp.com/en/coins/bitcoin/#general-information',
      },
      xrp: {
        description:
          'Ripple (XRP) is an open-source protocol built on a public database. The Ripple network supports all major currencies. The altcoin XRP emerged in 2012 and, unlike Bitcoin, is not blockchain-based and cannot be mined.',
        link: 'https://bisonapp.com/en/coins/ripple/#general-information',
      },
      eth: {
        description:
          'The Ethereum platform is used for distributed apps and enables the creation, execution, and management of smart contracts. Ether (ETH) is the internal means of payment for transaction processing on the global Ethereum platform.',
        link: 'https://bisonapp.com/en/coins/ethereum/#general-information',
      },
      ltc: {
        description:
          'Litecoin (LTC) is a global and decentralized payment network, comparable to the Bitcoin network. What differentiates Litecoin from Bitcoin is its technology. The transaction speed, the maximum supply of the respective coins, and the algorithm are particularly noteworthy.',
        link: 'https://bisonapp.com/en/coins/litecoin/#general-information',
      },
      bch: {
        description:
          'Bitcoin Cash (BCH) was created from the original Bitcoin blockchain through a hard fork (a community-initiated update to the protocol or code) in August 2017. The goal behind the fork was to increase the block size and thereby significantly increase transaction throughput (the number of transactions processed per unit of time in a computer).',
        link: 'https://bisonapp.com/en/coins/bitcoin-cash/#general-information',
      },
      link: {
        description:
          'LINK is the cryptocurrency of the decentralized network Chainlink. Chainlink verifies external data for smart contracts on the Ethereum Blockchain and makes that data publicly available (this is known as an Oracle Network). Through this, smart contracts can access resources such as data feeds, web APIs, or even weather data. Participants of the Chainlink network are rewarded in the cryptocurrency LINK (ERC677 token) if they operate Chainlink nodes and fulfill data requests.',
        link: 'https://bisonapp.com/en/coins/chainlink/#general-information',
      },
      uni: {
        description:
          'UNI is the native cryptocurrency of the Uniswap decentralized trading protocol. Uniswap is anchored on the Ethereum blockchain and is used to exchange ERC20 tokens. UNI itself is designed as an ERC20 token and, when used in the Uniswap protocol, enables governance rights to be exercised via the design of the Uniswap protocol.',
        link: 'https://bisonapp.com/en/coins/uniswap/#general-information',
      },
      ada: {
        description:
          "Cardano is the first proof-of-stake blockchain platform based on a peer-reviewed, research-driven development process. Its technology enables the development of decentralized applications and smart contracts using a modular approach that is both secure and efficient. Supporters claim that Cardano's Ouroboros protocol improves security, scalability, and energy efficiency relative to other proof-of-work predecessors, like Bitcoin. The platform was created in 2015 by Ethereum co-founders Charles Hoskinson and Jeremy Wood.",
        link: 'https://bisonapp.com/en/coins/cardano/#general-information',
      },
      sol: {
        description:
          'Solana is a blockchain platform. It increases scalability for users through its faster transaction & smart contract verification time, as well as its flexible infrastructure. It does this by combining a\n' +
          "proof-of-history consensus with the blockchain's underlying proof-of-stake consensus. Solana was created in 2018 by former Qualcomm employees Anatoly Yakovenko and Greg Fitzgerald.",
        link: 'https://bisonapp.com/en/coins/solana/#general-information',
      },
      dot: {
        description:
          "Polkadot is a blockchain designed to connect an ecosystem of different blockchains and enable the exchange of a wide variety of data and asset types – not just tokens. To accomplish this, it's introduced several new technical features. Its network consists of two types of blockchains: The heart of the platform (known as the relay chain), which is where all transactions are continuously validated, and the parallel user-generated parachains. Polkadot uses the proof-of-stake consensus algorithm and was developed by Ethereum's co-founder Gavin Wood.",
        link: 'https://bisonapp.com/en/coins/polkadot/#general-information',
      },
      shib: {
        description:
          'Shiba Inu is an alternative to Dogecoin that runs on the Ethereum blockchain. Its name is based on a dog breed. SHIB is an ERC20 token and part of the total supply has already been burned to ensure the stability of the token.',
      },
      doge: {
        description:
          'Dogecoin is one of the most popular altcoins. An open-source and peer-to-peer digital currency based on the viral "DOGE" meme that has been around since 2013. Dogecoin is used, among other things, as a tip on Reddit and Twitter to reward exceptional content on social media.',
      },
      pol: {
        description:
          'Originally developed in 2017 as the Matic Network to improve Ethereum\'s infrastructure, the network was eventually renamed "Polygon". Polygon aims to mitigate high gas fees and slow block confirmations, making crypto more accessible to a wider market. Due to a major upgrade in September 2024, the network\'s native token, MATIC, was migrated to POL, with the goal of providing a scalable, unified, and globally accessible blockchain technology.',
      },
      xlm: {
        description:
          "Stellar Lumens (XLM) is an open-source protocol that provides a customizable payment infrastructure. The assets native to the Stellar network are known as Lumens (XLM). Stellar's goal is to offer more efficient and cost-effective banking services. The Stellar protocol was developed by the non-profit Stellar Development Foundation. Jed McCaleb, the creator of eDonkey, Mtgox.com, and the Ripple protocol, is the founder of Stellar. Stellar XLM is used for transaction fees and acts as a bridge for transactions in various currencies.",
      },
      mana: {
        description:
          'A virtual reality (VR) game is the basis of this cryptocurrency, which runs on the Ethereum blockchain and is now the currency for many applications in Decentraland. Players can use MANA to buy goods and services in the Decentraland marketplace, as well as create their own content on the platform.',
      },
      sand: {
        description:
          'This technology aims to establish blockchains in mainstream gaming. SAND is an Ethereum-based "utility token." In the sandbox environment, this coin is the only valid cryptocurrency. Within the project, users can easily buy, sell, create, and develop digital assets, contributing content to the metaverse. SAND owners are also part of the decentralized governance (DAO) that encourages future product development.',
      },
      aave: {
        description:
          'One of the most well-known DeFi protocols developed using decentralized governance (DAO). AAVE is the governance token of the Aaave protocol and was originally called ETHLEND. It is a decentralized credit platform and one of the most important liquidity protocols in the DeFi world. Aave enables cryptocurrency lending in its protocol: Among other things, Aave users can deposit digital assets to receive interest there - without any centralized party.',
      },
      algo: {
        description:
          'A decentralized and scalable open-source blockchain project. The Algorand network is based on a Pure Proof-of-Stake (Pure PoS) consensus mechanism and aims to operate the first blockchain with a negative carbon footprint.',
      },
      axs: {
        description:
          "Axie Infinity Shards (AXS), the governance tokens of the ecosystem, play a crucial role in its governance structure. Axie Infinity is a blockchain game, drawing inspiration from popular titles like Pokémon and Tamagotchi, and stands as one of the most prominent Ethereum gaming dApps. It's built on the ERC-20 standard. The game's objective is to familiarize its players with blockchain technology in an educational and entertaining way. Players engage in breeding, battling, and collecting creatures called Axies.",
      },
      bat: {
        description:
          "Basic Attention Token (BAT) is a cryptocurrency built on Ethereum, aiming to revolutionize digital advertising by establishing a blockchain-powered digital ad exchange that completely prevents fraud. The project's objective is to facilitate a decentralized and transparent digital advertising marketplace on the Ethereum blockchain. Transactions within this ecosystem utilize the Basic Attention Token (BAT). The Ethereum blockchain's infrastructure plays a crucial role for BAT, given that it was developed under the Ethereum-based ERC-20 token standard, leveraging Ethereum's blockchain for its foundational technology.",
      },
      chz: {
        description:
          "Chiliz (CHZ) is a utility token built on the Ethereum blockchain, classified as an ERC-20 token, and issued by the Chiliz platform. This platform allows sports enthusiasts worldwide to support their favorite teams. It's important to note that Chiliz is a token, not a coin. Primarily, Chiliz tokens are used for buying fan tokens and serving as a currency for transactions like NFT purchases on the “Socios Fan Token Marketplace”. Beyond these uses, Chiliz is also popular among investors.",
      },
      crv: {
        description:
          'Curve (CRV) is a decentralized exchange (DEX) akin to the renowned Uniswap (whose UNI token is already available on BISON), but with a distinct focus. Unlike Uniswap, Curve specializes in trading based on stablecoins rather than general token-to-token exchanges. The Curve DAO Token, serving as a utility token, is designed specifically for transactions involving stablecoins and other ERC-20 tokens. Built on the Ethereum blockchain, CRV is an ERC-20 token, aligning with the industry standard for Ethereum-based tokens.',
      },
      enj: {
        description:
          'Enjin Coin (ENJ), launched in 2017 by the Enjin Network team, serves as a cornerstone of their social gaming platform. This platform empowers users to engage in various activities like operating virtual stores, socializing through chat, and creating websites and gaming clans. ENJ, adhering to the ERC-20 standard, is vital for minting NFTs. These blockchain assets, or NFTs, derive their value from ENJ, effectively granting them tangible real-world worth. Furthermore, the Enjin Ecosystem facilitates businesses and game developers in leveraging tokenized digital assets, enhancing their utility and integration.',
      },
      etc: {
        description:
          "Ethereum Classic (ETC) emerged from a hard fork of the Ethereum blockchain in 2016. This division resulted from a contentious debate among the Ethereum community and its developers, including Ethereum's founder, Vitalik Buterin. A segment of the crypto community was against a proposed change, opting to preserve the original Ethereum blockchain. As a result, Ethereum Classic ETC was born, maintaining the Proof-of-Work consensus mechanism. In contrast, the Ethereum blockchain that adopted the protocol change evolved into what is now known as the new version of Ethereum. The network's native tokens, abbreviated as ETC, are used for activities like executing transactions or running smart contracts.",
      },
      qnt: {
        description:
          'Quant Network (QNT) aims to connect networks and blockchains through operating systems. The Quant token addresses the issue of interoperability by developing the first blockchain operating system of its kind, enabling developers to launch multiple blockchains simultaneously. This is achieved with the help of QNT, the token native to the Quant network. QNT is an ERC-20 utility token hosted on the Ethereum blockchain, used to pay for licenses and fees required by developers to access the Quant network.',
      },
      xtz: {
        description:
          "Tezos (XTZ) is a decentralized blockchain network for smart contracts that allows all participants to jointly vote on changes, similar to Ethereum's Proof-of-Stake system. The core idea behind Tezos is to create a self-amending blockchain governance system through XTZ token voting. The Tezos developers aim for an advanced infrastructure that will enable the Tezos network to evolve and improve over time without the need for hard forks, which are protocol changes that lead to splits from the original blockchain. The technical foundation and associated high security, according to the founders, make the Tezos blockchain particularly suitable for sensitive areas, such as government institutions.",
      },
      grt: {
        description:
          'The Graph (GRT) is a decentralized network for querying blockchain data. The network and its native cryptocurrency, GRT, were developed to simplify and ease the complexity of querying data from blockchains, such as the Ethereum blockchain. The network operates like a search engine for data within specific blockchains, enabling The Graph to query data that is difficult to extract directly from the blockchain. The network is supported by its native cryptocurrency, GRT, which is used to pay for services within the network.',
      },
    },
    openPL: `# Open positions P/L

  The change of your portfolio value is calculated on the basis of the selling price. The change is the sum of your profits and losses that you could realise by selling all your cryptocurrencies.

  # Return

  The percentage value is the return you could realise by selling all your cryptocurrencies.
      `,
    closedPL: `# Realised P&L

  Realised profits and losses (P&L) is the sum of your profits and losses that you have achieved through the sale of cryptocurrencies. Profits and losses are calculated by BISON according to the FIFO principle (First-In-First-Out).
  
  # Yield

  The percentage display is the average volume-based return on your cryptocurrency sales.
      `,
  },
  maintenance: {
    text: 'Apologies for the inconvenience. BISON is currently undergoing maintenance and will be back up and running as soon as possible.',
  },
  statusFallback: {
    down: 'Apologies for the inconvenience. BISON is currently undergoing maintenance and will be back up and running as soon as possible.',
    warn: 'Please note that there may be interruptions during the scheduled maintenance.',
  },
  copyToClipboard: 'Copied to clipboard',
  footer: {
    poweredBy: 'powered by',
    de: 'Germany',
    relevantLinks: 'Relevant Links',
    contact: 'Contact',
    boerse: {
      text: 'Börse Stuttgart',
      url: 'https://www.boerse-stuttgart.de/en/',
    },
    solaris: {
      text: 'Cooperation partner',
      url: 'https://www.solarisgroup.com/en/',
    },
    impressum: {
      text: 'Imprint',
      url: 'https://bisonapp.com/impressum/',
    },
    datenschutzerklarung: {
      text: 'Privacy Policies',
      url: 'https://bisonapp.com/en/documents/data-privacy/',
    },
    disclaimer: {
      text: 'Disclaimer',
      url: 'https://bisonapp.com/disclaimer/',
    },
    riskInfo: {
      text: 'Basic and risk information',
      url: 'https://bisonapp.com/en/documents/risk-information/',
    },
    agb: {
      text: 'Terms & Conditions',
      url: 'https://bisonapp.com/en/documents/agb/',
    },
    termsOfUse: 'Terms of Use',
  },
  betaAccess: {
    forbidden: ` Unfortunately, you are not activated to use the beta phase for BISON Web (PC version).

If you’d like to become a beta user, please send us an email to [customer support form]({{supportLink}}). You can, of course, continue to use BISON via the app.
    
    `,
    announcement: {
      header: '*BISON Web is in Beta Phase.*',
      body: `Did you receive an invitation to Closed Beta from the BISON team? Then simply log in with your login details from the app.

If you'd like to give us feedback, have any unanswered questions, or want to apply for access to Beta as a BISON user, write to us at [hallo@bisonapp.com](mailto:hallo@bisonapp.com).
`,
      contact: 'hallo@bisonapp.com',
    },
  },
  beta: {
    header:
      'You are participating in the Beta-Test phase. You must be aware that under certain circumstances during this phase, not all functionalities may be consistently available in the web version. The same conditions apply to the use of BISON Web for the period of the closed beta phase as for the BISON app.',
    checkbox: 'Yes, I understand.',
    name: 'Beta-Phase',
  },
  updatedTermsAndConditions: {
    title: 'Modification to the General Terms and Conditions',
    header: `We've informed you about the changes made to our General Terms and Conditions. These apply with your consent.`,
    mainText: 'Please take a moment to read and agree to our updated General Terms and Conditions.',
    contentTerms: {
      a: `I have read, saved, and explicitly agree to the updated`,
      b: `and`,
      c: `.`,
    },
    contentTerms2:
      'I explicitly agree and authorize blocknox to use cryptocurrencies held in custody on my behalf for staking and to utilize the rewards generated from them. Custody remains free of charge for me.',
    termsLinks: {
      a: 'GTC of blocknox',
      b: 'EUWAX AG',
      c: 'special conditions of Solaris SE and the special conditions of Solaris SE for instant deposits.',
      d: 'GTC of EUWAX AG',
    },
    dataLinks: {
      a: 'data protection agreement of EUWAX AG',
      b: 'data protection agreement of blocknox GmbH',
      and: 'and',
    },
    agree: 'I agree',
    cancel: 'Cancel',
    exitIntent: {
      title: 'Are you sure you want to close this window?',
      body: 'Keep in mind: If you want to continue using BISON as usual, we require that you agree to our new GTC.',
      primaryAction: 'Back to approval',
      secondaryAction: 'Close window',
    },
    notification: {
      success: 'Consent to new GTC successful.',
      fail: 'Agreeing to the new GTC did not work – please try again later.',
      failAgain: 'Agreeing to the new GTC did not work. Please contact our customer service via {{email}}.',
    },
  },
  updatedTermsAndConditionsSavingsPlan: {
    title: 'Terms & conditions',

    header:
      'New: From today on we offer the possibility to define saving plans. To continue doing so, however, it is necessary that you agree to the changed terms and conditions of EUWAX AG as well as the changes to the basis and risk information. With your acceptance these changed terms and conditions will become effective immediately.',
    contentTerms: {
      a: `Yes, I read, saved, and accept the updated`,
      b: ``,
    },
    termsLinks: {
      a: 'terms & conditions of EUWAX AG.',
    },
    dataLinks: {
      a: 'basis and risk information.',
    },
    agree: 'Accept',
    cancel: 'Cancel',
  },
  featureTermsAndConditions: {
    title: 'Special Terms for Instant Deposits',
    bodyText: 'Your agreement to the Special Terms is required for the use of instant deposits.',
    checkboxText:
      'I have read, saved, and expressly agree to the Special Terms of Solaris, the Special Terms for Instant Deposit, and the Price List for Special Services.',
    agree: 'I agree',
    failure: 'Consent to the Special Terms failed. Please try again later.',
    success: 'Consent to the Special Terms successful.',
    exitPopup: {
      title: 'Are you sure you want to close this window?',
      body: 'Note: If you want to use this feature, it is necessary that you agree to the Special Terms.',
      primaryAction: 'Return to Special Terms',
      secondaryAction: 'Close window',
    },
    documents: {
      1: 'BISON_Solaris_Special_Services_Prices_1.0 (74 KB)',
      2: 'BISON_Solaris_Special_Conditions_1.11 (396 KB)',
      3: 'BISON_Solaris_Special_Conditions_instanttopups_1.1.1 (85 KB)',
    },
  },
  stakingFeatureTermsAndConditions: {
    title: 'Special terms and conditions for staking',
    bodyText: {
      withoutTandC:
        'Your agreement to the special terms and conditions for staking is required to use the staking feature.',
      withTandC:
        'To use the staking feature, your agreement to the current general terms and conditions and the special terms and conditions for staking of Boerse Stuttgart Digital Custody GmbH for private customers is required.',
    },
    checkboxText: {
      a: 'I have read, saved, and explicitly agree to the GTC of Boerse Stuttgart Digital Custody GmbH.',
      b: 'I have read, saved, and explicitly agree to the special terms and conditions for staking of Boerse Stuttgart Digital Custody GmbH for private customers.',
      c: 'I give my explicit consent and hereby authorize Boerse Stuttgart Digital Custody to use the cryptocurrencies held in custody for me for staking purposes and to utilize the rewards generated from staking.',
    },
    rewards: `Info:
<1/><1/>You will receive the rewards from the cryptocurrencies you have placed into staking on a weekly basis, in accordance with the special staking terms and conditions of Boerse Stuttgart Digital Custody GmbH.
<1/><1/>If you have agreed to the staking terms and conditions and authorized staking, but do not place your cryptocurrencies into staking, Boerse Stuttgart Digital Custody GmbH may utilize your cryptocurrencies for staking and use the rewards generated.
<1/><1/>Custody remains free of charge for you. A staking insurance is included in the offer for your protection.
<1/><1/>Agreeing to the special terms and providing authorization for staking is not mandatory if you do not wish to use BISON for staking. In this case, simply click on the "X" in the pop-up.`,
    agree: 'I agree',
    updateStatusNotifications: {
      withoutTandC: {
        success: 'You’ve successfully agreed to the special terms and conditions!',
        failure:
          'We were unable to process your agreement to the special terms and conditions—please try again later.',
        failureForMultipleTimes:
          'We were unable to process your agreement to the special staking terms and conditions—please contact our customer service at {{email}}.',
      },
      withTandC: {
        success: 'You’ve successfully agreed to the GTC & special terms and conditions for staking!',
        failure:
          'We were unable to process your agreement to the GTC & staking terms—please try again later.',
        failureForMultipleTimes:
          'We were unable to process your agreement to the GTC & staking terms—please contact our customer service at {{email}}.',
      },
    },
    exitPopup: {
      title: 'Are you sure you want to close this window?',
      body: {
        withoutTandC:
          'Note: If you’d like to use the staking feature, you’ll need to agree to the special terms and conditions.',
        withDirective:
          'Note: If you’d like to use the staking feature, you’ll need to agree to the special terms and conditions.',
        withTandC:
          'Note: If you’d like to use the staking feature, you’ll need to agree to the current general terms and conditions and the special staking terms and conditions of Boerse Stuttgart Digital Custody GmbH.',
      },
      primaryAction: 'Return to agreement',
      secondaryAction: 'Close window',
    },
    documents: {
      specialConditions: 'Staking-Terms.pdf',
      general: 'GTC-BSD-Custody.pdf',
    },
  },
  tradeOrder: {
    buyPrice: 'Buy price',
    sellPrice: 'Sell price',
    cryptoAmount: 'Number',
    eurAmount: 'Amount',
    sellRequest: 'Send binding sell request',
    buyRequest: 'Send binding purchase request',
    limitOrder: '+ Limit Order',
    cryptoAvailable: 'Available {{crypto}}: ',
    eurAvailable: 'Available Euro: ',
    illiquidMarketWarning: {
      buy: {
        text: 'Be aware that the current buy price deviates significantly from the market price. You may get a better price by reducing the amount or dividing your orders.',
        modalTitle: 'Why is the current buy price higher?',
        modalText: `
If the volume of a requested order is particularly high, it can result in significant price variations from the displayed market price. But why?

The gap between buying and selling prices is subject to market fluctuations. For large purchases, it may be necessary to resort to a broader market offer, which can then result in a higher average buy price.

To potentially get a better price, you can reduce the amount or divide it into several smaller orders.
        `,
      },
      sell: {
        text: 'Be aware that the current sell price deviates significantly from the market price. You may get a better price by reducing the amount or dividing your orders.',
        modalTitle: 'Why is the current sell price lower?',
        modalText: `
If the volume of a requested order is particularly high, it can result in significant price variations from the displayed market price. But why?

The gap between buying and selling prices is subject to market fluctuations. For large sales, it may be necessary to resort to a broader market offer, which can then result in a lower average sell price.

To potentially get a better price, you can reduce the amount or divide it into several smaller orders. 
        `,
      },
    },
  },
  stocksEtfsTradeOrder: {
    common: {
      title: {
        buy: 'Buy {{assetType}}',
        sell: 'Sell {{assetType}}',
      },
      priceLabel: {
        buy: 'Buy Price',
        sell: 'Sell Price',
      },
      inputLabel: 'Amount',
      inputPlaceholder: 'Max. {{maxAmount}}',
      expected: 'Expected Total',
      available: 'Available: {{available}}',
      commission: 'There is a fee of <strong>{{fee}}</strong><br/> per trade. See',
      linkText: 'information on costs',
      buttonText: 'To the portfolio',
      summary: {
        a: 'Amount',
        b: 'Price',
        c: 'Order Fee',
        price: {
          buy: 'Buy Price',
          sell: 'Sell Price',
        },
        countdown: {
          running: "We've reserved the price for you.",
          expired: 'Your offer has expired.',
          expiredExplanation: 'Your offer has expired – please make a new one.',
        },
        confirmButton: {
          buy: 'Send buy request',
          sell: 'Send sell request',
        },
        refreshButton: 'Refresh offer',
      },
      success: {
        headline: {
          one: '1 {{assetCategory}} {{type}}',
          multiple: 'You {{type}} {{amount}} {{assetCategory}}',
        },
        type: {
          buy: 'bought',
          sell: 'sold',
        },
        summaryText: {
          one: 'You {{type}} {{amount}} <strong>{{assetName}}</strong> {{assetCategory}} \nvalued at {{price}} for <strong>{{totalValue}}</strong>.',
          multiple:
            'You {{type}} {{amount}} {{assetCategory}} of <strong>{{assetName}}</strong> \nvalued at {{price}} each for <strong>{{totalValue}}</strong>.',
        },
        pendingSuccessText: {
          buy:
            '<strong>Note:</strong> Your purchase request is still being processed. It may take up to 5 minutes for your transaction to be processed under <1>Transaction Overview</1>.\n' +
            '\n' +
            "Don't worry, in the vast majority of cases the purchase will go through as normal. If this is not the case, we will inform you immediately.",
          sell:
            '<strong>Note:</strong> Your sale request is still being processed. It may take up to 5 minutes for your transaction to be processed under <1>Transaction Overview</1>.\n' +
            '\n' +
            "Don't worry, in the vast majority of cases the sale will go through as normal. If this is not the case, we will inform you immediately.",
        },
      },
      error: {
        title: {
          buy: '{{assetCategory}} unfortunately could not be bought',
          sell: '{{assetCategory}} unfortunately could not be sold',
        },
        text: {
          buy: 'We unfortunately were not able to process your buy request for <strong>{{assetName}}</strong>. Please try again at a later time.',
          sell: 'We unfortunately were not able to process your sell request for <strong>{{assetName}}</strong>. Please try again at a later time.',
        },
        maxAmount: {
          aboveMaxTradeValue: 'The maximum investment volume per trade is {{max}}',
          aboveMaxAccountFunds: 'You do not have enough euros available.',
          aboveMaxSellAmount:
            'The number specified exceeds your available shares {{max}}. Please enter an amount no greater than {{max}}.',
        },
        minAmount: 'The amount is too low. Enter a number that results in a sum of at least {{min}}.',
      },
      pending: {
        title: {
          buy: 'We are processing your purchase request ...',
          sell: 'We are processing your sale request ...',
        },
        text: 'This may take a few minutes in rare cases. Please do not leave this screen.',
      },
    },
    riskConfirmation: {
      label:
        'Trading this financial instrument is not advised according to my level of experience and knowledge, but I would like to trade despite knowing the risk.',
    },
    fundConfirmation: {
      label:
        'I consent to having my legal sales <1>{{linkLabel}}</1> provided to me electronically here as opposed to in paper form. I have downloaded and saved them.',
      linkLabel: 'documents',
    },
    nonUsConfirmation: {
      label:
        'I confirm that I am not a U.S. citizen and that I do not currently reside in the United States of America.',
      modalMarkdown: `The security you've selected is subject to U.S. sanctions. In accordance with Executive Order No. 13959, it is not permitted to be acquired or held by U.S. persons. 

U.S. persons are defined as:
- Those with U.S. nationality;
- Those with permanent residence in the United States of America;
- Green card holders regardless of their place of residence;
- Those who are temporarily on U.S. soil, such as for a vacation. 

In order to trade this security, you must verify that you are not a U.S. person as defined above and, in particular, that you are not physically located within the U.S. when the order is placed. Otherwise, you will be in violation of existing U.S. sanctions.`,
      modalHeader: 'Security subject to US sanctions',
    },
    structuredConfirmation: {
      label:
        'I consent to having my legal sales <1>{{linkLabel}}</1> provided to me electronically here as opposed to in paper form. I have downloaded and saved them.',
      linkLabel: 'documents',
      modalMarkdown: `This investor information also includes details on where the sales prospectus, as well as the latest published annual and semi-annual reports, can be downloaded from the fund provider's website.`,
      modalHeader: 'Additional notes on investor information (KID)',
    },
    highRiskModal: {
      title: 'Sorry! {{assetType}} could not be bought',
      body: 'Judging from your experience – which is based on the answers you provided during the activation process – you do not have enough experience regarding the asset class of this security. For this reason, we unfortunately cannot allow you to trade this security.<br><br> You can review and edit your experience with securities on your account under the <1>{{profileLink}}</1> section.',
      bodyLink: 'Profile',
    },
    costInformationModal: {
      quantityAbbreviation: 'pcs.',
      title: 'Cost Information on Securities Trading',
      transaction: {
        title: 'Transaction',
        orderTypeAmount: 'Order Type & Amount',
        orderTypeAmountText_one: '{{orderType}} of {{count}} unit',
        orderTypeAmountText_other: '{{orderType}} of {{count}} units',
        amountBuyOrderType: 'Purchase',
        amountSellOrderType: 'Sell',
        buyOrderType: 'Buy',
        sellOrderType: 'Sell',
        security: 'Security',
        fee: 'Order fee',
        price: '{{orderType}} price per unit',
        totalOrderPriceBuy: 'Total buy price',
        totalOrderPriceSell: 'Total sell price',
        details:
          'The total cost of a transaction consists of the buying, holding, and selling costs, and – assuming a one-year holding period – amounts to {{fee}}, which is {{feePercantage}} of the market price. The return would thus also be reduced by {{feePercantage}} for an assumed one-year holding period. The actual total costs may differ from this due to, for example, a different holding period.',
      },
      costs: {
        title: 'Costs',
        buy: {
          title: 'One-time purchase',
          serviceFees: 'Service fees',
          fee: 'Order Fee',
          discount: 'Discount',
          incentives: 'Incentives',
          productCosts: 'Product costs',
        },
        holdingPeriod: {
          title: 'Per year of holding period (ongoing)',
          tpcSecurity: 'Third-Party Costs: Security',
        },
        sell: {
          title: 'One-time sale',
          serviceFees: 'Service fees',
          fee: 'Order Fee',
          discount: 'Discount',
          incentives: 'Incentives',
          productCosts: 'Product costs',
        },
        details:
          'We would like to inform you that for this transaction, justTRADE receives an average reimbursement as outlined in the table above, which we distribute to our customers in the form of a reduction in their order commission. If the amount of the reimbursement is below that of the order commission, the bank will waive the difference. As a result, the effective order commission to be paid by the customer will always amount to {{fee}}. ',
      },
      totalCosts: {
        title: 'Total cost',
        firstYear: 'In the first year (plus proportional product costs)',
        holdingCosts: 'Per year of holding period',
        saleYear: 'In the first year of the sale (plus proportional product costs)',
        important: {
          title: 'Important note:',
          details:
            'Please keep in mind that some fee components are not included in the list, either because they have not yet been determined prior to placing the order or because the exact amount cannot be calculated. These include, among others, the service fee for entering registered shares or custody fees for items such as Xetra-Gold. Any accrued interest on bonds is also not included in the list.',
        },
      },
      actionButton: 'Back to the summary',
      errors: {
        title: 'Error with cost information',
        get: 'Something went wrong with fetching the cost information.',
      },
    },
    noteModal: {
      title: {
        buy: 'Note on duration of buy offer',
        sell: 'Note on duration of sell offer',
      },
      text: 'Keep in mind that we can only hold the price for you for a few seconds.\n\nIf your offer expires, you can easily update it in the next step.',
      checkboxText: "Don't show this message again.",

      buttonText: 'Ok',
    },
    stock: {
      name: 'stocks',
      summaryName: 'stock(s)',
      summaryNameOne: 'stock',
    },
    etf: {
      name: 'ETF',
      summaryName: 'ETF shares',
      summaryNameOne: 'ETF share',
    },
    etc: {
      name: 'ETC',
      summaryName: 'Commodities-ETPs',
      summaryNameOne: 'Commodities-ETP',
    },
    etn: {
      name: 'ETN',
      summaryName: 'ETN shares',
      summaryNameOne: 'ETN share',
    },
    confirmPriceLoadingModal: {
      title: 'Currently the exchange takes longer. Please wait until a quote is ready.',
    },
    errorModal: {
      notBuyable: {
        title: 'Unfortunately you cannot buy {{assetName}}',
        note: 'The selected security cannot be bought at this time.',
      },
      notSalable: {
        title: 'Unfortunately you cannot sell {{assetName}}',
        note: 'The selected security cannot be sold at this time.',
      },
      general: {
        title: 'Unfortunately, you cannot trade {{assetName}}',
        note: 'Based on your experience from the answers you gave in the activation process, you do not have enough knowledge within the asset class of this security. Therefore, unfortunately, we cannot enable you to trade in this security. You can view your securities experience in your account in the profile section.',
      },
      button: 'Back to asset',
      support:
        "Open questions? We'd be happy to help – feel free to contact us any time via <1>{{link}}</1>.",
    },
    quoteErrorModal: {
      title: 'Sorry! Your offer cannot be calculated at the moment',
      text: {
        buy: "Unfortunately, an offer couldn't be calculated for your {{assetName}} buy request. Please try again later.",
        sell: 'Unfortunately, an offer could not be calculated for your {{assetName}} sell request. Potential reasons for this may be that a corporate action is in progress, this stock is no longer tradable, or this stock is in a trading halt. \nPlease try again later.',
      },
      button: 'Try again',
    },
  },
  alerts: {
    title: 'Price Alert',
    web_pageHeadline: '{{currency}} Price Alert',
    noAlerts: 'No alerts',
    newAlert: 'New Price Alert',
    editAlertTitle: 'Edit price alert',
    createAlertTitle: 'Create Price Alert',
    reactivationTime: 'This alert is deactivated until {{time}}. Press to re-activate.',
    showAdvanced: 'Show advanced options',
    hideAdvanced: 'Hide advanced options',
    notificationsDisabled: `Your push notifications are currently disabled.\n\nTo receive price alerts, you first need to allow push notifications from BISON.`,
    enableNotifications: 'Allow notifications',
    sendMe: 'Send me',
    for: 'For',
    when: 'When',
    price: 'Price',
    afterBeingTriggeredThisAlertWillBeSuspended: 'This price alert will be deactivated',
    gracePeriod: 'Suspension period',
    web_crypto: 'CRYPTO',
    web_alertPrice: 'ALERT PRICE',
    web_deleteOrder: 'Delete alert',
    web_currentPrice: 'Current Price',
    currentPrice: {
      buy: 'Current purchase price',
      sell: 'Current selling price',
    },
    explanationTitle: 'Explanation',
    disclaimerTitle: 'Disclaimer',
    gracePeriods: {
      '1h': "for 1h after it's been triggered",
      '2h': "for 2h after it's been triggered",
      '6h': "for 6h after it's been triggered",
      '12h': "for 12h after it's been triggered",
      '24h': "for 24h after it's been triggered",
      triggerOnce: 'after it’s been triggered',
    },
    sendingType: {
      Push: 'a push notification',
      web_Push: 'a push notification (Mobile only)',
      Email: 'an email',
    },
    types: {
      WhenSellPriceFallsBelow: 'Sell price less than',
      WhenBuyPriceFallsBelow: 'Buy price less than',
      WhenSellPriceRisesAbove: 'Sell price more than',
      WhenBuyPriceRisesAbove: 'Buy price more than',
      WhenMidPriceCrosses: 'Current price',
    },
    typesText: {
      WhenSellPriceFallsBelow: 'I can sell for less than',
      WhenBuyPriceFallsBelow: 'I can buy for less than',
      WhenSellPriceRisesAbove: 'I can sell for more than',
      WhenBuyPriceRisesAbove: 'I can buy for more than',
      WhenMidPriceCrosses: 'the current price is',
    },
    typesExplanationText: {
      WhenSellPriceFallsBelow: {
        a: 'when',
        b: 'I can sell for less than',
        c: '',
      },
      WhenBuyPriceFallsBelow: {
        a: 'when',
        b: 'I can buy for less than',
        c: '',
      },
      WhenSellPriceRisesAbove: {
        a: 'when',
        b: 'I can sell for more than',
        c: '',
      },
      WhenBuyPriceRisesAbove: {
        a: 'when',
        b: 'I can buy for more than',
        c: '',
      },
      WhenMidPriceCrosses: {
        a: 'when',
        b: 'the current price is',
        c: '',
      },
      NotSelected: {
        a: '...',
        b: '',
        c: '',
      },
    },
    web_explanationSplit: `Send me <1>{{alertType}}</1> for <3>{{selectedCrypto}}</3> when <5>{{timeSelectValue}}</5> <6>{{alertPriceValue}}</6>. This price alert will be deactivated <8>{{optionalPart}}</8>.`,
    explanationSplit: {
      a: 'Send me',
      b: 'for',
      regular: {
        c: '. This price alert will be deactivated for',
        d: 'hour{{ending}}',
        e: 'once it’s been triggered.',
        ending: {
          singular: '',
          plural: 's',
        },
      },
      once: {
        c: ' .\nThis alert will be',
        d: 'deactivated once it’s been triggered.',
      },
    },
    form: {
      fieldErrors: {
        entityRequired: 'Please select a cryptocurrency',
        priceRequired: 'Please enter a price',
        priceAlertTypeRequired: 'Please select an alert type',
        gracePeriodRequired: 'Please select a suspension period',
      },
    },
    banners: {
      successCreated: 'Price alert successfully created.',
      successEdited: 'Price alert successfully edited.',
      successDelete: 'Price alert successfully deleted.',
      alreadyExists: 'You already have the same price alert.',
      editError: 'Failed to edit price alert.',
      createError: 'Failed to create price alert.',
      genericError: 'There was a problem setting your price alert.',
      deleteError: 'There was a problem with deleting your price alert.',
      fetchingError: 'There was a problem fetching your price alerts.',
    },
    emptyState: {
      title: 'Never miss a thing!',
      body: `Set a custom price alert now, so that you'll learn about price developments as they happen.`,
    },
    web_subheaderInfo:
      'Stay informed when prices change on the crypto market. Create as many price alerts as you like.',
    web_mobileNotifications:
      'For mobile notifications, you need the BISON app on your smartphone. Simply download it and allow push notifications from BISON.',
    status: {
      paused: 'Paused',
      active: 'Active',
    },
    pause: {
      success: 'Price Alert paused',
    },
    unpause: {
      success: 'Price Alert resumed',
    },
  },
  savings: {
    web_modalTitle: 'New Savings Plan',
    web_selectionTitle: 'Create Savings Plan',
    tableList: {
      web_crypto: 'CRYPTOCURRENCY',
      web_interval: 'INTERVAL',
      web_eurAmount: 'EURO AMOUNT',
      web_startingPoint: 'NEXT EXECUTION DATE',
    },
    savingsPlanDescriptionAmountAndTrigger: {
      web_weekly: '{{amount}} per week',
      web_monthly: '{{amount}} per month',
      web_quarterly: '{{amount}} per quarter',
    },
    web_legalText: `Send binding purchase inquiry with savings plan`,
    web_currentPrice: 'Current purchase price',
    web_eurAvailable: 'Available Euro',
    web_successText: `You have successfully submitted your {{crypto}} monthly Saving Plan.

You will receive a notification as soon as your next purchase request within the Savings Plan is processed.`,
    title: 'Savings Plan',
    selectYourCrypto: 'Create Savings Plan',
    listTitle: 'Savings Plan',
    trigger: {
      monthly: 'Monthly',
      weekly: 'Weekly',
      quarterly: 'Quarterly',
    },
    startingPoint: {
      FirstOfMonth: '1st of the month',
      FifteenthOfMonth: '15th of the month',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
    },
    nextExecution: 'Next execution',
    noSavings: {
      title: 'Save with a plan!',
      text: `Choose a savings amount and savings interval for the cryptocurrency of your choice. We'll take care of the rest.`,
    },
    newSaving: 'New Savings Plan',
    status: {
      paused: 'Paused',
      active: 'Active',
    },
    detail: {
      overview: 'Overview',
      crypto: 'Cryptocurrency',
      cryptoAmount: 'Crypto amount',
      amount: 'Euro amount',
      interval: 'Interval',
      firstExecution: 'Next execution date',
      invested: 'Invested',
      value: 'Value',
      delete: 'Delete',
      pause: 'Pause plan',
      resume: 'Resume plan',
      profitLoss: 'Open P/L',
      startingPoint: 'Execution',
    },
    savingsPlanDescription: {
      text: `I would like to invest <1>{{amountAndTrigger}}</1> in <2>{{crypto}}</2>. 
      
The next execution of the savings plan takes place on the <3>{{execution}}</3>.`,
    },
    pause: {
      success: 'Successfully paused the savings plan',
      error: 'Cannot pause the savings plan',
    },
    unpause: {
      success: 'Successfully resumed the savings plan',
      error: 'Cannot resume the savings plan',
    },
    disable: {
      success: 'Successfully deleted the savings plan',
      error: 'Cannot delete the savings plan',
    },
    info: {
      web_title: `Savings Plan`,
      web_content: `
The BISON Savings Plan allows you to regularly invest a fixed euro amount in the corresponding cryptocurrencies.

For each cryptocurrency you can set a fixed euro amount, a regular interval and an individual purchase date within this interval.

You can pause or delete your savings plan at any time in the "Trading Manager" menu item. A paused savings plan can be resumed at any time. It is possible to create several savings plans in parallel at BISON.

We will notify you as soon as your savings plan has been executed and you have bought the cryptocurrency.

Please note that your savings plan can only be executed as planned if your BISON Euro account has a corresponding credit balance. For this purpose, you can create a standing order from your reference account if necessary. If your savings plan is not executed due to insufficient funds, your savings plan will remain active and will be executed on the next execution date, provided there is a sufficient balance.`,
    },
    legalText: `Purchases are made at an updated purchase price`,
    legalTextConfirm: `Purchases are made at an updated purchase price`,
    currencyVolumeLabel: 'Euro amount',
    triggerLabel: 'Interval',
    startingPointLabel: 'Starting point',
    send: 'Next',
    web_send: 'OK, send',
    next: 'Next',
    savingsPlanConfirmTitle: '{{cryptoName}} Savings Plan',
    confirmSavingsPlanBtnLabel: 'OK, Confirm',
    successTitle: 'Savings Plan submitted!',
    failTitle: 'Savings Plan rejected.',
    successText: `
      Your {{cryptoName}} savings plan has been successfully submitted. You will be notified as soon as a purchase has been executed.
      \n Please make sure that your BISON account is always sufficiently funded on the purchase dates (e.g. via standing order).
      `,
    failText: 'There was a problem creating your savings plan.',
    termsAndConditions: {
      title: 'Title',
      firstParagraph:
        'Tell us your ideas on how to improve the BISON app! We look forward to hearing your feedback and suggestions for new features.',
      secondParagraph:
        "We hope you understand that we can't respond to every single suggestion. If we have specific questions about your request, we will get back to you via email.",
      checkboxPreLink: 'Yes, I read, saved, and accept the updated ',
      firstCheckboxLink: 'terms & conditions of EUWAX AG and blocknox GmbH ',
      firstCheckboxPostLink: '.',
      secondCheckboxLink: 'basis and risk information ',
      secondCheckboxPostLink: '.',
    },
    notEnoughFundsWarning:
      'Please ensure that your BISON account is always sufficiently funded on the purchase dates (e.g. via standing order). For this savings plan, you currently lack {{amount}} euros (account balance minus open orders) so that the transaction can be successfully executed. \n\n Please note: Other savings plans are not taken into account in this calculation!',
    confirm: {
      web_header: '{{cryptoName}} Savings Plan',
      web_subheader: 'Overview',
      web_firstItem: 'Cryptocurrency',
      web_secondItem: 'Euro amount',
      web_thirdItem: 'Interval',
      web_fourthItem: 'Execution',
      web_fifthItem: 'Next execution date',
    },
    delete: {
      popUp: {
        web_text: 'Are you sure you want to delete your savings plan?',
      },
      screen: {
        web_cryptocurrency: 'Cryptocurrency',
        web_euroAmount: 'Euro amount',
        web_interval: 'Interval',
        web_nextExecution: 'Next execution date',
        web_execution: 'Execution',
        web_cryptoAmount: 'Crypto amount',
        web_invested: 'Invested',
        web_value: 'Value',
        web_openPL: 'Open P/L',
        web_deletePlan: 'Delete',
      },
    },
    inputs: {
      euroAmount: {
        errors: {
          web_zero: 'Please enter a higher value',
          web_exceededValue: 'The maximum investment volume per trade is € 100.000,00.',
        },
        web_label: 'Euro amount',
      },
      interval: {
        web_label: 'Interval',
      },
      buyDate: {
        web_label: 'Execution',
        web_weekLabel: 'Execution',
      },
    },
    web_confirmText: 'Purchases are made at an updated purchase price',
    confirmDescriptionLabel: 'Confirm binding savings plan',
    buttons: {
      web_cancel: 'Cancel',
      web_confirm: 'Next',
    },
  },
  devices: {
    title: 'Paired devices',
    device: 'Device',
    OSVersion: 'OS version',
    web_OS: 'OS',
    status: 'Status',
    unpair: 'Unpair device',
    lastActivity: 'Last activity',
    unknownLastActivity: 'Unknown',
    unknownPhone: 'Unknown phone',
    unknownBrowser: 'Unknown browser',
    currentDevice: 'Current device',
    otherDevices: 'Other devices',
    unpairSuccess: 'The device was successfully unpaired.',
    unpairError: 'There was a problem unpairing your device. Please try again.',
    fetchError: 'There was a problem getting your paired devices info.',
    confirmUnpair: 'Are you sure, you want to unpair this device?',
    confirmUnpairModal: {
      title: `Are you sure, you want to unpair this device?`,
      text: `Are you sure, you want to remove this device from the authorized devices list?`,
    },
    revokeDeviceConsentModal: {
      title: 'Are you sure you want to disable device monitoring for {{device}}?',
      text: 'Note: If you do not consent to having your device information checked, your account may be suspended or terminated. Having your device information checked prevents, for example, third parties from taking over your account.',
      confirmButton: 'Disable monitoring',
    },
    revokeDeviceConsentResultNotification: {
      success: 'Device monitoring for {{device}} disabled.',
      error: 'Failed to disable device monitoring for {{device}}.',
    },
  },
  cookieConsent: {
    text: {
      a: 'Information regarding our use of',
      b: 'cookies',
      c: 'We use cookies and other related technologies to ensure that our website and its services are accessible, user-friendly, and can be continuously improved upon. While "necessary cookies" are required for the use of our website, all other cookies are used to help us optimize and further develop our services, thereby ensuring their economic operation. We only use non-essential cookies if you explicitly consent to them. You can express your consent by clicking on "Accept all cookies" or by agreeing to a particular use of cookies. Your consent to our use of cookies can be revoked at any time for future visits to our website; the settings can also be modified at a later point in time.',
      d: 'We would also like to state that by using this website, your data will also be collected and processed by US companies. If you are in agreement with this, click on “Accept all cookies”. By doing so, you are consenting to the transfer and processing of your data to third-party countries, such as the USA, in accordance with article 49 (1), sentence 1a) of the General Data Protection Regulation (GDPR). More information about transfers to third-party countries can be found in our privacy policy.',
      e: 'More information can be found in our',
      f: 'privacy policy',
      g: '.',
    },
    select: {
      necessary: 'Necessary',
      marketing: 'Marketing / Analysis',
    },
    buttons: {
      acceptAll: 'Accept all cookies',
      notAccept: 'Do not accept any cookies',
      select: 'Select Cookies',
      save: 'Save preferences',
    },
  },
  demoExplanationPopup: {
    headline: 'Test without risk',
    subText: 'Welcome to demo mode - here you can get to know BISON and the crypto market.',
    1: 'trade with playmoney',
    2: 'no verification necessary',
    3: 'Get to know the market risk-free',
    finalText:
      "<1>Please note:</1> While in demo mode you can't make any real losses, but you can't make any real profits either.",
    buttonText: 'Start',
    verifyText:
      'You already know the crypto market well enough? <1>{{verifyText}}</1> to trade directly with real money.',
    verifyLink: 'Verify yourself here',
  },
  demoPopup: {
    headline: 'Verify and get started!',
    subText: 'What you need:',
    1: 'a valid identity card or passport with a current bill. (e.g.: telephone bill)',
    2: 'a stable internet connection',
    3: 'a webcam, a laptop with a camera or a smartphone',
    finalText: 'And then you can trade cryptocurrencies with real money.',
    header: 'Verification',
  },
  demoPopupForRealUser: {
    headline: 'Demo mode',
    subText: 'You are in demo mode. This functionality is only available in real money mode.',
  },
  emptyCryptoPortfolioPage: {
    headline: 'Nothing to see yet',
    mainText:
      'As soon as you have made your first transaction, you will see the overview of your cryptocurrencies here.',
    buyCryptoBtn: 'Buy crypto',
    depositCryptoBtn: 'Deposit here.',
    preDepositCryptoText: 'Already own crypto?',
  },
  fewCryptoPortfolioPage: {
    headline: 'More cryptos',
    mainText: 'In the market overview you can view available cryptos and charts.',
    btnText: 'Market overview',
  },
  lotteryBanner: {
    header: 'BISON App Advent Calendar',
    mainText:
      "**Now exclusively available in your BISON app!** Each day during the Advent season, you'll have the chance to win 5 x €100 in Bitcoin – and at the end, we'll raffle an additional €1,000 in Bitcoin among everyone participating! ",
    linkText:
      "This promotion will take place from December 1st to December 24th. Here, you'll find everything you need to know about how to participate in the Advent Calendar Sweepstakes using the app.",
  },
  metaTags: {
    app: {
      title: 'BISON Web',
      description:
        'BISON Web ▶ Die Webversion zum Handeln von Kryptowährung - einfach ✅ smart ✅ zuverlässig ✅ powered by Börse Stuttgart',
    },
    signup: {
      title: 'BISON Web Registrieren',
      description:
        'BISON Web Registrieren ▶ Registriere dich jetzt ▶ Die Webversion zum Handeln von Kryptowährung - einfach ✅ smart ✅ zuverlässig ✅ powered by Börse Stuttgart',
    },
    signin: {
      title: 'BISON Web Login',
      description:
        'BISON Web Login ▶ Einloggen und loslegen ▶ Die Webversion zum Handeln von Kryptowährung - einfach ✅ smart ✅ zuverlässig ✅ powered by Börse Stuttgart',
    },
  },
  techIndicators: {
    button: 'Close',
  },
  phishing: {
    header_title: 'Phishing attempts',
    headline: 'Caution: Phishing attempts are on the rise these days!',
    text: {
      a: 'BISON will never ask you for your login details via email or prompt you to make transactions to foreign wallet addresses!',
      b: "Always make sure that you're navigating BISON's real, authentic sites or using the BISON app.",
      c: 'For more details, click',
      linkText: 'here',
    },
  },
  promotion: {
    savingPlan: {
      termsAndConditions: {
        title: 'It pays to save!',
        mainText: `
          Create your first savings plan and receive up to €35 in BTC! After your first successful savings plan investment, you'll receive €10 in BTC. Make sure to stick with it – after your third successful investment, you'll receive another €25 in BTC.
        `,
        subtitle: "Here's how easy it is:",
        points: {
          a: 'Create your first savings plan with the cryptocurrency and frequency of your choice.',
          b: 'Choose an amount to invest that is at least €35 per interval and invest it in three consecutive intervals.',
          c: 'Make sure that your BISON account is sufficiently funded so that your savings plan can be executed. If not, the reward will not be credited to your account. ',
        },
        label: {
          a: 'I would like to participate. I have read and I agree to the',
          b: 'terms and conditions,',
          c: 'as well as the',
          d: 'privacy policy.',
          e: 'Furthermore, I am a resident of Germany, Austria, or Switzerland.',
        },
        buttons: {
          agree: 'Participate & create savings plan',
          cancel: 'Maybe later',
        },
      },
      succsessModal: {
        title: "You're in!",
        mainText: `
          We're delighted that you've decided to take part in our Savings Plan promotion.
          \n
          Here's your next step
          \n
          Create your first savings plan and invest at least €35 per interval. Run it for at least three intervals to receive your full reward. Create savings plan now
        `,
        button: 'Create savings plan now',
      },
      portfolioBanner: {
        title: 'Receive up to €35 in Bitcoin when you start your first savings plan!',
        button: 'Create savings plan now',
        confirmed: `You're in!`,
      },
      accountBanner: {
        title: 'Savings Plan Promotion',
        text: 'Continues up until, and including, {{date}}',
      },
      demoPopup: {
        title: 'Demo mode',
        description: `
You are currently in demo mode. The savings plan function is only available in real money mode.

Would you like to participate in the savings plan bonus promotion? Then simply verify yourself in a few minutes via video identification and you're ready to go!
    `,
      },
    },
  },
  savingsPlanPromo: {
    title: 'It pays to save!',
    settingsLabel: 'Savings Plan Promotion',
    executions: 'Executed {{executions}} / 3',
    settingsLabelExpiration: 'Continues up until, and including, ',
    banner: {
      title: 'Receive up to €35 in Bitcoin when you start your first savings plan!',
      button: 'Create savings plan',
      optedIn: "You're in!",
    },
    optIn: {
      title: 'It pays to save!',
      a: "Create your first savings plan and receive up to €35 in BTC! After your first successful savings plan investment, you'll receive €10 in BTC. Make sure stick with it – after your third successful investment, you'll receive another €25 in BTC.",
      b: 'Here’s how easy it is:',
      c: 'Create your first savings plan with the cryptocurrency and frequency of your choice.',
      d: 'Choose an amount to invest that is at least €35 per interval and invest it in three consecutive intervals.',
      e: 'Make sure that your BISON account is sufficiently funded so that your savings plan can be executed. If not, the reward will not be credited to your account.',
      checkBox: {
        a: 'I would like to participate; I have read and I agree to the ',
        b: 'terms and conditions ',
        c: 'as well as ',
        d: 'the privacy policy.',
        e: ' ',
        f: 'Furthermore, I am a resident of Germany, Austria, or Switzerland.',
      },
      confirmButton: 'Participate & create savings plan',
      remindMeLater: 'Maybe later',
    },
    optInFinished: {
      title: "You're in!",
      text: `We're delighted that you've decided to take part in our Savings Plan promotion.
      Here's your next step:
      Create your first savings plan and invest at least €35 per interval. Run it for at least three intervals to receive your full reward.`,
      cta: 'Create savings plan now',
    },
    pauseConfirm: {
      title: 'Are you sure you want to pause your savings plan?',
      text: 'If you pause your savings plan now, you will no longer be able to participate in the savings plan promotion and you will automatically forfeit your reward.',
      confirm: 'Pause Savings Plan',
    },
    deleteConfirm: {
      title: 'Are you sure you want to delete your savings plan?',
      text: `If you delete your savings plan now, you will no longer be able to participate in the savings plan promotion and you will automatically forfeit your reward.
      If your first savings plan has not been executed yet, you can delete it now and create a new savings plan. 
      This will allow you to participate in the promotion again.`,
      confirm: 'Delete Savings Plan',
    },
  },
  tradingRules: {
    emptyScreen: 'No time to monitor the crypto market? Create trading rules to buy or sell automatically.',
    stopOrderButton: 'Stop Order',
    limitOrderButton: 'Limit Order',
    tradingRules: 'Trading Rules',
    limitBuy: 'Buy limit',
    limitSell: 'Sell limit',
    stopBuy: 'Stop buy',
    stopLoss: 'Stop loss',
    buy: 'Buy',
    sell: 'Sell',
  },
  abbreviations: {
    million: 'M',
    billion: 'Bn',
  },
  performanceIndicators: {
    title: 'Performance indicators',
    firstPriceOfTheDay: 'Opening price',
    lastPriceOfPreviousTradingDay: 'Closing price of the previous day',
    priceEarningRatio: 'PER',
    dividendYield: 'Dividend yield',
    dividendByStock: 'Dividend per stock',
    marketCapitalisation: 'Market capitalization',
    totalExpenseRatio: 'Total expense ratio',
    modalMarkdown: `# Performance indicators
    
Performance indicators are a set of quantitative measures that are used to assess the long-term performance of companies. They assist in determining the strategic, financial, and operational performance of a company, particularly in comparison with other companies within the same sector of the economy.
    `,
  },
  assetInformation: {
    title: 'Information',
    name: 'Name',
    issuerName: 'Issuer',
    headOfficeCountry: 'Country of origin',
    sector: 'Sector',
    wkn: 'WKN',
    isin: 'ISIN',
    typeAppropriationEarnings: 'Distribution',
    showMoreButton: 'More information',
    showLessButton: 'Less information',
    information: 'Basic information sheet',
  },
  revenue: {
    title: 'Revenue',
    modalMarkdown: `# Revenue

Revenue refers to the earnings generated through ordinary business operations. Revenue is the average selling price multiplied by the number of units sold. As such, it constitutes the gross income from which costs are deducted to determine the net income.
    `,
  },
  ebit: {
    title: 'EBIT',
    modalMarkdown: `# EBIT

EBIT indicates a company's profitability. The EBIT is calculated by subtracting expenses from income before interest and taxes, thus resulting in earnings before interest and taxes.
    `,
  },
  ebitda: {
    title: 'EBITDA',
    modalMarkdown: `# EBITDA

EBITDA indicates a company's earnings before interest, taxes, depreciation of fixed assets, and amortization of intangible assets. It measures the overall financial performance of a company and can be used, for example, to make cross-border comparisons, given that distortions (such as depreciation or financing policies) are eliminated from EBITDA at the international level.     
    `,
  },
  stockMarketHours: {
    night: {
      banner: 'No trading stocks & ETFs between the hours of {{close}} - {{start}}',
      modal: {
        header: 'No trading stocks & ETFs between the hours of {{close}} - {{start}}',
        bodyMarkdown: `In accordance with stock exchange closing times, no securities trading takes place between {{close}} and {{start}}. 

You can trade stocks and ETFs with BISON on weekdays from {{start}} to {{close}}. 
        
Just pop back in during trading hours!`,
        action: 'Close',
      },
    },
    weekend: {
      banner: 'No trading stocks & ETFs on weekends',
      modal: {
        header: 'No trading stocks & ETFs on weekends',
        bodyMarkdown: `In accordance with stock exchange closing times, no securities trading takes place between {{close}} and {{start}}. 

You can trade stocks and ETFs with BISON on weekdays from {{start}} to {{close}}. 
        
Just pop back in during trading hours!`,
        action: 'Close',
      },
    },
    holiday: {
      banner: 'No trading stocks & ETFs on {{date}}',
      modal: {
        header: 'No trading stocks & ETFs on {{date}}',
        bodyMarkdown: `No securities trading will take place on {{date}} given that it is a public holiday. 

You can trade stocks and ETFs with BISON on weekdays from {{start}} to {{close}}. 
        
Just pop back in during trading hours!`,
        action: 'Close',
      },
    },
    transactionHistory: {
      stockLabel: 'Stock',
    },
  },
  riskSettings: {
    step1: {
      title: `How many times have you traded structured exchange-traded funds (ETFs) worth more than 500 Euros in the last 3 years?`,
      subtitle: 'Question 1',
      details: 'E.g. short ETFs (inverse ETFs), factor ETFs, or leveraged ETFs',
      option1: '0 to 5 times',
      option2: 'Over 5 times',
    },
    step2: {
      title: `How many times have you traded certificates worth more than 500 Euros in the last 3 years?`,
      subtitle: 'Question 2',
      details: 'E.g. reverse convertibles or ETCs',
      option1: '0 to 5 times',
      option2: 'Over 5 times',
    },
    step3: {
      title: `How many times have you traded warrants worth more than 500 Euros in the last 3 years?`,
      subtitle: 'Question 3',
      details: 'E.g. standard warrants, turbo warrants with knock-outs, or factor warrants',
      option1: '0 to 5 times',
      option2: 'Over 5 times',
    },
    step4: {
      title: 'How do leveraged ETFs differ from classic ETFs?',
      subtitle: 'Question 4',
      option1: 'Leveraged and classic ETFs only differ in their product name.',
      option2:
        'A classic ETF tracks the performance of an underlying index almost 1:1. Leveraged ETFs, on the other hand, attempt to multiply the performance of the index by a fixed amount of leverage.',
      option3: "I'm not sure.",
    },
    step5: {
      title: 'What is a short ETF?',
      subtitle: 'Question 5',
      option1:
        'Short ETFs bet on a decline in the value of an underlying asset. If the index falls by 5%, for example, the short ETF rises by 5%.',
      option2:
        'Short ETFs are ETFs that invest in companies expected to file for bankruptcy in the near future.',
      option3: "I'm not sure.",
    },
    step6: {
      title: 'How high can the risk of loss be for certificates?',
      subtitle: 'Question 6',
      option1: 'The risk of loss is limited to 50% of invested capital or a maximum of 20% per year.',
      option2:
        'A total loss may occur by reason of issuer risk, resulting in investors losing 100% of their invested capital.',
      option3: "I'm not sure.",
    },
    step7: {
      title: 'What is the investment strategy of a certificate?',
      subtitle: 'Question 7',
      option1: 'A certificate is a financial instrument that specializes in real estate purchases.',
      option2:
        'Depending on the design, investors receive an agreed repayment if the underlying asset (e.g. index, commodity, stock, or currency) meets a certain performance.',
      option3: "I'm not sure.",
    },
    step8: {
      title: 'What causes the leverage effect in leveraged products?',
      subtitle: 'Question 8',
      option1:
        "With leveraged products, investors require only a fraction of their capital as opposed to investing directly in the underlying asset, while still participating in the underlying asset's price performance to the same extent.",
      option2:
        "Leverage is determined by the issuer and is only effective when the underlying asset's price rises.",
      option3: "I'm not sure.",
    },
    step9: {
      title: 'What factors impact the price of a standard warrant?',
      subtitle: 'Question 9',
      option1:
        'The value of a warrant is determined by a variety of factors, including the remaining term to maturity or the current market price of an underlying asset.',
      option2:
        'The remaining time to maturity is a decisive factor for performance, given that the warrant automatically increases in value when its remaining time to maturity decreases.',
      option3: "I'm not sure.",
    },
    continueButton: 'Continue',
    submitButton: 'Submit answers',
    exitModal: {
      title: "You'd like to stop answering the questions about your experience with securities?",
      subtitle:
        "Keep in mind: If you don't answer these questions, you won't be able to trade stocks or ETFs.",
      continue: 'Continue',
      cancel: 'Cancel',
    },
    entryModal: {
      title: 'Your experience with securities',
      titleProfile: "You'd like to re-determine your experience level with securities?",
      subtitleMarkdownProfile: `Just **answer 9 short questions.** You'll find out your experience level right after. Keep in mind: You can only do this once a day.`,
      subtitleMarkdown: `To ensure that you're trading within the bounds of your own experience and knowledge, we'll need to ask you **9 short questions** about complex financial instruments.`,
      details: `Answer all of the questions completely and to the best of your knowledge. The outcome may either restrict you from trading certain products or result in a warning notice.
        For non-complex products, such as stocks and ETFs, there's no suitability test. We also do not assess your financial circumstances or investment objectives, given that we do not provide investment advice.`,
      actionText: 'Continue to questions',
    },
    successModal: {
      title: {
        main: 'Thanks {{firstname}}! Your experience level is: ',
        basic: 'Basic',
        informed: 'Intermediate',
        advanced: 'Advanced',
      },
      details1: 'You can view this in your account under <1>Profile</1>.',
      details2: 'Have fun trading stocks & ETFs!',
      actionText: 'Continue to buy',
      actionTextProfile: 'Continue to profile',
    },
  },
  pdfDisplayModal: {
    pages: 'Page {{current}} of {{max}}',
  },
  inboxImportantMessagesModal: {
    title: 'Important message',
    text: 'You have a new message with important documents from the Solaris SE. Please check your inbox.',
    action: 'To the inbox',
  },
  instantSEPA: {
    title: 'Instant SEPA payments are now available!',
    // text: 'When making a transfer to BISON, select the <strong>instant payment</strong> option with your bank. Note: <strong>A fee of €1.00</strong> is charged for <strong>every instant payment</strong> (offered by Solaris), deducted directly from your BISON account. Additional fees may be charged by your bank.',
    // TODO: Remove when sepa fees are back.
    text: 'When making a transfer to BISON, select the <strong>instant payment</strong> option with your bank. Note: Additional fees may be charged by your bank.',
    button: 'Got it!',
  },
  deviceMonitoringConsent: {
    modal: {
      title: 'To ensure the security of your BISON account...',
      paragraphOnboarding: `...we need to check your device information. This requires your consent. Note: If you do not provide consent, you won't be able to complete the verification process.`,
      paragraphLogin: `...we need to check your device information. This requires your consent. Note: If you do not provide consent, you won't be able to log in.`,
      paragraphResetPassword: `...we need to check your device information. This requires your consent. Note: If you do not provide consent, you won't be able to reset your password.`,
      paragraphFallBack: `...we need to do checks on your device information. This requires your consent. Note: If you do not provide consent, you won't be able to proceed.`,
      paragraphOptOut: `...we need to check your device information. This requires your consent.`,
      text: `By providing your consent, you authorize us to collect specific information from your device through your browser, including traffic and location data, as well as other device-related information, such as your session ID. We will share this information, along with your email address, name, and phone number, with Solaris. Solaris will supplement this information with additional details and forward it to a service provider for fraud and money laundering screening. \n\nYou can revoke your consent at any time in your Settings > Linked Devices. For more information, see our privacy policy.`,
      action: 'I consent',
    },
    exitModal: {
      title: 'Are you sure you want to close this window?',
      paragraphOnboarding: `Keep in mind that you won't be able to complete the verification process unless you provide the required consent to check your device information.`,
      paragraphLogin: `Keep in mind that you won't be able to log in unless you provide the required consent to check your device information.`,
      paragraphResetPassword: `Keep in mind that you won't be able to reset your password unless you provide the required consent to check your device information.`,
      paragraphFallBack: `Note: You will not be able to proceed without the required consent to check your device information.`,
      paragraphOptOut: `Note: If you do not agree, your account may be suspended or terminated. The checks on your device information protects you from account takeover by third parties, for example.`,
      goBack: 'Back to consent',
      close: 'Close window',
    },
  },
  stockRaffle: {
    banner: {
      title: 'Win 1 of 3 e-scooters',
      stocksLocked: {
        description: 'Unlock stocks & ETFs trading to participate in the draw.',
        button: 'Unlock',
      },
      joined: {
        description: 'Trade stocks or ETFs {{transactionCount}} times to qualify for the raffle.',
        button: 'Trade now',
      },
      notJoined: {
        description: 'Join our stocks & ETFs raffle.',
        button: 'Join now',
      },
    },
    entryModal: {
      title: 'Win 1 of 3 e-scooters',
      subtitle: 'or €30 in Bitcoin (BTC)',
      terms: {
        title: 'Here is how you can qualify:',
        item1: 'Agree to our terms and conditions to join the raffle by clicking on the button below.',
        item2: 'Unlock stocks & ETFs trading.',
        item3: 'Execute at least 3 stocks or ETFs trades from {{startDate}} until {{endDate}} at 23:59.',
      },
      actions: {
        confirm: 'I want to participate',
        dismiss: 'I am not interested',
      },
      note: 'By clicking "I would like to participate", you will get to the <1>conditions of participation</1>. To successfully qualify, you must execute 3 stocks or ETFs trades. The raffle runs from {{startDate}} 08:00 to {{endDate}} at 23:59. The winners will be informed via e-mail.',
    },
    exitModal: {
      title: "Are you sure you don't want to enter the raffle?",
      note: `If you click on "I don't want to participate", you will no longer see this raffle.`,
      actions: {
        confirm: "I don't want to participate",
        dismiss: 'Return to the raffle',
      },
    },
    confirmModal: {
      title: {
        bolded: 'Almost there!',
        regular: ' Here is how to qualify for the raffle:',
      },
      terms: {
        title: 'The following steps are necessary:',
        item1: 'Unlock stocks & ETFs trading',
        item2: 'Execute at least 3 stocks or ETFs trades from {{startDate}} until {{endDate}} at 23:59.',
      },
      confirm: 'Unlock now',
    },
    successModal: {
      title: 'You did it! You are eligible for the raffle!',
      note: 'The winners of the <1>3 x e-scooters</1> and the <1>100 x €30 in Bitcoin (BTC)</1> will be notified via email from {{announcementDate}}.',
      confirm: 'OK',
    },
  },
  kccRaffle: {
    banner: {
      notJoined: {
        title: 'Win 2 tickets for the<br/> Kansas City Chiefs',
        description: 'Be there live at the <strong>American Football game</strong> in Frankfurt.',
        button: 'Participate now',
      },
      joined: {
        title: 'Don’t miss your chance!',
        description:
          '<strong>Trade €100 in crypto and 1 security until the end of this week<br/> for a chance to win 2 tickets to the Kansas City Chiefs game.</strong>',
      },
    },
    entryModal: {
      title: 'Win 2 tickets to the Kansas City Chiefs game!',
      subtitle:
        'Until October 29th, BISON will <strong>be giving away 3 x 2 tickets every week for the game taking place on November 5th in Frankfurt.</strong>',
      terms: {
        title: 'Here’s how to enter the raffle:',
        item1: 'Click on "Participate" to accept the <1>terms and conditions</1>.',
        item2:
          'Trade a minimum of €100 worth of cryptocurrency and complete at least 1 stock or ETF trade during the promotional week (Mon-Sun).',
        link: 'https://bisonapp.com/en/bison-kc/app',
      },
      actions: {
        confirm: 'Participate',
        maybeLater: 'Maybe next week',
        dismiss: 'Don’t show the raffle anymore',
      },
      note: '',
    },
    exitModal: {
      title: 'Are you sure you don’t want to participate in the raffle ?',
      note: `If you select "Don’t show this raffle anymore", you will not be eligible for the Kansas City Chiefs raffle, but you will continue to see other raffles as usual.`,
      actions: {
        confirm: 'Don’t show this raffle anymore',
        dismiss: 'Back to the raffle',
      },
    },
    confirmModal: {
      title: {
        bolded: 'Almost done!',
        regular: ' Qualify for the raffle now:',
      },
      terms: {
        title: 'Here are the steps to secure your entry:',
        item1: 'Verify your account for crypto and securities trading (if not already completed).',
        item2: 'Trade at least €100 worth of cryptocurrency and execute 1 securities trade within this week.',
      },
      note: 'The winners will be notified weekly by email.',
      confirm: 'Let’s get started',
    },
  },
  auditProof: {
    onLoginModal: {
      title: 'Are your details still up to date?',
      text: 'To ensure that your details are still up to date, we ask that you check and update them as needed within the following time period.',
      daysLeft: 'Only {{daysLeft}} days left',
      button: 'Check now',
      hardFlowExtraButton: 'Finish later',
    },
    postponeReviewModal: {
      title: 'Postpone the review',
      text: `Please note that you must review and, if necessary, update your information within the {{daysLeft}} days.
      <br/>
      <br/>
        If you fail to do this, we are legally obligated to lock your account until a review has taken place.`,
      button: 'Review now',
    },
    accountBlocked: {
      title: 'Your account has been locked.',
      text: 'Unfortunately, you did not confirm your data within the 60-day period. Please check your data to regain access to your account.',
      button: 'Review now',
    },
    accountInManualProgress: {
      title: 'We are currently reviewing your data.',
      text: 'We ask you to be patient. It may take up to a few days to unblock your account.',
    },
    summary: {
      title: 'Confirmation of your details',
      text: 'To ensure that your details are still up to date, we ask that you check and update them as needed.',
      fields: {
        address: 'Address',
        email: 'Email',
        phone: 'Cell phone number',
        employmentDetails: 'Employment details',
      },
      hardFlowCheckbox:
        "I confirm that I've checked my details and – where necessary – updated them or initiated the process of updating them.",
      button: 'Submit',
    },
    edit: {
      email: {
        title: 'Confirmation of Your Email Address',
        text: 'Be sure to check and confirm your email address. If anything has changed, you can update it right here.',
        label: 'Email',
        placeholder: 'Please enter your email address.',
        button: 'Confirm',
      },
      employmentStatus: {
        title: 'Your Employment Details',
        label: 'Type of employment',
        emptyType: 'Please select',
        button: 'Confirm',
        typeOfEmployment: {
          Unspecified: 'Please select',
          Apprentice: 'Apprentice',
          Employee: 'Employee',
          Freelancer: 'Freelancer',
          Housework: 'Housework',
          LeadingEmployee: 'Leading Employee',
          Management: 'Management',
          MilitaryOrCommunityService: 'Military or Community Service',
          NotEmployed: 'Not Employed',
          ProfessionalSoldier: 'Professional Soldier',
          PublicSectorEmployee: 'Public Sector Employee',
          Retired: 'Retired',
          SelfEmployed: 'Self Employed',
          Student: 'Student',
        },
        professionalField: {
          error: 'Please select a work industry.',
          empty: 'Select work industry',
          title: 'Work industry',
          AgricultureOrForestry: 'Agriculture, forestry and fishing',
          Mining: 'Mining and quarrying',
          Manufacturing: 'Manufacturing',
          EnergyOrPowerSupply: 'Electricity, gas, steam and air conditioning supply',
          WaterOrWasteRemoval: 'Water supply; sewerage; waste managment and remediation activities',
          Construction: 'Construction',
          RetailOrMaintenance: 'Wholesale and retail trade; repair of motor vehicles and motorcycles',
          TransportAndStorage: 'Transporting and storage',
          Hospitality: 'Accommodation and food service activities',
          InformationOrCommunication: 'Information and communication',
          FinanceOrInsurance: 'Financial and insurance activities',
          RealEstate: 'Real estate activities',
          ScientificServices: 'Professional, scientific and technical activities',
          EconomicServices: 'Administrative and support service activities',
          PublicService: 'Public administration and defence; compulsory social security',
          EducationOrTeaching: 'Education',
          HealthcareOrSocialServices: 'Human health and social work activities',
          ArtOrEntertainment: 'Arts, entertainment and recreation',
          OtherServices: 'Other services activities',
          PrivateHouseholds:
            'Activities of households as employers; undifferentiated goods - and services - producing activities of households for own use',
          ActivitiesOfExtraterritorial: 'Activities of extraterritorial organisations and bodies',
        },
      },
      addressChange: {
        title: 'Edit address',
        text: 'Be sure to check and confirm your address. If anything has changed, you can update it right here.',
        button: 'Confirm',
      },
    },
    twoFa: {
      title: 'Confirm change to details',
      text: "Confirm your changes by entering the security code we've sent via SMS to <1>{{phone}}</1>.",
    },
    success: {
      title: 'Your details have been successfully updated.',
      additionalText:
        "Keep in mind that it may take up to a few days before you have access to your account again – we'll notify you via email once you do.",
    },
    solarisError: 'Our service is currently down. Please try again later.',
  },
  staking: {
    demoOnlyModal: {
      title: 'This feature is only available in real money mode.',
      text: 'Verify now and unlock the full power of BISON.',
      support: `Open questions? We'd be happy to help — feel free to contact us anytime via  <1>{{supportEmail}}</1>.`,
      buttons: {
        stayInDemo: 'Stay in demo mode',
        verify: 'Verify now',
      },
    },
    stakingFeatureIntroduction: {
      0: {
        title: '<1>New:</1><br/>Discover staking with BISON',
        body: 'Our new feature has its own menu item. To get started without any detours, you can find <strong>staking</strong> directly in the navigation bar. ',
      },
      1: {
        title: '<1>View all orders at a glance</1>',
        body: 'You can now find your <strong>limit & stop orders, savings plans</strong> and further information on your <strong>activities</strong> under <strong>Orders</strong> (formerly “Activity”) at the bottom of the navigation bar.',
      },
      2: {
        title: '<1>Manage your price alerts per asset</1>',
        body: 'You can now find an overview of your price alerts <strong>in the respective asset profile</strong>. There you can create new price alerts as usual and activate or deactivate existing price alerts.',
      },
      actions: {
        skip: 'Skip',
        start: 'Get started',
      },
    },
    stakingBlockedModal: {
      title: 'Staking not available in your country',
      text: 'This feature is available only for German tax residents.',
    },
    assetOverview: {
      stakingSectionTitle: '{{shortName}} Staking',
      stake: 'Stake',
      apr: ' Ø APR',
      missingFundsModal: {
        title: '{{shortName}} Staking',
        text: 'You do not have enough {{shortName}} in your portfolio. Buy or deposit {{shortName}} into your BISON account.',
        buttons: {
          buy: 'Buy',
          deposit: 'Deposit',
        },
      },
    },
    joinWaitingList: {
      title: 'Do you want to stake {{displayName}}?',
      text: `We're actively working to enable staking for <strong>{{currency}}</strong>.
       
       Sign up for the waiting list and be the first to know when the time has come.`,
      buttons: {
        join: 'Sign up now',
        later: 'Later',
      },
    },
    title: 'Crypto staking',
    infoPopup: {
      title: `Receive <1>{{apr}} APR</1> for staking {{assetCode}}`,
      body: {
        0: {
          title: 'From the 1st following day in staking*',
          text: 'Until then, your {{assetCode}} are being processed and cannot be unstaked.',
        },
        1: {
          title: 'Staked {{assetCode}} cannot be traded',
          text: 'While your {{assetCode}} are staked, you cannot trade or transfer them.',
        },
        2: {
          title: 'Unstaking process: {{periodStart}} to {{periodEnd}} days',
          text: 'During the unstaking process, {{assetCode}} cannot be accessed, traded, or withdrawn.',
        },
        3: {
          title: 'Earn staking rewards',
          text: 'Participate in staking and receive weekly rewards. <1>Learn more</1> As the staking rewards are network-dependent, only an approximate range can be given.',
        },
      },
      confirm: 'Understood',
      legal:
        '*For technical reasons, it may take up to 2 days for staking to start for directly purchased {{assetCode}}.',
    },
    amount: 'Quantity',
    amountAvailable: 'Available: {{amount}}',
    apr: 'Effective Annual Interest Rate (APR)',
    type: 'Payout',
    typeValue: 'Weekly',
    unstakePeriod: 'Unstaking period',
    periodValue: '{{start}} to {{end}} days',
    rewardDate: 'First reward',
    continue: 'Continue to summary',
    periodPopup: {
      title: 'Unstaking process',
      text: `After you have decided to withdraw the staked {{assetCode}}, the unstaking process begins.<br/><br/>
During this process, your {{assetCode}} will not be available for transfers or trading to protect the network from potential abuse and ensure its stability and security.<br/><br/>
       At the end of the process, your {{assetCode}} will be released again, with the duration of the re-release varying depending on the blockchain network.`,
    },
    errors: {
      emptyAmount: 'Please enter a quantity.',
      tooSmall: 'This quantity is too low, please enter a minimum {{minAmount}} of {{code}}.',
      tooLarge: 'This quantity is too high, please enter a maximum of {{maxAmount}} {{code}}.',
    },
    confirmPopup: {
      stakeType: 'Stake',
      note: 'Your {{assetCode}} will be staked from the 1st day. For technical reasons, it may take up to 2 days for ETH purchased directly. Once you have staked ETH, you cannot unstake them until the next day. You can find more information about the <1>special conditions here.</1>',
      confirm: 'Stake now',
    },
    finishPopup: {
      proceed: 'To Staking',
      titleError: 'Staking failed',
      titleSuccess: '{{amount}} {{assetCode}} staked',
      textSuccess: `The {{amount}} {{assetCode}} will be staked from the first following day. For technical reasons, it may take up to 2 days for staking to start for immediately purchased {{assetCode}}.\n\n In the meantime, you can find your {{assetCode}} in the ‘In process’ section of the staking page.`,
      textError: 'Staking your {{amount}} {{assetCode}} did not work. Please try again later.',
    },
    stakingOverview: {
      buyButton: 'Buy',
      stakeButton: 'Stake',
      chart: {
        chartMeta: {
          totalRewards: 'Total Rewards',
          apr: ' Ø APR',
        },
      },
      availableForStaking: {
        title: 'Available for staking',
        emptyText:
          'No assets available for staking. Once you buy stakable assets, they’ll be displayed here.',
        info: `## Available for staking\n\nAll stakeable cryptocurrencies that you currently own with BISON are displayed here.`,
      },
      buyableForStaking: {
        title: 'Purchasable for staking',
        emptyText:
          'Currently, no additional assets are offered for staking. We are, however, working on expanding the range of assets that can be staked.',
        info: `## Purchasable for staking\n\nAll stakeable cryptocurrencies that you can buy from BISON but do not yet own are displayed here.`,
      },
      availableInFuture: {
        title: 'Available soon',
      },
      inRelease: {
        title: 'In progress',
        emptyText:
          'None of your assets are currently being processed. Unstaked assets are displayed here as long as they are in the unstaking process. Staked assets are displayed here while they are being processed. ',
        info: `## In progress \n\n **Unstaking**\n\n After you have decided to unstake your cryptocurrencies, the unstaking process begins. 

\n\nDuring this time, your cryptocurrencies are not available for transfers or trading. This serves to protect the network from potential abuse and to ensure its stability and security. \n\nAt the end of the process, your cryptocurrencies will be released again, with the duration of the unstaking process varying depending on the blockchain network. \n\n**Staking**\n\nThe staking of your ETH begins on the 1st following day from 0:00.`,
        detailsModal: {
          type: 'In unstaking',
          inProgress: 'In progress',
          periodLabel: 'Remaining duration in unstaking process:',
          id: 'ID',
          date: 'Date & time',
        },
      },
      inStaking: {
        title: 'In Staking',
        emptyText: 'Nothing is currently being staked. Once you stake assets, they’ll be displayed here.',
      },
      rewardsHistory: {
        type: 'Staking reward',
        title: 'Reward history',
        showMore: 'Learn more',
        emptyText:
          "You haven’t generated any staking rewards yet. They'll be displayed here once your first rewards have been generated.",
      },
      info: {
        title: 'What is staking?',
        mainText: `With staking, you hold cryptocurrencies of your choice in a digital wallet while they are used to secure and process transactions in a blockchain network. As a reward for your support, you regularly receive additional cryptocurrencies. By actively engaging in the crypto ecosystem, you can generate passive income instead of just owning cryptocurrencies.`,
        apr: 'APR',
        aprExplanation:
          'APR (Annual Percentage Rate) represents the effective annual interest rate, indicating the yearly yield as a percentage.',
        everyWeek: 'WEEKLY',
        everyWeekExplanation: 'Staking rewards are distributed weekly.',
        showMore: 'Learn more',
      },
      detail: {
        stake: 'Stake',
        unstake: 'Unstake',
        apr: 'Ø APR',
        type: 'Payout',
        typeValue: 'Weekly',
        period: 'Unstaking process',
        date: 'Next payout',
        estimatedAmount: 'Estimated quantity',
      },
    },
  },
  unstaking: {
    title: 'Unstake crypto',
    period: 'Unstaking period',
    periodValue: 'Approximately {{start}} to {{end}} days',
    unstake: 'Unstake',
    inProgress: 'In Progress',
    errors: {
      notEnoughLeftInStake:
        'Please enter either the maximum of {{maxAmount}} {{code}} or enough to leave a minimum of {{minAmount}} {{code}}.',
    },
    infoModal: {
      title: 'In Unstaking <1>process</1>',
      periodLabel: 'Remaining duration in unstaking process:',
      periodValue: '{{days}} days',
      periodValueLessThanOneDay: 'Less than 1 day',
      id: 'ID',
      date: 'Date & time',
      legalStakeText:
        'Your {{assetCode}} will be staked from the 1st following day. If you have any questions, please contact us at any time at ',
    },
    confirmModal: {
      title: 'UNSTAKE',
      confirm: 'Unstake now',
      note: 'You can find more information on the special conditions <1>here.</1>',
    },
    finishModal: {
      proceed: 'To staking',
      titleSuccess: '{{amount}} {{assetCode}} unstaked',
      textSuccess: `It can take **{{start}} to {{end}} days** to transfer {{amount}} {{assetCode}} to your portfolio.\n\nDuring the unstaking process, you will find your {{assetCode}}  in the "In unstaking process" section on the staking page.
      `,
      titleError: 'Unstaking failed',
      textError: 'Unstaking your {{amount}} {{assetCode}} did not work. Please try again later.',
    },
  },
  experiments: {
    winnersLosers: {
      winner: {
        title: "Yesterday's top 3 winners:",
      },
      loser: {
        title: "Yesterday's top 3 losers:",
      },
      crypto: {
        subtitle: 'Get started with stocks!',
        text: 'Diversify your portfolio with a selection of over 1,600 stocks and tap into market trends. Enable securities trading on BISON and optimize your investment strategy.',
        button: 'Enable now',
      },
      securities: {
        subtitle: 'Seize your next opportunity!',
        text: "Don't miss out on the chance to benefit from stock market price movements. Choose from a selection of over 1,600 stocks and optimize your investment profile now.",
        button: 'Select stocks',
      },
    },
    cryptoShort: {
      title: {
        eth: 'Short Ethereum now ',
        btc: 'Short Bitcoin now',
      },
      description: {
        eth: 'With the {{displayName}}, you can invest in a declining Ethereum price.',
        btc: 'With the {{displayName}}, you can invest in a declining Bitcoin price.',
      },
      infoMarkdown: {
        eth: `# What is the {{displayName}}?
        
The {{displayName}} replicates the inverse performance of Ethereum.

The product replicates the corresponding underlying 1:1. With high volatility in the cryptocurrency market, you can invest in a falling Ethereum price. 
        `,
        btc: `# What is the {{displayName}}?
        
The {{displayName}} replicates the inverse performance of Bitcoin.

The product replicates the corresponding underlying 1:1. With high volatility in the cryptocurrency market, you can invest in a falling Bitcoin price.`,
        button: 'Trade ETP',
      },
      timeSpanText: 'Last 24h',
      button: 'Trade ETP',
    },
  },
  stakingRollout: {
    banner: {
      title: 'Staking is currently only available in the BISON mobile app',
      text: `The introduction of staking in the web application will follow. If you have already staked in the BISON mobile app, you will not be able to see your staked cryptocurrencies on the web application yet.<br/>  Therefore, it will appear as if your wallet has been depleted by the cryptocurrencies you have staked. This is not the case. To get the correct view of your portfolio, to stake and unstake, and to get more details about staking, please use the BISON mobile app.`,
    },
    portfolioInfoPopupStakingData: {
      blockedByStaking: 'Blocked by staking',
      checkInApp: 'is displayed in the BISON app**',
    },
    assetOverviewStakingBlock: {
      title: 'Ethereum Staking',
      text: 'Everything about staking is currently only available in the BISON mobile app. If you have already staked ETH, please use the mobile app to stake, unstake and view further details.',
    },
  },
  blockpitBanner: {
    title: 'Simple & fast tax report with <image />',
    info: 'Quickly track your transactions and view all your assets at a glance!',
    buttonText: 'Learn more',
  },
  blockpitModal: {
    title: 'Tax reporting has never been easier',
    url: 'https://www.blockpit.io/lp/bison',
    table: {
      a: {
        mainText: 'Easily record your transactions',
        secondaryText: 'Import your transaction history as a CSV file',
      },
      b: {
        mainText: 'Save time and money',
        secondaryText: 'Enjoy an exclusive discount as a BISON customer',
      },
      c: {
        mainText: 'View all assets at a glance',
        secondaryText: 'Keep track of your portfolio',
      },
    },
    buttonTitle: 'Create tax report now',
  },
  blockpitInfoSection: {
    title: 'Get your tax report quickly and easily with <image />',
    text: 'No more tax stress! Quickly track your transactions and view all your assets at a glance. With BISON and Blockpit, managing your crypto and simplifying your tax reporting has never been easier.',
    button: 'Learn more',
  },
  orders: {
    tradeManager: {
      title: 'Open Orders',
      filters: {
        all: 'All',
        saving: 'Savings plan',
        limitStop: 'Limit & Stop',
      },
      saving: {
        title: 'Savings plans',
        emptyText: 'Select a savings amount and savings interval for the cryptocurrency of your choice.',
        action: 'New savings plan',
      },
      limitStop: {
        title: 'Limit & Stop Orders',
        emptyText: 'No time to monitor the crypto market? Create trading rules to buy or sell automatically.',
        actions: {
          0: 'Limit Order',
          1: 'Stop Order',
        },
        modal: {
          title: 'Limit & Stop Orders',
          action: 'Close',
          text: `
We currently support two types of trading rules:
<2/>
<2/>
<1>Limit Order</1>
<2/>
Buy Limit Rule: Enter the market to take advantage of lower prices. You specify a lower price at which you'd like to buy, and a purchase is executed as soon as that price is reached.  
<2/>
<2/>
Sell Limit Rule: Exit the market to take advantage of profits. You specify a higher price at which you'd like to sell, and a sale is executed as soon as that price is reached.
<2/>
<2/>
<1>Stop Order</1>
<2/>
Stop Buy Rule: Enter the market to participate in upward trends. You buy when a cryptocurrency reaches or exceeds a price that you've specified. 
<2/>
<2/>
Stop Loss Rule: Exit the market to limit losses. You sell when the price you've specified crosses a lower price point.
          `,
          bottomText: `
A pending order is blocking the expected value of your trade. Blocked assets can be viewed in your portfolio.
<1/>
<1/>
You can always view or delete your active trading rules in the Trading Manager. Trading rules remain valid for 90 days and are automatically deleted once this period is over.
<1/>
<1/>
We will notify you as soon as your trading rule is executed.
          `,
        },
      },
    },
    transactionHistory: {
      title: 'Transaction Overview',
      inProgress: {
        title: 'In Progress',
        emptyText: 'There are currently no transactions in progress.',
      },
      completed: {
        title: 'Completed',
        emptyText:
          'Once you’ve made your first deposit, it will be displayed here. Everything else – such as buys, sells, deposits & withdrawals – are also listed here.',
      },
    },
  },
  priceAlerts: {
    title: 'Price Alerts',
    emptyList:
      "You don't have any price alerts. As soon as you've created a price alert, it’ll be displayed here.",
    create: 'Create Price Alert',
    currentPrice: 'Current price: {{price}}',
  },
};
