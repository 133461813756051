import I18n from 'i18next';
import { TimePeriod } from 'types/currency';
import initIi18n from '../../locales/i18n';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
initIi18n();

export const timePeriods: Array<{ key: TimePeriod; translation: string }> = [
  { key: '3H', translation: I18n.t('home.currency.timePeriods.threeHours') },
  { key: '24H', translation: I18n.t('home.currency.timePeriods.day') },
  { key: '7D', translation: I18n.t('home.currency.timePeriods.week') },
  { key: '30D', translation: I18n.t('home.currency.timePeriods.month') },
  { key: '12M', translation: I18n.t('home.currency.timePeriods.year') },
  { key: 'MAX', translation: I18n.t('home.currency.timePeriods.max') },
];
