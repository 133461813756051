import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import React from 'react';

/**
 * A Higher-Order Component (HOC) that provides router-related props to a wrapped component.
 * @param {React.ComponentType} WrappedComponent - The component to be wrapped with router props.
 * @returns {React.ComponentType} - A new component with router-related props.
 */
const withRouter = (WrappedComponent: any) => {
  /**
   * @param {Object} props - Props passed to the wrapped component.
   * @returns {JSX.Element} - JSX representing the wrapped component with router props.
   */
  return function WithRouter(props: any) {
    /**
     * The current router location object.
     * @type {Object}
     * @property {string} pathname - The current path of the URL.
     * @property {string} search - The current search string of the URL.
     * @property {Object} state - The current state object associated with the URL.
     */
    const location = useLocation();

    /**
     * A function to navigate to different routes.
     * @type {function}
     * @param {string} to - The path to navigate to.
     */
    const navigate = useNavigate();

    /**
     * A record with all route params.
     * @type {Readonly<Params<string>>}
     */
    const params = useParams();

    return <WrappedComponent {...props} params={params} location={location} navigate={navigate} />;
  };
};

export default withRouter;
