import { ActionType } from 'safetypings';
import * as termsAndConditionsActions from 'store/actions/termsAndConditions';
import { ValuesType } from 'utility-types';
import { TNC_POPUP_TYPES } from 'common/const/termsAndConditions';

export type BlockedFeatures = string[] | null;

export type Terms = {
  version: string | null;
  name?: string | null;
  effectiveAt: string | null;
  termsUrl: string | null;
  risksUrl: string | null;
};

export type TermsAndConditionsData = {
  hasAcceptedLatestTerms: boolean;
  latestTerms: Terms;
  acceptedTerms: Terms;
  blockedFeatures: BlockedFeatures;
  warningStage: boolean;
};

export type TermsAndConditionsActions = ActionType<typeof termsAndConditionsActions>;

export type TnCPopUpType = ValuesType<typeof TNC_POPUP_TYPES>;

export enum TermsAndConditionsType {
  STOCKS = 'stocks',
  CRYPTO = 'crypto',
}
