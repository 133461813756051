import { RootEpic } from 'types/common';
import { catchError, exhaustMap, filter, map, pluck, switchMap, take } from 'rxjs/operators';
import {
  fetchLossTaxExemptionsFailure,
  fetchLossTaxExemptionsRequest,
  fetchLossTaxExemptionsSuccess,
} from 'store/slices/lossTaxExemptions/actions';
import { getAccessToken } from 'store/selectors/auth';
import { isPresent } from 'safetypings';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import { combineEpics } from 'redux-observable';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { AppReduxEpicMiddleware } from 'store/types/store';

const watchLossTaxExemptionsRequest: RootEpic = (action$, state$, { api, ajax }: AppReduxEpicMiddleware) =>
  action$.pipe(
    filter(fetchLossTaxExemptionsRequest.match),
    exhaustMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api
              .getBaseUrlForUserServices(accessToken)
              ?.concat('/stocks/loss-tax-exemptions')
              .concat(
                queryArrayString({
                  Skip: 0,
                  Limit: 10,
                }),
              ),
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(pluck('response'), map(fetchLossTaxExemptionsSuccess)),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchLossTaxExemptionsFailure)),
      ),
    ),
  );

export default combineEpics(watchLossTaxExemptionsRequest);
