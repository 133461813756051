import { createContext } from 'react';
import { ModalProps } from 'types/modals';
import { ModalContextType } from 'providers/Modal/types';

const ModalContext = createContext<ModalContextType>({
  queue: [],
  addModal: (modalData: ModalProps) => undefined,
  addModals: (modalData: ModalProps[]) => undefined,
  blockModalQueue: () => undefined,
  unblockModalQueue: () => undefined,
});

export default ModalContext;
