import React, { BaseSyntheticEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUserInPaperTrading } from 'store/selectors/auth';
import { paths } from 'common/urls';
import { devices } from 'common/styles';
import { useMedia } from 'common/hooks/useMedia';
import useMainPages from 'common/hooks/useMainPages';
import { useStakingRoleBlockedReason } from 'common/hooks/useStakingRoleBlockedReason';
import { setStakingBlockedModalStatus } from 'store/slices/staking/actions';
import Icon from '../Icon';
import { Menu, NavItem, Blackout } from './styles';

type Props = {
  opened: boolean;
  toggleMobileMenu: (opened: boolean) => void;
};

const MobileMenu: FC<Props> = ({ opened, toggleMobileMenu }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMainCategory } = useMainPages();
  const isMobile = useMedia([devices.maxMobileL, devices.mobileL], [true, false], false);
  const isDemo = useSelector(getIsUserInPaperTrading);
  const stakingRoleBlockedReason = useStakingRoleBlockedReason();

  const checkStakingRoleBeforeToggleMenu = (e: BaseSyntheticEvent) => {
    if (stakingRoleBlockedReason) {
      e?.preventDefault();
      dispatch(setStakingBlockedModalStatus({ isOpened: true, type: stakingRoleBlockedReason }));
    }
    toggleMobileMenu(false);
  };

  const links = [
    {
      url: paths.PORTFOLIO,
      name: t('tabTitles.portfolio'),
      icon: 'custom-icon-donut-chart',
      exact: false,
    },
    {
      url: paths.MARKET_OVERVIEW,
      name: t('tabTitles.market'),
      icon: 'icon-trending-up',
      exact: false,
    },
    {
      url: paths.STAKING,
      name: t('tabTitles.staking'),
      icon: 'icon-reload',
      exact: false,
    },
    {
      url: paths.ORDERS,
      name: t('tabTitles.orders'),
      icon: 'icon-swap-horiz',
      exact: false,
    },
    {
      url: paths.SETTINGS,
      name: t('tabTitles.settings'),
      icon: 'icon-profile-icon',
      exact: false,
    },
  ];

  if (!isMainCategory) {
    return null;
  }

  return (
    <>
      <Menu show={opened} isDemo={isDemo}>
        {links.map((link) => (
          <NavItem key={link.url} to={link.url} end={link.exact} onClick={checkStakingRoleBeforeToggleMenu}>
            <Icon name={link.icon} sizeValue={isMobile ? 'tiny' : 'small'} />
            {link.name}
          </NavItem>
        ))}
      </Menu>
      <Blackout show={opened} onClick={() => toggleMobileMenu(!opened)} />
    </>
  );
};

export default MobileMenu;
