import { createAction } from '@reduxjs/toolkit';
import { LoadMarketOverviewAssetsSuccess } from 'types/marketOverview';

/**
 * Place here all actions that are imported in multiple redux slices. Doing that we avoid circular dependencies.
 */

// MARKET OVERVIEW
export const loadMarketOverviewAssets = createAction<{ forceReload?: boolean }>('marketOverview/load');
export const loadMarketOverviewAssetsSuccess = createAction<LoadMarketOverviewAssetsSuccess>(
  'marketOverview/loadSuccess',
);
