import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ModalTemplate from 'common/components/Modal/index';
import confirmImg from 'assets/img/KCCsuccessImage.svg';
import {
  BlackText,
  ContainerCenter,
  RaffleButton,
  RaffleHeader,
  RaffleTCListItem,
  RaffleTCTitle,
} from '../styles';
import { ConfirmRaffleCTList, Note } from './styles';

type Props = {
  onDismiss: () => void;
  onConfirm: () => void;
};

const KCCRaffleConfirmModal = ({ onConfirm, onDismiss }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      defaultOpen
      actions={
        <RaffleButton
          fontSize="small"
          size="large"
          title={t('kccRaffle.confirmModal.confirm')}
          onClick={onConfirm}
        />
      }
      onCancel={onDismiss}
    >
      <ContainerCenter>
        <img width={125} height={125} src={confirmImg} alt="Raffle confirm" />
        <RaffleHeader centered>
          {t('kccRaffle.confirmModal.title.bolded')}
          <BlackText>{t('kccRaffle.confirmModal.title.regular')}</BlackText>
        </RaffleHeader>
        <RaffleTCTitle $disableTopMargin>{t('kccRaffle.confirmModal.terms.title')}</RaffleTCTitle>
      </ContainerCenter>
      <ConfirmRaffleCTList withPadding>
        <RaffleTCListItem>
          <Trans i18nKey="kccRaffle.confirmModal.terms.item1" />
        </RaffleTCListItem>

        <RaffleTCListItem>{t('kccRaffle.confirmModal.terms.item2')}</RaffleTCListItem>
      </ConfirmRaffleCTList>
      <Note>{t('kccRaffle.confirmModal.note')}</Note>
    </ModalTemplate>
  );
};

KCCRaffleConfirmModal.defaultProps = {};

export default KCCRaffleConfirmModal;
