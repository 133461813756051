import question_img from 'assets/img/documentVerificationQuestion.svg';
import blocked_img from 'assets/img/documentVerificationFailed.svg';
import manual_progress_img from 'assets/img/documentVerificationWaiting.svg';
import { AmlConfirmationStatus } from 'types/state';
import { $Values } from 'utility-types';

export const AUDIT_PROOF_MODAL_TYPES = Object.freeze({
  QUESTION: 'QUESTION',
  BLOCKED: 'BLOCKED',
  MANUAL_PROGRESS: 'MANUAL_PROGRESS',
});

export const TYPE_TO_TRANSLATION_KEY = {
  [AUDIT_PROOF_MODAL_TYPES.QUESTION]: 'onLoginModal',
  [AUDIT_PROOF_MODAL_TYPES.BLOCKED]: 'accountBlocked',
  [AUDIT_PROOF_MODAL_TYPES.MANUAL_PROGRESS]: 'accountInManualProgress',
};

export const MODAL_TYPE_TO_IMG_MAP = {
  [AUDIT_PROOF_MODAL_TYPES.QUESTION]: question_img,
  [AUDIT_PROOF_MODAL_TYPES.BLOCKED]: blocked_img,
  [AUDIT_PROOF_MODAL_TYPES.MANUAL_PROGRESS]: manual_progress_img,
};

export const AML_STATUSES: Record<string, AmlConfirmationStatus> = {
  UNSPECIFIED: 'Unspecified',
  CONFIRMED: 'Confirmed',
  OPEN: 'Open',
  OVERDUE: 'Overdue',
};

export type AuditProofModalType = $Values<typeof AUDIT_PROOF_MODAL_TYPES>;

export const EMPLOYMENT_TYPE_OPTIONS_WHICH_LEAD_TO_PROFESSIONAL_FIELD = [
  'SelfEmployed',
  'Freelancer',
  'PublicSectorEmployee',
  'ProfessionalSoldier',
  'Housework',
  'Apprentice',
  'Management',
  'MilitaryOrCommunityService',
  'LeadingEmployee',
  'Employee',
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  'Retired',
  'NotEmployed',
  'Student',

  ...EMPLOYMENT_TYPE_OPTIONS_WHICH_LEAD_TO_PROFESSIONAL_FIELD,
];

export const PROFESSIONAL_FIELD_OPTIONS = [
  ['A', 'AgricultureOrForestry'],
  ['B', 'Mining'],
  ['C', 'Manufacturing'],
  ['D', 'EnergyOrPowerSupply'],
  ['E', 'WaterOrWasteRemoval'],
  ['F', 'Construction'],
  ['G', 'RetailOrMaintenance'],
  ['H', 'TransportAndStorage'],
  ['I', 'Hospitality'],
  ['J', 'InformationOrCommunication'],
  ['K', 'FinanceOrInsurance'],
  ['L', 'RealEstate'],
  ['M', 'ScientificServices'],
  ['N', 'EconomicServices'],
  ['O', 'PublicService'],
  ['P', 'EducationOrTeaching'],
  ['Q', 'HealthcareOrSocialServices'],
  ['R', 'ArtOrEntertainment'],
  ['S', 'OtherServices'],
  ['T', 'PrivateHouseholds'],
  ['U', 'ActivitiesOfExtraterritorial'],
];
