// @flow
import I18n from 'i18next';

export const DEVICE_ID = 'bisonweb_device_id';
const CLIENT_TOKEN = 'bisonweb_client_token';

// Stores tokens to the session storage
export function storeAuthTokens({ clientToken, deviceId }: { clientToken?: string; deviceId: string }): {
  error?: { message: string };
  response?: boolean;
} {
  try {
    if (clientToken !== undefined) {
      localStorage.setItem(CLIENT_TOKEN, clientToken);
    }
    if (deviceId !== undefined) {
      localStorage.setItem(DEVICE_ID, deviceId);
    }
    return { response: true };
  } catch (error: any) {
    return { error: { message: error?.message ?? 'Something bad happened while storing tokens' } };
  }
}

// Loads tokens from the session storage
export function loadAuthTokens(): {
  error?: { code: string; message: string };
  response?: { clientToken: string };
} {
  try {
    const clientToken = localStorage.getItem(CLIENT_TOKEN);
    const deviceId = localStorage.getItem(DEVICE_ID);
    if (!clientToken || !deviceId) {
      return { error: { code: 'no_client_key', message: I18n.t('login.errorGeneric') } };
    }
    return { response: { clientToken } };
  } catch (error: any) {
    return { error: { code: error.code, message: I18n.t('login.errorGeneric') } };
  }
}

// Clears tokens from the session storage
export function clearAuthTokens(): {
  error?: { message: string };
  response?: boolean;
} {
  try {
    localStorage.removeItem(CLIENT_TOKEN);
    // localStorage.removeItem(DEVICE_ID);
    return { response: true };
  } catch (error: any) {
    return { error: { message: error?.message ?? 'Something bad happened while clearing tokens' } };
  }
}

// Loads deviceid from the session storage
export function loadDeviceId(): {
  error?: { code: string; message: string };
  response?: { deviceId: string };
} {
  try {
    const deviceId = localStorage.getItem(DEVICE_ID);
    if (!deviceId) {
      return { error: { code: 'no_device_id', message: I18n.t('login.errorGeneric') } };
    }
    return { response: { deviceId } };
  } catch (error: any) {
    return { error: { code: error.code, message: I18n.t('login.errorGeneric') } };
  }
}
