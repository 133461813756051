// epics/newsletter.ts

import { combineEpics } from 'redux-observable';
import { catchError, filter, map, pluck, switchMap, mergeMap, take, exhaustMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { getAccessToken } from 'store/selectors/auth';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { getBaseUrlForUserServices } from 'common/api';
import { NewsletterGetResponse, NewsletterRequestDto } from 'store/types/newsletter';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import I18n from 'i18next';
import {
  fetchNewsletterFailure,
  fetchNewsletterRequest,
  fetchNewsletterSuccess,
  setNewsletterFailure,
  setNewsletterRequest,
  setNewsletterSuccess,
} from 'store/slices/newsletter/actions';
import { AppReduxEpicMiddleware, State } from 'store/types/store';
import { isPresent } from 'safetypings';
import { AjaxResponse } from 'rxjs/ajax';

const watchFetchNewsletter = (
  action$: Observable<AnyAction>,
  state$: Observable<State>,
  { ajax, api }: AppReduxEpicMiddleware,
) =>
  action$.pipe(
    filter(fetchNewsletterRequest.match),
    exhaustMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: getBaseUrlForUserServices(accessToken)?.concat('/user/email-subscriptions'),
            method: 'GET',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck<AjaxResponse, NewsletterGetResponse>('response'),
            map((data: NewsletterGetResponse) => fetchNewsletterSuccess(data)),
            catchError(() => of(fetchNewsletterFailure())), // Error handling using of
          ),
        ),
      ),
    ),
  );
const watchSetNewsletter = (
  action$: Observable<AnyAction>,
  state$: Observable<State>,
  { ajax, api }: AppReduxEpicMiddleware,
) =>
  action$.pipe(
    filter(setNewsletterRequest.match),
    exhaustMap(({ payload }: PayloadAction<NewsletterRequestDto>) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: getBaseUrlForUserServices(accessToken)?.concat('/user/email-subscriptions'),
            method: 'POST',
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: {
              isSubscribed: payload.isSubscribed,
              subscriptionType: payload.subscriptionType,
            },
          }).pipe(
            pluck<AjaxResponse, NewsletterRequestDto>('response'),
            mergeMap((response: NewsletterRequestDto) => {
              const updatedResponse: NewsletterRequestDto = {
                ...response,
                subscriptionType: payload.subscriptionType,
              };

              // Return an array of actions
              return [
                setNewsletterSuccess(updatedResponse),
                notificationBannerActions.notifySuccess({
                  message: I18n.t('settings.newsletter.popupMessages.success'),
                }),
              ];
            }),
            catchError(() =>
              of(
                setNewsletterFailure(),
                notificationBannerActions.notifyError({
                  message: I18n.t('settings.newsletter.popupMessages.error'),
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

export default combineEpics(watchFetchNewsletter, watchSetNewsletter);
