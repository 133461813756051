import { LegalAgreementsState } from 'store/types/legalAgreements';
import * as ActionTypes from '../actions/legalAgreements';
import { AnyAction } from 'redux';

const initialState: LegalAgreementsState = {
  acceptedBetaPhaseAt: undefined,
  displayTradingReturnWaiver: false,
};

function root(state: LegalAgreementsState = initialState, action: AnyAction) {
  switch (action.type) {
    case ActionTypes.ACCEPT_BETA_PHASE:
      return { ...state, acceptedBetaPhaseAt: new Date().toISOString() };
    default:
      return state;
  }
}

export default root;
