import React from 'react';
import { withTranslation } from 'react-i18next';
import { paths } from 'common/urls';
import PaperTradingBanner from 'common/components/Banners/PaperTradingBanner';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath, useParams, Location } from 'react-router-dom';
import { getIsUserInPaperTrading } from 'store/selectors/auth';
import { getPageTitle } from 'store/selectors/localSettings';
import { useStakingRoleBlockedReason } from 'common/hooks/useStakingRoleBlockedReason';
import { deletePriceAlert } from 'store/slices/priceAlerts/actions';
import KCCRaffleBanner from 'common/components/Banners/KCCRaffleBanner';
import { useKCCRaffleDisplay } from 'common/hooks/useKCCRaffleDisplay';
import { WithRouterType } from 'types/withRouter';
import useTopBar from 'providers/TopBar/useTopBar';
import { useDesktop } from 'common/hooks/useMedia';
import useMainPages from 'common/hooks/useMainPages';
import IconButton from '../IconButton/IconButton';
import ProgressIndicator from '../ProgressIndicator';
import MainTabs from './MainTabs/MainTabs';
import {
  BisonImg,
  BisonLogo,
  BisonTextLogo,
  BurgerButton,
  CustomLayoutWrapper,
  CustomTopBarWrapper,
  HeaderContainer,
  Icons,
  IconWrapper,
  InboxButton,
  InboxIcon,
  MobileTitle,
  SettingsIcon,
  TopBarContainer,
  UnreadMessagesIcon,
  BackButton,
} from './styles';

// TODO: Uncomment when/if raffle comes back.
// import { useStockRaffleDisplay } from 'common/hooks/useStockRaffleDisplay';

type Props = {
  location: Location;
  selectedCrypto: string;
  unReadMessages: number;
  toggleMobileMenu?: (newState?: boolean) => void;
  nSteps?: number;
  currentStep?: number;
  mobileMenuOpen: boolean;
} & WithRouterType;
const TopBar = ({
  selectedCrypto,
  unReadMessages,
  toggleMobileMenu,
  nSteps,
  currentStep,
  mobileMenuOpen,
  location,
  navigate,
}: Props) => {
  const desktopLayout = useDesktop();
  const dispatch = useDispatch();
  // TODO: Uncomment when/if raffle comes back.
  // const { isDisplayed: isStockRaffleBannerDisplayed } = useStockRaffleDisplay();
  const { isDisplayed: isKCCRaffleBannerDisplayed } = useKCCRaffleDisplay();
  const showPaperTradingBanner = useSelector(getIsUserInPaperTrading);
  const stakingRoleBlockedReason = useStakingRoleBlockedReason();
  const isDemo = useSelector(getIsUserInPaperTrading);
  const title = useSelector(getPageTitle);
  const { backUrl, isShouldGoBack } = useMainPages();
  const params = useParams();
  const { customOnBack } = useTopBar();

  const handleBack = () => {
    if (isShouldGoBack) {
      navigate(-1);
    } else {
      navigate(backUrl);
    }
  };
  const isKycFlow = location.pathname.includes(paths.KYC);

  const unreadMessagesNotNaN = Number.isNaN(unReadMessages) ? 0 : unReadMessages;
  const limitedUnreadMessages = unreadMessagesNotNaN < 99 ? unreadMessagesNotNaN : 99;

  const handleDeletePriceAlert = () => {
    const { currency, id } = params;

    if (!id) return;

    const idNumber = Number(id);

    dispatch(deletePriceAlert({ id: idNumber, entity: currency ?? '' }));
  };

  const desktopHeader = (
    <>
      <BisonLogo to={paths.PORTFOLIO}>
        <BisonImg />
        <BisonTextLogo />
      </BisonLogo>
      <MainTabs
        selectedCrypto={selectedCrypto}
        stakingRoleBlockedReason={stakingRoleBlockedReason}
        isDemo={isDemo}
      />
      <Icons>
        <IconWrapper isActive={location.pathname === paths.INBOX}>
          <InboxButton onClick={() => navigate(paths.INBOX)}>
            <InboxIcon name="icon-envelope" sizeValue="tiniest" />
            <UnreadMessagesIcon unReadMessages={limitedUnreadMessages}>
              {limitedUnreadMessages}
            </UnreadMessagesIcon>
          </InboxButton>
        </IconWrapper>
        <IconWrapper isActive={location.pathname === paths.PERSONAL_INFO}>
          <InboxButton onClick={() => navigate(paths.PERSONAL_INFO)}>
            <SettingsIcon name="icon-profile-icon" sizeValue="tiniest" />
          </InboxButton>
        </IconWrapper>
      </Icons>
    </>
  );

  const mobileHeader = (
    <>
      {backUrl || isShouldGoBack ? (
        <>
          <BackButton>
            <IconButton onClick={isKycFlow ? customOnBack : handleBack}>
              <InboxIcon name="icon-arrow" sizeValue="regular" />
            </IconButton>
          </BackButton>
          <MobileTitle>{title}</MobileTitle>
        </>
      ) : (
        <BisonLogo to={paths.PORTFOLIO}>
          <BisonImg />
          <BisonTextLogo />
        </BisonLogo>
      )}

      {isKycFlow ? (
        <ProgressIndicator max={nSteps} step={currentStep} />
      ) : (
        <Icons>
          {matchPath(paths.PRICE_ALERTS_CREATION, location.pathname) ? (
            <IconWrapper>
              <InboxButton onClick={handleDeletePriceAlert}>
                <InboxIcon name="custom-icon-trash-alt" sizeValue="regular" />
              </InboxButton>
            </IconWrapper>
          ) : (
            <IconWrapper>
              <InboxButton onClick={() => navigate(paths.INBOX)}>
                <InboxIcon name="icon-envelope" sizeValue="regular" />
                <UnreadMessagesIcon mobile unReadMessages={limitedUnreadMessages}>
                  {limitedUnreadMessages}
                </UnreadMessagesIcon>
              </InboxButton>
            </IconWrapper>
          )}

          {!backUrl && !isShouldGoBack && (
            <>
              <IconWrapper isActive={location.pathname === paths.SETTINGS}>
                <InboxButton onClick={() => navigate(paths.SETTINGS)}>
                  <SettingsIcon name="icon-profile-icon" sizeValue="tiniest" />
                </InboxButton>
              </IconWrapper>
              <BurgerButton>
                <IconButton
                  onClick={() => {
                    if (toggleMobileMenu) toggleMobileMenu(!mobileMenuOpen);
                  }}
                >
                  <InboxIcon
                    name={mobileMenuOpen ? 'icon-crossIcon' : 'icon-hamburger'}
                    sizeValue="regular"
                  />
                </IconButton>
              </BurgerButton>
            </>
          )}
        </Icons>
      )}
    </>
  );

  const isBannerDisabled = mobileMenuOpen && !desktopLayout;

  return (
    <>
      <HeaderContainer>
        <TopBarContainer isBannerDisabled={isDemo && isBannerDisabled}>
          <CustomTopBarWrapper>{desktopLayout ? desktopHeader : mobileHeader}</CustomTopBarWrapper>
        </TopBarContainer>

        {showPaperTradingBanner && !isKycFlow && (
          <CustomLayoutWrapper>
            <PaperTradingBanner mobileMenuOpen={mobileMenuOpen} />
          </CustomLayoutWrapper>
        )}

        {/* // TODO: Uncomment when/if raffle comes back.
        {isStockRaffleBannerDisplayed && <StockRaffleBanner />} */}
        {isKCCRaffleBannerDisplayed && <KCCRaffleBanner />}
      </HeaderContainer>
    </>
  );
};

TopBar.defaultProps = {
  toggleMobileMenu: () => undefined,
  nSteps: 0,
  currentStep: 0,
};

export default withTranslation()(TopBar);
