import { FiltersStateType } from 'types/filters';
import { ASSET_ORDER_TYPES, ASSET_TAGS, MarketOverviewOrderBy } from 'types/assets';

export const initialState: FiltersStateType = {
  marketOverview: {
    assetTag: ASSET_TAGS.CRYPTO,
    order: ASSET_ORDER_TYPES.MOST_TRADED as MarketOverviewOrderBy,
    searchText: '',
    searchAssetCodes: [],
    visibleAssetCodes: [],
    reference: undefined,
  },
  inbox: {
    selectedCategory: 'notifications',
  },
};
