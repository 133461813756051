import { createAction } from '@reduxjs/toolkit';
import { StakingRewardsHistoryFilters } from 'types/staking';
import stakingSlice from '.';

export const {
  stakingInfoRequest,
  stakingInfoSuccess,
  stakingInfoFailure,
  stakeUnstakeFlowRequest,
  stakeUnstakeFlowRequestSuccess,
  stakeUnstakeFlowRequestFailure,
  resetStakeUnstakeState,
  stakingRewardsHistoryRequest,
  stakingRewardsHistorySuccess,
  stakingRewardsHistoryFailure,
  stakingChartRequest,
  stakingChartError,
  stakingChartSuccess,
  stakingNextRewardSuccess,
  stakingNextRewardFailure,
  stakingNextRewardRequest,
  setStakingBlockedModalStatus,
  fetchPendingOrdersRequest,
  fetchPendingOrdersSuccess,
  fetchPendingOrdersFailure,
  updateLastPointOfChart,
  invalidateStakingState,
} = stakingSlice.actions;

export const stakingRewardsNextPageRequest = createAction<StakingRewardsHistoryFilters>(
  'stakingRewardsNextPageRequest',
);
